import { SequenceUtils } from "@exportx/ui-utils"
import { ColumnsType } from "antd/es/table"

export const commonColumns: ColumnsType<any> = [
    {
        title: 'Barge Id',
        dataIndex: 'bargeId',
        render: (value) => {
            return <>
                {SequenceUtils.formatNumberToSpecificLength(value)}
            </>
        }
    },
    {
        title: 'Barge Nomination',
        dataIndex: 'bargeNomination'
    },
    {
        title: 'Purchase Contract',
        dataIndex: 'purchaseContractNo'
    },
    {
        title: 'Purchase Type',
        dataIndex: 'pcType'
    },
    {
        title: 'Quantity(MT)',
        dataIndex: 'bargeQuantityInMt'
    },
]