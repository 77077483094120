import { Button, Card, Tabs } from 'antd';
import PreshipmentReport from './pre-shipment-report';
import { useEffect } from 'react';
import { isPermissionExist } from '../../../../common';
import { useNavigate } from 'react-router-dom';

const { TabPane } = Tabs;
const PreShipment = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isPermissionExist([873])) navigate('/');
  }, []);

  return (
    <Card
      title="Pre-Shipment-S&A"
      extra={
        isPermissionExist([851]) && (
          <Button
            className="new-button"
            onClick={() => navigate('/purchase-sampling/shipment')}
          >
            Create
          </Button>
        )
      }
    >
      <Tabs>
        <TabPane tab="No Barge" key="1">
          <PreshipmentReport />
        </TabPane>
        <TabPane tab="Barge" key="2">
          <PreshipmentReport />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default PreShipment;
