import { AllocatedTypeEnum, BargeBusinessRequest, BargeMapppingUpdateDto, BargeStatusEnum, BusinessNoBargeMappingDto, BusinessNumberService } from "@exportx/shared-models-and-services";
import { SequenceUtils } from "@exportx/ui-utils";
import { Button, Card, Col, Descriptions, Drawer, Form, Input, Popover, Row, Select, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { AlertMessages } from "../../../../../common";
import DatePicker from "../../../../../common/data-picker/date-picker";
import BMSupplierLevelSummery, { IBMSupplierLevelSummery } from "./barge-mapping-supplier-level-summery";
import { MapBarges } from "./map-barges";
import { EditFilled, MessageFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faXmark } from "@fortawesome/free-solid-svg-icons";


const { Option } = Select;

interface IBargeDetailsProps {
    businessNo?: string;
    setFeaturesRefName?: React.Dispatch<React.SetStateAction<string>>
    setCommentsTitle?: React.Dispatch<React.SetStateAction<string>>
    setCommentsVisible?: React.Dispatch<React.SetStateAction<boolean>>
}

export const BargeMappingView = (props: IBargeDetailsProps) => {
    const { formatMessage: fm } = useIntl();
    //const navigate = useNavigate();
    const { state }: any = useLocation();
    // let { businessNo } = state ? state : { businessNo: '' };
    let { businessNo } = props ? props : state ? state : { businessNo: '' };
    const { setFeaturesRefName, setCommentsTitle, setCommentsVisible } = props

    const [mapVisible, setMapVisible] = useState(false);
    const [gridData, setGridData] = useState<BusinessNoBargeMappingDto[]>([]);
    const [selectedBarges, setSelectedBarges] = useState<BusinessNoBargeMappingDto[]>([]);
    const [supplierSummaryData, setSupplierSummaryData] = useState<IBMSupplierLevelSummery[]>([]);
    const [totalBargeQuantity, setTotalBargeQuantity] = useState<number>(0);
    const [totalRedraftQuantity, setTotalRedraftQuantity] = useState<number>(0)
    const [editData, setEditData] = useState<any>(false);
    const [dummyState, setDummyState] = useState<number>(1);
    const [hatchQty, setHatchQty] = useState<any>();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [editedRowIndex, setEditedRowIndex] = useState(null);
    const [editVisible, setEditVisible] = useState(false);
    const [selectedBargesIds, setSelectedBargeIds] = useState<number[]>([])
    const [searchedText, setSearchedText] = useState('');

    const [bargeMappingForm] = Form.useForm();

    const service = new BusinessNumberService();

    useEffect(() => {
        getAllBargeBusinessMapping(businessNo)
    }, [businessNo]);

    const setSupplierWiseSummaryData = (bargeMappingData: BusinessNoBargeMappingDto[]) => {
        const tableDataSource = [];
        const mapData: Map<string, BusinessNoBargeMappingDto[]> = new Map();
        let totalAllocatedQty = 0;
        let totalRedraftQty = 0;
        bargeMappingData?.forEach(rec => {
            let key = `${rec.supplier}$@$${rec.mineName ? rec.mineName : rec.mine}$@$${rec.purchaseQuality}`;
            if (mapData.has(key)) {
                mapData.get(key).push(rec);
            } else {
                mapData.set(key, [rec]);
            }
        });
        for (const [uniqueKey, uniqueData] of mapData.entries()) {
            let supplierWiseAllocatedQty = 0;
            let supplierWiseRedraftQty = 0;
            for (const eachData of uniqueData) {
                let redraftQuantity = 0
                if (eachData.allocationType === AllocatedTypeEnum.FULL) {
                    supplierWiseAllocatedQty += Number(eachData.bargeQuantityInMt);
                    totalAllocatedQty += Number(eachData.bargeQuantityInMt);
                } else {
                    supplierWiseAllocatedQty += Number(eachData.quantity) ? Number(eachData.quantity) : 0;
                    totalAllocatedQty += Number(eachData.quantity) ? Number(eachData.quantity) : 0;
                }

                if (eachData.redraftQuantity === null || Number(eachData.redraftQuantity) === 0) {
                    if (eachData.allocationType === AllocatedTypeEnum.FULL) {
                        redraftQuantity = Number(eachData.bargeQuantityInMt);
                    } else {
                        redraftQuantity = Number(eachData.quantity);
                    }
                } else {
                    redraftQuantity = Number(eachData.redraftQuantity);
                }

                supplierWiseRedraftQty += Number(redraftQuantity);
                totalRedraftQty += Number(redraftQuantity);
            }
            tableDataSource.push({
                bargeQuantityInMt: Number(supplierWiseAllocatedQty.toFixed(3)),
                supplierWiseRedraftQty: Number(supplierWiseRedraftQty.toFixed(3)),
                allocationDisplayId: uniqueData[0]?.allocationDisplayId,
                loadingJetty: uniqueData[0]?.loadingJetty,
                supplier: uniqueData[0]?.supplier,
                purchaseQuality: uniqueData[0]?.purchaseQuality,
                purchaseQualitySpec: uniqueData[0]?.purchaseQualitySpec,
                uniqueKey: uniqueKey
            });
        }
        setSupplierSummaryData(tableDataSource);
        setTotalBargeQuantity(totalAllocatedQty);
        setTotalRedraftQuantity(totalRedraftQty);
    }
    const getAllBargeBusinessMapping = (businessNoId: string) => {
        const req = new BargeBusinessRequest(businessNoId)
        service.getAllBargeBusinessMapping(req).then(res => {
            if (res.status) {
                setGridData(res.data.mappedBargeData);
                setSupplierWiseSummaryData(res.data.mappedBargeData);
                setHatchQty(res.data.totalHatchQty)
                console.log('res.data.mappedBargeData', res.data.mappedBargeData);
            } else {
                setGridData([]);
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message)
        });
    }

    const closeDrawer1 = () => {
        setMapVisible(false);
        setEditVisible(false);
        bargeMappingForm.resetFields();
        setDummyState(prev => prev + 1);
    }

    const openMapBargeData = () => {
        setMapVisible(true);
        setDummyState(prev => prev + 1);
    }

    const rowDataUpdateHAndler = (rowData, index) => {
        const updateData = gridData
        updateData[index] = rowData;
        setEditData(updateData)
    }

    const handleSubmit = () => {
        const req: BargeMapppingUpdateDto[] = []
        editData?.forEach((el) => { req.push(new BargeMapppingUpdateDto(el.id, el.bargeId, el.bargeStatus, el.commenceDischarge, el.completionDischarge, el.loadingDate, el.completionTime, el.norAnchorage)) })
        service.bargeAllocationUpdate(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                setSelectedBarges([]);
                setSelectedBargeIds([]);
                setEditData(false);
                getAllBargeBusinessMapping(businessNo);
                setDummyState(prev => prev + 1);
                setPopoverVisible(false);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
                setPopoverVisible(false);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message)
            setPopoverVisible(false);
        })
    }

    const handleCancel = () => {
        setEditData(false);
        setPopoverVisible(false);
        setEditedRowIndex(null);
    }

    const editBargeData = (index) => {
        setEditData([gridData[index]]);
        setPopoverVisible(true);
        setEditedRowIndex(index);
    }
    const editBargesData = () => {
        setEditData(gridData);
        setEditVisible(true)
    }

    const commentsData = (refName, title) => {
        setCommentsVisible(true);
        setFeaturesRefName(refName);
        setCommentsTitle(title);
    }

    const getFilteredData = (data: any[]) => {
        if (!searchedText) return data;
        return data?.filter((item: any) =>
            Object.values(item)
                .join(' ')
                .toLowerCase()
                .includes(searchedText.toLowerCase())
        );
    };

    //     const onClickSaveAndContinue = () =>{
    //         setSelectedBarges(gridData?.filter((e=> selectedBargesIds.includes(e.bargeId))))
    //    }

    const handleBargeIdChange = (selectedIds) => {
        setSelectedBargeIds(selectedIds);

        const updatedBarges = gridData?.filter((barge) => selectedIds.includes(barge.bargeId));
        setSelectedBarges(updatedBarges);
    };

    const tableColumns: ColumnsType<any> = [
        {
            title: 'Barge Id',
            dataIndex: 'bargeId',
            width: 100,
            fixed: 'left',

            render: (text, record) => {
                const link = `/#/barge-detail-view?barge_id=${record.bargeUid}`;
                return <>
                    <a href={link} className="link" >
                        {SequenceUtils.formatNumberToSpecificLength(record.bargeId)}
                    </a></>
            }
        },
        {
            title: 'Allocation Id',
            dataIndex: 'allocationDisplayId',
            width: 120,
            sorter: (a, b) => {
                const aValue = String(a.allocationDisplayId);
                const bValue = String(b.allocationDisplayId);
                return aValue.localeCompare(bValue);
            },
            sortDirections: ['ascend', 'descend'],
            onFilter: (value, record) => {
                return (
                    String(record.bargeId)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.allocationDisplayId)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.bargeNomination)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.loadingJetty)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.supplier)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.mine)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.purchaseQuality)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.quantity)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.redraftQuantity)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.loadingDate)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.completionTime)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.norAnchorage)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.commenceDischarge)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.completionDischarge)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||

                    String(record.bargeStatus)
                        .toLowerCase()
                        .includes(value.toLocaleString()))
            },
        },
        {
            title: 'Barge Nomination',
            dataIndex: 'bargeNomination',
            width: 160,
        },
        {
            title: 'Jetty',
            dataIndex: 'loadingJetty',
            width: 160,
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier',
            width: 160,
        },
        {
            title: 'Mine',
            dataIndex: 'mine',
            width: 160,
        },
        {
            // title: 'Purchase Quality',
            title: ' Quality',
            dataIndex: 'purchaseQuality',
            width: 160,
            render: (value, record: any) => {
                return (
                    <> {value} <span className="quality-style">{record.purchaseQualitySpec}</span></>
                )
            }
        },
        {
            title: 'Barge Status',
            dataIndex: 'bargeStatus',
            width: 200,
            // render: (text, record, index) => {
            //     return <>
            //         {editData ?
            //             <Select
            //                 showSearch
            //                 optionFilterProp="children"
            //                 onChange={(value) => { record.bargeStatus = value; rowDataUpdateHAndler(record, index) }}
            //                 allowClear
            //                 defaultValue={record.bargeStatus}
            //                 style={{ width: '180px' }}
            //                 placeholder='Please Select'>
            //                 {Object.keys(BargeStatusEnum).map(key => {
            //                     return <Option value={BargeStatusEnum[key]}>{BargeStatusEnum[key]}</Option>
            //                 })}
            //             </Select>
            //             : text ? <span style={{color: '#94A3B8'}}><FontAwesomeIcon icon={faCircle} size="xs" style={{marginRight: '4px'}}/>{text}</span> : ''}</>
            // }
            render: (value, record) => {
                return value ? <span style={{ color: '#94A3B8' }}><FontAwesomeIcon icon={faCircle} size="xs" style={{ marginRight: '4px' }} />{value}</span> : ''
            }
        },
        {
            title: 'Quantity',
            children: [
                {
                    title: 'Barge(MT)',
                    dataIndex: 'quantity',
                    align: 'right',
                    width: 120,
                    render: (value, record, index) => {
                        let qty = Number(record.quantity) ? Number(record.quantity)?.toFixed(3) : Number(record.bargeQuantityInMt)?.toFixed(3);
                        return <>{qty}</>
                    },
                },
                {
                    title: 'Redraft(MT)',
                    dataIndex: 'redraftQuantity',
                    align: 'right',
                    width: 120,
                    render: (value, record, index) => {
                        let qty = Number(record.redraftQuantity) ? Number(record.redraftQuantity).toFixed(3) : Number(0).toFixed(3);
                        return <>{Number(qty) !== 0 ? qty : 0}</>;
                    },
                },
            ]
        },
        {
            title: 'Loading Date by Jetty',
            children: [
                {
                    title: 'Start',
                    dataIndex: 'loadingDate',
                    width: 100,
                    // render: (text, record, index) => {
                    //     const formattedDate = record.loadingDate
                    //         ? moment(record.loadingDate).format('DD\u2011MMM\u2011YYYY')
                    //         : '';

                    //     return editData ? (
                    //         <DatePicker
                    //             defaultValue={record.loadingDate ? moment(record.loadingDate) : null}
                    //             onChange={(value) => {
                    //                 if (moment(value).isValid()) {
                    //                     record.loadingDate = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                    //                     rowDataUpdateHAndler(record, index);
                    //                 }
                    //             }}
                    //             showTime
                    //             format="YYYY-MM-DD HH:mm:ss"
                    //             placeholder="Select Date & time"
                    //             autoComplete="off"
                    //             allowClear
                    //         />
                    //     ) : (
                    //         <span>{formattedDate}</span>
                    //     );
                    // },
                    render: (value, record) => {
                        return value ? moment(value).format('DD\u2011MMM\u2011YYYY') : ''
                    }
                },



                {
                    title: 'Completion',
                    dataIndex: 'completionTime',
                    width: 100,
                    // render: (text, record, index) => {
                    //     const formattedDate = record.completionTime
                    //         ? moment(record.completionTime).format('DD\u2011MMM\u2011YYYY')
                    //         : '';

                    //     return editData ? (
                    //         <DatePicker
                    //             defaultValue={record.completionTime ? moment(record.completionTime) : null}
                    //             onChange={(value) => {
                    //                 if (moment(value).isValid()) {
                    //                     record.completionTime = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                    //                     rowDataUpdateHAndler(record, index);
                    //                 }
                    //             }}
                    //             showTime
                    //             format="YYYY-MM-DD HH:mm:ss"
                    //             placeholder="Select Date & time"
                    //             autoComplete="off"
                    //             allowClear
                    //         />
                    //     ) : (
                    //         <span>{formattedDate}</span>
                    //     );
                    // },
                    render: (value, record) => {
                        return value ? moment(value).format('DD\u2011MMM\u2011YYYY') : ''
                    }
                },
            ]
        },
        {
            title: 'Anchorage',
            children: [
                {
                    title: 'Nor',
                    dataIndex: 'norAnchorage',
                    width: 100,
                    // render: (text, record, index) => {
                    //     const formattedDate = record.norAnchorage
                    //         ? moment(record.norAnchorage).format('DD\u2011MMM\u2011YYYY')
                    //         : '';

                    //     return editData ? (
                    //         <DatePicker
                    //             defaultValue={record.norAnchorage ? moment(record.norAnchorage) : null}
                    //             onChange={(value) => {
                    //                 if (moment(value).isValid()) {
                    //                     record.norAnchorage = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                    //                     rowDataUpdateHAndler(record, index);
                    //                 }
                    //             }}
                    //             showTime
                    //             format="YYYY-MM-DD HH:mm:ss"
                    //             placeholder="Select Date & time"
                    //             autoComplete="off"
                    //             allowClear
                    //         />
                    //     ) : (
                    //         <span>{formattedDate}</span>
                    //     );
                    // },
                    render: (value, record) => {
                        return value ? moment(value).format('DD\u2011MMM\u2011YYYY') : ''
                    }
                },
                {
                    title: 'Commence',
                    dataIndex: 'commenceDischarge',
                    width: 100,
                    // render: (text, record, index) => {
                    //     const formattedDate = record.commenceDischarge
                    //         ? moment(record.commenceDischarge).format('DD\u2011MMM\u2011YYYY')
                    //         : '';

                    //     return editData ? (
                    //         <DatePicker
                    //             defaultValue={record.commenceDischarge ? moment(record.commenceDischarge) : null}
                    //             onChange={(value) => {
                    //                 if (moment(value).isValid()) {
                    //                     record.commenceDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                    //                     rowDataUpdateHAndler(record, index);
                    //                 }
                    //             }}
                    //             showTime
                    //             format="YYYY-MM-DD HH:mm:ss"
                    //             placeholder="Select Date & time"
                    //             autoComplete="off"
                    //             allowClear
                    //         />
                    //     ) : (
                    //         <span>{formattedDate}</span>
                    //     );
                    // },
                    render: (value, record) => {
                        return value ? moment(value).format('DD\u2011MMM\u2011YYYY') : ''
                    }
                },

                {
                    title: 'Completed',
                    dataIndex: 'completionDischarge',
                    width: 100,
                    // render: (text, record, index) => {
                    //     const formattedDate = record.completionDischarge
                    //         ? moment(record.completionDischarge).format('DD\u2011MMM\u2011YYYY')
                    //         : '';

                    //     return editData ? (
                    //         <DatePicker
                    //             defaultValue={record.completionDischarge ? moment(record.completionDischarge) : null}
                    //             onChange={(value) => {
                    //                 if (moment(value).isValid()) {
                    //                     record.completionDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                    //                     rowDataUpdateHAndler(record, index);
                    //                 }
                    //             }}
                    //             showTime
                    //             format="YYYY-MM-DD HH:mm:ss"
                    //             placeholder="Select Date & time"
                    //             autoComplete="off"
                    //             allowClear
                    //         />
                    //     ) : (
                    //         <span>{formattedDate}</span>
                    //     );
                    // },
                    render: (value, record) => {
                        return value ? moment(value).format('DD\u2011MMM\u2011YYYY') : ''
                    }
                }
            ]
        },
        {
            title: 'Action',
            fixed: 'right',
            width: 70,
            render: (value, record, index) => {
                return <>
                    <EditFilled type="edit" className='action-icons' onClick={() => editBargeData(index)} />
                    {editedRowIndex === index &&
                        <Popover
                            visible={popoverVisible}
                            onVisibleChange={() => setPopoverVisible(true)}
                            title={<span className='popover-title'>Edit Barge Details</span>}
                            placement="bottom"
                            content={<>
                                <Descriptions colon={false} layout="vertical" style={{ width: 360 }} column={{ xl: 2, lg: 2, md: 2, sm: 2 }}>
                                    <Descriptions.Item label="Barge Status">
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(value) => { record.bargeStatus = value; rowDataUpdateHAndler(record, index) }}
                                            allowClear
                                            defaultValue={record.bargeStatus}
                                            style={{ width: '171.5px' }}
                                            placeholder='Please Select'>
                                            {Object.keys(BargeStatusEnum).map(key => {
                                                return <Option value={BargeStatusEnum[key]}>{BargeStatusEnum[key]}</Option>
                                            })}
                                        </Select>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Nor Anchorage">
                                        <DatePicker
                                            defaultValue={record.norAnchorage ? moment(record.norAnchorage) : null}
                                            onChange={(value) => {
                                                if (moment(value).isValid()) {
                                                    record.norAnchorage = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                                    rowDataUpdateHAndler(record, index);
                                                }
                                            }}
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder="Select Date & time"
                                            autoComplete="off"
                                            allowClear
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions colon={false} layout="vertical" style={{ width: 360 }} column={{ xl: 2, lg: 2, md: 2, sm: 2 }}>
                                    <Descriptions.Item label="Commence">
                                        <DatePicker
                                            defaultValue={record.commenceDischarge ? moment(record.commenceDischarge) : null}
                                            onChange={(value) => {
                                                if (moment(value).isValid()) {
                                                    record.commenceDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                                    rowDataUpdateHAndler(record, index);
                                                }
                                            }}
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder="Select Date & time"
                                            autoComplete="off"
                                            allowClear
                                        />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Completed">
                                        <DatePicker
                                            defaultValue={record.completionDischarge ? moment(record.completionDischarge) : null}
                                            onChange={(value) => {
                                                if (moment(value).isValid()) {
                                                    record.completionDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                                    rowDataUpdateHAndler(record, index);
                                                }
                                            }}
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder="Select Date & time"
                                            autoComplete="off"
                                            allowClear
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                                <Row justify='end' style={{ margin: '16px 10px 0px 0px' }}>
                                    <Space>
                                        <Button type='link' className='link' onClick={handleCancel}>Cancel</Button>
                                        <Button className="new-button" onClick={handleSubmit}>Update</Button>
                                    </Space>
                                </Row>
                            </>}
                        >
                        </Popover>
                    }
                </>
            }
        }
        // {
        //     title: 'Barge Quantity',
        //     dataIndex: 'quantity',
        //     width: 160,
        //     render: (value, record, index) => {
        //         let qty = Number(record.quantity) ? Number(record.quantity)?.toFixed(3) : Number(record.bargeQuantityInMt)?.toFixed(3);
        //         return <>{qty}</>
        //     },
        // },
        // {
        //     title: 'Redraft Quantity',
        //     dataIndex: 'redraftQuantity',
        //     width: 160,
        //     render: (value, record, index) => {
        //         let qty = Number(record.redraftQuantity) ? Number(record.redraftQuantity).toFixed(3) : Number(0).toFixed(3);
        //         return <>{Number(qty) !== 0 ? qty : 0}</>;
        //     },
        // },

        // {
        //     title: 'Barge Status',
        //     dataIndex: 'bargeStatus',
        //     width: 200,
        //     render: (text, record, index) => {
        //         return <>
        //             {editData ?
        //                 <Select
        //                     showSearch
        //                     optionFilterProp="children"
        //                     onChange={(value) => { record.bargeStatus = value; rowDataUpdateHAndler(record, index) }}
        //                     allowClear
        //                     defaultValue={record.bargeStatus}
        //                     style={{ width: '180px' }}
        //                     placeholder='Please Select'>
        //                     {Object.keys(BargeStatusEnum).map(key => {
        //                         return <Option value={BargeStatusEnum[key]}>{BargeStatusEnum[key]}</Option>
        //                     })}
        //                 </Select>
        //                 : text}</>
        //     }
        // },

        // {
        //     title: 'Loading Start Date By Jetty',
        //     dataIndex: 'loadingDate',
        //     width: 160,
        //     render: (text, record, index) => {
        //         const formattedDate = record.loadingDate
        //             ? moment(record.loadingDate).format('DD-MMM-YYYY')
        //             : '';

        //         return editData ? (
        //             <DatePicker
        //                 defaultValue={record.loadingDate ? moment(record.loadingDate) : null}
        //                 onChange={(value) => {
        //                     if (moment(value).isValid()) {
        //                         record.loadingDate = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
        //                         rowDataUpdateHAndler(record, index);
        //                     }
        //                 }}
        //                 showTime
        //                 format="YYYY-MM-DD HH:mm:ss"
        //                 placeholder="Select Date & time"
        //                 autoComplete="off"
        //                 allowClear
        //             />
        //         ) : (
        //             <span>{formattedDate}</span>
        //         );
        //     },
        // },



        // {
        //     title: 'Loading Completion Time at Jetty',
        //     dataIndex: 'completionTime',
        //     width: 160,
        //     render: (text, record, index) => {
        //         const formattedDate = record.completionTime
        //             ? moment(record.completionTime).format('DD-MMM-YYYY')
        //             : '';

        //         return editData ? (
        //             <DatePicker
        //                 defaultValue={record.completionTime ? moment(record.completionTime) : null}
        //                 onChange={(value) => {
        //                     if (moment(value).isValid()) {
        //                         record.completionTime = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
        //                         rowDataUpdateHAndler(record, index);
        //                     }
        //                 }}
        //                 showTime
        //                 format="YYYY-MM-DD HH:mm:ss"
        //                 placeholder="Select Date & time"
        //                 autoComplete="off"
        //                 allowClear
        //             />
        //         ) : (
        //             <span>{formattedDate}</span>
        //         );
        //     },
        // },
        // {
        //     title: 'Nor Anchorage',
        //     dataIndex: 'norAnchorage',
        //     width: 160,
        //     render: (text, record, index) => {
        //         const formattedDate = record.norAnchorage
        //             ? moment(record.norAnchorage).format('DD-MMM-YYYY')
        //             : '';

        //         return editData ? (
        //             <DatePicker
        //                 defaultValue={record.norAnchorage ? moment(record.norAnchorage) : null}
        //                 onChange={(value) => {
        //                     if (moment(value).isValid()) {
        //                         record.norAnchorage = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
        //                         rowDataUpdateHAndler(record, index);
        //                     }
        //                 }}
        //                 showTime
        //                 format="YYYY-MM-DD HH:mm:ss"
        //                 placeholder="Select Date & time"
        //                 autoComplete="off"
        //                 allowClear
        //             />
        //         ) : (
        //             <span>{formattedDate}</span>
        //         );
        //     },
        // },
        // {
        //     title: 'Commence Discharging',
        //     dataIndex: 'commenceDischarge',
        //     width: 160,
        //     render: (text, record, index) => {
        //         const formattedDate = record.commenceDischarge
        //             ? moment(record.commenceDischarge).format('DD-MMM-YYYY')
        //             : '';

        //         return editData ? (
        //             <DatePicker
        //                 defaultValue={record.commenceDischarge ? moment(record.commenceDischarge) : null}
        //                 onChange={(value) => {
        //                     if (moment(value).isValid()) {
        //                         record.commenceDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
        //                         rowDataUpdateHAndler(record, index);
        //                     }
        //                 }}
        //                 showTime
        //                 format="YYYY-MM-DD HH:mm:ss"
        //                 placeholder="Select Date & time"
        //                 autoComplete="off"
        //                 allowClear
        //             />
        //         ) : (
        //             <span>{formattedDate}</span>
        //         );
        //     },
        // },

        // {
        //     title: 'Completed Discharging',
        //     dataIndex: 'completionDischarge',
        //     width: 160,
        //     render: (text, record, index) => {
        //         const formattedDate = record.completionDischarge
        //             ? moment(record.completionDischarge).format('DD-MMM-YYYY')
        //             : '';

        //         return editData ? (
        //             <DatePicker
        //                 defaultValue={record.completionDischarge ? moment(record.completionDischarge) : null}
        //                 onChange={(value) => {
        //                     if (moment(value).isValid()) {
        //                         record.completionDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
        //                         rowDataUpdateHAndler(record, index);
        //                     }
        //                 }}
        //                 showTime
        //                 format="YYYY-MM-DD HH:mm:ss"
        //                 placeholder="Select Date & time"
        //                 autoComplete="off"
        //                 allowClear
        //             />
        //         ) : (
        //             <span>{formattedDate}</span>
        //         );
        //     },
        // }
    ]

    const childRef = useRef(null);

    // Function to call the child's function
    const handleClick = () => {
        if (childRef?.current) {
            childRef.current?.bargeMappingFormSubmit();
        }
    };

    return (
        <>

            <Row className='card-title-buttons mb-12' justify="space-between" >
                <Col>
                    <Input.Search placeholder="Search" allowClear className='input-search' onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} />
                </Col>
                <Col>
                    <Space size={'middle'}>
                        <Button className="new-button" onClick={() => openMapBargeData()}>Map Barges</Button>
                        <EditFilled type="edit"
                            className='action-icons'
                            onClick={() => editBargesData()}
                        />
                    </Space>
                </Col>
            </Row>

            <Row gutter={[12, 12]} className='mb-12' >
                <Col lg={{ span: 4 }} md={{ span: 12 }} xs={{ span: 24 }}>
                    <Card className='card-br-8 mv-cards' bordered={false}>
                        <Descriptions colon={false} layout='vertical' className='descriptions'>
                            <Descriptions.Item label='Stowage Plan'>
                                <><span className="font-14">{hatchQty ? Number(hatchQty).toFixed(3) : 0}</span>&nbsp;<span className="font-10">MT</span></>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 12 }} xs={{ span: 24 }}>
                    <Card className='card-br-8 mv-cards' bordered={false}>
                        <Descriptions colon={false} layout='vertical' className='descriptions'>
                            <Descriptions.Item label='Loaded Quantity'>
                                <><span className="font-14">{totalBargeQuantity?.toFixed(3)}</span>&nbsp;<span className="font-10">MT</span></>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 12 }} xs={{ span: 24 }}>
                    <Card className='card-br-8 mv-cards' bordered={false}>
                        <Descriptions colon={false} layout='vertical' className='descriptions'>
                            <Descriptions.Item label='Redraft Quantity'>
                                <><span className="font-14">{totalRedraftQuantity?.toFixed(3)}</span>&nbsp;<span className="font-10">MT</span></>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 12 }} xs={{ span: 24 }}>
                    <Card className='card-br-8 mv-cards' bordered={false}>
                        <Descriptions colon={false} layout='vertical' className='descriptions'>
                            <Descriptions.Item label='Remaining Quantity'>
                                <><span className="font-14">{hatchQty ? (Number(hatchQty) - Number(totalBargeQuantity)).toFixed(3) : 0}</span>&nbsp;<span className="font-10">MT</span></>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>

            <Card title={<span className="card-title-md">Mapped Barges</span>}
                extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Barge Mapping > Mapped Barges', 'Mapped Barges')} /></Tooltip>}
                headStyle={{ border: 'none' }}
                className="contracts-list card-br-8 mb-12"

            >
                <Table
                    rowKey={(row) => row.id}
                    columns={tableColumns}
                    dataSource={getFilteredData(gridData)}
                    scroll={{ x: 500 }}
                    pagination={false}
                />
            </Card>
            <Row gutter={[12, 12]}>
                <Col lg={{ span: 16 }} md={{ span: 24 }} sm={{ span: 24 }}>
                    <Card title={<span className="card-title-md">Group by Quality</span>}
                        extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Barge Mapping > Group by Quality', 'Group by Quality')} /></Tooltip>}
                        headStyle={{ border: 'none' }}
                        className="contracts-list card-br-8 full-width"
                    >
                        <BMSupplierLevelSummery supplierSummaryData={getFilteredData(supplierSummaryData)} />
                    </Card>
                </Col>
            </Row>
            {/* <br />
                <Row>
                    {editData && <Col>
                        <Space>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button className="new-button" onClick={handleSubmit}>Save</Button>
                        </Space>
                    </Col>}
                </Row>
            <br /> */}


            {/* <Card title={'Barge Data'}>
            <Card>
                <Descriptions column={{ xxl: 5, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
                    <Descriptions.Item label={'Total Barge Quantity'}>{totalBargeQuantity?.toFixed(3)}</Descriptions.Item>
                    <Descriptions.Item label={'Total Redraft Quantity'}>{totalRedraftQuantity?.toFixed(3)}</Descriptions.Item>
                </Descriptions>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button type="primary" onClick={() => openMapBargeData()}>Map Barges</Button>
                            <Button type="primary" onClick={() => editBargeData()}> Edit Barges</Button>
                        </Space>
                    </Col>
                </Row>
                <br></br>
                <Table
                    rowKey={(row) => row.id}
                    className='contracts'
                    columns={tableColumns}
                    dataSource={gridData}
                    pagination={false}
                    scroll={{ x: 500 }}
                    size="large"
                    bordered
                />
                <br />
                <Row>
                    {editData && <Col>
                        <Space>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button type="primary" onClick={handleSubmit}>Save</Button>
                        </Space>
                    </Col>}
                </Row>
                <br />
                <br />
                <BMSupplierLevelSummery supplierSummaryData={supplierSummaryData} />
            </Card>
                <Drawer
                    bodyStyle={{ paddingBottom: 80 }}
                    width={1000}
                    onClose={closeDrawer1}
                    open={mapVisible}
                    closable={true} key={dummyState} >
                    <MapBarges setDummyState={setDummyState} bargeMappingForm={bargeMappingForm} getAllBargeBusinessMapping={getAllBargeBusinessMapping} closeDrawer1={closeDrawer1} businessNo={businessNo} />
                </Drawer>
        </Card> */}
            {/* <Drawer
                    bodyStyle={{ paddingBottom: 80 }}
                    width={1000}
                    onClose={closeDrawer1}
                    open={mapVisible}
                    closable={true} key={dummyState} >
                    <MapBarges setDummyState={setDummyState} bargeMappingForm={bargeMappingForm} getAllBargeBusinessMapping={getAllBargeBusinessMapping} closeDrawer1={closeDrawer1} businessNo={businessNo} />
                </Drawer> */}
            <Drawer
                bodyStyle={{ paddingBottom: 80 }}
                width={800}
                onClose={closeDrawer1}
                open={mapVisible}
                closable={false}
                key={dummyState}
                title={<span className='drawer-title'>Map Barges</span>}
                extra={<Button className='xmark-icon' icon={<FontAwesomeIcon icon={faXmark} />} onClick={closeDrawer1}></Button>}
                footer={
                    <div className='drawer-footer-div'>
                        <Button type='link' className='link' onClick={closeDrawer1} >Cancel</Button>
                        <Button className='new-button' onClick={handleClick} >Map</Button>

                    </div>
                }
            >
                <MapBarges
                    setDummyState={setDummyState}
                    bargeMappingForm={bargeMappingForm}
                    getAllBargeBusinessMapping={getAllBargeBusinessMapping}
                    closeDrawer1={closeDrawer1}
                    businessNo={businessNo}
                    ref={childRef}
                />
            </Drawer>

            <Drawer
                width={800}
                open={editVisible}
                onClose={closeDrawer1}
                closable={false}
                title={<span className='drawer-title'>Edit Barges</span>}
                extra={<Button className='xmark-icon' icon={<FontAwesomeIcon icon={faXmark} />} onClick={closeDrawer1}></Button>}
                footer={
                    <div className='drawer-footer-div'>
                        <Button type='link' className='link' onClick={closeDrawer1} >Cancel</Button>
                        <Button className='new-button' disabled={!selectedBarges.length} onClick={handleSubmit} >Update</Button>

                    </div>
                }
            >
                <Row className="mb-12">
                    <span style={{ color: '#64748B' }}>Edit multiple barges at same time.</span>
                </Row>

                <Card className="card-br-8 mb-12">
                    <Descriptions colon={false} layout="vertical">
                        <Descriptions.Item label="Barge ID">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                mode="multiple"
                                style={{ width: '300px' }}
                                onChange={(value) => handleBargeIdChange(value)}
                                placeholder='Select'
                                value={selectedBargesIds}
                            >
                                {gridData?.map((item) => (
                                    <Option key={item.bargeId} value={item.bargeId}>
                                        {SequenceUtils.formatNumberToSpecificLength(String(item.bargeId))}
                                    </Option>
                                ))}
                            </Select>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
                {/* <Button className='new-button mb-12' disabled={!selectedBargesIds.length} onClick={onClickSaveAndContinue} >Next</Button> */}

                {selectedBarges?.map((barge, index) => (
                    <Card className="card-br-8 mb-12" key={barge.bargeId}>
                        <Descriptions style={{ fontWeight: 600 }}>
                            <Row>
                                <Col span={4}> <Descriptions.Item>{SequenceUtils.formatNumberToSpecificLength(String(barge.bargeId))}</Descriptions.Item> </Col>
                                <Col span={10}> <Descriptions.Item>{barge.bargeNomination}</Descriptions.Item> </Col>
                                <Col span={10}> <Descriptions.Item>{barge.bargeQuantityInMt}</Descriptions.Item>  </Col>
                            </Row>
                        </Descriptions>
                        <Descriptions colon={false} layout="vertical" column={{ xl: 4, lg: 4, md: 4, sm: 4 }}>
                            <Descriptions.Item label="Barge Status">
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => { barge.bargeStatus = value; rowDataUpdateHAndler(barge, index) }}
                                    allowClear
                                    defaultValue={barge.bargeStatus}
                                    style={{ width: '171.5px' }}
                                    placeholder='Please Select'>
                                    {Object.keys(BargeStatusEnum).map(key => {
                                        return <Option value={BargeStatusEnum[key]}>{BargeStatusEnum[key]}</Option>
                                    })}
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label="Nor Anchorage">
                                <DatePicker
                                    defaultValue={barge.norAnchorage ? moment(barge.norAnchorage) : null}
                                    onChange={(value) => {
                                        if (moment(value).isValid()) {
                                            barge.norAnchorage = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                            rowDataUpdateHAndler(barge, index);
                                        }
                                    }}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    placeholder="Select Date & time"
                                    autoComplete="off"
                                    allowClear
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Commence">
                                <DatePicker
                                    defaultValue={barge.commenceDischarge ? moment(barge.commenceDischarge) : null}
                                    onChange={(value) => {
                                        if (moment(value).isValid()) {
                                            barge.commenceDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                            rowDataUpdateHAndler(barge, index);
                                        }
                                    }}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    placeholder="Select Date & time"
                                    autoComplete="off"
                                    allowClear
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Completed">
                                <DatePicker
                                    defaultValue={barge.completionDischarge ? moment(barge.completionDischarge) : null}
                                    onChange={(value) => {
                                        if (moment(value).isValid()) {
                                            barge.completionDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                            rowDataUpdateHAndler(barge, index);
                                        }
                                    }}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    placeholder="Select Date & time"
                                    autoComplete="off"
                                    allowClear
                                />
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                ))}
            </Drawer>
        </>
    )
}