import { EditFilled, UploadOutlined, } from '@ant-design/icons';
import { BargeService, BusinessNumberService, LcTrackerEnum, PlantCodeReqDto } from '@exportx/shared-models-and-services';
import { Button, Card, Divider, Form, Input, Select, Table, Tooltip, Upload } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';
import { SequenceUtils, getBase64 } from '@exportx/ui-utils';
import ViewFileHandling from '../../../../common/file-upload-form/view-files-handling';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const ScLcTracker = () => {

    const [gridData, setGridData] = useState<any>(false);
    const bargeService = new BargeService()
    const { authContext } = useAuthState()
    const [formRef] = Form.useForm()
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState<any>([])
    const [indexes, setIndex] = useState<any>()
    const [searchedText, setSearchedText] = useState("")
    const [lcSwiftFile, setLcSwiftFile] = useState<any>([]);
    const [amendment1File, setAmendment1File] = useState<any>([]);
    const [amendment2File, setAmendment2File] = useState<any>([]);
    const [amendment3File, setAmendment3File] = useState<any>([]);
    const businessNumberService = new BusinessNumberService()


    useEffect(() => {
        if(!isPermissionExist([779])) {
            return navigate('/')
        }
    }, [])

    useEffect(() => {
        lcTrackerGetData()
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])


    const onLcSWiftFileChange = (data) => {
        setLcSwiftFile([data.file]);
    };

    const OnAmendment1FileChange = (data) => {
        setAmendment1File([data.file])
    }

    const OnAmendment2FileChange = (data) => {
        setAmendment2File([data.file])
    }

    const OnAmendment3FileChange = (data) => {
        setAmendment3File([data.file])
    }

    const lcTrackerGetData = () => {
        const req = new PlantCodeReqDto(authContext.defaultPlant)
        businessNumberService.getScLcTrackerData(req).then(res => {
            if (res.status) {
                setGridData(res.data);
            }
            setIndex(null);

        }).catch(err => {
            console.log(err.message)
        })
    }

    const submit = (record, index) => {
        formRef.validateFields().then(async values => {
            console.log(values, "VALUES")

            const formFilesData = values?.lcSwift?.[index]?.file;
            let lcSwift = [];
            if (formFilesData) {
                const base64 = await getBase64(formFilesData.originFileObj);
                lcSwift.push(
                    {
                        ...formFilesData,
                        base64Url: base64,
                        fileDescription: 'lcSwift file',
                        //fileUploadId: initialValues?.lcSwift[index]?.fileUploadId
                    }
                )
            };
            record.lcSwift = lcSwift

            const formFilesData11 = values?.amendment1?.[index]?.file;
            let amendment1 = [];
            if (formFilesData11) {
                const base64 = await getBase64(formFilesData11.originFileObj);
                amendment1.push(
                    {
                        ...formFilesData11,
                        base64Url: base64,
                        fileDescription: 'amendment1 file',
                        //fileUploadId: initialValues?.amendment1[index]?.fileUploadId
                    }
                )
            };
            record.amendment1 = amendment1

            const formFilesData12 = values?.amendment2?.[index]?.file;
            let amendment2 = [];
            if (formFilesData12) {
                const base64 = await getBase64(formFilesData12.originFileObj);
                amendment2.push(
                    {
                        ...formFilesData12,
                        base64Url: base64,
                        fileDescription: 'amendment2 file',
                        //fileUploadId: initialValues?.amendment2[index]?.fileUploadId
                    }
                )
            };
            record.amendment2 = amendment2


            const formFilesData13 = values?.amendment3?.[index]?.file;
            let amendment3 = [];
            if (formFilesData13) {
                const base64 = await getBase64(formFilesData13.originFileObj);
                amendment3.push(
                    {
                        ...formFilesData13,
                        base64Url: base64,
                        fileDescription: 'amendment3 file',
                        //fileUploadId: initialValues?.amendment3[index]?.fileUploadId
                    }
                )
            };
            record.amendment3 = amendment3

            businessNumberService.updateScLcTracker({ ...record, plantCode: authContext.defaultPlant, userName: authContext.user.userName }).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    setIndex(undefined)
                    lcTrackerGetData();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage)
                }
            }).catch(err => {
                console.log(err.message)
            })

        })
    }

    const columns: any = [
        {
            title: 'Barge Name',
            dataIndex: 'bargeId',
            width: '100px',
            align: 'center',
            render: (value, record) => {
                const link = `/#/barge-detail-view?barge_id=${record.id}`
                return <>
                    {/* {record?.purchaseType === "FOB BARGE" ? value : ""} */}
                    <a href={link}>{SequenceUtils.formatNumberToSpecificLength(value) + " " + `(${record.bargeNomination})`}</a>
                </>
            },
            sorter: (a, b) => a.bargeId !== null ? a.bargeId.localeCompare(b.bargeId) : "Have Null Values",
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                const aaa = new Set(Object.keys(record).map((key) => {
                    return String(record[key]).toLowerCase().includes(value.toLocaleString())
                }))
                if (aaa.size && aaa.has(true))
                    return true;
                else
                    return false;
            },
        },

        {
            title: 'Business Number',
            dataIndex: 'bargeBusinessNumbers',
            width: '130px',
            align: 'center',
            // onCell:(record)=>,
            render: (text) => {
                return <>
                    {text.map((rec, index) => {
                        const link = `/#/bn-detail-view?bn_Id=${rec.bnId}`
                        const removeHr = index === text.length - 1 ? '' : <hr style={{ marginTop: '0rem', marginBottom: '0rem' }}></hr>
                        return <a href={link} className="link-primary" >{rec.businessNo}{removeHr}</a>
                    })}
                </>
            }
        },

        {
            title: 'Contract Number',
            dataIndex: 'contractNo',
            width: '180px',
            align: 'center',
            render: (text, record, index) => {
                const link = `/#/po-detail-view?pc_id=${record.pcContractId}`
                return <>
                    <a href={link} className="link-primary" >
                        {record.contractNo}
                    </a></>
            }
        },
        {
            title: 'Quantity',
            dataIndex: 'quantityInMt',
            width: '80px',
            align: 'center',

        },
        {
            title: ' Incoterms',
            dataIndex: 'salesType',
            width: '100px',
            align: 'center',
        },
        {
            title: 'Specs',
            dataIndex: 'purchasingTerm',
            width: '80px',
            align: 'center',
        },
        {
            title: 'Lc Number',
            dataIndex: 'lcNumber',
            width: '120px',
            align: 'center',
            render: (name, record, index) => {
                return <>
                    {index === indexes ? <Input
                        key={index}
                        defaultValue={name}
                        onChange={(e) => record.lcNumber = Number(e.target.value)}
                        placeholder='Lc Number'
                    >
                    </Input> : name}
                </>
            }
        },
        {
            title: 'Lc Amount',
            dataIndex: 'lcAmount',
            width: '120px',
            align: 'center',
            render: (name, record, index) => {
                return <>

                    {index === indexes ? <Input key={index} defaultValue={name} onChange={(e) => record.lcAmount = Number(e.target.value)} placeholder='Lc Amount' ></Input> : name}
                </>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '130px',
            align: 'center',
            render: (name, record, index) => {
                return <>
                    {index === indexes ? <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch key={index} defaultValue={name} onChange={(value) => record.status = value} optionFilterProp="children" style={{ width: '120px' }} placeholder='Please Select'> {Object.keys(LcTrackerEnum).map(key => { return <Option value={LcTrackerEnum[key]}>{LcTrackerEnum[key]}</Option> })}</Select> : name}
                </>
            }

        },
        {
            title: 'Date Of Issuance',
            dataIndex: 'dateOfIssuance',
            width: '140px',
            align: 'center',
            render: (name, record, index) => {
                return <>
                    {index === indexes ? <DatePicker key={index} format="YYYY-MM-DD" defaultValue={name ? moment(name) : moment()} onChange={(e) => record.dateOfIssuance = moment(e)} /> : name ? moment(name).format('YYYY-MM-DD') : ''}
                </>
            }
        },
        {
            title: 'Date Of Expiry',
            dataIndex: 'dateOfExpiry',
            width: '140px',
            align: 'center',
            render: (name, record, index) => {
                return <>
                    {index === indexes ? <DatePicker format="YYYY-MM-DD" key={index} defaultValue={name ? moment(name) : moment()} onChange={(e) => record.dateOfExpiry = moment(e)} /> : name ? moment(name).format('YYYY-MM-DD') : ''}</>
            }
        },
        {
            title: 'Last Shipment Date',
            dataIndex: 'lastShipmentDate',
            width: '140px',
            align: 'center',
            render: (name, record, index) => {
                return <>

                    {index === indexes ?
                        <DatePicker
                            key={index}
                            defaultValue={name ? moment(name) : moment()}
                            onChange={(e) => record.lastShipmentDate = moment(e)}
                        />
                        : name ? moment(name).format('YYYY-MM-DD') : ''}
                </>
            }
        },
        {
            title: 'Lc Swift',
            dataIndex: 'lcSwift',
            width: '140px',
            align: 'center',
            hidden: !isPermissionExist([783]),
            render: (name, record, index) => {
                return <>
                    {index === indexes ?
                        <>
                            <Form.Item name={['lcSwift', index]}>
                                <Upload
                                    multiple={false}
                                    fileList={lcSwiftFile}
                                    customRequest={onLcSWiftFileChange}
                                    onRemove={() => setLcSwiftFile([])}
                                    showUploadList={true}
                                    listType='text'
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>Choose File</Button>
                                </Upload>
                            </Form.Item>
                        </>

                        :
                        <ViewFileHandling filesData={name} required={false} getAllData={lcTrackerGetData} />


                    }
                </>

            }
        },
        {
            title: 'Amendment 1',
            dataIndex: 'amendment1',
            width: '140px',
            align: 'center',
            hidden: !isPermissionExist([783]),
            render: (name, record, index) => {
                return <>
                    {index === indexes ?
                        <>
                            <Form.Item name={['amendment1', index]}>
                                <Upload
                                    multiple={false}
                                    fileList={amendment1File}
                                    customRequest={OnAmendment1FileChange}
                                    onRemove={() => setAmendment1File([])}
                                    showUploadList={true}
                                    listType='text'
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>Choose File</Button>
                                </Upload>
                            </Form.Item>

                        </>

                        : <ViewFileHandling filesData={name} required={false} getAllData={lcTrackerGetData} />

                    }
                </>
            }

        },
        {
            title: 'Amendment 2',
            dataIndex: 'amendment2',
            width: '140px',
            align: 'center',
            hidden: !isPermissionExist([783]),
            render: (name, record, index) => {
                return <>
                    {index === indexes ?
                        <>
                            <Form.Item name={['amendment2', index]}>
                                <Upload
                                    multiple={false}
                                    fileList={amendment2File}
                                    customRequest={OnAmendment2FileChange}
                                    onRemove={() => setAmendment2File([])}
                                    showUploadList={true}
                                    listType='text'
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>Choose File</Button>
                                </Upload>
                            </Form.Item>

                        </>

                        : <ViewFileHandling filesData={name} required={false} getAllData={lcTrackerGetData} />

                    }
                </>
            }
        },
        {
            title: 'Amendment 3',
            dataIndex: 'amendment3',
            width: '140px',
            align: 'center',
            hidden: !isPermissionExist([783]),
            render: (name, record, index) => {
                return <>
                    {index === indexes ?
                        <>
                            <Form.Item name={['amendment3', index]}>
                                <Upload
                                    multiple={false}
                                    fileList={amendment3File}
                                    customRequest={OnAmendment3FileChange}
                                    onRemove={() => setAmendment3File([])}
                                    showUploadList={true}
                                    listType='text'
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>Choose File</Button>
                                </Upload>
                            </Form.Item>

                        </>

                        : <ViewFileHandling filesData={name} required={false} getAllData={lcTrackerGetData} />

                    }
                </>
            }
        },
        {
            title: 'Action',
            width: '150px',
            align: 'center',
            fixed: 'right',
            render: (name, record, index) => {
                return <>
                {isPermissionExist([780]) && (
                    <Tooltip
                        placement="top"
                        title={'Edit'}>
                        {index === indexes ? <Button type='primary' onClick={() => submit(record, index)}>Submit</Button> :
                            <>  <EditFilled
                                onClick={() => setIndex(index)}
                                style={{ fontSize: '20px' }}
                                className={'Editable-Icon'}
                            /> <Divider type='vertical' />  </>}
                    </Tooltip>
                )}
                </>
            }
        }
    ]

    return (
        <div>
            <Card>
                <Form form={formRef} autoComplete='off' initialValues={initialValues}>
                    <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} style={{ width: 200, float: "right" }} />,
                    <br />
                    <br />

                    <Table
                        className='contracts'
                        rowKey={(record) => record.scLcTrackerId}
                        bordered
                        columns={columns}
                        dataSource={gridData}
                        pagination={false}
                        scroll={{ x: 2000 }}
                    />
                </Form>
            </Card>
        </div>)
}

export default ScLcTracker;