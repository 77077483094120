import {
  BargeCostingsService,
  BPFCostingRequestIdDto,
  ContractStatusEnum,
  GetDataDeadFreightDto,
  GetExpApprovalDataDto,
} from '@exportx/shared-models-and-services';
import { getLocalFormat, internationalFormattedValue } from '@exportx/ui-utils';
import {
  Input,
  Card,
  Table,
  Form,
  Row,
  Col,
  List,
  Button,
  Popconfirm,
  Tooltip,
  Flex,
  Space,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../common';
import { SequenceUtils } from '@exportx/ui-utils';
import { PaymentNoticePageEnum } from '../payment-notice';
import { DownCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DeadFreightApprovalDetails } from '../costing-detail-view/dead-freight-details';
import { InvoiceDetails } from '../costing-detail-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';

interface IProps {
  type?: ContractStatusEnum;
  paymentType?: PaymentNoticePageEnum;
}

export const DeadFreightApproval = (props: IProps) => {
  const { type, paymentType } = props;
  const [formRef] = Form.useForm();

  const [gridData, setGridData] = useState<GetDataDeadFreightDto[]>();
  const bargeCostingService = new BargeCostingsService();
  const { authContext } = useAuthState();
  const [deadFreightForm, setDeadFreightForm] = useState<boolean>(false);
  const [searchedText, setSearchedText] = useState('');
  const [expandedIndex, setExpandedIndex] = useState([]);

  useEffect(() => {
    getDeadFreightData();
  }, []);

  const tableColumns: ColumnsType<any> = [
    {
      title: 'Dead Freight Ref #',
      dataIndex: 'costingNo',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
    },
    {
      title: 'Date',
      dataIndex: 'deadFreightDate',
      render: (value, record) => {
        return <>{moment(value)?.format('DD\u2011MMM\u2011YYYY')}</>;
      },
    },

    {
      title: 'MV Name',
      dataIndex: 'businessNo',
      render: (value, record) => {
        const href = `/#/bn-detail-view?bn_Id=${record.bnId}`;
        return (
          <>
            {
              <a href={href} className="link" style={{ fontSize: 12 }}>
                {value}
              </a>
            }
          </>
        );
      },
    },

    {
      title: 'Account Type',
      dataIndex: 'account',
    },
    {
      title: 'Business Partner',
      dataIndex: 'supplierName',
    },

    {
      title: 'Dead Freight Qty',
      dataIndex: 'deadFreight',
      align: 'right',
      render: (value, record) => {
        return <>
        <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">MT</span>
        </>;
      },
    },
    {
      title: 'Price PMT',
      dataIndex: 'pricePerMT',
      align: 'right',
      // width: 40,
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${record.baseCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${record.baseCurrency}`}</span>
          </>
        );
      },
    },
  ];

  const getDeadFreightData = () => {
    const status =
      type === ContractStatusEnum.BUSINESS_APPROVAL
        ? ContractStatusEnum.PENDING_APPROVAL
        : ContractStatusEnum.PENDING_AUDIT_APPROVAL;
    const req = new GetExpApprovalDataDto(
      paymentType,
      authContext.defaultPlant,
      status,
    );
    bargeCostingService
      .getDeadFreightDataForApproval(req)
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.demurrageAccountId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };
  const approveStatus =
    type === ContractStatusEnum.BUSINESS_APPROVAL
      ? ContractStatusEnum.ACTIVE
      : ContractStatusEnum.ACTIVE;

  const approveDeadFreight = (
    costingId: string,
    status: ContractStatusEnum,
  ) => {
    formRef
      .validateFields()
      .then((values) => {
        const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(
          costingId,
          values['remarks'],
          status,
          authContext.user.userName,
          authContext.defaultPlant,
          type,
        );

        console.log('req =>', req);
        bargeCostingService
          .deadFreightApproval2(req)
          .then((res) => {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              getDeadFreightData();
              window.location.reload();
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderItems = (record: any, index, indent, expanded) => {
    return (
      <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }}>
        <DeadFreightApprovalDetails
          costingId={record.bgDeadFreightId}
          remainingAmount={0}
        >
          <Card
            bordered={false}
            className="card-shadow mb-2"
            styles={{ body: { padding: '0px 10px 15px' } }}
          >
            <Form
              form={formRef}
              // initialValues={initialValues}
              layout="vertical"
              autoComplete="off"
            >
              <Row className="p-2">
                <Form.Item
                  style={{ width: '100%' }}
                  name="remarks"
                  label="Remarks"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Work Log', // fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Notes" />
                </Form.Item>
              </Row>
              <Row>
                <Col span={16}>
                  <Row>
                    <Col span={3}>Worklog :</Col>
                    <Col span={20}>
                      {record?.workLog?.length > 0 && (
                        <List
                          bordered
                          dataSource={record?.workLog ? record?.workLog : []}
                          renderItem={(item, index) => (
                            <List.Item key={index} style={{ width: '100%' }}>
                              <div>
                                <span>{index + 1}</span>
                                <span>.</span>&nbsp;{item.workLog}
                              </div>
                            </List.Item>
                          )}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {record.approvalStatus ===
                ContractStatusEnum.PENDING_APPROVAL && (
                <Flex align="middle" justify="flex-end">
                  <Space size={10}>
                    <Button
                      size="small"
                      className="approve"
                      onClick={() =>
                        approveDeadFreight(
                          record?.bgDeadFreightId,
                          approveStatus,
                        )
                      }
                    >
                      {' '}
                      <FontAwesomeIcon icon={faCheckCircle} /> Approve
                    </Button>

                    <Popconfirm
                      onConfirm={(e) => {
                        approveDeadFreight(
                          record?.bgDeadFreightId,
                          ContractStatusEnum.CANCELLED,
                        );
                      }}
                      title={'Are you sure to reject'}
                    >
                      <Tooltip placement="top" title={'Reject'}>
                        <Button size="small" className="reject">
                          {' '}
                          <FontAwesomeIcon icon={faCircleXmark} />
                          Reject
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                </Flex>
              )}
            </Form>
          </Card>
          <InvoiceDetails
            costingId={record.bgDeadFreightId}
            status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
            level={ContractStatusEnum.BUSINESS_APPROVAL}
            invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
            invApproveDisabled={
              record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL
            }
          />
        </DeadFreightApprovalDetails>
      </div>
    );
  };
  return (
    <>
      <Card
        className="card-radius"
        title={
          <Row className="mt-16">
            <Input.Search
              placeholder="Search Orders"
              allowClear
              value={searchedText}
              onChange={(e) => setSearchedText(e.target.value)}
              className="input-search"
            />
          </Row>
        }
      >
        <Table
          // size='small'
          expandIconColumnIndex={0}
          className="contracts-list"
          rowKey={(record) => record.demurrageAccountId}
          columns={tableColumns}
          dataSource={gridData}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right',
          }}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <DownCircleOutlined onClick={(e) => onExpand(record, e)}>
                Collapse
              </DownCircleOutlined>
            ) : (
              <RightCircleOutlined onClick={(e) => onExpand(record, e)}>
                Expand
              </RightCircleOutlined>
            )
          }
          pagination={false}
          scroll={{ x: 500 }}
          bordered={false}
        />
      </Card>
    </>
  );
};
export default DeadFreightApproval;
