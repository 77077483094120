import { useState, useEffect } from 'react';
import './taxes-grid.css';
import { Card, Button, Tabs, Row, Col, Input } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useLocation, useNavigate } from 'react-router-dom';
import { TaxesDto, TaxesService } from '@exportx/shared-models-and-services';
import {
  AlertMessages,
  FilterableGrid,
  isPermissionExist,
} from '../../../../../common';
import { useIntl } from 'react-intl';
import TabPane from 'antd/es/tabs/TabPane';
import { TaxesAndItemsGrid } from '../../taxes-and-items';
import { CompanyTdsGrid } from '../../company-tds';
import { PaymentModesGrid } from '../../payment-modes';
export interface TaxesGridProps {
  // StatesData: StatesDto[];
  //  viewState: (State: StatesDto, viewOnly: boolean) => void;
  // deleteState: (State: StatesDto) => void;
}

export const TaxesGrid = (props: TaxesGridProps) => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const activeTab = state?.activeTab || 'taxes';
  const { formatMessage: fm } = useIntl(); //Common for any Component that might be Page,Grid,Form.....
  const [taxesData, setTaxesData] = useState<TaxesDto[]>([]);
  const [searchedText, setSearchedText] = useState('');

  const service = new TaxesService();

  useEffect(() => {
    if (!isPermissionExist([23, 27, 31, 55])) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    getAllTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllTaxes = () => {
    service
      .getAllTaxes()
      .then((res) => {
        if (res.status) {
          setTaxesData(res.data);
        } else {
          setTaxesData([]);
        }
      })
      .catch((err) => {
        setTaxesData([]);
      });
  };
  /**
   *
   * @param TaxesData
   */
  const deleteTax = (taxesDto: TaxesDto) => {
    taxesDto.isActive = taxesDto.isActive ? false : true;
    service
      .ActivateOrDeactivateTax(taxesDto)
      .then((res) => {
        if (res.status) {
          getAllTaxes();
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: `Taxes ${
                taxesDto.isActive ? ' activated ' : ' deactivated '
              } SuccessFully`,
            }),
          );
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const sampleTypeColumns: ColumnProps<any>[] = [
    {
      title: fm({
        id: 'pms.components.taxesGrid.taxName',
        defaultMessage: 'Tax Name',
      }),
      dataIndex: 'taxName',
      align: 'left',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
    },
    {
      title: fm({
        id: 'pms.components.taxesGrid.taxPercentage',
        defaultMessage: 'Tax Percentage',
      }),
      dataIndex: 'taxPercentage',
      align: 'right',
    },
    {
      title: fm({
        id: 'pms.components.taxesGrid.taxCategory',
        defaultMessage: 'Tax Category',
      }),
      dataIndex: 'taxType',
      align: 'left',
    },
  ];

  const view = (taxesData: TaxesDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (taxesData) {
      isNewRecord = false;
    }
    let pathToreDirect = '/taxes-form';
    navigate(pathToreDirect, { state: { taxesData, isNewRecord, isUpdate } });
  };
  return (
    <>
      <Card className="card-radius">
        <Tabs defaultActiveKey={activeTab}>
          {isPermissionExist([23]) && (
            <TabPane tab="Taxes" key="taxes">
              {/* <Card
                title={<span>Taxes</span>}
                className="default-card-class"
                extra={
                  <>
                    {isPermissionExist([22]) && (
                      <Button onClick={() => view(undefined, false)}>
                        {fm({ id: 'common.create', defaultMessage: 'Create' })}
                      </Button>
                    )}
                  </>
                }
              > */}
              <Row justify="space-between" className="mb-12 mt-8">
                <Col>
                  <Input.Search
                    placeholder="Search Taxes"
                    allowClear
                    onChange={(e) => {
                      setSearchedText(e.target.value);
                    }}
                    onSearch={(value) => {
                      setSearchedText(value);
                    }}
                    className="input-search"
                  />
                </Col>
                <Col>
                  <>
                    {isPermissionExist([22]) && (
                      <Button
                        className="new-button"
                        onClick={() => view(undefined, false)}
                      >
                        {fm({
                          id: 'common.create',
                          defaultMessage: 'Create',
                        })}
                      </Button>
                    )}
                  </>
                </Col>
              </Row>
              <FilterableGrid
                rowKey="taxId"
                mainColumns={sampleTypeColumns}
                data={taxesData}
                filterColumnsData={[]}
                activateOrDeactivate={deleteTax}
                view={view}
              />
              {/* </Card> */}
            </TabPane>
          )}
          {isPermissionExist([27]) && (
            <TabPane tab="Items And Taxes" key="items and taxes">
              <TaxesAndItemsGrid />
            </TabPane>
          )}
          {isPermissionExist([31]) && (
            <TabPane tab="Company TDS" key="company tds">
              <CompanyTdsGrid />
            </TabPane>
          )}
          {isPermissionExist([55]) && (
            <TabPane tab="Payment Modes" key="payment modes">
              <PaymentModesGrid />
            </TabPane>
          )}
        </Tabs>
      </Card>
    </>
  );
};

export default TaxesGrid;
