import { EyeOutlined } from '@ant-design/icons';
import { ActionEnum, BargeRequest, BargeService, ContractModesEnum } from '@exportx/shared-models-and-services';
import { Col, Drawer, Row, Table, Tooltip, Descriptions, Flex, List, Tag, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../common';
import BargeSamplingReport from '../../../sampling-and-analysis/purchasequality/reports/barge-sampling-report';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faXmark } from '@fortawesome/free-solid-svg-icons';

interface qualityIProps {
    bargeId?: any;
    bgId: string;
}

const ContractQualityResults = (props: qualityIProps) => {
    const { bargeId, bgId } = props
    const bargeService = new BargeService()
    const [pcQualityResults, setPcQualityResults] = useState<any>();
    const [scQualityResults, setScQualityResults] = useState<any>();
    const { authContext } = useAuthState()
    const [drawerState, setDrawerState] = useState<boolean>(false);
    const [contractType, setContractType] = useState<ContractModesEnum>();
    const [dummyRefresh, setDummyRefresh] = useState<number>(1);
    const [bargeScq, setBargeScq] = useState<any>()


    useEffect(() => {
        if (bargeId) {
            bargePcFinQualityResults();
            bargeSalesQualityResults()
        }
    }, [bargeId])

    const reqBargeId = [bargeId]

    const bargePcFinQualityResults = () => {
        const req = new BargeRequest(bargeId, authContext.defaultPlant)
        bargeService.bargePcFinQualityResults(req).then(res => {
            if (res.status) {
                setPcQualityResults(res.data);
            }
        }).catch(err => console.log(err.message))
    }

    const bargeSalesQualityResults = () => {
        const req = new BargeRequest(bargeId, authContext.defaultPlant)
        bargeService.bargeSalesQualityResults(req).then(res => {
            if (res.status) {
                setScQualityResults(res.data)
                setBargeScq(res.data[0] ?? undefined)
            }
        }).catch(err => console.log(err.message))
    }

    const pcColumns: ColumnsType<any> = [
        {
            title: "Purchase Quality Results ",
            dataIndex: 'pcqResultsId',
            align: 'center',
            render: (value, record) => {
                return <>
                    <span>{" Quality Results "}
                        <Tooltip placement="top" title={'View Quality Results'}>
                            <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }} onClick={e => {
                                setDrawerState(true);
                                setContractType(ContractModesEnum.PURCHASE);
                                setDummyRefresh(prev => prev + 1);
                            }} />
                        </Tooltip>

                    </span>
                </>
            }
        }
    ]

    const scColumns: ColumnsType<any> = [
        {
            title: "Sales Quality Results",
            dataIndex: 'pcqResultsId',
            align: 'center',
            render: (value, record) => {
                return <>
                    <span>{" Quality Results "}
                        <Tooltip placement="top" title={'View Quality Results'}>
                            <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }} onClick={e => {
                                setDrawerState(true);
                                setContractType(ContractModesEnum.SALES);
                                setDummyRefresh(prev => prev + 1);
                                setBargeScq(record);
                            }} />
                        </Tooltip>

                    </span>
                </>
            }
        }
    ]

    return (
        <>
            <h6>Quality Results</h6>
            <Row gutter={[16, 12]}>
                <Col span={24} xs={24} md={24} lg={8} xl={8}>
                    <div className='standard-row'>
                        <Flex justify='space-between' align='center' >
                            <span>Purchase Quality</span>
                            <Button type='text'
                                icon={<EyeOutlined />}
                                className="icon"
                                onClick={e => {
                                    setDrawerState(true);
                                    setContractType(ContractModesEnum.PURCHASE);
                                    setDummyRefresh(prev => prev + 1);
                                }}
                            />
                        </Flex>
                    </div>
                </Col>
                <Col span={24} xs={24} md={24} lg={8} xl={8}>
                    <div className='standard-row'>
                        <Flex justify='space-between' align='center' >
                            <span>Sales Quality</span>
                            <Button type='text'
                                icon={<EyeOutlined />}
                                className="icon"
                                onClick={e => {
                                    setDrawerState(true);
                                    setContractType(ContractModesEnum.SALES);
                                    setDummyRefresh(prev => prev + 1);
                                }}
                            />

                        </Flex>

                    </div>
                </Col>
            </Row>
            <Drawer
                title={
                    <Flex align='center' justify='space-between'>
                        <span className='drawer-heading'>{`${contractType} QUALITY`}</span>
                        <Button type='text' className='drawer-close-icon' icon={<FontAwesomeIcon icon={faXmark} />} onClick={() => setDrawerState(false)} />
                    </Flex>
                }
                open={drawerState}
                closable={false}
                onClose={() => {
                    setDrawerState(false);
                    setDummyRefresh(prev => prev + 1);
                }}
                width={800}
                className='dashborad-popup'
                footer={
                    <Flex align='center' justify='flex-end' style={{ margin: 8 }}>
                        <Button type='text' className='link' onClick={() => setDrawerState(false)}>Close</Button>
                    </Flex>
                }
            >
                <BargeSamplingReport key={dummyRefresh} bargeId={reqBargeId} contractQuality={'contract'} bargeScq={bargeScq} contractType={contractType} actionType={ActionEnum.VIEW} />
            </Drawer>

        </>
    )
}

export default ContractQualityResults