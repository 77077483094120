import { UomsDto, UomsService } from '@exportx/shared-models-and-services';
import { ClassValidator } from '@exportx/ui-utils';
import { Button, Card, Form, Space } from 'antd';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, GenericFormWithOutPopUP, InputTypes, isPermissionExist, useAuthState } from '../../../../../common';
import './uoms-form.css';
import { useEffect } from 'react';


export interface UomsFormProps {
  uomsData: UomsDto;
  updateItem: (uomsData: UomsDto) => void;
  isUpdate: boolean;
  // saveItem:(uomsData:uomsDto) => void;
  closeForm: () => void;
}

export function UomsForm(props: UomsFormProps) {
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const service = new UomsService();
  const { formatMessage: fm } = useIntl();
  const { state }: any = useLocation();
  const [formRef] = Form.useForm();
  const { authContext } = useAuthState()
  const classValidator = new ClassValidator();

  const { isUpdate, uomData, isNewRecord } = state ? state : { isUpdate: false, uomData: null, isNewRecord: true }; // Read values passed on router  


    useEffect(()=>{
      const accessId = {
        create: [58],
        view: [59],
        edit: [60]
      }
      const hasPermission = isNewRecord ? accessId.create : isUpdate ? accessId.edit : accessId.view;
  
      if(!isPermissionExist(hasPermission)){
        return navigate('/')
      }
  
    },[])


  let pathToreDirect = '/commodity';
  const submitFormHandler = () => {
    formRef.validateFields().then(values => {
      saveUoms(values);
    })
  }


  const saveUoms = (values: UomsDto) => {
    if (isUpdate && uomData?.uomId) {
      updateUoms(values);
      return;
    }
    values.isActive = true;
    service.createUoms({ ...values, createdUser: authContext.user.userName }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Uom Created Successfully');
        navigate("/UOMs-view");
        onReset();
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param values //Dto values
   */
  const updateUoms = (values: any) => {
    service.updateUoms({ ...values, createdUser: authContext.user.userName, uomId: uomData?.uomId, versionFlag: uomData?.versionFlag }).then(res => {
      console.log(res);
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        setTimeout(() => {
          navigate("/UOMs-view");
        }, 500)

        onReset();
      } else {
        if (res.intlCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  /**
   * To reset form fields
   */
  const onReset = () => {
    console.log('Uom Reset');
    form.resetFields();
  };

  const formItems = [
    {
      name: 'uomName',
      label: fm({ id: "mdm.components.uomsForm.uomName", defaultMessage: 'UOM NAME' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.uomsForm.uomNameReq", defaultMessage: 'Please Enter UOM NAME' }) },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.uomsForm.uomNameReq`, defaultMessage: "Please Enter UOM NAME" }) },

        ...classValidator.alphaNumericWithOutSpecialChars(),
        ...classValidator.lengthValidation(15, fm({ id: "mdm.components.uomsForm.uomName", defaultMessage: ' UOM NAME' }))
      ]
    },
    {
      name: 'uomType',
      label: fm({ id: "mdm.components.uomsForm.uomType", defaultMessage: 'UOM TYPE' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.uomsForm.uomTypeReq", defaultMessage: 'Please Enter UOM TYPE' }) },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.uomsForm.uomTypeReq`, defaultMessage: "Please Enter UOM TYPE" }) },

        ...classValidator.alphaNumericWithOutSpecialChars(),
        ...classValidator.lengthValidation(40, fm({ id: "mdm.components.uomsForm.uomType", defaultMessage: 'uomType' }))
      ]
    }


  ]

  const clearData = () => {
    formRef.resetFields();
  }

  return (
    <Card bodyStyle={{ overflow: 'auto' }} title={fm({ id: 'mdm.components.companyForm.header', defaultMessage: 'UOMs' })}
      className='default-card-class'
      extra={
        <Button
          onClick={() => navigate(pathToreDirect, {state: {activeTab: "uom's"}})}
        >
          {fm({ id: 'common.view', defaultMessage: 'View' })}
        </Button>
      }
    >
      <GenericFormWithOutPopUP
        title={"UOM's"}
        formItems={formItems}
        clearData={clearData}
        formRef={formRef}
        isUpdate={isUpdate}
        isNewRecord={isNewRecord}
        closeForm={() => { }}
        submitForm={() => { }}
        initialValues={uomData}              >
        {
          (isNewRecord || isUpdate) && <Space>
            <Button
              onClick={() => { clearData(); }}
            >
              {fm({ id: "common.clear", defaultMessage: "Clear" })}
            </Button>
            <Button onClick={submitFormHandler} type="primary">
              {fm({ id: "common.submit", defaultMessage: "Submit" })}
            </Button>
          </Space>
        }
      </GenericFormWithOutPopUP>
    </Card>
  );
}
export default UomsForm;






