import { Card, Tabs } from "antd";
import AgingReportTab from "./aging-report-tab";
import { ContractModesEnum } from "@exportx/shared-models-and-services";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isPermissionExist } from "../../../../common";

const { TabPane } = Tabs;

interface IProps {
  contractType: ContractModesEnum
}
export const AgingReport = (props: IProps) => {
  const navigate = useNavigate();

  useEffect(() => {
      let permission;

      if (props.contractType === ContractModesEnum.PURCHASE) {
          permission = [869];
      } else {
          permission = [886];
      }

      if (!isPermissionExist(permission)) {
          navigate('/');
      }

  },[props.contractType]);

  return (
    <Card title="Aging Report">
      <Tabs >
        <TabPane tab="Delayed Samples Report" key="1">
          <AgingReportTab contractType={props.contractType} />
        </TabPane>

      </Tabs>
    </Card>

  )
}

export default AgingReport;