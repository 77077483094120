import Icon, * as antdIcons from '@ant-design/icons';
import { Divider, Layout, Menu, MenuProps, Space } from 'antd';
import React, { Children, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { CommonHeader, isPermissionExist } from '../common';
import { ReactComponent as AccountGroups } from '../common/icons/account-groups.svg';
import { ReactComponent as Ledger } from '../common/icons/accounts.svg';
import { ReactComponent as AnchorageIcon } from '../common/icons/anchor.svg';
import { ReactComponent as ApprovalIcon } from '../common/icons/approved.svg';
import { ReactComponent as LcIcon } from '../common/icons/bank.svg';
import { ReactComponent as CostingsIcon } from '../common/icons/budget.svg';
import { ReactComponent as BudgetAndCostingIcon } from '../common/icons/budgeting.svg';
import { ReactComponent as VendorTypeIcon } from '../common/icons/business-partner.svg';
import { ReactComponent as Cargo } from '../common/icons/cargo-barge.svg';
import { ReactComponent as SamplingIcon } from '../common/icons/chemical-testing.svg';
import { ReactComponent as Coins } from '../common/icons/coins.svg';
import { ReactComponent as CommoditiesIcon } from '../common/icons/commodities.svg';
import { ReactComponent as CompanyIcon } from '../common/icons/company.svg';
import { ReactComponent as AdvanceContractIcon } from '../common/icons/contract.svg';
import { ReactComponent as CountryIcon } from '../common/icons/countries.svg';
import { ReactComponent as CurrencyExchangeIcon } from '../common/icons/currency-exchange.svg';
import { ReactComponent as CurrencyIcon } from '../common/icons/currency.svg';
import { ReactComponent as DestinationIcon } from '../common/icons/delivery-route.svg';
import { ReactComponent as ReceiptVoucher } from '../common/icons/discount-vouchers.svg';
import { ReactComponent as ContractsDoc } from '../common/icons/document.svg';
import { ReactComponent as FinanceApprove } from '../common/icons/finance-approve.svg';
import { ReactComponent as FinReports } from '../common/icons/finance-report.svg';
import { ReactComponent as PurchaseSamplingIcon } from '../common/icons/financial-experiment-p.svg';
import { ReactComponent as SalesSamplingIcon } from '../common/icons/financial-experiment-s.svg';
import { ReactComponent as BusinessNoIcon } from '../common/icons/financial-planning.svg';
import { ReactComponent as CustomerTypeIcon } from '../common/icons/global-business-partner.svg';
import { ReactComponent as BasisIcon } from '../common/icons/humidity-mobile-application.svg';
import { ReactComponent as JVoucher } from '../common/icons/journal.svg';
import { ReactComponent as LedgerBook } from '../common/icons/ledger-book.svg';
import { ReactComponent as BusinessPartnerIcon } from '../common/icons/management.svg';
import { ReactComponent as UOMIcon } from '../common/icons/measuring-tape.svg';
import { ReactComponent as BargeIcon } from '../common/icons/noun-barge-3447518.svg';
import { ReactComponent as FacilitatorTypeIcon } from '../common/icons/partnership.svg';
import { ReactComponent as PayableIcon } from '../common/icons/payable.svg';
import { ReactComponent as PaymentModesIcon } from '../common/icons/payment-modes.svg';
import { ReactComponent as FacilitatorIcon } from '../common/icons/people-conversation.svg';
import { ReactComponent as CustomerIcon } from '../common/icons/people.svg';
import { ReactComponent as PersonIcon } from '../common/icons/person.svg';
import { ReactComponent as ColaPriceIndexIcon } from '../common/icons/pricing.svg';
import { ReactComponent as PCIcon } from '../common/icons/purchase-contracts.svg';
import { ReactComponent as ReceivableIcon } from '../common/icons/receivable.svg';
import { ReactComponent as SCIcon } from '../common/icons/sales-contract.svg';
import { ReactComponent as ShippingTermsIcon } from '../common/icons/shipping-contract.svg';
import { ReactComponent as ApproveReject } from '../common/icons/take-decision.svg';
import { ReactComponent as ItemsAndTaxesIcon } from '../common/icons/tax.svg';
import { ReactComponent as TDSIcon } from '../common/icons/tds.svg';
import { ReactComponent as UserManagementIcon } from '../common/icons/user-management.svg';
import { ReactComponent as TaxIcon } from '../common/icons/vat-tax.svg';
import { ReactComponent as Voucher } from '../common/icons/voucher.svg';
import './BasicLayout.css';
import {
  AttributePage,
  ApplicationPage,
  ModulePage,
  MenuPage,
  SubMenuPage,
  ScopePage,
  PermissionsPage,
  OrganizationPage,
  ClientToApplicationsMapping,
  UnitPage,
  RolesPage,
  UserPage,
  UserRoleMappings,
  RolePermissionsMapping,
} from '../pages';
import CostingReports from '../pages/costings/reports/costing-reports';
import { PieChartTwoTone } from '@ant-design/icons';
import { isPermissionExists } from '../common/iam-client-react/permission-checker/permission-checker';
import LTCReports from '../pages/costings/reports/LTC-reports';
import LoadingPlansList from '../pages/costings/reports/loading-plans';
import { HeaderBreadcrumb } from '../common/header-nav-component/header-breadcrumb';
import { title } from 'process';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faBox,
  faBriefcase,
  faCalendarDays,
  faChartColumn,
  faChartSimple,
  faClipboardCheck,
  faClipboardList,
  faCoins,
  faCreditCard,
  faDollarSign,
  faFileCircleCheck,
  faFileLines,
  faFlaskVial,
  faGauge,
  faGear,
  faGlobe,
  faHandHoldingDollar,
  faHandshake,
  faMoneyBillWave,
  faReceipt,
  faRightLeft,
  faUserLock,
  faVialCircleCheck,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import path from 'path';
import ProfitLossReports from '../pages/costings/reports/pl-reports';
const { Sider, Content } = Layout;

export const menu = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <FontAwesomeIcon icon={faGauge} />,
    path: '/',
    show: true,
    children: [],
  },
  {
    id: 'contracts',
    title: 'Contracts',
    icon: <FontAwesomeIcon icon={faFileLines} />,
    show: isPermissionExist([
      95, 96, 108, 165, 110, 111, 123, 147, 156, 126, 130, 138, 174, 194, 214,
      234, 104, 119, 151, 177, 197,
    ]),
    children: [
      {
        id: 'contracts-sales',
        title: 'Sales',
        icon: <FontAwesomeIcon icon={faCoins} />,
        show: isPermissionExist([95, 96, 108, 165]),
        children: [
          {
            id: 'sales-orders',
            title: 'Sales Order',
            path: '/so-tracker',
            show: isPermissionExist([95, 96, 108]),
          },
          {
            id: 'sales-marketing-fees',
            title: 'Marketing Fees',
            path: '/marketing',
            show: isPermissionExist([165]),
          },
        ],
      },
      {
        id: 'contracts-purchase',
        title: 'Purchase',
        icon: <FontAwesomeIcon icon={faBox} />,
        show: isPermissionExist([110, 111, 123, 147, 156]),
        children: [
          {
            id: 'purchase-orders',
            title: 'Purchase Order',
            path: '/po-tracker',
            show: isPermissionExist([110, 111, 123]),
          },
          {
            id: 'purchase-procurement-service',
            title: 'Procurement Service',
            path: '/procurementServiceFee',
            show: isPermissionExist([147]),
          },
          {
            id: 'purchase-coal-service-fees',
            title: 'Coal Service Fees',
            path: '/coalServiceFee',
            show: isPermissionExist([156]),
          },
        ],
      },
      {
        id: 'contracts-trading',
        title: 'Trading',
        icon: <FontAwesomeIcon icon={faDollarSign} />,
        show: isPermissionExist([126, 130, 138]),
        children: [
          {
            id: 'trading-coal-price-indexes',
            title: 'Coal Price Indexes',
            path: '/weekly-coal-price-index',
            show: isPermissionExist([126, 130]),
          },
          {
            id: 'trading-fixture-notes',
            title: 'Fixture Notes',
            path: '/bargeAgreement-grid',
            show: isPermissionExist([138]),
          },
        ],
      },
      {
        id: 'contracts-advance',
        title: 'Advance',
        icon: <FontAwesomeIcon icon={faCreditCard} />,
        show: isPermissionExist([174, 194, 214, 234]),
        children: [
          {
            id: 'advance-vendor',
            title: 'Vendor Advance',
            path: '/vendor-advance',
            show: isPermissionExist([174]),
          },
          {
            id: 'advance-down-payment',
            title: 'Down Payment',
            path: '/down-payment',
            show: isPermissionExist([194]),
          },
          {
            id: 'advance-contract',
            title: 'Contract Advance',
            path: '/vendor-advance-against-contract',
            show: isPermissionExist([214]),
          },
          {
            id: 'advance-receivables',
            title: 'Advance Receivables',
            path: '/advance-receivables',
            show: isPermissionExist([234]),
          },
        ],
      },
      {
        id: 'contracts-approvals',
        title: 'Approvals',
        icon: <FontAwesomeIcon icon={faFileCircleCheck} />,
        show: isPermissionExist([104, 119, 151, 177, 197]),
        children: [
          {
            id: 'all-approvals',
            title: 'All Approvals',
            path: '/contract-approval',
            show: isPermissionExist([104, 119, 151, 177, 197]),
          },
        ],
      },
    ],
  },
  {
    id: 'business',
    title: 'Business',
    icon: <FontAwesomeIcon icon={faBriefcase} />,
    show: isPermissionExist([253, 254, 262, 263]),
    children: [
      {
        id: 'business-transactions',
        title: 'Transactions',
        icon: <FontAwesomeIcon icon={faHandshake} />,
        show: isPermissionExist([253, 254, 262, 263]),
        children: [
          {
            id: 'transactions-business-number',
            title: 'Business Number',
            path: '/businessnumber',
            show: isPermissionExist([253, 254]),
          },
          {
            id: 'transactions-barge',
            title: 'Barge',
            path: '/barge-grid',
            show: isPermissionExist([262, 263]),
          },
        ],
      },
    ],
  },
  {
    id: 'accounts',
    title: 'Accounts',
    icon: <FontAwesomeIcon icon={faHandHoldingDollar} />,
    show: isPermissionExist([
      271, 275, 292, 296, 298, 313, 317, 334, 338, 355, 359, 376, 380, 396, 400,
      417, 421, 438, 442, 459, 463, 480, 484, 501, 505, 522, 526, 543, 547, 564,
      568, 585, 589, 606, 610, 627, 630, 647, 650, 667, 670, 687, 690, 707, 710,
      712, 727, 730, 732, 747, 750,
    ]),
    children: [
      {
        id: 'accounts-payables',
        title: 'Payables',
        icon: <FontAwesomeIcon icon={faMoneyBillWave} />,
        show: isPermissionExist([
          271, 275, 292, 298, 317, 355, 359, 376, 380, 396, 400, 707, 727, 747,
          417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667, 687,
          421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690,
          730, 732, 750, 710, 712,
        ]),
        children: [
          {
            id: 'payables-performa-purchase',
            title: 'Performa Purchase',
            path: '/performaCoalCosting',
            show: isPermissionExist([271, 275]),
          },
          {
            id: 'payables-commercial-purchase',
            title: 'Commercial Purchase',
            path: '/commercial-coal-costing',
            show: isPermissionExist([292, 298, 317]),
          },
          {
            id: 'payables-procurement-fees',
            title: 'Procurement Fees',
            path: '/procurement-coal-costing',
            show: isPermissionExist([355, 359]),
          },
          {
            id: 'payables-marketing-fees',
            title: 'Marketing Fees',
            path: '/marketing-coal-costing',
            show: isPermissionExist([376, 380]),
          },
          {
            id: 'payables-barge-costing',
            title: 'Barge Costing',
            path: '/barge-costings/barge',
            show: isPermissionExist([396, 400]),
          },
          {
            id: 'payables-expense-entry',
            title: 'Expense Entry',
            path: '/expense-entry',
            show: isPermissionExist([
              417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667,
              687, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650,
              670, 690,
            ]),
          },
          {
            id: 'payables-demurrage',
            title: 'Demurrage',
            path: '/demurrage',
            show: isPermissionExist([727, 730, 732]),
          },
          {
            id: 'payables-credit-note',
            title: 'Credit Note',
            path: '/credit-note-entry',
            show: isPermissionExist([747, 750]),
          },
          {
            id: 'payables-advances',
            title: 'Advances',
            path: '/advance-business-approval',
            // show: isPermissionExist([750]),
            show: true,
          },
          {
            id: 'payables-dead-freight',
            title: 'Dead Freight',
            path: '/dead-freight',
            show: isPermissionExist([707, 710, 712]),
          },
        ],
      },
      {
        id: 'accounts-receivables',
        title: 'Receivables',
        icon: <FontAwesomeIcon icon={faReceipt} />,
        show: isPermissionExist([
          313, 296, 334, 338, 417, 438, 459, 480, 501, 522, 543, 564, 585, 606,
          627, 647, 667, 687, 730, 747, 750, 421, 442, 463, 484, 505, 526, 547,
          568, 589, 610, 630, 650, 670, 690, 750,
        ]),
        children: [
          {
            id: 'receivables-performa-sales',
            title: 'Performa Sales',
            path: '/performaCostingSale',
            show: isPermissionExist([313, 296]),
          },
          {
            id: 'receivables-commercial-sales',
            title: 'Commercial Sales',
            path: '/commercial-costing-sales',
            show: isPermissionExist([334, 338]),
          },
          {
            id: 'receivables-freight-costing',
            title: 'Freight Costing',
            path: '/salesFreightCosting',
            show: isPermissionExist([313, 296]),
          },
          {
            id: 'receivables-expense-entry',
            title: 'Expense Entry',
            path: '/other-income-expense-entry',
            show: isPermissionExist([
              417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667,
              687, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650,
              670, 690,
            ]),
          },
          {
            id: 'receivables-demurrage',
            title: 'Demurrage',
            path: '/demurrage-receivable-approval',
            show: isPermissionExist([730]),
          },
          {
            id: 'receivables-debit-note',
            title: 'Debit Note',
            path: '/debit-note-entry',
            show: isPermissionExist([747, 750]),
          },
          {
            id: 'receivables-dead-freight',
            title: 'Dead Freight',
            path: '/dead-freight-receivable-approval',
            show: isPermissionExist([710]),
          },
          {
            id: 'receivables-advance',
            title: 'Advance Receivables',
            path: '/advance-receivable-business-approval',
            show: isPermissionExist([750]),
          },
        ],
      },
    ],
  },
  {
    id: 'finance',
    title: 'Finance',
    icon: <FontAwesomeIcon icon={faWallet} />,
    show:
      isPermissionExist([
        287, 308, 329, 350, 371, 391, 412, 433, 454, 475, 496, 517, 538, 559,
        580, 601, 622, 642, 662, 682, 702, 722, 742, 762, 288, 309, 330, 351,
        372, 392, 413, 434, 455, 476, 497, 518, 539, 560, 581, 602, 623, 643,
        663, 683, 703, 723, 743, 763, 290, 311, 332, 353, 374, 394, 415, 436,
        457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725,
        745, 765, 334, 338, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610,
        630, 650, 670, 690, 773, 779, 785, 791, 797, 803, 809, 767,
      ]) || isPermissionExists([889, 890, 891]),
    children: [
      {
        id: 'finance-audit',
        title: 'Audit',
        icon: <FontAwesomeIcon icon={faClipboardCheck} />,
        show: isPermissionExist([
          334, 338, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650,
          670, 690,
        ]),
        children: [
          {
            id: 'audit-payables',
            title: 'Payables',
            path: '/audit-approvals-payables',
            show: isPermissionExist([
              334, 338, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630,
              650, 670, 690,
            ]),
          },
          {
            id: 'audit-receivables',
            title: 'Receivables',
            path: '/audit-approvals-receivables',
            show: isPermissionExist([
              334, 338, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630,
              650, 670, 690,
            ]),
          },
        ],
      },
      {
        id: 'finance-overview',
        title: 'Payment Notice',
        icon: <FontAwesomeIcon icon={faBook} />,
        show: isPermissionExist([
          287, 308, 329, 350, 371, 391, 412, 433, 454, 475, 496, 517, 538, 559,
          580, 601, 622, 642, 662, 682, 702, 722, 742, 762, 288, 309, 330, 351,
          372, 392, 413, 434, 455, 476, 497, 518, 539, 560, 581, 602, 623, 643,
          663, 683, 703, 723, 743, 763, 290, 311, 332, 353, 374, 394, 415, 436,
          457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725,
          745, 765,
        ]),
        children: [
          {
            id: 'payment-notice-payables',
            title: 'Payables',
            path: '/payment-notice-creation',
            show: isPermissionExist([
              287, 308, 329, 350, 371, 391, 412, 433, 454, 475, 496, 517, 538,
              559, 580, 601, 622, 642, 662, 682, 702, 722, 742, 762, 288, 309,
              330, 351, 372, 392, 413, 434, 455, 476, 497, 518, 539, 560, 581,
              602, 623, 643, 663, 683, 703, 723, 743, 763, 290, 311, 332, 353,
              374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583, 604, 625,
              645, 665, 685, 705, 725, 745, 765,
            ]),
          },
          {
            id: 'payment-notice-receivables',
            title: 'Receivables',
            path: '/approve-payment',
            show: isPermissionExist([
              288, 309, 330, 351, 372, 392, 413, 434, 455, 476, 497, 518, 539,
              560, 581, 602, 623, 643, 663, 683, 703, 723, 743, 763, 290, 311,
              332, 353, 374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583,
              604, 625, 645, 665, 685, 705, 725, 745, 765,
            ]),
          },
        ],
      },
      {
        id: 'finance-lc-tracker',
        title: 'LC Tracker',
        icon: <FontAwesomeIcon icon={faClipboardList} />,
        show: isPermissionExist([773, 779]),
        children: [
          {
            id: 'lc-tracker-purchase',
            title: 'Purchase',
            path: '/purchase-lc-tracker',
            show: isPermissionExist([773]),
          },
          {
            id: 'lc-tracker-sales',
            title: 'Sales',
            path: '/sales-lc-tracker',
            show: isPermissionExist([779]),
          },
        ],
      },
      {
        id: 'finance-advance-accounting',
        title: 'Advance Accounting',
        icon: <FontAwesomeIcon icon={faCreditCard} />,
        show: isPermissionExist([785, 791, 797, 803, 809]),
        children: [
          {
            id: 'advance-accounting-accounts-groups',
            title: 'Accounts Groups',
            path: '/account-groups',
            show: isPermissionExist([785]),
          },
          {
            id: 'advance-accounting-ledgers',
            title: 'Ledgers',
            path: '/accounts',
            show: isPermissionExist([791]),
          },
          {
            id: 'advance-accounting-vouchers',
            title: 'Vouchers',
            path: '/vouchers',
            show: isPermissionExist([797, 803, 809]),
          },
        ],
      },
      {
        id: 'finance-planning',
        title: 'Planning',
        icon: <FontAwesomeIcon icon={faCalendarDays} />,
        show:
          isPermissionExist([767, 779]) || isPermissionExists([889, 890, 891]),
        children: [
          {
            id: 'planning-budget-costing',
            title: 'Budget Costing',
            path: '/budget-costing',
            show: isPermissionExist([767]),
          },
          {
            id: 'planning-markup-price',
            title: 'Markup Price',
            path: '/markup-price',
            show: isPermissionExist([779]),
          },
          {
            id: 'planning-loading-plans',
            title: 'Loading Plans',
            path: '/loading-plans',
            show: isPermissionExists([889, 890, 891]),
          },
        ],
      },
    ],
  },
  {
    id: 'sampling',
    title: 'Sampling',
    icon: <FontAwesomeIcon icon={faVialCircleCheck} />,
    show: isPermissionExist([
      857, 836, 858, 861, 842, 862, 865, 846, 866, 873, 851, 874, 882, 876, 877,
    ]),
    children: [
      {
        id: 'sampling-purchase',
        title: 'Purchase',
        icon: <FontAwesomeIcon icon={faFlaskVial} />,
        show: isPermissionExist([
          857, 836, 858, 861, 842, 862, 865, 846, 866, 873, 851, 874,
        ]),
        children: [
          {
            id: 'purchase-supplier',
            title: 'Supplier',
            path: '/purchase-sampling-reports/supplier',
            show: isPermissionExist([857, 836, 858]),
          },
          {
            id: 'purchase-srpl',
            title: 'SRPL',
            path: '/purchase-sampling-reports/company',
            show: isPermissionExist([861, 842, 862]),
          },
          {
            id: 'purchase-actual',
            title: 'Actual',
            path: '/purchase-sampling-reports/actual',
            show: isPermissionExist([865, 846, 866]),
          },
          {
            id: 'purchase-pre-shipment',
            title: 'Pre-Shipment',
            path: '/purchase-sampling-reports/pre-shipment',
            show: isPermissionExist([873, 851, 874]),
          },
        ],
      },
      {
        id: 'sampling-sales',
        title: 'Sales',
        icon: <FontAwesomeIcon icon={faFlaskVial} />,
        show: isPermissionExist([882, 876, 877]),
        children: [
          {
            id: 'sales-sales-quality',
            title: 'Sales Quality',
            path: '/sales-sampling-reports/actual',
            show: isPermissionExist([882, 876, 877]),
          },
        ],
      },
    ],
  },
  {
    id: 'reports',
    title: 'Reports',
    icon: <FontAwesomeIcon icon={faChartSimple} />,
    show: isPermissionExist([1, 869, 886, 815, 821, 827, 892]),
    children: [
      {
        id: 'reports-business',
        title: 'Business Reports',
        icon: <FontAwesomeIcon icon={faChartColumn} />,
        show: isPermissionExist([1, 892]),
        children: [
          {
            id: 'business-costing-reports',
            title: 'Costing Reports',
            path: '/costing-reports',
            show: isPermissionExist([1]),
          },
          {
            id: 'business-ltc-reports',
            path: '/LTC-report',
            title: 'LTC Report',
            show: isPermissionExist([892]),
          },
          {
            id: 'business-pl-reports',
            path: '/pl-report',
            title: 'PL Report',
            show: isPermissionExist([1]),
          },
        ],
      },
      {
        id: 'reports-sampling-analysis',
        title: 'Sampling Analysis',
        icon: <FontAwesomeIcon icon={faFlaskVial} />,
        show: isPermissionExist([869, 886]),
        children: [
          {
            id: 'sampling-analysis-purchase-aging',
            title: 'Purchase Aging',
            path: '/purchase-sampling-reports/aging-report',
            show: isPermissionExist([869]),
          },
          {
            id: 'sampling-analysis-sales-aging',
            title: 'Sales Aging',
            path: '/sales-sampling-reports/aging-report',
            show: isPermissionExist([886]),
          },
        ],
      },
      {
        id: 'reports-accounting',
        title: 'Accounting Reports',
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        show: isPermissionExist([815, 821, 827]),
        children: [
          {
            id: 'accounting-reports-ledger',
            title: 'Accounts Ledger',
            path: '/ledger',
            show: isPermissionExist([815]),
          },
          {
            id: 'accounting-reports-payable',
            title: 'Payable',
            path: '/accountsPayable',
            show: isPermissionExist([821]),
          },
          {
            id: 'accounting-reports-receivables',
            title: 'Receivables',
            path: '/accountReceivables',
            show: isPermissionExist([827]),
          },
        ],
      },
    ],
  },
  {
    id: 'admin',
    title: 'Admin',
    icon: <FontAwesomeIcon icon={faGear} />,
    show: isPermissionExist([
      3, 7, 11, 15, 59, 23, 27, 31, 55, 39, 43, 47, 63, 67, 51, 71, 75, 82, 89,
      1, 35,
    ]),
    children: [
      {
        id: 'admin-basic-setup',
        title: 'Basic Setup',
        icon: <FontAwesomeIcon icon={faGlobe} />,
        show: isPermissionExist([
          3, 7, 11, 15, 59, 23, 27, 31, 55, 39, 43, 47, 63, 67, 51, 71, 75, 82,
          89,
        ]),
        children: [
          {
            id: 'basic-setup-region&country',
            title: 'Region & Currency',
            path: '/countries',
            show: isPermissionExist([3, 7]),
          },
          {
            id: 'basic-setup-company',
            title: 'Company',
            path: '/company',
            show: isPermissionExist([11]),
          },
          {
            id: 'basic-setup-commodity',
            title: 'Commodity',
            path: '/commodity',
            show: isPermissionExist([15, 19, 59]),
          },
          {
            id: 'basic-setup-taxes',
            title: 'Taxes',
            path: '/taxes-view',
            show: isPermissionExist([23, 27, 31, 55]),
          },
          {
            id: 'basic-setup-partner-type',
            title: 'Partner Type',
            path: '/partnerType',
            show: isPermissionExist([39, 43, 47]),
          },
          {
            id: 'basic-setup-shipping',
            title: 'Shipping',
            path: '/destination-view',
            show: isPermissionExist([63, 67, 51, 71]),
          },
          {
            id: 'basic-setup-business-partner',
            title: 'Business Partner',
            path: '/businessPartner',
            show: isPermissionExist([75, 82, 89]),
          },
        ],
      },
      {
        id: 'admin-user-management',
        title: 'User Management',
        icon: <FontAwesomeIcon icon={faUserLock} />,
        show: isPermissionExist([1]),
        children: [
          {
            id: 'user-management-roles',
            title: 'Roles',
            path: '/roles',
            show: isPermissionExist([1]),
          },
          {
            id: 'user-management-user',
            title: 'User',
            path: '/user-creation',
            show: isPermissionExist([1]),
          },
          {
            id: 'user-management-user&role-mapping',
            title: 'User and Role Mapping',
            path: '/user-role-mappings',
            show: isPermissionExist([1]),
          },
        ],
      },
      {
        id: 'admin-exchange-rates',
        title: 'Exchange Rates',
        icon: <FontAwesomeIcon icon={faRightLeft} />,
        show: isPermissionExist([35]),
        children: [
          {
            id: 'exchange-rates-currency-conversion',
            title: 'Currency Conversion',
            path: '/currency-conversion',
            show: isPermissionExist([35]),
          },
          // {
          //   title: 'BI',
          //   path: '/',
          //   show: isPermissionExist([1]),
          // },
          // {
          //   title: 'Middle',
          //   path: '/',
          //   show: isPermissionExist([1]),
          // },
          // {
          //   title: 'Kurs',
          //   path: '/',
          //   show: isPermissionExist([1]),
          // },
          // {
          //   title: 'Tax',
          //   path: '/',
          //   show: isPermissionExist([1]),
          // },
          // {
          //   title: 'Jisdor',
          //   path: '/',
          //   show: isPermissionExist([1]),
          // },
        ],
      },
    ],
  },
];

// export const menus = [
//   {
//     path: '/masters',
//     title: 'Masters',
//     icon: <antdIcons.HomeOutlined />,
//     component: null,
//     show: isPermissionExist([
//       3, 7, 11, 15, 19, 23, 27, 31, 35, 39, 43, 47, 51, 55, 59, 63, 67, 71,
//     ]),
//     children: [
//       {
//         path: '/countries',
//         title: 'Countries',
//         icon: <Icon component={CountryIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([3]),
//         component: <></>,
//       },
//       {
//         path: '/currency',
//         title: 'Currencies',
//         icon: <Icon component={CurrencyIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([7]),
//         component: <></>,
//       },
//       {
//         path: '/company',
//         title: 'Company',
//         icon: <Icon component={CompanyIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([11]),
//         component: <></>,
//       },
//       {
//         path: '/commodity',
//         title: 'Commodity',
//         icon: (
//           <Icon
//             component={CommoditiesIcon}
//             style={{ fontSize: '20px', color: 'blue' }}
//           />
//         ),
//         show: isPermissionExist([15]),
//         component: <></>,
//       },
//       {
//         path: '/basis',
//         title: 'Basis',
//         icon: <Icon component={BasisIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([19]),
//         component: <></>,
//       },
//       {
//         path: '/taxes-view',
//         title: 'Taxes',
//         icon: (
//           <Icon
//             component={TaxIcon}
//             style={{ fontSize: '20px', color: 'blue' }}
//           />
//         ),
//         show: isPermissionExist([23]),
//         component: null,
//         children: [],
//       },
//       {
//         path: '/items-and-taxes',
//         title: 'Items And Taxes',
//         icon: (
//           <Icon component={ItemsAndTaxesIcon} style={{ fontSize: '20px' }} />
//         ),
//         show: isPermissionExist([27]),
//         component: null,
//         children: [],
//       },
//       {
//         path: '/company-vat',
//         title: 'Company TDS',
//         icon: <Icon component={TDSIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([31]),
//         component: null,
//         children: [],
//       },
//       {
//         path: '/currency-conversion',
//         title: 'Exchange Rates',
//         icon: (
//           <Icon component={CurrencyExchangeIcon} style={{ fontSize: '20px' }} />
//         ),
//         show: isPermissionExist([35]),
//         component: <></>,
//       },
//       {
//         path: '/vendorType',
//         title: 'VendorType',
//         icon: <Icon component={VendorTypeIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([39]),
//         component: <></>,
//       },
//       {
//         path: '/customerType',
//         title: 'CustomerType',
//         icon: (
//           <Icon component={CustomerTypeIcon} style={{ fontSize: '20px' }} />
//         ),
//         show: isPermissionExist([43]),
//         component: <></>,
//       },
//       {
//         path: '/facilitatorType',
//         title: 'facilitatorType',
//         icon: (
//           <Icon component={FacilitatorTypeIcon} style={{ fontSize: '20px' }} />
//         ),
//         show: isPermissionExist([47]),
//         component: <></>,
//       },
//       {
//         path: '/shippingTerms',
//         title: 'ShippingTerms',
//         icon: (
//           <Icon component={ShippingTermsIcon} style={{ fontSize: '20px' }} />
//         ),
//         show: isPermissionExist([51]),
//         component: <></>,
//       },
//       {
//         path: '/paymentModes',
//         title: 'PaymentModes',
//         icon: (
//           <Icon
//             component={PaymentModesIcon}
//             style={{ fontSize: '20px', color: 'blue' }}
//           />
//         ),
//         show: isPermissionExist([55]),
//         component: <></>,
//       },
//       {
//         path: '/UOMs-view',
//         title: `UOM's`,
//         icon: (
//           <Icon
//             component={UOMIcon}
//             style={{ fontSize: '20px', color: 'blue' }}
//           />
//         ),
//         show: isPermissionExist([59]),
//         component: null,
//       },
//       {
//         path: '/destination-view',
//         title: `Province`,
//         icon: (
//           <Icon
//             component={DestinationIcon}
//             style={{ fontSize: '20px', color: 'blue' }}
//           />
//         ),
//         show: isPermissionExist([63]),
//         component: null,
//         children: [],
//       },
//       {
//         path: '/anchoragegrid',
//         title: 'Anchorage',
//         icon: <Icon component={AnchorageIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([67]),
//         component: null,
//         children: [],
//       },
//       {
//         path: '/jettyGrid',
//         title: 'Jetty',
//         icon: <Icon component={AnchorageIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([71]),
//         component: null,
//       },
//     ],
//   },
//   {
//     path: '/business-partner',
//     title: 'Business Partner',
//     icon: <Icon component={BusinessPartnerIcon} style={{ fontSize: '20px' }} />,
//     show: isPermissionExist([75, 82, 89]),
//     component: <></>,
//     children: [
//       {
//         path: '/customer',
//         title: 'Customers',
//         icon: <Icon component={CustomerIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([75]),
//         component: <></>,
//       },
//       {
//         path: '/vendor',
//         title: 'Vendors',
//         icon: <Icon component={PersonIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([82]),
//         component: <></>,
//       },
//       {
//         path: '/facilitator',
//         title: 'Facilitator',
//         icon: <Icon component={FacilitatorIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([89]),
//         component: <></>,
//       },
//     ],
//   },
//   {
//     path: '/contracts',
//     title: 'Contracts',
//     icon: <Icon component={ContractsDoc} style={{ fontSize: '20px' }} />,
//     show: isPermissionExist([
//       96, 111, 123, 126, 126, 134, 138, 147, 156, 165, 174, 182, 189, 194, 202,
//       209, 214, 222, 229, 234, 242, 249,
//     ]),
//     component: null,
//     children: [
//       {
//         path: '/so-tracker',
//         title: `Sales`,
//         icon: <Icon component={SCIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([95, 96, 108]),
//         component: null,
//         // children: [
//         //   {
//         //     path: '/so-creation',
//         //     title: 'SO Creation',
//         //     icon: <antdIcons.PayCircleOutlined />,
//         //     component: null,
//         //     show: isPermissionExist([95]),
//         //     children: []
//         //   },
//         //   {
//         //     path: '/so-tracker',
//         //     title: 'SO Tracker',
//         //     icon: <antdIcons.PayCircleOutlined />,
//         //     component: null,
//         //     show: isPermissionExist([96]),
//         //     children: []
//         //   },
//         //   {
//         //     path: '/sales-transfer-log',
//         //     title: 'Sales Transfer',
//         //     icon: <antdIcons.PayCircleOutlined />,
//         //     component: null,
//         //     show: isPermissionExist([108]),
//         //     children: []

//         //   }
//         // ]
//       },
//       {
//         path: '/po-tracker',
//         title: 'Purchase',
//         icon: <Icon component={PCIcon} style={{ fontSize: '20px' }} />,
//         show: isPermissionExist([110, 111, 123]),
//         component: null,
//         // children: [
//         //   {
//         //     path: '/po-creation',
//         //     title: 'PO Creation',
//         //     icon: <antdIcons.PayCircleOutlined />,
//         //     show: isPermissionExist([110]),
//         //     component: null,
//         //     children: []
//         //   },
//         //   {
//         //     path: '/po-tracker',
//         //     title: 'PO Tracker',
//         //     icon: <antdIcons.PayCircleOutlined />,
//         //     show: isPermissionExist([111]),
//         //     component: null,
//         //     children: []
//         //   },
//         //   {
//         //     path: '/purchase-transfer-log',
//         //     title: 'Purchase Transfer',
//         //     icon: <antdIcons.PayCircleOutlined />,
//         //     show: isPermissionExist([123]),
//         //     component: null,
//         //     children: []

//         //   }
//         // ]
//       },
//       {
//         path: '/coal-price-indexes',
//         title: 'Coal Price Indexes',
//         icon: (
//           <Icon component={ColaPriceIndexIcon} style={{ fontSize: '20px' }} />
//         ),
//         component: null,
//         show: isPermissionExist([126, 130, 134]),
//         children: [
//           {
//             path: '/weekly-coal-price-index',
//             title: 'Weekly',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([126]),
//             children: [],
//           },
//           {
//             path: '/coal-price-calculation',
//             title: 'Price Calculation',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([130]),
//             children: [],
//           },
//           {
//             path: '/monthly-coal-price-index',
//             title: 'Monthly',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([134]),
//             children: [],
//           },
//         ],
//       },
//       {
//         path: 'fixtureNoteDetails',
//         title: 'FixtureNote',
//         icon: <antdIcons.PayCircleOutlined />,
//         component: null,
//         show: isPermissionExist([138, 137]),
//         children: [
//           // {
//           //   path: '/bargeAgreement-creation',
//           //   title: 'Barging Agreement Creation ',
//           //   icon: <antdIcons.PayCircleOutlined />,
//           //   component: null,
//           //   children: [],
//           //   show: isPermissionExist([137]),
//           // },
//           {
//             path: '/bargeAgreement-grid',
//             title: 'Barging Agreement ',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             children: [],
//             show: isPermissionExist([138]),
//           },
//           {
//             path: '/vesselAgreement-grid',
//             title: 'Vessel Agreement',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             children: [],
//             show: isPermissionExist([138]),
//           },
//         ],
//       },
//       {
//         path: 'procurement-service',
//         title: 'Service Contracts',
//         icon: <antdIcons.PayCircleOutlined />,
//         component: null,
//         show: isPermissionExist([147, 156, 165]),
//         children: [
//           {
//             path: '/procurementServiceFee',
//             title: 'Procurement Service',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             children: [],
//             show: isPermissionExist([147]),
//           },
//           {
//             path: '/coalServiceFee',
//             title: 'Coal Service Fees',
//             icon: <antdIcons.TeamOutlined />,
//             show: isPermissionExist([156]),
//             component: <></>,
//           },
//           {
//             path: '/marketing',
//             title: 'Marketing Fees',
//             icon: <antdIcons.TeamOutlined />,
//             show: isPermissionExist([165]),
//             component: <></>,
//           },
//         ],
//       },
//       {
//         path: '/Advance',
//         title: `Advance`,
//         icon: (
//           <Icon component={AdvanceContractIcon} style={{ fontSize: '20px' }} />
//         ),
//         component: null,
//         show: isPermissionExist([174, 194, 214, 234]),
//         children: [
//           {
//             path: '/vendor-advance',
//             title: 'Vendor Advance',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([174]),
//             children: [],
//           },

//           {
//             path: '/down-payment',
//             title: 'Down Payment',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([194]),
//             children: [],
//           },
//           {
//             path: '/vendor-advance-against-contract',
//             title: 'Advance Against Contract',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([214]),
//             children: [],
//           },
//           {
//             path: '/advance-receivables',
//             title: 'Advance Receivables',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([234]),
//             children: [],
//           },
//         ],
//       },
//       {
//         path: '/contract-approval',
//         title: `Approvals`,
//         icon: <Icon component={ApprovalIcon} style={{ fontSize: '20px' }} />,
//         component: null,
//         show: isPermissionExist([104, 119, 151, 177, 197]),
//       },
//     ],
//   },
//   {
//     path: '/businessnumber',
//     title: 'Business Number',
//     icon: <Icon component={BusinessNoIcon} style={{ fontSize: '20px' }} />,
//     component: null,
//     show: isPermissionExist([253, 254]),
//     // children: [
//     //   {
//     //     path: '/businessnumberDetails',
//     //     title: 'Business Number Creation',
//     //     icon: <antdIcons.PayCircleOutlined />,
//     //     component: null,
//     //     show: isPermissionExist([253]),
//     //     children: []
//     //   },
//     //   {
//     //     path: '/businessnumber',
//     //     title: 'Business Number Grid',
//     //     icon: <antdIcons.PayCircleOutlined />,
//     //     component: null,
//     //     show: isPermissionExist([254]),
//     //     children: []
//     //   }
//     // ]
//   },
//   {
//     path: 'BusinessReports',
//     title: `Business Report`,
//     icon: <Icon />,
//     component: null,
//     show: isPermissionExist([261]),
//     children: [
//       {
//         path: '/PLReports',
//         title: 'PL Reports',
//         icon: <antdIcons.PayCircleOutlined />,
//         component: null,
//         show: isPermissionExist([261]),
//         children: [],
//       },
//       // {
//       //     path :'/PLChildReports',
//       //     title:'PL Child Reports',
//       //     icon: <antdIcons.PayCircleOutlined />,
//       //     component: null,
//       //     children: []
//       // }
//     ],
//   },
//   {
//     path: '/barge-grid',
//     title: `Barge`,
//     icon: <Icon component={Cargo} style={{ fontSize: '20px' }} />,
//     component: null,
//     show: isPermissionExist([263, 262]),
//   },
//   {
//     path: '/costings',
//     title: 'Costings Creation',
//     icon: <Icon component={CostingsIcon} style={{ fontSize: '20px' }} />,
//     component: null,
//     show: isPermissionExist([
//       271, 292, 313, 334, 355, 376, 396, 417, 438, 459, 480, 501, 522, 543, 564,
//       585, 606, 627, 647, 667, 687, 707, 727, 747,
//     ]),
//     children: [
//       {
//         path: 'performaCoalCosting',
//         title: `Account Payables`,
//         icon: <antdIcons.RotateRightOutlined />,
//         component: null,
//         show: isPermissionExist([
//           271, 292, 355, 376, 396, 707, 727, 747, 417, 438, 459, 480, 501, 522,
//           543, 564, 585, 606, 627, 647, 667, 687,
//         ]),
//         children: [
//           {
//             path: 'performaCoalCosting',
//             title: `Performa Purchase`,
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([271]),
//           },

//           {
//             path: 'commercial-coal-costing',
//             title: `Commercial Purchase`,
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([292]),
//             children: [],
//           },

//           {
//             path: 'procurement-coal-costing',
//             title: `Procurement Fees`,
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([355]),
//             children: [],
//           },
//           {
//             path: 'marketing-coal-costing',
//             title: `Marketing Fees`,
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([376]),
//             children: [],
//           },
//           {
//             path: '/barge-costings/barge',
//             title: 'Barge Costing',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([396]),
//             children: [],
//           },
//           {
//             path: '/expense-entry',
//             title: 'Expense Entry',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([
//               417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667,
//               687,
//             ]),
//             children: [],
//           },
//           {
//             path: '/dead-freight',
//             title: 'Dead Freight',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([707]),
//             children: [],
//           },
//           {
//             path: '/demurrage',
//             title: 'Demurrage',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([727]),
//             children: [],
//           },
//           {
//             path: '/credit-note-entry',
//             title: 'Credit note',
//             icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
//             show: isPermissionExist([747]),
//             component: <></>,
//           },
//         ],
//       },
//       {
//         path: 'performaCostingSale',
//         title: `Account Receivables`,
//         icon: <antdIcons.RotateLeftOutlined />,
//         component: null,
//         show: isPermissionExist([313]),
//         children: [
//           {
//             path: 'performaCostingSale',
//             title: `Performa Sale`,
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([313]),
//           },

//           {
//             path: 'commercial-costing-sales',
//             title: `Commercial Sales`,
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([334]),
//             children: [],
//           },
//           {
//             path: 'salesFreightCosting',
//             title: `Freight Costing`,
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([313]),
//           },

//           {
//             path: '/other-income-expense-entry',
//             title: 'Expense Entry',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([
//               417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667,
//               687,
//             ]),
//             children: [],
//           },
//           {
//             path: '/debit-note-entry',
//             title: 'Debit Note',
//             icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
//             show: isPermissionExist([747]),
//             component: <></>,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     path: '/costingApprovals',
//     title: 'Business Approvals',
//     icon: <Icon component={ApproveReject} style={{ fontSize: '20px' }} />,
//     component: null,
//     show: true,
//     children: [
//       {
//         path: 'Account_payables',
//         title: `Account Payables`,
//         icon: <antdIcons.RotateRightOutlined />,
//         component: null,
//         show: isPermissionExist([
//           275, 296, 317, 338, 359, 380, 400, 421, 442, 463, 484, 505, 526, 547,
//           568, 589, 610, 630, 650, 670, 690, 710, 730, 750,
//         ]),
//         children: [
//           {
//             path: '/performa-pc-approval',
//             title: 'Performa Purchase',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([275]),
//             children: [],
//           },
//           {
//             path: '/commercial-pc-approval',
//             title: 'Commercial Purchase',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([317]),
//             children: [],
//           },
//           {
//             path: '/procurement-approval',
//             title: 'Procurement Fees',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([359]),
//             children: [],
//           },
//           {
//             path: '/marketing-approval',
//             title: 'Marketing Fees',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([380]),
//             children: [],
//           },

//           {
//             path: '/barge-costings/barge-approval',
//             title: 'Barge Costing',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([400]),
//             children: [],
//           },
//           {
//             path: '/expense-entry-approval',
//             title: 'Expense Entry',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([
//               421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670,
//               690,
//             ]),
//             children: [],
//           },
//           {
//             path: '/dead-freight-approval',
//             title: 'Dead Freight',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([710]),
//             children: [],
//           },
//           {
//             path: '/demurrage-approval',
//             title: 'Demurrage ',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([730]),
//             children: [],
//           },
//           {
//             path: '/credit-note-approval',
//             title: 'Credit Approval',
//             icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
//             show: isPermissionExist([750]),
//             component: <></>,
//           },
//           {
//             path: '/advance-business-approval',
//             title: 'Advances',
//             icon: <antdIcons.PayCircleOutlined />,
//             // show: isPermissionExist([750]),
//             show: true,
//             component: <></>,
//           },
//         ],
//       },
//       {
//         path: 'Account_receivables',
//         title: `Account Receivables`,
//         icon: <antdIcons.RotateRightOutlined />,
//         component: null,
//         show: isPermissionExist([298, 712, 732]),
//         children: [
//           {
//             path: '/performa-sc-approval',
//             title: 'Performa Sales',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([296]),
//             children: [],
//           },
//           {
//             path: '/commercial-sc-approval',
//             title: 'Commercial Sales',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([334, 338]),
//             children: [],
//           },
//           {
//             path: '/sc-freight-costing-approval',
//             title: 'Freight Costing',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([296]),
//             children: [],
//           },
//           {
//             path: '/rec-expense-entry-approval',
//             title: 'Expense Entry',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([
//               421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670,
//               690,
//             ]),
//             children: [],
//           },
//           {
//             path: '/dead-freight-receivable-approval',
//             title: 'Dead Freight',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([710]),
//             children: [],
//           },
//           {
//             path: '/demurrage-receivable-approval',
//             title: 'Demurrage',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([730]),
//             children: [],
//           },
//           {
//             path: '/debit-note-approval',
//             title: 'Debit Approval',
//             show: isPermissionExist([750]),
//             icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
//             component: <></>,
//           },
//           {
//             path: '/advance-receivable-business-approval',
//             title: 'Advance Receivables',
//             show: isPermissionExist([750]),
//             icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
//             component: <></>,
//           },
//         ],
//       },

//       // {
//       //     path: '/commercial-sc-approval',
//       //     title: 'Commercial Sales',
//       //     icon: <antdIcons.PayCircleOutlined />,
//       //     component: null,
//       //      show: isPermissionExist([338])
//       //     children: [],

//       // },

//       //TODO:have to check
//       // {
//       //     path: '/coalCostView',
//       //     title: 'CoalCostView',
//       //     icon: <antdIcons.PayCircleOutlined />,
//       //     component: null,
//       //     children: []
//       // },
//     ],
//   },
//   {
//     path: '/audit-approvals',
//     title: 'Audit Approvals',
//     icon: <antdIcons.AuditOutlined style={{ fontSize: 15 }} />,
//     show: isPermissionExist([
//       334, 338, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670,
//       690,
//     ]),
//     component: null,
//   },
//   {
//     path: '/managementApprovals',
//     title: 'Management Review',
//     show: isPermissionExist([298, 712, 732]),
//     icon: <antdIcons.UsergroupAddOutlined style={{ fontSize: 15 }} />,

//     // icon: <Icon component={ApproveReject} style={{ fontSize: '20px' }} />,
//     component: null,
//     children: [
//       {
//         path: 'Account_payables',
//         title: `Account Payables`,
//         icon: <antdIcons.RotateRightOutlined />,
//         component: null,
//         show: isPermissionExist([298, 712, 732]),
//         children: [
//           {
//             path: '/commercial-pc-management-approval',
//             title: 'Commercial Purchase',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([298]),
//             children: [],
//           },
//           {
//             path: '/dead-freight-management-approval',
//             title: 'Dead Freight',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([712]),
//             children: [],
//           },
//           {
//             path: '/demurrage-management-approval',
//             title: 'Demurrage ',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([732]),
//             children: [],
//           },
//         ],
//       },

//       {
//         path: 'Account_Receivables',
//         title: `Account Receivables`,
//         icon: <antdIcons.RotateLeftOutlined />,
//         component: null,
//         show: true,
//         children: [
//           {
//             path: '/commercial-sc-approval',
//             title: 'Commercial Sales',
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([298]),
//             children: [],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     path: '/payment-notice-creation',
//     title: 'Costing Finance',
//     show: isPermissionExist([
//       287, 308, 329, 350, 371, 391, 412, 433, 454, 475, 496, 517, 538, 559, 580,
//       601, 622, 642, 662, 682, 702, 722, 742, 762,
//     ]),
//     icon: <Icon component={FinanceApprove} style={{ fontSize: '20px' }} />,
//     component: null,
//     // children: [
//     //   // {
//     //   //   path: '/barge-costings/barge-pmt-notice',
//     //   //   title: 'Advance Adjustment - Barge',
//     //   //   icon: <antdIcons.PayCircleOutlined />,
//     //   //   component: null,
//     //   //   show: isPermissionExist([412]),
//     //   //   children: []
//     //   // },
//     //   {
//     //     path: '/payment-notice-creation',
//     //     title: 'Payment Notice Creation',
//     //     icon: <antdIcons.PayCircleOutlined />,
//     //     component: null,
//     //     show: isPermissionExist([287, 308, 329, 350, 371, 391, 412, 433, 454, 475, 496, 517, 538, 559, 580, 601, 622, 642, 662, 682, 702, 722, 742, 762]),
//     //     children: []
//     //   },
//     //   {
//     //     path: '/payment-notice-approval',
//     //     title: 'Payment Notice Approval',
//     //     icon: <antdIcons.PayCircleOutlined />,
//     //     component: null,
//     //     show: isPermissionExist([288, 309, 330, 351, 372, 392, 413, 434, 455, 476, 497, 518, 539, 560, 581, 602, 623, 643, 663, 683, 703, 723, 743, 763]),
//     //     children: []
//     //   },
//     //   {
//     //     path: '/payment-notice-release',
//     //     title: 'Payment Notice Release',
//     //     icon: <antdIcons.PayCircleOutlined />,
//     //     component: null,
//     //     show: isPermissionExist([290, 311, 332, 353, 374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725, 745, 765]),
//     //     children: []
//     //   },
//     //   {
//     //     path: '/closed-payment-notices',
//     //     title: 'Closed Payment Notices',
//     //     icon: <antdIcons.PayCircleOutlined />,
//     //     component: null,
//     //     show: isPermissionExist([290, 311, 332, 353, 374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725, 745, 765]),
//     //     children: []
//     //   },
//     //   // {
//     //   //   path: '/dead-freight-accounts-entry',
//     //   //   title: 'Dead Freight Accounts Entry',
//     //   //   icon: <antdIcons.PayCircleOutlined />,
//     //   //   component: null,
//     //   //   show: isPermissionExist([722]),
//     //   //   children: []
//     //   // },
//     //   // {
//     //   //   path: '/demurrage-accounts-entry',
//     //   //   title: 'Demurrage Accounts Entry',
//     //   //   icon: <antdIcons.PayCircleOutlined />,
//     //   //   component: null,
//     //   //   show: isPermissionExist([742]),
//     //   //   children: []
//     //   // },
//     // ]
//   },
//   // {
//   //   path: '/receivedApprovals',
//   //   title: 'Received Approvals',
//   //   show: isPermissionExist([334, 338, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690]),
//   //   icon: <Icon component={ApproveReject} style={{ fontSize: '20px' }} />,
//   //   component: null,
//   //   children: [
//   //     {
//   //       path: '/commercial-sc-approval',
//   //       title: 'Commercial Sales',
//   //       icon: <antdIcons.PayCircleOutlined />,
//   //       component: null,
//   //       show: isPermissionExist([334, 338]),
//   //       children: []
//   //     },
//   //     {
//   //       path: '/rec-expense-entry-approval',
//   //       title: 'Expense Entry',
//   //       icon: <antdIcons.PayCircleOutlined />,
//   //       component: null,
//   //       show: isPermissionExist([421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690]),
//   //       children: []
//   //     },
//   //     {
//   //       path: '/dead-freight-receivable-approval',
//   //       title: 'Dead Freight',
//   //       icon: <antdIcons.PayCircleOutlined />,
//   //       component: null,
//   //       show: isPermissionExist([710]),
//   //       children: []
//   //     },
//   //     {
//   //       path: '/demurrage-receivable-approval',
//   //       title: 'Demurrage',
//   //       icon: <antdIcons.PayCircleOutlined />,
//   //       component: null,
//   //       show: isPermissionExist([730]),
//   //       children: []
//   //     },
//   //     {
//   //       path: '/debit-note-approval',
//   //       title: 'Debit Approval',
//   //       show: isPermissionExist([750]),
//   //       icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
//   //       component: <></>
//   //     },

//   //   ]
//   // },
//   {
//     path: '/approve-payment',
//     title: 'Receivables',
//     show: isPermissionExist([
//       287, 308, 329, 350, 371, 391, 412, 433, 454, 475, 496, 517, 538, 559, 580,
//       601, 622, 642, 662, 682, 702, 722, 742, 762,
//     ]),
//     icon: <Icon component={FinanceApprove} style={{ fontSize: '20px' }} />,
//     component: null,
//     // children: [
//     //   // {
//     //   //     path: '/aprove-payment',
//     //   //     title: 'Receive Payment',
//     //   //     icon: <antdIcons.PayCircleOutlined />,
//     //   //     component: null,
//     //   //     children: []
//     //   // },
//     //   {
//     //     path: '/approve-payment',
//     //     title: 'Approve Payment',
//     //     icon: <antdIcons.PayCircleOutlined />,
//     //     component: null,
//     //     show: isPermissionExist([288, 309, 330, 351, 372, 392, 413, 434, 455, 476, 497, 518, 539, 560, 581, 602, 623, 643, 663, 683, 703, 723, 743, 763]),
//     //     children: []
//     //   },
//     //   {
//     //     path: '/release-payment',
//     //     title: 'Release Payment',
//     //     icon: <antdIcons.PayCircleOutlined />,
//     //     component: null,
//     //     show: isPermissionExist([290, 311, 332, 353, 374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725, 745, 765]),
//     //     children: []
//     //   },
//     //   {
//     //     path: '/receivable-closed-payment',
//     //     title: 'Closed Payment',
//     //     icon: <antdIcons.PayCircleOutlined />,
//     //     component: null,
//     //     show: isPermissionExist([290, 311, 332, 353, 374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725, 745, 765]),
//     //     children: []
//     //   },
//     //   // {
//     //   //   path: '/dead-freight-receivable-transactions',
//     //   //   title: 'Dead Freight',
//     //   //   icon: <antdIcons.PayCircleOutlined />,
//     //   //   component: null,
//     //   //   show: isPermissionExist([722]),
//     //   //   children: []
//     //   // },
//     //   // {
//     //   //   path: '/demurrage-receivable-transactions',
//     //   //   title: 'Demurrage',
//     //   //   icon: <antdIcons.PayCircleOutlined />,
//     //   //   component: null,
//     //   //   show: isPermissionExist([742]),
//     //   //   children: []
//     //   // },
//     // ]
//   },
//   {
//     path: '/sampling-analysis',
//     title: `Sampling Analysis`,
//     show: isPermissionExist([
//       836, 858, 841, 862, 846, 866, 851, 874, 857, 861, 865, 869, 873, 876, 882,
//       883, 886,
//     ]),
//     icon: <Icon component={SamplingIcon} style={{ fontSize: '20px' }} />,
//     component: null,
//     children: [
//       {
//         path: '/purchase-sampling',
//         title: `Purchase Quality`,
//         icon: (
//           <Icon component={PurchaseSamplingIcon} style={{ fontSize: '20px' }} />
//         ),
//         component: null,
//         show: isPermissionExist([
//           836, 858, 841, 862, 846, 866, 851, 874, 857, 861, 865, 869, 873,
//         ]),
//         children: [
//           {
//             path: 'PurchaseS&A',
//             title: `Purchase S&A`,
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([836, 858, 842, 862, 846, 866, 851, 874]),
//             children: [
//               {
//                 path: '/purchase-sampling/supplier',
//                 title: `S&A Supplier`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([836, 858]),
//                 children: [],
//               },

//               {
//                 path: '/purchase-sampling/company',
//                 title: `S&A SRPL`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([842, 862]),
//                 children: [],
//               },
//               {
//                 path: '/purchase-sampling/actual',
//                 title: `S&A Actual`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([846, 866]),
//                 children: [],
//               },
//               {
//                 path: '/purchase-sampling/shipment',
//                 title: `S&A Pre-Shipment`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([851, 874]),
//                 children: [],
//               },
//             ],
//           },
//           {
//             path: '',
//             title: `Reports`,
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             show: isPermissionExist([857, 861, 865, 869, 873]),
//             children: [
//               {
//                 path: '/purchase-sampling-reports/supplier',
//                 title: `Purchase Supplier Report`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([857]),
//                 children: [],
//               },
//               {
//                 path: '/purchase-sampling-reports/company',
//                 title: `SRPL Reports`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([861]),
//                 children: [],
//               },
//               {
//                 path: '/purchase-sampling-reports/actual',
//                 title: `Actual Report`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([865]),
//                 children: [],
//               },
//               {
//                 path: '/purchase-sampling-reports/aging-report',
//                 title: `Purchase Aging Report`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([869]),
//                 children: [],
//               },
//               {
//                 path: '/purchase-sampling-reports/pre-shipment',
//                 title: `Pre-Shipment Report`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([873]),
//                 children: [],
//               },
//             ],
//           },
//         ],
//       },
//       {
//         path: 'salesQuality',
//         title: `Sales Quality`,
//         show: isPermissionExist([876, 883, 882, 886]),
//         icon: (
//           <Icon component={SalesSamplingIcon} style={{ fontSize: '20px' }} />
//         ),
//         component: null,
//         children: [
//           {
//             path: '/sales-sampling',
//             title: `Sale S&A`,
//             show: isPermissionExist([876, 877]),
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             children: [
//               {
//                 path: 'sales-sampling/actual',
//                 title: `S&A Actual`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([876, 877]),
//                 children: [],
//               },
//             ],
//           },
//           {
//             path: '/Reports',
//             title: `Report`,
//             show: isPermissionExist([882, 886]),
//             icon: <antdIcons.PayCircleOutlined />,
//             component: null,
//             children: [
//               {
//                 path: 'sales-sampling-reports/actual',
//                 title: `Supplier Report `,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 show: isPermissionExist([882]),
//                 component: null,
//                 children: [],
//               },
//               {
//                 path: '/sales-sampling-reports/aging-report',
//                 title: `Aging Report`,
//                 icon: <antdIcons.PayCircleOutlined />,
//                 component: null,
//                 show: isPermissionExist([886]),
//                 children: [],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     path: '/budget-costing',
//     title: 'CostingDetails',
//     show: isPermissionExist([767]),
//     icon: (
//       <Icon component={BudgetAndCostingIcon} style={{ fontSize: '20px' }} />
//     ),
//     component: null,
//     children: [
//       {
//         path: '/budget-costing',
//         title: 'Budget Costing',
//         // show: isPermissionExist([773]),
//         show: isPermissionExist([767]),
//         icon: <Icon component={AccountGroups} style={{ fontSize: '20px' }} />,
//         component: <></>,
//       },
//       {
//         path: '/markup-price',
//         title: 'Markup Price',
//         show: isPermissionExist([779]),
//         icon: <Icon component={Ledger} style={{ fontSize: '20px' }} />,
//         component: <></>,
//       },
//     ],
//   },
//   {
//     path: '/lc-tracker',
//     title: 'Lc Tracker',
//     show: isPermissionExist([773, 779]),
//     icon: <Icon component={LcIcon} style={{ fontSize: '20px' }} />,
//     component: null,
//     children: [
//       {
//         path: '/purchase-lc-tracker',
//         title: 'Purchase LcTracker',
//         show: isPermissionExist([773]),
//         icon: <Icon component={AccountGroups} style={{ fontSize: '20px' }} />,
//         component: <></>,
//       },
//       {
//         path: '/sales-lc-tracker',
//         title: 'Sales LcTracker',
//         show: isPermissionExist([779]),
//         icon: <Icon component={Ledger} style={{ fontSize: '20px' }} />,
//         component: <></>,
//       },
//     ],
//   },
//   {
//     path: '/accountsModule',
//     title: 'Accounts',
//     show: isPermissionExist([785, 791, 797, 803, 809, 815, 821, 827]),
//     icon: <Icon component={Coins} style={{ fontSize: '20px' }} />,
//     component: null,
//     children: [
//       {
//         path: '/account-groups',
//         title: 'Accounts Groups',
//         show: isPermissionExist([785]),
//         icon: <Icon component={AccountGroups} style={{ fontSize: '20px' }} />,
//         component: <></>,
//       },
//       {
//         path: '/accounts',
//         title: 'Ledgers',
//         show: isPermissionExist([791]),
//         icon: <Icon component={Ledger} style={{ fontSize: '20px' }} />,
//         component: <></>,
//       },
//       {
//         path: '/payment-voucher',
//         title: 'Payment Vouchers',
//         show: isPermissionExist([797]),
//         icon: <Icon component={Voucher} style={{ fontSize: '20px' }} />,
//         component: <></>,
//       },
//       {
//         path: '/receipt-voucher',
//         title: 'Receipt Vouchers',
//         show: isPermissionExist([803]),
//         icon: <Icon component={ReceiptVoucher} style={{ fontSize: '20px' }} />,
//         component: <></>,
//       },
//       {
//         path: '/journalVoucher',
//         title: 'Journal Vouchers',
//         show: isPermissionExist([809]),
//         icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
//         component: <></>,
//       },

//       {
//         path: '/reports',
//         title: 'Accounting Reports',
//         show: isPermissionExist([815, 821, 827]),
//         icon: <Icon component={FinReports} style={{ fontSize: '20px' }} />,
//         component: null,
//         children: [
//           {
//             path: '/ledger',
//             show: isPermissionExist([815]),
//             title: 'Accounts Ledger',
//             icon: <Icon component={LedgerBook} style={{ fontSize: '20px' }} />,
//             component: <></>,
//           },
//           {
//             path: '/accountsPayable',
//             title: 'Payable',
//             show: isPermissionExist([821]),
//             icon: <Icon component={PayableIcon} style={{ fontSize: '20px' }} />,
//             component: <></>,
//           },
//           {
//             path: '/accountReceivables',
//             title: 'Receivables',
//             show: isPermissionExist([827]),
//             icon: (
//               <Icon component={ReceivableIcon} style={{ fontSize: '20px' }} />
//             ),
//             component: <></>,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     path: '/userManagement',
//     title: 'User Management',
//     show: isPermissionExist([1]),
//     icon: <Icon component={UserManagementIcon} style={{ fontSize: '20px' }} />,
//     children: [
//       // {
//       //     path: '/attribute',
//       //     key: '/attribute',
//       //     title: 'Attributes',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <AttributePage />,
//       // },
//       // {
//       //     path: '/applications',
//       //     key: '/applications',
//       //     title: 'Applications',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <ApplicationPage />,
//       // },
//       // {
//       //     path: '/modules',
//       //     key: '/modules',
//       //     title: 'Modules',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <ModulePage />,
//       // },
//       // {
//       //     path: '/menu',
//       //     key: '/menu',
//       //     title: 'Menu',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <MenuPage />,
//       // },
//       // {
//       //     path: '/sub-menu',
//       //     key: '/sub-menu',
//       //     title: 'Sub-Menu',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <SubMenuPage />,
//       // },
//       // {
//       //     path: '/scopes',
//       //     key: '/scopes',
//       //     title: 'Scopes',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <ScopePage />,
//       // },
//       // {
//       //     path: '/permissions',
//       //     key: '/permissions',
//       //     title: 'Permissions',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <PermissionsPage />,
//       // },
//       // {
//       //     path: '/client',
//       //     key: '/client',
//       //     title: 'Client',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <OrganizationPage />,
//       // },
//       // {
//       //     path: '/clientToApplicationMapping',
//       //     key: '/clientToApplicationMapping',
//       //     title: 'Client-Application',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <ClientToApplicationsMapping />,
//       // },
//       {
//         path: '/roles',
//         key: '/roles',
//         title: 'Roles',
//         show: isPermissionExist([1]),
//         icon: <antdIcons.HomeOutlined />,
//         component: <RolesPage />,
//       },
//       {
//         path: '/user-creation',
//         key: '/user-creation',
//         title: 'User',
//         show: isPermissionExist([1]),
//         icon: <antdIcons.HomeOutlined />,
//         component: <UserPage />,
//       },
//       {
//         path: '/user-role-mappings',
//         key: '/user-role-mappings',
//         title: 'User Role Mapping',
//         show: isPermissionExist([1]),
//         icon: <antdIcons.HomeOutlined />,
//         component: <UserRoleMappings />,
//       },
//       // {
//       //   path: '/role-permissions-mapping',
//       //   key: '/role-permissions-mapping',
//       //   title: 'Role Permissions Mapping',
//       //   show: isPermissionExist([1]),
//       //   icon: <antdIcons.HomeOutlined />,
//       //   component: <RolePermissionsMapping />,
//       // },
//       // {
//       //     path: '/client-menu',
//       //     key: '/client-menu',
//       //     title: 'Client Menu',
//       //     icon: <antdIcons.HomeOutlined />,
//       //     component: <> </>,
//       //     children: [
//       //         {
//       //             path: '/units',
//       //             key: '/units',
//       //             title: 'Units',
//       //             icon: <antdIcons.HomeOutlined />,
//       //             component: <UnitPage />,
//       //         },
//       //         {
//       //             path: '/roles',
//       //             key: '/roles',
//       //             title: 'Roles',
//       //             icon: <antdIcons.HomeOutlined />,
//       //             component: <RolesPage />,
//       //         },
//       //         {
//       //             path: '/user-creation',
//       //             key: '/user-creation',
//       //             title: 'User',
//       //             icon: <antdIcons.HomeOutlined />,
//       //             component: <UserPage />,
//       //         },
//       //         {
//       //             path: '/user-role-mappings',
//       //             key: '/user-role-mappings',
//       //             title: 'User Role Mapping',
//       //             icon: <antdIcons.HomeOutlined />,
//       //             component: <UserRoleMappings />,
//       //         },
//       //         {
//       //             path: '/role-permissions-mapping',
//       //             key: '/role-permissions-mapping',
//       //             title: 'Role Permissions Mapping',
//       //             icon: <antdIcons.HomeOutlined />,
//       //             component: <RolePermissionsMapping />,
//       //         },
//       //     ]
//       // }
//     ],
//   },
//   {
//     path: '/costingReports',
//     title: 'Reports',
//     show: isPermissionExist([1, 890, 891, 892]),
//     icon: <Icon component={PieChartTwoTone} style={{ fontSize: '20px' }} />,
//     children: [
//       {
//         path: '/costing-reports',
//         key: '/costing-reports',
//         title: 'Costing Reports',
//         show: isPermissionExist([1]),
//         // icon: <PieChartTwoTone />,
//         component: <CostingReports />,
//       },
//       {
//         path: '/loading-plan',
//         key: '/loading-plan',
//         title: 'Loading Plans',
//         show: isPermissionExists([889, 890, 891]),
//         component: <LoadingPlansList />,
//       },
//       {
//         path: '/LTC-report',
//         key: '/LTC-report',
//         title: 'LTC Report',
//         show: isPermissionExist([1]),
//         components: <LTCReports />,
//       },
//       {
//         path: '/pl-report',
//         key: '/pl-report',
//         title: 'PL Report',
//         show: isPermissionExist([1]),
//         components: <ProfitLossReports />
//       }
//     ],
//   },
// ];

const BasicLayout = React.memo(() => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeParent, setActiveParent] = useState<string | null>();
  const [activeChild, setActiveChild] = useState<string | null>();
  const [isChildSidebarVisible, setIsChildSidebarVisible] =
    useState<boolean>(false);

  const location = useLocation();

  const fistTime = localStorage.getItem('firstTimeLogin');

  useEffect(() => {
    if (fistTime === 'true') {
      localStorage.setItem('firstTimeLogin', 'false');
      window.location.reload();
    }
  }, [fistTime]);

  useEffect(() => {
    const storedParent = localStorage.getItem('activeParent');
    const storedChild = localStorage.getItem('activeChild');
    if (storedParent) setActiveParent(storedParent);
    if (storedChild) setActiveChild(storedChild);
  }, []);

  const toggle = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  };

  // const getSubMenu = (route) => {
  //   if (route?.show) {
  //     if (route && route.children && route.children.length) {
  //       return {
  //         key: `${route.key ? route.key : route.path}`,
  //         icon: route.icon, //React.createElement(route.icon),renderIcon(route.iconType, route.iconName)
  //         label: route.title,
  //         children: route.children.map((item) => getSubMenu(item)),
  //       };
  //     } else {
  //       return {
  //         key: `${route.key ? route.key : route.path}`,
  //         icon: route.icon, //React.createElement(route.icon),
  //         label: (
  //           <Link to={`${route.path}`}>
  //             <span>{route.title}</span>
  //           </Link>
  //         ),
  //       };
  //     }
  //   }
  // };
  // const getAllSubMenus = () => {
  //   const subMenus: MenuProps['items'] = [];
  //   menus.forEach((eachRoutes) => {
  //     if (eachRoutes.show) subMenus.push(getSubMenu(eachRoutes));
  //   });
  //   return subMenus;
  // };

  const handleParentClick = (parentTitle: string) => {
    if (parentTitle === 'Dashboard') {
      localStorage.setItem('activeChild', 'null');
    }

    if (activeParent === parentTitle) {
      setIsChildSidebarVisible(!isChildSidebarVisible);
    } else {
      setActiveParent(parentTitle);
      localStorage.setItem('activeParent', parentTitle);
      setIsChildSidebarVisible(true);
    }
  };

  const handleChildClick = (subItem: any) => {
    setActiveChild(subItem.id);
    localStorage.setItem('activeChild', subItem.id);
    setIsChildSidebarVisible(false);
  };

  const extractPathsWithTitles = (menuItems) => {
    const pathsWithTitles = {};

    menuItems.forEach((item) => {
      if (item.path) {
        pathsWithTitles[item.path] = item.title || '';
      }
      if (item.children && item.children.length > 0) {
        Object.assign(pathsWithTitles, extractPathsWithTitles(item.children));
      }
    });

    return pathsWithTitles;
  };

  // Extract paths and titles from the menu
  const menuPaths = extractPathsWithTitles(menu);
  const currentPath = location.pathname;
  const pageTitle = menuPaths[currentPath] || '';

  return (
    // <Layout style={{ minHeight: '100vh' }}>
    //   <Sider
    //     // className="layout"
    //     // trigger={null}
    //     // collapsible
    //     collapsed={true}
    //     // breakpoint="lg"
    //     // collapsedWidth="80"
    //     width={80}
    //     style={{
    //       overflow: "auto",
    //       background: "#fff",
    //       marginTop: "56px",
    //     }}
    //   >
    //     <div style={{ height: "calc(100% - 60px)", overflow: "auto" }}>
    //       <Menu
    //         theme="light"
    //         mode="inline"
    //         defaultSelectedKeys={["1"]}
    //         style={{ marginTop: "20px" }}
    //         items={getAllSubMenus()}
    //       />
    //     </div>
    //   </Sider>

    //   <Layout className="site-layout" >
    //     <CommonHeader key={Date.now()} toggle={toggle} collapsed={collapsed} />
    //     <Content
    //       className="site-layout-background"
    //       style={{
    //         margin: '78px 16px',
    //         background: '#f0f2f5',
    //         height: 'auto'
    //       }}
    //     >
    //       {/* <HeaderBreadcrum title='Guna' key={Date.now()} toggle={toggle} collapsed={collapsed} /> */}
    //       <Outlet />
    //     </Content>
    //     {/* <Affix offsetBottom={0}>
    //                 <Footer style={{ textAlign: 'center', background: '#f0f2f5' }}>©2023 Design and Developed by SchemaX</Footer>
    //             </Affix> */}
    //   </Layout>
    // </Layout>
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        width={80}
        collapsed={true}
        style={{
          background: '#fff',
          position: 'fixed',
          left: 0,
          top: 56,
          height: 'calc(100vh - 56px)',
          overflow: 'auto',
        }}
      >
        <div>
          {menu.slice(0, -1).map((item, index) => (
            <>
              {item.show && (
                <a
                  key={item.id + '-' + index}
                  href={item.path}
                  style={{
                    display: 'block',
                    textDecoration: 'none',
                    padding: '0 6px',
                  }}
                  onClick={() => handleParentClick(item.title)}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '8px 0',
                      cursor: 'pointer',
                      color: activeParent === item.title ? '#fff' : '#323F49',
                      background:
                        activeParent === item.title ? '#0295D4' : '#fff',
                      borderRadius: 8,
                      fontWeight: activeParent === item.title ? 600 : '',
                    }}
                  >
                    <span style={{ fontSize: 24 }}>{item.icon}</span>
                    <span style={{ fontSize: 10 }}>{item.title}</span>
                  </div>
                </a>
              )}
            </>
          ))}
        </div>

        {menu[menu.length - 1].show && (
          <div
            style={{
              position: 'absolute',
              bottom: 12,
              width: '100%',
            }}
          >
            <a
              href={menu[menu.length - 1].path}
              style={{
                display: 'block',
                textDecoration: 'none',
                padding: '0 6px',
              }}
              onClick={() => handleParentClick(menu[menu.length - 1].title)}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '8px 0',
                  cursor: 'pointer',
                  color:
                    activeParent === menu[menu.length - 1].title
                      ? '#fff'
                      : '#323F49',
                  background:
                    activeParent === menu[menu.length - 1].title
                      ? '#0295D4'
                      : '#fff',
                  borderRadius: 8,
                  fontWeight:
                    activeParent === menu[menu.length - 1].title ? 600 : '',
                }}
              >
                <span style={{ fontSize: 24 }}>
                  {menu[menu.length - 1].icon}
                </span>
                <span style={{ fontSize: 10 }}>
                  {menu[menu.length - 1].title}
                </span>
              </div>
            </a>
          </div>
        )}
      </Sider>

      {isChildSidebarVisible &&
        activeParent &&
        menu.find((item) => item.title === activeParent)?.children?.length >
          0 && (
          <div
            style={{
              position: 'fixed',
              left: 80,
              top: 56,
              width: 200,
              height: 'calc(100vh - 56px)',
              background: '#fff',
              border: '1px solid #ddd',
              overflow: 'auto',
              padding: '10px',
            }}
          >
            <h5>{activeParent}</h5>
            {menu
              .find((item) => item.title === activeParent)
              ?.children.map((subItem, index) => (
                <div key={subItem.id + '-' + index}>
                  {subItem?.show && <Divider />}
                  <>
                    {subItem.show && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px',
                        }}
                      >
                        <span
                          style={{
                            fontSize: 20,
                            color: '#565E6C',
                            width: '26px',
                            textAlign: 'center',
                          }}
                        >
                          {subItem.icon}
                        </span>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                        >
                          {subItem.title}
                        </span>
                      </div>
                    )}
                    <>
                      {subItem?.children?.map((child, index) => (
                        <div key={child.id + '-' + index}>
                          {child.show && (
                            <Link
                              to={`${child.path}`}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                                padding: '6px 0',
                                color: '#565E6C',
                                background:
                                  activeChild === child.id ? '#CCF0FF' : '#fff',
                                borderRadius: 4,
                                textDecoration: 'none',
                              }}
                              onClick={() => handleChildClick(child)}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.color = '#1677FF')
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.color = '#565E6C')
                              }
                            >
                              <span
                                style={{ width: '26px', textAlign: 'center' }}
                              ></span>
                              <span>{child.title}</span>
                            </Link>
                          )}
                        </div>
                      ))}
                    </>
                  </>
                </div>
              ))}
            <Divider />
          </div>
        )}

      <Layout
        style={{
          marginLeft: isChildSidebarVisible ? 280 : 80,
          transition: 'margin-left 0.6s',
        }}
      >
        <CommonHeader key={Date.now()} toggle={toggle} collapsed={collapsed} />
        <Content
          style={{
            margin: '68px 12px',
            background: '#F5F5F5',
            minHeight: 'calc(100vh - 68px)',
          }}
        >
          {menuPaths[currentPath] && currentPath !== '/' && (
            <>
              <HeaderBreadcrumb
                title={pageTitle}
                toggle={() => setIsChildSidebarVisible((prev) => !prev)}
              />
            </>
          )}
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
});

export default BasicLayout;
