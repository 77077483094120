import { useEffect, useState } from "react"
import moment from "moment";
import { useAuthState } from "../../../common";
import { BargeCostingIdReqDto, BargeCostingsService } from "@exportx/shared-models-and-services"
import { Card, Col, Divider, Flex, Row, Typography } from "antd";
import { internationalFormattedValue } from "@exportx/ui-utils";

const { Text } = Typography;
interface DeadFreightDetailsProps {
  costingId: string;
  remainingAmount?: number;
  children?: React.ReactNode;
}

export const DeadFreightApprovalDetails = (props: DeadFreightDetailsProps) => {
  const { costingId, remainingAmount, children } = props;
  const { authContext } = useAuthState();
  const bargeCostingService = new BargeCostingsService();
  const [data, setData] = useState<any>();

  useEffect(() => {

    const req = new BargeCostingIdReqDto(costingId, authContext.defaultPlant)
    bargeCostingService.getDeadFreightDataById(req).then(res => {
      if (res.status) {
        setData(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })

  }, [costingId])



  return (
    <>

      <Row gutter={8}>
        <Col span={16}>
          {children}
        </Col >

        <Col span={8}>
          <Card
            className="card-shadow"
            title={<span className="card-title-md">Price Details</span>}
            styles={{ body: { padding: '6px 14px' } }}
          >

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">dead Freight</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.deadFreight, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Price</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.pricePerMT, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Date</Text>
              <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.exchangeRate, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Amount</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.amount, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Discount</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.discount, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Amount After Discount</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.amtAftDiscount, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>

            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Tax 1</Text>
              <Text className="price-card-text">{`${data?.taxName1 || 'N/A'} (${data?.taxPercent1 || '0'})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.taxTotal1, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Tax 2</Text>
              <Text className="price-card-text">{`${data?.taxName2 || 'N/A'} (${data?.taxPercent2 || '0'})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.taxTotal2, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TDS</Text>
              <Text className="price-card-text">{`${data?.tdsName || 'N/A'} (${data?.tdsPercentage || '0'})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.tdsTotal, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>


            <Divider dashed={true} />
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading">Total Amount</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(data?.totalAmount, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>

            <Flex justify="space-between" align='center'>
              <Text className="price-card-green">To be paid to Supplier</Text>
              <Text className="price-card-green">
                {internationalFormattedValue(data?.totalAmount, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>
            {Number(remainingAmount) > 0 &&

              <Flex justify="space-between" align='center'>
                <Text className="price-card-red">Remaining Amount</Text>
                <Text className="price-card-red">
                  {internationalFormattedValue(remainingAmount, 3)}  {data?.baseCurrency}
                </Text>
              </Flex>
            }
          </Card>
        </Col>
      </Row>
    </>
  )
}