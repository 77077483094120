import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
} from '@ant-design/icons';
import {
  BargeService,
  BNoRequest,
  BusinessNoDropDownDto,
  BusinessNoPlantCodeReq,
  BusinessNumberService,
  ContractModesEnum,
  CostingReportsReqDto,
  PurchaseContractService,
  PurchaseDropDownDto,
  PurchaseSupplierReportDto,
  SalesContractService,
  SamplingAndAnalysisService,
  SamplingReportsReqDto,
  SamplingSampleTypeReqDto,
  SamplingTypes,
} from '@exportx/shared-models-and-services';
import { SequenceUtils } from '@exportx/ui-utils';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import Link from 'antd/es/typography/Link';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  isPermissionExist,
  useAuthState,
} from '../../../../common';
import { Option } from 'antd/es/mentions';

interface IActualReportProps {
  sampleType?: SamplingTypes;
  contractType: ContractModesEnum;
}

export const ActualReport = (props: IActualReportProps) => {
  const { formatMessage: fm } = useIntl();
  const { sampleType, contractType } = props;
  const [purchaseSupplier, setPurchaseSupplier] = useState<
    PurchaseSupplierReportDto[]
  >([]);
  const { authContext } = useAuthState();
  const [searchedText, setSearchedText] = useState('');
  const mapToGroupByGroupId = new Map<number, any[]>();
  const navigate = useNavigate();
  const [editPermission, setEditPermission] = useState<boolean>(false);
  const [deletePermission, setDeletePermission] = useState<boolean>(false);
  const [formRef] = Form.useForm();
  const [businessDropDown, setBusinessDropDown] = useState<
    BusinessNoDropDownDto[]
  >([]);
  const [dropDown, setDropDown] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [purchases, setPurchases] = useState<PurchaseDropDownDto[]>([]);
  const [sales, setSales] = useState<any>([]);

  let pathToreDirect = '/barge-detail-view';

  const qualityService = new SamplingAndAnalysisService();
  const businessService = new BusinessNumberService();
  const bargeService = new BargeService();
  const purchase = new PurchaseContractService();
  const salesService = new SalesContractService();

  useEffect(() => {
    if (contractType === ContractModesEnum.PURCHASE) {
      switch (sampleType) {
        case SamplingTypes.SUPPLIER:
          setEditPermission(isPermissionExist([858]));
          setDeletePermission(isPermissionExist([859]));
          break;
        case SamplingTypes.INTERNAL:
          setEditPermission(isPermissionExist([862]));
          setDeletePermission(isPermissionExist([863]));
          break;
        case SamplingTypes.ACTUAL:
          setEditPermission(isPermissionExist([866]));
          setDeletePermission(isPermissionExist([867]));
          break;
        default:
          setEditPermission(isPermissionExist([1]));
          setDeletePermission(isPermissionExist([1]));
          break;
      }
    } else {
      switch (sampleType) {
        case SamplingTypes.ACTUAL:
          setEditPermission(isPermissionExist([883]));
          setDeletePermission(isPermissionExist([884]));
          break;
        default:
          setEditPermission(isPermissionExist([1]));
          setDeletePermission(isPermissionExist([1]));
          break;
      }
    }
  }, [sampleType, contractType]);

  useEffect(() => {
    // getPurchaseSamplingReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBusinessNoDropDown();
    getBargeDropForBusinessNo();
    getAllPurchaseContracts();
    getAllSaleContractsForDropDown();
  }, []);
  const getBusinessNoDropDown = () => {
    formRef.setFieldsValue({ businessNo: [] });
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant);
    businessService
      .getBusinessNoDropDown(req)
      .then((res) => {
        if (res.status) {
          setBusinessDropDown(res.data);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getBargeDropForBusinessNo = () => {
    formRef.setFieldsValue({ barges: [] });
    const req = new BNoRequest('');
    req.plantCode = authContext.defaultPlant;
    bargeService
      .getBargeDropForBusinessNo(req)
      .then((res) => {
        if (res.status) {
          setDropDown(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getAllPurchaseContracts = () => {
    formRef.setFieldsValue({ purchaseContract: [] });
    purchase
      .getAllPurchaseContractsForDropDown({
        plantCode: authContext.defaultPlant,
      })
      .then((res) => {
        if (res.status) {
          setPurchases(res.data);
        }
      })
      .catch((err) => {
        console.log('error: ' + err.message);
      });
  };

  const getAllSaleContractsForDropDown = () => {
    formRef.setFieldsValue({ salesContract: [] });
    salesService
      .getAllSaleContractsForDropDown({ plantCode: authContext.defaultPlant })
      .then((res) => {
        if (res.status) {
          setSales(res.data);
        }
      })
      .catch((err) => {
        console.log('error: ' + err.message);
      });
  };

  const submitHandler = () => {
    let values = formRef.getFieldsValue([
      'barges',
      'businessNo',
      contractType === ContractModesEnum.PURCHASE
        ? 'purchaseContract'
        : 'salesContract',
    ]);
    const contract =
      contractType === ContractModesEnum.PURCHASE
        ? 'purchaseContract'
        : 'salesContract';
    const req = new SamplingReportsReqDto(
      values.barges,
      values.businessNo,
      values[contract],
      sampleType,
      authContext.defaultPlant,
      contractType,
    );
    if (
      !req.bargeId?.length &&
      !req.businessNo.length &&
      !req.contractId.length
    ) {
      setData([]);
      return AlertMessages.getErrorMessage(
        `Please select Business No, Barge, or ${
          contractType === ContractModesEnum.PURCHASE
            ? 'Purchase Contract'
            : 'Sales Contract'
        }`,
      );
    }
    qualityService
      .getSamplingReportsByIds(req)
      .then((res) => {
        if (res.status) {
          setData(res.data);
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          setData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const getPurchaseSamplingReports = () => {
    const req = new SamplingSampleTypeReqDto(
      sampleType,
      authContext.defaultPlant,
      contractType,
    );
    qualityService
      .getPurchaseSamplingReports(req)
      .then((res) => {
        if (res.status) {
          setPurchaseSupplier(res.data);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const redirectToFullView = (bargeId: string) => {
    navigate(pathToreDirect, { state: { bargeId } });
  };

  const columns: any = [
    {
      title: 'Barge Id',
      dataIndex: 'bargeNumber',
      key: 'bid',
      fixed: 'left',
      width: '80px',
      render: (text, record) => {
        const link = `/#/barge-detail-view?barge_id=${record?.bargeId}`;
        return (
          <>
            {/* <Link className="link-primary" onClick={() => { redirectToFullView(record?.bargeId); }}>
                          {SequenceUtils.formatNumberToSpecificLength(record.bargeNumber.toString())}
                      </Link> */}
            <a href={link} className="link">
              {SequenceUtils.formatNumberToSpecificLength(record?.bargeNumber)}
            </a>
          </>
        );
      },
      sorter: (a, b) =>
        a.bargeId !== null
          ? a.bargeId.localeCompare(b.bargeId)
          : 'Have Null Values',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const aaa = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (aaa.size && aaa.has(true)) return true;
        else return false;
      },
    },
    {
      title: 'Barge Name',
      dataIndex: 'bargeNomination',
      key: 'bargeNomination',
      fixed: 'left',
      width: '180px',
    },
    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      key: 'bn',
      fixed: 'left',
      width: '160px',
    },
    {
      title: `${
        contractType === ContractModesEnum.SALES
          ? 'Sales Contract'
          : 'Purchase Contract'
      }`,
      dataIndex: 'purchaseContractNo',
      key: 'pcs',
      fixed: 'left',
      width: '160px',
    },
    {
      title: `${
        contractType === ContractModesEnum.SALES ? 'Vendor' : 'Supplier'
      }`,
      dataIndex: 'supplierName',
      key: 'splr',
      width: '180px',
    },
    {
      title: 'Port Type',
      dataIndex: 'portType',
      key: 'bas',
      width: '80px',
    },
    {
      title: 'Method',
      dataIndex: 'method',
      width: '80px',
    },
    {
      title: 'Basis',
      dataIndex: 'cvBasis',
      key: 'bas',
      width: '80px',
    },

    {
      title: 'GROSS CALORIFIC VALUE',
      children: [
        {
          title: 'Typical',
          key: 'typ',
          width: '80px',
          dataIndex: 'cvTypical',
        },
        {
          title: 'Rejection',
          key: 'rej',
          width: '80px',
          dataIndex: 'cvRejection',
        },
        {
          title: 'Actual',
          key: 'actl',
          width: '80px',
          dataIndex: 'cvActual',
        },
      ],
    },
    {
      title: 'ASH (%)',
      children: [
        {
          title: 'Typical',
          key: 'typ',
          width: '80px',
          dataIndex: 'ashTypical',
        },
        {
          title: 'Rejection',
          key: 'rej',
          width: '80px',
          dataIndex: 'ashRejection',
        },
        {
          title: 'Actual',
          key: 'actl',
          width: '80px',
          dataIndex: 'ashActual',
        },
      ],
    },
    {
      title: 'TOTAL SULPHUR (%)',
      children: [
        {
          title: 'Typical',
          key: 'typ',
          width: '80px',
          dataIndex: 'tsTypical',
        },
        {
          title: 'Rejection',
          key: 'rej',
          width: '80px',
          dataIndex: 'tsRejection',
        },
        {
          title: 'Actual',
          key: 'actl',
          width: '80px',
          dataIndex: 'tsActual',
        },
      ],
    },
    {
      title: 'TOTAL MOISTURE (%)',
      children: [
        {
          title: 'Typical',
          key: 'typ',
          width: '80px',
          dataIndex: 'tmTypical',
        },
        {
          title: 'Rejection',
          key: 'rej',
          width: '80px',
          dataIndex: 'tmRejection',
        },
        {
          title: 'Actual',
          key: 'actl',
          width: '80px',
          dataIndex: 'tmActual',
        },
      ],
    },
    {
      title: 'INHERENT MOISTURE (%)',
      children: [
        {
          title: 'Typical',
          key: 'typ',
          width: '80px',
          dataIndex: 'inmTypical',
        },
        {
          title: 'Rejection',
          key: 'rej',
          width: '80px',
          dataIndex: 'inmRejection',
        },
        {
          title: 'Actual',
          key: 'actl',
          width: '80px',
          dataIndex: 'inmActual',
        },
      ],
    },
    {
      title: 'VOLATILE MOISTURE (%)',
      children: [
        {
          title: 'Typical',
          key: 'typ',
          width: '80px',
          dataIndex: 'vmTypical',
        },
        {
          title: 'Rejection',
          key: 'rej',
          width: '80px',
          dataIndex: 'vmRejection',
        },
        {
          title: 'Actual',
          key: 'actl',
          width: '80px',
          dataIndex: 'vmActual',
        },
      ],
    },
    {
      title: 'FIXED CARBON (%)',
      children: [
        {
          title: 'Typical',
          key: 'typ',
          width: '80px',
          dataIndex: 'fcTypical',
        },
        {
          title: 'Rejection',
          key: 'rej',
          width: '80px',
          dataIndex: 'fcRejection',
        },
        {
          title: 'Actual',
          key: 'actl',
          width: '80px',
          dataIndex: 'fcActual',
        },
      ],
    },
    {
      title: 'FUEL RATIO (FC/VM)',
      children: [
        {
          title: 'Typical',
          key: 'typ',
          width: '80px',
          dataIndex: 'frTypical',
        },
        {
          title: 'Rejection',
          key: 'rej',
          width: '80px',
          dataIndex: 'frRejection',
        },
        {
          title: 'Actual',
          key: 'actl',
          width: '80px',
          dataIndex: 'frActual',
        },
      ],
    },
    {
      title: 'HGI',
      children: [
        {
          title: 'Typical',
          key: 'typ',
          width: '80px',
          dataIndex: 'hgTypical',
        },
        {
          title: 'Rejection',
          key: 'rej',
          width: '80px',
          dataIndex: 'hgRejection',
        },
        {
          title: 'Actual',
          key: 'actl',
          width: '80px',
          dataIndex: 'hgActual',
        },
      ],
    },
    {
      title: 'Action',
      fixed: 'right',
      width: '80px',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan,
      }),
      render: (text, rowData) => {
        return (
          <span>
            {editPermission && (
              <Tooltip placement="topRight" title="Edit">
                <EditFilled
                  type="edit"
                  onClick={() => {
                    editClickHandler(rowData);
                  }}
                  className="action-icons mr-8"
                />
              </Tooltip>
            )}

            {/* <Divider type="vertical" /> */}
            {deletePermission && (
              <Popconfirm
                onConfirm={(e) => {
                  deletePurchaseSamplingSupplier(rowData?.actualGroupId);
                }}
                title={fm({
                  id: 'common.deleteConfirmMsg',
                  defaultMessage: 'Are you sure to Delete Record ?',
                })}
              >
                <Tooltip placement="topRight" title="Delete">
                  <DeleteFilled
                    type="delete"
                    onClick={() => {}}
                    className="delete-filled"
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </span>
        );
      },
    },
  ];

  const deletePurchaseSamplingSupplier = (actualGroupId: string) => {
    qualityService
      .deletePurchaseSamplingSupplier({
        groupId: actualGroupId,
        plantCode: authContext.defaultPlant,
        contractType: contractType,
      })
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          // getPurchaseSamplingReports();
          submitHandler();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const editClickHandler = (rowData) => {
    const qty = mapToGroupByGroupId.get(Number(rowData.actualGroupId));
    let pathToRedirect;
    if (sampleType === SamplingTypes.SUPPLIER) {
      pathToRedirect = 'supplier-update';
    } else if (sampleType === SamplingTypes.ACTUAL) {
      pathToRedirect = 'actual-update';
    } else if (sampleType === SamplingTypes.INTERNAL) {
      pathToRedirect = 'company-update';
    } else if (sampleType === SamplingTypes.PRE_SHIPMENT) {
      pathToRedirect = 'shipment-update';
    }
    navigate(
      `/${
        contractType === ContractModesEnum.PURCHASE ? 'purchase' : 'sales'
      }-sampling/` + pathToRedirect,
      { state: { qualityData: qty } },
    );
  };

  const processQualityData = (qualityData: PurchaseSupplierReportDto[]) => {
    const returnArray = [];
    qualityData.forEach((rec) => {
      if (!mapToGroupByGroupId.has(Number(rec.actualGroupId)))
        mapToGroupByGroupId.set(Number(rec.actualGroupId), [
          { ...rec, rowSpan: 1 },
        ]);
      else {
        const existingData: any = mapToGroupByGroupId.get(
          Number(rec.actualGroupId),
        );
        existingData[0].rowSpan = existingData.length + 1;
        mapToGroupByGroupId.set(Number(rec.actualGroupId), [
          ...existingData,
          rec,
        ]);
      }
    });
    for (const a of new Map(
      [...mapToGroupByGroupId].sort((a, b) => b[0] - a[0]),
    ).values()) {
      returnArray.push(...a);
    }
    return qualityData;
  };

  return (
    <div>
      {/* <Button type='primary' style={{ float: 'left' }}>Load More</Button> */}
      <Form form={formRef} autoComplete="off" layout="vertical">
        <Row gutter={16}>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
            <Form.Item
              label="Business Number"
              rules={[{ required: false }]}
              name="businessNo"
            >
              <Select
                mode="multiple"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                allowClear
                showSearch
                placeholder="Select Business Number"
              >
                {businessDropDown.map((bn) => {
                  return <Option value={bn.bnId}>{bn.businessName}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
            <Form.Item
              label="Barge"
              rules={[{ required: false }]}
              name="barges"
            >
              <Select
                mode="multiple"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                allowClear
                showSearch
                placeholder="Select Barge"
              >
                {dropDown.map((rec) => {
                  return (
                    <Option value={rec.id}>
                      {SequenceUtils.formatNumberToSpecificLength(
                        rec.bargeId.toString(),
                      ) +
                        ' ' +
                        `(${rec.bargeNomination})`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
            <Form.Item
              label={
                contractType === ContractModesEnum.PURCHASE
                  ? 'Purchase Contract'
                  : 'sales Contract'
              }
              rules={[{ required: false }]}
              name={
                contractType === ContractModesEnum.PURCHASE
                  ? 'purchaseContract'
                  : 'salesContract'
              }
            >
              <Select
                mode="multiple"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                showSearch
                allowClear
                placeholder={
                  contractType === ContractModesEnum.PURCHASE
                    ? 'Select Purchase Contract'
                    : 'select Sales Contract'
                }
              >
                {(contractType === ContractModesEnum.PURCHASE
                  ? purchases
                  : sales
                ).map((item) => {
                  return (
                    <Option key={item.pcId} value={item.pcId}>
                      {item.contractAndSupplier}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: '24px' }}
              className="new-button"
              onClick={submitHandler}
            >
              Submit
            </Button>
          </Col>
          <Table
            className="contracts-list"
            columns={columns}
            dataSource={processQualityData(data)}
            scroll={{ x: 1500 }}
          />
          <br />
        </Row>
        {/* <Input.Search
        className="input-search mb-12"
        placeholder="Search"
        allowClear
        onChange={(e) => {
          setSearchedText(e.target.value);
        }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
      /> */}
        {/* <Table
        className="contracts-list"
        columns={columns}
        scroll={{ x: 1500 }}
        dataSource={processQualityData(purchaseSupplier)}
        pagination={{
          showTotal: (total) => (
            <span className="page-total-items">Total {total} items</span>
          ),
          showSizeChanger: true,
          pageSizeOptions: ['25', '50', '100'],
          defaultPageSize: 25,
        }}
      /> */}
      </Form>
    </div>
  );
};

export default ActualReport;
