import { CheckOutlined, CloseCircleOutlined, EditFilled, EyeFilled, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { CurrencyConversionGetDataDto, CurrencyConverterIdRequestDto, CurrencyConverterService } from '@exportx/shared-models-and-services';
import { Button, Card, Divider, Input, InputRef, Popconfirm, Row, Space, Switch, Table, Tooltip } from 'antd';
import { ColumnType, FilterConfirmProps } from 'antd/lib/table/interface';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist } from '../../../../common';
import { SequenceUtils } from '@exportx/ui-utils';
import debounce from 'lodash/debounce';

interface DataType {
  key: string;
  baseCurrency: string;
  quoteCurrency: string;
  exchangeRate: string;
  exchangeDate: string;
}


const CurrencyG = () => {
  const currencyConverterService = new CurrencyConverterService();
  const [gridData, setGridData] = React.useState<CurrencyConversionGetDataDto[]>([]);
  // const [gridData, setGridData] = React.useState<any>([]);
  const navigate = useNavigate();

  type DataIndex = keyof DataType;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [searchedText, setSearchedText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
    total: 0,
  });

  useEffect(()=>{
      if(!isPermissionExist([35])){
         return navigate('/')
      }
  },[])

  useEffect(() => {
    getCurrencyConversionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const dumpExchangeRates = () => {
    currencyConverterService.dumpExchangeRates('').then(res => {
      if (res.status) {
        getCurrencyConversionData();
        AlertMessages.getSuccessMessage('Received Successfully')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      };
    }).catch(err => console.log(err.message))
  }


  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });



  // const getCurrencyConversionData = () => {
  //   currencyConverterService.getCurrencyConversionData().then(res => {
  //     if (res.status) {
  //       setGridData(res.data);
  //       console.log(res.data.length,'Count of records')
  //     } else {
  //       // AlertMessages.getErrorMessage(res.internalMessage)
  //       console.log(res.internalMessage)
  //     }
  //   }).catch(err => {
  //     console.log(err.message)
  //   })
  // }

  const getCurrencyConversionData = useCallback(
    (
      page = 1,
      pageSize = 25,
      search?: string
    ) => {
    
    const req = { 
      limit: pageSize, 
      offset: (page - 1) * pageSize,
      searchString: search 
    }
    
    currencyConverterService.getCurrencyConversionData(req).then(res => {
      if (res.status) {
        setGridData(res.data.data);
        setPagination((prev) => ({
          ...prev,
          current: page,
          pageSize: pageSize,
          total: res.data.totalCount || 0,
        }));
      } else {
        console.log(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }, []);

  const activateDeactivate = (id) => {
    const req = new CurrencyConverterIdRequestDto(id)
    currencyConverterService.activateDeactivate(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getCurrencyConversionData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }


  const debouncedSearch = useMemo(
    () =>
      debounce((value: string, page: number, pageSize: number) => {
        getCurrencyConversionData(
            page,
            pageSize,
            value,
          );
      }, 500),
    [
      getCurrencyConversionData,
    ],
  );



  const columns: any = [

    {
      title: 'From currency',
      dataIndex: 'baseCurrency',
      width: '80px',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record)
      },
      // ...getColumnSearchProps('baseCurrency'),
    },
    {
      title: 'To currency',
      dataIndex: 'quoteCurrency',
      width: '80px',
      // ...getColumnSearchProps('quoteCurrency'),
    },
    {
      title: 'Date',
      dataIndex: 'exchangeDate',
      width: '80px',
      render: (text, record) => {
        return <span>{text ? moment(text).format('YYYY-MM-DD') : null}</span>
      }
      // ...getColumnSearchProps('exchangeDate'),
    },
    {
      title: 'Currency Type',
      dataIndex: 'currencyType',
      width: '80px',
    },
    {
      title: 'Rate',
      dataIndex: 'exchangeRate',
      width: '80px',
      // ...getColumnSearchProps('exchangeRate'),
      align: 'right' as AlignType
    },
    {
      title: 'Action',
      width: '60px',
      align: 'center' as AlignType,
      render: (record, value) => {
        return <span >
          {isPermissionExist([35]) && (
          <Tooltip title={'View'}  > 
            <EyeFilled
              style={{ fontSize: '16px' }} // fontSize: 24
              className='action-icons'
              onClick={() => {
                if (record.fromDate || record.toDate) {
                  record.fromDate = moment(record.fromDate).format('YYYY-MM-DD')
                  record.fromDate = moment(record.Date).format('YYYY-MM-DD')

                }
                navigate("/save-currency-conversion", { state: { record: record, hidden: true } });
              }} 
            />
          </Tooltip>)}
          <Divider type="vertical" />
          {isPermissionExist([36]) && (
            <Tooltip title={record.isActive ? "Edit" : ""}> 
              <EditFilled 
                style={{ fontSize: '16px' }}
                className='action-icons'
                onClick={() => {
                  navigate("", { state: { record: record } }); 
                  if (!record.isActive) {
                    AlertMessages.getErrorMessage('You Could Not Update Deactivated Record')
                  } else {
                  (navigate('/save-currency-conversion', { state: { record: record, isUpdate: true } }));
                  }
                }} 
                />
            </Tooltip>
          )}
          <Divider type="vertical" />
          {isPermissionExist([37]) && (
          <Popconfirm onConfirm={() => activateDeactivate(record.currencyConverterId)} title={`${record.isActive ? "Are you sure to Deactivate Record" : "Are You Sure To Activate Record ?"}`}>
            <Tooltip title={record.isActive ? 'Deactivate' : "Activate"}>
              <Switch
                style={{ width: 40, height: 20, marginTop: 4, backgroundColor: record.isActive ? "#0295D4" : "red", fontSize: "12px" }}
                size='default'
                className={record.isActive ? 'toggle-activated' : 'toggle-deactivated'}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseCircleOutlined />}
                checked={record.isActive}
              />
            </Tooltip>
          </Popconfirm >)}
        </span >
      }


    }
  ]
  return (
      <Card className="card-radius">
        <Row style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
          <Input.Search
            placeholder="Search"
            allowClear
            onChange={(e) => {
              const value = e.target.value;
              setSearchedText(value);
              debouncedSearch(value, 1, pagination.pageSize);
            }}
            onSearch={(value) => {
              setSearchedText(value);

              getCurrencyConversionData(
                  pagination.current,
                  pagination.pageSize,
                  value,
                );
            }}
            style={{ width: 200, float: "right" }}
          />

          {isPermissionExist([34]) && (<span style={{ color: '#5860f5' }} ><Button onClick={() => navigate("/save-currency-conversion")}>Create</Button> </span>)}
          
        </Row>

        <Table 
          className='contracts-list'
          scroll={{ x: 500 }}
          rowKey={(record) => record.currencyConverterId}
          columns={columns} 
          dataSource={gridData}
          pagination={{
            ...pagination,
            showTotal: (total) => (
              <span className="page-total-items">Total {total} items</span>
            ),
            showSizeChanger: true,
            pageSizeOptions: ['25', '50', '100'],
            onChange: (page, pageSize) => {
                getCurrencyConversionData(
                  page,
                  pageSize,
                  searchedText,
                )
              }
            }}
        />
      </Card>
  )
}

export default CurrencyG