import React from "react";
import { Affix, Button, Card, Col, Descriptions, Divider, Drawer, Flex, Form, Layout, Row, Space, Table, Tabs, Tooltip } from 'antd';
import moment from 'moment';


const ProcurementContractPreview = (props) => {
    const { gridData } = props;


    console.log('gridData', gridData);

    const laycanDateFormate = (value: any) => value && Array.isArray(value) ? `${moment(value[0]).format('DD-MMM-YYYY')} to ${moment(value[1]).format('DD-MMM-YYYY')}` : `${moment(value?.split(',')[0]).format('DD-MMM-YYYY')} to ${moment(value?.split(',')[1]).format('DD-MMM-YYYY')}` || ''

    const LaycanColumns: any = [

        {
            title: 'Laycan',
            dataIndex: 'layCan',
            onCell: (record: any) => ({
                style: { whiteSpace: 'nowrap' }
            }),
            render: (value, record: any, index) => (
                <span>{laycanDateFormate(record?.layCan)}</span>
            ),
        },
        {
            title: 'Quality',
            dataIndex: 'purchaseQuality',
            render: (value, record: any, index) => (
                <span>{record?.purchaseQuality}</span>
            ),
        },
        {
            title: 'Purchase Type',
            dataIndex: 'pcPurchaseType',
            render: (value, record: any, index) => (
                <span>{record?.pcPurchaseType}</span>
            ),
        },
        {
            title: 'Price Type',
            dataIndex: 'priceType',
            render: (value, record: any, index) => (
                <span>{record?.priceType}</span>
            ),
        },
        {
            title: 'Quantity MT',
            dataIndex: 'quantityInMT',
            align: 'right',
            render: (value, record: any, index) => (
                <span>{record?.quantityInMT}</span>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'pricePMT',
            align: 'right',
            render: (value, record: any, index) => (
                <span>{record?.pricePMT}</span>
            ),
        },
        {
            title: 'Total Price',
            dataIndex: 'totalPrice',
            align: 'right',
            render: (value, record: any, index) => (
                <span>{record?.totalPrice}</span>
            ),
        },
        {
            title: 'Quantity Adjustment',
            dataIndex: 'qualityAdjustment',
            render: (value, record: any, index) => (
                <span>{record?.qualityAdjustment}</span>
            ),
        },


    ]

    return (
        <div style={{ backgroundColor: '#F5F5F5', padding: '0px' }}>

            <Row gutter={[16, 12]}>
                <Col span={24} style={{ display: 'flex' }}
                    xs={24}
                    md={24}
                    lg={24}
                >
                    <Card title='Contract Details' styles={{ header: { fontSize: '16px', fontWeight: 600, color: '#323F49', border: 'none', paddingTop: '10px' } }} bordered={false} style={{ width: '100%', borderRadius: '8px' }}>
                        <Row>
                            <Col>
                                <Descriptions
                                    className='descriptions'
                                    layout='vertical'
                                    colon={false}
                                >
                                    <Descriptions.Item label={"Contract Type"}>
                                        {gridData?.contractType}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Contract Date"}>
                                        {gridData?.contractDate ? moment(gridData?.contractDate).format('DD-MMM-YYYY') : 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={`Contract`} >
                                        {gridData?.purchaseContract}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Vendor"}>
                                        <span>{gridData?.vendor}</span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Currency"}>
                                        <span style={{ backgroundColor: '#D9F1FD', padding: '2px 6px', borderRadius: '20px' }}>
                                            {gridData?.currency || gridData?.currencyId}
                                        </span>
                                    </Descriptions.Item>

                                </Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Row>

            <br />
            <Card
                title={<span style={{ fontSize: '16px', fontWeight: 600 }}>Laycan Details</span>}
                styles={{ header: { paddingTop: '8px', border: 0 }, body: { paddingTop: '12px', paddingBottom: 0, overflow: 'auto' } }}
                bordered={false}
                style={{ borderRadius: '8px' }}
            >
                <Table
                    className='contracts-list'
                    columns={LaycanColumns}
                    dataSource={gridData?.qualityDetails?.qualityData}
                    pagination={false}
                    onRow={(record) => ({
                        style: { backgroundColor: record.color }
                    })}
                    style={{ overflowX: 'auto' }}
                >

                </Table>
                <br />

                
            </Card>
            <br />

            <Row>
                <Col xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}>
                    <Card title={<span style={{ color: 'black' }}>{'Remarks'}</span>} style={{ textAlign: 'left', backgroundColor: '#F7F9FC', height: '300px', overflowY: 'auto', }} bordered={false} styles={{ body: { paddingTop: 2 }, header: { border: 0 } }}>
                        <Divider dashed style={{ marginTop: 0, marginBottom: '10px' }}></Divider>
                        <pre>
                            {gridData?.remarks}
                        </pre>
                    </Card>
                </Col>
            </Row>

        </div>
    )
};

export default ProcurementContractPreview;