import { CPSPreviewDto, ContractStatusEnum, ContractTypesEnum, GetAllProcurementResponseDto, ProcurementContractService, ProcurementContractTypeEnum, laycanApprovalReqDto } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Button, Card, Col, Descriptions, Divider, Drawer, Flex, Form, Row, Space, Table, Tabs, Tooltip } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import PaymentTermsView from '../view-components/payment-terms-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faCircleXmark, faXmark } from '@fortawesome/free-solid-svg-icons';
import TabPane from 'antd/es/tabs/TabPane';
import { ArrowRightOutlined, EditFilled, PlusCircleFilled } from '@ant-design/icons';
import QualitiesForm from './qualities-form';
import { LaycanView } from './laycan-view';
interface IProps {
    contractTypeCategory?: ProcurementContractTypeEnum;
}


export const ProcurementContractDetailedView = (props: IProps) => {
    const { contractTypeCategory } = props
    const [gridData, setGridData] = useState<GetAllProcurementResponseDto>();
    const [snapData, setSnapData] = useState<GetAllProcurementResponseDto[]>([]);
    const [qualities, setQualities] = useState([]);
    const [editPermission, setEditPermission] = useState(false)
    const [filePermission, setFilePermission] = useState(false)
    const [pricePermission, setPricePermission] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [refresh, setRefresh] = useState<number>(0);
    const [showAddLaycan, setShowAddLaycan] = useState<boolean>(false);
    const [showViewLaycan, setShowViewLaycan] = useState<boolean>(false);
    const [laycanData, setLaycanData] = useState<any>({});
    const [qualityDetails, setQualityDetails] = useState<any>({ qualityData: [] });



    const [filesFormRef] = Form.useForm();
    const { authContext } = useAuthState();
    const location = useLocation();
    const paramCpcId = queryString.parse(location.search)?.cpcId;
    let { cpcId }: any = paramCpcId != null ? { cpcId: paramCpcId } : { cpcId: null };

    const [formRef] = Form.useForm();

    const laycanDateFormate = (value: any) => value && Array.isArray(value) ? `${moment(value[0]).format('DD-MMM-YYYY')} to ${moment(value[1]).format('DD-MMM-YYYY')}` : `${moment(value?.split(',')[0]).format('DD-MMM-YYYY')} to ${moment(value?.split(',')[1]).format('DD-MMM-YYYY')}` || ''


    const snapQuality = snapData?.[0]?.quality || [];
    const qualityData = gridData?.quality || [];

    useEffect(() => {
        let permission;
        if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
            setEditPermission(isPermissionExist([148]))
            setFilePermission(isPermissionExist([154]))
            setPricePermission(isPermissionExist([150]))
            permission = [147];
        } else if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
            setEditPermission(isPermissionExist([157]))
            setFilePermission(isPermissionExist([163]))
            setPricePermission(isPermissionExist([159]))
            permission = [156];
        } else if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
            setEditPermission(isPermissionExist([166]))
            setFilePermission(isPermissionExist([172]))
            setPricePermission(isPermissionExist([168]))
            permission = [165];
        } else {
            setEditPermission(isPermissionExist([148, 157, 166]))
            setFilePermission(isPermissionExist([154, 163, 172]))
            setPricePermission(isPermissionExist([150, 159, 168]))
            permission = [147, 156, 165]
        }
        if (!isPermissionExist(permission)) navigate('/')
    }, [contractTypeCategory])

    useEffect(() => {

        if (gridData?.contractTypes !== ContractTypesEnum.ADDENDUM) {
            setQualities(qualityData);
            return;
        };


        const snap_qualities = JSON.parse(JSON.stringify(snapQuality));
        const addendum_qualites = JSON.parse(JSON.stringify(qualityData));
        const new_qualities = []
        if (gridData?.contractTypes === ContractTypesEnum.ADDENDUM) {

            const removed_records = snap_qualities?.filter(({ qualitySeq: id1 }) => !addendum_qualites.some(({ qualitySeq: id2 }) => id2 === id1));
            console.log('removed_records', removed_records)
            for (const obj of removed_records) {
                obj.color = "#f65c8d1f";
                new_qualities.push(obj);
            }
        }
        setQualities([...new_qualities, ...addendum_qualites])

    }, [snapQuality, qualityData, gridData?.contractTypes])


    const navigate = useNavigate()
    let pathToreDirect = `/${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "s" : "p"}o-detail-view`;

    let pcUpdatePath = '';

    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
        pcUpdatePath = '/procurement-service-update'
    } if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
        pcUpdatePath = '/coalServiceFee-service-update'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
        pcUpdatePath = '/marketing-service-update'
    }


    let cpsAddendum = '';
    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
        cpsAddendum = '/procurement-addendum'
    } if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
        cpsAddendum = '/coalServiceFee-addendum'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
        cpsAddendum = '/marketing-addendum'
    }

    let detailViewPath = '';
    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
        detailViewPath = '/procurement-contract-detailedView'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
        detailViewPath = '/coalServiceFee-detailedView'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
        detailViewPath = '/marketing-detailedView'
    }

    const service = new ProcurementContractService();


    useEffect(() => {
        if (!isPermissionExist([147, 156, 165])) return navigate('/')
    }, [])

    useEffect(() => {
        getServiceContractPreviewData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cpcId, refresh]);

    const getServiceContractPreviewData = () => {
        const req = new CPSPreviewDto(cpcId, authContext.defaultPlant, contractTypeCategory, undefined, authContext.user.userName);
        service.getServiceContractPreviewData(req).then(res => {
            if (res.status) {
                setGridData(res.data);
                setSnapData(res.snapData);
                setOpenModal(false);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
                setOpenModal(false);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
            setOpenModal(false);
        })
    };

    const sendForApproval = () => {
        const req = new CPSPreviewDto(cpcId, authContext.defaultPlant, undefined, undefined, authContext.user.userName);
        service.sendForApproval(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getServiceContractPreviewData();
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const addendumClickHandler = () => {
        const link = `${cpsAddendum}?cpcId=${cpcId}`
        navigate(link);
    }

    const approveOrRejectServiceContract = (contractId: string, status: ContractStatusEnum, contractTypes: any) => {
        if (!contractTypes) {
            return
        }
        const req = new CPSPreviewDto(contractId, authContext.defaultPlant, contractTypes, status, authContext.user.userName);
        service.approveOrRejectServiceContract(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getServiceContractPreviewData();
            } else {
                AlertMessages.getErrorMessage(res.error);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const disableAddendum = gridData?.quality?.every(e => e.approvalStatus === ContractStatusEnum.ACTIVE || e.approvalStatus === ContractStatusEnum.CLOSED);


    const getExtraComponent = (status: any) => {
        if (status === ContractStatusEnum.DRAFT) {
            return <><Button style={{ backgroundColor: '#0295D4', borderRadius: '4px', color: '#FFFFFF' }} onClick={() => sendForApproval()}>Send For Approval</Button></>
        }
        if (status === ContractStatusEnum.PENDING_APPROVAL) {
            return <span>
                {PermissionExist('Approve') && <Button size="small" className='approve' onClick={() => {
                    approveOrRejectServiceContract(cpcId, ContractStatusEnum.ACTIVE, gridData?.contractTypes);
                }}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>}&nbsp;&nbsp;&nbsp;
                {PermissionExist('Reject') &&
                    <Button size="small" className='reject' onClick={() => {
                        approveOrRejectServiceContract(cpcId, ContractStatusEnum.DRAFT, gridData?.contractTypes);
                    }}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>}
            </span>
        }
        if (status === ContractStatusEnum.ACTIVE && disableAddendum && gridData?.contractTypes === ContractTypesEnum.ORIGINAL) {
            return <>{PermissionExist('Addendum') && <Button style={{ backgroundColor: '#0295D4', borderRadius: '4px', color: '#FFFFFF' }} onClick={() => addendumClickHandler()}>Create Addendum</Button>}</>
        }
    }


    const fileUploadFormHandler = () => {
        filesFormRef.validateFields().then(fileValues => {
            getFilesData(fileValues.filesData).then(filesData => {
                service.addMoreFilesToCpsContracts({
                    filesData: filesData,
                    updatedUser: authContext.user.userName,
                    pcID: cpcId
                }).then(res => {
                    if (res.status) {
                        AlertMessages.getSuccessMessage(res.internalMessage);
                        getServiceContractPreviewData();
                        filesFormRef.resetFields();
                    } else {
                        AlertMessages.getErrorMessage(res.internalMessage);
                    }
                }).catch(err => {
                    AlertMessages.getErrorMessage(err.message);
                })
            });
        });
    }

    const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
        for (const snapDate of snapData) {
            let valueAtGivenKey: any = snapDate;
            for (const key of keys) {
                valueAtGivenKey = valueAtGivenKey?.[key];
            }
            if (valueAtGivenKey) {
                if (keys.includes('contractDate')) {
                    valueAtGivenKey = moment(valueAtGivenKey).format('DD-MMM-YYYY')
                }
            }
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (gridData.contractTypes === ContractTypesEnum.ADDENDUM) {
                    if (isRawData) {
                        return value;
                    } else {
                        return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapDate.contractNo.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    }
                }
            }
        };
        return value
    }

    const valiDateChildWithSnapShotChild = (qualitySeq: string, key: string, value: string): any => {
        const filterSeq = snapQuality?.find((e) => e.qualitySeq === qualitySeq)
        let valueAtGivenKey: any = filterSeq && filterSeq[key];

        if (valueAtGivenKey) {
            if (key === 'layCan') {
                valueAtGivenKey = laycanDateFormate(valueAtGivenKey)
            }
            if (valueAtGivenKey === value) {
                return value;
            } else {
                if (gridData.contractTypes === ContractTypesEnum.ADDENDUM) {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapData?.[0]?.contractNo?.replace('SNP', 'ADD')}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }

    const PermissionExist = (type: string) => {
        if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
            return type === 'Approve' ? isPermissionExist([151]) : type === 'Reject' ? isPermissionExist([152]) : isPermissionExist([146]) // no feature id's for addendum todo in feature
        } else if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
            return type === 'Approve' ? isPermissionExist([169]) : type === 'Reject' ? isPermissionExist([170]) : isPermissionExist([164])
        }
        return type === 'Approve' ? isPermissionExist([160]) : type === 'Reject' ? isPermissionExist([161]) : isPermissionExist([155])

    }


    const paymentTerms = { lc: gridData?.lc, others: gridData?.others, telegraphicTransfer: gridData?.telegraphicTransfer, lcValue: gridData?.lcValue, lcType: gridData?.lcType, lcCurrency: gridData?.lcCurrency, paymentMode: gridData?.paymentMode }

    const link = `/#/detailCustomer?bp_id=${gridData?.vendorId}`
    const purchaseOrSalesDetailView = `/#${pathToreDirect}?${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "s" : "p"}c_id=${gridData?.purchaseContractNo}`;
    const CpfBaseContract = `/#/procurement-contract-detailedView?cpcId=${gridData?.baseContractId}`;
    const CsfBaseContract = `/#/coalServiceFee-detailedView?cpcId=${gridData?.baseContractId}`;
    const CmfBaseContract = `/#/marketing-detailedView?cpcId=${gridData?.baseContractId}`;

    const rowClassName = (record, qualitySeq) => {
        if (!snapQuality.some((item) => item?.qualitySeq === qualitySeq)) {
            if (gridData?.contractTypes === ContractTypesEnum.ADDENDUM) {
                return 'highlighted-row';
            } else {
                return '';
            }
        }
        return '';
    };

    const LaycanColumns: any = [

        {
            title: 'Laycan',
            dataIndex: 'layCan',
            sorter: (a, b) => (a.layCan?.[0] ? moment(a.layCan[0]).valueOf() : 0) - (b.layCan?.[0] ? moment(b.layCan[0]).valueOf() : 0),
            onCell: (record: any) => ({
                style: { whiteSpace: 'nowrap' }
            }),
            render: (value, record: any, index) => (
                <span>{valiDateChildWithSnapShotChild(record.qualitySeq, 'layCan', laycanDateFormate(record?.layCan))}</span>
            ),
        },
        {
            title: 'Quality',
            dataIndex: 'purchaseQuality',
            render: (value, record: any, index) => (
                <span>{valiDateChildWithSnapShotChild(record.qualitySeq, 'purchaseQuality', record?.purchaseQuality)}</span>
            ),
        },
        {
            title: 'Purchase Type',
            dataIndex: 'pcPurchaseType',
            render: (value, record: any, index) => (
                <span>{valiDateChildWithSnapShotChild(record.qualitySeq, 'pcPurchaseType', record?.pcPurchaseType)}</span>
            ),
        },
        {
            title: 'Price Type',
            dataIndex: 'priceType',
            render: (value, record: any, index) => (
                <span>{valiDateChildWithSnapShotChild(record.qualitySeq, 'priceType', record?.priceType)}</span>
            ),
        },
        {
            title: 'Quantity MT',
            dataIndex: 'quantityInMT',
            align: 'right',
            render: (value, record: any, index) => (
                <span>{valiDateChildWithSnapShotChild(record.qualitySeq, 'quantityInMT', record?.quantityInMT)}</span>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'pricePMT',
            align: 'right',
            render: (value, record: any, index) => (
                <span>{valiDateChildWithSnapShotChild(record.qualitySeq, 'pricePMT', record?.pricePMT)}</span>
            ),
        },
        {
            title: 'Total Price',
            dataIndex: 'totalPrice',
            align: 'right',
            render: (value, record: any, index) => (
                <span>{valiDateChildWithSnapShotChild(record.qualitySeq, 'totalPrice', record?.totalPrice)}</span>
            ),
        },
        {
            title: 'Quantity Adjustment',
            dataIndex: 'qualityAdjustment',
            render: (value, record: any, index) => (
                <span>{valiDateChildWithSnapShotChild(record.qualitySeq, 'qualityAdjustment', record?.qualityAdjustment)}</span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
            hidden: gridData?.status !== ContractStatusEnum.ACTIVE,
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any) => {
                return <span><StatusTag status={value ?? gridData?.status} /></span>
            }
        },
        {
            title: 'Action',
            // hidden: mode === 'preview',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any) => {
                return (
                    <Tooltip title='View Laycan Details'>
                        <Button size='middle' icon={<ArrowRightOutlined />}
                            style={{ border: 'none', padding: '8px', borderRadius: '50%', color: '#0295D4', backgroundColor: '#DEECF3' }}
                            onClick={() => {
                                setLaycanData(record);
                                setShowViewLaycan(true);
                            }}
                        ></Button>
                    </Tooltip>
                )
            }
        }

    ]

    // const rowClassName = (row: any, index) => {
    //     return index % 2 === 0 ? 'row-even' : 'row-odd'; 
    //   };

    const addNewLaycanHandler = () => {
        formRef.validateFields().then((values) => {
            const laycanData = {
                ...values,
                contractId: cpcId,
                status: ContractStatusEnum.DRAFT,
                plantCode: authContext.defaultPlant
            }
            service.addLaycanData(laycanData).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    getServiceContractPreviewData();
                    setShowAddLaycan(false);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                AlertMessages.getErrorMessage(err.message);
            })
        }
        ).catch((err) => {
            console.log(err);
        });
    }

    const laycanApprovalOperations = (id: string, status: ContractStatusEnum) => {

        const payload = new laycanApprovalReqDto(cpcId, id, status, authContext.defaultPlant, authContext.user.userName);

        service.laycanDataApprovalOperations(payload).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getServiceContractPreviewData();
                setShowViewLaycan(false);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })

    }
    const editLaycan = (id: string) => {

        const selectedLaycan = gridData?.quality?.find((rec) => rec.id === id);
        if (selectedLaycan) {
            setQualityDetails({ qualityData: [selectedLaycan] });

        }
        setShowViewLaycan(false);
        setShowAddLaycan(true);
    }

    const tabItem = [
        {
            key: "1",
            label: "Payment Terms",
            children: <PaymentTermsView paymentTerms={paymentTerms} />
        },
        {
            key: "2",
            label: "Remarks",
            children: <Row>
                <Col xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}>
                    <Card title={<span style={{ color: 'black' }}>{'Remarks'}</span>} style={{ textAlign: 'left', backgroundColor: '#F7F9FC', height: '300px', overflowY: 'auto' }} bordered={false} styles={{ body: { paddingTop: 2 }, header: { border: 0 } }}>
                        <Divider dashed style={{ marginTop: 0, marginBottom: '10px' }}></Divider>
                        <pre>
                            {gridData?.remarks}
                        </pre>
                    </Card>
                </Col>
            </Row>
        },
        {
            key: "3",
            label: "Audit Information",
            children: <Card title={<span style={{ color: 'black' }}>{'User Details'}</span>} style={{ textAlign: 'left', backgroundColor: '#F7F9FC', height: '300px', overflowY: 'auto' }} bordered={false} styles={{ body: { paddingTop: 2 }, header: { border: 0 } }}>
                <Divider dashed style={{ marginTop: 0, marginBottom: '10px' }}></Divider>
                <Row>
                    <Col>
                        <Descriptions
                            column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                        >
                            <Descriptions.Item label="Created By" >
                                <span className='descriptions'>{gridData?.createdUser}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved By" >
                                <span className='descriptions'>{gridData?.approvedBy}</span>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col>
                        <Descriptions
                            column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                        >
                            <Descriptions.Item label="Created Date">
                                <span className='descriptions'>{gridData?.createdAt ? moment(gridData?.createdAt).format('DD-MMM-YYYY h:mm A') : ''}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved Date">
                                <span className='descriptions'>{gridData?.approvedOn ? moment(gridData?.approvedOn).format('DD-MMM-YYYY h:mm A') : ''}</span>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </Card>
        },
    ];



    return (
        <>
            {/* <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}> */}

            <div style={{ backgroundColor: '#F5F5F5', padding: '0px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'none', marginBottom: '10px' }}>
                    <Space size={5}>
                        <a href={contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees ? '/#/procurementServiceFee' : contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees ? '/#/coalServiceFee' : '/#/marketing'} style={{ fontSize: '18px', color: '#6B7280' }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </a>
                        <span style={{ fontSize: '16px', fontWeight: 600, color: '#323F49' }}>{gridData?.contractType} Contract -  {(gridData?.contractNo)}</span>&nbsp;
                        <StatusTag status={gridData?.status} />
                    </Space>
                    <Space size={10}>
                        {(gridData?.['status'] === ContractStatusEnum.DRAFT || gridData?.['status'] === ContractStatusEnum.PENDING_APPROVAL && editPermission) && (
                            <Button
                                icon={<EditFilled />}
                                style={{
                                    border: 'none',
                                    fontSize: '15px',
                                    padding: '7px',
                                    borderRadius: '50%',
                                    color: '#0295D4',
                                    backgroundColor: '#DEECF3'
                                }}
                                onClick={() => {
                                    const link = `${pcUpdatePath}?cpcId=${cpcId}`
                                    navigate(link);
                                }}

                            />
                        )}

                        {getExtraComponent(gridData?.status)}
                    </Space>
                </div>

                <Row gutter={[16, 12]}>
                    <Col span={gridData?.addendumData?.length > 0 ? 14 : 24} style={{ display: 'flex' }}
                        xs={24}
                        md={24}
                        lg={gridData?.addendumData?.length > 0 ? 14 : 24}
                    >
                        <Card title='Contract Details' styles={{ header: { fontSize: '16px', fontWeight: 600, color: '#323F49', border: 'none', paddingTop: '10px' } }} bordered={false} style={{ width: '100%', borderRadius: '8px' }}>
                            <Row>
                                <Col>
                                    <Descriptions
                                        className='descriptions'
                                        layout='vertical'
                                        colon={false}
                                    >
                                        <Descriptions.Item label={"Contract Type"}>
                                            {valiDateWithSnapShotData(['contractType'], gridData?.contractType, false)}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={"Contract Date"}>
                                            {valiDateWithSnapShotData(['contractDate'], gridData?.contractDate ? moment(gridData?.contractDate).format('DD-MMM-YYYY') : '', false)}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={`${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Contract" : "Purchase Contract"}`} >
                                            <a style={{ color: '#0083C9' }} href={purchaseOrSalesDetailView}>
                                                {valiDateWithSnapShotData(['purchaseContract'], gridData?.purchaseContract, false)}
                                            </a>
                                        </Descriptions.Item>
                                        <Descriptions.Item label={"Vendor"}>
                                            <span><a style={{ color: '#0083C9' }} href={link}>
                                                {valiDateWithSnapShotData(['vendor'], gridData?.vendor, false)}
                                            </a></span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label={"Currency"}>
                                            <span style={{ backgroundColor: '#D9F1FD', padding: '2px 6px', borderRadius: '20px' }}>
                                                {valiDateWithSnapShotData(['currency'], gridData?.currency, false)}
                                            </span>
                                        </Descriptions.Item>
                                        {gridData?.contractTypes === ContractTypesEnum.ADDENDUM &&
                                            <Descriptions.Item label={"Base Contract"} >
                                                <a style={{ color: '#0083C9' }} href={contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees ? CpfBaseContract : contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees ? CsfBaseContract : CmfBaseContract}>
                                                    {valiDateWithSnapShotData(['baseContractNo'], gridData?.baseContractNo, false)}
                                                </a>
                                            </Descriptions.Item>
                                        }
                                    </Descriptions>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    {(gridData?.addendumData && gridData?.addendumData?.length > 0) &&
                        <Col span={10} style={{ display: 'flex' }}
                            xs={24}
                            md={24}
                            lg={10}
                        >
                            <Card title='Addendums' styles={{ header: { fontSize: '16px', fontWeight: 600, color: '#323F49', border: 'none', paddingTop: '10px' } }} bordered={false} style={{ width: '100%', borderRadius: '8px' }}>
                                <Row style={{ display: 'flex', gap: '12px', marginBottom: '12px' }}>
                                    <Card
                                        bordered={false}
                                        styles={{ header: { border: 'none' } }}
                                        size="small"
                                        style={{ borderRadius: '4px', backgroundColor: '#F8F8F8' }}
                                    >
                                        <Space size={16}>
                                            <Tooltip placement="top" title={gridData.addendumData[0].status}>
                                                <a
                                                    href={`/#${detailViewPath}?cpcId=${gridData.addendumData[0].addendumContractId}`}
                                                    className="link"
                                                >
                                                    {gridData.addendumData[0].addendumContractNo}
                                                </a>
                                            </Tooltip>
                                            <StatusTag status={gridData.addendumData[0].status} />
                                        </Space>
                                    </Card>
                                </Row>

                                <Row style={{ display: 'flex', gap: '12px' }}>
                                    {gridData.addendumData.slice(1).reverse().map((rec, index) => (
                                        <Card
                                            bordered={false}
                                            styles={{ header: { border: 'none' } }}
                                            size="small"
                                            key={rec.addendumContractId}
                                            style={{ borderRadius: '4px', backgroundColor: '#F8F8F8' }}
                                        >
                                            <Space size={16}>
                                                <Tooltip placement="top" title={rec.status}>
                                                    <a
                                                        href={`/#${detailViewPath}?cpcId=${rec.addendumContractId}`}
                                                        className="link"
                                                    >
                                                        {rec.addendumContractNo}
                                                    </a>
                                                </Tooltip>
                                                <StatusTag status={rec.status} />
                                            </Space>
                                        </Card>
                                    ))}
                                </Row>
                            </Card>
                        </Col>
                    }
                </Row>

                <br />
                <Card
                    title={<span style={{ fontSize: '16px', fontWeight: 600 }}>Laycan Details</span>}
                    styles={{ header: { paddingTop: '8px', border: 0 }, body: { paddingTop: '12px', paddingBottom: 0, overflow: 'auto' } }}
                    bordered={false}
                    style={{ borderRadius: '8px' }}
                    extra={gridData?.status === ContractStatusEnum.ACTIVE && gridData?.contractTypes === ContractTypesEnum.ORIGINAL && gridData?.addendumData?.every(el => el.status === ContractStatusEnum.ACTIVE) &&
                        <Tooltip title='Add Laycan'>
                            <Button size='middle' icon={<PlusCircleFilled />}
                                style={{ border: 'none', padding: '14px', borderRadius: '50%', color: '#0295D4', backgroundColor: '#DEECF3' }}
                                onClick={() => { setShowAddLaycan(true); setQualityDetails({ qualityData: [''] }); }}
                            ></Button>
                        </Tooltip>
                    }
                >
                    <Table
                        className='contracts-list'
                        columns={LaycanColumns}
                        dataSource={qualities}
                        pagination={false}
                        rowClassName={(record) => rowClassName(record, record.qualitySeq)}
                        onRow={(record) => ({
                            style: { backgroundColor: record.color }
                        })}
                        style={{ overflowX: 'auto' }}
                    >

                    </Table>
                    <br />
                </Card>

                <br />

                <Row gutter={[16, 12]}>
                    <Col span={16} lg={16} md={24} xs={24}>
                        <Card title="Additional terms" bordered={false} styles={{ header: { fontSize: '16px', fontWeight: 600, color: '#323F49', border: 'none', paddingTop: '10px' } }} style={{ borderRadius: '8px' }}>
                            <Tabs tabPosition='left' tabBarStyle={{ backgroundColor: '#F7F9FC' }} items={tabItem} />

                        </Card>
                    </Col>
                    <Col span={8} lg={8} md={24} xs={24} style={{ display: 'flex' }}>
                        {gridData?.filesData && filePermission && (
                            <FileUploadForm
                                maxCount={3}
                                layoutType='vertical'
                                initialValues={gridData?.filesData}
                                formRef={filesFormRef}
                                formType='View'
                                fileModal={openModal}
                                setFileModal={setOpenModal}
                                setRefresh={setRefresh}
                            >
                                <Row justify="end">
                                    <Col>
                                        {
                                            <Space>
                                                <Button
                                                    onClick={fileUploadFormHandler}
                                                    type="primary">
                                                    Save
                                                </Button>
                                            </Space>
                                        }</Col>
                                </Row>
                            </FileUploadForm>
                        )
                        }
                    </Col>
                </Row>

                {/* Add Laycan drawer */}
                <Drawer
                    open={showAddLaycan}
                    className='dashborad-popup'
                    width={1000}
                    title={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49' }}>Add Laycan</span>
                            <Button style={{ border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none' }}
                                icon={<FontAwesomeIcon icon={faXmark} />} onClick={() => { setShowAddLaycan(false) }}>
                            </Button>
                        </div>
                    }
                    closable={false}
                    footer={
                        <Flex justify='space-between' align='center' style={{ padding: '0px 16px', margin: 8, }}>
                            <Button onClick={() => addNewLaycanHandler()} type="primary" className='new-button'>
                                Submit
                            </Button>
                            <span style={{ color: '#0083C9', fontSize: '14px', fontWeight: 400 }}>
                                <a onClick={() => { setShowAddLaycan(false) }}>Close</a>
                            </span>
                        </Flex>
                    }
                >

                    <QualitiesForm
                        contractType={contractTypeCategory}
                        contractCurrency={gridData?.currencyId}
                        purchaseContractNo={gridData?.purchaseContractNo}
                        initialValues={qualityDetails}
                        formRef={formRef}
                        singleLaycan={true}
                    />
                </Drawer>


                {/* View Laycan drawer */}
                <Drawer
                    open={showViewLaycan}
                    className='dashborad-popup'
                    width={800}
                    title={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49', marginRight: '8px' }}>Laycan Details</span>
                                <StatusTag status={laycanData.approvalStatus ?? gridData?.['status']} />
                            </div>
                            <Button style={{ border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none' }}
                                icon={<FontAwesomeIcon icon={faXmark} />} onClick={() => setShowViewLaycan(false)}>
                            </Button>
                        </div>
                    }
                    closable={false}
                    onClose={() => setShowViewLaycan(false)}
                    footer={
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: "16px", paddingRight: "16px", margin: '8px', alignItems: 'center' }}>
                            <div>
                                {gridData?.contractTypes === ContractTypesEnum.ORIGINAL && laycanData.approvalStatus === ContractStatusEnum.DRAFT &&
                                    <>
                                        <Button onClick={() => laycanApprovalOperations(laycanData?.id, ContractStatusEnum.PENDING_APPROVAL)}
                                            type="primary" style={{ marginRight: "16px" }} className='new-button'
                                        >
                                            Send For Approval
                                        </Button>
                                        <Button onClick={() => editLaycan(laycanData?.id)}
                                            type="default" style={{ marginRight: "16px" }}
                                        >
                                            Edit
                                        </Button>
                                    </>
                                }
                                {gridData?.status === ContractStatusEnum.ACTIVE && laycanData.approvalStatus === ContractStatusEnum.PENDING_APPROVAL &&
                                    <>
                                        <Button onClick={() => editLaycan(laycanData?.id)}
                                            type="default" style={{ marginRight: "16px" }} className='new-button'
                                        >
                                            Edit
                                        </Button>

                                        <Button size="small" className='approve' style={{ marginRight: "16px" }}
                                            onClick={() => laycanApprovalOperations(laycanData?.id, ContractStatusEnum.ACTIVE)}
                                        >
                                            <FontAwesomeIcon icon={faCheckCircle} />Approve
                                        </Button>
                                        <Button size="small" className='reject' onClick={() => laycanApprovalOperations(laycanData?.id, ContractStatusEnum.DRAFT)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
                                    </>
                                }
                                {gridData?.status === ContractStatusEnum.ACTIVE && laycanData.approvalStatus === ContractStatusEnum.ACTIVE &&
                                    <Button onClick={() => laycanApprovalOperations(laycanData?.id, ContractStatusEnum.CLOSED)}
                                        type="primary" style={{ marginRight: "16px" }} className='new-button'
                                    >
                                        Mark as Closed
                                    </Button>
                                }
                            </div>
                            <span style={{ color: '#0083C9', fontSize: '14px', fontWeight: 400 }}>
                                <a onClick={() => setShowViewLaycan(false)}>Close</a>
                            </span>
                        </div>
                    }
                >
                    <LaycanView
                        laycanData={laycanData}
                    />
                </Drawer>

                {/* <Card
                    style={{ textAlign: 'center' }}
                    className='default-card-class'
                    title={<span style={{ color: 'white' }}> {gridData?.contractType} Contract -  {(gridData?.contractNo)}
                        <span style={{ paddingLeft: 20 }}> <StatusTag status={gridData?.status} />
                        </span>
                    </span>
                    }
                    extra={<>{getExtraComponent(gridData?.status)}</>}
                >
                    <Row>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"ContractType"}>
                                    {valiDateWithSnapShotData(['contractType'], gridData?.contractType, false)}
                                </Descriptions.Item>
                            </Descriptions></Col><Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"ContractDate"}>
                                    {valiDateWithSnapShotData(['contractDate'], gridData?.contractDate ? moment(gridData?.contractDate).format('DD-MM-YYYY h:mm A') : '', false)}</Descriptions.Item>
                            </Descriptions></Col>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={`${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Contract" : "Purchase Contract"}`} ><a><Link href={purchaseOrSalesDetailView}>
                                    {valiDateWithSnapShotData(['purchaseContract'], gridData?.purchaseContract, false)}
                                </Link></a></Descriptions.Item>
                            </Descriptions></Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"Vendor"}><span><Link href={link}>
                                    {valiDateWithSnapShotData(['vendor'], gridData?.vendor, false)}
                                </Link></span></Descriptions.Item>
                            </Descriptions></Col><Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"Currency"}>
                                    {valiDateWithSnapShotData(['currency'], gridData?.currency, false)}
                                </Descriptions.Item>

                            </Descriptions></Col>
                        <Col span={8}>
                            <Descriptions>

                                <Descriptions.Item label={"Approved By"}>
                                    {gridData?.approvedBy}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"Approved Date"}>
                                    {gridData?.approvedOn ? moment(gridData?.approvedOn).format('DD-MM-YYYY h:mm A') : ''}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={'Remarks'}>
                                    {valiDateWithSnapShotData(['remarks'], gridData?.remarks, false)}
                                </Descriptions.Item>
                            </Descriptions></Col>
                            
                    </Row>
                    <Row>

                        <Descriptions>
                            <Descriptions.Item label={"AddendumData"}>
                                {gridData?.addendumData.map((rec) => {
                                    const link = `/#${detailViewPath}?cpcId=${rec.addendumContractId}`
                                    return <><a href={link} className="link-primary" >
                                        {`${rec.addendumContractNo} ,`}
                                    </a></>
                                })}
                            </Descriptions.Item>
                        </Descriptions>
                    </Row>

                    <br />

                    <Card>
                        <div className='table-responsive'>
                            <table className='table table-bordered table-sm'>
                                <thead className='table-header' style={{ background: '#fafafa' }}>
                                    {columns?.map((item) => (
                                        item === 'Price' && !pricePermission ? null : <th className='text-center'>{item}</th>
                                    ))}</thead>
                                <tbody>
                                    {qualities?.map((e, index) => {
                                        return (
                                            <tr key={e.id} style={{ backgroundColor: e.color ? e.color : "" }} className={rowClassName(e, e.qualitySeq)}>
                                                <td className='text-uppercase text-center font-bold' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'layCan', laycanDateFormate(e?.layCan))}</td>
                                                <td className='text-uppercase text-center font-bold' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'purchaseQuality', e?.purchaseQuality)}</td>
                                                <td className='text-uppercase text-center font-bold' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'pcPurchaseType', e?.pcPurchaseType)}</td>
                                                <td className='text-uppercase text-center' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'priceType', e?.priceType)}</td>
                                                <td className='text-uppercase text-center font-bold' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'quantityInMT', e?.quantityInMT)}</td>

                                                {pricePermission && (<td className='text-uppercase text-center' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'pricePMT', e?.pricePMT)}</td>)}
                                                <td className='text-uppercase text-center' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'totalPrice', e?.totalPrice)}</td>
                                                <td className='text-uppercase text-center' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'qualityAdjustment', e?.qualityAdjustment)}</td>

                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Card>

                    <Card >
                        <PaymentTermsView paymentTerms={paymentTerms} />
                    </Card>
                    <Card>
                        <Table dataSource={Array(gridData)} columns={remarks}
                            pagination={false} /></Card>

                    {gridData?.filesData && filePermission && (
                        <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} >
                            <Row justify="end">
                                <Col>
                                    {
                                        <Space>
                                            <Button
                                                onClick={fileUploadFormHandler}
                                                type="primary">
                                                Save and Next
                                            </Button>
                                        </Space>
                                    }</Col>
                            </Row>
                        </FileUploadForm>)
                    }
                </Card>
                <Affix offsetBottom={0}>
                    <Card bodyStyle={{ padding: '6px 12px' }}>
                        <Row justify='space-between'>
                            <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
                            <Col>{(gridData?.['status'] === ContractStatusEnum.DRAFT || gridData?.['status'] === ContractStatusEnum.PENDING_APPROVAL && editPermission) && <Button onClick={() => {
                                const link = `${pcUpdatePath}?cpcId=${cpcId}`
                                navigate(link);
                            }}>Edit</Button>}</Col>
                        </Row>
                    </Card>
                </Affix> */}
            </div>
            {/* </Layout > */}
        </>
    );

}
export default ProcurementContractDetailedView;