import React, { useEffect } from 'react';
import {
  ContractModesEnum,
  SamplingTypes,
} from '@exportx/shared-models-and-services';
import ActualReport from './actual-report';
import { Button, Card } from 'antd';
import { isPermissionExist } from '../../../../common';
import { useNavigate } from 'react-router-dom';

interface IProps {
  sampleType: SamplingTypes;
  contractType: ContractModesEnum;
}

export const PurchaseSampleReport = (props: IProps) => {
  const navigate = useNavigate();
  const { sampleType, contractType } = props;

  useEffect(() => {
    let actionTypee;

    if (contractType === ContractModesEnum.PURCHASE) {
      switch (sampleType) {
        case SamplingTypes.SUPPLIER:
          actionTypee = 857;
          break;
        case SamplingTypes.INTERNAL:
          actionTypee = 861;
          break;
        case SamplingTypes.ACTUAL:
          actionTypee = 865;
          break;
        default:
          actionTypee = 1;
          break;
      }
    } else {
      switch (sampleType) {
        case SamplingTypes.ACTUAL:
          actionTypee = 882;
          break;
        default:
          actionTypee = 1;
          break;
      }
    }

    if (!isPermissionExist([actionTypee])) navigate('/');
  }, [sampleType, contractType]);

  const getCreateButtonPermissions = () => {
    if (
      sampleType === SamplingTypes.SUPPLIER &&
      isPermissionExist([836, 858])
    ) {
      return { show: true, path: '/purchase-sampling/supplier' };
    }
    if (
      sampleType === SamplingTypes.INTERNAL &&
      isPermissionExist([842, 862])
    ) {
      return { show: true, path: '/purchase-sampling/company' };
    }
    if (sampleType === SamplingTypes.ACTUAL && isPermissionExist([846, 866])) {
      return { show: true, path: '/purchase-sampling/actual' };
    }
    return { show: false, path: '/' };
  };

  const { show, path } = getCreateButtonPermissions();

  return (
    <Card
      className="card-radius"
      title={`${
        props.contractType === ContractModesEnum.SALES
          ? 'S&A - Sales'
          : 'S&A - Purchase'
      }`}
      extra={
        show && (
          <Button className="new-button" onClick={() => navigate(path)}>
            Create
          </Button>
        )
      }
      headStyle={{marginTop: '8px'}}
    >
      <div>
        <ActualReport
          sampleType={props.sampleType}
          contractType={ContractModesEnum.PURCHASE}
        />
      </div>
    </Card>
  );
};

export default PurchaseSampleReport;
