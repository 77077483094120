import { Button, Card, Col, Drawer, Input, Row, Tabs } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';

// import './shipping-terms-grid.css';
import {
  CommodityDeactivateDto,
  CommodityDto,
  CommodityService,
} from '@exportx/shared-models-and-services';
import {
  AlertMessages,
  FilterableGrid,
  isPermissionExist,
} from '../../../../../common';

import { useIntl } from 'react-intl';
import CommodityForm from '../commodity-form/commodity.form';
import { useLocation, useNavigate } from 'react-router-dom';
import TabPane from 'antd/es/tabs/TabPane';
import { BasisGrid } from '../../basis';
import { UomsGrid } from '../../uoms';

/* eslint-disable-next-line */
export interface CommodityGridProps {}

export function CommodityGrid(props: CommodityGridProps) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const activeTab = state?.activeTab || 'commodity';
  const service = new CommodityService();
  const [commodityData, setCommodityData] = useState<CommodityDto[]>([]);
  const [selectCommodityData, setSelectCommodityData] =
    useState<any>(undefined);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [searchedText, setSearchedText] = useState('');

  const { formatMessage: fm } = useIntl();

  const openFormWithData = (viewData: CommodityDto, update: boolean) => {
    setIsUpdate(update);
    setDrawerVisible(true);
    setSelectCommodityData(viewData);
  };

  useEffect(() => {
    if (!isPermissionExist([15, 19, 59])) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAll = () => {
    service
      .getAll()
      .then((res) => {
        if (res.status) {
          setCommodityData(res.data);
        } else {
          setCommodityData([]);
        }
      })
      .catch((err) => {
        setCommodityData([]);
      });
  };

  const deleteUser = (data: CommodityDto) => {
    const reqDto = new CommodityDeactivateDto(
      data.commodityId,
      data.updatedUser,
      data.versionFlag,
    );
    service
      .activatedeActivate(reqDto)
      .then((res) => {
        console.log(res);
        if (res.status) {
          getAll();
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: `Commodity ${
                data.isActive ? ' deactivated ' : ' activated '
              } SuccessFully`,
            }),
          );
        } else {
          if (res.errorCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Commodity Name',
      dataIndex: 'commodityName',
      sorter: (a, b) => a.commodityName.localeCompare(b.commodityName),
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
    },
    {
      title: 'Commodity Code',
      dataIndex: 'commodityCode',
      sorter: (a, b) => a.commodityCode.localeCompare(b.commodityCode),
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const updateUser = (Data: CommodityDto) => {
    service
      .update(Data)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage('Updated Successfully');
          getAll();
          setDrawerVisible(false);
        } else {
          if (res.errorCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    getAll();
  };

  return (
    <>
      <Card className="card-radius">
        <Tabs defaultActiveKey={activeTab}>
          {isPermissionExist([15]) && (
            <TabPane tab="Commodity" key="commodity">
              {/* <Card
                title="Commodity Details"
                className="default-card-class"
                extra={
                  <>
                    {isPermissionExist([14]) && (
                      <Button
                        onClick={() => {
                          setIsNewRecord(true);
                          setDrawerVisible(true);
                          setSelectCommodityData(null);
                        }}
                      >
                        {fm({ id: 'common.create', defaultMessage: 'Create' })}{' '}
                      </Button>
                    )}
                  </>
                }
              > */}
              <Row justify="space-between" className="mb-12 mt-8">
                <Col>
                  <Input.Search
                    placeholder="Search Commodity"
                    allowClear
                    onChange={(e) => {
                      setSearchedText(e.target.value);
                    }}
                    onSearch={(value) => {
                      setSearchedText(value);
                    }}
                    className="input-search"
                  />
                </Col>
                <Col>
                  <>
                    {isPermissionExist([14]) && (
                      <Button
                        className="new-button"
                        onClick={() => {
                          setIsNewRecord(true);
                          setDrawerVisible(true);
                          setSelectCommodityData(null);
                        }}
                      >
                        {fm({ id: 'common.create', defaultMessage: 'Create' })}{' '}
                      </Button>
                    )}
                  </>
                </Col>
              </Row>
              <FilterableGrid
                rowKey="commodityId"
                mainColumns={columnsSkelton}
                data={commodityData}
                filterColumnsData={[]}
                activateOrDeactivate={deleteUser}
                view={openFormWithData}
              />
              <Drawer
                bodyStyle={{ paddingBottom: 80 }}
                title="Commodity Details"
                width={window.innerWidth > 768 ? '50%' : '85%'}
                onClose={closeDrawer}
                open={drawerVisible}
                closable={true}
              >
                <CommodityForm
                  key={Date.now()}
                  updateDetails={updateUser}
                  isUpdate={isUpdate}
                  isNewRecord={isNewRecord}
                  commodityData={selectCommodityData}
                  closeForm={closeDrawer}
                />
              </Drawer>
              {/* </Card> */}
            </TabPane>
          )}
          {isPermissionExist([19]) && (
            <TabPane tab="Basis" key="basis">
              <BasisGrid />
            </TabPane>
          )}
          {isPermissionExist([59]) && (
            <TabPane tab="UOM's" key="uom's">
              <UomsGrid />
            </TabPane>
          )}
        </Tabs>
      </Card>
    </>
  );
}

export default CommodityGrid;
