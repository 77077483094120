import { ContractStatusEnum, ContractTypesEnum, IndexedDataDto, LcTypeEnum, PaymentModesEnum, PriceTypeEnum, SpecTypesEnum, VesselTypesEnum } from "@exportx/shared-models-and-services";
import { Button, Descriptions, Drawer, Card, Table, Typography, Divider } from "antd";
import moment from "moment";
import { useState } from "react";
import { CoalIndexView } from "../../masters/components/coal-index-prices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface ILaycanViewProps {
  laycanData: any;
  contractType: ContractTypesEnum;
}

export const LaycanView = (props: ILaycanViewProps) => {
  const { laycanData } = props;

  const laycanDateFormate = (value: any) => value && Array.isArray(value) ? `${moment(value[0]).format('DD-MMM-YYYY')} to ${moment(value[1]).format('DD-MMM-YYYY')}` : `${moment(value?.split(',')[0]).format('DD-MMM-YYYY')} to ${moment(value?.split(',')[1]).format('DD-MMM-YYYY')}` || ''

  console.log('laycanData', laycanData)
  return (
    <Card size='small' bordered={false} className="no-shadow">
      <Descriptions
        className='descriptions-label'
        title=''
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        layout='vertical'
        colon={false}
      >
        <Descriptions.Item label="Laycan">
          <span className='descriptions'>{laycanDateFormate(laycanData.layCan)}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Quality">
          <span className='descriptions'>{laycanData.purchaseQuality} {laycanData.qualitySpecifications}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Quantity">
          <span className='descriptions'>{laycanData.quantityInMT} MT</span>
        </Descriptions.Item>
        <Descriptions.Item label="Purchase Type">
          <span className='descriptions'>{laycanData.pcPurchaseType}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Price Type">
          <span className='descriptions'>{laycanData.priceType}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Price Type">
          <span className='descriptions'>{laycanData.priceType}</span>
        </Descriptions.Item>
        {laycanData.priceType === PriceTypeEnum.PER_MT &&
          <Descriptions.Item label="Price PMT">
            <span className='descriptions'>{laycanData.pricePMT}</span>
          </Descriptions.Item>
        }
        <Descriptions.Item label="Total Price">
          <span className='descriptions'>{laycanData.totalPrice}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Quality Adjustment">
          <span className='descriptions'>{laycanData.qualityAdjustment}</span>
        </Descriptions.Item>
      </Descriptions>
      <br />

      <h6>Payment Mode</h6>

      <Descriptions
        className='descriptions-label'
        title=''
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        layout='vertical'
        colon={false}
      >
        <Descriptions.Item label="Payment Mode">
          <span className='descriptions'>{laycanData.paymentMode}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Lc Type">
          <span className='descriptions'>{laycanData.lcType ?? 'N/A'}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Lc Value">
          <span className='descriptions'>{`${laycanData?.lcValue ?? 'N/A'} ${laycanData?.lcCurrency ?? ''}`}</span>
        </Descriptions.Item>
      </Descriptions>


      <br />
      {laycanData.approvalStatus === ContractStatusEnum.ACTIVE && laycanData.approvedBy && (
        <>
         
          <h6>Approval Details</h6>

          <Descriptions
            className='descriptions-label'
            title=''
            column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
            layout='vertical'
            colon={false}
          >
            <Descriptions.Item label="Approved By">
              <span className='descriptions'>{laycanData.approvedBy}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Approved On">
              <span className='descriptions'>{moment(laycanData.approvedOn).format('DD-MMM-YYYY h:mm a')}</span>
            </Descriptions.Item>
          </Descriptions>
        </>
      )
      }
    </Card>
  )
}