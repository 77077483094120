import React, { useEffect, useState } from 'react'
import { Affix, Button, Card, Col, Descriptions, Divider, Form, Row, Space, Table, Tabs, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../../common';
import { useIntl } from 'react-intl';
import { BargeAgreementService, ContractStatusEnum, ContractTypesEnum, PreviewRequests, VesselAgreementPreviewDto } from '@exportx/shared-models-and-services';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getFilesData } from '@exportx/ui-utils';
import queryString from 'query-string';
import Link from 'antd/lib/typography/Link';
import { title } from 'process';
import { render } from '@testing-library/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faCircleXmark, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { EditFilled } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';

const BargeAgreementDetailedView = () => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [snapData, setSnapData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState<number>(0);
  //const { baId } = state ? state : { baId: null };
  const location = useLocation();
  const paramBargeId = queryString.parse(location.search)?.ba_Id;
  console.log(paramBargeId, "=====================")
  console.log(queryString.parse(location.search));
  let { baId } = paramBargeId != null ? { baId: paramBargeId } : state ? state : { baId: null };
  
  const [gridData, setGridData] = useState<any>();
  const service = new BargeAgreementService();

  const [filesFormRef] = Form.useForm();
  const { authContext } = useAuthState();

  useEffect(() => {
    if(!isPermissionExist([138])) navigate('/')
    if (baId) {
      getBargeAgreementPreviewData();
    }

  }, [baId])

  let bargeAddendum = '/bargeAgreement-addendum';


  const getBargeAgreementPreviewData = () => {
    const req = new PreviewRequests(baId, authContext.defaultPlant);
    service.getBargeAgreementPreviewData(req).then(res => {
      if (res.status) {
        setGridData(res.data);
        setSnapData(res.snapData)
        setOpenModal(false)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        setOpenModal(false)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setOpenModal(false)
    })
  }


  const sendForApproval = () => {
    const req = new PreviewRequests(baId, authContext.defaultPlant);
    service.sendForApproval(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Barge Agreement Sent successfully' }));
        getBargeAgreementPreviewData()
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const addendumClickHandler = () => {
    const link = `${bargeAddendum}?ba_Id=${baId}`
    navigate(link, { state: { record: {...gridData, action:'addendum'} } })
  }

  const approveOrRejectHandler = (agreementId: string, status: ContractStatusEnum) => {
    const req = new VesselAgreementPreviewDto(agreementId, authContext.defaultPlant, authContext.user.userName, status, gridData.contractType)
    service.approveOrRejectAgreement(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getBargeAgreementPreviewData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button className='send-approval-button' onClick={() => sendForApproval()}><FontAwesomeIcon icon={faPaperPlane} />Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <span>
        {isPermissionExist([142]) && (<Button size="small" className='approve' onClick={() => {
          approveOrRejectHandler(baId, ContractStatusEnum.ACTIVE);
        }}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>)} &nbsp;&nbsp;
        {isPermissionExist([143]) && (<Button size="small" className='reject' onClick={() => {
          approveOrRejectHandler(baId, ContractStatusEnum.DRAFT);
        }}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>)}
      </span>
    }
    if (status === ContractStatusEnum.ACTIVE && gridData?.contractType === ContractTypesEnum.ORIGINAL && isPermissionExist([139])) {
      return <Button className='new-button' onClick={()=> addendumClickHandler()}>Create Addendum</Button>
    }
  }

  const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
    for (const snapDate of snapData) {
      let valueAtGivenKey: any = snapDate;
      for (const key of keys) {
        valueAtGivenKey = valueAtGivenKey?.[key];
      }
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (gridData.contractType === ContractTypesEnum.ADDENDUM) {
          if (isRawData) {
            return value;
          } else {
            return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapDate.fixtureNoteNo.replace('SNP', 'ADD')}><b>
              {value}
            </b></Tooltip></>
          }
        }
      }
    };
    return value
  }

  const valiDateChildWithSnapShotChild = (index: number, key: string, value: string): any => {
    let valueAtGivenKey: any = snapData?.[0]?.specPriceData?.[index]?.[key];
    if (valueAtGivenKey) {
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (gridData.contractType === ContractTypesEnum.ADDENDUM) {
          console.log('came', key, value, valueAtGivenKey)
          return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapData?.[0]?.fixtureNoteNo?.replace('SNP', 'ADD')}><b>
            {value}
          </b></Tooltip></>
        }
      };
    }
    return value;
  }


  const setFormData = () => {
    navigate('/bargeAgreement-update', { state: { record: gridData } });
  }



  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      getFilesData(fileValues.filesData).then(filesData => {
          service.addNewFiles({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          baId: baId
        }).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            getBargeAgreementPreviewData();
            filesFormRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      });
    });
  }




  const bargeDetails: ColumnsType<any> = [
    {
      title: 'Barge Vendor',
      dataIndex: 'vendorName',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['vendorName'], gridData?.vendorName, false)}</span>
      ),
   

    },

    {
      title: 'Currency',
      dataIndex: 'currency',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['currency'], gridData?.currency, false)}</span>
      ),

    },
    {
      title: 'Agreement Date',
      dataIndex: 'agreementDate',
      render(value, record, index) {
      const formattedDate = value ? moment(value).format('DD/MM/YYYY') : '';
      return (
        <span>{valiDateWithSnapShotData(['agreementDate'], formattedDate, false)}</span>
      );
    }

    },
    {
      title: gridData?.contractType === 'Addendum' ? 'Base Contract' : 'Addendum Data' ,
      dataIndex: '',
      render: (text, record) => {
        if (gridData?.contractType === 'Original') {
          return (
            <div>
              {gridData?.addendumData?.map((rec) => {
                const link = `/#/bargeAgreement-detailed-view?ba_Id=${rec.agreementId}`;
                return (
                  <a href={link} className="link-primary">
                    {`${rec.contractNo}, `}
                  </a>
                );
              })}
            </div>
          );
        } else {
          const link = `/#/bargeAgreement-detailed-view?ba_Id=${gridData?.baseContractId}`;
          return (
            <a href={link} className="link-primary">
              {gridData?.baseContractNo}
            </a>
          );
        }
      }
    }

  ];
  const specPriceData: ColumnsType<any> = [
    {
      title: 'Area',
      dataIndex: 'area',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'area', value)}</span>
      ),

    },

    {
      title: 'Price',
      dataIndex: 'price',
      align: 'right',
      hidden: !isPermissionExist([141]),
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'price', value)}</span>
      ),

    },
    {
      title: 'Barging Type',
      dataIndex: 'bargingType',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'bargingType', value)}</span>
      ),

    },
    {
      title: 'VAT Applicability (PPN)',
      dataIndex: 'vatInclOrExcl',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'vatInclOrExcl', value)}</span>
      ),

    },
  ];
  const remarks: ColumnsType<any> = [
    {
      title: 'Remarks',
      dataIndex: 'remarks',

      render: text => <span>{text}</span>,
    },]

    const link = `/#/detailCustomer?bp_id=${gridData?.bargeVendorId}`

  return (
    <>
      <div className='layout'>
          <Row justify='space-between' className='mb-12' >
                <Space size={5}>
                    <a href='/#/bargeAgreement-grid' className='arrow-left'>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </a>
                    <span className='card-title-md'>Contract - {(gridData?.fixtureNoteNo)}</span>&nbsp;
                    <StatusTag status={gridData?.status} />
                </Space>
                <Space size={10}>
                  {(gridData?.status !== ContractStatusEnum.ACTIVE && isPermissionExist([139])) && (
                      <Button className='action-icons'
                        icon={<EditFilled />}
                        onClick={setFormData}
                      />
                    )}
                                  
                    {getExtraComponent(gridData?.status)}
                </Space>
          </Row>
        <Row gutter={[16, 12]}>
                <Col span={gridData?.addendumData?.length > 0 ? 14 : 24} className='flex'
                    xs={24}
                    md={24}
                    lg={gridData?.addendumData?.length > 0 ? 14 : 24}
                >
                    <Card title={<span className='card-title-md'>Contract Details</span>} headStyle={{ border: 'none', paddingTop: '10px'}} bordered={false} className='card-br-8 full-width'>
                        <Row>
                            <Col>
                                <Descriptions
                                className='descriptions'
                                    layout='vertical'
                                    colon={false}
                                >
                                    <Descriptions.Item label={"Barge Vendor"}>
                                      <a href={link} className='link'>
                                          {valiDateWithSnapShotData(['vendor'], gridData?.vendorName, false)}
                                      </a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Agreement Date"}>
                                      {valiDateWithSnapShotData(['agreementDate'], gridData?.agreementDate ? moment(gridData?.agreementDate).format('DD\u2011MMM\u2011YYYY') : '', false)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Currency"}>
                                      <span className='currency'>
                                        {valiDateWithSnapShotData(['currency'], gridData?.currency, false)}
                                      </span>  
                                    </Descriptions.Item>
                                    {gridData?.contractType === ContractTypesEnum.ADDENDUM &&
                                    <Descriptions.Item label={ "Base Contract"} >
                                        <a className='link' href={`/#/bargeAgreement-detailed-view?ba_Id=${gridData?.baseContractId}`}>
                                            {valiDateWithSnapShotData(['baseContractNo'], gridData?.baseContractNo, false)}
                                        </a>
                                    </Descriptions.Item> 
                                    }
                                </Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                { (gridData?.addendumData && gridData?.addendumData?.length > 0) &&
                    <Col className='flex'
                    xs={24}
                    md={24}
                    lg={10}
                    >
                        <Card title={<span className='card-title-md'>Addendums</span>} headStyle={{ border: 'none', paddingTop: '10px'}} bordered={false} className='card-br-8 full-width' >
                            <Row className='mb-12' >
                                <Card 
                                    bordered={false} 
                                    headStyle={{ border: 'none' }} 
                                    size="small" 
                                    className='addendum-card'
                                >
                                    <Space size={16}>
                                    <Tooltip placement="top" title={gridData.addendumData[0].status}>
                                        <a 
                                        href={`/#/bargeAgreement-detailed-view?ba_Id=${gridData.addendumData[0].agreementId}`} 
                                        className="link"
                                        >
                                        {gridData.addendumData[0].contractNo}
                                        </a>
                                    </Tooltip>
                                    <StatusTag status={gridData.addendumData[0].status} />
                                    </Space>
                                </Card>
                            </Row>

                            <Row gutter={[12,12]}>
                                {gridData.addendumData.slice(1).reverse().map((rec, index) => (
                                  <Col>
                                <Card 
                                    bordered={false} 
                                    headStyle={{ border: 'none' }} 
                                    size="small" 
                                    className='addendum-card'
                                >
                                    <Space size={16}>
                                    <Tooltip placement="top" title={rec.status}>
                                        <a 
                                        href={`/#/bargeAgreement-detailed-view?ba_Id=${rec.agreementId}`} 
                                        className="link"
                                        >
                                        {rec.contractNo}
                                        </a>
                                    </Tooltip>
                                    <StatusTag status={rec.status} />
                                    </Space>
                                </Card>
                                </Col>
                            ))}
                            </Row>
                        </Card>
                    </Col>
                }
          </Row> 
          <br />
                    <Card title={<span className='card-title-md'>Area Details</span>} headStyle={{ paddingTop: '10px', border: 0 }} bordered={false} className='card-br-8 full-width' >
                        <Table
                          className='contracts-list'
                          style={{overflowX: 'auto'}}
                          dataSource={gridData?.specPriceData ? gridData?.specPriceData : gridData}
                          columns={specPriceData}
                          pagination={false} 
                        /> 
                    </Card>
          <br />
          <Row gutter={[16, 12]}>
                    <Col span={16} lg={16} md={24} xs={24}>
                <Card title={<span className='card-title-md'>Additional terms</span>} bordered={false} headStyle={{  border: 'none', paddingTop: '10px'}} className='card-br-8 full-width' >
                    <Tabs tabPosition='left' tabBarStyle={{backgroundColor: '#F7F9FC'}}>
                        <TabPane tab="Remarks" key="1">
                        <Row>
                            <Col xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 24 }}
                            xl={{ span: 24 }}>
                            <Card title="Remarks" className='terms-card' bordered={false} headStyle={{ border: 0 }} bodyStyle={{paddingTop: '2px'}}>
                            <Divider dashed className='divider'></Divider>
                            <pre>
                                {gridData?.remarks}
                                </pre>
                            </Card>
                            </Col>
                        </Row>
                        </TabPane>
                        <TabPane tab="Audit Information" key="2">
                        <Card title="User Details" className='terms-card' bordered={false} headStyle={{ border: 0 }} bodyStyle={{paddingTop: '2px'}}>
                        <Divider className='divider'></Divider>
                        <Row>
                        <Col>
                        <Descriptions
                                column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                                >
                                <Descriptions.Item label="Created By" >
                                <span className='descriptions'>{gridData?.createdUser}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Approved By" >
                                <span className='descriptions'>{gridData?.approvedBy}</span>
                                </Descriptions.Item>
                                </Descriptions>
                                </Col>
                                <Col>
                                <Descriptions
                                column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                                >
                                <Descriptions.Item label="Created Date">
                                <span className='descriptions'>{gridData?.createdAt ? moment(gridData?.createdAt).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Approved Date">
                                <span className='descriptions'>{gridData?.approvedOn ? moment(gridData?.approvedOn).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</span>
                                </Descriptions.Item>
                            </Descriptions>
                                </Col>
                                </Row>
                            </Card>
                        </TabPane>
                    </Tabs>
                </Card>
                </Col>
                <Col span={8} lg={8} md={24} xs={24} className='flex'>
                {gridData?.filesData && (
                        <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} formType='View' fileModal={openModal} setFileModal={setOpenModal} setRefresh={setRefresh}>
                            <Row justify="end">
                                <Col>
                                    {
                                        <Space>
                                            <Button className='new-button'
                                                onClick={fileUploadFormHandler}
                                                type="primary">
                                                Save
                                            </Button>
                                        </Space>
                                    }</Col>
                            </Row>
                        </FileUploadForm>)
                    }
                    </Col>
          </Row>           
      </div>

      {/* <Card
        style={{ textAlign: 'center' }}
        className='default-card-class'
        title={<span style={{ color: 'white' }}> Contract -  {(gridData?.fixtureNoteNo)}
          <span style={{ paddingLeft: 20 }}> <StatusTag status={gridData?.status} />
          </span>
        </span>
        }
        extra={<>{getExtraComponent(gridData?.status)}</>}
      >
        <Card >
          <Table dataSource={Array(gridData)} columns={bargeDetails} pagination={false} bordered /><br></br>
          <Table dataSource={gridData?.specPriceData ? gridData?.specPriceData : gridData} columns={specPriceData} pagination={false} bordered /><br></br>
          <Table dataSource={Array(gridData)} columns={remarks} pagination={false} bordered />
        </Card>
        {(gridData?.filesData && isPermissionExist([145])) &&
          <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} >
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button
                      onClick={fileUploadFormHandler}
                      type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </FileUploadForm>
        }

        <Affix offsetBottom={0}>
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>
              <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
              <Col>{(gridData?.status !== ContractStatusEnum.ACTIVE && isPermissionExist([139])) && <Button onClick={setFormData}>Edit</Button>}</Col>
            </Row>
          </Card>
        </Affix>
      </Card> */}


    </>
  )
}

export default BargeAgreementDetailedView