import { MessageFilled } from "@ant-design/icons";
import { BNoRequest, BNVesselApprove, BusinessNumberService, BusinessPartnerTypeEnum, ContractStatusEnum, MvDeadFreightQtyApprove, PriceTypeEnum } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Descriptions, Divider, Popover, Row, Table, TableColumnsType, Tooltip } from "antd";
import { AlertMessages, StatusTag, useAuthState } from "../../../../common";
import { error } from "console";
import { useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

interface IBNApprovalPageProps {
    businessNo?: string;
    setFeaturesRefName?: React.Dispatch<React.SetStateAction<string>>
    setCommentsTitle?: React.Dispatch<React.SetStateAction<string>>
    setCommentsVisible?: React.Dispatch<React.SetStateAction<boolean>>  
    setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>
}

export const BNApprovalsPage = (props: IBNApprovalPageProps) => {

    const { authContext } = useAuthState();
    const {businessNo,setFeaturesRefName, setCommentsTitle, setCommentsVisible, setDummyRefresh } = props;
    
    const [approvalsData, setApprovalsData] = useState<any>();
    const [vesselAgreementData, setVesselAgreementData] = useState<any>();
    const [samplingAnalysisData, setSamplingAnalysisData] = useState<any>();
    const [motherVesselData, setMotherVesselData] = useState<any>({ deadFreightTransaction: [''], vesselFinalQtyData: [''], mvDfSupplier: [''], mvDfBuyer: [], mvDfVessel: [] });

    const businessNoService = new BusinessNumberService();

    console.log('businessNo', businessNo);

    useEffect(() => {
        getAllApprovals();
        
    }, [])

    const getAllApprovals = () => {
        businessNoService.getApprovalsDataByBnId({businessNo: businessNo, plantCode: authContext.defaultPlant}).then((res) => {
            if(res.status) {
                console.log('approvalData', res.data);
                setApprovalsData(res.data)
                setVesselAgreementData(res.data.vesselAgreement[0])
                setSamplingAnalysisData(res.data.samplingAnalysisData)
            }
        }).catch((error) => {
            console.log('error', error);
        })
    }

    const approveDFMappedQty = (mvDfId: string, status: ContractStatusEnum) => {
        const req = new MvDeadFreightQtyApprove(mvDfId, status, authContext.user.userName);
        businessNoService.mvDeadFreightQtyApprove(req).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            motherVesselDataForUpdate(true);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          console.log(err.message)
        })
    
      }

      const approveVesselAgreement = (status: ContractStatusEnum) => {
        const req = new BNVesselApprove(businessNo, authContext.defaultPlant, status, authContext.user.userName);
    
        businessNoService.approveBnVesselAgreement(req).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            getAllApprovals();
            setDummyRefresh(prev => prev + 1);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        });
    
    
      }

      const approveInsurance = (insId: string, status: ContractStatusEnum) => {
        businessNoService.approveOrRejectInsurance({insId, status, userName: authContext.user.userName}).then(res => {
            if(res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getAllApprovals();
                setDummyRefresh(prev => prev + 1);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            console.log(err.message);
        })
      }

      const appproveSamplingAnalysis = (bnId: string, status: ContractStatusEnum, surveyorType: string) => {
        businessNoService.approveOrRejectBusinessNoInternalAndJoint({bnId, status, userName: authContext.user.userName, surveyorType}).then(res => {
            if(res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getAllApprovals();
                setDummyRefresh(prev => prev + 1);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            console.log(err.message);
        })
      }

      const motherVesselDataForUpdate = (makeRefresh?: boolean) => {
        const req = new BNoRequest(businessNo, authContext.defaultPlant)
        businessNoService.motherVesselDataForUpdate(req).then(res => {
          if (res.status) {
            let motherVesselDataLocal: any = { ...res.data };
            motherVesselDataLocal.estimatedETA = res.data?.estimatedETA ? moment(res.data?.estimatedETA) : undefined;
            motherVesselDataLocal.documentOnLoad = res.data?.documentOnLoad ? moment(res.data?.documentOnLoad) : undefined;
            motherVesselDataLocal.norAcceptance = res.data?.norAcceptance ? moment(res.data?.norAcceptance) : undefined;
            motherVesselDataLocal.vesselArrived = res.data?.vesselArrived ? moment(res.data?.vesselArrived) : undefined;
            motherVesselDataLocal.vesselSailing = res.data?.vesselSailing ? moment(res.data?.vesselSailing) : undefined;
            motherVesselDataLocal.loadingCompletion = res.data?.loadingCompletion ? moment(res.data?.loadingCompletion) : undefined;
            motherVesselDataLocal.loadingCommenCement = res.data?.loadingCommenCement ? moment(res.data?.loadingCommenCement) : undefined;
            const dfSuppliers = motherVesselDataLocal.mvDeadFreightQtyData?.deadFreightMapped?.filter(e => e.partnerType === BusinessPartnerTypeEnum.VENDOR);
            const dfBuyers = motherVesselDataLocal.mvDeadFreightQtyData?.deadFreightMapped?.filter(e => e.partnerType === BusinessPartnerTypeEnum.CUSTOMER);
            const dfVessel = motherVesselDataLocal.mvDeadFreightQtyData?.deadFreightMapped?.filter(e => e.partnerType === BusinessPartnerTypeEnum.VESSEL_OWNER);
            motherVesselDataLocal.mvDfSupplier = dfSuppliers?.length > 0 ? dfSuppliers : [''];
            motherVesselDataLocal.mvDfBuyer = dfBuyers?.length > 0 ? dfBuyers : [''];
            motherVesselDataLocal.mvDfVessel = dfVessel?.length > 0 ? dfVessel : [''];
            setMotherVesselData(motherVesselDataLocal);
            if (makeRefresh) {
              setDummyRefresh(prev => prev + 1);
            }
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      }

      const commentsData = (refName, title) => {
        setCommentsVisible(true);
        setFeaturesRefName(refName);
        setCommentsTitle(title);
      }
    
    const deadFreightColumns: TableColumnsType<any> = [
        {
          title: 'Partner Type',
          dataIndex: 'partnerType',
          onCell: (record: any) => ({
            className: 'nowrap'
        }),
          render: (value) => {
            return <>
            <span className="vessel-owner">{value === 'VESSEL_OWNER' ? 'Vessel Owner' : ''}</span>
            <span className="supplier">{value === 'VENDOR' ? 'Supplier' : ''}</span>
            <span className="buyer">{value === 'CUSTOMER' ? 'Buyer' : ''}</span>
            </>
          }
        },
        {
          title: 'Partner Name',
          dataIndex: 'businessPartnerName',
        },
        {
          title: 'Quantity(MT)',
          dataIndex: 'quantity',
          align: 'right',
        },
        {
          title: 'Price (Per MT)',
          dataIndex: 'price',
          align: 'right',
        },
        {
          title: 'status',
          dataIndex: 'status',
          render: (value, record) => {
            return <StatusTag status={value} />
          }
        },
        {
          title: 'Action',
          dataIndex: 'status',
          onCell: (record: any) => ({
            className: 'nowrap'
        }),
            render: (value, record) => {
                if(value === ContractStatusEnum.DRAFT) { 
                    return (<Button size="small" className='send-approval-button' onClick={() => approveDFMappedQty(record.mvDfMappedId, ContractStatusEnum.PENDING_APPROVAL)}><FontAwesomeIcon icon={faPaperPlane} />Send For Approval</Button>);
                } else if(value === ContractStatusEnum.PENDING_APPROVAL) {
                    return <> <Button size="small" className='approve' onClick={() => approveDFMappedQty(record.mvDfMappedId, ContractStatusEnum.ACTIVE)}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>&nbsp;&nbsp;
                <Button size="small" className='reject' onClick={() => approveDFMappedQty(record.mvDfMappedId, ContractStatusEnum.DRAFT)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button></>
                } else {
                    return '--'
                }
            }
         
        },
        {
            title: 'Approved By',
            dataIndex: 'apporvedBy',
          },
          {
            title: 'Date',
            dataIndex: 'approvedOn',
            render: (value) => {
              return <>{value ? moment(value).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</>
            }
          },
      ]

      const insuranceColumns: TableColumnsType<any> = [
        {
            title: 'Vendor Name',
            dataIndex: 'insVendorName'
        },
        {
            title: 'Policy Number',
            dataIndex: 'policyNumber'
        },
        {
            title: 'Price Type',
            dataIndex: 'priceType',
        },
        {
            title: 'Insurance Amount',
            dataIndex: 'insuranceAmount',
            render: (text, record) => {
                if (record.priceType === PriceTypeEnum.LUMP_SUM) {
                    return record.insurancePrice && record.priceCurrency ? (
                        <>
                        {record.insurancePrice}{' '}
                        <span className="currency-style">{record.priceCurrency}</span>
                        </>
                    ) : null;
                } else if (record.priceType === PriceTypeEnum.PER_MT) {
                    return record.insuranceAmount && record.amountCurrency ? (
                        <>
                          {record.insuranceAmount}{' '}
                          <span className="currency-style">{record.amountCurrency}</span>
                        </>
                    )   : null;
                }
                return null;
            }
        },
        {
            title: 'Action',
            dataIndex: 'status',
            onCell: (record: any) => ({
                className: 'nowrap'
            }),
              render: (value, record) => {
                    if(value === ContractStatusEnum.DRAFT) { 
                        return (<Button size="small" className='send-approval-button' onClick={() => approveInsurance(record.bnInsuranceId, ContractStatusEnum.PENDING_APPROVAL)}><FontAwesomeIcon icon={faPaperPlane} />Send For Approval</Button>);
                    } else if(value === ContractStatusEnum.PENDING_APPROVAL) {
                        return <> <Button size="small" className='approve' onClick={() => approveInsurance(record.bnInsuranceId, ContractStatusEnum.ACTIVE)}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>&nbsp;&nbsp;
                      <Button size="small" className='reject' onClick={() => approveInsurance(record.bnInsuranceId, ContractStatusEnum.DRAFT)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button></>
                    } else {
                        return '--'
                    }
              }
        },
        {
            title: 'Approved By',
            dataIndex: 'approvedBy',
        },
        {
            title: 'Date',
            dataIndex: 'approvedOn',
            render: (value) => {
                return <>{value ? moment(value).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</>
            }
        },
     
    ];

return(
    <>
    <Row gutter={[12, 12]} className='mb-12 mt-16'>
        <Col sm={{span: 24}} md={{span: 24}} lg={{span: 16}} className="flex">
            <Card title={<span className="card-title-md">Dead Freight</span>}
                extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Approvals > Dead Freight', 'Dead Freight')} /></Tooltip>}
                    headStyle={{border: 'none'}}
                    className="contracts-list card-br-8 full-width"
            >
                <Table columns={deadFreightColumns} dataSource={approvalsData?.mvDFMappedData} pagination={false} style={{overflowX: 'auto'}}></Table>
            </Card>
        </Col>
        <Col sm={{span: 24}} md={{span: 24}} lg={{span: 8}} className="flex">
            <Card title={<span className="card-title-md">Vessel Agreement</span>}
                extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Approvals > Vessel Agreement', 'Vessel Agreement')} /></Tooltip>}
                    headStyle={{border: 'none'}}
                    className="contracts-list card-br-8 full-width"
            >
                <Card className="agreement-bg card-br-8" bordered={false}>
                        <Row justify='space-between'>
                          <a 
                            href={`/#/vesselAgreement-detailed-view?va_Id=${vesselAgreementData?.vesselAgreementId}`} 
                            className="link"
                          >
                            {vesselAgreementData?.vesselAgreementNo}
                          </a>
                          <StatusTag status={vesselAgreementData?.status} />
                        </Row>
                        <Descriptions colon={false} layout='vertical' column={{ xxl: 2, xl: 2, lg: 1, md: 2, sm: 2, xs: 1 }}>
                          <Descriptions.Item span={2}>
                              {vesselAgreementData?.bpName}
                          </Descriptions.Item>
                          <Descriptions.Item label="Laycan">
                          {vesselAgreementData?.laycan && Array.isArray(vesselAgreementData.laycan) && vesselAgreementData.laycan.length === 2
                              ? `${moment(vesselAgreementData.laycan[0]).format('DD\u2011MMM\u2011YYYY')} to ${moment(vesselAgreementData.laycan[1]).format('DD\u2011MMM\u2011YYYY')}`
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Price">
                              {vesselAgreementData?.vesselLaycanPrice}&nbsp;<span className='currency-style'>{vesselAgreementData?.vesselLaycanCurrency}</span>
                          </Descriptions.Item>
                        </Descriptions>
                        {vesselAgreementData?.vesselAgreementNo &&
                        <>
                        <Divider className="divider-margin" />
                          {vesselAgreementData?.status !== ContractStatusEnum.ACTIVE ? (
                            <>
                              <span style={{display: 'flex', justifyContent: 'end', marginTop: '10px'}}>
                              {vesselAgreementData.status === ContractStatusEnum.DRAFT && (
                                <Button size='small' className='send-approval-button' onClick={() => approveVesselAgreement(ContractStatusEnum.PENDING_APPROVAL)}>
                                  <FontAwesomeIcon icon={faPaperPlane} />Send For Approval
                                </Button>
                              )}
                              {vesselAgreementData.status === ContractStatusEnum.PENDING_APPROVAL && (
                                <>
                                <Button size="small" className='approve' onClick={() => approveVesselAgreement(ContractStatusEnum.ACTIVE)}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>&nbsp;&nbsp;
                                <Button size="small" className='reject' onClick={() => approveVesselAgreement(ContractStatusEnum.DRAFT)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
                                </>
                              )}
                              </span>
                            </>
                          ) : <span>
                                <Popover  title={<span className='popover-title'>Approval Details</span>}
                                            placement='bottom'
                                            content={
                                              <div>
                                                {vesselAgreementData.vesselAgreementWorkLog && vesselAgreementData.vesselAgreementWorkLog.map((rec, index) => (
                                                  <div key={index} >
                                                      <b>{rec.createdUser}</b> Approved on <b>{rec.createdAt ? moment(rec.createdAt).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</b>
                                                  </div>
                                                ))}
                                              </div>
                                            }
                                >
                                  <Button type='link' className='link'>Approval Details</Button>
                                </Popover>
                              </span>}
                        </>
                          }
                    </Card>
            </Card>
            
        </Col>
    </Row>
    <Row gutter={[12,12]} >
        <Col sm={{span: 24}} md={{span: 24}} lg={{span: 16}} className="flex">
            <Card title={<span className="card-title-md">Insurance</span>}
                extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Approvals > Insurance', 'Insurance')} /></Tooltip>}
                    headStyle={{border: 'none'}}
                    className="contracts-list card-br-8 full-width"
            >
                <Table className="overflowX" columns={insuranceColumns} dataSource={approvalsData?.insuraneData} pagination={false} ></Table>
            </Card>
        </Col>
        <Col sm={{span: 24}} md={{span: 24}} lg={{span: 8}} className="flex">
            <Card title={<span className="card-title-md">Sampling and Analysis</span>}
                extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Approvals > Sampling and Analysis', 'Sampling and Analysis')} /></Tooltip>}
                    headStyle={{border: 'none'}}
                    className="contracts-list card-br-8 full-width"
            >
                <Descriptions
                    colon={false}
                    layout="vertical"
                    column={{ xl: 1, lg: 1, md: 1, sm: 1 }}
                >
                    <Descriptions.Item>
                        <div className="full-width" >
                            <Row justify="space-between" align="middle">
                                <span>{`${authContext.defaultPlant} Surveyor Name`}</span>
                                {samplingAnalysisData?.internalStatus === ContractStatusEnum.DRAFT && samplingAnalysisData?.internalSurveyorName ?
                                     <Button size="small" className='send-approval-button' onClick={() => appproveSamplingAnalysis(businessNo, ContractStatusEnum.PENDING_APPROVAL, 'Internal')}><FontAwesomeIcon icon={faPaperPlane} />Send For Approval</Button> :
                                     samplingAnalysisData?.internalStatus === ContractStatusEnum.PENDING_APPROVAL && samplingAnalysisData?.internalSurveyorName ?
                                    <span> <Button size="small" className='approve' onClick={() => appproveSamplingAnalysis(businessNo, ContractStatusEnum.ACTIVE, 'Internal')}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>&nbsp;&nbsp;
                                <Button size="small" className='reject' onClick={() => appproveSamplingAnalysis(businessNo, ContractStatusEnum.DRAFT, 'Internal')}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button></span>
                                 : <span>
                                    {samplingAnalysisData?.internalSurveyorName && <Popover  title={<span className='popover-title'>Approval Details</span>}
                                                placement='bottom'
                                                content={
                                                <>
                                                    {samplingAnalysisData?.internalApprovedBy &&  (
                                                    
                                                        <><b>{samplingAnalysisData.internalApprovedBy}</b> Approved on <b>{samplingAnalysisData?.internalApprovedOn ? moment(samplingAnalysisData.internalApprovedOn).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</b></>
                                                    )}
                                                </>
                                                }
                                    >
                                    <Button type='link' className='link'>Approval Details</Button>
                                    </Popover>}
                               </span>}
                            </Row>
                            {samplingAnalysisData?.internalSurveyorName && (
                                <Card bordered={false} headStyle={{ border: 'none' }} size="small" className="surveyor-card mb-12" style={{marginTop: '8px', width: '100%'}} >
                                    <Row justify="space-between" align="middle">
                                        <span>{samplingAnalysisData?.internalSurveyorName}</span>
                                        <span className='surveyor-type'>{samplingAnalysisData?.internalSurveyorAnalysisType}</span>
                                    </Row>
                                </Card>
                            )}
                            <Row justify='space-between' align="middle">
                                <span>Witness Surveyor</span>
                                {samplingAnalysisData?.jointStatus === ContractStatusEnum.DRAFT && samplingAnalysisData?.witnessSurveyorName ?
                                     <Button size="small" className='send-approval-button' onClick={() => appproveSamplingAnalysis(businessNo, ContractStatusEnum.PENDING_APPROVAL, 'Joint')}><FontAwesomeIcon icon={faPaperPlane} />Send For Approval</Button> :
                                     samplingAnalysisData?.jointStatus === ContractStatusEnum.PENDING_APPROVAL && samplingAnalysisData?.witnessSurveyorName ?
                                    <span> <Button size="small" className='approve' onClick={() => appproveSamplingAnalysis(businessNo, ContractStatusEnum.ACTIVE, 'Joint')}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>&nbsp;&nbsp;
                                <Button size="small" className='reject' onClick={() => appproveSamplingAnalysis(businessNo, ContractStatusEnum.DRAFT, 'Joint')}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button></span>
                                 : <span>
                                        {samplingAnalysisData?.witnessSurveyorName && <Popover  title={<span className='popover-title'>Approval Details</span>}
                                                    placement='bottom'
                                                    content={
                                                    <div>
                                                        {samplingAnalysisData?.jointApprovedBy &&  (
                                                        
                                                            <><b>{samplingAnalysisData.jointApprovedBy}</b> Approved on <b>{samplingAnalysisData?.jointApprovedOn ? moment(samplingAnalysisData.jointApprovedOn).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</b></>
                                                        )}
                                                    </div>
                                                    }
                                        >
                                        <Button type='link' className='link'>Approval Details</Button>
                                        </Popover>}
                                    </span>}
                              </Row>
                            {samplingAnalysisData?.witnessSurveyorName && (
                                <Card bordered={false} headStyle={{ border: 'none' }} size="small" className="surveyor-card" style={{marginTop: '8px', width: '100%'}} >
                                    <Row justify="space-between" align="middle">
                                        <span>{samplingAnalysisData?.witnessSurveyorName}</span>
                                        <span className='surveyor-type'>{samplingAnalysisData?.jointSurveyorAnalysisType}</span>
                                    </Row>
                                </Card>
                            )}
                        </div>
                    </Descriptions.Item>
                </Descriptions>

            </Card>        
        </Col>
    </Row>
    </>
)

} 

export default BNApprovalsPage;