import React, { useEffect, useState } from "react";
import { internationalFormattedValue } from "@exportx/ui-utils";
import moment from "moment";
import { Card, Col, Row, Table, Typography, Flex, Divider } from "antd";
import { ColumnsType } from "antd/es/table";
import { ExpenseEntryIdReq, ExpenseEntryService } from "@exportx/shared-models-and-services";
import { AlertMessages, useAuthState } from "../../../common";

const { Text } = Typography;

interface ExpenseEntryProps {
  expenseEntryId: string;
  remainingAmount?: number;
  children?: React.ReactNode;
}

export const ExpenseEntryCostingDetails = (props: ExpenseEntryProps) => {
  const { expenseEntryId, remainingAmount, children } = props;
  const { authContext } = useAuthState()
  const expenseEntryService = new ExpenseEntryService()
  const [initialValues, setInitialValues] = useState<any>();
  const [advTotal, setAdvTotal] = useState<number>(0)

  useEffect(() => {
    if (expenseEntryId) {
      updateExpensive(expenseEntryId);
    };
  }, [expenseEntryId]);

  const updateExpensive = (value) => {
    const req = new ExpenseEntryIdReq()
    req.expenseEntryId = value;
    req.plantCode = authContext.defaultPlant;
    expenseEntryService.updateExpensive(req).then((res) => {
      if (res.status) {
        setInitialValues({
          ...res.data,
          jisdorDate: res.data?.
            expenseEntryTransactions[0]?.jisdorDate,
          currency: res.data?.
            expenseEntryTransactions[0]?.currency,
          quoteCurrency: authContext.defaultPlantCurrency
        });
        const advTotal = Number(res.data.advanceTarnsactionsData.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2))
        setAdvTotal(advTotal)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      console.log(err.message)
    })
  };

  const tableColumns: ColumnsType<any> = [

    {
      title: 'Barge No',
      dataIndex: 'bargesId',
      render: (value, record) => {
        console.log('record =>', record)
        if (record.bargeId) {
          const link = `/#/barge-detail-view?barge_id=${record.bargeId}`;
          return <div>
            <a href={link} className="link" target="_blank">
              {value}
            </a>
          </div>

        }
        return <Typography className="small-text"> {value}</Typography>

      }
    },
    {
      title: 'Business No',
      dataIndex: 'businessNumber',
      render: (text, record) => <>
        <Text className="small-text">{text}</Text>
      </>

    },
    {
      title: 'Vendor',
      dataIndex: 'actLedName',
      render: (text, record) => <>
        <Text className="small-text">{text}</Text>
      </>

    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(text, 3)}</Text>
      </>

    },
    {
      title: 'Payable (lumpsum or PMT)',
      dataIndex: 'payable',

    },
    {
      title: 'Price',
      dataIndex: 'expensivePrice',
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(text, 3)}</Text>
        <span className="currency-style">{record?.currency}</span>
      </>
    }

  ]
  return (
    <>

      <Row gutter={8}>
        <Col span={16}>
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 10px 15px' } }}
            style={{ marginBottom: 8 }}
            title={<h6>Barge Details</h6>}
          >

            <Table
              className='contracts-list'
              columns={tableColumns}
              dataSource={initialValues?.expenseEntryTransactions}
              size="small"
              pagination={false}
              scroll={{ x: 1200 }}
            />

          </Card>

          {children}

        </Col >

        <Col span={8}>
          <Card
            className="card-shadow"
            title={<span className="card-title-md">Price Details</span>}
            styles={{ body: { padding: '6px 14px' } }}
          >

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Date</Text>
              <Text className="price-card-text"> {initialValues?.exchangeDate ? moment(initialValues?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(initialValues?.exchangeRateValue, 3)}  {initialValues?.quoteCurrency}
              </Text>
            </Flex>

            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading">Total Amount</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(initialValues?.total, 3)}  {initialValues?.quoteCurrency}
              </Text>
            </Flex>
            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">VAT</Text>
              <Text className="price-card-text">{`${initialValues?.vatApplicable ? 'Yes' : 'No'}`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(initialValues?.vat, 3)}  {initialValues?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TDS</Text>
              <Text className="price-card-text">{`${initialValues?.tdsName || 'N/A'} (${initialValues?.tdsPercent || '0'})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(initialValues?.tds, 3)}  {initialValues?.quoteCurrency}
              </Text>
            </Flex>

            <Divider dashed={true} />

            {initialValues?.advanceTarnsactionsData?.map((adv) => {
              const link = `/#/advanceDetailView?id=${adv.adjustedCostingId}`;

              return (
                <Flex justify="space-between" align='center' key={adv.id}>
                  <Text className="price-card-text">Deduction (<a href={link} className="link" target='_blank'>{adv.adjustedCostingNo}</a>)</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(adv?.totalAdjustedAmount, 3)}  {initialValues?.quoteCurrency}
                  </Text>
                </Flex>
              )
            })}

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Total Advance Adjustment</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(advTotal, 3)}  {initialValues?.quoteCurrency}
              </Text>
            </Flex>

            <Flex justify="space-between" align='center'>
              <Text className="price-card-green">To be paid to Supplier</Text>
              <Text className="price-card-green">
                {internationalFormattedValue(Number(initialValues?.totalAmount - advTotal), 3)}  {initialValues?.quoteCurrency}
              </Text>
            </Flex>
            {Number(remainingAmount) > 0 &&

              <Flex justify="space-between" align='center'>
                <Text className="price-card-red">Remaining Amount</Text>
                <Text className="price-card-red">
                  {internationalFormattedValue(remainingAmount, 3)}  {initialValues?.quoteCurrency}
                </Text>
              </Flex>
            }
          </Card>
        </Col>
      </Row>
    </>
  )
}
