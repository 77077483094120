import { DeleteFilled, PlusSquareOutlined } from '@ant-design/icons';
import { BpCurrencyResponseDto, ContractIdRequest, ContractTypesEnum, CurrencyService, LcTypeEnum, PaymentModesEnum, PcQualitiesDropDownDto, PcTypesDropDownDto, PriceTypeEnum, ProcurementContractTypeEnum, PurchaseContractService, SalesContractService } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Space, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DatePicker from '../../../common/data-picker/date-picker';
import { useAuthState } from '../../../common';
import React from 'react';

const { Option } = Select;
const { RangePicker } = DatePicker;
interface IQualitiesFormProps {
    contractCurrency: string;
    purchaseContractNo: string;
    initialValues: any;
    children?: React.ReactNode;
    formRef: FormInstance<any>;
    contractType?: ProcurementContractTypeEnum;
    singleLaycan?: boolean;
    poAction?: ContractTypesEnum;
}
export const QualitiesForm = (props: IQualitiesFormProps) => {
    const { purchaseContractNo, contractCurrency, formRef, contractType, singleLaycan, poAction } = props;
    const { formatMessage: fm } = useIntl();
    const { authContext } = useAuthState();
    const [currencies, setCurrencies] = useState<any[]>([]);
    const [initialValues, setInitialValues] = useState<{ qualityData: any[] }>();

    const [qualitiesDropdown, setQualitiesDropdown] = useState<PcQualitiesDropDownDto[]>([]);
    const [pcTypes, setPcTypes] = useState<PcTypesDropDownDto[]>([]);

    const pathToPurchase = contractType === ProcurementContractTypeEnum.Marketing_Fees ? 'mdm.components.salesTypesForm.' : 'mdm.components.purchaseTypesForm.';

    const purchase = new PurchaseContractService();
    const sales = new SalesContractService();

    console.log('props.initialValues =>', props.initialValues)

    useEffect(() => {
        if (props.initialValues?.qualityData[0]?.pcQualitiesId) {
            let valesToSet = { ...props.initialValues };
            props?.initialValues?.qualityData.forEach((item, index) => {
                // if (!Array.isArray(item?.layCan)) {
                let layCan = Array.isArray(item?.layCan) ? item?.layCan : item?.layCan.split(',');
                console.log('layCan =>', layCan)
                valesToSet.qualityData[index].layCan = [moment(layCan[0]), moment(layCan[1])];
                // }
                handleUpdate(index, item?.pcQualitiesId);
            });
            console.log('valesToSet =>', valesToSet)
            setInitialValues(valesToSet);
            formRef.setFieldsValue(valesToSet)
        } else {
            setInitialValues(props.initialValues);
            formRef.setFieldsValue(props.initialValues)
        }
    }, [props.initialValues, qualitiesDropdown]);


    useEffect(() => {
        if (purchaseContractNo) {
            getQualitiesData(purchaseContractNo);
        }

    }, [purchaseContractNo])


    const getQualitiesData = (contractId: string, isUsEffectCall?: boolean) => {
        const req = new ContractIdRequest(contractId, authContext.defaultPlant)
        const service = contractType === ProcurementContractTypeEnum.Marketing_Fees ? sales : purchase;
        service.getQualitiesDropDown(req).then(res => {
            if (res.status) {
                setQualitiesDropdown(res.data.pcQualities);
            }
        }).catch(err => {
            console.log('error:' + err.message);
        })
    }

    const handleUpdate = (index, newValue) => {
        const currentList = formRef.getFieldValue("qualityData") || [];
        const selectedQuality = qualitiesDropdown.find(rec => rec.pcqId === newValue);
        currentList[index] = {
            ...currentList[index],
            purchaseQuality: selectedQuality?.pcqQuality,
            layCan: [moment(selectedQuality?.layCan?.split(',')[0]), moment(selectedQuality?.layCan?.split(',')[1])],
            quantityInMT: selectedQuality?.pcqQuantity,
        }; // Update the specific index
        setPcTypes(selectedQuality?.pcTypes);
        formRef.setFieldsValue({ qualityData: currentList }); // Update the form values
    };

    const onChangePrice = (index, newValue) => {
        if (formRef.getFieldValue(['qualityData', index, 'priceType']) === PriceTypeEnum.PER_MT) {
            const pricePMT = Number.isNaN(Number(formRef.getFieldValue(['qualityData', index, 'pricePMT']))) ? 1 : Number(formRef.getFieldValue(['qualityData', index, 'pricePMT']));
            const quantityInMT = Number.isNaN(Number(formRef.getFieldValue(['qualityData', index, 'quantityInMT']))) ? 1 : Number(formRef.getFieldValue(['qualityData', index, 'quantityInMT']));

            const currentList = formRef.getFieldValue("qualityData") || [];
            currentList[index] = {
                ...currentList[index],
                totalPrice: pricePMT * quantityInMT,
            }; // Update the specific index
            formRef.setFieldsValue({ qualityData: currentList }); // Update the form values
        }
    }

    const onChangePurchaseType = (index, newValue) => {
        const currentList = formRef.getFieldValue("qualityData") || [];
        currentList[index] = {
            ...currentList[index],
            pcPurchaseType: newValue,
        }; // Update the specific index
        formRef.setFieldsValue({ qualityData: currentList }); // Update the form values

    }

    return (
        <div>
            <Form
                form={formRef}
                layout='vertical'
                autoComplete='off'
                initialValues={initialValues}
            >
                <Form.List name="qualityData">
                    {(qualityFields, { add: addQuality, remove: removeQuality }) => (<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        {qualityFields.map((qualityField, qualityIndex) => (
                            <Row key={qualityIndex}>
                                <Col
                                    xs={{ span: 23 }}
                                    sm={{ span: 23 }}
                                    md={{ span: 23 }}
                                    lg={{ span: 23 }}
                                    xl={{ span: 23 }}>
                                    <Card>
                                        <Row gutter={16}>
                                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} >

                                                <Form.Item
                                                    name={[qualityField.name, "pcQualitiesId"]}
                                                    label={contractType === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Quantity" : fm({ id: "mdm.components.procurmentservice.PurchaseQuality ", defaultMessage: `Purchase Quality ` })}
                                                    rules={[{ required: true, message: 'PurchaseQuality Is ReQuired' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder={fm({ id: `common.procurmentservice.reqPurchaseQuality `, defaultMessage: `Please Select` })}
                                                        onChange={(value) => handleUpdate(qualityIndex, value)}
                                                    >
                                                        {qualitiesDropdown?.map((rec) => {
                                                            const laycanDate = rec.layCan.split(',');
                                                            return <Option value={rec.pcqId} key={rec.pcqId}>{`${moment(laycanDate[0]).format('DD MMM YY') + ' - ' + moment(laycanDate[1]).format('DD MMM YY')} - (${rec.pcqQuality})`}</Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Form.Item
                                                name={[qualityField.name, "purchaseQuality"]}
                                                style={{ display: 'none' }}
                                            >
                                                <Input hidden />
                                            </Form.Item>
                                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} >

                                                <Form.Item
                                                    label='Laycan'
                                                    name={[qualityField.name, "layCan"]}
                                                >
                                                    <RangePicker disabled={true} />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} >


                                                <Form.Item
                                                    name={[qualityField.name, "pcPurchaseTypeId"]}
                                                    label={contractType === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Type" : fm({ id: "mdm.components.procurmentservice.purchaseType", defaultMessage: 'Purchase Type' })}
                                                    rules={[{ required: true, message: 'purchase Type' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder={fm({ id: `common.procurmentservice.reqPurchaseType`, defaultMessage: `Please Select` })}
                                                        onChange={(value, opt: any) => onChangePurchaseType(qualityIndex, opt?.children)
                                                        }
                                                    >
                                                        {pcTypes?.map((rec) => {
                                                            return <Option value={rec.pcTypeId} key={rec.pcTypeId} type={rec.purchaseType}>{`${rec.purchaseType} ${rec.jettyName ? `- (${rec.jettyName})` : ''}`}</Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Form.Item
                                                name={[qualityField.name, "pcPurchaseType"]}
                                                style={{ display: 'none' }}>
                                                <Input hidden />
                                            </Form.Item>
                                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} >
                                                <Form.Item
                                                    name={[qualityField.name, "priceType"]}
                                                    label="Price Type"
                                                    rules={[{ required: true, message: "Please Select An Option" }]} >
                                                    <Radio.Group>
                                                        {Object.keys(PriceTypeEnum).map(priceTypeKey => {
                                                            return <Radio.Button key={priceTypeKey} value={PriceTypeEnum[priceTypeKey]}>{PriceTypeEnum[priceTypeKey]}</Radio.Button>
                                                        })}
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} >
                                                <Form.Item
                                                    name={[qualityField.name, "quantityInMT"]}
                                                    label={fm({ id: "mdm.components.procurmentservice.quantity ", defaultMessage: 'Quantity' })}
                                                    rules={[{ required: true, message: 'Quantity' }]}>
                                                    <InputNumber placeholder={fm({ id: `common.procurmentservice.reqQuantit`, defaultMessage: `Quantity` })} min={0}
                                                        onChange={(value) => onChangePrice(qualityIndex, value)}

                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Form.Item dependencies={[['qualityData', qualityField.name, 'priceType']]} noStyle>
                                                {({ getFieldValue }) => {
                                                    const priceType = getFieldValue(['qualityData', qualityField.name, 'priceType']);
                                                    return (priceType === PriceTypeEnum.PER_MT) ? (
                                                        <>
                                                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} >
                                                                <Form.Item
                                                                    name={[qualityField.name, "pricePMT"]}
                                                                    label={fm({ id: "mdm.components.procurmentservice.pricePMT", defaultMessage: 'Price PMT' })}
                                                                    rules={[{ required: true, message: '"Price PMT' }]}>

                                                                    <InputNumber
                                                                        placeholder={fm({ id: `mdm.components.procurmentservice.reqPricePMT`, defaultMessage: `Enter Value` })} autoComplete='off'
                                                                        addonAfter={contractCurrency}
                                                                        onChange={(value) => onChangePrice(qualityIndex, value)}
                                                                        min={0}
                                                                        precision={3}
                                                                    />
                                                                </Form.Item >
                                                            </Col>
                                                        </>
                                                    ) : null;
                                                }
                                                }
                                            </Form.Item>
                                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} >
                                                <Form.Item
                                                    name={[qualityField.name, "totalPrice"]}
                                                    label={fm({ id: "mdm.components.procurmentservice.totalPrice", defaultMessage: 'TotalPrice' })}
                                                    rules={[{ required: true, message: 'Total Price' }]}
                                                >
                                                    <InputNumber
                                                        placeholder={fm({ id: `common.procurmentservice.reqTotalPrice`, defaultMessage: `Enter Value` })} autoComplete='off'
                                                        addonAfter={contractCurrency}
                                                        disabled={formRef.getFieldValue(["qualityData", qualityIndex, 'priceType']) === PriceTypeEnum.PER_MT}
                                                        min={0}
                                                        precision={2}
                                                    />
                                                </Form.Item >
                                            </Col>
                                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} >
                                                <Form.Item
                                                    name={[qualityField.name, "qualityAdjustment"]}
                                                    label={fm({ id: "mdm.components.procurmentservice.qualityAdjustment", defaultMessage: 'QualityAdjustment' })}
                                                    rules={[{ required: false, message: 'QualityAdjustment' }]}
                                                >
                                                    <Radio.Group buttonStyle="solid" >
                                                        <Radio.Button value={'Yes'}>Yes</Radio.Button>
                                                        <Radio.Button value={'No'}>No</Radio.Button>
                                                    </Radio.Group>
                                                </Form.Item >
                                            </Col>

                                        </Row>

                                        <h6>Payment Mode</h6>
                                        <Form.Item
                                            name={[qualityField.name, "paymentMode"]}
                                            label={fm({ id: `${pathToPurchase}paymentMode`, defaultMessage: 'Payment Mode' })}
                                            rules={[
                                                {
                                                    required: true, message: fm({ id: `${pathToPurchase}paymentMode`, defaultMessage: "Please Select Payment Mode" })
                                                }
                                            ]}
                                        >
                                            <Radio.Group buttonStyle="solid">
                                                {React.Children.toArray(Object.keys(PaymentModesEnum).map(contractTermKey => {
                                                    return <Radio.Button key={contractTermKey} value={PaymentModesEnum[contractTermKey]}>{PaymentModesEnum[contractTermKey]}</Radio.Button>
                                                }))
                                                }
                                            </Radio.Group>
                                        </Form.Item>

                                        <Form.Item dependencies={[['qualityData', qualityField.name, 'paymentMode']]} noStyle>
                                            {({ getFieldValue }) => {
                                                const paymentMode = getFieldValue(['qualityData', qualityField.name, 'paymentMode']);
                                                return (paymentMode === PaymentModesEnum.LETTER_OF_CREDIT || paymentMode === PaymentModesEnum.BOTH) ? (
                                                    <>
                                                        <Row style={{ gap: '8px' }}>
                                                            <Col>
                                                                <Form.Item
                                                                    name={[qualityField.name, "lcType"]}
                                                                    label={fm({ id: `${pathToPurchase}lcType`, defaultMessage: 'LC Type' })}
                                                                    rules={[
                                                                        {
                                                                            required: true, message: fm({ id: `${pathToPurchase}lcType`, defaultMessage: "Please Select LC Type" })
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Radio.Group buttonStyle="solid">
                                                                        {React.Children.toArray(Object.keys(LcTypeEnum).map(lcType => {
                                                                            return <Radio.Button key={lcType} value={LcTypeEnum[lcType]}>{LcTypeEnum[lcType]}</Radio.Button>
                                                                        }))
                                                                        }
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>

                                                            <Form.Item dependencies={[['qualityData', qualityField.name, 'lcType']]} noStyle>
                                                                {({ getFieldValue }) => {
                                                                    const lcType = getFieldValue(['qualityData', qualityField.name, 'lcType']);
                                                                    return (lcType === LcTypeEnum.percentage) ? (
                                                                        <Col>
                                                                            <Form.Item
                                                                                name={[qualityField.name, "lcValue"]}
                                                                                label={fm({ id: `${pathToPurchase}lcValue`, defaultMessage: 'LC Percentage' })}
                                                                                rules={[
                                                                                    {
                                                                                        required: true, message: fm({ id: `${pathToPurchase}lcValue`, defaultMessage: "Please Enter LC Percentage" })
                                                                                    }
                                                                                ]}
                                                                            >
                                                                                <InputNumber placeholder={fm({ id: `${pathToPurchase}lcValueReq`, defaultMessage: "Enter LC Percentage" })} max={100} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    ) : (lcType === LcTypeEnum.value) ? (
                                                                        <>
                                                                            <Col>
                                                                                <Form.Item
                                                                                    name={[qualityField.name, "lcValue"]}
                                                                                    label={fm({ id: `${pathToPurchase}lcValue`, defaultMessage: 'LC Value' })}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true, message: fm({ id: `${pathToPurchase}lcValue`, defaultMessage: "Please Enter LC Value" })
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Input placeholder={fm({ id: `${pathToPurchase}lcValueReq`, defaultMessage: "Enter LC Value" })} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Item
                                                                                    name={[qualityField.name, "lcCurrency"]}
                                                                                    label={fm({ id: `.currency`, defaultMessage: "Currency" })}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true, message: fm({ id: "mdm.components.contractDetailsForm.currencyReq", defaultMessage: "Please Select Currency" })
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        placeholder={fm({ id: `.currencyReq`, defaultMessage: "Please Select Currency" })}
                                                                                        allowClear
                                                                                        showSearch
                                                                                        optionFilterProp="children"
                                                                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                                                                    >
                                                                                        <Option value={''}>Please Select</Option>
                                                                                        {React.Children.toArray(currencies.map(item => {
                                                                                            return <Option value={item.currencyCode}>{item.currencyName}</Option>
                                                                                        }))
                                                                                        }
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </>
                                                                    ) : null;
                                                                }}
                                                            </Form.Item>
                                                        </Row>
                                                    </>
                                                ) : null;
                                            }}
                                        </Form.Item>
                                    </Card>

                                </Col>
                                {(qualityFields?.length > 0) && <Col xs={{ span: 2 }}
                                    sm={{ span: 1 }}
                                    md={{ span: 1 }}
                                    lg={{ span: 1 }}
                                    xl={{ span: 1 }}>
                                    <span style={{
                                        position: "absolute",
                                        marginTop: "33px",
                                        marginLeft: "25px",
                                    }}>
                                        <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                            <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                                                removeQuality(qualityField.name);

                                            }} />
                                        </Tooltip>
                                    </span>
                                </Col>
                                }

                            </Row>
                        ))}
                        {!singleLaycan && poAction !== ContractTypesEnum.ADDENDUM &&
                            <Form.Item >
                                <Button type="dashed" onClick={() => addQuality()}>
                                    Add laycan
                                </Button>
                            </Form.Item>}
                    </Space>)}
                </Form.List>

                {/* <Row>
                    <Col
                        xs={{ span: 22 }}
                        sm={{ span: 22 }}
                        md={{ span: 22 }}
                        lg={{ span: 22 }}
                        xl={{ span: 22 }}>
                        <div className='contractTypeBlock p-1 pl-3'>
                            <Row gutter={16}>
                                {purchaseContractNo && <>
                                    <Col>
                                        <Form.Item
                                            name={"pcQualitiesId"}
                                            label={contractType === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Quantity" : fm({ id: "mdm.components.procurmentservice.PurchaseQuality ", defaultMessage: `Purchase Quality ` })}
                                            rules={[{ required: true, message: 'PurchaseQuality Is ReQuired' }]}
                                        >
                                            <Select
                                                style={{ width: "212px" }}
                                                showSearch
                                                placeholder={fm({ id: `common.procurmentservice.reqPurchaseQuality `, defaultMessage: `Please Select` })}
                                                onChange={() => { }}
                                            >
                                                {qualitiesDropdown?.map((rec) => {
                                                    const laycanDate = rec.layCan.split(',');
                                                    return <Option value={rec.pcqId} key={rec.pcqId}>{`${moment(laycanDate[0]).format('DD MMM YY') + ' - ' + moment(laycanDate[1]).format('DD MMM YY')} - (${rec.pcqQuality})`}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name={"purchaseQuality"} style={{ display: 'none' }}>
                                            <Input hidden />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item label='Laycan' name={'layCan'}>
                                            <RangePicker disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name={"pcPurchaseTypeId"}
                                            label={contractType === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Type" : fm({ id: "mdm.components.procurmentservice.purchaseType", defaultMessage: 'Purchase Type' })}
                                            rules={[{ required: true, message: 'purchase Type' }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder={fm({ id: `common.procurmentservice.reqPurchaseType`, defaultMessage: `Please Select` })}
                                                style={{ width: "212px" }}
                                                onChange={() => { }}
                                            >
                                                {pcTypes?.map((rec) => {
                                                    return <Option value={rec.pcTypeId} key={rec.pcTypeId}>{`${rec.purchaseType} ${rec.jettyName ? `- (${rec.jettyName})` : ''}`}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name={"pcPurchaseType"} style={{ display: 'none' }}>
                                            <Input hidden />
                                        </Form.Item>
                                    </Col></>}
                                <Col>
                                    <Form.Item name={"priceType"}
                                        label="Price Type"
                                        rules={[{ required: true, message: "Please Select An Option" }]} >
                                        <Radio.Group>
                                            {Object.keys(PriceTypeEnum).map(priceTypeKey => {
                                                return <Radio.Button key={priceTypeKey} value={PriceTypeEnum[priceTypeKey]}>{PriceTypeEnum[priceTypeKey]}</Radio.Button>
                                            })}
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name={"quantityInMT"}
                                        label={fm({ id: "mdm.components.procurmentservice.quantity ", defaultMessage: 'Quantity' })}
                                        rules={[{ required: true, message: 'Quantity' }]}>
                                        <InputNumber placeholder={fm({ id: `common.procurmentservice.reqQuantit`, defaultMessage: `Quantity` })} min={0} />
                                    </Form.Item>
                                </Col>

                                {initialValues?.priceType === PriceTypeEnum.PER_MT && <Col>
                                    <Form.Item name={"pricePMT"}
                                        label={fm({ id: "mdm.components.procurmentservice.pricePMT", defaultMessage: 'Price PMT' })}
                                        rules={[{ required: true, message: '"Price PMT' }]}>

                                        <InputNumber
                                            placeholder={fm({ id: `mdm.components.procurmentservice.reqPricePMT`, defaultMessage: `Enter Value` })} autoComplete='off'
                                            addonAfter={contractCurrency}
                                            min={0}
                                            precision={3}
                                        />
                                    </Form.Item >
                                </Col>}

                                <Col>
                                    <Form.Item name={"totalPrice"}
                                        label={fm({ id: "mdm.components.procurmentservice.totalPrice", defaultMessage: 'TotalPrice' })}
                                        rules={[{ required: true, message: 'Total Price' }]}
                                    >
                                        <InputNumber
                                            placeholder={fm({ id: `common.procurmentservice.reqTotalPrice`, defaultMessage: `Enter Value` })} autoComplete='off'
                                            addonAfter={contractCurrency}
                                            disabled={initialValues?.priceType === PriceTypeEnum.PER_MT}
                                            min={0}
                                            precision={2}
                                        />
                                    </Form.Item >
                                </Col>
                                <Col>
                                    <Form.Item name={"qualityAdjustment"}
                                        label={fm({ id: "mdm.components.procurmentservice.qualityAdjustment", defaultMessage: 'QualityAdjustment' })}
                                        rules={[{ required: false, message: 'QualityAdjustment' }]}
                                    >
                                        <Radio.Group buttonStyle="solid" >
                                            <Radio.Button value={'Yes'}>Yes</Radio.Button>
                                            <Radio.Button value={'No'}>No</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item >
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        md={{ span: 2 }}
                        lg={{ span: 2 }}
                        xl={{ span: 2 }}>
                        <Button onClick={() => addQualityData()} type='primary' icon={<PlusSquareOutlined style={{ fontSize: '18px' }} />}></Button>
                    </Col>
                </Row> */}
            </Form>
            <div style={{ paddingBottom: '10px' }}>
                {props?.children}
            </div>
        </div>
    )
}

export default QualitiesForm;