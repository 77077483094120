import { BargeApproveReqDto, BargeRequest, BargeService, BargeSICreationDto, BusinessNumberService, ContractStatusEnum, GetAllBargeDataDto, getBargeStatusDataDto, PriceTypeEnum, ReferenceFeatures, YESOrNoRadioEnum } from "@exportx/shared-models-and-services";
import { SequenceUtils, getBase64, getFilesData, getLocalFormat } from "@exportx/ui-utils";
import { Button, Card, Col, Descriptions, Divider, Drawer, Flex, Form, Layout, Modal, Popover, Row, Space, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import Link from 'antd/es/typography/Link';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from "../../../../common";
import { FileUploadForm } from "../../../../common/file-upload-form/FileUploadForm";
import ContractQualityResults from "./contracts-quality-results";
import queryString from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircle, faDownload, } from '@fortawesome/free-solid-svg-icons';
import { EditFilled, EditOutlined, PlusCircleFilled, MessageFilled, } from '@ant-design/icons';
import { BargeShippingInstructions } from "./barge-shipping-instructions";
import ViewFileHandling from "../../../../common/file-upload-form/view-files-handling";
import CommentsPage from "../../../../common/comments/comments-page";

export const BargeDetailView = () => {
  const location = useLocation();
  const paramBargeId = queryString.parse(location.search)?.barge_id;
  const { authContext } = useAuthState();
  const { formatMessage: fm } = useIntl();
  const [filesFormRef] = Form.useForm();
  const printRef = React.useRef();
  const { state }: any = useLocation();
  const navigate = useNavigate();
  let { bargeId } = paramBargeId != null ? { bargeId: paramBargeId } : state ? state : { bargeId: null };
  const [getAllBarge, setGetAllBarge] = useState<GetAllBargeDataDto>()
  const service = new BargeService();
  const [bargeTimings, setBargeTimings] = useState<getBargeStatusDataDto[]>([])
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [shippingInstructions, setShippingInstructions] = useState<GetAllBargeDataDto>();

  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');

  const bnService = new BusinessNumberService();
  let pathToreDirect = '/bn-detail-view'


  let pathToreDirec = '/barge-detail-view'

  const redirectToFull = (bargeId: string) => {
    navigate(pathToreDirec, { state: { bargeId } });
  }

  let pathToreDirectPc = '/po-detail-view'

  const redirectToFullViewPc = (contractId: string) => {
    navigate(pathToreDirectPc, { state: { contractId } });
  }

  const redirectToFullView = (businessNo: string) => {
    navigate(pathToreDirect, { state: { businessNo } });
  }
  useEffect(() => {
    getBargePreviewData(bargeId);
    bargeTimingData(bargeId)
  }, [bargeId, refresh]);

  const bargeTimingData = (bargeId) => {
    const req = new BargeRequest(bargeId, authContext.defaultPlant)
    bnService.getBargeStatusData(req).then(res => {
      if (res.status) {
        setBargeTimings(res.data)
      }
    }).catch(err => console.log(err.message))
  }


  const getBargePreviewData = (bargeId: number) => {
    const req = new BargeRequest(bargeId);
    service.getBargePreviewData(req).then(res => {
      if (res.status) {
        setGetAllBarge(res.data)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const approveOrRejectChemicalSpray = (status: ContractStatusEnum) => {
    const req = new BargeApproveReqDto(bargeId, authContext.user.userName, authContext.defaultPlant, status)
    service.approveChemicalSprayBarge(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage)
        getBargePreviewData(bargeId)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        getBargePreviewData(bargeId)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const approveBargeDeadWeight = (status: ContractStatusEnum) => {
    const approvalLevel = getAllBarge?.deadFreightStatus === ContractStatusEnum.PENDING_APPROVAL ? ContractStatusEnum.BUSINESS_APPROVAL : ContractStatusEnum.AUDIT_APPROVAL;

    const req = new BargeApproveReqDto(bargeId, authContext.user.userName, authContext.defaultPlant, status, approvalLevel)
    service.approveBargeDeadWeight(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage)
        getBargePreviewData(bargeId)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        getBargePreviewData(bargeId)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }



  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('print.pdf');
  };

  const setFormData = (bargeId: number) => {
    const req = new BargeRequest(bargeId);
    service.getBargePreviewData(req).then(res => {
      if (res.status) {
        console.log(res.data);
        navigate('/barge-update', { state: { bargeId: getAllBarge.id } });
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
    // navigate('/barge-update', { state: previewData });
  }

  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      getFilesData(fileValues.filesData).then(filesData => {
        service.addMoreFilesToBarge({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          bargeId: bargeId,
          plantCode: authContext.defaultPlant
        }).then(res => {
          if (res.status) {
            getBargePreviewData(bargeId);
            setOpenModal(false)
            AlertMessages.getSuccessMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          };
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        });
      });
    });
  };

  const surveyorDetails = [
    {
      key: '1',
      label: 'Supplier Surveyor',
      name: getAllBarge?.supplierSurveyorName,
      type: getAllBarge?.supplierSurveyorAnalysisType
    },
    {
      key: '2',
      label: 'Witness Surveyor',
      name: getAllBarge?.witnessSurveyorName,
      type: getAllBarge?.jointSurveyorAnalysisType
    },
    {
      key: '3',
      label: 'SRPL Surveyor Name',
      name: getAllBarge?.internalSurveyorName,
      type: getAllBarge?.internalSurveyorAnalysisType
    },
    {
      key: '4',
      label: 'Additional Surveyor 1',
      name: getAllBarge?.additionalAnalysisType1Name1,
      type: getAllBarge?.additionalAnalysisType
    },
    {
      key: '5',
      label: 'Additional Surveyor 2',
      name: getAllBarge?.additionalSurveyorName1,
      type: getAllBarge?.additionalAnalysisType1
    },
  ]

  const bargeTimingColumns: ColumnsType<any> = [
    {
      title: 'Business No',
      dataIndex: 'businessNumber',
      render: (text, record) => {
        return(
          <>
          <Link onClick={() => { redirectToFullView(record?.businessNoId); }} style={{ color: '#0083C9' }}> {record.businessNumber}</Link>
          <Typography className="small-text">{record?.motherVesselName}</Typography>
          
          </>

        )
      }
    },
    {
      title: 'Allocation',
      dataIndex: 'allocationId'
    },
    {
      title: 'Barge Status',
      dataIndex: 'bargeStatus',
      render: (value, record) => {
        return value ? <span style={{ color: '#94A3B8' }}><FontAwesomeIcon icon={faCircle} size="xs" style={{ marginRight: '4px' }} />{value}</span> : ''
    }
    },
    {
      title: 'Anchorage',
      children: [
        {
          title: 'Commence',
          dataIndex: 'commenceDischarge',
          key: 'commenceDischarge',
        },
        {
          title: 'Completed',
          dataIndex: 'completionDischarge',
          key: 'completionDischarge',
        },
        {
          title: 'Time',
          dataIndex: 'completionDischarge',
          key: 'completionDischarge',
        },
      ],
    },
  ]
  const link = `/#/po-detail-view?pc_id=${getAllBarge?.purchaseContract}`
  const bargeAgreement = `/#/bargeAgreement-detailed-view?ba_Id=${getAllBarge?.bargeAgreementId}`
  const links = `/#/detailCustomer?bp_id=${getAllBarge?.bargeVendor}`


  const [shippingForm] = Form.useForm();

  const onSaveShippingInstructions = () => {
    shippingForm.validateFields().then(async values => {
      console.log('values =>', values)
      let count = 1;
      for (const file of values?.shippingInstructions) {
        if (file?.filesData?.file) {
          const base64 = await getBase64(file?.filesData.fileList[0].originFileObj);
          const document = file.filesData.fileList[0]
          file.file = [{
            ...document,
            base64Url: base64,
            fileDescription: 'SI file' + " " + count,
            fileUploadId: file.fileUploadId
          }];
          count += 1;
        } else {
          file.file = [];
        }
      }
      const payload = new BargeSICreationDto(bargeId, authContext.user.userName, values.shippingInstructions, authContext.defaultPlant)
      service.createBargeShippingInstructions(payload).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage)
          setOpenDrawer(false)
          getBargePreviewData(bargeId)
          shippingForm.resetFields()
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch(err => {
        console.log(err)
      })
    }).catch(err => {
      console.log(err)
    })

  }

  const bargeSIColums: ColumnsType<any> = [
    {
      title: 'SI Date',
      dataIndex: 'siDate',
      render: (text, record) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
    },
    {
      title: 'SI Quantity',
      dataIndex: 'siQuantity'
    },
    {
      title: "Document",
      render: (value, record) => {
        return <>
          <ViewFileHandling filesData={record?.file} required={true} />
        </>
      }
    },
    {
      title: "Action",
      align: 'left',
      fixed: 'right',
      render: (text, rowData) => (
        <span>
          <EditOutlined type="edit"
            onClick={() => {
              const data = [rowData]?.map((item) => ({ ...item, siDate: moment(item.siDate) }))
              setShippingInstructions((prev) => { return { ...prev, shippingInstructions: data } })
              setOpenDrawer(true)
              shippingForm.setFieldsValue({ shippingInstructions: data })
            }}
            className='action-icons mr-8'
          />
        </span>

      )
    }
  ]

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    setCommentsTitle(title);
  }


  return (
    <>
      <Layout style={{ backgroundColor: '#F8F8F8', border: 10 }}>
        <div style={{ backgroundColor: '#F5F5F5', padding: '0px' }} ref={printRef}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'none', marginBottom: '10px' }}>
            <Space size={5}>
              <a href="/#/barge-grid" style={{ fontSize: '18px', color: '#6B7280' }}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </a>
              <span style={{ fontSize: '16px', fontWeight: 600, color: '#323F49' }}>{`${getAllBarge?.bargeId} (${getAllBarge?.bargeNomination})`}</span>&nbsp;
            </Space>
            <Space size={10}>
              <Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData(ReferenceFeatures.BARGES, 'All Comments')} /></Tooltip>

              <Button
                icon={<FontAwesomeIcon icon={faDownload} />}
                className="icon"
                onClick={handleDownloadPdf}
              />
              {isPermissionExist([264]) &&
                <Button
                  icon={<EditFilled />}
                  className="icon"
                  onClick={() => { setFormData(bargeId) }}
                />
              }
            </Space>
          </div>
          <Row gutter={[14, 12]}>
            <Col span={13} xs={24} md={24} lg={24} xl={13}>
              <Card bordered={false} className="ant-card-height" >
                <Descriptions
                  colon={false}
                  layout="vertical"
                  title="Purchase Contract"
                  items={[{
                    key: '1',
                    label: 'Name &ID',
                    children: <div>
                      <div>{getAllBarge?.bargeVendors}</div>
                      <Link href={link} style={{ color: '#0083C9' }}>{getAllBarge?.purchaseContractNo ?? '-'}</Link>
                    </div>,
                  },
                  {
                    key: '2',
                    label: 'Laycan, Terms & Quality',
                    children: <div>
                      <div>{getAllBarge?.laycanFromDate && getAllBarge?.laycanToDate && `${moment(getAllBarge?.laycanFromDate).format('DD-MMM-YYYY')} to ${moment(getAllBarge?.laycanToDate).format('DD-MMM-YYYY')} `}</div>
                      <span>  {getAllBarge?.purchaseTerm}, {getAllBarge?.quality}</span>
                    </div>,
                  },
                  {
                    key: '3',
                    label: 'Type',
                    children: <span>{getAllBarge?.purchaseType ?? '-'}</span>,
                  },
                  ]} />
                <br />

                <Descriptions
                  colon={false}
                  layout="vertical"
                  title="Barge Vendor"
                  items={[{
                    key: '1',
                    label: 'Vendor Name',
                    children: <Link href={links} style={{ color: '#0083C9' }}>{getAllBarge?.bargeVendorName ?? '-'}</Link>
                  },
                  {
                    key: '2',
                    label: 'Agreement',
                    children: <Link href={bargeAgreement} style={{ color: '#0083C9' }}>{getAllBarge?.bargeAgreementNo ?? '-'}</Link>,
                  },
                  {
                    key: '3',
                    label: 'Loading  Jetty',
                    children: <span>{getAllBarge?.loadingJetty ?? '-'}</span>,
                  },
                  ]} />
                <br />

                <Card
                  bordered={false}
                  size="small"
                  className="no-shadow"
                  styles={{ body: { padding: '0px' }, header: { padding: '0px', paddingBottom: 5 } }}
                  title={<h6>Shipping Instructions(SI)</h6>}
                  extra={<Button icon={<PlusCircleFilled />} className="action-icons" onClick={() => setOpenDrawer(true)} />}
                >
                  <Table
                    columns={bargeSIColums}
                    dataSource={getAllBarge?.shippingInstructions}
                    size="small"
                    pagination={false}
                  />
                </Card>


              </Card>
            </Col>
            <Col span={11} xs={24} md={24} lg={24} xl={11} >
              <Card bordered={false} className="ant-card-height" >
                <Descriptions
                  colon={false}
                  layout="vertical"
                  title="Price"
                  items={[{
                    key: '1',
                    label: 'Base Price (MT)',
                    children: <span>
                      {getAllBarge?.bargingType === PriceTypeEnum.PER_MT ?
                        <span>{getLocalFormat(+getAllBarge?.bargingBasePrice, getAllBarge?.bargingBasePriceCurrency)}</span>
                        :
                        <span>{getLocalFormat(+getAllBarge?.lumpSumBasePrice, getAllBarge?.lumpSumBasePriceCurrency)}</span>
                      }
                    </span>
                  },
                  {
                    key: '2',
                    label: 'Type',
                    children: <span> {getAllBarge?.bargingType}</span>
                  },
                  {
                    key: '3',
                    label: 'TDS',
                    children: <span> {getAllBarge?.pph5}</span>,
                  },
                  ]} />
                <br />
                <Descriptions
                  colon={false}
                  layout="vertical"
                  title="Quantity"
                  items={[{
                    key: '1',
                    label: 'Quantity (MT)',
                    children: <span>{getAllBarge?.bargeQuantityInMt}</span>
                  },
                  {
                    key: '2',
                    label: 'Loaded Quantity (MT)',
                    children: <h6 style={{ color: '#0EAD49' }}>{getAllBarge?.loadedQuantityInMt}</h6>,
                  },
                  {
                    key: '3',
                    label: 'Return Cargo Quantity (MT)',
                    children: <h6 style={{ color: '#0EAD49' }}>{getAllBarge?.returnCargoQuantityInMt}</h6>,
                  },
                  {
                    key: '3',
                    label: 'Barges ID',
                    children: <span onClick={() => redirectToFull(getAllBarge?.returnBarge)}><Link >
                      {getAllBarge?.returnBargeId ? SequenceUtils.formatNumberToSpecificLength(getAllBarge?.returnBargeId?.toString()) : '-'}</Link></span>
                  },
                  ]} />

                <Card title={`Dead Weight`} bordered={true} className="no-shadow" styles={{ body: { paddingTop: 2 } }}>
                  <Card bordered={false} className="remarks-card no-shadow" styles={{ body: { padding: '10px' } }}>
                    <Flex justify="flex-end" align="center">
                      <StatusTag status={getAllBarge?.deadFreightStatus} />
                    </Flex>

                    <Descriptions
                      colon={false}
                      layout="vertical"
                      items={[{
                        key: '1',
                        label: 'Dead Weight',
                        children: <span>{getAllBarge?.deadFreight ?? '-'}</span>
                      },
                      {
                        key: '2',
                        label: 'Incurred',
                        children: <span>  {getAllBarge?.deadFreightIncurred ?? '-'}</span>
                      },
                      {
                        key: '3',
                        label: 'Applicability',
                        children: <span> {getAllBarge?.deadFreightApplicability ?? '-'}</span>,
                      },
                      ]} />

                    <Divider />

                    {getAllBarge?.deadFreightStatus === ContractStatusEnum.DRAFT &&
                      <Flex align="center" justify="flex-end">
                        <Button type="text" size="small" className="link" onClick={() => approveBargeDeadWeight(ContractStatusEnum.PENDING_APPROVAL)}>Send For Approval</Button>
                      </Flex>

                    }

                    {(getAllBarge?.deadFreightStatus === ContractStatusEnum.PENDING_APPROVAL || getAllBarge?.deadFreightStatus === ContractStatusEnum.PENDING_AUDIT_APPROVAL) &&

                      <Flex align="center" justify="flex-end">
                        <Space>
                          <Button size='small' className='approve' onClick={() => approveBargeDeadWeight(getAllBarge?.deadFreightStatus === ContractStatusEnum.PENDING_APPROVAL ? ContractStatusEnum.PENDING_AUDIT_APPROVAL : ContractStatusEnum.ACTIVE)}>Approve</Button> &nbsp;&nbsp;&nbsp;
                          <Button size='small' className='reject' onClick={() => approveBargeDeadWeight(ContractStatusEnum.CANCELLED)}>Cancel</Button> &nbsp;&nbsp;&nbsp;
                        </Space>
                      </Flex>
                    }

                    {getAllBarge?.deadFreightStatus === ContractStatusEnum.ACTIVE &&

                      <Popover
                        placement="bottom"
                        title={<Typography className="tooltip-heading">Approval Details</Typography>}
                        content={<>
                          {getAllBarge?.deadFreightWorkLog?.map((log) => (
                            <Typography style={{ fontSize: 10 }}><b>{log?.createdUser}</b> Approved On <b>{log?.createdAt ? moment(log?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
                          ))}
                        </>}

                      >
                        <Button type="text" size="small" className="link">Approval Details</Button>

                      </Popover>

                    }
                  </Card>
                </Card>

              </Card>
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 12]}>
            <Col span={13} xs={24} md={24} lg={24} xl={13}>
              <Card bordered={false} className="ant-card-height" >
                <Descriptions
                  colon={false}
                  layout="vertical"
                  title="Barge Timing"
                  items={[{
                    key: '1',
                    label: 'Loading Start',
                    children: <span>{getAllBarge?.loadingDate ? moment(getAllBarge?.loadingDate).format('DD-MM-YYYY HH:mm') : '-'}</span>,
                  },
                  {
                    key: '2',
                    label: 'Loading Completion',
                    children: <div>
                      <span>  {getAllBarge?.completionTime ? moment(getAllBarge?.completionTime).format('DD-MM-YYYY HH:mm') : '-'}</span>
                    </div>,
                  },
                  {
                    key: '3',
                    label: 'NOR Anchorage',
                    children: <span> {getAllBarge?.norAnchorage ? moment(getAllBarge?.norAnchorage).format('DD-MM-YYYY HH:mm') : '-'}</span>,
                  },
                  ]} />
                <br />

                <Table columns={bargeTimingColumns} dataSource={bargeTimings} pagination={false} size="small" />

              </Card>
            </Col>
            <Col span={11} xs={24} md={24} lg={24} xl={11} >
              <Card bordered={false} className="ant-card-height" >
                <h6>Surveyor Details</h6>
                <Row gutter={[16, 12]}>
                  {surveyorDetails?.map((surveyor) => (
                    <Col span={24} xs={24} md={24} lg={12} xl={12} key={surveyor.key}>
                      <Typography className="label">{surveyor?.label}</Typography>
                      <Flex align="center" justify="space-between" className="standard-row">
                        <Typography style={{ width: '70%' }}>{surveyor?.name ?? '-'}</Typography>
                        <Typography className="specification-standard">{surveyor?.type ?? 'N/A'}</Typography>

                      </Flex>
                    </Col>
                  ))}
                </Row>
                <br />
                <ContractQualityResults bargeId={bargeId} bgId={getAllBarge?.bargeId} />
              </Card>

            </Col>
          </Row>
          <br />
          <Row gutter={[16, 12]}>
            <Col span={13} xs={24} md={24} lg={24} xl={13}>
              <Card title='Other Details' className="ant-card-height" bordered={false} styles={{ body: { paddingTop: 2 } }}>

                {getAllBarge?.chemicalSpray === YESOrNoRadioEnum.NO ?
                  <Descriptions
                    colon={false}
                    layout="vertical"
                  >
                    <Descriptions.Item label="Chemical Spray">
                      {getAllBarge?.chemicalSpray}
                    </Descriptions.Item>
                  </Descriptions>
                  :

                  <Card title={`Chemical Spray`} bordered={true} className="no-shadow" styles={{ body: { paddingTop: 2 } }}>
                    <Card bordered={false} className="remarks-card no-shadow" styles={{ body: { padding: '10px' } }}>
                      <Flex justify="flex-end" align="center">

                        <StatusTag status={getAllBarge?.chemicalSprayStatus} />
                      </Flex>
                      <Descriptions
                        colon={false}
                        layout="vertical"
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                      >
                        <Descriptions.Item label="Chemical Spray">
                          {getAllBarge?.chemicalSpray}
                        </Descriptions.Item>
                        <Descriptions.Item label="Chemical Spray Value">
                          {getLocalFormat(getAllBarge?.chemicalSprayPrice, getAllBarge?.chemicalSprayCurrency)}
                        </Descriptions.Item>


                      </Descriptions>


                      <Divider />

                      {getAllBarge?.chemicalSprayStatus === ContractStatusEnum.DRAFT &&
                        <Flex align="center" justify="flex-end">
                          <Button type="text" size="small" className="link" onClick={() => approveOrRejectChemicalSpray(ContractStatusEnum.PENDING_APPROVAL)}>Send For Approval</Button>
                        </Flex>

                      }
                      {getAllBarge?.chemicalSprayStatus === ContractStatusEnum.PENDING_APPROVAL &&

                        <Flex align="center" justify="flex-end">
                          <Space>
                            <Button size='small' className='approve' onClick={() => approveOrRejectChemicalSpray(ContractStatusEnum.ACTIVE)}>Approve</Button> &nbsp;&nbsp;&nbsp;
                            <Button size='small' className='reject' onClick={() => approveOrRejectChemicalSpray(ContractStatusEnum.CANCELLED)}>Cancel</Button> &nbsp;&nbsp;&nbsp;
                          </Space>
                        </Flex>

                      }
                      {getAllBarge?.chemicalSprayStatus === ContractStatusEnum.ACTIVE &&

                        <Popover
                          placement="bottom"
                          title={<Typography className="tooltip-heading">Approval Details</Typography>}
                          content={
                            <>
                              {getAllBarge?.workLog?.map((log) => (
                                <Typography style={{ fontSize: 10 }}><b>{log?.createdUser}</b> Approved On <b>{log?.createdAt ? moment(log?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
                              ))}
                            </>
                          }
                        >
                          <Button type="text" size="small" className="link">Approval Details</Button>
                        </Popover>

                      }


                    </Card>
                  </Card>
                }
                <br />
                <Card title='Remarks' bordered={false} className="remarks-card no-shadow" styles={{ body: { paddingTop: 2 } }}>
                  <Divider dashed style={{ marginTop: 0 }} />
                  <pre>
                    {getAllBarge?.remarks ?? '-'}
                  </pre>
                </Card>

              </Card>

            </Col>
            <Col span={11} xs={24} md={24} lg={24} xl={11}>
              {isPermissionExist([269]) && (
                <FileUploadForm maxCount={3}
                  layoutType='vertical'
                  formRef={filesFormRef}
                  initialValues={getAllBarge?.filesData ? getAllBarge?.filesData : []}
                  formType='View'
                  fileModal={openModal}
                  setFileModal={setOpenModal}
                  setRefresh={setRefresh}
                >
                  <Row justify="end">
                    <Col>
                      {
                        <Space>
                          <Button onClick={fileUploadFormHandler} type="primary" className='new-button'>
                            Save
                          </Button>
                        </Space>
                      }</Col>
                  </Row>
                </FileUploadForm>
              )}
            </Col>
          </Row>
        </div>

        <Modal
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onCancel={() => setOpenDrawer(false)}
          destroyOnClose
          okText='Save'
          okButtonProps={{ className: 'new-button' }}
          onOk={onSaveShippingInstructions}
          title="Shipping Instruction"
        >

          <BargeShippingInstructions
            formRef={shippingForm}
            initialValues={shippingInstructions}
          />

        </Modal>

        <Drawer
          bodyStyle={{ paddingBottom: 0 }}
          width={700}
          onClose={() => setCommentsVisible(false)}
          open={commentsVisible}
          closable={false}
        >
          <CommentsPage featuresRefNo={bargeId} featuresRefName={featuresRefName} commentsTitle={commentsTitle} setCommentsVisible={setCommentsVisible} />
        </Drawer>

      </Layout>
    </>
  )
}
export default BargeDetailView;