import { DeleteFilled, EditFilled, FilePdfFilled, MessageFilled, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { AnchorageService, BNVesselApprove, BNoRequest, BPDropDownDto, BpCategoryGetDto, BusinessNumberService, BusinessPartnerService, BusinessPartnerTypeEnum, ContractStatusEnum, FloatingCraneService, FloatingCranesGetDto, PlantCodeReqDto, VesselAgreementService, VesselLaycanDropDownDto, VesselTypesEnum, VesselVendorIdReqDto } from '@exportx/shared-models-and-services';
import { getBase64 } from '@exportx/ui-utils';
import { Affix, Button, Card, Col, Descriptions, Divider, Form, FormInstance, Input, InputNumber, Popover, Row, Select, Space, Tooltip, Upload } from 'antd';
import Item from 'antd/es/descriptions/Item';
import Table, { ColumnsType } from 'antd/es/table';
import DatePicker from '../../../../common/data-picker/date-picker';
import { FormLayout } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, StatusTag, useAuthState } from '../../../../common';
import ViewFileHandling from '../../../../common/file-upload-form/view-files-handling';
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleXmark, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

interface IShipmentPlaningProps {
  formRef: FormInstance<any>
  layoutType?: FormLayout;
  children?: React.ReactNode;
  businessNo?: string
  drawerState?: any;
  setHatchData?: (value: any) => void;
  setFeaturesRefName?: React.Dispatch<React.SetStateAction<string>>
  setCommentsTitle?: React.Dispatch<React.SetStateAction<string>>
  setCommentsVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>
}
const { RangePicker } = DatePicker;
export const ShipmentPlaning = (props: IShipmentPlaningProps) => {
  const { Option } = Select;
  const { formRef, layoutType, businessNo, drawerState, setHatchData, setDummyRefresh, setFeaturesRefName, setCommentsTitle, setCommentsVisible } = props
  const { formatMessage: fm } = useIntl();
  const businessNoService = new BusinessNumberService()
  const businessPartnerService = new BusinessPartnerService();
  const vesselAgreementService = new VesselAgreementService();

  const [stevadorVendor, setStevadorVendor] = useState<BPDropDownDto[]>([]);
  const [vesselAgents, setVesselAgents] = useState<BPDropDownDto[]>([])
  const [fcNames, setFcNames] = useState([]);
  const [shippingInitialValues, setShippingInitialValues] = useState<any>({ loadingPortData: [''], mvDischargePortData: [''], stowagePlanData: [{ hatchNames: 'hatch1' }], filesData: [] })
  const [stowageTotal, setStowageTotal] = useState(0);
  const [anchorages, setAnchorages] = useState([]);
  const anchorageService = new AnchorageService();
  const fcService = new FloatingCraneService();
  const { authContext } = useAuthState()
  const [viewForm, setViewForm] = useState<boolean>(false)
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileLists, setFileLists] = useState<any[]>([]);
  const [shipmenttype, setShipmentType] = useState<any>()
  const [vesselAgreements, setVesselAgreements] = useState<any>([]);
  const [vesselAgreementLaycans, setVesselAgreementLaycans] = useState<VesselLaycanDropDownDto[]>([]);
  const [vesselAgreementId, setVesselAgreementId] = useState<string>(''); // for vessel agreement id
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    getAllStevedorDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR));
    getAllAnchorages();
    getAllShippingDetailsData();
    getAllVesselAgentsDropDown();
    getAllVesselAgreements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessNo]);


  if (shippingInitialValues?.etaNor) {
    shippingInitialValues.etaNor = shippingInitialValues?.etaNor ? moment(shippingInitialValues?.etaNor) : undefined;
  }

  useEffect(() => {
    setVesselAgreementId(shippingInitialValues.vesselAgreementId)
  }, [shippingInitialValues.vesselAgreementId])

  useEffect(() => {
    if (vesselAgreementId) {
      const req = new VesselVendorIdReqDto(vesselAgreementId, authContext.defaultPlant);
      vesselAgreementService.getvesselLaycanDropDown(req).then(res => {
        if (res.status) {
          setVesselAgreementLaycans(res.data);
          // formRef.setFieldValue('vesselLaycanId', null)
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      });
    }

  }, [vesselAgreementId])

  const getAllVesselAgreements = () => {
    const req = new PlantCodeReqDto(authContext.defaultPlant, ContractStatusEnum.ACTIVE);

    vesselAgreementService.getAllVesselAgreement(req).then(res => {
      if (res.status) {
        setVesselAgreements(res.data);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const getAllShippingDetailsData = () => {
    const req = new BNoRequest(businessNo, authContext.defaultPlant);
    businessNoService.getAllShippingDetailsData(req).then(res => {
      if (res.status) {
        const loadingPortData = [...res.data?.loadingPortData];

        loadingPortData.forEach((rec, index) => {
          if (rec?.loadBargeWindow && Array.isArray(rec.loadBargeWindow)) {
            const start = moment(rec.loadBargeWindow[0]);
            const end = moment(rec.loadBargeWindow[1]);

            if (start.isValid() && end.isValid()) {
              res.data.loadingPortData[index] = {
                ...rec, loadBargeWindow:
                  [start, end]
              };
            } else {
              console.error(`Invalid date range at index ${index}`);
              // Handle the case of invalid dates here (e.g., set to null or another default value)
            }
          }
        });
        setShippingInitialValues(res.data);
        setShipmentType(res?.data?.shipmentType)
        setHatchData(res?.data?.stowagePlanData)
        let total = res?.data?.stowageTotal ? res?.data?.stowageTotal : 0;
        // setStowageTotal(total);
        handleTotal(undefined, res.data)
        if (res?.data?.floatingCraneVendor) {
          getFCNames(res?.data?.floatingCraneVendor)
        }
        formRef.setFieldValue('stowageTotal', total);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const onFileChange = (data) => {
    setFileList([data.file]);
  }

  const onFileChanges = (data) => {
    setFileLists([data.file]);
  }

  const getAllAnchorages = () => {
    anchorageService.getAllAnchorages().then(res => {
      if (res.status) {
        setAnchorages(res.data)
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }
  const shippingCreate = () => {
    formRef.validateFields().then(async (values: any) => {
      const formFilesDataFile = formRef.getFieldValue('PKKAFile');
      const formFilesData = formFilesDataFile?.file;
      values.filesData = [];
      let f1 = [];
      if (formFilesData) {
        const base64 = await getBase64(formFilesData.originFileObj);
        f1.push(
          {
            ...formFilesData,
            base64Url: base64,
            fileDescription: 'Shipment File'
          }
        )
      }
      const invoiceFileData = formRef.getFieldValue('invoiceFile')
      const invoiceFile = invoiceFileData?.file;
      let f2 = []
      if (invoiceFile) {
        const base64 = await getBase64(invoiceFile.originFileObj);
        f2.push(
          {
            ...invoiceFile,
            base64Url: base64,
            fileDescription: 'Stowage Plan'
          }
        )
      };
      values.filesData.push(...f1, ...f2);
      const requestObj = { ...values, businessNo, stowageTotal: stowageTotal, plantCode: authContext.defaultPlant };
      upDateShippingDetails(requestObj);
      setViewForm(false);
    })
  }


  const upDateShippingDetails = (values) => {

    businessNoService.upDateShippingDetails(values).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllShippingDetailsData();
        onClear();
        setViewForm(false);
        setDummyRefresh(prev => prev + 1);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })

  }


  const onClear = () => {
    setShippingInitialValues({ loadingPortData: [''], mvDischargePortData: [''], stowagePlanData: [''] });
    formRef.resetFields();
    formRef.setFieldsValue(shippingInitialValues);

  }

  const getAllStevedorDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
    businessPartnerService.getAllStevedorDropDown(bpCategoryGetDto).then(res => {
      if (res.status) {
        if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.VENDOR)
          setStevadorVendor(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }



  const getAllVesselAgentsDropDown = () => {
    businessPartnerService.getAllVesselAgentsDropDown(1).then(res => {
      if (res.status) {
        setVesselAgents(res.data)
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getFCNames = (vendorId: string) => {
    const req = new FloatingCranesGetDto();
    req.fcVendorId = vendorId;
    fcService.getAllFCsDropDown(req).then(res => {
      if (res.status) {
        setFcNames(res.data)
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }


  const handleTotal = (_, values: any) => {
    console.log(values, ":::::::::::::::::")
    if (values?.stowagePlanData) {
      const transactions = [...values.stowagePlanData];
      let sum = 0;
      transactions.forEach(element => {
        sum += element?.hatchQty ? Number(element.hatchQty) : 0;
      });
      formRef.setFieldValue('stowageTotal', sum);
      setStowageTotal(sum);
    }

  }

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    setCommentsTitle(title);
  }

  const getFilteredData = (data: any[]) => {
    if (!searchedText) return data;
    return data?.filter((item: any) => 
        Object.values(item)
            .join(' ')
            .toLowerCase()
            .includes(searchedText.toLowerCase())
    );
};

  const anchorageColumns: ColumnsType<any> = [
    {
      // title: 'Loading Port Anchorage',
      title: 'Port Anchorage',
      dataIndex: 'anchorageName',
    },
    {
      title: 'Port Agent',
      dataIndex: 'bpName',
    },
    ...(shipmenttype === 'FAS ANCHORAGE' ? [
      {
        title: 'Barge Window',
        dataIndex: 'loadBargeWindow',
        width: 225,
        render: (value) => {
          if (value && value.length === 2) {
            return (
              <>
                {moment(value[0]).isValid() && moment(value[1]).isValid() ? `${moment(value[0]).format('DD\u2011MMM\u2011YYYY')} - ${moment(value[1]).format('DD\u2011MMM\u2011YYYY')}` : null}
              </>
            );
          }
        }
      },
      {
        // title: 'Load Quantity',
        title: 'Quantity(MT)',
        dataIndex: 'loadQuantity',
        align: 'right' as const, 
        render: (value) => {
            return (
              <>
                {value ? Number(value).toFixed(3) : ''}
              </>
            );
        }
      },
    ] : [])

  ]

  const loadingPortColumns: ColumnsType<any> = [
    {
      title: 'Discharge Port',
      dataIndex: 'anchorageName',
    },
    {
      title: 'Port Agent',
      dataIndex: 'bpName',
    },

  ]

  const hatchColumns: ColumnsType<any> = [
    {
      title: 'Hatch',
      dataIndex: 'hatchNames',
    },
    {
      title: 'Quantity (MT)',
      dataIndex: 'hatchQty'
    }

  ]
  const vesselMappingColumn: ColumnsType<any> = [
    {
      title: 'Agreement No',
      dataIndex: 'vesselAgreementNo',
      render: (value, record) => {
        const link = `/#/vesselAgreement-detailed-view?va_Id=${record.vesselAgreementId}`;
        return <>
          <a href={link} className="link-primary" >
            {value}
          </a></>
      }
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vesselVendor'
    },
    {
      title: 'Laycan',
      dataIndex: 'vesselLaycan'
    },
    {
      title: 'Price',
      dataIndex: 'vesselLaycanPrice'
    },
    {
      title: 'Status',
      dataIndex: 'vesselAgreementLinkingStatus',
      width: 130,
      align: 'left',
      render: (text, record) => <>
        <StatusTag status={record.vesselAgreementLinkingStatus} />
      </>,
    },

    ...(shippingInitialValues?.vesselAgreementLinkingStatus !== ContractStatusEnum.ACTIVE ? [
      {
        title: 'Action',
        dataIndex: 'vesselLaycan',
        render: (text, rowData) => (
          <span>
            {rowData.vesselAgreementLinkingStatus === ContractStatusEnum.DRAFT && <Button type="primary" onClick={() => approveVesselAgreement(ContractStatusEnum.PENDING_APPROVAL)}>Send For Approval</Button>}
            {rowData.vesselAgreementLinkingStatus === ContractStatusEnum.PENDING_APPROVAL && <Button type="primary" onClick={() => approveVesselAgreement(ContractStatusEnum.ACTIVE)}>Approve</Button>}
          </span>)
      }
    ] : [])



  ]

  const approveVesselAgreement = (status: ContractStatusEnum) => {
    const req = new BNVesselApprove(businessNo, authContext.defaultPlant, status, authContext.user.userName);

    businessNoService.approveBnVesselAgreement(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllShippingDetailsData();
        setDummyRefresh(prev => prev + 1);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });


  }

  const addButtonHandler = (addMethod, index) => {
    addMethod({ hatchNames: `hatch ${index + 1}` })
  }

  const deleteButtonHandler = (removeMethod, index) => {
    removeMethod(index);
    const fieldsLength = formRef.getFieldValue('stowagePlanData').length
    for (let i = 0; i < fieldsLength; i++) {
      formRef.setFieldValue(['stowagePlanData', i, 'hatchNames'], `hatch ${i + 1}`)
    }
  }

  const vesselAgreementDetails = () => {
    return [
      {
        vesselAgreementId: shippingInitialValues?.vesselAgreementId,
        vesselAgreementNo: shippingInitialValues?.vesselAgreementNo,
        vesselLaycanId: shippingInitialValues?.vesselLaycanId,
        vesselLaycan: shippingInitialValues?.vesselLaycan,
        vesselAgreementLinkingStatus: shippingInitialValues?.vesselAgreementLinkingStatus,
        vesselLaycanPrice: shippingInitialValues?.vesselLaycanPrice,
        vesselVendor: shippingInitialValues?.vesselVendor,
      }
    ]
  }

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.vesselAgreementId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const renderItems = (record: any, index, indent, expanded) => {
    return <>

    
      <Card title={'Approval Info'}>
        {shippingInitialValues.vesselAgreementWorkLog && shippingInitialValues.vesselAgreementWorkLog.map((rec, index) =>
          <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical" bordered>
            <Descriptions.Item label='Approved By'>{rec.createdUser}</Descriptions.Item>
            <Descriptions.Item label='Approved on'>{rec.createdAt ? moment(rec.createdAt).format('DD-MMM-YYYY h:mm A') : ''}</Descriptions.Item>
          </Descriptions>)}
      </Card>

    </>
  };


  return (
    <>
      {!viewForm ? <>

        <Row className='card-title-buttons mb-12' justify="space-between" >
            <Col>
                <Input.Search placeholder="Search" allowClear className='input-search' onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} />
            </Col>
            <Col>
                <EditFilled type="edit"
                    className='action-icons'
                    onClick={() => { setViewForm(true); }}
                />
            </Col>
        </Row>

      <Card title={<span className="card-title-md">Vessel Details</span>}
            extra={<Space size='middle'>
                {shippingInitialValues?.filesData?.some((rec) => rec?.fileDescription === "Shipment File") && (
                  <span className='link file' >
              <ViewFileHandling filesData={shippingInitialValues?.filesData?.filter((rec) => rec?.fileDescription === "Shipment File")} required={true} /></span>)}
              <Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Shipment Planning > Vessel Details', 'Vessel Details')} /></Tooltip>
              </Space>}
          className="card-br-8 mb-12" headStyle={{border: 'none'}}>
          <Descriptions
            className='descriptions'
            title=''
            column={{ xxl: 5, xl: 5, lg: 3, md: 3, sm: 2, xs: 1 }}
            layout='vertical'
            colon={false}
          >
            <Item label='Vessel Type'>{shippingInitialValues?.vesselType}</Item>
            <Item label="Stevedore Vendor">
              <div className='flex-col'>
                {shippingInitialValues?.stevedoreVendorName
                  ? shippingInitialValues.stevedoreVendorName.split(',').map((name, index) => (
                      <Row key={index} className='vendors'>
                        {name.trim()}
                      </Row>
                    ))
                  : ''}
              </div>
            </Item>
            <Item label="Floating Crane Vendor">
              <div className='flex-col'>
                {shippingInitialValues?.floatingCraneVendorName
                  ? shippingInitialValues.floatingCraneVendorName.split(',').map((name, index) => (
                      <Row key={index} className='vendors'>
                        {name.trim()}
                      </Row>
                    ))
                  : ''}
              </div>
            </Item>
            <Item label='Floating Crane Name'>{shippingInitialValues?.floatingCrane ? shippingInitialValues?.floatingCrane : <span className='NA'>N/A</span> }</Item>
            <Item label='Stevedore Additional Day'>{shippingInitialValues.stevedoreAdditionalDay ? shippingInitialValues.stevedoreAdditionalDay : <span className='NA'>N/A</span>}</Item>
          </Descriptions>
      </Card>

      <Row gutter={[12,12]} >
          <Col span={16} xs={24} md={24} lg={16}>
              <Row className="mb-12">
                  <Card title={<span className="card-title-md">Load Port Details</span>}
                        extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Shipment Plannig > Load Port Details', 'Load Port Details')} /></Tooltip>}
                          headStyle={{border: 'none'}}
                          className="contracts-list card-br-8 contracts-List full-width"
                  >
                      <Table className='overflowX' columns={anchorageColumns} dataSource={getFilteredData(shippingInitialValues?.loadingPortData)} pagination={false}></Table>
                  </Card>
              </Row>
              <Row gutter={[12,12]}>
                <Col span={14} xs={24} md={24} lg={14}>
                  <Card title={<span className="card-title-md">Discharge Port Details</span>}
                        extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Shipment Plannig > Discharge Port Details', 'Discharge Port Details')} /></Tooltip>}
                          headStyle={{border: 'none'}}
                          className="contracts-list card-br-8 contracts-List full-width"
                  >
                      <Table className='overflowX' columns={loadingPortColumns} dataSource={getFilteredData(shippingInitialValues?.mvDischargePortData)} pagination={false} ></Table>
                  </Card>
                  </Col>
                  <Col span={10} xs={24} md={24} lg={10}>
                  <Card title={<span className="card-title-md">Vessel Agreement</span>}
                        extra={<Tooltip placement="top" title='Comment'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Shipment Plannig > Vessel Agreement', 'Vessel Agreement')} /></Tooltip>}
                          headStyle={{border: 'none'}}
                          className='card-br-8'
                  >
                      <Card className='agreement-bg card-br-8' bordered={false}>
                          <Row justify='space-between'>
                            <a 
                              href={`/#/vesselAgreement-detailed-view?va_Id=${shippingInitialValues?.vesselAgreementId}`} 
                              className="link"
                            >
                              {shippingInitialValues?.vesselAgreementNo}
                            </a>
                            <StatusTag status={shippingInitialValues?.vesselAgreementLinkingStatus} />
                          </Row>
                          <Descriptions colon={false} layout='vertical' column={{ xxl: 2, xl: 2, lg: 1, md: 2, sm: 2, xs: 1 }}>
                            <Descriptions.Item span={2}>
                                {shippingInitialValues?.vesselVendor}
                            </Descriptions.Item>
                            <Descriptions.Item label="Laycan">
                            {shippingInitialValues?.vesselLaycan ? shippingInitialValues.vesselLaycan.replace(/\s*\(.*?\)/, '').split(' - ').map(d => moment(d.trim(), 'DD MMM YY').format('DD\u2011MMM\u2011YYYY')).join(' to ') : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="Price">
                                {shippingInitialValues?.vesselLaycanPrice}&nbsp;<span className='currency-style'>{shippingInitialValues?.vesselLaycanCurrency}</span>
                            </Descriptions.Item>
                          </Descriptions>
                          {shippingInitialValues?.vesselAgreementNo &&
                          <>
                          <Divider className='divider-margin' />
                            {shippingInitialValues?.vesselAgreementLinkingStatus !== ContractStatusEnum.ACTIVE ? (
                              <>
                                <span className='agreement-btn'>
                                {shippingInitialValues.vesselAgreementLinkingStatus === ContractStatusEnum.DRAFT && (
                                  <Button size='small' className='send-approval-button' onClick={() => approveVesselAgreement(ContractStatusEnum.PENDING_APPROVAL)}>
                                    <FontAwesomeIcon icon={faPaperPlane} />Send For Approval
                                  </Button>
                                )}
                                {shippingInitialValues.vesselAgreementLinkingStatus === ContractStatusEnum.PENDING_APPROVAL && (
                                  <>
                                  <Button size="small" className='approve' onClick={() => approveVesselAgreement(ContractStatusEnum.ACTIVE)}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>&nbsp;&nbsp;
                                  <Button size="small" className='reject' onClick={() => approveVesselAgreement(ContractStatusEnum.DRAFT)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
                                  </>
                                )}
                                </span>
                              </>
                            ) : <span>
                                  <Popover  title={<span className='popover-title'>Approval Details</span>}
                                              placement='bottom'
                                              content={
                                                <>
                                                  {shippingInitialValues.vesselAgreementWorkLog && shippingInitialValues.vesselAgreementWorkLog.map((rec, index) => (
                                                    <div key={index} >
                                                        <b>{rec.createdUser}</b> Approved on <b>{rec.createdAt ? moment(rec.createdAt).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</b>
                                                    </div>
                                                  ))}
                                                </>
                                              }
                                  >
                                    <Button type='link' className='link'>Approval Details</Button>
                                  </Popover>
                                </span>}
                          </>
                            }
                      </Card>
                  </Card>
                  </Col>
              </Row>
          </Col>
          <Col span={8} xs={24} md={24} lg={8} >
              <Card title={<span className="card-title-md">Stowage Plan</span>}
                    extra={<Space size='middle'>
                        {shippingInitialValues?.filesData?.some((rec) => rec?.fileDescription === "Stowage Plan") && (
                          <span className='link file'>
                      <ViewFileHandling filesData={shippingInitialValues?.filesData?.filter((rec) => rec?.fileDescription === "Stowage Plan")} required={true} /></span>)}
                      {<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Shipment Plannig > Stowage Plan', 'Stowage Plan')} /></Tooltip>}</Space>}
                      headStyle={{border: 'none'}}
                      className="contracts-list card-br-8 contracts-List full-width"
              >
                  <Table
                    columns={hatchColumns}
                    dataSource={getFilteredData(shippingInitialValues?.stowagePlanData)}
                    pagination={false}
                    style={{overflowX: 'auto'}}
                    summary={() => {
                      const hatchQtyTotal = shippingInitialValues?.stowagePlanData?.reduce((a, c) => a + Number(c.hatchQty), 0).toFixed(3);
                      return (
                        <>
                          <Table.Summary.Row className='tableFooter' style={{backgroundColor: '#F2F7FD'}}>
                            <Table.Summary.Cell index={1} colSpan={1} align='right'><b>Total Hatch Quantity</b> </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={1}> <b>{hatchQtyTotal} MT</b> </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      )

                    }}
                  >

                  </Table>
              </Card>
          </Col>
      </Row> </>: <></>} 





      {/* <br /> */}
    <>
      {/* {!viewForm ? <>
        <Descriptions>
          <Item label='Vessel Type'>{shippingInitialValues?.vesselType}</Item>
          <Item label='Stevedore Vendor'>{shippingInitialValues?.stevedoreVendorName}</Item>
          <Item label='Floating Crane Vendor'>{shippingInitialValues?.floatingCraneVendorName}</Item>
          <Item label='Floating Crane Name'>{shippingInitialValues?.floatingCrane}</Item>
          <Item label='Stevedore Additional Day'>{shippingInitialValues.stevedoreAdditionalDay}</Item>
          <Item>{
            <ViewFileHandling filesData={shippingInitialValues?.filesData?.filter((rec) => rec?.fileDescription === "Shipment File")} required={true} />
          }</Item>
        </Descriptions>


        <Table columns={anchorageColumns} dataSource={shippingInitialValues?.loadingPortData} pagination={false}></Table>
        <Table columns={loadingPortColumns} dataSource={shippingInitialValues?.mvDischargePortData} pagination={false}></Table>
        <br />
        {shippingInitialValues?.vesselAgreementId &&
          <Table columns={vesselMappingColumn} dataSource={vesselAgreementDetails()} pagination={false}
            expandable={{
              expandedRowRender: renderItems,
              expandedRowKeys: expandedIndex,
              onExpand: setIndex,
              fixed: 'right'
            }}
            rowKey={record => record.vesselAgreementId}
            expandIcon={({ expanded, onExpand, record }) =>
              expanded ? (
                <UpCircleFilled
                  onClick={(e) => onExpand(record, e)}
                >
                  Collapse
                </UpCircleFilled>
              ) : (
                <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
              )
            }
          />
        }
        <br />
        <Table
          columns={hatchColumns}
          dataSource={shippingInitialValues?.stowagePlanData}
          pagination={false}
          summary={() => {
            const hatchQtyTotal = shippingInitialValues?.stowagePlanData?.reduce((a, c) => a + Number(c.hatchQty), 0);
            return (
              <>
                <Table.Summary.Row className='tableFooter'>
                  <Table.Summary.Cell index={1} colSpan={1}><b>Hatch Quantity Total</b> </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={1}> <b>{hatchQtyTotal}</b> </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )

          }}
        >

        </Table>
        <ViewFileHandling filesData={shippingInitialValues?.filesData?.filter((rec) => rec?.fileDescription === "Stowage Plan")} required={true} />
      </> : <></>} */}


      {/* <Button style={{ float: 'right', display: `${viewForm ? 'none' : ''}` }} onClick={() => { setViewForm(true); }} type='primary'>EDIT</Button> */}
      {viewForm ?
        <Form
          form={formRef}
          layout={layoutType}
          initialValues={shippingInitialValues}
          autoComplete="off"
          onValuesChange={handleTotal}
        >
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.shippingplaningForm.vesselType', defaultMessage: "Vessel Type" })}
                name="vesselType"
              >
                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  showSearch allowClear

                  placeholder={"Please Select Vessel Type"}
                >
                  <Option value={''}>Please Select</Option>
                  {Object.keys(VesselTypesEnum).filter(rec => rec !== VesselTypesEnum.NA).map(vessel => {
                    return <Option value={VesselTypesEnum[vessel]}>{VesselTypesEnum[vessel]}</Option>
                  })}
                </Select>

              </Form.Item>

            </Col>

            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.shippingplaningForm.stevedoreVendor', defaultMessage: "Stevedore Vendor" })}
                name="stevedoreVendor"

              >

                <Select
                  mode='multiple'
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  showSearch allowClear placeholder={"Please Select Stevedore Vendor"} style={{ width: "160px" }}>

                  <Option value="">Please Select</Option>
                  {stevadorVendor.map(item => { return <Option value={item.bpId}>{item.bpName}</Option> })}
                </Select>
              </Form.Item>

            </Col>


            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.shippingplaningForm.floatingcraneVendor', defaultMessage: "Floating Crane Vendor" })}
                name="floatingCraneVendor"

              >
                <Select
                  mode='multiple'
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  showSearch allowClear placeholder={"Floating Crane Vendor"} onChange={(value) => getFCNames(value)}>

                  <Option value={''}>Please Select</Option>
                  {stevadorVendor.map(item => { return <Option value={item.bpId}>{item.bpName}</Option> })}

                </Select>

              </Form.Item>

            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item
                label='Floating Crane Name'
                name="fcName"

              >
                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  showSearch allowClear placeholder={"Floating Crane Name"} >
                  <Option value={''}>Please Select</Option>
                  {fcNames.map(item => { return <Option value={item.id}>{item.fcName}</Option> })}
                </Select>

              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
              <Form.Item
                label={'file to upload'}
                name={'PKKAFile'}

              >

                <Upload
                  multiple={false}
                  fileList={fileList}
                  customRequest={onFileChange}
                  onRemove={() => setFileList([])}
                  showUploadList={true}
                  listType='text'
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Choose File</Button>
                </Upload>
              </Form.Item>
              <ViewFileHandling filesData={shippingInitialValues?.filesData?.filter((rec) => rec?.fileDescription === "Shipment File")} required={false} />

            </Col>


            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
              <Form.Item label='Stevedore Additional Day'
                name="stevedoreAdditionalDay" >
                <InputNumber placeholder='Stevedore AdditionalDay' style={{ width: "100%" }} />
              </Form.Item>
            </Col>

          </Row>

          <br />
          <Card title='Loading Port' headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>

            <Form.List name={'loadingPortData'} >

              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }, index) => (
                    <>
                      <Row>
                        <Form.Item name={[name, "bnAnchorageId"]} style={{ display: 'none' }}>
                          <Input hidden />
                        </Form.Item>
                        <Col
                          xs={{ span: 22 }}
                          sm={{ span: 22 }}
                          md={{ span: 22 }}
                          lg={{ span: 22 }}
                          xl={{ span: 22 }}>
                          <Row>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                              <Form.Item
                                label={"Loading Port Anchorage"}
                                name={[name, "anchorage"]}

                              >
                                {/* <Input placeholder={fm({ id: 'mdm.components.shippingplaningForm.loadingPortAgentReq', defaultMessage: "LoadingPort Agent" })} /> */}
                                <Select
                                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                  allowClear showSearch placeholder={fm({ id: 'common.select', defaultMessage: "Please Select" })} disabled={true}>
                                  <Option value={''}>Please Select</Option>
                                  {
                                    anchorages.map(rec => {
                                      return <Option value={rec.anchorageId}>{rec.anchorageName}</Option>
                                    })
                                  }
                                </Select>
                              </Form.Item>

                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                              <Form.Item
                                label={'Port Agent'}
                                name={[name, "portAgent"]}
                              >
                                <Select
                                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                  showSearch allowClear placeholder={"Port Agent"}>
                                  <Option value={''}>Please Select</Option>
                                  {vesselAgents.map(item => { return <Option value={item.bpId}>{item.bpName}</Option> })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} style={{ display: shipmenttype === 'FAS ANCHORAGE' ? 'unset' : 'none' }}>
                              <Form.Item
                                label={'Loading Barge Window'} name={[name, "loadBargeWindow"]}>
                                <RangePicker />
                              </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} style={{ display: shipmenttype === 'FAS ANCHORAGE' ? 'unset' : 'none' }}>
                              <Form.Item
                                label={"Load Quatity"}
                                name={[name, "loadQuantity"]}
                              >
                                <Input placeholder='Load Quantity' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        {index !== 0 && <Col xs={{ span: 2 }}
                          sm={{ span: 2 }}
                          md={{ span: 2 }}
                          lg={{ span: 2 }}
                          xl={{ span: 2 }}>
                          <span style={{
                            position: "absolute",
                            marginTop: "33px",
                            marginLeft: "25px",
                          }}>
                            <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                              <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                                remove(name);
                              }} />
                            </Tooltip>
                          </span>
                        </Col>
                        }
                      </Row>
                    </>
                  ))}
                  <Row justify='end'>
                    <Col>
                      <span >
                        <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                          <PlusCircleOutlined
                            type="dashed"
                            onClick={() => add()}
                            style={{ fontSize: 20, display: 'none' }}
                          >
                            Add field
                          </PlusCircleOutlined>
                        </Tooltip>
                      </span>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>

          </Card>
          <br />
          <Card title='Discharge Port' headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
            <Form.List name={'mvDischargePortData'} >

              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }, index) => (
                    <>
                      <Row>
                        <Col
                          xs={{ span: 22 }}
                          sm={{ span: 22 }}
                          md={{ span: 22 }}
                          lg={{ span: 22 }}
                          xl={{ span: 22 }}>
                          <Row>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                              <Form.Item
                                label={'Discharge Port'}
                                name={[name, "anchorage"]}

                              >
                                <Select
                                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                  allowClear showSearch placeholder={fm({ id: 'common.select', defaultMessage: "Please Select" })}>
                                  <Option value={''}>Please Select</Option>
                                  {
                                    anchorages.map(rec => {
                                      return <Option value={rec.anchorageId}>{rec.anchorageName}</Option>
                                    })
                                  }
                                </Select>
                              </Form.Item>

                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                              <Form.Item
                                label={'Port Agent'}
                                name={[name, "portAgent"]}
                              >
                                <Select
                                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                  showSearch allowClear placeholder={"Port Agent"}>
                                  <Option value={''}>Please Select</Option>
                                  {vesselAgents.map(item => { return <Option value={item.bpId}>{item.bpName}</Option> })}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        {index !== 0 && <Col xs={{ span: 2 }}
                          sm={{ span: 2 }}
                          md={{ span: 2 }}
                          lg={{ span: 2 }}
                          xl={{ span: 2 }}>
                          <span style={{
                            position: "absolute",
                            marginTop: "33px",
                            marginLeft: "25px",
                          }}>
                            <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                              <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                                remove(name);
                              }} />
                            </Tooltip>
                          </span>
                        </Col>
                        }
                      </Row>
                    </>
                  ))}
                  <Row justify='end'>
                    <Col>
                      <span >
                        <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                          <PlusCircleOutlined
                            type="dashed"
                            onClick={() => add()}
                            style={{ fontSize: 20 }}
                          >
                            Add field
                          </PlusCircleOutlined>
                        </Tooltip>
                      </span>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Card>
          <br />
          <Card title='Vessel Agreement' headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>

            <Row>
              <Col
                xs={{ span: 22 }}
                sm={{ span: 22 }}
                md={{ span: 22 }}
                lg={{ span: 22 }}
                xl={{ span: 22 }}>
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                    <Form.Item
                      label={'Vessel Agreement'}
                      name={"vesselAgreementId"}


                    >
                      <Select
                        disabled={shippingInitialValues?.vesselAgreementLinkingStatus == ContractStatusEnum.ACTIVE}
                        onChange={(value) => setVesselAgreementId(value)}
                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                        allowClear showSearch placeholder={fm({ id: 'common.select', defaultMessage: "Please Select" })}>
                        <Option value={''}>Please Select</Option>
                        {
                          vesselAgreements.map(rec => {
                            return <Option value={rec.agreementId}>{`${rec.contractNo} - (${rec.vendor})`}</Option>
                          })
                        }
                      </Select>
                    </Form.Item>

                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                    <Form.Item
                      label={'Laycan'}
                      name={"vesselLaycanId"}
                    >
                      <Select
                        disabled={shippingInitialValues?.vesselAgreementLinkingStatus == ContractStatusEnum.ACTIVE}

                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                        showSearch allowClear placeholder={"Laycan"}>
                        <Option value={''}>Please Select</Option>
                        {vesselAgreementLaycans.map(item => { return <Option value={item.laycanId}>{item.laycan}</Option> })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <br></br>
          <Form.List name={'stowagePlanData'} >
            {(fields, { add, remove }) => (
              <Card title={
                <Row justify='space-between'>
                  <Col><>Stowage Plan -  {stowageTotal} </></Col>
                  <Col>
                    <span >
                      <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                        <PlusCircleOutlined
                          type="dashed"
                          onClick={() => addButtonHandler(add, fields?.length ? fields.length : 0)}
                          style={{ fontSize: 20 }}
                        >
                          Add field
                        </PlusCircleOutlined>
                      </Tooltip>
                    </span>
                  </Col>
                </Row>}>
                <Row
                // justify='space-around'
                >
                  {fields.map(({ key, name }, index) => (
                    <>

                      <Col span={3} >
                        <Form.Item name={[name, 'hatchNames']}>
                          <Input disabled></Input>
                        </Form.Item>
                      </Col>
                      <Col >
                        <Form.Item style={{ display: 'none' }} name={[name, 'mothersVesselQtyId']}>
                          <Input ></Input>
                        </Form.Item>
                      </Col>
                      <Col span={3} offset={1} >
                        <Form.Item
                          // label={`Hatch ${index + 1}`}
                          name={[name, "hatchQty"]}
                        >
                          <InputNumber />
                        </Form.Item>

                      </Col>

                      <Col span={17}>

                        {index !== 0 &&
                          <span style={{
                            marginTop: "4px", paddingLeft: 6
                          }}>
                            <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                              <DeleteFilled style={{ color: '#f70404', fontSize: '21px' }} onClick={() => {
                                deleteButtonHandler(remove, name);
                              }} />
                            </Tooltip>
                          </span>
                        }
                      </Col>
                    </>
                  ))}
                </Row>
              </Card>
            )
            }
          </Form.List >
          <Card>
            <Form.Item name='invoiceFile' label="Upload The Stowage Plan"  >

              <Upload
                multiple={false}
                fileList={fileLists}
                customRequest={onFileChanges}
                onRemove={() => setFileLists([])}
                showUploadList={true}
                listType='text'
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Choose File</Button>
              </Upload>
            </Form.Item>
            <ViewFileHandling filesData={shippingInitialValues?.filesData?.filter((rec) => rec?.fileDescription === "Stowage Plan")} required={false} />

          </Card>
          {/* </Card> */}
          < Affix offsetBottom={0} >
            <Card bodyStyle={{ padding: '6px 6px ' }}>
              <Row >
                <Col ><Button onClick={() => { onClear(); }}>Clear</Button></Col>
                <Col offset={18} style={{ paddingLeft: "" }}><Button className='new-button' onClick={shippingCreate}>SUBMIT</Button></Col>
              </Row>
            </Card>
          </Affix >
        </Form > : <></>}

      {props?.children}
    </ >
    </>
  )
}

export default ShipmentPlaning