import { Affix, Button, Card, Col, Descriptions, Divider, Drawer, Flex, Form, Layout, Row, Space, Tabs, Tag, Tooltip } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BusinessNumberService, ColaIndexTypeEnum, ContractIdRequest, ContractModesEnum, ContractStatusEnum, ContractTermEnum, ContractTypesEnum, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, PaymentModesEnum, PurchaseContractDto, PurchaseContractService, SpecTypesEnum, TaxesAndItemsService, } from '@exportx/shared-models-and-services';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import { useIntl } from 'react-intl';
import QualityDetailView from './quality-detail-view';
import { getFilesData } from '@exportx/ui-utils';
import SurveyorDetailsView from './surveyor-details-view';
import ContractDetailsView from './contract-details-view';
import PaymentTermsView from './payment-terms-view';
import ShippingTermsView from './shipping-terms-view';
import CommercialDocumentsView from './commercial-documents';
import AllPurchaseContractDetailedView from './all-purchase-contracts-detailed-view';
import queryString from 'query-string';
import { ArrowLeftOutlined, CheckCircleFilled, CloseCircleFilled, DownloadOutlined, EditFilled, EditOutlined, PlusCircleFilled } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faCircleXmark, faDownload, faXmark } from '@fortawesome/free-solid-svg-icons';
import AddQuality from '../creation-forms/add-quality';
import { SpecInitialData } from '../creation-forms/specs/initial-spec-data';
import { LaycanView } from './laycan-view';
import PaymentTerm from '../creation-forms/payment-terms';
import { PricingFormulaView } from './pricing-formula-view';
import { PricingFormula } from '../creation-forms/pricing-formula';

export const PurchaseContractDetailView = () => {
  const { authContext } = useAuthState();
  const { formatMessage: fm } = useIntl();
  const printRef = React.useRef();
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState<PurchaseContractDto>(null);
  const [snapData, setSnapData] = useState<PurchaseContractDto[]>([]);
  const [contractDetails, setContractDetails] = useState(null);
  const [shippingTerms, setShippingTerms] = useState(null);
  const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
  const [allocateData, setAllocateData] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [filesFormRef] = Form.useForm();
  const [refresh, setRefresh] = useState<number>(0);
  const service = new PurchaseContractService();
  const taxesAndItemsService = new TaxesAndItemsService();
  const location = useLocation();
  const paramPcId = queryString.parse(location.search)?.pc_id;
  const paramPlantCode = queryString.parse(location.search)?.plantCode;
  const businessService = new BusinessNumberService();
  const [showAddLaycan, setShowAddLaycan] = useState(false);
  const [showViewLaycan, setShowViewLaycan] = useState(false);
  const [showEditLaycan, setShowEditLaycan] = useState(false);
  const [laycanData, setLaycanData] = useState<any>({});
  const [qualityDetailsFormRef] = Form.useForm();
  const [purchaseData, setPurchaseData] = useState<any>({
    contractDetails: {},
    qualityDetails: { qualityData: [{ purchaseTypeData: [''] }] },
    surveyorDetails: [],
    commercialDocuments: { commercialDocuments: ['', '', '', '', ''] },
    shippingTerms: { shippingTerms: ['', '', '', ''] },
    paymentTerms: { paymentMode: PaymentModesEnum.TELEGRAPHIC_TRANSFER, paymentTerms: ['', '', '', ''] },
    filesData: [],
    remarks: '',
    addendumData: []
  });
  const [purchaseTermData, setPurchaseTermData] = useState({});
  const [activeQualityKeyTerm, setActiveQualityKeyTerm] = useState<number>(null);
  const [purchaseTypeData, setPurchaseTypeData] = useState<any>({ '0$&0': { purchaseType: undefined, isIndexed: false, data: { coalIndexType: ColaIndexTypeEnum.WEEKLY } } });
  const [activeIndexForm, setActiveIndexForm] = useState(undefined);
  const [activeIndexGrade, setActiveIndexGrade] = useState(undefined);
  const [pricingFormulaEdit, setPricingFormulaEdit] = useState(false);
  const [pricingFormulaFormRef] = Form.useForm();

  let { contractId }: any = paramPcId != null ? { contractId: paramPcId } : { contractId: null };
  let { plantCode }: any = paramPlantCode != null ? { plantCode: paramPlantCode } : { plantCode: authContext.defaultPlant };


  useEffect(() => {
    if (!isPermissionExist([111])) return navigate('/')
  }, [])
  useEffect(() => {
    if (contractId) {
      getSalesPreviewData(contractId, plantCode);
    }
  }, [contractId, refresh]);

  const getAssociatedBusinessNoByPcId = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode: authContext.defaultPlant, contractType: previewData?.contractType };
    businessService.getAllocationByPcId(req).then(res => {
      if (res.status) {
        setAllocateData(res.data)
      }
    }).catch(err => {
      console.log(err.message);
    })
  }

  const getSalesPreviewData = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.getPurchasePreviewData(req).then(res => {
      if (res.status) {
        setPreviewData(res.data);
        setSnapData(res?.snapData ? res.snapData : []);
        setContractDetails(res.data.contractDetails);
        if (res.data.contractDetails.contractDate) {
          getTaxesByItemsId(res.data.contractDetails.contractDate)
        }
        setOpenModal(false);
        setShippingTerms(res.data.shippingTerms);
        getAssociatedBusinessNoByPcId();
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        setOpenModal(false);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setOpenModal(false);
    })
  }

  const getTaxesByItemsId = (contractDate) => {
    const req = new ItemsIdRequestDto(ItemsCodesEnum.COAL, contractDate)
    taxesAndItemsService.getVatByItemsId(req).then(res => {
      if (res.status) {
        setVat(res.data);
      } else {
        setVat([]);
      }
    }).catch(err => {
      console.log(err);
      setVat([]);
    });
  }

  const handleDownloadPdf = async () => {
    console.log('print the pdf');
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('print.pdf');
  };



  const sendForApproval = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.sendForApproval(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Purchase  Order Sent successfully' }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // const addendumApproval = () => {
  //   const req = { ...new ContractIdRequest(contractId), plantCode };
  //   service.approvePurchaseAddendum(req).then(res => {
  //     if (res.status) {
  //       getSalesPreviewData(contractId, plantCode);
  //       AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Purchase Order Addendum Sent successfully' }));
  //     } else {
  //       AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const PurchaseForReject = () => {
    const req = new ContractIdRequest(contractId, authContext.defaultPlant)
    service.purchaseForReject(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.error);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const purchaseForApproval = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode, userName: authContext.user.userName };
    if (previewData?.contractType === ContractTypesEnum.ADDENDUM) {
      service.approvePurchaseAddendum(req).then(res => {
        if (res.status) {
          getSalesPreviewData(contractId, plantCode);
          AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Purchase Order Addendum Sent successfully' }));
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    } else {
      service.purchaseForApproval(req).then(res => {
        if (res.status) {
          getSalesPreviewData(contractId, plantCode);
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.error);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

  }



  const addendumClickHandler = () => {
    navigate('/po-addendum', { state: previewData });
  }


  const disableAddendum = previewData?.qualityDetails?.qualityData?.every(e => e.approvalStatus === ContractStatusEnum.ACTIVE || e.approvalStatus === ContractStatusEnum.CLOSED);


  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button className='new-button' onClick={() => sendForApproval()}>Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <> {isPermissionExist([119]) && <Button size="small" className='approve' onClick={() => purchaseForApproval()}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>}

        {isPermissionExist([120]) && <Button size="small" className='reject' onClick={() => PurchaseForReject()}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>}</>;
    }


    // if (status === ContractStatusEnum.PENDING_APPROVAL) {
    //   return <><Button hidden={previewData?.contractType === ContractTypesEnum.ADDENDUM ? false : true} onClick={() => addendumApproval()}>Approve</Button></>;
    // }

    if (status === ContractStatusEnum.ACTIVE && disableAddendum && isPermissionExist([116])) {
      return <><Button className='new-button' hidden={previewData?.contractType === ContractTypesEnum.ADDENDUM ? true : false} onClick={() => addendumClickHandler()} >Create Addendum</Button></>
    }
  }

  const setFormData = () => {
    navigate('/po-update', { state: previewData });
  }

  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      getFilesData(fileValues.filesData).then(filesData => {
        setPreviewData((prevState) => { return { ...prevState, filesData: [...prevState.filesData, ...filesData] } });
        service.addMoreFilesToPurchaseOrder({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          contractId: contractId
        }).then(res => {
          if (res.status) {
            getSalesPreviewData(contractId, plantCode);
            filesFormRef.resetFields();
            AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Sale Order Sent successfully' }));
          } else {
            AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      });
    });
  }

  const purchaseTermHandler = (qualityKey: number, selectedPurchasingTerm: ContractTermEnum, purchaseTermDataClone?: any, specSpecification?: any, qualityAdjustments?: any, noPriceAdjustment?: any) => {
    setPurchaseTermData(prevState => {
      const newState = { ...prevState };
      newState[qualityKey] = {
        purchaseTerm: selectedPurchasingTerm,
        purchaseTermData: purchaseTermDataClone ? purchaseTermDataClone : newState[qualityKey]?.['purchaseTermData'] ? newState[qualityKey]['purchaseTermData'] : { astmData: SpecInitialData, isoData: SpecInitialData },
        specSpecification: specSpecification ? specSpecification : newState[qualityKey]?.['specSpecification'] ? newState[qualityKey]['specSpecification'] : SpecTypesEnum.ASTM,
        qualityAdjustments: qualityAdjustments ? qualityAdjustments : newState[qualityKey]?.['qualityAdjustments'] ? newState[qualityKey]['qualityAdjustments'] : null,
        noPriceAdjustment: selectedPurchasingTerm === ContractTermEnum.SPEC ? false : noPriceAdjustment ? noPriceAdjustment : newState[qualityKey]?.['noPriceAdjustment'] ? newState[qualityKey]['noPriceAdjustment'] : false,
        specStandardToBeShow: selectedPurchasingTerm === ContractTermEnum.SPEC ? specSpecification ? specSpecification : newState[qualityKey]?.['specSpecification'] ? newState[qualityKey]['specSpecification'] : SpecTypesEnum.ASTM : getSpecStandardToBeShow(specSpecification ? specSpecification : newState[qualityKey]?.['specSpecification'] ? newState[qualityKey]['specSpecification'] : SpecTypesEnum.ASTM, noPriceAdjustment ? noPriceAdjustment : newState[qualityKey]?.['noPriceAdjustment'] ? newState[qualityKey]['noPriceAdjustment'] : false)
      };
      return newState;
    });
    if (!specSpecification)
      setActiveQualityKeyTerm(qualityKey);
  }

  const getSpecStandardToBeShow = (qualityAdjustments: any, noPriceAdjustment: any) => {
    return `${qualityAdjustments} ${noPriceAdjustment ? ' - No Price Adjustment' : ''}`;
  }

  const purchaseTermFormSubmitHandler = (formValues: any) => {
    setPurchaseTermData(prevState => {
      const newState = { ...prevState };
      const qualityKey = activeQualityKeyTerm;
      newState[qualityKey] = {
        purchaseTerm: prevState[qualityKey]['purchaseTerm'],
        purchaseTermData: { ...prevState[qualityKey]['purchaseTermData'], astmData: { ...formValues['astmData'] }, isoData: { ...formValues['isoData'] } },
        specSpecification: formValues['specSpecification'],
        qualityAdjustments: formValues['qualityAdjustments'],
        noPriceAdjustment: formValues['noPriceAdjustment'],
        specStandardToBeShow: getSpecStandardToBeShow(formValues['specSpecification'], formValues['noPriceAdjustment'])
      };
      return newState;
    });
    qualityDetailsFormRef.setFields([
      {
        name: ['qualityData', activeQualityKeyTerm, 'specStandard'],
        value: formValues['specSpecification'],
      },
      {
        name: ['qualityData', activeQualityKeyTerm, 'qualityAdjustments'],
        value: formValues['qualityAdjustments'],
      },
      {
        name: ['qualityData', activeQualityKeyTerm, 'noPriceAdjustment'],
        value: formValues['noPriceAdjustment'],
      },
      {
        name: ['qualityData', activeQualityKeyTerm, 'specStandardToBeShow'],
        value: getSpecStandardToBeShow(formValues['specSpecification'], formValues['noPriceAdjustment']),
      }
    ]);
    setActiveQualityKeyTerm(null);
  }

  const handleIsIndexedButton = (isIndexChecked: boolean, purchaseTypeKey: string) => {
    const presentFormData = qualityDetailsFormRef.getFieldValue(['qualityData', purchaseTypeKey.split('$&')[0], 'purchaseTypeData']);
    const activeIndexGrade = qualityDetailsFormRef.getFieldValue(['qualityData', purchaseTypeKey.split('$&')[0], 'quality']);
    const activeIndexLayCan = qualityDetailsFormRef.getFieldValue(['qualityData', purchaseTypeKey.split('$&')[0], 'layCan']);
    const activeIndexQualitySpecifications = qualityDetailsFormRef.getFieldValue(['qualityData', purchaseTypeKey.split('$&')[0], 'qualitySpecifications']);
    if (activeIndexGrade && activeIndexLayCan && activeIndexQualitySpecifications) {
      presentFormData[purchaseTypeKey.split('$&')[1]].isIndexLinked = isIndexChecked;
      qualityDetailsFormRef.setFields([
        {
          name: ['qualityData', purchaseTypeKey.split('$&')[0], 'purchaseTypeData'],
          value: presentFormData,
        },
      ]);
      setPurchaseTypeData(prevState => {
        const newState = { ...prevState };
        newState[purchaseTypeKey] = {
          ...prevState[purchaseTypeKey],
          purchaseType: prevState[purchaseTypeKey]?.purchaseType,
          isIndexed: isIndexChecked,
        };
        return newState;
      });
      setActiveIndexForm(isIndexChecked ? purchaseTypeKey : undefined);
      setActiveIndexGrade(activeIndexGrade);
    } else {
      const msg = (!(activeIndexGrade && activeIndexLayCan && activeIndexQualitySpecifications)) ? 'Please select Laycan and enter Quality' : activeIndexGrade ? 'Please Select Laycan' : activeIndexQualitySpecifications ? 'Please select Quality type' : 'Please enter Quality';
      AlertMessages.getErrorMessage(msg);
      presentFormData[purchaseTypeKey.split('$&')[1]].isIndexLinked = false;
      qualityDetailsFormRef.setFields([
        {
          name: ['qualityData', purchaseTypeKey.split('$&')[0], 'purchaseTypeData'],
          value: presentFormData,
        },
      ]);
      setPurchaseTypeData(prevState => {
        const newState = { ...prevState };
        newState[purchaseTypeKey] = {
          ...prevState[purchaseTypeKey],
          purchaseType: prevState[purchaseTypeKey]?.purchaseType,
          isIndexed: false,
        };
        return newState;
      });
    }
  }

  const handlePurchaseType = (shippingTerm: string, purchaseTypeKey: string) => {
    setPurchaseTypeData(prevState => {
      const newState = { ...prevState };
      newState[purchaseTypeKey] = {
        ...prevState[purchaseTypeKey],
        purchaseType: shippingTerm,
      };
      return newState;
    });
  }

  const indexFormCancelHandler = () => {
    const presentFormData = qualityDetailsFormRef.getFieldValue(['qualityData', activeIndexForm.split('$&')[0], 'purchaseTypeData']);
    presentFormData[activeIndexForm.split('$&')[1]].isIndexLinked = false;
    qualityDetailsFormRef.setFields([
      {
        name: ['qualityData', activeIndexForm.split('$&')[0], 'purchaseTypeData'],
        value: presentFormData,
      },
    ]);
    setPurchaseTypeData(prevState => {
      const newState = { ...prevState };
      newState[activeIndexForm] = {
        purchaseType: prevState[activeIndexForm]?.purchaseType,
        isIndexed: false,
        data: { coalIndexType: ColaIndexTypeEnum.WEEKLY }
      };
      return newState;
    });
    setActiveIndexForm(undefined);
    setActiveIndexGrade(undefined);
  }

  const indexFormSubmitButtonHandler = (indexedFormValues: any) => {
    const presentFormData = qualityDetailsFormRef.getFieldValue(['qualityData', activeIndexForm.split('$&')[0], 'purchaseTypeData']);
    presentFormData[activeIndexForm.split('$&')[1]].coalPricePMT = indexedFormValues?.finalCoalBasePricePMT ?? 0;
    qualityDetailsFormRef.setFields([
      {
        name: ['qualityData', activeIndexForm.split('$&')[0], 'purchaseTypeData'],
        value: presentFormData,
      },
    ]);
    setPurchaseTypeData(prevState => {
      const newState = { ...prevState };
      newState[activeIndexForm] = {
        ...prevState[activeIndexForm],
        isIndexed: true,
        data: { ...indexedFormValues }
      };
      return newState;
    });
    setActiveIndexForm(undefined);
    setActiveIndexGrade(undefined);
  }

  const addQualityFormHandler = () => {
    qualityDetailsFormRef.validateFields().then(values => {
      values.qualityData.forEach((rec, index) => {
        let specData = {};
        if (rec['specStandard'] === SpecTypesEnum.ASTM) {
          specData['astmData'] = purchaseTermData[`${index}`]['purchaseTermData']['astmData'];
        } else if (rec['specStandard'] === SpecTypesEnum.ISO) {
          specData['isoData'] = purchaseTermData[`${index}`]['purchaseTermData']['isoData'];
        } else {
          specData['astmData'] = purchaseTermData[`${index}`]['purchaseTermData']['astmData'];
          specData['isoData'] = purchaseTermData[`${index}`]['purchaseTermData']['isoData'];
        }
        rec.purchaseTypeData.forEach((purchaseRec, purchaseIndex) => {
          if (purchaseRec.isIndexLinked) {
            const indexedData = purchaseTypeData[`${index}$&${purchaseIndex}`].data;
            rec.purchaseTypeData[purchaseIndex] = { ...purchaseRec, indexedData }
          }
        });
        values.qualityData[index] = { ...rec, purchaseTermData: specData }
      });
      const requestObject = {
        "pcId": contractId,
        "plantCode": plantCode,
        "qualityDetails": values.qualityData
      }
      addLaycanData(requestObject);
    }).catch(errorInfo => {
      console.log('errorInfo ===>', errorInfo);
    })
  }

  const addLaycanData = (requestObject: any) => {
    service.addLaycanData(requestObject).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Laycan saved successfully` }));
        setShowAddLaycan(false); // to close the drawer
        setShowEditLaycan(false); // to close the drawer
        setPurchaseData({
          contractDetails: {},
          qualityDetails: { qualityData: [{ purchaseTypeData: [''] }] },
          surveyorDetails: [],
          commercialDocuments: { commercialDocuments: ['', '', '', '', ''] },
          shippingTerms: { shippingTerms: ['', '', '', ''] },
          paymentTerms: { paymentMode: PaymentModesEnum.TELEGRAPHIC_TRANSFER, paymentTerms: ['', '', '', ''] },
          filesData: [],
          remarks: '',
          addendumData: []
        });
        getSalesPreviewData(contractId, plantCode);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const viewLaycanHandler = (qualityId: string) => {
    setLaycanData(previewData?.qualityDetails.qualityData.find(quality => quality.qualityId === qualityId));
    setShowViewLaycan(true);
  }

  const editLaycan = (qualityId: string) => {
    let qualityRec = previewData?.qualityDetails.qualityData.filter(quality => quality.qualityId === qualityId);
    qualityRec.forEach((quality: any, index: number) => {
      purchaseTermHandler(index, quality.purchasingTerm, quality.purchaseTermData, quality.specStandard, quality.qualityAdjustments, quality.noPriceAdjustment);
      quality.purchaseTypeData.forEach((purchaseType, pcIndex) => {
        setPurchaseTypeData(prevState => {
          const newState = { ...prevState };
          newState[`${index}$&${pcIndex}`] = {
            ...prevState[`${index}$&${pcIndex}`] ? prevState[`${index}$&${pcIndex}`] : {},
            isIndexed: purchaseType['isIndexLinked'],
            purchaseType: purchaseType.purchaseType,
            data: { ...purchaseType['indexedData'] }
          };
          return newState;
        });
      });
    });
    setPurchaseData((prevState) => { return { ...prevState, qualityDetails: { qualityData: qualityRec } } });
    setShowViewLaycan(false);
    setShowEditLaycan(true);
  }

  const sendLaycanDataForReview = (qualityId: string) => {
    service.sendLaycanDataForReview({ qualityId, userName: authContext.user.userName }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Laycan Data Sent For Approval successfully' }));
        getSalesPreviewData(contractId, plantCode);
        setShowViewLaycan(false);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    })
  }

  const approveLaycan = (qualityId: string) => {
    service.approveLaycan({ qualityId, userName: authContext.user.userName }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Laycan approved successfully' }));
        getSalesPreviewData(contractId, plantCode);
        setShowViewLaycan(false);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    })
  }

  const rejectLaycan = (qualityId: string) => {
    service.rejectLaycan({ qualityId, userName: authContext.user.userName }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Laycan sent back to Draft successfully' }));
        getSalesPreviewData(contractId, plantCode);
        setShowViewLaycan(false);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    })
  }

  const closeLaycan = (qualityId: string) => {
    service.closeLaycan({ qualityId, userName: authContext.user.userName }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Laycan closed successfully' }));
        getSalesPreviewData(contractId, plantCode);
        setShowViewLaycan(false);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    })
  }

  const pricingFormulaFormHandler = () => {
    pricingFormulaFormRef.validateFields().then(values => {
      const updatedFormulas = values.pricingFormulas.map(formula => ({
        ...formula,
        updatedUser: authContext.user.userName,
        ...(formula.id ? {} : {
          contractId,
          contractType: ContractModesEnum.PURCHASE,
          plantCode: authContext.defaultPlant,
          createdUser: authContext.user.userName,
          isActive: 1
        })
      }));
      service.updatePricingFormula(updatedFormulas).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Pricing Formula updated successfully` }));
          getSalesPreviewData(contractId, plantCode);
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
      setPricingFormulaEdit(false);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }


  const pcQuantityTotal = previewData?.qualityDetails.qualityData.reduce((a, c) => a + Number(c.quantity), 0);

  const tabItem = [
    {
      key: "1",
      label: "Payment Terms",
      children: <PaymentTermsView paymentTerms={previewData?.paymentTerms} snapShotData={snapData} contractType={previewData?.contractType} />,
    },
    {
      key: "2",
      label: "Shipping Terms",
      children: <ShippingTermsView shippingTerms={previewData?.shippingTerms} snapShotData={snapData} contractType={previewData?.contractType} />,
    },
    {
      key: "3",
      label: "Commercial Terms",
      children: <CommercialDocumentsView commercialDocuments={previewData?.commercialDocuments} snapShotData={snapData} contractType={previewData?.contractType} />
    },
    {
      key: "4",
      label: "Pricing Formula",
      children: <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
        >
          <Card
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ color: 'black' }}>Pricing Formula</span>
                <Button
                  icon={<EditFilled style={{ fontSize: '10px' }} />}
                  style={{
                    border: 'none',
                    padding: '2px',
                    height: '20px',
                    width: '20px',
                    minWidth: '20px',
                    borderRadius: '50%',
                    color: '#0295D4',
                    backgroundColor: '#DEECF3',
                    marginRight: '8px',
                  }}
                  onClick={() => { setPricingFormulaEdit(true) }}
                />
              </div>
            }
            bordered={false}
            style={{
              textAlign: 'left',
              backgroundColor: '#F7F9FC',
              height: '300px',
              overflowY: 'auto',
            }}
            styles={{ header: { border: 0 }, body: { paddingTop: '2px' } }}
          >
            <Divider
              dashed
              style={{ marginTop: 0, marginBottom: '10px' }}
            ></Divider>
            {!pricingFormulaEdit ?
              (<PricingFormulaView pricingFormulas={previewData?.pricingFormulas?.pricingFormulas} />) :
              (<>
                <PricingFormula formRef={pricingFormulaFormRef} initialValues={previewData?.pricingFormulas} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                  <Button
                    type="primary"
                    style={{ marginRight: '8px' }}
                    onClick={pricingFormulaFormHandler}
                  >
                    Save
                  </Button>
                  <Button onClick={() => { setPricingFormulaEdit(false) }}>Cancel</Button>
                </div>
              </>)
            }
          </Card>
        </Col>
      </Row>,
    },
    {
      key: "5",
      label: "Remarks",
      children: <Row>
        {/* {previewData?.remarks &&  */}
        <Col xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}>
          <Card title={<span style={{ color: 'black' }}>{'Remarks'}</span>} style={{ textAlign: 'left', backgroundColor: '#F7F9FC', height: '300px', overflowY: 'auto' }} bordered={false} styles={{ header: { border: 0 }, body: { paddingTop: '2px' } }}>
            <Divider dashed style={{ marginTop: 0, marginBottom: '10px' }}></Divider>
            <pre>
              {previewData?.remarks}
            </pre>
          </Card>
        </Col>
      </Row>,
    },
    {
      key: "6",
      label: "Audit Information",
      children: <Card title={<span style={{ color: 'black' }}>{'User Details'}</span>} style={{ textAlign: 'left', backgroundColor: '#F7F9FC', height: '300px', overflowY: 'auto' }} bordered={false} styles={{ header: { border: 0 }, body: { paddingTop: '2px' } }}>
        <Divider dashed style={{ marginTop: 0, marginBottom: '10px' }}></Divider>
        <Row>
          <Col>
            <Descriptions
              column={
                previewData?.contractType === ContractTypesEnum.ADDENDUM
                  ? { xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }
                  : { xxl: 4, xl: 4, lg: 3, md: 2, sm: 1, xs: 1 }
              }
            >
              <Descriptions.Item label="Created By" >
                <span className='descriptions'>{contractDetails?.createdBy}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Approved By" >
                <span className='descriptions'>{contractDetails?.approvedBy}</span>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col>
            <Descriptions
              column={
                previewData?.contractType === ContractTypesEnum.ADDENDUM
                  ? { xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }
                  : { xxl: 4, xl: 4, lg: 3, md: 2, sm: 1, xs: 1 }
              }
            >
              <Descriptions.Item label="Created Date">
                <span className='descriptions'>{contractDetails?.createdDate ? moment(contractDetails?.createdDate).format('DD-MMM-YYYY h:mm A') : ''}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Approved Date">
                <span className='descriptions'>{contractDetails?.approvedOn ? moment(contractDetails?.approvedOn).format('DD-MMM-YYYY h:mm A') : ''}</span>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    },
  ];

  return (
    <>
      <Layout style={{ backgroundColor: '#F8F8F8', border: 10 }}>
        {/* <Card  bordered={false} style={{padding:'0px'}} title={<Space size={5}>
               <a href="#/po-tracker" style={{fontSize: '18px', color: '#6B7280' }}>
               <FontAwesomeIcon icon={faArrowLeft} />
               </a>
               <span>{contractDetails?.['contractNo']} Purchase Details</span>&nbsp;
               <StatusTag status={contractDetails?.['status']} />
              </Space>} 
              headStyle={{border: 'none'}}
              style={{backgroundColor: '#F8F8F8'}}
              ref={printRef}
               extra={<Space size={10}>
              <Button icon={<FontAwesomeIcon icon={faDownload} />} style={{ border: 'none', fontSize: '15px', padding: '7px', borderRadius: '50%', color: '#0295D4', backgroundColor: '#DEECF3'}} onClick={handleDownloadPdf}></Button>
              {isPermissionExist([112]) && (contractDetails?.['status'] === ContractStatusEnum.DRAFT || contractDetails?.['status'] === ContractStatusEnum.PENDING_APPROVAL) 
              && <Button  icon={<EditFilled />} style={{ border: 'none', fontSize: '15px', padding: '7px', borderRadius: '50%', color: '#0295D4', backgroundColor: '#DEECF3'}} onClick={setFormData}></Button>}
                {getExtraComponent(contractDetails?.['status'])}
              </Space>}
              > */}

        <div style={{ backgroundColor: '#F5F5F5', padding: '0px' }} ref={printRef}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'none', marginBottom: '10px' }}>
            <Space size={5}>
              <a href="#/po-tracker" style={{ fontSize: '18px', color: '#6B7280' }}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </a>
              <span style={{ fontSize: '16px', fontWeight: 600, color: '#323F49' }}>{contractDetails?.['contractNo']} Purchase Details</span>&nbsp;
              <StatusTag status={contractDetails?.['status']} />
            </Space>
            <Space size={10}>
              <Button
                icon={<FontAwesomeIcon icon={faDownload} />}
                style={{
                  border: 'none',
                  fontSize: '15px',
                  padding: '7px',
                  borderRadius: '50%',
                  color: '#0295D4',
                  backgroundColor: '#DEECF3'
                }}
                onClick={handleDownloadPdf}
              />
              {isPermissionExist([112]) && (contractDetails?.['status'] === ContractStatusEnum.DRAFT || contractDetails?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && (
                <Button
                  icon={<EditFilled />}
                  style={{
                    border: 'none',
                    fontSize: '15px',
                    padding: '7px',
                    borderRadius: '50%',
                    color: '#0295D4',
                    backgroundColor: '#DEECF3'
                  }}
                  onClick={setFormData}
                />
              )}
              {getExtraComponent(contractDetails?.['status'])}
            </Space>
          </div>

          {/* <br></br> */}
          {/* <Row justify="end">
          <Col><> <Button href="#/po-tracker" style={{ color: 'black' }}>PO Tracker</Button> <Button style={{ marginLeft: 20 }} onClick={handleDownloadPdf}>Download Purchase Order</Button>
          </></Col></Row> */}
          {/* <Card
          title={<span ><a href="#/po-tracker" style={{ color: 'black' }}><ArrowLeftOutlined /></a> {contractDetails?.['contractNo']} Purchase Details
           <StatusTag status={contractDetails?.['status']} /> 
          </span>}
          style={{ background: 'transparent' }}
          headStyle={{ backgroundColor: '#2196f3', color: '#fff' }}
          ref={printRef}
          extra={<>{getExtraComponent(contractDetails?.['status'])}</>}
          > */}
          {/* <br></br> */}
          <ContractDetailsView
            addendumData={previewData?.addendumData}
            contractDetails={contractDetails}
            shippingTerms={shippingTerms}
            contractMode={ContractModesEnum.PURCHASE}
            contractType={previewData?.contractType}
            plantCode={plantCode}
            snapShotData={snapData}
            allocateData={allocateData}
          />
          <br></br>
          <Card title={<span style={{ fontSize: '16px', fontWeight: 600 }}>Laycan Details</span>}
            extra={previewData?.contractDetails.status === ContractStatusEnum.ACTIVE && previewData?.contractType === ContractTypesEnum.ORIGINAL && previewData?.addendumData?.every(el => el.status === ContractStatusEnum.ACTIVE) &&
              <Tooltip title='Add Laycan'>
                <Button size='middle' icon={<PlusCircleFilled />}
                  style={{ border: 'none', padding: '14px', borderRadius: '50%', color: '#0295D4', backgroundColor: '#DEECF3' }}
                  onClick={() => { setShowAddLaycan(true) }}
                ></Button>
              </Tooltip>
            }
            styles={{ header: { paddingTop: '8px', border: 0 }, body: { paddingTop: '12px', paddingBottom: 0, overflow: 'auto' } }}
            style={{ borderRadius: '8px' }}
          >
            <QualityDetailView
              classes='contracts'
              qualityData={previewData?.qualityDetails?.qualityData}
              type={ContractModesEnum.PURCHASE}
              plantCode={plantCode}
              snapShotData={snapData}
              contractType={previewData?.contractType}
              vat={vat}
              contractStatus={previewData?.contractDetails?.status}
              viewLaycanHandler={viewLaycanHandler}
            />

            {previewData?.surveyorDetails.length > 0 && (<Divider dashed={true}></Divider>)}
            {previewData?.surveyorDetails.length > 0 && (<span style={{ fontSize: '16px', fontWeight: 600, padding: '10px' }}>Surveyor Details</span>)}

            <SurveyorDetailsView
              surveyorDetails={previewData?.surveyorDetails}
              snapShotData={snapData}
              contractType={previewData?.contractType}
            />
          </Card>

          {/* Add Laycan drawer */}
          <Drawer
            open={showAddLaycan}
            className='dashborad-popup'
            width={1000}
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49' }}>Add Laycan</span>
                <Button style={{ border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none' }}
                  icon={<FontAwesomeIcon icon={faXmark} />} onClick={() => { setShowAddLaycan(false) }}>
                </Button>
              </div>
            }
            closable={false}
            footer={
              <Flex justify='space-between' align='center' style={{ padding: '0px 16px', margin: 8, }}>
                <Button onClick={() => { addQualityFormHandler() }} type="primary" className='new-button'>
                  Submit
                </Button>
                <span style={{ color: '#0083C9', fontSize: '14px', fontWeight: 400 }}>
                  <a onClick={() => { setShowAddLaycan(false) }}>Close</a>
                </span>
              </Flex>
            }
          >
            <AddQuality
              formRef={qualityDetailsFormRef}
              initialValues={purchaseData.qualityDetails}
              layoutType='vertical'
              purchaseTermHandler={purchaseTermHandler}
              purchaseTermFormSubmitHandler={purchaseTermFormSubmitHandler}
              handleIsIndexedButton={handleIsIndexedButton}
              handlePurchaseType={handlePurchaseType}
              indexFormCancelHandler={indexFormCancelHandler}
              indexFormSubmitButtonHandler={indexFormSubmitButtonHandler}
              activeIndexForm={activeIndexForm}
              purchaseTypeData={purchaseTypeData}
              activeQualityKeyTerm={activeQualityKeyTerm}
              setPurchaseTermData={setPurchaseTermData}
              setPurchaseTypeData={setPurchaseTypeData}
              purchaseTermData={purchaseTermData}
              activeIndexGrade={activeIndexGrade}
              contractMode={ContractModesEnum.PURCHASE}
              contractType={previewData?.contractType}
              singleLaycan={true}
            />
          </Drawer>

          {/* View Laycan drawer */}
          <Drawer
            open={showViewLaycan}
            className='dashborad-popup'
            width={800}
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49', marginRight: '8px' }}>Laycan Details</span>
                  <StatusTag status={laycanData.approvalStatus ?? contractDetails?.['status']} />
                </div>
                <Button style={{ border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none' }}
                  icon={<FontAwesomeIcon icon={faXmark} />} onClick={() => setShowViewLaycan(false)}>
                </Button>
              </div>
            }
            closable={false}
            onClose={() => setShowViewLaycan(false)}
            footer={
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: "16px", paddingRight: "16px", margin: '8px', alignItems: 'center' }}>
                <div>
                  {previewData?.contractType === ContractTypesEnum.ORIGINAL && laycanData.approvalStatus === ContractStatusEnum.DRAFT &&
                    <>
                      <Button onClick={() => sendLaycanDataForReview(laycanData.qualityId)}
                        type="primary" style={{ marginRight: "16px" }} className='new-button'
                      >
                        Send For Approval
                      </Button>
                      <Button onClick={() => editLaycan(laycanData.qualityId)}
                        type="default" style={{ marginRight: "16px" }}
                      >
                        Edit
                      </Button>
                    </>
                  }
                  {contractDetails?.status === ContractStatusEnum.ACTIVE && laycanData.approvalStatus === ContractStatusEnum.PENDING_APPROVAL &&
                    <>
                      <Button onClick={() => editLaycan(laycanData.qualityId)}
                        type="default" style={{ marginRight: "16px" }} className='new-button'
                      >
                        Edit
                      </Button>

                      <Button size="small" className='approve' style={{ marginRight: "16px" }}
                        onClick={() => approveLaycan(laycanData.qualityId)}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />Approve
                      </Button>
                      <Button size="small" className='reject' onClick={() => rejectLaycan(laycanData.qualityId)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
                    </>
                  }
                  {contractDetails?.status === ContractStatusEnum.ACTIVE && laycanData.approvalStatus === ContractStatusEnum.ACTIVE &&
                    <Button onClick={() => closeLaycan(laycanData.qualityId)}
                      type="primary" style={{ marginRight: "16px" }} className='new-button'
                    >
                      Mark as Closed
                    </Button>
                  }
                </div>
                <span style={{ color: '#0083C9', fontSize: '14px', fontWeight: 400 }}>
                  <a onClick={() => setShowViewLaycan(false)}>Close</a>
                </span>
              </div>
            }
          >
            <LaycanView laycanData={laycanData} contractType={previewData?.contractType} />
          </Drawer>

          {/* Edit Laycan drawer */}
          <Drawer
            open={showEditLaycan}
            className='dashborad-popup'
            width={1000}
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49', marginRight: '8px' }}>Laycan Details</span>
                  <StatusTag status={laycanData.approvalStatus ?? contractDetails?.['status']} />
                </div>
                <Button style={{ border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none' }}
                  icon={<FontAwesomeIcon icon={faXmark} />} onClick={() => setShowEditLaycan(false)}>
                </Button>
              </div>
            }
            closable={false}
            onClose={() => setShowEditLaycan(false)}
            footer={
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: "16px", paddingRight: "16px", margin: '8px', alignItems: 'center' }}>
                <Button onClick={() => { addQualityFormHandler() }} type="primary" className='new-button'>
                  Submit
                </Button>
                <span style={{ color: '#0083C9', fontSize: '14px', fontWeight: 400 }}>
                  <a onClick={() => setShowEditLaycan(false)}>Cancel</a>
                </span>
              </div>
            }
          >
            <AddQuality
              formRef={qualityDetailsFormRef}
              initialValues={purchaseData.qualityDetails}
              layoutType='vertical'
              purchaseTermHandler={purchaseTermHandler}
              purchaseTermFormSubmitHandler={purchaseTermFormSubmitHandler}
              handleIsIndexedButton={handleIsIndexedButton}
              handlePurchaseType={handlePurchaseType}
              indexFormCancelHandler={indexFormCancelHandler}
              indexFormSubmitButtonHandler={indexFormSubmitButtonHandler}
              activeIndexForm={activeIndexForm}
              purchaseTypeData={purchaseTypeData}
              activeQualityKeyTerm={activeQualityKeyTerm}
              setPurchaseTermData={setPurchaseTermData}
              setPurchaseTypeData={setPurchaseTypeData}
              purchaseTermData={purchaseTermData}
              activeIndexGrade={activeIndexGrade}
              contractMode={ContractModesEnum.PURCHASE}
              contractType={previewData?.contractType}
              singleLaycan={true}
            />
          </Drawer>

          <br></br>

          <Row gutter={[16, 12]}>
            <Col
              span={previewData?.contractType === ContractTypesEnum.ADDENDUM ? 16 : 24}
              xs={24}
              md={24}
              lg={previewData?.contractType === ContractTypesEnum.ADDENDUM ? 16 : 24}
              xl={previewData?.contractType === ContractTypesEnum.ADDENDUM ? 16 : 24}
              style={{ display: 'flex' }}
            >

              <Card title="Additional terms" bordered={false} styles={{ header: { fontSize: '16px', fontWeight: 600, color: '#323F49', border: 'none', paddingTop: '10px' } }} style={{ width: '100%', borderRadius: '8px' }}>
                <Tabs tabPosition='left' tabBarStyle={{ backgroundColor: '#F7F9FC' }} items={tabItem} />
              </Card>
            </Col>
            <br></br>

            {previewData?.contractType !== ContractTypesEnum.ADDENDUM && (
              <Col span={16} xs={24} md={24} lg={16} xl={16} style={{ display: 'flex' }}>
                <Card bordered={false} style={{ width: '100%', borderRadius: '8px' }}>
                  <AllPurchaseContractDetailedView pcQuantityTotal={pcQuantityTotal} contractId={contractId} plantCode={plantCode} bpId={previewData?.contractDetails?.businessPartner} contractType={previewData?.contractType} allocateData={allocateData} contractDetails={contractDetails} />
                </Card>
              </Col>
            )}

            <Col span={8} xs={24} md={24} lg={8} xl={8} style={{ display: 'flex' }}>
              {previewData?.filesData && isPermissionExist([122]) && (<FileUploadForm maxCount={3} layoutType='vertical'
                formRef={filesFormRef}
                initialValues={previewData?.filesData}
                contractType={previewData?.contractType}
                formType='View'
                fileModal={openModal}
                setFileModal={setOpenModal}
                setRefresh={setRefresh}
              >

                <Row justify="end">
                  <Col>
                    {
                      <Space>
                        <Button onClick={fileUploadFormHandler} type="primary" className='new-button'>
                          Save
                        </Button>
                      </Space>
                    }</Col>
                </Row>
              </FileUploadForm>)}
            </Col>
          </Row>
          {/* </Card> */}
          <br></br>
          {/* <Affix offsetBottom={0}>
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>
              <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
              <Col>{isPermissionExist([112]) && (contractDetails?.['status'] === ContractStatusEnum.DRAFT || contractDetails?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && <Button onClick={setFormData}>Edit</Button>}</Col>
            </Row>
          </Card>
        </Affix> */}

        </div>

        {/* </Card> */}

      </Layout >
    </>
  )
}
export default PurchaseContractDetailView;