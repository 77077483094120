import { ActionEnum, ContractModesEnum, purchaseSamplingAndSupplierReponseDto, QualityFilterDto, SamplingAndAnalysisService, SamplingTypes, ScqTypicalRequestDto, ShippingTypes } from '@exportx/shared-models-and-services';
import { Card, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isPermissionExist, useAuthState } from '../../../common';
import { QualityBargeSelection, QualityResultsGlobalFilter } from '../common';
import { BargeSamplingReport } from './reports';
export interface IPropsPurchaseSampling {
  sampleType: SamplingTypes;
  contractType: ContractModesEnum;
  actionType: ActionEnum;
}



export const PurchaseSampling = (props: IPropsPurchaseSampling) => {
  const [formRef] = Form.useForm();
  const navigate = useNavigate();
  const { sampleType, contractType, actionType } = props;
  const [samplingData, setSamplingData] = useState<purchaseSamplingAndSupplierReponseDto[]>([]);
  const [selectedBargeIds, setSelectedBargeIds] = useState<string[]>([]);
  const [isEntryFormActivated, setIsEntryFormActivated] = useState(false);
  const qualityService = new SamplingAndAnalysisService();
  const { state }: any = useLocation();
  const { qualityData } = state ? state : { qualityData: null, };
  const [bargeScq, setBargeScq] = useState<ScqTypicalRequestDto>();
  const [filePermission, setFilePermission] = useState(0);
  const { authContext } = useAuthState();

  useEffect(() => {

    let actionTypee;

    if (contractType === ContractModesEnum.PURCHASE) {
        switch (sampleType) {
            case SamplingTypes.SUPPLIER:
                actionTypee = actionType === ActionEnum.CREATE ? 836 : 858;
                setFilePermission(840)
                break;
            case SamplingTypes.INTERNAL:
                actionTypee = actionType === ActionEnum.CREATE ? 841 : 862;
                setFilePermission(845)
                break;
            case SamplingTypes.ACTUAL:
                actionTypee = actionType === ActionEnum.CREATE ? 846 : 866;
                setFilePermission(850)
                break;
            default:
                actionTypee = actionType === ActionEnum.CREATE ? 851 : 874;
                setFilePermission(855)
                break;
        }
    } else {
        switch (sampleType) {
            case SamplingTypes.ACTUAL:
                actionTypee = actionType === ActionEnum.CREATE ? 876 : 883;
                setFilePermission(880)
                break;
            default:
                actionTypee = actionType === ActionEnum.CREATE ? 876 : 883;
                setFilePermission(880)
                break;
        }
    }

    if (!isPermissionExist([actionTypee])) navigate('/');
}, [contractType, sampleType, actionType]);



  useEffect(() => {
    if (qualityData) {
      filterSubmitHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualityData])

  const filterSubmitHandler = () => {
    formRef.validateFields().then(formData => {
      console.log(formData, "formData");
      const reqDto: QualityFilterDto = new QualityFilterDto(formData.businessNumber, formData.contractId, formData.qualityId, formData.type, sampleType, contractType, actionType);
      const bgScq = new ScqTypicalRequestDto(formData.qualityId, authContext.defaultPlant, formData.type);
      setBargeScq(bgScq)

      if(contractType === ContractModesEnum.SALES && formRef.getFieldValue('incoTerm') !== ShippingTypes.FOB_BARGE) {
        setIsEntryFormActivated(true)
        return;

      }
      
      qualityService.getBargesForQualityData(reqDto).then(res => {
        if (res.status) {
          setSamplingData(res.data);
        }
      }).catch(err => {

      })
    });
  }

  return (
    <>
      <Card style={{ minHeight: '90vh' }}>
        <QualityResultsGlobalFilter initialValues={qualityData ? qualityData[0] : ''} filterSubmitHandler={filterSubmitHandler} actionType={actionType} sampleType={sampleType} formRef={formRef} contractType={contractType} />
        {!isEntryFormActivated && <QualityBargeSelection setBargeScq={setBargeScq} bargeDataAtUpdateTime={qualityData} samplingData={samplingData} selectedBargeIds={selectedBargeIds} setSelectedBargeIds={setSelectedBargeIds} setIsEntryFormActivated={setIsEntryFormActivated} />}
        {isEntryFormActivated && <BargeSamplingReport formRef={formRef} bargeScq={bargeScq} qualityData={qualityData} setIsEntryFormActivated={setIsEntryFormActivated} bargeId={selectedBargeIds} sampleType={sampleType} actionType={actionType} contractType={contractType} filePermission={filePermission} />}
      </Card>
    </>
  )
}

export default PurchaseSampling