import { DownOutlined } from '@ant-design/icons';
import { BusinessNumberService, BusinessResponseNumberDto, ContractStatusEnum } from '@exportx/shared-models-and-services';
import { Button, Col, Collapse, Dropdown, Form, Menu, MenuProps, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
//import { AlertMessages, PreviewComponent, useAuthState } from '../../../common';
import moment from 'moment';
import { AlertMessages, isPermissionExist, PreviewComponent, useAuthState } from '../../../../common';
import BusinessNumber from './business-number';

const { Panel } = Collapse;

export interface BusinessNoProps {
  bnAction?: string;
}

export const BusinessNumberDetails = (props: BusinessNoProps) => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  //Common for all Page Components

  const [defaultOpenKey, setDefaultOpenKey] = useState(['1']);
  const [goToButtonKeys, setGoToButtonKeys] = useState([]);
  const [businessNumberData, setBusinessNumberData] = useState<any>({ businessNumberCreation: { supplierDemurrage: [''], buyerDemurrage: [''], loadingAnchorage: [''], }, shippingPlaning: {}, vessel: {}, status: '' });
  const [businessNumberFormRef] = Form.useForm();
  const { formatMessage: fm } = useIntl();
  const { authContext } = useAuthState();

  useEffect(()=>{
    let permission;
      if(props?.bnAction === 'create'){
        permission = [253]
      } else {
        permission = [255]
      }
      if(!isPermissionExist(permission)) navigate('/')
  },[state])

  useEffect(() => {
    let limit = Number(defaultOpenKey[0]);
    let i: number, goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOpenKey])

  const service = new BusinessNumberService();
  let pathToreDirect = '/businessnumber';

  useEffect(() => {
    if (state) {
      setDefaultData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const setDefaultData = () => {
    const newState = { ...state };
    newState.businessNumberCreation.estimateDate = moment(state.businessNumberCreation.estimateDate);
    // if (state?.shippingPlaning?.etaNor) {
    //   newState.shippingPlaning.etaNor = moment(state.shippingPlaning.etaNor);
    // }
    // if (state?.vessel?.norAcceptance) {
    //   newState.vessel.norAcceptance = moment(state.vessel.norAcceptance);
    // }
    // if (state?.vessel?.vesselArrived)
    //   newState.vessel.vesselArrived = moment(state.vessel.vesselArrived);
    setBusinessNumberData(newState);
  }
  useEffect(() => {
    businessNumberFormRef.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessNumberData])

  const businessNumberFormHandler = () => {
    businessNumberFormRef.validateFields().then(values => {
      console.log(values)
      setBusinessNumberData((prevState) => { return { ...prevState, businessNumberCreation: values, createdUser: authContext.user.userName, updatedUser: authContext.user.userName } });
      setDefaultOpenKey(['2']);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const previewDataLabels = [{
    title: fm({ id: `step1heading`, defaultMessage: `Business Number Details` }),
    keysAndLabelMapping: [{
      key: "businessNumberCreation",
      labels: {
        businessType: fm({ id: 'mdm.components.businessNumberCreationForm.businessType', defaultMessage: "Business Type" }),
        consignmentType: fm({ id: 'mdm.components.businessNumberCreationForm.consignmentType', defaultMessage: "Consignment Type" }),
        motherVesselName: fm({ id: 'mdm.components.businessNumberCreationForm.motherVesselName', defaultMessage: "Mother Vessel Name" }),
        shipmentType: fm({ id: 'mdm.components.businessNumberCreationForm.ShipmentType', defaultMessage: "Shipment Type" }),
      }
    }]
  },
  {
    title: 'Loading Anchorage Details',
    keysAndLabelMapping: [{
      key: 'businessNumberCreation.loadingAnchorage',
      labels: {
        anchorageName: fm({ id: 'mdm.components.businessNumberCreationFrom.anchorageName', defaultMessage: "Anchorage" }),
        laycan: fm({ id: 'mdm.components.businessNumberCreationFrom.laycan', defaultMessage: "Laycan" }),
        estimateDate: fm({ id: 'mdm.components.businessNumberCreationForm.estimateDate', defaultMessage: "Estimated ETA" }),
      }
    }],
    isGridDisplay: true,
  },
  {
    title: 'Supplier Demurrage',
    keysAndLabelMapping: [{
      key: 'businessNumberCreation.supplierDemurrage',
      labels: {
        bpName: fm({ id: 'mdm.components.businessNumberCreationForm.bpName', defaultMessage: "Supplier" }),
        IncotermType: fm({ id: 'mdm.components.businessNumberCreationForm.IncotermType', defaultMessage: "Incoterm Type" }),
        supplierDemurrage: fm({ id: 'mdm.components.businessNumberCreationForm.supplierDemurrage', defaultMessage: "Supplier Demurrage" }),
        supplierCurrency: "Supplier Demurrage Currency",
        supplierQuantity: fm({ id: 'mdm.components.businessNumberCreationForm.supplierQuantity', defaultMessage: "Supplier Quatity" }),
        // bargeWindowData: fm({ id: 'mdm.components.businessNumberCreationForm. bargeWindowData', defaultMessage: "Barge Window" }),
      }
    }],
    isGridDisplay: true,
  },
  {
    title: 'Buyer Demurrage',
    keysAndLabelMapping: [{
      key: 'businessNumberCreation.buyerDemurrage',
      labels: {
        bpName: fm({ id: 'mdm.components.businessNumberCreationForm.bpName', defaultMessage: "Supplier" }),
        buyerDemurrage: fm({ id: 'mdm.components.businessNumberCreationForm.buyerDemurrage', defaultMessage: "Buyer Demurrage" }),
        buyerDemurrageCurrency: "Buyer Demurrage Currency",
        buyerQuantity: fm({ id: 'mdm.components.businessNumberCreationForm.buyerQuantity', defaultMessage: "Buyer Quatity" }),
      }
    }],
    isGridDisplay: true,
  }]
  const saveBusinessNumber = () => {
    if (state?.businessNumberCreation?.businessNo) {
      const req = new BusinessResponseNumberDto(businessNumberData.businessNumberCreation, businessNumberData.shippingPlaning, businessNumberData.vessel, ContractStatusEnum.ACTIVE, undefined, businessNumberData.updatedUser, authContext.defaultPlant, state.businessNumberCreation?.businessNo, undefined, state.businessNumberCreation?.bnId, state.businessNumberCreation?.versionFlag);
      service.update(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setTimeout(() => {
            navigate(pathToreDirect);
          }, 1000);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
    } else {
      const req = new BusinessResponseNumberDto(businessNumberData.businessNumberCreation, businessNumberData.shippingPlaning, businessNumberData.vessel, ContractStatusEnum.ACTIVE, businessNumberData.createdUser, businessNumberData.updatedUser, authContext.defaultPlant, undefined, undefined, undefined, undefined);
      service.create(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setTimeout(() => {
            navigate(pathToreDirect);
          }, 1000);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  }




  const goToOnClickHandler: MenuProps['onClick'] = ({ key }) => {
    setDefaultOpenKey([`${key}`]);
  };

  const items = goToButtonKeys.map(item => {
    return {
      label: `${item}`,
      key: `${item}`,
    }
  })
  return (
    <div>
      <Collapse activeKey={defaultOpenKey} collapsible='disabled'>
        <Panel header="Business Number" key="1" >
          <BusinessNumber formRef={businessNumberFormRef} initialValues={businessNumberData.businessNumberCreation} layoutType='vertical' >
            <Row justify="end">
              <Col>
                {<Space>
                  <Button onClick={businessNumberFormHandler} type="primary">
                    Save
                  </Button>
                </Space>
                }
              </Col>
            </Row>
          </BusinessNumber>
        </Panel>

        <Panel header="Preview" key="2">
          <>
            <PreviewComponent data={businessNumberData} labels={previewDataLabels} primaryKeyData={{}}>

              <Row justify="end">
                <Col>
                  {
                    <Space>
                      <Dropdown.Button
                        icon={<DownOutlined />}
                        menu={{ items, onClick: goToOnClickHandler }}
                      >
                        {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                      </Dropdown.Button>
                      <Button onClick={saveBusinessNumber} type="primary">
                        Save
                      </Button>
                    </Space>
                  }</Col>
              </Row>
            </PreviewComponent>
          </>
        </Panel>

      </Collapse>
    </div>
  )
}

export default BusinessNumberDetails