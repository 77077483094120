import { Popover, Button, Typography } from "antd";
import moment from "moment";
import React from "react";


export const ApprovalTracking = ({ history = [] }) => {
    const approvalDetails = history?.filter((e) => e.approvalLevel && e.approvalLevel !== null);
    return (

        <Popover
            placement="bottom"
            title={<Typography className="tooltip-heading">Approval Details</Typography>}
            content={<>
                {approvalDetails?.map((log) => (
                    <Typography style={{ fontSize: 10 }}><b>{log.approvalLevel}</b> Approved By <b>{log?.createdUser}</b> Approved On <b>{log?.createdAt ? moment(log?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
                ))}
            </>}

        >
            <Button type="text" size="small" className="link">Approval Details</Button>

        </Popover>
    )
}