import {
  BasisDeactivateDto,
  BasisDto,
  BasisService,
} from '@exportx/shared-models-and-services';
import { Button, Card, Col, Input, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  FilterableGrid,
  isPermissionExist,
  useAuthState,
} from '../../../../../common';

interface IPropsBasisGrid {}
export const BasisGrid = (props: IPropsBasisGrid) => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { authContext } = useAuthState();
  const [gridData, setGridData] = useState<BasisDto[]>([]); //This is common for all Page Components But Variable Name May Vary
  const [searchedText, setSearchedText] = useState('');

  const service = new BasisService();

  useEffect(() => {
    if (!isPermissionExist([19])) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGridData = () => {
    service
      .getAllBasis()
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
        } else {
          // AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const activateOrDeactivate = (basisData: BasisDto) => {
    const basisDeactivateDto: BasisDeactivateDto = new BasisDeactivateDto(
      basisData.basisId,
      authContext.user.userName,
      basisData.versionFlag,
    );
    service
      .activateOrDeactivate(basisDeactivateDto)
      .then((res) => {
        if (res.status) {
          getGridData();
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: `Basis ${
                basisData.isActive ? ' deactivated ' : ' activated '
              } SuccessFully`,
            }),
          );
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const view = (basisData: BasisDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (basisData) {
      isNewRecord = false;
    }
    let pathToreDirect = '/createBasis';
    navigate(pathToreDirect, { state: { basisData, isNewRecord, isUpdate } });
  };

  const columns: ColumnsType<any> = [
    {
      title: fm({
        id: 'mdm.components.basisGrid.basisName',
        defaultMessage: 'Basis Name',
      }),
      dataIndex: 'basisCode',
      key: 'basisCode',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
    },
  ];
  return (
    <>
      {/* <Card
        title={fm({ id: `basisGrid.header`, defaultMessage: `Basis Details` })}
        style={{ overflow: 'auto' }}
        className="default-card-class"
        extra={
          <>
            {isPermissionExist([18]) && (
              <Button onClick={() => view(undefined, false)}>
                {fm({ id: 'common.create', defaultMessage: 'Create' })}
              </Button>
            )}
          </>
        }
        bodyStyle={{ overflow: 'auto' }}
      > */}
      <Row justify="space-between" className="mb-12 mt-8">
        <Col>
          <Input.Search
            placeholder="Search Basis"
            allowClear
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
            onSearch={(value) => {
              setSearchedText(value);
            }}
            className="input-search"
          />
        </Col>
        <Col>
          <>
            {isPermissionExist([18]) && (
              <Button
                className="new-button"
                onClick={() => view(undefined, false)}
              >
                {fm({ id: 'common.create', defaultMessage: 'Create' })}
              </Button>
            )}
          </>
        </Col>
      </Row>
      <FilterableGrid
        rowKey="basisId"
        mainColumns={columns}
        data={gridData}
        filterColumnsData={[]}
        activateOrDeactivate={activateOrDeactivate}
        view={view}
      />
      {/* </Card> */}
    </>
  );
};
export default BasisGrid;
