import { Text, View, StyleSheet } from "@react-pdf/renderer"
import moment from "moment";
import { Fragment } from "react"

interface InvoiceNoProps {
   invoice: any;
}

export const InvoiceNO = (props: InvoiceNoProps) => {
   const { invoice } = props;

   const styles = StyleSheet.create({
      container: {
        width: "100%",
        marginTop: 20,
      },
      header: {
        flexDirection: "row",
        backgroundColor: "#f2f7fd",
        paddingVertical: 6,
      },
      headerText: {
        flex: 1,
        fontSize: 8,
        fontWeight: "bold",
        // textAlign: "center",
      },
      row: {
        flexDirection: "row",
        paddingVertical: 6,
        fontSize: 10,
        borderBottomWidth: 1,
        borderBottomColor: "#ddd",
      },
      cell: {
        flex: 1,
        fontSize: 8,
        // textAlign: "center",
      },
      rightAlignCell: {
        flex: 1,
        fontSize: 8,
        paddingRight: 8,
        textAlign: "right",
      },
      rightAlignText: {
        flex: 1,
        fontSize: 8,
        paddingRight: 8,
        fontWeight: "bold",
        textAlign: "right",
      },
      fRow: {
        flexDirection: "row",
        fontSize: 9,
      },
      footerRow: {
        flex: 1,
        paddingTop: 8,
        paddingHorizontal: 8,
        textAlign: "right",
      },
    });
  
  
    const rows = invoice?.invoices?.map((item => 
      <View key={item?.invoiceId} style={styles.row}>
         <Text style={[styles.cell,{ paddingLeft:8 }]}>{item?.invoiceNo}</Text>
         <Text style={styles.cell}>{moment(item?.invoiceDate).format('DD-MMM-YYYY')}</Text>
         <Text style={styles.cell}>{item?.invoicePercent}</Text>
         <Text style={styles.cell}>{item?.status}</Text>
         <Text style={styles.rightAlignCell}>{Number(item?.invoiceAmount).toFixed(3)}</Text>
      </View>
    ))

    const TotalAmount = invoice?.invoices?.reduce(
      (total, item) => total + Number(item.invoiceAmount), 0);

   return (
    <>
      {/* <View
          style={{
            borderBottom: 0.65,
            borderBottomColor: "black",
          }}
      >
        <Text style={{ textAlign: "center", fontSize: 16, paddingBottom: 8 }}>Invoice</Text>
      </View> */}
      <View style={styles.container}>
         <View style={styles.header}>
            <Text style={[styles.headerText, {paddingLeft:8}]}>Invoice No</Text>
            <Text style={styles.headerText}>Invoice Date</Text>
            <Text style={styles.headerText}>Invoice %</Text>
            <Text style={styles.headerText}>Status</Text>
            <Text style={styles.rightAlignText}>Invoice Amount</Text>
         </View>
         {rows}
      <View style={styles.fRow}>
        <Text style={styles.footerRow}>Total Amount:  {"  "} <Text style={{ color: "#0083C9" }}>{Number(TotalAmount).toFixed(3) || 0.000}</Text></Text>
      </View>
    </View>
    </>
   )
}

export default InvoiceNO

