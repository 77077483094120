// import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import { Document, Page, StyleSheet, Text, View, Font } from "@react-pdf/renderer";
import CostingBasicDetails from "./costing-invoice";
import InvoiceNO from "./invoice-no";
import BargeInvoice from "./barge-invoice";
import { ReferenceFeatures } from "@exportx/shared-models-and-services";
import moment from "moment";
import { useAuthState } from "../../../common";
import { useState } from "react";
import { CostingPriceDetails } from "./costing-price-details";
// import { fontFamily } from "html2canvas/dist/types/css/property-descriptors/font-family"

export interface InvoiceIProps {
  costingDetails?: any,
  costingName?: ReferenceFeatures,
  plantCode?: string;
}


export const CostingInvoice = (props: InvoiceIProps) => {
  const { costingDetails, costingName, plantCode } = props;
  Font.register({
    family: "Roboto",
    fonts: [
      { src: "https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Me5Q.ttf" },
      { src: "https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlvAw.ttf", fontWeight: "bold" },
    ],
  });
  let invoiceName: any = ''

  if(costingName === ReferenceFeatures.PERFORMA_PC_COSTING){
    invoiceName = 'Performa Invoice (Coal Purchase)'
  } else if(costingName === ReferenceFeatures.PERFORMA_SC_COSTING){
    invoiceName = 'Performa Invoice (Coal Sales)'
  } else if(costingName === ReferenceFeatures.COMMERCIAL_PC_COSTING){
    invoiceName = 'Commercial Invoice (Coal Purchase)'
  } else if(costingName === ReferenceFeatures.COMMERCIAL_SC_COSTING){
    invoiceName = 'Commercial Invoice (Coal Sales)'
  } else if(costingName === ReferenceFeatures.MARKETING_FEES){
    invoiceName = 'Marketing Fees'
  } else if(costingName === ReferenceFeatures.PROCUREMENT_FEES){
    invoiceName = 'Procurement Fees'
  } else if(costingName === ReferenceFeatures.BARGE_COSTINGS){
    invoiceName = 'Barge Costing'
  } else if(costingName === ReferenceFeatures.EXPENSE_ENTRY){
    invoiceName = 'Expense Entry'
  } else if(costingName === ReferenceFeatures.MV_DEAD_FREIGHT){
    invoiceName = 'Dead Freight'
  } else if(costingName === ReferenceFeatures.MV_DEMURRAGE){
    invoiceName = 'Demurrage'
  } else if(costingName === ReferenceFeatures.CREDIT_NOTE){
    invoiceName = 'Credit Note'
  } else if(costingName === ReferenceFeatures.DEBIT_NOTE){
    invoiceName = 'Debit Note'
  } else {
    invoiceName = costingName
  }
  
  // let advTotal;
  // if(costingDetails?.advanceTarnsactionsData){
  //   advTotal = Number(
  //     costingDetails?.advanceTarnsactionsData?.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2),
  //   );
  // }

  let barge =  costingDetails?.costingTransactions || costingDetails?.transactionBarges || costingDetails?.bargeCostings || costingDetails?.expenseEntryTransactions

  

  const styles = StyleSheet.create({
    page: {
      width: "100%",
      height: "100vh",
      fontFamily: "Helvetica",
      fontSize: 8,
      paddingTop: 20,
      paddingLeft: 25,
      paddingRight: 25,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    amount: {
      paddingHorizontal: 8, 
      paddingVertical: 4,
      flexDirection: "row", 
      alignItems: "center", 
      justifyContent: 'space-between'
    }
});
  return <>

        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    style={{
                      borderBottom: 0.65,
                      borderBottomColor: "black",
                    }}
                >
                  <Text style={{ textAlign: "center", fontSize: 16, paddingBottom: 8, fontWeight: "bold" }}>{invoiceName}</Text>
                </View>

                <CostingBasicDetails costing={costingDetails} costingName={costingName} plantCode={plantCode}/>
                {Array.isArray(barge) && barge.length <= 0 &&(
                   <View style={{ width: "100%", borderBottomWidth: 1, borderBottomColor: "#ddd" }}></View>
                )}

                {!barge && (
                   <View style={{ width: "100%", marginTop: 10, borderBottomWidth: 1, borderBottomColor: "#ddd" }}></View>
                )}

                {Array.isArray(barge) && barge.length > 0 &&(
                  <BargeInvoice BargeInvoice={costingDetails} costingName={costingName} />
                )}

                <CostingPriceDetails costingDetails={costingDetails} costingName={costingName} barge={barge}/>
                
                {(costingName === ReferenceFeatures.CREDIT_NOTE || 
                  costingName === ReferenceFeatures.DEBIT_NOTE || 
                  costingName === ReferenceFeatures.MV_DEMURRAGE || 
                  costingName === ReferenceFeatures.MV_DEAD_FREIGHT) ? 
                  (
                    Array.isArray(costingDetails?.invoices) && costingDetails.invoices.length > 0 && costingDetails.invoices.length <= 5 ? (
                      <InvoiceNO invoice={costingDetails} />
                    ) : null
                  ) : (
                    Array.isArray(costingDetails?.invoices) && costingDetails.invoices.length > 0 && costingDetails.invoices.length <= 5 &&
                    Array.isArray(barge) && barge.length <= 5 ? (
                      <InvoiceNO invoice={costingDetails} />
                    ) : null
                  )
                }


                
            </Page>
            {Array.isArray(costingDetails?.invoices) && costingDetails?.invoices.length > 5 || Array.isArray(barge) && barge.length > 5 &&
              <Page size="A4" style={styles.page}>
                <View
                    style={{
                      borderBottom: 0.65,
                      borderBottomColor: "black",
                    }}
                >
                  <Text style={{ textAlign: "center", fontSize: 16, paddingBottom: 8 }}>Invoice</Text>
                </View>
                  <InvoiceNO invoice={costingDetails} />
              </Page>
            }
        </Document>

    </>
}

export default CostingInvoice;