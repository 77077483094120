import { GroupEntryCreateDto, GroupEntryDto, GroupEntryService } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../common';

export const GroupEntryForm = () => {
  const navigate = useNavigate();
  const pathToreDirect = '/account-groups';
  const { formatMessage: fm } = useIntl();
  const [formRef] = Form.useForm();
  const service = new GroupEntryService();
  const [dropData, setDropData] = useState<any[]>([]);
  const { state }: any = useLocation();
  const { isUpdate, groupEntryData } = state ? state : { isUpdate: false, groupEntryData: null, };
  const { authContext } = useAuthState()
  const { Option } = Select

  useEffect(()=>{
    let permission = false;
    if(isUpdate){
      permission = isPermissionExist([786])
    } else {
      permission = isPermissionExist([784])
    }
    if(!permission) navigate('/')
  },[])



  const submitFormHandler = () => {
    formRef.validateFields().then(values => {
      submitForm(values);
    })
  }

  const clearData = () => {
    formRef.resetFields();
  }

  useEffect(() => {
    getMainGroupDropdownData();
    if (groupEntryData?.actGrpMainGroup) {
      formRef.setFieldValue("actGrpMainGroup", groupEntryData?.actGrpMainGroup)
    }
  }, [])



  const getMainGroupDropdownData = () => {
    service.dropDownGroupEntry().then(res => {
      if (res.status) {
        setDropData(res.data);
      }
    }).catch(err => {
      console.log('err:' + err.message);
    })
  }

  const submitForm = (values: any) => {
    const createDto: GroupEntryCreateDto = new GroupEntryCreateDto(values.actGrpName, values.actGrpMainGroup, groupEntryData?.actGrpId, authContext.user.userName, groupEntryData?.versionFlag);
    service.create({ ...createDto, versionFlag: groupEntryData?.versionFlag }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      }
      else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }

    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (
    <div>
      <Card title={<span>Group Entry </span>}
        extra={<Button type='primary' className='panel_button' onClick={() => navigate(pathToreDirect)}>View</Button>}>
        <Form form={formRef} autoComplete='off' layout='vertical' initialValues={groupEntryData}>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item label="Subgroup Name" rules={[{ required: true, },]}
                name="actGrpName"><Input placeholder="Enter Sub Group Name" type="text" style={{ width: "90%" }} />
              </Form.Item></Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item label="Main Group" rules={[{ required: true, },]}
                name="actGrpMainGroup">

                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear showSearch placeholder="Select Main Group" >
                  {dropData.map(d => {
                    return <Option disabled={groupEntryData ? true : false} value={d.actGrpId}>{d.actGrpName}</Option>
                  })}
                </Select>
              </Form.Item></Col>
            <Col span={24} style={{ textAlign: 'left' }}>
              <Button htmlType="button" style={{ float: 'right' }} onClick={() => {
                clearData();
              }}>
                Clear
              </Button>
              <Button type="primary" style={{ float: 'right' }} onClick={submitFormHandler}>
                Submit
              </Button>
            </Col>

          </Row>
        </Form>
      </Card>
    </div>

  )
}

export default GroupEntryForm;