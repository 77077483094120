import {
  AdvanceEnumType,
  AdvanceTypeReqDto,
  CalculationFilterDataReq,
  CalculationTableDto,
  CommercialCoalCostingService,
  CommercialGetAllDto,
  ContractModesEnum,
  ContractStatusEnum,
  ContractTermEnum,
  CostingTypesEnum,
  CostingWorkLogsEnum,
  CurrencyDto,
  CurrencyService,
  GetAllPerFormaDataDto,
  PerFormaReqIdDto,
  PerformaAdvanceDataDto,
  PerformaCoalCostingService,
  ProcurementContractTypeEnum,
  ReferenceFeatures,
  SalesContractService,
  ShippingTypes,
} from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Tabs,
} from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  isPermissionExist,
  useAuthState,
} from '../../../../common';
import { SavedCostingsGrid } from '../common';
import { CmSaleAdvanceAdjustments } from './cm-sale-advance-adjustments';
import CmSaleFilterComponent from './cm-sale-costing-filter-component';
import CmSalePriceAdjustment from './cm-sale-price-adjustment';
import CommercialCostingsBargeSelection from '../common/cm-coal-costings-bg-selection';
import { getNumberFromLocalString } from '@exportx/ui-utils';
import TabPane from 'antd/es/tabs/TabPane';
import { PaymentNoticePageEnum } from '../../payment-notice';
import { BargeCostingApproval } from '../../barge-costings';

interface IPropsCommercialCoalCostings {
  contractType: ContractModesEnum;
  referenceFeatures: ReferenceFeatures;
  pcrContractType?: ProcurementContractTypeEnum;
  costingType?: CostingTypesEnum;
}

export const CmSalesCostingMainForm = (props: IPropsCommercialCoalCostings) => {
  const [formRef] = Form.useForm();
  const [initialValues, setInitialValues] = useState<
    CommercialGetAllDto | GetAllPerFormaDataDto
  >();
  const navigate = useNavigate();
  const { contractType, referenceFeatures, pcrContractType, costingType } =
    props;
  const [bargeData, setBargeData] = useState<CalculationTableDto[]>([]);
  const [totalBargeQuantity, setTotalBargeQuantity] = useState<any>();
  const [bargeSelection, setBargeSelection] = useState<any>([]);
  const [bargeSelectionRowKeys, setBargeSelectionRowKeys] = useState<any[]>([]);
  const cmService = new CommercialCoalCostingService();
  const sales = new SalesContractService();
  const performaCostingsService = new PerformaCoalCostingService();

  const { authContext } = useAuthState();
  const [cmCostingId, setCmCostingId] = useState<string>();
  const [performaAdvance, setPerformaAdvance] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const [adjustedData, setAdjustedData] = useState<any[]>([]);
  let location = useLocation();
  let paramsId: any = queryString.parse(location.search)?.costingId;
  const [qualityFieldValue, setQualityFieldValue] = useState<number>();
  const [priceAdjustmentDrawer, setPriceAdjustmentDrawer] =
    useState<boolean>(false);
  const [dummyRefresh, setDummyRefresh] = useState<number>(0);
  const [gridDrawer, setGridDrawer] = useState<boolean>(false);
  const [invoiceContracts, setInvoiceContracts] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const currencyService = new CurrencyService();
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
  const [costingCurrency, setCostingCurrency] = useState<any>();
  const [accessId, setAccessId] = useState({});
  const [createPermission, setCreatePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  const [taxTotal, setTaxTotal] = useState<any>({
    taxTotal1: 0,
    taxTotal2: 0,
    tdsTotal: 0,
    invoiceTotal: 0,
    exchangeRateValue: 0,
    fcTotal: 0,
    fcCharges: 0,
    freightPricePmt: 0,
    totalCoalPrice: 0,
    freightTotalAmt: 0,
    freightTaxTotal1: 0,
    freightTaxTotal2: 0,
    freightTdsTotal: 0,
  });

  useEffect(() => {
    console.log('costingType', contractType);
    let permission;
    if (contractType === ContractModesEnum.PURCHASE) {
      permission = [292];
      setViewPermission(isPermissionExist([292]));
      setCreatePermission(isPermissionExist([291]));
      setAccessId({
        create: 300,
        view: 301,
        update: 302,
        delete: 303,
        approve: 304,
        reject: 305,
        release: 306,
        files: 307,
        purchaseEdit: 293,
      });
    } else {
      permission = [334];
      setViewPermission(isPermissionExist([334]));
      setCreatePermission(isPermissionExist([333]));
      setAccessId({
        create: 342,
        view: 343,
        update: 344,
        delete: 345,
        approve: 346,
        reject: 347,
        release: 348,
        files: 349,
        purchaseEdit: 335,
      });
    }
    if (!isPermissionExist(permission)) navigate('/');
  }, [contractType]);

  useEffect(() => {
    getCurrency();
    if (cmCostingId) {
      getAllCommercialData(cmCostingId);
    } else if (paramsId) {
      getAllCommercialData(paramsId);
    }
  }, [cmCostingId, paramsId]);

  useEffect(() => {
    getCurrency();
  }, []);

  const scCommercialBargeData = (values) => {
    if (
      costingType === CostingTypesEnum.COMMERCIAL &&
      values?.incoterm === ShippingTypes.FOB_BARGE
    ) {
      cmService
        .getBargesDataForSalesCommercial(values)
        .then((res) => {
          if (res.status) {
            setBargeData(res.data);
          } else {
            setBargeData([]);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setBargeData([]);
        });
    } else {
      sales
        .getPerformaNonSalesFOBBargeData(values)
        .then((res: any) => {
          if (res.status) {
            setBargeData(res.data);
          } else {
            setBargeData([]);
          }
        })
        .catch((err) => {
          console.log('error: ' + err.message);
          setBargeData([]);
        });
    }
  };

  const loadBargesOnClickHandler = (values) => {
    const req = new CalculationFilterDataReq(
      authContext.defaultPlant,
      values.businessNumber,
      values.contractId,
      values.qualityId,
      values.incoterm,
      values.purchaseType,
      contractType,
      values.pcrContractId,
    );
    scCommercialBargeData(req);
  };
  // for 113 line at update time
  // const bgDs = { contractNo: res.data.contractId, bgUId: bg.bgUId, bargeQuantityInMt: bg.bargeQuantityInMt, purchasingTerms: res.data.purchasingTerms, coalSupplierName: res.data.bpName, freightPricePmt: res.data.coalPricePMT, cmBgTransactionId: bg.cmBgTransactionId, incoterm: res.data.incoterm, qualityGroupId: bg.qualityGroupId, baseCurrency: res.data.baseCurrency }
  // bargeData.push(bgDs);

  const getAllCommercialData = async (id: string) => {
    const req = new PerFormaReqIdDto(
      id,
      contractType,
      authContext.defaultPlant,
    );
    if (costingType === CostingTypesEnum.COMMERCIAL) {
      cmService
        .getAllCommercialCostingsData(req)
        .then((res) => {
          if (res.status) {
            setInitialValues(res.data);
            const req: any = new CalculationFilterDataReq(
              authContext.defaultPlant,
              res.data.businessNumber,
              res.data.contractId,
              res.data.qualityId,
              res.data.incoterm,
              res.data.purchaseType,
              contractType,
              res.data.pcrContractId,
            );
            // formRef.setFieldValue('taxId1', res)
            // sales commercial
            setCostingCurrency(res.data.quoteCurrency);
            formRef.setFieldValue('incoterm', res.data?.incoterm);
            formRef.setFieldValue(
              'includeFreight',
              res.data?.includeFreight ? true : false,
            );

            formRef.setFieldValue(
              'includeFreight',
              res.data?.includeFreight ? true : false,
            );

            setTotalBargeQuantity(Number(res.data.totalBargeQuantity));
            formRef.setFieldValue(
              'totalBargeQuantity',
              Number(res.data.totalBargeQuantity),
            );
            qualityOnChange(Number(res.data.totalBargeQuantity));
            setAdjustedData(res.data.advanceTarnsactionsData);
            const advTotal = Number(
              res.data.advanceTarnsactionsData
                .reduce((a, c) => a + Number(c.totalAdjustedAmount), 0)
                .toFixed(2),
            ).toLocaleString();
            formRef.setFieldValue('totalAdjustedValue', advTotal);
            if (res.data.incoterm === ShippingTypes.FOB_BARGE) {
              cmService
                .getBargesDataForSalesCommercial(req)
                .then((response) => {
                  if (res.status) {
                    const bargeId = [];
                    const bargesData = [];
                    for (const bg of res.data.transactionBarges) {
                      bargeId.push(bg.bgUId);
                      const bgD = response.data.filter(
                        (rec) => rec.bgUId === bg.bgUId,
                      )[0];
                      bargesData.push(bgD);
                    }
                    rowSelection.onChange(bargeId, bargesData);
                    setBargeData(response.data);
                  } else {
                    setBargeData([]);
                  }
                })
                .catch((err) => {
                  console.log(err.message);
                  setBargeData([]);
                });
            } else {
              sales
                .getPerformaNonSalesFOBBargeData(req)
                .then((response: any) => {
                  if (response.status) {
                    setBargeData(response.data);
                    const {
                      inWardQty,
                      adjustedPriceValue,
                      cmBgTransactionId,
                      baseCurrency,
                      quoteCurrency,
                    } = res.data?.transactionBarges[0] || {};
                    const bargeData = response.data?.map((item) => ({
                      ...item,
                      bargeQuantityInMt: inWardQty,
                      coalBasePrice: adjustedPriceValue,
                      cmBgTransactionId: cmBgTransactionId,
                      baseCurrency,
                      quoteCurrency,
                    }));
                    rowSelection.onChange([undefined], bargeData);
                  } else {
                    setBargeData([]);
                  }
                })
                .catch((err) => {
                  console.log('error: ' + err.message);
                  setBargeData([]);
                });
            }

            setTaxTotal((prev) => {
              return {
                ...prev,
                totalCoalPrice:
                  Number(res.data?.totalBargeQuantity) *
                  (res.data.exchangeRateValue
                    ? Number(res.data.exchangeRateValue)
                    : 1),
                includeFreight: res.data?.includeFreight ? true : false,
                freightPricePmt: res.data?.freightPricePmt,
              };
            });

            // setTotalBargeQuantity(Number(res.data.totalBargeQuantity.toFixed(2)));
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
            setInitialValues(null);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setInitialValues(null);
        });
    } else {
      await performaCostingsService
        .getAllPerFormaData(req)
        .then((res) => {
          if (res.status) {
            setInitialValues(res.data);
            setTaxTotal((prev) => ({
              ...prev,
              includeFreight: res.data?.includeFreight ? true : false,
              cmCostingId: res.data.costingId,
              freightPricePmt: res.data?.freightPricePmt,
            }));
            formRef.setFieldValue(
              'includeFreight',
              res.data?.includeFreight ? true : false,
            );
            const req: any = new CalculationFilterDataReq(
              authContext.defaultPlant,
              res.data.businessNumber,
              res.data.contractId,
              res.data.qualityId,
              res.data.incoterm,
              res.data.purchaseType,
              contractType,
            );

            sales
              .getPerformaNonSalesFOBBargeData(req)
              .then((response: any) => {
                if (response.status) {
                  setBargeData(response.data);
                  const {
                    inWardQty,
                    coalPricePerMt,
                    perFormaTransactionId,
                    baseCurrency,
                    quoteCurrency,
                  } = res.data?.costingTransactions[0] || {};
                  const bargeData = response.data?.map((item) => ({
                    ...item,
                    bargeQuantityInMt: inWardQty,
                    coalBasePrice: coalPricePerMt,
                    cmBgTransactionId: perFormaTransactionId,
                    baseCurrency,
                    quoteCurrency,
                  }));
                  rowSelection.onChange([undefined], bargeData);
                } else {
                  setBargeData([]);
                }
              })
              .catch((err) => {
                console.log('error: ' + err.message);
                setBargeData([]);
              });

            setTotalBargeQuantity(
              Number(res.data?.costingTransactions[0]?.inWardQty),
            );
            setCostingCurrency(res.data.billingCurrency);
            formRef.setFieldValue('portType', res.data?.portType);
            setAdjustedData(res.data.advanceTarnsactionsData);
            const advTotal = Number(
              res.data.advanceTarnsactionsData
                .reduce((a, c) => a + Number(c.totalAdjustedAmount), 0)
                .toFixed(2),
            ).toLocaleString();
            formRef.setFieldValue('totalAdjustedValue', advTotal);
          } else {
            console.log(res.internalMessage);
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  const getInvoiceContracts = (req: AdvanceTypeReqDto, incoterm: string) => {
    if (req?.bargeId?.length) {
      cmService
        .getInvoiceContracts(req)
        .then((res) => {
          if (res.status) {
            setInvoiceContracts(res.data);
            if (incoterm === 'FOB BARGE') {
              setPerformaAdvance(res.data);
            }
          } else {
            // AlertMessages.getErrorMessage(res.internalMessage)
            setInvoiceContracts([]);
            setPerformaAdvance([]);
          }
        })
        .catch((err) => console.log(err.message));
    } else {
      formRef.setFieldValue('advanceType', undefined);
      formRef.setFieldValue('invoiceContractId', undefined);
      formRef.setFieldValue('advanceBalance', undefined);
      formRef.setFieldValue('advanceToAdjust', undefined);
    }
  };

  const rowSelection = {
    columnTitle: <></>,
    onChange: (selectedRowKeys: string[], selectedRows: any[]) => {
      setBargeSelection(selectedRows);
      setBargeSelectionRowKeys(selectedRowKeys);
      let sum: number = 0;
      for (const t of selectedRows) {
        sum += Number(t.bargeQuantityInMt);
      }
      const req = new AdvanceTypeReqDto(
        authContext.defaultPlant,
        AdvanceEnumType.Against_Performa_Invoice,
        formRef.getFieldValue('contractId'),
        selectedRowKeys,
        formRef.getFieldValue('purchaseType'),
        formRef.getFieldValue('qualityId'),
        bargeSelection[0]?.coalSupplierId,
      );
      getInvoiceContracts(req, selectedRows[0]?.incoterm);
      setTotalBargeQuantity(Number(sum.toFixed(3)));
      formRef.setFieldValue(
        'adjustedPriceValue',
        selectedRows[0]?.coalBasePrice,
      );
      setTaxTotal((prev) => ({
        ...prev,
        freightPricePmt: selectedRows[0]?.freightPricePmt,
      }));
    },
    getCheckboxProps: (record) => {
      if (paramsId || cmCostingId) {
        record.isBargeMapped = false;
      }
      if (bargeSelection[0]?.qualityGroupId || record.isBargeMapped) {
        if (
          bargeSelection[0]?.qualityGroupId !== record?.qualityGroupId ||
          record.isBargeMapped
        ) {
          return { disabled: true };
        }
      }

      return {};
    },
    selectedRowKeys: bargeSelectionRowKeys,
  };

  const submitHandler = () => {
    formRef.validateFields().then((values) => {
      const gcvAdjustment = formRef.getFieldValue('gcvAdjustment');
      const ashPriceAdjustment = formRef.getFieldValue('ashPriceAdjustment');
      const tsPriceAdjustment = formRef.getFieldValue('tsPriceAdjustment');
      const totalPenalty = formRef.getFieldValue('totalBonusPrice');
      const tsRejection = formRef.getFieldValue('tsRejection');
      const ashRejection = formRef.getFieldValue('ashRejection');
      const gcvRejection = formRef.getFieldValue('gcvRejection');
      const barges = [];
      for (const bg of bargeSelection) {
        barges.push({
          bargeId: bg.bgUId,
          inWardQty: totalBargeQuantity,
          coalPricePerMt: values?.adjustedPriceValue,
          baseCurrency: values.baseCurrency,
          costingCurrency: values.costingCurrency,
          quoteCurrency: authContext.defaultPlantCurrency,
          exchangeRate: values.exchangeRate,
          exchangeDate: values.exchangeDate,
          total: taxTotal.invoiceTotal,
          qualityGroupId: bg.qualityGroupId,
          plantCode: authContext.defaultPlant,
          userName: authContext.user.userName,
          cmBgTransactionId: bg.cmBgTransactionId || bg.perFormaTransactionId,
          purchasingTerms: bg.purchasingTerms ? bg.purchasingTerms : '',
        });
      }
      cmService
        .saveCommercialCostings({
          ...values,
          // qulity adjustmentData
          gcvAdjustment,
          ashPriceAdjustment,
          tsPriceAdjustment,
          totalPenalty,
          tsRejection,
          ashRejection,
          gcvRejection,
          ...taxTotal,
          freightId: initialValues?.freightId,
          totalAdjustedValue: getNumberFromLocalString(
            values?.totalAdjustedValue,
          ),
          costingInvoiceTotal: getNumberFromLocalString(taxTotal?.invoiceTotal),
          remainingBalance: getNumberFromLocalString(values?.remainingBalance),
          costingType: costingType,
          transactionBarges: barges,
          plantCode: authContext.defaultPlant,
          userName: authContext.user.userName,
          quoteCurrency: authContext.defaultPlantCurrency,
          coalPricePMT: bargeSelection[0]?.freightPricePmt,
          contractType: contractType,
          incoterm: bargeSelection[0]?.incoterm || initialValues?.incoterm,
          costingNo: initialValues?.costingNo,
          adjustmentTransactions: adjustedData,
          vendorId: bargeSelection[0]?.coalSupplierId,
        })
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        });
    });
  };

  const qualityOnChange = (e: number) => {
    setQualityFieldValue(Number(e));
  };

  const getCurrency = () => {
    currencyService
      .getAllCurrenciesForDropDown()
      .then((res) => {
        if (res.status) {
          setCurrencies(res.data);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const currencyOnChangeHandler = (value) => {
    setCostingCurrency(value);
  };

  return (
    <>
      <Card className="card-radius">
              <Card
                className="default-card-class"
                title={`${costingType} Costings ${
                  contractType === ContractModesEnum.PURCHASE
                    ? '- Purchase Contract'
                    : contractType === ContractModesEnum.SALES
                    ? '- Sales Contract'
                    : ''
                } `}
                // extra={
                //   <>
                //     {viewPermission && (
                //       <Button
                //         onClick={() => {
                //           setGridDrawer(true);
                //           setDummyRefresh(dummyRefresh + 1);
                //         }}
                //         className="panel_button"
                //       >
                //         View
                //       </Button>
                //     )}
                //   </>
                // }
              >
                <Row>
                  <Col span={16}>
                    <Form form={formRef} layout="vertical" autoComplete="off">
                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }}>
                        <Form.Item
                          name="costingCurrency"
                          label="Select Costing Currency"
                          rules={[
                            {
                              required: true,
                              message: 'Please Select Costing Currency',
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            showSearch
                            placeholder={'Costing Currency'}
                            onChange={currencyOnChangeHandler}
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toString()
                                .toLocaleLowerCase()
                                .includes(input.toLocaleLowerCase())
                            }
                          >
                            {currencies?.map((rec) => {
                              return (
                                <Select.Option
                                  value={rec.currencyCode}
                                  key={rec.currencyCode}
                                >
                                  {rec.currencyCode}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Form>
                  </Col>

                  <Col span={24}>
                    {createPermission && (
                      <CmSaleFilterComponent
                        key={cmCostingId}
                        formRef={formRef}
                        loadBargesOnClickHandler={loadBargesOnClickHandler}
                        isBusinessNumberMandatory={
                          contractType === ContractModesEnum.SALES
                        }
                        initialValues={initialValues}
                        contractType={contractType}
                        setBargeData={setBargeData}
                        pcrContractType={pcrContractType}
                      />
                    )}
                  </Col>
                </Row>

                <br></br>
                {bargeData.length !== 0 && (
                  <>
                    <Row>
                      <Col span={24}>
                        <CommercialCostingsBargeSelection
                          key={cmCostingId}
                          bargeGridData={bargeData}
                          rowSelection={rowSelection}
                        />
                      </Col>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                      <Col offset={20}>
                        <Descriptions
                          bordered
                          style={{ width: '200px' }}
                          size="small"
                        >
                          <Descriptions.Item label="Quantity">
                            <Form form={formRef} layout="vertical">
                              <Form.Item name={'totalBargeQuantity'}>
                                <Input
                                  type="number"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    qualityOnChange(Number(e.target.value))
                                  }
                                ></Input>
                              </Form.Item>
                            </Form>
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col span={taxTotal?.includeFreight ? 24 : 12}>
                        <Card
                          className="default-card-class"
                          title={'Advance Adjustment'}
                        >
                          <CmSaleAdvanceAdjustments
                            key={cmCostingId}
                            bargeSelectionRowKeys={bargeSelectionRowKeys}
                            formRef={formRef}
                            vendorId={bargeSelection[0]?.coalSupplierId}
                            contractId={bargeSelection[0]?.contractId}
                            totalInvoice={Number(taxTotal?.invoiceTotal)}
                            performaAdvance={performaAdvance}
                            setAdjustedData={setAdjustedData}
                            adjustedData={adjustedData}
                            setInvoiceContracts={setInvoiceContracts}
                            invoiceContracts={invoiceContracts}
                            costingType={costingType}
                          />
                        </Card>
                      </Col>
                      <Col span={taxTotal?.includeFreight ? 24 : 12}>
                        <Card
                          className="default-card-class"
                          title={'Price Adjustment'}
                          extra={
                            <>
                              <Button
                                className="panel_button"
                                disabled={
                                  bargeSelection[0]?.purchasingTerms ===
                                  ContractTermEnum.SPEC
                                    ? false
                                    : true
                                }
                                onClick={() => {
                                  setPriceAdjustmentDrawer(true);
                                  setDummyRefresh((prev) => prev + 1);
                                }}
                              >
                                Quality Adjustment
                              </Button>
                            </>
                          }
                        >
                          <CmSalePriceAdjustment
                            key={cmCostingId}
                            contractType={contractType}
                            initialValues={initialValues}
                            taxTotal={taxTotal}
                            setTaxTotal={setTaxTotal}
                            totalBargeQuantity={totalBargeQuantity}
                            formRef={formRef}
                            bargeSelection={bargeSelection[0]}
                            qualityFieldValue={qualityFieldValue}
                            setPriceAdjustmentDrawer={setPriceAdjustmentDrawer}
                            priceAdjustmentDrawer={priceAdjustmentDrawer}
                            dummyRefresh={dummyRefresh}
                            setDummyRefresh={setDummyRefresh}
                            submitHandler={submitHandler}
                            costingCurrency={costingCurrency}
                            costingType={costingType}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}
              </Card>

      </Card>
      <Drawer
        open={gridDrawer}
        onClose={() => setGridDrawer(false)}
        key={dummyRefresh}
        width={window.innerWidth > 768 ? '70%' : '85%'}
      >
        <SavedCostingsGrid
          setPerFormaId={setCmCostingId}
          contractType={contractType}
          costingType={costingType}
          referenceFeatures={referenceFeatures}
          setGridDrawer={setGridDrawer}
          formRef={formRef}
          accessId={accessId}
        />
      </Drawer>
    </>
  );
};

export default CmSalesCostingMainForm;
