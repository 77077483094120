import { Form, Input, Button, Card, Row, Col, Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnchorageCreateDto, AnchorageService, AnchorageUpdateDto, CountryDropDownDto, CountryService, DestinationService } from '@exportx/shared-models-and-services';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../../common';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';


const { Option } = Select;
const AnchorageForm = () => {
  const pathToreDirect = '/destination-view'

  const navigate = useNavigate();
  const { authContext } = useAuthState();
  const { formatMessage: fm } = useIntl();
  const [formRef] = Form.useForm();
  const [, setSelectedCountry] = useState<string>(null);
  const { state }: any = useLocation();
  const { isUpdate, anchorageData, hidden } = state ? state : { isUpdate: false, anchorageData: null, hidden: null };
  const service = new AnchorageService();
  const destinationService = new DestinationService()
  const [destination, setDestination] = useState<any[]>()
  const [countryData, setCountryData] = useState<CountryDropDownDto[]>([]);
  const countryService = new CountryService();
  const isNewRecord = !anchorageData;

  useEffect(()=>{
    const accessId = {
      create: [66],
      view: [67],
      edit: [68]
    }
    const hasPermission = isNewRecord ? accessId.create : isUpdate ? accessId.edit : accessId.view;

    if(!isPermissionExist(hasPermission)){
      return navigate('/')
    }

  },[])

  useEffect(() => {
    destinationDropDown();
    getAllActiveCountries()
  }, [])


  const submitFormHandler = () => {
    formRef.validateFields().then(values => {
      submitForm(values);
    })
  }
  const destinationDropDown = () => {
    destinationService.getDestinationDropDown().then(res => {
      if (res.status) {
        setDestination(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }
  const getAllActiveCountries = () => {
    countryService.getAllCountryDropDownDto().then(res => {
      if (res.status) {
        setCountryData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const clearData = () => {
    formRef.resetFields();
  }
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  const handleCountry = (value) => {
    setSelectedCountry(value);
  }

  const submitForm = (values: any) => {

    if (isUpdate && anchorageData?.anchorageId) {
      update(values);
      return;
    }

    const createDto: AnchorageCreateDto = new AnchorageCreateDto(values.anchorageName, authContext.user.userName,
      // values.port,
      values.country);
    service.create(createDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(`Anchorage Created SuccessFully`);
        setTimeout(() => {
          navigate(pathToreDirect, {state: {activeTab: 'anchorage'}});
        }, 1000);
      }
      else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }

    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const update = (values: any) => {
    const updateDto: AnchorageUpdateDto = new AnchorageUpdateDto(values.anchorageId, values.anchorageName,
      // values.port,
      values.country, authContext.user.userName, anchorageData.versionFlag);
    service.update(updateDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(`Anchorage Updated SuccessFully`);
        setTimeout(() => {
          navigate(pathToreDirect, {state: {activeTab: 'anchorage'}});
        }, 1000);
      }
      else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  console.log(anchorageData)
  console.log(state)
  return (
    <div>
      <Card title={<span>Anchorage Details</span>}
        className='default-card-class'
        extra={<span ><Button onClick={() => navigate(pathToreDirect, {state: {activeTab: 'anchorage'}})}>View</Button></span>}>
        <Form layout="vertical" form={formRef} initialValues={anchorageData} autoComplete="off">
          <Row>
            <Form.Item name="anchorageId" hidden><Input hidden placeholder="Enter Anchorage Name" type="text" style={{ width: "70%" }} />
            </Form.Item>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item label="Anchorage Name" rules={[{ required: true, },]}
                name="anchorageName"><Input placeholder="Enter Anchorage Name" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="country"
                label="Country Name"
                rules={[
                  {
                    required: true,
                    message: 'Country Name is Required'
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: `Enter the Country Name`
                  }
                ]}
              >
                <Select
                  showSearch
                  //style={{ width: 200 }}
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  placeholder="Select Country"
                  optionFilterProp="children"
                  onChange={handleCountry}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                >
                  {countryData.map((country) => {
                    return <Option key={country.countryId} value={country.countryId}>{country.countryName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item label="Province" rules={[{ required: true, },]}
                name="port">
                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={"Please Select Port"} >
                  {destination?.map((rec) => {
                    return <Option value={rec.destinationId}>{rec.destinationName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col> */}



            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="button" style={{ display: hidden ? 'none' : 'block', margin: '0 14px' }} onClick={() => { clearData(); }} >
                Clear
              </Button>
              <Button type="primary" onClick={submitFormHandler} style={{ display: hidden ? 'none' : 'block' }}>
                Submit
              </Button>
            </Col>

          </Row>
        </Form>
      </Card>
    </div>
  )

}
export default AnchorageForm






