import { Card, Col, Form, FormInstance, Input, InputNumber, Radio, Row, Select, } from 'antd';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { BpCategoryGetDto, BPDropDownDto, BusinessPartnerService, BusinessPartnerTypeEnum, CommodityDropDownDto, CommodityEnum, CommodityService, ContractModesEnum, CurrencyService } from '@exportx/shared-models-and-services';
import { FormLayout } from 'antd/lib/form/Form';
import moment from 'moment';
import DatePicker from '../../../common/data-picker/date-picker';


const { Option } = Select;
interface IContractDetailProps {
	formRef: FormInstance<any>;
	initialValues: any;
	children?: React.ReactNode;
	layoutType: FormLayout;
	contractType: ContractModesEnum
}
export const ContractDetail = (props: IContractDetailProps) => {
	const [currencies, setCurrencies] = useState<any[]>([]);
	const [vendorsData, setVendorsData] = useState<BPDropDownDto[]>([]);
	const [facilitatorsData, setFacilitatorsData] = useState<BPDropDownDto[]>([]);
	const [commodityData, setCommodityData] = useState<CommodityDropDownDto[]>([]);
	const [mineData, setMineData] = useState<BPDropDownDto[]>([]);
	const currencyService = new CurrencyService();
	const businessPartnerService = new BusinessPartnerService();
	const commodityService = new CommodityService();
	const { formatMessage: fm } = useIntl();
	const { formRef, layoutType, contractType } = props;
	const [initialValues, setInitialValues] = useState();
	let baseIntlPath = '';
	if (contractType === ContractModesEnum.PURCHASE) {
		baseIntlPath = 'mdm.components.contractDetailsForm.purchase';
	} else {
		baseIntlPath = 'mdm.components.contractDetailsForm.sales';
	}

	useEffect(() => {
		if (props.initialValues?.pcId) {
			let valesToSet = { ...props.initialValues };
			valesToSet.contractDate = moment(props.initialValues.contractDate);
			setInitialValues(valesToSet);
		} else {
			setInitialValues(props.initialValues);
		}
	}, [props.initialValues]);

	useEffect(() => {
		formRef.resetFields();
	}, [initialValues])



	useEffect(() => {
		getCurrencyData();
		if (contractType === ContractModesEnum.PURCHASE) {
			getAllMinesDropDown();
		} else {
			getAllBusinessPartnersForDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.CUSTOMER));
		}
		getAllBusinessPartnersForDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.FACILITATOR));
		getCommodityData(CommodityEnum.COAL);
		getAllMinesDropDow();
	}, []);

	const getAllBusinessPartnersForDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
		businessPartnerService.getAllBusinessPartnersForDropDown(bpCategoryGetDto).then(res => {
			if (res.status) {
				if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.VENDOR || bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.CUSTOMER)
					setVendorsData(res.data);
				else if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.FACILITATOR)
					setFacilitatorsData(res.data)
			}
		}).catch(err => {
			console.log('error: ' + err.message);
		})
	}


	const getAllMinesDropDown = () => {
		businessPartnerService.getAllMinesDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)).then(res => {
			if (res.status) {
				setVendorsData(res.data);
			}
		}).catch(err => {
			console.log('error: ' + err.message);
		})
	}

	const getAllMinesDropDow = () => {
		businessPartnerService.getAllMinesDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)).then(res => {
			if (res.status) {
				setMineData(res.data);
			}
		}).catch(err => {
			console.log('error: ' + err.message);
		})
	}

	const getCommodityData = (req: any) => {
		commodityService.getAllCommodityDropDown({ category: req }).then(res => {
			if (res.status) {
				formRef.setFieldValue("commodity", res.data[0]?.commodityId)
				setCommodityData(res.data);
			}
		}).catch(err => {
			console.log('error: ' + err.message);
		})
	}

	const getCurrencyData = () => {
		currencyService.getAllCurrenciesForDropDown().then(res => {
			if (res.status) {
				setCurrencies(res.data);
			}
		}).catch(err => {
			console.log('error: ' + err.message);
		})
	}
	const setValues = (name: string, value: string, key: string, valueKey: string, values: any[]) => {
		if (value) {
			const labelToBeSet = values.find(el => el[key] === value);
			formRef.setFieldValue(name, labelToBeSet[valueKey]);
		}

	}

	let requiredMessage = '';
	if (contractType === ContractModesEnum.PURCHASE) {
		requiredMessage = "Please Select Vendor"
	} else {
		requiredMessage = "Please Select Customer"
	}


	return (
		<div>
			<Card
				title={fm({ id: `${baseIntlPath}.header`, defaultMessage: `Contract Details` })}>
				<Form
					initialValues={initialValues}
					form={formRef}
					layout={layoutType}
					autoComplete="off"
					key={Date.now()}
				>
					<Form.Item name={"contractNo"} style={{ display: 'none' }}>
						<Input hidden />
					</Form.Item>
					<Form.Item name={"businessPartnerName"} style={{ display: 'none' }}>
						<Input hidden />
					</Form.Item>
					<Form.Item name={"currencyName"} style={{ display: 'none' }}>
						<Input hidden />
					</Form.Item>
					<Form.Item name={"facilitatorName"} style={{ display: 'none' }}>
						<Input hidden />
					</Form.Item>
					<Form.Item name={"commodityName"} style={{ display: 'none' }}>
						<Input hidden />
					</Form.Item>
					<Row>
						<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }} >
							<Form.Item
								label={fm({ id: `${baseIntlPath}.vendor`, defaultMessage: "Vendor" })}
								name="businessPartner"
								rules={[
									{
										required: true, message: `${requiredMessage}`
									}
								]}
							>
								<Select
									onChange={(value) => setValues('businessPartnerName', value, 'bpId', 'bpName', vendorsData)}
									placeholder={fm({ id: `${baseIntlPath}.vendorReq`, defaultMessage: "Please  Select Vendor" })}
									allowClear
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
								>

									{React.Children.toArray(vendorsData.map(item => {
										return <Option value={item.bpId} title={item.bpName}>{item.bpName}</Option>
									}))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }} >
							<Form.Item
								label={fm({ id: `${baseIntlPath}.facilitator`, defaultMessage: "Facilitator" })}
								name="facilitator"
								rules={[
									{
										required: false, message: fm({ id: `${baseIntlPath}.facilitatorReq` })
									}
								]}
							>
								<Select
									onChange={(value) => setValues('facilitatorName', value, 'bpId', 'bpName', mineData)}
									placeholder={fm({ id: `${baseIntlPath}.facilitatorReq`, defaultMessage: "Please Select Facilitator" })}
									allowClear
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
								>
									<Option value={''}>Please Select</Option>
									{React.Children.toArray(mineData.map(item => {
										return <Option value={item.bpId}>{item.bpName}</Option>
									}))}
								</Select>
							</Form.Item>
						</Col>

						<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }} >
							<Form.Item
								label={fm({ id: `${baseIntlPath}.bPRefNumber`, defaultMessage: "Supplier Reference Number" })}
								name="bPRefNumber"
							>
								<Input placeholder={fm({ id: `${baseIntlPath}.bPRefNumberReq`, defaultMessage: "Please Enter Supplier Reference Number" })} />
							</Form.Item>
						</Col>

					</Row>
					<Row>
						<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }}  >
							<Form.Item
								label={fm({ id: `${baseIntlPath}.contractDate`, defaultMessage: "Contract Date" })}
								name="contractDate"
								rules={[
									{
										required: true, message: fm({ id: `${baseIntlPath}.contractDateReq` })
									}
								]}
							>
								<DatePicker style={{ width: "100%" }} placeholder={fm({ id: `${baseIntlPath}.contractDateReq`, defaultMessage: "Please Select Contract Date" })} format="YYYY-MM-DD" />

							</Form.Item>
						</Col>

						<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }}  >
							<Form.Item
								label={fm({ id: `${baseIntlPath}.commodity`, defaultMessage: "Commodity" })}
								name="commodity"
								rules={[
									{
										required: true, message: fm({ id: "mdm.components.contractDetailsForm.commodityReq", defaultMessage: "Please Select Commodity" })
									}
								]}
							>
								<Select
									onChange={(value) => setValues('commodityName', value, 'commodityId', 'commodityName', commodityData)}
									placeholder={fm({ id: `${baseIntlPath}.commodityReq`, defaultMessage: "Please Select Commodity" })}
									allowClear
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>

									<Option value={''}>Please Select</Option>
									{React.Children.toArray(commodityData.map(item => {
										return <Option value={item.commodityId}>{item.commodityName}</Option>
									}))}
								</Select>

							</Form.Item>
						</Col>




						<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }} >
							<Form.Item
								label={fm({ id: `${baseIntlPath}.currency`, defaultMessage: "Currency" })}
								name="currency"
								rules={[
									{
										required: true, message: fm({ id: "mdm.components.contractDetailsForm.currencyReq", defaultMessage: "Please Select Currency" })
									}
								]}

							>
								<Select
									onChange={(value) => setValues('currencyName', value, 'currencyCode', 'currencyName', currencies)}
									placeholder={fm({ id: `${baseIntlPath}.currencyReq`, defaultMessage: "Please Select Currency" })}
									allowClear
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
								>
									<Option value={''}>Please Select</Option>
									{React.Children.toArray(currencies.map(item => {
										return <Option value={item.currencyCode}>{item.currencyName}</Option>
									}))}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<Row>
						<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }}  >
							<Form.Item
								label={fm({ id: `${baseIntlPath}.totalQty`, defaultMessage: "Total Quantity" })}
								name='totalQty'
								rules={[
									{
										required: true, message: fm({ id: `${baseIntlPath}.totalQtyReq`, defaultMessage: "Please Enter Quantity" })
									},
									{
										pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
										message: `Only Number & Decimals`
									}

								]}
							>
								<InputNumber placeholder={fm({ id: `${baseIntlPath}.totalQtyReq`, defaultMessage: " Please Enter Quantity" })} style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>

						<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }}  >
							<Form.Item
								label={fm({ id: `${baseIntlPath}.contractLtSpot`, defaultMessage: "Contract Type" })}
								name="contractLtSpot"
								rules={[
									{
										required: true, message: fm({ id: `${baseIntlPath}.contractTypeReq` })
									}
								]}
							>
								<Radio.Group>
									<Radio.Button value='Long Term'>Long Term</Radio.Button>
									<Radio.Button value='Spot'>Spot</Radio.Button>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>


					{/* <Form.List name="layCanData">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }, index) => (
									<Row>
										<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }} >
											<Form.Item
												label={fm({ id: `${baseIntlPath}.layCan`, defaultMessage: "LayCan" })}
												name={[name, "layCan"]}
												rules={[
													{
														required: false, message: fm({ id: `${baseIntlPath}.layCanReq` })
													}
												]}
											>
												<RangePicker />
											</Form.Item>
										</Col>
										<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }} >
											<Form.Item
												label={fm({ id: `${baseIntlPath}.quantityInMt`, defaultMessage: "Quantity In (MT)" })}
												name={[name, "quantityInMT"]}
											>
												<Input type="number" placeholder={fm({ id: `${baseIntlPath}.quantityInMtReq`, defaultMessage: "Please Enter Quantity In (MT)" })} />
											</Form.Item>
										</Col>
										<Col xs={{ span: 2 }}
											sm={{ span: 2 }}
											md={{ span: 2 }}
											lg={{ span: 2 }}
											xl={{ span: 2 }}>
											{index != 0 &&
												<span style={{
													position: "absolute",
													marginTop: "33px",
													marginLeft: "35px",
												}}>
													<Tooltip placement="top" title={fm({ id: "common.delete" })}>
														<DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(name)} />
													</Tooltip>
												</span>
											}
											{(fields.length - 1 === index && fields.length < 5) && <span style={{
												position: "absolute",
												marginTop: "33px",
												marginLeft: "15px"
											}}>
												<Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
													<PlusCircleOutlined onClick={() => add()} />
												</Tooltip>
											</span>}
										</Col>
									</Row>
								))}

								<Form.Item
								>

								</Form.Item>
							</>
						)}
					</Form.List> */}
				</Form>
				{props?.children}
			</Card>
		</div >
	)
}

export default ContractDetail;