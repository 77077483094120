import { AddendumDto, ContractDetailsDataDto, ContractModesEnum, ContractTypesEnum, PurchaseContractDto, PurchaseShippingTermsDto } from '@exportx/shared-models-and-services';
import { Card, Col, Descriptions, Progress, Row, Space, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { StatusTag } from '../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
interface IContractDetailsViewProps {
  contractDetails: ContractDetailsDataDto;
  shippingTerms: PurchaseShippingTermsDto;
  contractMode: ContractModesEnum;
  addendumData: AddendumDto[];
  plantCode: string;
  snapShotData: PurchaseContractDto[];
  contractType: ContractTypesEnum;
  allocateData?: any;
}
export const ContractDetailsView = (props: IContractDetailsViewProps) => {
  const { contractDetails, addendumData, shippingTerms, contractMode, contractType, plantCode, snapShotData, allocateData } = props;
  const link = `/#/detailCustomer?bp_id=${contractDetails?.businessPartner}`;
  const PcBaseContract = `/#/po-detail-view?pc_id=${contractDetails?.baseContractId}`;
  const ScBaseContract = `/#/so-detail-view?sc_id=${contractDetails?.baseContractId}`;
  let pathToreDirectToSale = '/#/so-detail-view';
  const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
    for (const snapData of snapShotData) {
      let valueAtGivenKey: any = snapData;
      for (const key of keys) {
        valueAtGivenKey = valueAtGivenKey?.[key];
      }
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (contractType === ContractTypesEnum.ADDENDUM) {
          if (isRawData) {
            return value;
          } else {
            return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapData.contractDetails.contractNo.replace('SNP', 'ADD')}><b>
              {value}
            </b></Tooltip></>
          }
        }
      }
    };
    return value
  }

  let totalDelivered = 0;

  allocateData?.allocationData?.forEach(e => {
    let type = e.salesTypes ? e.salesTypes : e.purchaseTypes
    type?.forEach(item => {
      totalDelivered += parseFloat(item.totalDelivered);
    });
  });

  totalDelivered += Number(allocateData?.unAssignedBargeQty);

  const Balance = Number(contractDetails?.totalQty) - Number(totalDelivered);

  const percent = (Number(totalDelivered) / (Number(contractDetails?.totalQty) || 1)) * 100;

  return (
    <Row
      gutter={[16, 12]}
    >
      <Col span={addendumData?.length > 0 ? 14 : 24} style={{ display: 'flex' }}
        xs={24}
        md={24}
        lg={addendumData?.length > 0 ? 14 : 24}
      >
        <Card title={'Contract Header Details'} styles={{header: { fontSize: '16px', fontWeight: 600, color: '#323F49', border: 'none', paddingTop: '10px' }}} bordered={false} style={{ width: '100%', borderRadius: '8px' }}>
          <Row>
            <Col span={12}
              xs={24}
              md={24}
              lg={12}
            >
              <Descriptions
                className='descriptions-label'
                title=''
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                layout='vertical'
                colon={false}
              >
                <Descriptions.Item label={contractMode === ContractModesEnum.SALES ? "Customer" : "Vendor"}>
                  <span className='descriptions'><a style={{ color: '#0083C9' }} href={link}>{valiDateWithSnapShotData(['contractDetails', 'businessPartnerName'], contractDetails?.businessPartnerName, false)}</a></span>
                </Descriptions.Item>
                <Descriptions.Item label="Facilitator">
                  <span className='descriptions'>{valiDateWithSnapShotData(['contractDetails', 'facilitatorName'], contractDetails?.facilitatorName, false)}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Commodity" span={2}>
                  <span className='descriptions'>{valiDateWithSnapShotData(['contractDetails', 'commodityName'], contractDetails?.commodityName, false)}</span>
                </Descriptions.Item>

                <Descriptions.Item label="Geared Loading Rate " >
                  <span className='descriptions'>{valiDateWithSnapShotData(['shippingTerms', 'loadingRateForGeared'], shippingTerms?.loadingRateForGeared, false)}</span>
                </Descriptions.Item>

                <Descriptions.Item label="Gearless Loading Rate " >
                  <span className='descriptions'>{valiDateWithSnapShotData(['shippingTerms', 'loadingRateForGearless'], shippingTerms?.loadingRateForGearless, false)}</span>
                </Descriptions.Item>

              </Descriptions>

            </Col>
            {/* <Col span={(addendumData && addendumData.length != 0) ? 20 : 24} style={{border:'1px solid blue'}}> */}
            <Col span={12}
              xs={24}
              md={24}
              lg={12}
            >
              <Descriptions
                title=''
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                layout='vertical'
                colon={false}
              >
                <Descriptions.Item label="Contract Date">
                  <span className='descriptions'>{moment(valiDateWithSnapShotData(['contractDetails', 'contractDate'], contractDetails?.contractDate, true)).format('DD-MMM-YYYY')}</span>
                </Descriptions.Item>

                <Descriptions.Item label="Currency">
                  <span className='descriptions' style={{ backgroundColor: '#D9F1FD', padding: '2px 6px', borderRadius: '20px' }}>{valiDateWithSnapShotData(['contractDetails', 'currency'], contractDetails?.currency, false)}</span>
                </Descriptions.Item>

              </Descriptions>

              {contractType === ContractTypesEnum.ADDENDUM ?
                <Descriptions
                  layout='vertical'
                  colon={false}
                >
                  <Descriptions.Item label="Base Contract">
                    <span className='descriptions'><a style={{ color: '#0083C9' }} href={contractMode === ContractModesEnum.PURCHASE ? PcBaseContract : ScBaseContract}>{valiDateWithSnapShotData(['contractDetails', 'baseContractNo'], contractDetails?.baseContractNo, false)}</a></span>
                  </Descriptions.Item>
                </Descriptions> :
                <><br /><Card
                  bordered={false}
                  styles={{header: { border: 'none' }}}
                  style={{ backgroundColor: '#F2F7FD', borderRadius: '4px' }}
                >
                  <Row justify="space-between" >
                    <Col style={{ fontSize: '14px', fontWeight: 600 }} >
                      Total Quantity
                    </Col>
                    <Col style={{ fontSize: '12px', fontWeight: 600 }}>
                      {Math.round(Number(valiDateWithSnapShotData(['contractDetails', 'totalQty'], contractDetails?.totalQty, false))) || 0} MT
                    </Col>
                  </Row>
                  <Progress
                    style={{ borderRadius: '10px', marginTop: '5px' }}
                    strokeLinecap="square"
                    percent={percent}
                    showInfo={false}
                    size={{ height: 17 }}
                    strokeColor='#0083C9'
                    trailColor='#D9F1FD'
                  />
                  <Row justify={'space-between'}>
                    <Col>
                      <FontAwesomeIcon icon={faCircle} color="#0083C9" />
                      <span style={{ marginLeft: '4px' }}>Delivered</span>
                    </Col>
                    <Col>
                      <span style={{ fontSize: '12px', fontWeight: 600 }}>{parseFloat(totalDelivered.toFixed(3))} MT</span>
                    </Col>
                    <Col>
                      <FontAwesomeIcon icon={faCircle} color="#D9F1FD" />
                      <span style={{ marginLeft: '4px' }}>Balance</span>
                    </Col>
                    <Col>
                      <span style={{ fontSize: '12px', fontWeight: 600 }}>{parseFloat(Balance.toFixed(3))} MT</span>
                    </Col>
                  </Row>
                </Card></>}


              {/* <Descriptions.Item label="Vessel Type">
                                {valiDateWithSnapShotData(['shippingTerms', 'vesselType'], shippingTerms?.vesselType, false)}
                            </Descriptions.Item> */}

              {/* <Descriptions.Item span={2} >
                              <Card title="Total Quantity" headStyle={{border: 'none'}} bordered={false}>
                             <Progress
                              strokeLinecap="square"
                              percent={50}
                              showInfo={false}
                              size={[300, 20]}
                              strokeColor={'#0083C9'}
                              trailColor='#D9F1FD'
                               />
                            </Card>
                                {valiDateWithSnapShotData(['contractDetails', 'totalQty'], contractDetails?.totalQty, false)}
                            </Descriptions.Item> */}



              {/* <Descriptions.Item label="Discharge Rate ">
                                {valiDateWithSnapShotData(['shippingTerms', 'dischargeRate'], shippingTerms?.dischargeRate, false)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved By">
                                {contractDetails?.approvedBy}
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved Date">
                                {contractDetails?.approvedOn ? moment(contractDetails?.approvedOn).format('DD-MM-YYYY h:mm A') : ''}
                            </Descriptions.Item> */}
              {/* </Descriptions> */}
            </Col>
            {/* {(addendumData && addendumData.length != 0) && <Col span={4}>
                        <Card title="Addendum Data">
                            {React.Children.toArray(addendumData?.map((addendum, index) => {
                                const link = contractMode === ContractModesEnum.SALES ? `${pathToreDirectToSale}?sc_id=${addendum.addendumContractId}&plantCode=${plantCode}` : `/#/po-detail-view?pc_id=${addendum.addendumContractId}&plantCode=${plantCode}`;
                                return <><Tooltip placement="top" title={addendum.status}>
                                    <a className="link-primary" href={link} >
                                        {addendum.addendumContractNo}
                                    </a>{addendumData.length !== index + 1 && ','}</Tooltip><br /></>
                            }))}
                        </Card>
                    </Col>} */}
          </Row>
        </Card>
      </Col>
      {(addendumData && addendumData.length > 0) &&
        <Col span={10} style={{ display: 'flex' }}
          xs={24}
          md={24}
          lg={10}
        >
          <Card title='Addendums' styles={{header: { fontSize: '16px', fontWeight: 600, color: '#323F49', border: 'none', paddingTop: '10px' }}} bordered={false} style={{ width: '100%', borderRadius: '8px' }}>

            <Row style={{ display: 'flex', gap: '12px', marginBottom: '12px' }}>
              <Card
                bordered={false}
                styles={{header: { border: 'none' }}}
                size="small"
                style={{ borderRadius: '4px', backgroundColor: '#F8F8F8' }}
              >
                <Space size={16}>
                  <Tooltip placement="top" title={addendumData[addendumData.length - 1].status}>
                    <a
                      className='link'
                      href={
                        contractMode === ContractModesEnum.SALES
                          ? `${pathToreDirectToSale}?sc_id=${addendumData[addendumData.length - 1].addendumContractId}&plantCode=${plantCode}`
                          : `/#/po-detail-view?pc_id=${addendumData[addendumData.length - 1].addendumContractId}&plantCode=${plantCode}`
                      }
                    >
                      {addendumData[addendumData.length - 1].addendumContractNo}
                    </a>
                  </Tooltip>
                  <StatusTag status={addendumData[addendumData.length - 1].status} />
                </Space>
              </Card>
            </Row>

            <Row style={{ display: 'flex', gap: '12px' }}>
              {React.Children.toArray(addendumData.slice(0, -1).map((addendum, index) => {
                const link = contractMode === ContractModesEnum.SALES ? `${pathToreDirectToSale}?sc_id=${addendum.addendumContractId}&plantCode=${plantCode}` : `/#/po-detail-view?pc_id=${addendum.addendumContractId}&plantCode=${plantCode}`;
                return (
                  <Card
                    key={index}
                    bordered={false}
                    styles={{header: { border: 'none' }}}
                    size='small'
                    style={{ borderRadius: '4px', backgroundColor: '#F8F8F8' }}
                  >
                    <Space size={16}><Tooltip placement="top" title={addendum.status}>
                      <a className='link' href={link} >
                        {addendum.addendumContractNo}
                      </a>{addendumData.length !== index + 1}</Tooltip>
                      <StatusTag status={addendum.status} />
                    </Space>
                  </Card>
                )
              }))}

            </Row>
          </Card>

        </Col>
      }
    </Row>
  )
}

export default ContractDetailsView;