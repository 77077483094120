import {
  EditOutlined,
  SaveOutlined,
  PlusOutlined,
  DeleteOutlined,
  CloseOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import {
  Table,
  Button,
  Input,
  Select,
  InputNumber,
  Space,
  Popconfirm,
  message,
  AutoComplete,
  Card,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState, useEffect, useRef, Children } from 'react';
import moment from 'moment';
import { BargeService, BPDropDownDto, BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, BusinessPartnerService, ContractIdRequest, CurrencyService, PcPurchaseTypeReq, ProcurementContractService, ProcurementContractTypeEnum, PurchaseContractService, ReportsService, UnitsService } from '@exportx/shared-models-and-services';
import { AlertMessages, useAuthState } from '../../../common';
import { SequenceUtils } from '@exportx/ui-utils';
import { title } from 'process';
import { isPermissionExists } from '../../../common/iam-client-react/permission-checker/permission-checker';
import DatePicker from '../../../common/data-picker/date-picker';
import { uuidv4 } from '@antv/xflow-core';


interface LoadingPlans {
  id: string;
  supplier: string;
  countryParty: string;
  motherVessel: string;
  bgName: string;
  typeOfBarge: string;
  jettyName: string;
  loadingQuantity: string;
  expectedDateOfLoading: string;
  contractNo: string;
  pcLaycan: string;
  pcType: string;
  termsOfPayment: string;
  typeOfPayment: string;
  procurementQuantity: string;
  price: number;
  amount: string;
  pph: string;
  ppn: string;
  advance: string;
  piAdjustment: string;
  netAmount: string;
  finalPayment: string;
  paymentStatus: string;
  expectedDateOfReceipt: string;
  expectedDateOfSettlement: string;
  amountCurrency: string;
  exchangeRate: string;
  priceCurrency: string;
  siQuantity: string;
  contractType: string;
  status: string;
  // baseId?: string;
  // children?: DischargeReport[];
}
const LoadingPlansList = () => {
    const [data, setData] = useState<any[]>([]);
    const [editingKey, setEditingKey] = useState('');
    const [editingRecord, setEditingRecord] = useState<any | null>(null);
    const [bpDropdown, setBpDropdown] = useState<BPDropDownDto[]>();
    const [businessDropDown, setBusinessDropDown] = useState<
    BusinessNoDropDownDto[]
    >([]);
    const [bgDropDown, setBgDropDown] = useState<any[]>([]);
    const [children, setChildren] = useState<any[]>([]);
    const [purchaseDropdown, setPurchaseDropDown] = useState<any[]>([]);
    const [procurement, setProcurement] = useState<any[]>([]);
    const [laycan, setLaycan] = useState([]);
    const [pcType, setPcType] = useState([]);
    // const [prevContracts, setPrevContracts] = useState<Set<string>>(new Set());
    const fetchedContracts = useRef(new Set<string>());
    const [hideExchangeRate, setHideExchangeRate] = useState(false);

    const [loading, setLoading] = useState(false);
    const { authContext } = useAuthState();

    const service = new ReportsService();
    const businessPartner = new BusinessPartnerService();
    const unitService = new UnitsService();
    const businessService = new BusinessNumberService();
    const currencyService = new CurrencyService();
    const bargeService = new BargeService();
    const purchase = new PurchaseContractService();
    const pcService = new ProcurementContractService();

    useEffect(() => {
        getAllBusinessPartnersDropDown();
        getBusinessNoDropDown();
        getBargeDropDown()
        getAllPurchaseContracts()
        procurementDropDown()
        fetchData();
    }, []);

    useEffect(() => {
      if (!editingRecord) {
        fetchedContracts.current.clear();
      }
    }, [editingRecord]);

    const getAllBusinessPartnersDropDown = () => {
        businessPartner
        .getAllBusinessPartnersDropDown()
        .then((res) => {
            if (res.status) {
            setBpDropdown(res.data);
            }
        })
        .catch((err) => {
            console.log('error: ' + err.message);
        });
    };

    const getBusinessNoDropDown = () => {
        const req = new BusinessNoPlantCodeReq(authContext.defaultPlant);
        businessService
        .getBusinessNoDropDown(req)
        .then((res) => {
            if (res.status) {
            setBusinessDropDown(res.data);
            } else {
            console.log(res.internalMessage);
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
    };

    const getBargeDropDown = () => {
        bargeService
        .getAllPlantCodeBarges()
        .then((res) => {
            if (res.status) {
            setBgDropDown(res.data);
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
    };

    const getAllPurchaseContracts = () => {
        purchase
        .getAllPurchaseContractsForDropDown({
            plantCode: authContext.defaultPlant,
        })
        .then((res) => {
            if (res.status) {
            //   let pcDropdown = res.data.map((item: any) => {
            //     return {
            //         contractNo: item.pcNo,
            //         contractId: item.pcId,
            //         ...item,
            //     };
            // });
            setPurchaseDropDown(res.data);
            }
        })
        .catch((err) => {
            console.log('error: ' + err.message);
        });
    };

    const procurementDropDown = () => {
      let pcrContractType = ProcurementContractTypeEnum.Procurement_Fees
      const req = new ContractIdRequest(undefined, authContext.defaultPlant, pcrContractType)
      pcService.procurementDropDown(req).then(res => {
          if (res.status) {
              let pcDropdown = res.data.map((item: any) => {
                let pcNo = item.contractNo.split(' ')[0]
                return {
                    pcNo: pcNo,
                    pcId: item.pcID,
                    ...item,
                };
            });
              setProcurement(pcDropdown);
          } else {
              console.log(res.internalMessage);
          }
      }).catch(err => console.log(err.message));
  }

    const getQualityAndLaycan = (contractId: string) => {
      // Only fetch if we haven't already fetched this contract
      if (contractId && !fetchedContracts.current.has(contractId)) {
        fetchedContracts.current.add(contractId);
        purchase
          .getQualityAndLaycanDropdown({
            contractId: contractId,
            plantCode: authContext.defaultPlant,
          })
          .then((res) => {
            if (res.status) {
              // setLaycan(prev => {
              //   const existingIds = new Set(prev.map(p => p.qualityId));
              //   const newData = res.data.filter(item => !existingIds.has(item.qualityId));
              //   return [...prev, ...newData];
              // });
              setLaycan(res.data);
            }
          })
          .catch((err) => {
            console.log('error: ' + err.message);
          });
      }
    };

const getLaycan = (contractId: string) => {
  if (contractId) {

      purchase
          .getQualityAndLaycanDropdown({
              contractId: contractId,
              plantCode: authContext.defaultPlant,
          })
          .then((res) => {
              if (res.status) {
                  setLaycan(res.data);
              }
          })
          .catch((err) => {
              console.error('Error fetching laycan data:', err.message);
          });
  } else if (contractId) {
      setLaycan([]);
  }

}

const getQualitiesLaycans = (contractId: string) => {
  if (contractId) {
      pcService
          .getQualityAndLaycanDropdownByCpsId({
              contractId: contractId,
              plantCode: authContext.defaultPlant,
          })
          .then((res) => {
              if (res.status) {
                  setLaycan(res.data);
              }
          })
          .catch((err) => {
              console.error('Error fetching laycan data:', err.message);
          });
  } else if (contractId) {
      setLaycan([]);
  }

}


    const getPcTypeData = (qualityId: string) => {
        if (qualityId) {
          const req = new PcPurchaseTypeReq(qualityId);
          purchase
            .getPcPurchaseTypesDropDown(req)
            .then((res) => {
              if (res.status) {
                setPcType(res.data);
                if (res.data.length > 0) {
                  setEditingRecord(prev => {
                    if (!prev) return prev;

                    if(!prev.pcType) {
                      prev.pcType = res.data[0].pcTypeId;
                    }

                    if(!prev.price && prev.contractType === 'Original') {
                      prev.price = res.data[0].pricePmt;
                      if(!prev.amount && prev?.priceCurrency === prev?.amountCurrency) {
                        prev.amount = res.data[0].pricePmt || 0;
                      }
                    }

                    if(!prev.price && prev.contractType === 'Subrow') {
                      prev.price = prev.cpsPricePMT;
                      if(!prev.amount && prev?.priceCurrency === prev?.amountCurrency) {
                        prev.amount = prev.cpsPricePMT || 0;
                      }
                    }
                    return prev;
                  });
                }
              }
            })
            .catch((err) => {
              console.log('error: ' + err.message);
            });
        }
      };

  
  // Working method function for children editable
  const handleInputChange = (fieldName: string, value: any) => {
    if (!editingRecord) return;
    // Create new data array
    const newData = [...data];
    // Check if we're editing a child record
    if (editingRecord.contractType === 'Subrow') {
      // Find parent record
      const parentIndex = newData.findIndex(item => item.id === editingRecord.baseId);
      if (parentIndex > -1 && newData[parentIndex].children) {
        // Find child record
        const childIndex = newData[parentIndex].children.findIndex(child => child.id === editingRecord.id);
        if (childIndex > -1) {
          // Create new child record with updated field
          const updatedChild = {
            ...editingRecord,  // Keep all existing fields
            [fieldName]: value
          };
  
          // Handle currency-related calculations for child
          if (['priceCurrency', 'amountCurrency', 'price', 'exchangeRate'].includes(fieldName)) {
            const dfvalue = 1;
            
            if (!updatedChild.exchangeRate) {
              updatedChild.exchangeRate = dfvalue.toString();
            }
  
            if (updatedChild.priceCurrency && updatedChild.amountCurrency) {
              if (updatedChild.priceCurrency === updatedChild.amountCurrency) {
                updatedChild.exchangeRate = dfvalue.toString();
                const currentPrice = updatedChild.price || 0;
                updatedChild.amount = currentPrice.toString();
                setHideExchangeRate(true);
              } else {
                setHideExchangeRate(false);
                const currentPrice = Number(updatedChild.price || 0);
                const currentRate = Number(updatedChild.exchangeRate || dfvalue);
  
                if (updatedChild.priceCurrency === 'USD' && updatedChild.amountCurrency === 'IDR') {
                  updatedChild.amount = Number((currentRate * currentPrice).toFixed(3)).toString();
                } else {
                  updatedChild.amount = Number((currentPrice / currentRate).toFixed(3)).toString();
                }
              }
            }
          }
  
          // Update child in parent's children array
          newData[parentIndex].children[childIndex] = updatedChild;
          // Update editing record
          setEditingRecord(updatedChild);
        }
      }
    } else {
      // Handle parent record update
      const index = newData.findIndex(item => item.id === editingRecord.id);
      if (index > -1) {
        // Get existing record from data to preserve children
        const existingRecord = newData[index];
        
        // Create new parent record with updated field while keeping all existing fields
        const updatedRecord = {
          ...editingRecord,  // Keep all existing fields
          children: existingRecord.children || [], // Preserve children array
          [fieldName]: value
        };

        // Handle currency-related calculations
        if (['priceCurrency', 'amountCurrency', 'price', 'exchangeRate'].includes(fieldName)) {
          const dfvalue = 1;
          
          if (!updatedRecord.exchangeRate) {
            updatedRecord.exchangeRate = dfvalue.toString();
          }

          // Check if currencies are same when currency fields change
          if (fieldName === 'priceCurrency' || fieldName === 'amountCurrency') {
            const areCurrenciesSame = 
              (fieldName === 'priceCurrency' ? value : updatedRecord.priceCurrency) === 
              (fieldName === 'amountCurrency' ? value : updatedRecord.amountCurrency);
            
            if (areCurrenciesSame) {
              updatedRecord.exchangeRate = dfvalue.toString();
              setHideExchangeRate(true);
            } else {
              setHideExchangeRate(false);
            }
          }

          if (updatedRecord.priceCurrency && updatedRecord.amountCurrency) {
            if (updatedRecord.priceCurrency === updatedRecord.amountCurrency) {
              updatedRecord.exchangeRate = dfvalue.toString();
              const currentPrice = updatedRecord.price || 0;
              updatedRecord.amount = currentPrice.toString();
              setHideExchangeRate(true);
            } else {
              setHideExchangeRate(false);
              const currentPrice = Number(updatedRecord.price || 0);
              const currentRate = Number(updatedRecord.exchangeRate || dfvalue);

              if (updatedRecord.priceCurrency === 'USD' && updatedRecord.amountCurrency === 'IDR') {
                updatedRecord.amount = Number((currentRate * currentPrice).toFixed(3)).toString();
              } else {
                updatedRecord.amount = Number((currentPrice / currentRate).toFixed(3)).toString();
              }
            }
          }
        }

        // Update both states while preserving children
        newData[index] = updatedRecord;
        setEditingRecord(updatedRecord);
      }
    }

    // Update data state
    setData(newData);
  };

    const isEditing = (record: LoadingPlans) => {
      if (!record) return false;
      // For new records (with temp ID in data but undefined in record)
      if (record.id === undefined && editingKey.startsWith(TEMP_ID_PREFIX)) {
        return true;
      }
      // For existing records
      return record.id === editingKey;
    };

    const edit = (record: LoadingPlans) => {
        setLaycan([]);
        setEditingKey(record.id);
        setEditingRecord(record);
        // Fetch laycan data when editing
        if (record.contractNo && record.contractType === 'Original') {
          getLaycan(record.contractNo)
        } else {
          getQualitiesLaycans(record.contractNo)
        }
        if(record.pcLaycan) {
          getPcTypeData(record.pcLaycan);
        }
    };


  const cancel = () => {
    if (editingRecord) {
      // Remove the row if it's a new record (has a temp_ prefix in id)
      if (editingRecord.id?.startsWith(TEMP_ID_PREFIX)) {
        setData(data.filter(item => !item.id?.startsWith(TEMP_ID_PREFIX)));
      }
    }
    setEditingRecord(null);
    setEditingKey('');
  };

const TEMP_ID_PREFIX = 'temp_';
let tempIdCounter = 1;

const handleAdd = () => {
    const tempId = `${TEMP_ID_PREFIX}${tempIdCounter++}`;
    const newRecord: any = {
      id: tempId,
      supplier: '',
      countryParty: '',
      motherVessel: '',
      bgName: '',
      typeOfBarge: '',
      jettyName: '',
      loadingQuantity: '',
      expectedDateOfLoading: '',
      contractNo: '',
      pcLaycan: '',
      pcType: '',
      termsOfPayment: '',
      typeOfPayment: '',
      procurementQuantity: '',
      price: 0,
      amount: '',
      pph: '',
      ppn: '',
      advance: '',
      piAdjustment: '',
      netAmount: '',
      finalPayment: '',
      paymentStatus: '',
      expectedDateOfReceipt: '',
      expectedDateOfSettlement: '',
      amountCurrency: '',
      exchangeRate: '',
      priceCurrency: '',
      siQuantity: '',
      contractType: 'Original',
      status: 'DRAFT',
      isNewRecord: true,
    };

    const newData = [{ ...newRecord, key: tempId }, ...data];
    setData(newData);
    setEditingKey(tempId);
    setEditingRecord(newRecord);
  };


  const save = async (record: LoadingPlans) => {
    try {    
      const values = {
        ...record,
        id: record.id?.includes('_') ? undefined : record.id, // Remove generated ID when saving
        supplier: editingRecord.supplierId || editingRecord.supplier,
        pph: editingRecord.pph ? parseFloat(editingRecord.pph.toString()) : null,
        ppn: editingRecord.ppn ? parseFloat(editingRecord.ppn.toString()) : null,
        exchangeRate: editingRecord.exchangeRate ? parseFloat(editingRecord.exchangeRate.toString()) : null,
        priceCurrency: editingRecord.priceCurrency || null,
        amountCurrency: editingRecord.amountCurrency || null,
        plantCode: authContext.defaultPlant,
        userId: authContext.user.userName,
        contractType: editingRecord.contractType,
        status: editingRecord.status || 'DRAFT',
        // children: children || []
      };

      const response = await service.saveLoadingPlans(values);

      if (response.status) {
        // setChildren([])
        setEditingKey('');
        setEditingRecord(null);
        // message.success('Record saved successfully');
        AlertMessages.getSuccessMessage(response.internalMessage);
        await fetchData();
      } else {
        // message.error(response.internalMessage || 'Failed to save record');
        AlertMessages.getSuccessMessage(response.internalMessage);
      }
    } catch (error) {
      AlertMessages.getErrorMessage('Failed to save record');
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await service.getLoadingPlans();
      if (response.status && Array.isArray(response.data)) {

        const transformedData = response.data.map((record: any) => ({
          ...record,
          key: record.id,
          contractType: record.contractType || 'Original',
          children: Array.isArray(record.children)
            ? record.children.map((child: any) => ({
                ...child,
                id: child.id ? child.id : `new_${child.contractNo}-${child.pcLaycan}-${child.pcType}`,
                baseId: record.id,
                amountCurrency: record.amountCurrency,
              }))
            : null,
        }))
        console.log('dataaaaaaaaaaaaaaaaaaaaa',transformedData)
        setData(transformedData);
      } else {
        AlertMessages.getSuccessMessage(response.internalMessage);
        setData([]);
      }
    } catch (error) {
      AlertMessages.getErrorMessage('Failed to fetch data');
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      service.deleteLoadingPlans({ id }).then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          fetchData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      });
    } catch (error) {
      AlertMessages.getErrorMessage('filed to delete record');
    }
    };
    const getBpNameFromId = (bpId: string) => {
        return bpDropdown?.find(bp => bp.bpId === bpId)?.bpName || bpId;
    };

    const getBusinessNameFromId = (businessId: string) => {
        return businessDropDown?.find(b => b.businessNo === businessId)?.businessNo || businessId;
    };

    const formatBargeDisplay = (bargeId: string, plantCode: string) => {
        return `${SequenceUtils.formatNumberToSpecificLength(bargeId.toString())} (${plantCode})`;
    };

    const getPcNoFromId = (pcId: string) => {
        return purchaseDropdown?.find(pc => pc.pcId === pcId)?.pcNo || pcId;
    };

    const getProcurementNoFromId = (pcId: string) => {
      return procurement?.find(pc => pc.pcID === pcId)?.pcNo || pcId;
  };


    const getLaycanDisplay = (qualityId: string, contractId: string) => {
      if (!laycan.some(l => l.qualityId === qualityId) && contractId && !fetchedContracts.current.has(contractId)) {
        getQualityAndLaycan(contractId);
      }

      const laycanData = laycan.find(l => l.qualityId === qualityId);
      if (laycanData?.laycan) {
        return `${moment(laycanData.laycan[0]).format('DD MMM YY')}-${moment(laycanData.laycan[1]).format('DD MMM YY')}`;
      }
      return ''; // Return empty string if no laycan data found
    };
  
    const getPcTypeDisplay = (pcTypeId: string) => {
      // If pcType data is not loaded and we have a laycan, fetch the data
      const currentRecord = editingRecord || data.find(r => r.pcType === pcTypeId);
      if (!pcType.length && currentRecord?.pcLaycan) {
        getPcTypeData(currentRecord.pcLaycan);
      }

      const pcTypeData = pcType.find(pt => pt.pcTypeId === pcTypeId);
      return pcTypeData?.purchaseType || pcTypeId;
    };

  const getPermissionBasedColumns = (): ColumnsType<LoadingPlans> => {
    const columns = [];
    // finance: '#f6f6ff',  
    // financeHeader: '#e0e0ff',      // Darker purple for finance header

    // Add background colors for different sections
    const sectionColors = {
      operational: '#f0f7ff',  // Light blue for operational team
      finance: '#fff4e6',     // Light purple for finance section
      procurement: '#f0fff4',  // Light green for procurement section
    };

    const sectionHeaderColors = {
      operationalHeader: '#d4e6ff',  // Darker blue for operational team header
      financeHeader: '#ffd4aa',      // Darker purple for finance header
      procurementHeader: '#c8ffd8',  // Darker green for procurement header
    };

    if (isPermissionExists([889])) {
      columns.push({
        title: 'Operation Team',
        onHeaderCell: () => ({
          style: { backgroundColor: sectionHeaderColors.operationalHeader }
        }),
        children: [
          {
            title: 'Supplier',
            dataIndex: 'supplier',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('supplier', record, isEditing(record)),
          },
          {
            title: 'Counter Party',
            dataIndex: 'countryParty',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('countryParty', record, isEditing(record)),
          },
          {
            title: 'Mother Vessel',
            dataIndex: 'motherVessel',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('motherVessel', record, isEditing(record)),
          },
          {
            title: 'BG Name',
            dataIndex: 'bgName',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('bgName', record, isEditing(record)),
          },
          {
            title: 'Type of Barge',
            dataIndex: 'typeOfBarge',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('typeOfBarge', record, isEditing(record)),
          },
          {
            title: 'Jetty Name',
            dataIndex: 'jettyName',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('jettyName', record, isEditing(record)),
          },
          {
            title: 'SI Quantity',
            dataIndex: 'siQuantity',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('siQuantity', record, isEditing(record)),
          },
          {
            title: 'Loading Quantity',
            dataIndex: 'loadingQuantity',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('loadingQuantity', record, isEditing(record)),
          },
          {
            title: 'Expected Loading Date',
            dataIndex: 'expectedDateOfLoading',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => {
              if(!isEditing(record) && record.expectedDateOfLoading){
                return moment(record.expectedDateOfLoading).format('DD-MMM-YY')
              }
              return getColumnInput('expectedDateOfLoading', record, isEditing(record))
            },
          },
        ].map(col => ({
          ...col,
          onCell: () => ({ style: { backgroundColor: sectionColors.operational } }),
          onHeaderCell: () => ({
            style: { backgroundColor: sectionHeaderColors.operationalHeader }
          }),
        }))
      });
    }

    if (isPermissionExists([890])) {
      columns.push({
        title: 'Procurement Team',
        onHeaderCell: () => ({
          style: { backgroundColor: sectionHeaderColors.procurementHeader }
        }),
        children: [
          {
            title: 'Contract No',
            dataIndex: 'contractNo',
            width: 150,
            align: 'center',
            render: (_: any, record) => {
              if(!isEditing(record) && record.contractType !== 'Original' && 'cpsContractNo' in record) {
                return record.cpsContractNo;
              }
              return getColumnInput('contractNo', record, isEditing(record))
            }
          },
          {
            title: 'PC Laycan',
            dataIndex: 'pcLaycan',
            width: 120,
            align: 'center',
            // render: (_: any, record: DischargeReport) => getColumnInput('pcLaycan', record, isEditing(record)),
            render: (_: any, record) => {
              if (!isEditing(record)) {
                const isValidLaycan =
                    Array.isArray(record.laycan) &&
                    record.laycan.length === 2 &&
                    moment(record.laycan[0], moment.ISO_8601, true).isValid() &&
                    moment(record.laycan[1], moment.ISO_8601, true).isValid();
                const value = isValidLaycan
                  ? `${moment(record.laycan[0]).format('DD-MMM-YYYY')} to ${moment(record.laycan[1]).format('DD-MMM-YYYY')}`
                  : null;
                return value;
              }
              return getColumnInput('pcLaycan', record, isEditing(record))
            }
          },
          {
            title: 'Purchase Type',
            dataIndex: 'pcType',
            width: 120,
            align: 'center',
            // render: (_: any, record: DischargeReport) => getColumnInput('pcType', record, isEditing(record)),
            render: (_: any, record) => {
              if (!isEditing(record)) {
                const value = record.purchaseType || null;
                return value;
              }
              return getColumnInput('pcType', record, isEditing(record))
            }
          },
          {
            title: 'Payment Terms',
            dataIndex: 'termsOfPayment',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('termsOfPayment', record, isEditing(record)),
          },
          {
            title: 'Payment Type',
            dataIndex: 'typeOfPayment',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('typeOfPayment', record, isEditing(record)),
          },
          {
            title: 'Procurement Quantity',
            dataIndex: 'procurementQuantity',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('procurementQuantity', record, isEditing(record)),
          },
          {
            title: 'Price',
            dataIndex: 'price',
            width: 200,
            align: 'center',
            render: (_: any, record: LoadingPlans) => {
              if (!isEditing(record)) {
                return (
                  <span>
                    {record.price} {record.priceCurrency}
                  </span>
                );
              }
              return getColumnInput('price', record, true);
            }
          },
          {
            title: 'Exchange Rate',
            dataIndex: 'exchangeRate',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('exchangeRate', record, isEditing(record)),
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            width: 200,
            align: 'center',
            render: (_: any, record: LoadingPlans) => {
              if (!isEditing(record)) {
                return (
                  <span>
                    {record.amount} {record.amountCurrency}
                  </span>
                );
              }
              return getColumnInput('amount', record, true);
            }
          },
          {
            title: 'PPH',
            dataIndex: 'pph',
            width: 100,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('pph', record, isEditing(record)),
          },
          {
            title: 'PPN',
            dataIndex: 'ppn',
            width: 100,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('ppn', record, isEditing(record)),
          },
          {
            title: 'Advance',
            dataIndex: 'advance',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('advance', record, isEditing(record)),
          },
          {
            title: 'PI Adjustment',
            dataIndex: 'piAdjustment',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('piAdjustment', record, isEditing(record)),
          },
          {
            title: 'Net Amount',
            dataIndex: 'netAmount',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('netAmount', record, isEditing(record)),
          }
        ].map(col => ({
          ...col,
          onCell: () => ({ style: { backgroundColor: sectionColors.procurement } }),
          onHeaderCell: () => ({
            style: { backgroundColor: sectionHeaderColors.procurementHeader }
          }),
        }))
      });
    }

    if (isPermissionExists([891])) {
      columns.push({
        title: 'Finance Team',
        onHeaderCell: () => ({
          style: { backgroundColor: sectionHeaderColors.financeHeader }
        }),
        children: [
          {
            title: 'Final Payment',
            dataIndex: 'finalPayment',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('finalPayment', record, isEditing(record)),
          },
          {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            width: 120,
            align: 'center',
            render: (_: any, record: LoadingPlans) => getColumnInput('paymentStatus', record, isEditing(record)),
          },
          {
            title: 'Expected Receipt Date',
            dataIndex: 'expectedDateOfReceipt',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => {
              if(!isEditing(record) && record.expectedDateOfReceipt){
                return moment(record.expectedDateOfReceipt).format('DD-MMM-YY')
              }
              return getColumnInput('expectedDateOfReceipt', record, isEditing(record))
            },
          },
          {
            title: 'Expected Settlement Date',
            dataIndex: 'expectedDateOfSettlement',
            width: 150,
            align: 'center',
            render: (_: any, record: LoadingPlans) => {
              if(!isEditing(record) && record.expectedDateOfSettlement){
                return moment(record.expectedDateOfSettlement).format('DD-MMM-YY')
              }
              return getColumnInput('expectedDateOfSettlement', record, isEditing(record))
            },
          }
        ].map(col => ({
          ...col,
          onCell: () => ({ style: { backgroundColor: sectionColors.finance } }),
          onHeaderCell: () => ({
            style: { backgroundColor: sectionHeaderColors.financeHeader }
          }),
        }))
      });
    }

    columns.push({
      title: 'Action',
      dataIndex: 'action',
      width: '70px',
      fixed: 'right',
      align: 'center', // Add center alignment
      render: (_: any, record: LoadingPlans) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <>
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => save(record)}
                />
                <Popconfirm 
                  title="Cancel editing?" 
                  onConfirm={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<CloseOutlined />} />
                </Popconfirm>
              </>
            ) : (
              <>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => edit(record)}
                  disabled={editingKey !== ''}
                />
                {record.id !== `new_${record.contractNo}-${record.pcLaycan}-${record.pcType}` &&
                <Popconfirm
                  title="Are you sure to delete?"
                  onConfirm={() => handleDelete(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} danger disabled={editingKey !== ''} />
                </Popconfirm>
                }
              </>
            )}
          </Space>
        );
      },
    });

    return columns;
  };

  const columns = getPermissionBasedColumns();

  const getColumnInput = (fieldName: string, record: LoadingPlans, editable: boolean) => {
    if (!record) return null;

    const value = editingRecord && editingRecord.id === record.id 
      ? (fieldName === 'supplier' ? getBpNameFromId(editingRecord[fieldName]) : 
         fieldName === 'motherVessel' ? getBusinessNameFromId(editingRecord[fieldName]) :
         fieldName === 'contractNo' ? getPcNoFromId(editingRecord[fieldName]) :
         fieldName === 'pcType' ? getPcTypeDisplay(editingRecord[fieldName]) :
         editingRecord[fieldName])
      : (fieldName === 'supplier' ? getBpNameFromId(record[fieldName]) : 
         fieldName === 'motherVessel' ? getBusinessNameFromId(record[fieldName]) :
         fieldName === 'contractNo' ? getPcNoFromId(record[fieldName]) :
         fieldName === 'pcType' ? getPcTypeDisplay(record[fieldName]) :
         record[fieldName]);

        if(editingRecord && editingRecord.id === record.id && fieldName === 'contractNo' && record.contractType !== 'Original'){
        return getProcurementNoFromId(record[fieldName])
        }

    if (!editable) {
      if (fieldName === 'bgName') {
        const barge = bgDropDown?.find(b => b.id === value);
        return barge ? formatBargeDisplay(barge.bargeId.toString(), barge.plantCode) : value;
      }
      if (fieldName === 'contractNo' && record.contractType === 'Original') {
        return getPcNoFromId(value as string);
      } else {
        return getProcurementNoFromId(value as string);
      }
      return value;
    }

    switch(fieldName) {
        
        case 'supplier':
            const supplierMap = new Map(bpDropdown?.map(bp => [bp.bpName, bp.bpId]));
            
            return (
                <AutoComplete
                    value={getBpNameFromId(value as string)}
                    style={{ width: '100%' }}
                    onChange={(val) => {
                        const bpId = supplierMap.get(val);
                        setEditingRecord(prev => {
                            if (!prev) return prev;
                            return {
                                ...prev,
                                supplier: bpId || val,
                                displaySupplier: val
                            };
                        });
                        handleInputChange(fieldName, val)
                    }}
                    options={bpDropdown?.map(bp => ({
                        value: bp.bpName,
                        label: bp.bpName
                    })) || []}
                    placeholder="Type or select supplier"
                    filterOption={(inputValue, option) =>
                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    disabled={editingRecord['contractType'] !== 'Original' ? true : false}
                    allowClear
                />
            )

            case 'motherVessel':
                const businessMap = new Map(businessDropDown?.map(b => [b.businessNo, b.businessNo]));
                
                return (
                    <AutoComplete
                        value={getBusinessNameFromId(value as string)}
                        style={{ width: '100%' }}
                        onChange={(val) => {
                            const businessId = businessMap.get(val);
                            setEditingRecord(prev => {
                                if (!prev) return prev;
                                return {
                                    ...prev,
                                    motherVessel: businessId || val,
                                    displayMotherVessel: val
                                };
                            });
                            handleInputChange(fieldName, val)
                        }}
                        options={businessDropDown?.map(b => ({
                            value: b.businessNo,
                            label: b.businessNo
                        })) || []}
                        placeholder="Type or select mother vessel"
                        filterOption={(inputValue, option) =>
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        disabled={editingRecord['contractType'] !== 'Original' ? true : false}
                        allowClear
                    />
                );

            case 'bgName':
                const filteredOptions = bgDropDown?.filter(
                    (barge) => !data.some((dataItem) => dataItem.bgName === barge.id)
                ) || [];

                if (value) {
                    const currentBarge = bgDropDown?.find(b => b.id === value);
                    if (currentBarge && !filteredOptions.some(b => b.id === currentBarge.id)) {
                        filteredOptions.push(currentBarge);
                    }
                }
            return (
                <Select
                    value={value as string}
                    style={{ width: '100%' }}
                    onChange={(val) => {
                      handleInputChange(fieldName, val)

                        const selectedBarge = bgDropDown?.find(barge => barge.id === val);
                        
                        setEditingRecord(prev => {
                            if (!prev) return prev;
                            return {
                                ...prev,
                                bgName: selectedBarge?.id || '',  // Store ID in bgName
                                loadingQuantity: selectedBarge?.quantity?.toString() || ''
                            };
                        });
                    }}
                    options={filteredOptions.map(barge => ({
                        label: formatBargeDisplay(barge.bargeId.toString(), barge.plantCode),
                        value: barge?.id
                    }))}
                    placeholder="Select barge"
                    disabled={editingRecord['contractType'] !== 'Original' ? true : false}
                    allowClear
                />
            );

            case 'contractNo':
              return (
                <Select
                  value={value as string}
                  style={{ width: '80%' }}
                  onChange={(val) => {
                    handleInputChange(fieldName, val)
                    const matchedContract = editingRecord['contractType'] !== 'Original' ? procurement : purchaseDropdown.find(pc => pc.pcId === val);
                    console.log('matchedContract', matchedContract)
                    setEditingRecord(prev => {
                      if (!prev) return prev;
                      return {
                        ...prev,
                        contractNo: val,
                        pcLaycan: undefined, // Reset laycan when contract changes
                        pcType: undefined, // Reset pcType when contract changes
                        price: undefined, // Reset price when contract changes
                        // priceCurrency: matchedContract?.pcCurrency, // Set price currency from contract
                        priceCurrency: matchedContract ? matchedContract.pcCurrency : undefined,
                      };
                    });
                    console.log('price Currency',editingRecord)
                    console.log('After Price Currency Upadte',matchedContract.pcCurrency)
                    if (val) {
                      getQualityAndLaycan(val);
                    } else {
                      setPcType([]); // Clear pcType options if no contract selected
                    }
                  }}
                  options={purchaseDropdown.map(contract => ({
                    label: contract.pcNo,
                    value: contract.pcId
                  }))}
                  placeholder="Select contract no"
                  disabled={editingRecord['contractType'] !== 'Original'}
                  allowClear
                />
              );

        case 'pcLaycan':
        return (
          <Select
            value={value as string}
            style={{ width: '100%' }}
            onChange={(val) => {
              setEditingRecord(prev => {
                if (!prev) return prev;
                return {
                  ...prev,
                  pcLaycan: val,
                  pcType: undefined, // Reset pcType when laycan changes
                  price: undefined // Reset price when laycan changes
                };
              });
              handleInputChange(fieldName, val)
              if (val) {
                getPcTypeData(val); // Fetch pcType data when laycan is selected
              } else {
                setPcType([]); // Clear pcType options if no laycan selected
              }
            }}
            options={laycan.map(l => ({
              label: `${moment(l.laycan[0]).format('DD MMM YY')}-${moment(l.laycan[1]).format('DD MMM YY')}`,
              value: l.qualityId
            }))}
            placeholder="Select laycan"
            disabled={!editingRecord?.contractNo || editingRecord['contractType'] !== 'Original'}
            allowClear
          />
        );


        case 'pcType':
            return (
              <Select
                value={value as string}
                style={{ width: '100%' }}
                onChange={(val) => {
                  const selectedType = pcType.find(pt => pt.pcTypeId === val);
                  setEditingRecord(prev => {
                    if (!prev) return prev;
                    return {
                      ...prev,
                      pcType: val,
                      price: selectedType?.pricePmt || 0
                    };
                  });
                  handleInputChange(fieldName, val)
                }}
                options={pcType.map(pt => ({
                  label: pt.purchaseType,
                  value: pt.pcTypeId
                }))}
                placeholder="Select purchase type"
                disabled={!editingRecord?.pcLaycan || editingRecord['contractType'] !== 'Original'}
                allowClear
              />
            );

      case 'expectedDateOfLoading':
        return (
          <DatePicker 
            value={value ? moment(value) : null}
            style={{ width: '100%' }}
            format="YYYY-MM-DD"
            onChange={(date) => handleInputChange(fieldName, date?.format('YYYY-MM-DD'))}
            placeholder="Select Date"
            disabled={editingRecord['contractType'] !== 'Original'}
          />
        );
      case 'expectedDateOfReceipt':
      case 'expectedDateOfSettlement':
        return (
          <DatePicker 
            value={value ? moment(value) : null}
            style={{ width: '100%' }}
            format="YYYY-MM-DD"
            onChange={(date) => handleInputChange(fieldName, date?.format('YYYY-MM-DD'))}
            placeholder="Select Date"
            // disabled={editingRecord['contractType'] !== 'Original'}
          />
        );

        case 'countryParty':
          const currentCounterParty = editingRecord ? editingRecord.countryParty : value;
          return (
            <Select
              value={currentCounterParty || undefined}
              style={{ width: '100%' }}
              onChange={(val) => {
                handleInputChange(fieldName, val);
                const currency = val === 'BKM' || val === 'SDAM' ? 'IDR' : 'USD';
                setEditingRecord((prev: any) => {
                  if (!prev) return prev;
                  return {
                    ...prev,
                    countryParty: val,
                    amountCurrency: currency
                  };
                });
              }}
              options={[
                { value: 'BKM', label: 'BKM' },
                { value: 'SRPL', label: 'SRPL' },
                { value: 'SDAM', label: 'SDAM' },
                { value: 'AVS', label: 'AVS' }
              ]}
              placeholder="Select counter party"
              disabled={editingRecord['contractType'] !== 'Original' ? true : false}
            />
          );
      
        case 'price':
        case 'amount':
          const currencyField = fieldName === 'price' ? 'priceCurrency' : 'amountCurrency';
          const currentCurrency = editingRecord ? editingRecord[currencyField] : record[currencyField];
          const isAmount = fieldName === 'amount';
          const isPriceCurrencyDisabled = isAmount || (fieldName === 'price' && !!editingRecord?.contractNo);
          return (
            <Space.Compact style={{ width: '100%' }}>
              <InputNumber 
                value={value as number}
                style={{ width: '60%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => parseFloat(value!.replace(/\$\s?|(,*)/g, ''))}
                onChange={(val) => handleInputChange(fieldName, val)}
                min={0}
                disabled={isAmount}
              />
              <Select
                value={currentCurrency || undefined}  // Use undefined for empty value
                style={{ width: '40%' }}
                onChange={(val) => handleInputChange(currencyField, val)}
                options={[
                  { value: 'USD', label: 'USD' },
                  { value: 'IDR', label: 'IDR' },
                  { value: 'AED', label: 'AED' },
                  { value: 'INR', label: 'INR' },
                  { value: 'SGD', label: 'SGD' }
                ]}
                disabled={isPriceCurrencyDisabled}
              />
            </Space.Compact>
          );
      case 'loadingQuantity':
      case 'siQuantity':
        return (
          <InputNumber 
            value={value as string}
            style={{ width: '100%' }}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            onChange={(val) => handleInputChange(fieldName, val)}
            placeholder="Enter quantity"
            disabled={editingRecord['contractType'] !== 'Original' ? true : false}
          />
        );

        case 'procurementQuantity':
        return (
          <InputNumber 
            value={value as string}
            style={{ width: '100%' }}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            placeholder="Enter quantity"
            onChange={(val) => handleInputChange(fieldName, val)}
          />
        );

        case 'pph':
        case 'ppn':
          return (
            <InputNumber 
              value={value ? parseFloat(value as string) : undefined}
              style={{ width: '100%' }}
              onChange={(val) => handleInputChange(fieldName, val)}
              placeholder="Enter Value"
              precision={2}
            />
          );

          case 'exchangeRate':
          return (
            <InputNumber 
              value={value ? parseFloat(value as string) : undefined}
              style={{ width: '100%' }}
              onChange={(val) => handleInputChange(fieldName, val)}
              placeholder="Enter Exchange Rate"
              disabled={editingRecord['amountCurrency'] && editingRecord['priceCurrency'] && editingRecord['amountCurrency'] === editingRecord['priceCurrency'] ? true : false}
              precision={2}
            />
          );

          case 'advance':
            case 'piAdjustment':
              case 'netAmount':
            return (
              <Input 
                value={value as string} 
                onChange={(e) => handleInputChange(fieldName, e.target.value)}
                placeholder="Enter Value"
                // disabled={editingRecord['contractType'] === 'Subrow' ? true : false}
              />
            );

        // case 'typeOfPayment':
          case 'paymentStatus':
              return (
                <Input
                  value={value as string}
                  style={{ width: '100%' }}
                  onChange={(e) => handleInputChange(fieldName, e.target.value)}
                  placeholder="Enter Status"
                  // disabled={editingRecord['contractType'] !== 'Original' ? true : false}
                />
            );
            case 'finalPayment':
              return (
                <Input
                  value={value as number}
                  style={{ width: '100%' }}
                  onChange={(e) => handleInputChange(fieldName, e.target.value)}
                  placeholder="Enter Status"
                  // disabled={editingRecord['contractType'] !== 'Original' ? true : false}
                />
            );
            case 'termsOfPayment':
            case 'typeOfPayment':
              return (
                <Input
                  value={value as string}
                  style={{ width: '100%' }}
                  placeholder="Enter Type"
                  onChange={(e) => handleInputChange(fieldName, e.target.value)}
                />
            );

      default:
        return (
          <Input 
            value={value as string} 
            onChange={(e) => handleInputChange(fieldName, e.target.value)}
            placeholder="Enter Value"
            disabled={editingRecord['contractType'] !== 'Original' ? true : false}
          />
        );
    }
  };

  return (
        <Card className="card-radius"
        extra={
          <span>
              <Button
              className='new-button mt-8'
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAdd}
              disabled={editingKey !== ''}
            >
              Add New Record
            </Button>
          </span>
        }>
          <Table
            bordered
            dataSource={data}
            columns={columns}
            rowKey={(record) => record.id || `temp_${record.contractNo}_${record.pcLaycan}_${record.pcType}`}
            loading={loading}
            scroll={{ x: 4000 }}
            pagination={{
              pageSize: 10,
              position: ['bottomRight']
            }}
            expandable={{
              expandIconColumnIndex: 9,
            }}
          />
        </Card>
  );
};

export default LoadingPlansList