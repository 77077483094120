import { BasisCreateDto, BasisService, BasisUpdateDto } from "@exportx/shared-models-and-services";
import { ClassValidator } from "@exportx/ui-utils";
import { Button, Card, Col, Form, Row, Space } from "antd";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, GenericFormWithOutPopUP, InputTypes, isPermissionExist, useAuthState } from "../../../../../common";
import { useEffect } from "react";
//import { GenericForm, InputTypes } from "../../../../common";
//import GenericForm, { InputTypes } from "../../../../common/generic-form/generic-form";


interface BasisFormProps {

}
export const BasisForm = (props: BasisFormProps) => {
  const navigate = useNavigate();
  const { authContext } = useAuthState();
  const { state }: any = useLocation();
  const { isUpdate, basisData, isNewRecord } = state ? state : { isUpdate: false, basisData: null, isNewRecord: true };
  const { formatMessage: fm } = useIntl();
  const [formRef] = Form.useForm();
  const classValidator = new ClassValidator();
  const service = new BasisService();
  let pathToreDirect = '/commodity';

  const submitFormHandler = () => {
    formRef.validateFields().then(values => {
      submitForm(values);
    })
  }

  useEffect(()=>{
    const accessId = {
      create: [18],
      view: [19],
      edit: [20]
    }
    const hasPermission = isNewRecord ? accessId.create : isUpdate ? accessId.edit : accessId.view;

    if(!isPermissionExist(hasPermission)){
      return navigate('/')
    }

  },[])

  const clearData = () => {
    formRef.resetFields();
  }
  const formItems = [
    {
      name: 'basisCode',
      label: fm({ id: "mdm.components.basisForm.basisName", defaultMessage: 'Basis Name' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.basisForm.basisNameReq", defaultMessage: 'Enter Basis Name' }) },
      validationRules: [
        { pattern: /^[A-Z]*$/, required: true, message: fm({ id: `mdm.components.basisForm.basisNameRe`, defaultMessage: "Enter Basis Name In Capitals Only" }) },

        ...classValidator.onlyAlphabets(),
        ...classValidator.lengthValidation(15, fm({ id: "mdm.components.basisForm.basisName", defaultMessage: 'Basis Name' }))
      ]
    }
  ]
  const update = (values: any) => {
    const updateDto: BasisUpdateDto = new BasisUpdateDto(basisData.basisId, values.basisCode, authContext.user.userName, basisData.versionFlag);
    service.update(updateDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Basis Updated SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect, {state: {activeTab: 'basis'}});
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const submitForm = (values: any) => {
    if (isUpdate && basisData?.basisId) {
      update(values);
      return;
    }
    const createDto: BasisCreateDto = new BasisCreateDto(values.basisCode, authContext.user.userName);
    service.create(createDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Basis Created SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect, {state: {activeTab: 'basis'}});
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
      console.log("createDto");
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }






  return (
    <Card bodyStyle={{ overflow: 'auto' }}
      title="Basis"
      className='default-card-class'
      extra={
        <Button
          title={fm({ id: "mdm.components.basisForm.title", defaultMessage: 'BASIS' })}
          onClick={() => navigate(pathToreDirect, {state: {activeTab: 'basis'}})}
        >
          {fm({ id: 'common.view', defaultMessage: 'View' })}
        </Button>
      }
    >
      <GenericFormWithOutPopUP
        title={fm({ id: "mdm.components.basisForm.title", defaultMessage: 'Basis' })}
        formItems={formItems}
        formRef={formRef}
        initialValues={basisData}
        isUpdate={isUpdate}
        isNewRecord={isNewRecord}
        closeForm={() => { }}
        submitForm={() => { }}
        clearData={clearData} >
        <Row justify="end">
          <Col>
            {
              (isNewRecord || isUpdate) && <Space>
                <Button
                  onClick={() => { clearData(); }}
                >
                  {fm({ id: "common.clear", defaultMessage: "Clear" })}
                </Button>
                <Button onClick={submitFormHandler} type="primary">
                  Submit
                </Button>
              </Space>
            }</Col>
        </Row>

      </GenericFormWithOutPopUP>   </Card>)
}
export default BasisForm;







