import { DownloadOutlined } from '@ant-design/icons';
import { BACGetDataDto, BudgetAndCostingService } from '@exportx/shared-models-and-services';
import { Button, Card, Input, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Link from 'antd/lib/typography/Link';
import { isPermissionExist } from '../../../../common';

export const BudgetGrid = () => {


  const { Search } = Input;
  const navigate = useNavigate();
  const bacService = new BudgetAndCostingService()
  const [gridData, setGridData] = useState<BACGetDataDto[]>([])
  const { state }: any = useLocation()

  useEffect(() => {
     if(!isPermissionExist([767])) navigate('/')
  },[])

  useEffect(() => {
    getBudgetingAndCostingData()
  }, [])

  const getBudgetingAndCostingData = () => {
    bacService.getBudgetingAndCostingData().then(res => {
      if (res.status) {
        setGridData(res.data);
      }
    }).catch(err => {
      console.log(err.message)
    })
  }



  const Columns: any = [

    {
      title: 'Costing No',
      dataIndex: 'budgetCostingNo',
      width: 100,
      render: (value, record) => {
        return <> <Link onClick={() => navigate('/budget-costing-detailed-view', { state:{id:record.budgetCostingId} })}>{value}</Link> </>
      }
    },
    {
      title: 'Laycan',
      dataIndex: 'laycan',
      width: 100,
      render: (value, record) => {
        return <>
          <span onClick={(() => console.log(value))}>
            {[moment(value[0]).format('DD-MMM-YY'), " to ", moment(value[0]).format('DD-MMM-YY')]}
          </span>
        </>
      }
    },
    {
      title: 'Customer Name',
      dataIndex: 'customername',
      width: 100,
    },
    {
      title: 'Business No',
      dataIndex: 'businessNoId',
      width: 100,
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vesselName',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
    },

  ]


  const onSearch = () => {

  };
  return (
    <div>
      <Card
        title={<span style={{ color: 'black' }}>Budget And Costing</span>}
        style={{ textAlign: 'left' }} extra={<>{isPermissionExist([766]) && (<span style={{ color: 'white' }} ><Button type="primary" onClick={() => navigate("/budget-costing-form")}>New Budget & Costing</Button> </span>)}</>}>
        {isPermissionExist([770]) && (<Tooltip placement="bottom" title='Download' color='#11ad9b'>
          <Button shape="circle" icon={<DownloadOutlined />} style={{ float: 'right' }} />
        </Tooltip>)}
        <Search placeholder=" Search " allowClear onSearch={onSearch} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
        <Table bordered style={{ paddingBlockEnd: '50px' }} columns={Columns} dataSource={gridData} scroll={{ x: 1500, y: 500 }} />

      </Card>

    </div>
  )
}

export default BudgetGrid; 