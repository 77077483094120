import {
  AnchorageDto,
  AnchorageService,
} from '@exportx/shared-models-and-services';
import {
  RightSquareOutlined,
  EditOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  EditFilled,
  EyeFilled,
} from '@ant-design/icons';
import {
  Card,
  Button,
  Table,
  Popconfirm,
  Divider,
  Switch,
  Tooltip,
  Row,
  Col,
  Input,
  Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist } from '../../../../common';

const AnchorageGrid = () => {
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const [, setselectedgridData] = useState<any>(undefined);
  const [gridData, setGridData] = useState<AnchorageDto[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const service = new AnchorageService();

  const openFormWithData = (rowData: AnchorageDto) => {
    setselectedgridData(rowData);
  };

  useEffect(() => {
    if (!isPermissionExist([67])) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    service
      .getAllAnchorages()
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
        } else {
          setGridData([]);
          //AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      })
      .catch((err) => {
        setGridData([]);
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const deleteProfile = (anchorageData: AnchorageDto) => {
    anchorageData.isActive = anchorageData.isActive ? false : true;
    service
      .activateOrDeactivate(anchorageData)
      .then((res) => {
        if (res.status) {
          getAll();
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: `Anchorage ${
                anchorageData.isActive ? ' activated ' : ' deactivated '
              } SuccessFully`,
            }),
          );
        } else {
          if (res.errorCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };
  const Columns: any = [
    {
      title: 'Anchorage Name',
      dataIndex: 'anchorageName',
      width: 130,
      sorter: (a: { anchorageName: string }, b: { anchorageName: any }) =>
        a.anchorageName.localeCompare(b.anchorageName),
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
    },
    {
      title: 'Country',
      dataIndex: 'countryName',
      width: 130,
      sorter: (a: { countryName: string }, b: { countryName: any }) =>
        a.countryName.localeCompare(b.countryName),
      sortDirections: ['descend', 'ascend'],
    },

    // {
    //   title: 'Province',
    //   dataIndex: 'portName',
    //   width: 130,
    //   sorter: (a: { port: string; }, b: { port: any; }) => a.port.localeCompare(b.port),
    //   sortDirections: ['descend', 'ascend'],
    // },

    {
      title: 'Action',
      dataIndex: 'operations',
      width: 130,
      align: 'center',
      render: (text, rowData) => (
        // rowData.anchorageName.trim().toLowerCase() === "vizag" ? <span></span> :
        <Space size={10}>
          {isPermissionExist([67]) && (
            <Tooltip title={'View'}>
              <EyeFilled
                // style={{ color: '#1890ff', fontSize: '15px' }}
                className='action-icons'
                onClick={() =>
                  navigate('/anchorageForm', {
                    state: { anchorageData: rowData, hidden: true },
                  })
                }
              />
            </Tooltip>
          )}
          {isPermissionExist([68]) && (
            <EditFilled
              // className={'editSamplTypeIcon'}
              className='action-icons'
              type="edit"
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                  navigate('/anchorageForm', {
                    state: { anchorageData: rowData, isUpdate: true },
                  });
                } else {
                  AlertMessages.getErrorMessage(
                    'You Cannot Edit Deactivated Anchorage',
                  );
                }
              }}
              // style={{ color: '#1890ff', fontSize: '20 px' }}
            />
          )}

          {/* <Divider type="vertical" /> */}
          {isPermissionExist([69]) && (
            <Popconfirm
              onConfirm={(e) => {
                deleteProfile(rowData);
              }}
              title={
                rowData.isActive
                  ? 'Are you sure to Deactivate ?'
                  : 'Are you sure to Activate ?'
              }
            >
              <Switch
                size="default"
                className={
                  rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'
                }
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseCircleOutlined />}
                checked={rowData.isActive}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      {/* <Card
        title="Anchorages"
        className="default-card-class"
        extra={
          <span style={{ color: 'white' }}>
            {isPermissionExist([66]) && (
              <Button onClick={() => navigate('/anchorageForm')}>Create</Button>
            )}
          </span>
        }
      > */}
      <Row justify="space-between" className="mb-12 mt-8">
        <Col>
          <Input.Search
            placeholder="Search Anchorage"
            allowClear
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
            onSearch={(value) => {
              setSearchedText(value);
            }}
            className="input-search"
          />
        </Col>
        <Col>
          <>
            {isPermissionExist([66]) && (
              <Button
                className="new-button"
                onClick={() => navigate('/anchorageForm')}
              >
                Create
              </Button>
            )}
          </>
        </Col>
      </Row>
      <Table
      className='contracts-list'
        rowKey={(rowData) => rowData.anchorageId}
        columns={Columns}
        dataSource={gridData}
      />
      {/* </Card> */}
    </>
  );
};

export default AnchorageGrid;
