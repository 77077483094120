import { VendorAdvService, ContractStatusEnum, AdvancePreview } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Form, Input, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AlertMessages, useAuthState } from "../../../../../common";
import Link from 'antd/lib/typography/Link';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";



const AdvancePendingApproval = () => {
  const [advancePending, setAdvancePending] = useState<any>();
  const [searchData, setSearchData] = useState("")
  const { formatMessage: fm } = useIntl();
  const service = new VendorAdvService();
  const navigate = useNavigate();
  let pathToreDirect = '/advanceDetailView'
  const { authContext } = useAuthState();



  useEffect(() => {
    advancePendingD();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.defaultPlant]);


  const advancePendingD = () => {
    service.getAdvancePendingData({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setAdvancePending(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }
  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contract_no',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contract_no)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contract_date)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.bp_name)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.action)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
      render: (value, rowData) => {
        const link = `/#${pathToreDirect}?id=${rowData.id}`
      return <> {
        <a href={link} className="link" >{value}</a>
      }
      </>}
    },

    {
        title: 'Contract Type',
        dataIndex: 'contract_type',
    },

    {
      title: 'Contract Date',
      dataIndex: 'contract_date',
      render: (text, record) => { return record.contract_date !== undefined ? moment(record.contract_date).format('DD\u2011MMM\u2011YYYY') : "" },
      sorter: (a, b) => moment(a.contract_date).unix() - moment(b.contract_date).unix(),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Vendor',
      dataIndex: 'bp_name',
    },

    {
      title: 'Action',
      dataIndex: 'action',
      onCell: (record: any) => ({
        className: 'nowrap'
      }),
      render: (text, rowData) => {
        return <span >
          <Button size="small" className="approve" onClick={() => advanceApproveOrReject(rowData?.id, ContractStatusEnum.ACTIVE)}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>&nbsp;&nbsp;
          <Button size="small" className="reject" onClick={() => advanceApproveOrReject(rowData?.id, ContractStatusEnum.CANCELLED)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
          </span>
      }
    },
  ]

  const advanceApproveOrReject = (id: string, status: ContractStatusEnum) => {
    if (!id) {
      AlertMessages.getErrorMessage(fm({ id: ``, defaultMessage: 'Contract id not found' }));
      return;
    }
    const req = new AdvancePreview(id, authContext.defaultPlant, authContext.user.userName, status);
    service.advanceApproveOrReject(req).then(res => {
    if (res.status) {
        advancePendingD()
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`}));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }

  const redirectToFullView = (contractId: string) => {
    navigate(pathToreDirect, { state: { contractId } });
  }

  return (
    <>
      <Card headStyle={{border: 0, paddingTop: '16px'}} className="card-br-8"
        title={<Input.Search placeholder="Search" className="input-search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} />}
      >

        <Table className='contracts-list overflowX' columns={tableColumns} dataSource={advancePending}
            pagination={{
              showTotal: (total) => (
                <span 
                className='page-total-items'
                >Total {total} items</span>
              ),
              showSizeChanger: true, 
                pageSizeOptions: ['25', '50', '100'],
                defaultPageSize: 25,
              style: {
                paddingTop: '20px',
              },
            }}
        ></Table>

        </Card>

    {/* <Card>

      <Form layout="vertical">
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
          </Col>
        </Row>

      </Form>
      <Table className='contracts' columns={tableColumns} dataSource={advancePending} ></Table>

    </Card> */}
    </>
  )

}
export default AdvancePendingApproval;