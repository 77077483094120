import {
  CheckOutlined,
  CloseCircleOutlined,
  EditFilled,
  EditOutlined,
  EyeFilled,
  EyeOutlined,
  RightSquareOutlined,
} from '@ant-design/icons';
import { JettyDto, JettyService } from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist } from '../../../../common';

const JettyGrid = () => {
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const [, setselectedgridData] = useState<any>(undefined);
  const [gridData, setGridData] = useState<JettyDto[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const service = new JettyService();

  const openFormWithData = (rowData: JettyDto) => {
    setselectedgridData(rowData);
  };

  useEffect(() => {
    if (!isPermissionExist([71])) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAll = () => {
    service
      .getAllJettyNames()
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
        } else {
          setGridData([]);
        }
      })
      .catch((err) => {
        setGridData([]);
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const deleteProfile = (jettyData: JettyDto) => {
    jettyData.isActive = jettyData.isActive ? false : true;
    service
      .activateOrDeactivate(jettyData)
      .then((res) => {
        if (res.status) {
          getAll();
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: `Jetty${
                jettyData.isActive ? ' activated ' : ' deactivated '
              } SuccessFully`,
            }),
          );
        } else {
          if (res.errorCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };
  const Columns: any = [
    {
      title: 'JettyName',
      dataIndex: 'jettyName',
      width: 130,
      sorter: (a: { jettyName: string }, b: { jettyName: any }) =>
        a.jettyName.localeCompare(b.jettyName),
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
    },

    {
      title: 'Area',
      dataIndex: 'area',
      width: 130,
      align: 'center',
      sorter: (a: { area: string }, b: { area: any }) =>
        a.area.localeCompare(b.area),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Country',
      dataIndex: 'countryName',
      width: 130,
      sorter: (a: { countryName: string }, b: { countryName: any }) =>
        a.countryName.localeCompare(b.countryName),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Province',
      dataIndex: 'portName',
      width: 130,
      sorter: (a: { port: string }, b: { port: any }) =>
        a.port.localeCompare(b.port),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Action',
      dataIndex: 'operations',
      width: 130,
      render: (text, rowData) => (
        <Space size={10}>
          {isPermissionExist([71]) && (
            <Tooltip title={'View'}>
              <EyeFilled
                // style={{ color: '#1890ff', fontSize: '15px' }}
                className="action-icons"
                onClick={() =>
                  navigate('/jettyForm', {
                    state: { jettyData: rowData, hidden: true },
                  })
                }
              />
            </Tooltip>
          )}
          {isPermissionExist([72]) && (
            <EditFilled
              // className={'editSamplTypeIcon'}
              type="edit"
              className="action-icons"
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                  navigate('/jettyForm', {
                    state: { jettyData: rowData, isUpdate: true },
                  });
                } else {
                  AlertMessages.getErrorMessage(
                    'You Cannot Edit Deactivated Jetty',
                  );
                }
              }}
              // style={{ color: '#1890ff', fontSize: '20 px' }}
            />
          )}

          {/* <Divider type="vertical" /> */}
          {isPermissionExist([73]) && (
            <Popconfirm
              onConfirm={(e) => {
                deleteProfile(rowData);
              }}
              title={
                rowData.isActive
                  ? 'Are you sure to Deactivate ?'
                  : 'Are you sure to Activate ?'
              }
            >
              <Switch
                size="default"
                className={
                  rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'
                }
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseCircleOutlined />}
                checked={rowData.isActive}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      {/* <Card
        title="Jetty"
        className="default-card-class"
        extra={
          <>
            {isPermissionExist([70]) && (
              <span style={{ color: 'white' }}>
                <Button onClick={() => navigate('/jettyForm')}>Create</Button>{' '}
              </span>
            )}
          </>
        }
      > */}
      <Row justify="space-between" className="mb-12 mt-8">
        <Col>
          <Input.Search
            placeholder="Search Jetty"
            allowClear
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
            onSearch={(value) => {
              setSearchedText(value);
            }}
            className="input-search"
          />
        </Col>
        <Col>
          <>
            {isPermissionExist([70]) && (
              <Button
                className="new-button"
                onClick={() => navigate('/jettyForm')}
              >
                Create
              </Button>
            )}
          </>
        </Col>
      </Row>
      <Table
        className="contracts-list"
        rowKey={(rowData) => rowData.jettyId}
        columns={Columns}
        dataSource={gridData}
      />
      {/* </Card> */}
    </>
  );
};

export default JettyGrid;
