import {
  CheckOutlined,
  CloseCircleOutlined,
  EditFilled,
  EyeFilled,
  EyeOutlined,
  RightSquareOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Input,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Tabs,
  Tooltip,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';

import {
  DestinationDto,
  DestinationService,
} from '@exportx/shared-models-and-services';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist } from '../../../../../common';
import DestinationForm from '../destination-form/destination-form';
import './destination-grid.css';
import TabPane from 'antd/es/tabs/TabPane';
import AnchorageGrid from '../../anchorage-name/anchorageGrid';
import { ShippingTermsGrid } from '../../shipping-terms';
import JettyGrid from '../../jetty/jetty-grid';

/* eslint-disable-next-line */

export function DestinationGrid() {
  const { state }: any = useLocation();
  const activeTab = state?.activeTab || 'province';
  const [drawerVisible, setDrawerVisible] = useState(false);
  const destinationService = new DestinationService();
  const [selectedDestinationData, setSelectedDestinationData] =
    useState<any>(undefined);
  const [destinationData, setDestinationData] = useState<DestinationDto[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const navigate = useNavigate();

  const openFormWithData = (viewData: DestinationDto) => {
    setSelectedDestinationData(viewData);
    navigate('/destination-form', {
      state: { destinationData: viewData, isUpdate: true },
    });
  };

  useEffect(() => {
    if (!isPermissionExist([63, 67, 51, 71])) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAll = () => {
    destinationService
      .getAllDestination()
      .then((res) => {
        if (res.status) {
          setDestinationData(res.data);
        } else {
          if (res.errorCode) {
            setDestinationData([]);
          }
        }
      })
      .catch((err) => {
        setDestinationData([]);
      });
  };

  const deleteProfile = (destinationData: DestinationDto) => {
    destinationData.isActive = destinationData.isActive ? false : true;
    destinationService
      .ActivateorDeactivateDestination(destinationData)
      .then((res) => {
        console.log(res);
        if (res.status) {
          getAll();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          if (res.errorCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Country Name',
      dataIndex: 'countryName',
      render: (text, rowData) => {
        return rowData.countryName;
      },
      sorter: (a, b) => a.countryName.localeCompare(b.countryName),
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
    },
    {
      title: 'Province Name',
      dataIndex: 'destinationName',
      sorter: (a, b) =>
        a.destinationName.trim().localeCompare(b.destinationName.trim()),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: `Action`,
      dataIndex: 'action',
      align: 'center',
      render: (text, rowData) => (
        <Space size={10}>
          {isPermissionExist([63]) && (
            <Tooltip title={'View'}>
              <EyeFilled
                className='action-icons'
                onClick={() =>
                  navigate('/destination-form', {
                    state: { destinationData: rowData, hidden: true },
                  })
                }
              />
            </Tooltip>
          )}
          {isPermissionExist([64]) && (
            <EditFilled
              type="edit"
              className='action-icons'
              onClick={() => {
                if (rowData.isActive) {
                  openFormWithData(rowData);
                } else {
                  AlertMessages.getErrorMessage(
                    'You Cannot Edit Deactivated Destination',
                  );
                }
              }}
              // style={{ color: '#1890ff', fontSize: '24px' }}
            />
          )}

          {/* <Divider type="vertical" /> */}
          {isPermissionExist([65]) && (
            <Popconfirm
              onConfirm={(e) => {
                deleteProfile(rowData);
              }}
              title={
                rowData.isActive
                  ? 'Are you sure to Deactivate ?'
                  : 'Are you sure to Activate ?'
              }
            >
              <Switch
                size="default"
                className={
                  rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'
                }
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseCircleOutlined />}
                checked={rowData.isActive}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <>
      <Card className="card-radius">
        <Tabs defaultActiveKey={activeTab}>
          {isPermissionExist([63]) && (
            <TabPane tab="Province" key="province">
              {/* <Card
                className="default-card-class"
                title={<span> Province </span>}
                extra={
                  <>
                    {isPermissionExist([62]) && (
                      <Link to="/destination-form">
                        <span>
                          <Button>Create </Button>{' '}
                        </span>
                      </Link>
                    )}
                  </>
                }
              > */}
                <Row justify="space-between" className='mb-12 mt-8'>
                  <Col><Input.Search
                    placeholder="Search Province"
                    allowClear
                    onChange={(e) => {
                      setSearchedText(e.target.value);
                    }}
                    onSearch={(value) => {
                      setSearchedText(value);
                    }}
                    className="input-search"
                  /></Col>
                  <Col><>
                    {isPermissionExist([62]) && (
                          <Button className='new-button' onClick={() => navigate("/destination-form")}>Create </Button>
                    )}
                  </></Col>
                </Row>
                <Table
                className='contracts-list'
                  rowKey={(record) => record.destinationId}
                  columns={columnsSkelton}
                  dataSource={destinationData}
                  scroll={{ x: true }}
                  pagination={false}
                  onChange={onChange}
                  // bordered
                />
                <Drawer
                  bodyStyle={{ paddingBottom: 80 }}
                  title="Update"
                  width={window.innerWidth > 768 ? '85%' : '85%'}
                  onClose={closeDrawer}
                  open={drawerVisible}
                  closable={true}
                >
                  <Card
                    headStyle={{
                      textAlign: 'center',
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                    size="small"
                  >
                    <DestinationForm
                      key={Date.now()}
                      isUpdate={true}
                      destinatonData={selectedDestinationData}
                      closeForm={closeDrawer}
                    />
                  </Card>
                </Drawer>
              {/* </Card> */}
            </TabPane>
          )}
          {isPermissionExist([67]) && (
            <TabPane tab="Anchorage" key="anchorage">
              <AnchorageGrid />
            </TabPane>
          )}
          {isPermissionExist([51]) && (
            <TabPane tab="Shipping Terms" key="shippingTerms">
              <ShippingTermsGrid />
            </TabPane>
          )}
          {isPermissionExist([71]) && (
            <TabPane tab="Jetty" key="jetty">
              <JettyGrid />
            </TabPane>
          )}
        </Tabs>
      </Card>
    </>
  );
}

export default DestinationGrid;
