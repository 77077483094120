import { AccountsActLedDto, AccountsTransactionTypeEnum, GroupEntryDto, GroupEntryService } from '@exportx/shared-models-and-services';
import { AccountsService } from '@exportx/shared-models-and-services'
import { ClassValidator } from '@exportx/ui-utils';
import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../common';

const { Option } = Select;

export const AccountCreationF = (props) => {
  const [form] = Form.useForm();
  const { formatMessage: fm } = useIntl();
  const service = new AccountsService();
  const groupService = new GroupEntryService();
  const [accountsData, setAccountsData] = useState<AccountsActLedDto>();
  const [formRef] = Form.useForm();
  const navigate = useNavigate();
  const pathToreDirect = "/accounts";
  const [entryData, setEntryData] = useState<any[]>([])
  const [subGroupData, setSubGroupData] = useState<any[]>([])
  const { authContext } = useAuthState()
  const { state }: any = useLocation()
  const { rowData } = state ? state : { rowData: null };
  const classValidator = new ClassValidator();

  useEffect(()=>{
    let permission = false;
     if(state){
      permission = isPermissionExist([792])
     } else {
      permission = isPermissionExist([790])
     }
     if(!permission) navigate('/')
  },[])

  useEffect(() => {
    // setAccountsData(props.initialValues)
    getAllMainGroups();
    getAllSubGroups();
  }, [])

  const saveAccounts = () => {
    form.validateFields().then(values => {
      const accountDto = new AccountsActLedDto(values.actLedName, values.actLedRemarks, values.actLedActive, values.actLedOpBalType, authContext.defaultPlant, values.actLedActGrpId, authContext.user.userName, values.actLedOpBal, rowData?.actLedId,rowData?.versionFlag);
      
      service.createAccounts(accountDto).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setTimeout(() => {
            navigate(pathToreDirect)
          }, 1000);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    })

  }


  const getAllMainGroups = () => {
    groupService.dropDownGroupEntry().then(res => {
      if (res.status) {
        setEntryData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getAllSubGroups = () => {
    groupService.getAllSubGroups().then(res => {
      if (res.status) {
        setSubGroupData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const clearData = () => {
    form.resetFields();
  }

  return (
    <div>
      <Card title={<span> Account Details</span>}
        extra={<Button type='primary' onClick={() => navigate(pathToreDirect)}>View</Button>}>
        <Card>
          <Form initialValues={rowData} layout="vertical" autoComplete="off" form={form}>
            <Row>
              <Col span={8} offset={3}>
                <Form.Item label="Account Name" 
                rules={[{ required: true, message: "Account Name is Required" },
                ...classValidator.alphaSpaces(),
               ]}
               
                  name="actLedName" style={{ width: '80%' }}>
                  <Input allowClear placeholder='Enter Account Name' />
                </Form.Item>
              </Col>

              <Col span={8} offset={1}>
                <Form.Item label="Group"
                  name="actLedActGrpId" style={{ width: '80%' }}>
                  <Select
                    placeholder='Select Group'
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  >
                    {React.Children.toArray(subGroupData.map(item => {
                      return <Option value={item?.actGrpId}>{item?.actGrpName}</Option>
                    }))}
                  </Select>
                </Form.Item>
              </Col>

            </Row>
            <Row>

              <Col span={8} offset={3}>
                <Form.Item label="Remarks"
                  name="actLedRemarks" style={{ width: '80%' }}>
                  <Input allowClear placeholder='' />
                </Form.Item>
              </Col>

              <Col span={8} offset={1} >
                <Form.Item label="Opening Balance" style={{ width: '80%' }}
                  name="actLedOpBal"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only Numbers Accepted"
                    },
                  ]}>
                  <Input allowClear placeholder='Enter Balance' />
                </Form.Item>
              </Col>

            </Row>

            <Row>
              <Col span={8} offset={3}>
                <Form.Item name={'actLedOpBalType'} label={'Account Type'} style={{ width: '80%' }}>
                  <Select allowClear showSearch placeholder='Select BL Type' >
                    {React.Children.toArray(Object.keys(AccountsTransactionTypeEnum).map(key => {
                      return <Option value={AccountsTransactionTypeEnum[key]}>{AccountsTransactionTypeEnum[key]}</Option>
                    }))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3} offset={1} >
                <Form.Item name="actLedActive" valuePropName="checked" style={{ paddingTop: 25 }}>
                  <Checkbox>Show in Payables</Checkbox>
                </Form.Item>

              </Col>


            </Row>
            <br /><br />
            <Row>
              <Col span={8} >
                {/* <Button type='primary' danger>Clear</Button> */}
                <Button htmlType="button" style={{ float: 'left' }} onClick={() => {
                clearData();
              }}>Clear</Button>
                </Col>
              <Col span={8} ></Col>
              <Col span={8} ><Button onClick={saveAccounts} type='primary'>Submit</Button></Col>
            </Row>
          </Form>
        </Card>
      </Card>
    </div>
  )
}

export default AccountCreationF







// <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1} style={{ display: 'none' }}>
// <Form.Item label="Main Group"
//   name="actledActgrpId" style={{ width: '100%' }} >
//   <Select
//     placeholder='Select Main Group'
//     allowClear
//     showSearch
//     //  disabled={true}
//     optionFilterProp="children"
//     filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
//   >
//     {entryData.map(item => {
//       return <Option value={item.actGrpId}>{item.actGrpName}</Option>
//     })}
//   </Select>
// </Form.Item>
// </Col>
