import { DeleteFilled } from '@ant-design/icons';
import {
  AllocatedTypeEnum,
  BusinessNoBargeMappingDto,
  GetAllBargeMappingDataDto,
} from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Col,
  Form,
  FormListFieldData,
  Row,
  Select,
  Table,
} from 'antd';
import { commonColumns } from './common-columns';
import { RedraftForm } from './redraft-form';

interface IPropsBargeMapAllocForm {
  businessNo: string;
  fields: FormListFieldData[];
  add: (defaultValue?: any, insertIndex?: number) => void;
  remove: (index: number | number[]) => void;
  bargeAllocatedData: any[];
  allocationDataMap: Map<string, GetAllBargeMappingDataDto[]>;
  selectedAllocations: Map<number, string>;
  setSelectedAllocations: React.Dispatch<
    React.SetStateAction<Map<number, string>>
  >;
  selectedRowKeysData: Map<string, any>;
  setSelectedRowKeysData: React.Dispatch<
    React.SetStateAction<Map<string, any>>
  >;
  expandedRowKeys: string[];
  setExpandedRowKeys: React.Dispatch<React.SetStateAction<string[]>>;
  formValuesData: Map<string, any>;
  setFormValuesData: React.Dispatch<React.SetStateAction<Map<string, any>>>;
  getBargesAllocatedData: (allocationId: string[]) => void;
}

const { Option } = Select;
export const BargeMapAllocForm = (props: IPropsBargeMapAllocForm) => {
  const {
    businessNo,
    fields,
    add,
    remove,
    bargeAllocatedData,
    allocationDataMap,
    selectedAllocations,
    setSelectedAllocations,
    selectedRowKeysData,
    setSelectedRowKeysData,
    expandedRowKeys,
    setExpandedRowKeys,
    formValuesData,
    setFormValuesData,
    getBargesAllocatedData,
  } = props;
  //const [noDataAllocationIds, setNoDataAllocationIds] = useState<string[]>([]);

  // useEffect(() => {
  //     if (selectedAllocations.size) {
  //         for (const [index, allocationId] of selectedAllocations.entries()) {
  //             if (!allocationDataMap.has(allocationId) && !noDataAllocationIds.includes(allocationId)) {
  //                 debouncedSendRequest(allocationId, Number(index));
  //             };
  //         };
  //     };
  // }, [selectedAllocations]);

  // memoize the callback with useCallback
  // we need it since it's a dependency in useMemo below
  const allocationIdOnChangeHandler = (allocationId: string, index: number) => {
    getBargesAllocatedData([allocationId]);
    const updatedMap = new Map(selectedAllocations);
    updatedMap.set(index, allocationId);
    setSelectedAllocations(updatedMap);
  };

  // memoize the debounce call with useMemo
  // const debouncedSendRequest = useMemo(() => {
  //     return debounce(allocationIdOnChangeHandler, 1500);
  // }, [allocationIdOnChangeHandler]);

  const selectedRowOnChange = (
    allocationId: string,
    selectedRow,
    updatedFormValuesData: Map<string, any>,
  ) => {
    if (!formValuesData.has(selectedRow)) {
      const barges = allocationDataMap
        .get(allocationId)
        ?.filter(
          (record) => `${record.id}$@$${record.allocationUId}` === selectedRow,
        )[0];
      updatedFormValuesData.set(
        selectedRow,
        new BusinessNoBargeMappingDto(
          undefined,
          Number(barges?.allocationId),
          barges.allocationUId,
          barges.allocationType || AllocatedTypeEnum.FULL,
          undefined,
          undefined,
          `${barges?.availableQuantity ?? 0}`,
          undefined,
          undefined,
          undefined,
          businessNo,
          barges?.bargeId ? Number(barges?.bargeId) : undefined,
          barges?.id,
          undefined,
          barges.bargeNomination,
          undefined,
          undefined,
          Number(barges.pcQualities),
          undefined,
          Number(barges.bargeQuantityInMt),
          undefined,
          undefined,
          undefined,
          barges.loadingDate,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        ),
      );
    }
    return updatedFormValuesData;
  };

  const rowSelection = (allocationId: string): any => {
    return {
      selectedRowKeys: selectedRowKeysData.get(allocationId),

      onChange: (selectedRowKeys, selectedRows) => {
        // Determine which rows to expand or collapse
        const newExpandedRowKeys = selectedRowKeys.filter(
          (key) => !expandedRowKeys.includes(key),
        );
        const collapsedRowKeys = expandedRowKeys.filter(
          (key) => !selectedRowKeys.includes(key),
        );

        // Update expanded row keys based on selection
        setExpandedRowKeys(
          [...expandedRowKeys, ...newExpandedRowKeys].filter(
            (key) => !collapsedRowKeys.includes(key),
          ),
        );

        if (selectedRowKeys.length) {
          const updatedMap = new Map(selectedRowKeysData);
          updatedMap.set(allocationId, selectedRowKeys);
          setSelectedRowKeysData(updatedMap);

          let updatedFormValuesData = new Map(formValuesData);
          for (const selectedRow of selectedRowKeys) {
            updatedFormValuesData = selectedRowOnChange(
              allocationId,
              selectedRow,
              updatedFormValuesData,
            );
          }
          const barges = allocationDataMap
            .get(allocationId)
            .map((record) => `${record.id}$@$${record.allocationUId}`);
          const presentSelected = barges.filter(
            (uniqueKey) => !selectedRowKeys.includes(uniqueKey),
          );
          for (const selected of presentSelected) {
            updatedFormValuesData.delete(selected);
          }
          setFormValuesData(updatedFormValuesData);
        } else {
          const barges = allocationDataMap
            .get(allocationId)
            .map((record) => `${record.id}$@$${record.allocationUId}`);
          let updatedFormValuesData = new Map(formValuesData);
          for (const selected of barges) {
            updatedFormValuesData.delete(selected);
          }
          const updatedMap = new Map(selectedRowKeysData);
          setFormValuesData(updatedFormValuesData);
          updatedMap.delete(allocationId);
          setSelectedRowKeysData(updatedMap);
        }
      },
    };
  };

  const onRemoveHandler = (index, callBack) => {
    const updatedMap = new Map(selectedAllocations);
    updatedMap.delete(index);
    setSelectedAllocations(updatedMap);
    const updatedMapRowKeys = new Map(selectedRowKeysData);
    updatedMapRowKeys.delete(index);
    setSelectedRowKeysData(updatedMapRowKeys);
    callBack(index);
  };

  // Function to handle expand icon click
  const handleExpand = (expanded, record) => {
    const key = record.id + '$@$' + record.allocationUId;
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, key]);
    } else {
      setExpandedRowKeys(expandedRowKeys?.filter((el) => el !== key));
    }
  };

  return (
    <>
      <Row className="mb-12">
        <span style={{ color: '#64748B' }}>
          Select Allocation ID to map the barges
        </span>
      </Row>

      {fields.map(({ key, name, ...restField }, index) => (
        <>
          {/* <Form.Item label='Allocation ID' name={[name, 'allocationId']}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: '150px' }}
                            placeholder='Please Select Allocation Id'
                            onChange={(value) => allocationIdOnChangeHandler(value, index)}
                        >
                            {bargeAllocatedData.map(item => {
                                return <Option
                                    disabled={Array.from(selectedAllocations.values()).includes(item.id)}
                                    value={item.id} >{item.displayAllocationId}</Option>
                            })}
                        </Select>
                    </Form.Item> */}

          <Card
            className="card-br-8 mb-12"
            headStyle={{ border: 'none', marginTop: '12px' }}
            key={key}
            extra={
              index !== 0 && (
                <DeleteFilled
                  onClick={() => onRemoveHandler(index, remove)}
                  className="delete-filled"
                />
              )
            }
            title={
              <Form.Item label="Allocation ID" name={[name, 'allocationId']}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  style={{ width: '171.5px' }}
                  placeholder="Please Select Allocation Id"
                  onChange={(value) =>
                    allocationIdOnChangeHandler(value, index)
                  }
                >
                  {bargeAllocatedData
                    .sort((b, a) => {
                      // Check if the displayAllocationId values are numeric or not
                      const isNumeric =
                        !isNaN(a.displayAllocationId) &&
                        !isNaN(b.displayAllocationId);
                      if (isNumeric) {
                        return b.displayAllocationId - a.displayAllocationId; // Sort numeric values in descending order
                      } else {
                        return b.displayAllocationId.localeCompare(
                          a.displayAllocationId,
                        ); // Sort strings in descending order
                      }
                    })
                    .map((item) => (
                      <Option
                        disabled={Array.from(
                          selectedAllocations.values(),
                        ).includes(item.id)}
                        value={item.id}
                        key={item.id} // Add a unique key for each option
                      >
                        {item.displayAllocationId}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }
          >
            {/* <Row> */}
            {/* <Col span={22}> */}
            {selectedAllocations.has(index) && (
              <Table
                size="small"
                className="contracts-list mb-12 overflowX"
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection(selectedAllocations.get(index)),
                }}
                expandable={{
                  expandedRowKeys,
                  onExpand: handleExpand,
                  expandedRowRender: (record) => (
                    <div className="expanded-row">
                      <RedraftForm
                        index={index}
                        record={record}
                        formValuesData={formValuesData}
                        setFormValuesData={setFormValuesData}
                      />
                    </div>
                  ),
                }}
                rowClassName={(record) =>
                    selectedAllocations.get(index)?.includes(record.id)
                      ? 'selected-row'
                      : ''
                  }
                rowKey={(record) => record.id + '$@$' + record.allocationUId}
                dataSource={allocationDataMap.get(
                  selectedAllocations.get(index),
                )}
                columns={[...commonColumns]}
                pagination={false}
              />
            )}
            {/* </Col> */}
            {/* <Col span={2}>
                            {index !== 0 && <DeleteFilled onClick={() => onRemoveHandler(index, remove)} style={{ color: "red", fontSize: 20 }} />}
                        </Col> */}
            {/* </Row> */}
          </Card>
        </>
      ))}

      {!(fields.length >= bargeAllocatedData.length) && (
        <Button className="new-button" onClick={() => add()}>
          Save and Add Another
        </Button>
      )}

      {/* {!(fields.length >= bargeAllocatedData.length) && <Form.Item>
                <Button onClick={() => add()}>Add More</Button>
            </Form.Item>} */}
    </>
  );
};

export default BargeMapAllocForm;
