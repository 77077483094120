import { PriceTypeEnum } from "@exportx/shared-models-and-services";
import { getLocalFormat } from "@exportx/ui-utils";
import { Card, Col, Descriptions, Divider, Flex, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from 'moment';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
interface IBargePreviewProps {
  getAllBarge: any,
  surveyorDropDownData: any

}

export const BargePreview = (props: IBargePreviewProps) => {
  const { getAllBarge } = props;
  const [surveyorData, setSurveyorData] = useState([]);

  const { bargeDetails, bargeOthers, bargeSurveyor, bargeTiming } = getAllBarge
  const { surveyorDropDownData = [] } = props


  console.log('BargePreview ==>', props)

  useEffect(() => {
    if (bargeSurveyor) {
      setSurveyorData([
        {
          surveyorType: 'Supplier',
          surveyorName: surveyorDropDownData.filter((e) => bargeSurveyor?.supplierSurveyor == e.bpId)[0]?.bpName,
          analysisType: bargeSurveyor?.supplierSurveyorAnalysisType
        },
        {
          //TODO:change dynamic
          surveyorType: 'Internal',
          surveyorName: surveyorDropDownData.filter((e) => bargeSurveyor?.internalSurveyor == e.bpId)[0]?.bpName,
          analysisType: bargeSurveyor?.internalSurveyorAnalysisType
        },
        {
          surveyorType: 'WithNess',
          surveyorName: surveyorDropDownData.filter((e) => bargeSurveyor?.witnessSurveyor == e.bpId)[0]?.bpName,
          analysisType: bargeSurveyor?.witnessAnalysisType
        },
        {
          surveyorType: 'Additional Surveyor',
          surveyorName: surveyorDropDownData.filter((e) => bargeSurveyor?.additionalSurveyor == e.bpId)[0]?.bpName,
          analysisType: bargeSurveyor?.additionalAnalysisType
        },
        {
          surveyorType: "additionalSurveyor1",
          surveyorName: surveyorDropDownData.filter((e) => bargeSurveyor?.additionalSurveyor1 == e.bpId)[0]?.bpName,
          analysisType: bargeSurveyor?.additionalAnalysisType1
        },
      ]);
    }
  }, [bargeSurveyor])



  return (
    <>

      <Row gutter={[14, 12]}>
        <Col span={13} xs={24} md={24} lg={24} xl={13}>
          <Card bordered={false} className="ant-card-height" >
            <Descriptions
              colon={false}
              layout="vertical"
              title="Purchase Contract"
              items={[{
                key: '1',
                label: 'Name &ID',
                children: <div>
                  <div>{bargeDetails?.purchaseContractNo}</div>
                </div>,
              },
              {
                key: '2',
                label: 'Laycan, Terms & Quality',
                children: <div>
                  <div>{bargeDetails?.laycanFromDate && bargeDetails?.laycanToDate && `${moment(bargeDetails?.laycanFromDate).format('DD-MMM-YYYY')} to ${moment(bargeDetails?.laycanToDate).format('DD-MMM-YYYY')} `}</div>
                  <span>  {bargeDetails?.purchasingTerm}, {bargeDetails?.quality}</span>
                </div>,
              },
              {
                key: '3',
                label: 'Type',
                children: <span>{bargeDetails?.purchaseType ?? '-'}</span>,
              },
              ]} />
            <br />

            <Descriptions
              colon={false}
              layout="vertical"
              title="Barge Vendor"
              items={[{
                key: '1',
                label: 'Vendor Name',
                children: <span>{bargeDetails?.bargeVendorName ?? '-'}</span>
              },
              {
                key: '2',
                label: 'Agreement',
                children: <span>{bargeDetails?.bargeAgreementNo ?? '-'}</span>
              },
              {
                key: '3',
                label: 'Loading  Jetty',
                children: <span>{bargeDetails?.loadingJetty ?? '-'}</span>,
              },
              ]} />

            {/* <Descriptions
                  colon={false}
                  layout="vertical"
                  title="Shipping Instructions(SI)"
                  items={[{
                    key: '1',
                    label: 'SI Date',
                    children: <Link href={links} style={{ color: '#0083C9' }}>{getAllBarge?.bargeVendorName}</Link>
                  },
                  {
                    key: '2',
                    label: 'SI Quantity',
                    children: <Link href={link} style={{ color: '#0083C9' }}>{getAllBarge?.purchaseContractNo}</Link>,
                  },
                  {
                    key: '3',
                    label: 'Documents',
                    children: <span>{getAllBarge?.loadingJetty ?? '-'}</span>,
                  },
                  ]} /> */}

          </Card>
        </Col>
        <Col span={11} xs={24} md={24} lg={24} xl={11} >
          <Card bordered={false} className="ant-card-height" >
            <Descriptions
              colon={false}
              layout="vertical"
              title="Price"
              items={[{
                key: '1',
                label: 'Base Price (MT)',
                children: <span>
                  {bargeDetails?.bargingType === PriceTypeEnum.PER_MT ?
                    <span>{getLocalFormat(+bargeDetails?.bargingBasePrice, bargeDetails?.bargingBasePriceCurrency)}</span>
                    :
                    <span>{getLocalFormat(+bargeDetails?.lumpSumBasePrice, bargeDetails?.lumpSumBasePriceCurrency)}</span>
                  }
                </span>
              },
              {
                key: '2',
                label: 'Type',
                children: <span> {bargeDetails?.bargingType}</span>
              },
              {
                key: '3',
                label: 'TDS',
                children: <span> {bargeDetails?.pph5}</span>,
              },
              ]} />
            <br />
            <Descriptions
              colon={false}
              layout="vertical"
              title="Quantity"
              items={[{
                key: '1',
                label: 'Quantity (MT)',
                children: <span>{bargeDetails?.bargeQuantityInMt}</span>
              },
              {
                key: '2',
                label: 'Loaded Quantity (MT)',
                children: <h6 style={{ color: '#0EAD49' }}>{bargeDetails?.loadedQuantityInMt}</h6>,
              },
              {
                key: '3',
                label: 'Return Cargo Quantity (MT)',
                children: <h6 style={{ color: '#0EAD49' }}>{bargeDetails?.returnCargoQuantityInMt}</h6>,
              },
                //   {
                //     key: '3',
                //     label: 'Barges ID',
                //     children: <span onClick={() => redirectToFull(getAllBarge?.returnBarge)}><Link >
                //       {getAllBarge?.returnBargeId ? SequenceUtils.formatNumberToSpecificLength(getAllBarge?.returnBargeId?.toString()) : '-'}</Link></span>
                //   },
              ]} />


            <Descriptions
              colon={false}
              title='Dead Weight'
              layout="vertical"
              items={[{
                key: '1',
                label: 'Dead Weight',
                children: <span>{bargeDetails?.deadFreight ?? '-'}</span>
              },
              {
                key: '2',
                label: 'Incurred',
                children: <span>  {bargeDetails?.deadFreightIncurred ?? '-'}</span>
              },
              {
                key: '3',
                label: 'Applicability',
                children: <span> {bargeDetails?.deadFreightApplicability ?? '-'}</span>,
              },
              ]} />

          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 12]}>
        <Col span={13} xs={24} md={24} lg={24} xl={13}>
          <Card bordered={false} className="ant-card-height" >
            <Descriptions
              colon={false}
              layout="vertical"
              title="Barge Timing"
              items={[{
                key: '1',
                label: 'Loading Start',
                children: <span>{bargeTiming?.loadingDate ? moment(bargeTiming?.loadingDate).format('DD-MM-YYYY HH:mm') : '-'}</span>,
              },
              {
                key: '2',
                label: 'Loading Completion',
                children: <div>
                  <span>  {bargeTiming?.completionTime ? moment(bargeTiming?.completionTime).format('DD-MM-YYYY HH:mm') : '-'}</span>
                </div>,
              },
              {
                key: '3',
                label: 'NOR Anchorage',
                children: <span> {bargeTiming?.norAnchorage ? moment(bargeTiming?.norAnchorage).format('DD-MM-YYYY HH:mm') : '-'}</span>,
              },
              ]} />
          </Card>
        </Col>
        <Col span={11} xs={24} md={24} lg={24} xl={11} >
          <Card bordered={false} className="ant-card-height" >
            <h6>Surveyor Details</h6>
            <Row gutter={[16, 12]}>
              {surveyorData?.map((surveyor) => (
                <Col span={24} xs={24} md={24} lg={12} xl={12}>
                  <Typography className="label">{surveyor?.surveyorType}</Typography>
                  <Flex align="center" justify="space-between" className="standard-row">
                    <Typography style={{ width: '70%' }}>{surveyor?.surveyorName ?? '-'}</Typography>
                    <Typography className="specification-standard">{surveyor?.analysisType ?? 'N/A'}</Typography>
                  </Flex>
                </Col>
              ))}
            </Row>
          </Card>

        </Col>
      </Row>
      <br />
      <Row gutter={[16, 12]}>
        <Col span={13} xs={24} md={24} lg={24} xl={13}>
          <Card title='Other Details' className="ant-card-height" bordered={false} styles={{ body: { paddingTop: 2 } }}>
            <Descriptions
              colon={false}
              layout="vertical"
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label="Chemical Spray">
                {bargeOthers?.chemicalSpray}
              </Descriptions.Item>
              <Descriptions.Item label="Chemical Spray Value">
                {getLocalFormat(bargeOthers?.chemicalSprayPrice, bargeOthers?.chemicalSprayCurrency)}
              </Descriptions.Item>
            </Descriptions>
            <br />
            <Card title='Remarks' bordered={false} className="remarks-card no-shadow" styles={{ body: { paddingTop: 2 } }}>
              <Divider dashed style={{ marginTop: 0 }} />
              <pre>
                {bargeOthers?.remarks ?? '-'}
              </pre>
            </Card>
          </Card>
        </Col>
      </Row>
    </>
  )

}
export default BargePreview