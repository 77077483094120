import { BusinessNumberService, CPSPreviewDto, ContractModesEnum, ContractStatusEnum, ContractTypesEnum, ProcurementContractService, ProcurementContractTypeDto, ProcurementContractTypeEnum, PurchaseContractNoReqDto, VendorAdvService, laycanApprovalReqDto } from '@exportx/shared-models-and-services'
import { Button, Col, Form, MenuProps, Modal, Row, Table, TableColumnsType, Tabs } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { AlertMessages, StatusTag, useAuthState } from '../../../common'
import StatusApprovalsButton from '../../../common/status-tag/status-approvals-button-component'
import VendorAdvance from '../../masters/components/advance/vendoradvance-form'
import { ProcurementServiceContract } from '../procurement-service'
import AssociatedBusinessNumbers from './pc-associated-business-numbers'
import TabPane from 'antd/es/tabs/TabPane'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

interface IAllPurchaseContractDetailedViewProps {
  contractId: any;
  plantCode: string;
  bpId?: any;
  pcQuantityTotal?: number;
  contractType: ContractTypesEnum;
  allocateData?: any;
  contractDetails?: any;
}

const AllPurchaseContractDetailedView = (props: IAllPurchaseContractDetailedViewProps) => {
  const navigate = useNavigate();
  const { authContext } = useAuthState();
  const [procurementformRef] = Form.useForm();
  const [procurement, setProcurement] = useState<ProcurementContractTypeDto[]>();
  const [vendor, setVendor] = useState<any>();
  const [vendorAdvanceData, setVendorAdvanceData] = useState<any>({ "purchaseContractNo": props.contractId, "vendorId": props.bpId });
  const [vendorAdvanceFormRef] = Form.useForm();
  const { formatMessage: fm } = useIntl();
  const [open, setOpen] = useState(false);
  const [procurementModal, setProcurementModal] = useState(false);
  const [procurementProps, setProcurementProps] = useState<any>();
  const [procurementInitialValues, setProcurementIntialValues] = useState({ quality: [''], filesData: [], lc: [''], others: [''], telegraphicTransfer: [''], });
  const businessService = new BusinessNumberService();
  const procurementService = new ProcurementContractService();
  const vendorService = new VendorAdvService();
  let pathToreDirect = '/po-detail-view';

  const [dummyState, setDummyState] = useState<number>(1);
  const [dummy, setDummy] = useState<number>(1)

  const { contractId, plantCode, bpId, pcQuantityTotal, contractType, allocateData, contractDetails } = props

  useEffect(() => {
    if (contractId) {
      getProcurementServiceFees();
      getAllAdvanceData();
    }
  }, [contractId]);

  const procurementCreate = () => {
    procurementformRef.validateFields().then(values => {
      procurementService.createProcurementContract({ ...values, plantCode: authContext.defaultPlant }).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setProcurementModal(false);
          getProcurementServiceFees();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err.message)
      })
    })
  }

  const procurementStatusApproval = (status: ContractStatusEnum, id: string) => {
    const req = new CPSPreviewDto(id, authContext.defaultPlant, undefined, status, authContext.user.userName)
    procurementService.procurementStatusApproval(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getProcurementServiceFees();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      console.log(err.message);
    })
  }

  const laycanApprovalOperations = (pcID: string, id: string, status: ContractStatusEnum) => {

    const payload = new laycanApprovalReqDto(pcID, id, status, authContext.defaultPlant, authContext.user.userName);

    procurementService.laycanDataApprovalOperations(payload).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getProcurementServiceFees();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }

  const getProcurementServiceFees = () => {
    const req = new PurchaseContractNoReqDto(contractId, authContext.defaultPlant, null);
    procurementService.getProcurementServiceFees(req).then(res => {
      console.log('res', res)
      if (res.status) {
        setProcurement(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getAllAdvanceData = () => {
    const req = new PurchaseContractNoReqDto(contractId, authContext.defaultPlant)
    vendorService.getAllAdvanceData(req).then(res => {
      if (res.status) {
        setVendor(res.data);
      }

    }).catch(err => {

      AlertMessages.getErrorMessage(err.message);
    })
  }

  const vendorAdvanceSaveData = () => {
    vendorAdvanceFormRef.validateFields().then(values => {
      vendorService.create({ ...values, plantCode: authContext.defaultPlant }).then(res => {

        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          // redirectToFullView(contractId,authContext.defaultPlant)
          getAllAdvanceData()
          vendorAdvanceFormRef.resetFields();
          setDummy((prev) => prev + 1)
          setTimeout(() => {
            setOpen(false);
          }, 1000)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      }
      )
    })
  }



  const handleCancel = () => {
    setOpen(false);
    setDummy((prev) => prev + 1)
    vendorAdvanceFormRef.resetFields()
  }




  const procurementColumns: TableColumnsType<any> = [

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      sorter: (a, b) => a.contractNo != null ? a.contractNo.localeCompare(b.contractNo) : "Have Null Values",
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      render: (value, record: any, index) => {
        const pathToreDirect = record.contractType === ProcurementContractTypeEnum.Procurement_Fees
          ? '/procurement-contract-detailedView'
          : record.contractType === ProcurementContractTypeEnum.Coal_Service_Fees
            ? '/coalServiceFee-detailedView'
            : undefined;
        const link = `/#${pathToreDirect}?cpcId=${record.pcID}`;
        return <a href={link} target="_blank" rel="noopener noreferrer" className='link'>
          {record.contractNo}
        </a>;
      }
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor',
      sorter: (a, b) => a.vendorName != null ? a.vendorName.localeCompare(b.vendorName) : "Have Null Values",
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      render: (value, record: any, index) => {
        return <span>{value} </span>;
      }
    },
    {
      title: 'Laycan',
      dataIndex: 'layCan',
      onCell: (record: any) => ({
        style: { whiteSpace: 'nowrap' }
      }),
      render: (value, record) => { return `${moment(value[0]).format('DD-MMM-YYYY')} to ${moment(value[1]).format('DD-MMM-YYYY')}` },
    },
    {
      title: 'Inco Term',
      dataIndex: 'incoterm',
    },
    {
      title: 'Quantity MT',
      dataIndex: 'quantityInMt',
      align: 'right',
      // sorter: (a, b) => a.contractDate != null ? a.contractDate.localeCompare(b.contractDate) : "Have Null Values",
      render: (value) => {
        return <>
          {Math.round(value)}
        </>
      }

    },

    {
      title: 'Deduction Bases',
      dataIndex: 'priceType',
      align: 'right',
      // sorter: (a, b) => a.priceType != null ? a.priceType.localeCompare(b.priceType) : "Have Null Values",

    },
    {
      title: 'Price PMT',
      dataIndex: 'pricePmt',
      align: 'right',
      // sorter: (a, b) => a.pricePmt != null ? a.pricePmt.localeCompare(b.pricePmt) : "Have Null Values",

    },
    // {
    //     title: 'Total Price',
    //     dataIndex: 'totalPrice',
    //     sorter: (a, b) => a.pricePMT != null ? a.pricePMT.localeCompare(b.pricePmt) : "Have Null Values",
    // },
    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      // sorter: (a, b) => a.pricePmt != null ? a.pricePmt.localeCompare(b.pricePmt) : "Have Null Values",
      render: (value, record) => {
        return <>
          <StatusTag status={value} />
        </>
      }
    },
    {
      title: 'Action',
      dataIndex: 'approvalStatus',
      // sorter: (a, b) => a.pricePmt != null ? a.pricePmt.localeCompare(b.pricePmt) : "Have Null Values",
      render: (value, record) => {
        return <>
          {record?.status === ContractStatusEnum.ACTIVE && record.approvalStatus === ContractStatusEnum.DRAFT &&
            <>
              <Button onClick={() => laycanApprovalOperations(record?.pcID, record.qualityId, ContractStatusEnum.PENDING_APPROVAL)}
                type="primary" style={{ marginRight: "16px" }} className='new-button'
              >
                Send For Approval
              </Button>
            </>
          }
          {record?.status === ContractStatusEnum.ACTIVE && record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL &&
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', width: 170 }}>
              <Button size="small" className='approve' style={{ marginRight: "16px" }}
                onClick={() => laycanApprovalOperations(record?.pcID, record.qualityId, ContractStatusEnum.ACTIVE)}
              >
                <FontAwesomeIcon icon={faCheckCircle} />Approve
              </Button>

              <Button size="small" className='reject' onClick={() => laycanApprovalOperations(record?.pcID, record.qualityId, ContractStatusEnum.DRAFT)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
            </Row>
          }
        </>
      }
    },

    {
      title: 'Contract Status',

      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),

      render: (value, record: any, index) => {

        return <>
          <StatusApprovalsButton status={record.status} statusApproval={procurementStatusApproval} id={record.pcID} />
        </>

        // return <span><Button type='primary' disabled={record.status === ContractStatusEnum.DRAFT ? false : true} onClick={() => procurementStatusApproval(record.pcID)}>Approve</Button></span>;
      }
    }

  ]
  const vendorAdvanceColumns: TableColumnsType<any> = [
    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      sorter: (a, b) => a.contractNo != null ? a.contractNo.localeCompare(b.contractNo) : "Have Null Values",

      render: (value, record) => {
        const link = `/#/advanceDetailView?id=${record.id}`
        return <> {
          <a href={link} className='link' >{record.contractNo}</a>
        }
        </>
      }

    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      sorter: (a, b) => a.vendorName != null ? a.vendorName.localeCompare(b.vendorName) : "Have Null Values",

    },
    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      render: (text, record) => { return moment(text).format("DD-MMM-YYYY") },
      sorter: (a, b) => a.contractDate != null ? a.contractDate.localeCompare(b.contractDate) : "Have Null Values",

    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => a.advanceAmount != null ? a.amount.localeCompare(b.amount) : "Have Null Values",
      render: (value, record) => {
        if (value) {
          return <div style={{ textAlign: 'right' }}>{value} <span style={{ color: '#64748B' }}>{record.negotiationCurrency}</span></div>
        } else {
          return '';
        }

      }

    },
    // {
    //     title: 'Currency',
    //     dataIndex: 'negotiationCurrency',
    //     sorter: (a, b) => a.negotiationCurrency != null ? a.amount.localeCompare(b.negotiationCurrency) : "Have Null Values",

    // }

  ]

  const procurementTableDataProcessing = (values: any[]) => {
    const procurementData = [];
    values?.forEach((rec) => {
      rec.quality.forEach((qualityRecord, index) => {
        procurementData.push(
          {
            qualityId: qualityRecord.qualityId,
            quantity: qualityRecord.quantity,
            quantityInMt: qualityRecord.quantityInMt,
            priceType: qualityRecord.priceType,
            pricePmt: qualityRecord.pricePmt,
            layCan: qualityRecord.layCan,
            totalPrice: qualityRecord.totalPrice,
            incoterm: qualityRecord.incoterm,
            rowSpan: index === 0 ? rec.quality.length : 0,
            pcID: rec.pcID,
            contractNo: rec.contractNo,
            vendor: rec.vendor,
            contractType: rec.contractType,
            status: rec.status,
            approvalStatus: qualityRecord.approval_status
          }
        )
      })
    })
    return procurementData
  }


  const items = Object.values(ProcurementContractTypeEnum).filter(value => { return value !== ProcurementContractTypeEnum.Marketing_Fees }).map(bpCat => {
    return {
      label: `${bpCat}`,
      key: `${bpCat}`,
    }
  })
  const menuOnClickHandler: MenuProps['onClick'] = ({ key }) => {
    setProcurementProps(key); setProcurementModal(true)
  };

  const rowClassName = (row: any, index) => {
    return index % 2 === 0 ? 'row-even' : 'row-odd';
  };

  console.log('procurementTableDataProcessing(procurement)', procurementTableDataProcessing(procurement))

  return (
    <>

      {/* <AssociatedBusinessNumbers contractId={contractId} pcQuantityTotal={pcQuantityTotal} contractType={ContractModesEnum.PURCHASE} />

            <Card title={<span style={{ float: "left" }}>Procurement Service Summary</span>}
                extra={<Dropdown.Button type='primary' onClick={() => setDummyState((prev) => prev + 1)}
                    menu={{ items, onClick: menuOnClickHandler }}
                >Procurement Contract Service</Dropdown.Button>}>
                <Table
                    columns={procurementColumns}
                    bordered dataSource={procurementTableDataProcessing(procurement)}
                    pagination={false}
                >

                </Table>
            </Card>

            <Card
                extra={<Button type='primary' onClick={() => { setOpen(true); }}>Add New Vendor Advance</Button>}
            >
                <Table columns={vendorAdvanceColumns} dataSource={vendor} pagination={false}></Table>
            </Card> */}

      <Tabs>
        <TabPane tab="Procurement Service Summary" key="1">
          <Table
            className='purchase-Details'
            columns={procurementColumns}
            dataSource={procurementTableDataProcessing(procurement)}
            pagination={false}
            rowClassName={rowClassName}
            size='small'
            style={{ overflowX: 'auto' }}
          >
          </Table>
        </TabPane>
        <TabPane tab="Vendor Advance" key="2">
          <Table className='purchase-Details' columns={vendorAdvanceColumns} dataSource={vendor} pagination={false} rowClassName={rowClassName} style={{ overflowX: 'auto' }}></Table>
        </TabPane>
        <TabPane tab="Allocation" key="3">
          <AssociatedBusinessNumbers contractId={contractId} pcQuantityTotal={pcQuantityTotal} contractType={ContractModesEnum.PURCHASE} allocateData={allocateData} contractDetails={contractDetails} />

        </TabPane>

      </Tabs>

      <Modal
        style={{ top: 10 }}
        open={procurementModal}
        onCancel={() => { setProcurementModal(false); procurementformRef.resetFields(); setDummyState((prev) => prev + 1) }}
        okText="Submit"
        key={dummyState + 1}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={null}
        onOk={() => {
          procurementCreate()
        }}
        width={1300}
        footer={null}
      >
        <ProcurementServiceContract setDummyState={setDummyState} key={dummyState} closeDrawer={"PurchaseContract"} getProcurementServiceFees={getProcurementServiceFees} setProcurementModal={setProcurementModal} contractType={procurementProps} contractId={contractId} />
      </Modal>

      <Modal
        open={open}
        okText='Submit'
        onCancel={handleCancel}
        width={1000}

        onOk={() => { vendorAdvanceSaveData() }}
        key={dummy}
      >
        <VendorAdvance bpId={bpId} vendor={contractId} key={dummy} formRef={vendorAdvanceFormRef} initialValues={vendorAdvanceData} layoutType={'vertical'} />
      </Modal>

    </>
  )
}

export default AllPurchaseContractDetailedView