import { BusinessAllocationDropdownDto, BusinessNumberService, CalculationTableDto, ContractIdRequest, ContractModesEnum, CostingTypesEnum, PcPurchaseTypeReq, ProcurementContractTypeEnum, PurchaseContractService, PurchaseDropDownDto, SalesContractService } from '@exportx/shared-models-and-services';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';

const { Option } = Select;

export interface ICoalCostingFilterInitialValues {
    businessNumber: string;
    contractId: string;
    qualityId: string;
    incoterm: string;
    purchaseType: string;
    costingDate: any;
    costingId?: string;
    pcrContractId?: string;
    bnId?: string;
    costingCurrency?:string;
}

interface ICoalCostingFilterComponent {
    loadBargesOnClickHandler?: (values: ICoalCostingFilterInitialValues) => void;
    isBusinessNumberMandatory: boolean;
    initialValues?: ICoalCostingFilterInitialValues;
    contractType: ContractModesEnum;
    formRef: FormInstance<any>;
    setBargeData?: React.Dispatch<React.SetStateAction<CalculationTableDto[]>>
    pcrContractType?: ProcurementContractTypeEnum;
    costingType?: CostingTypesEnum
}
export const CmSaleFilterComponent = (props: ICoalCostingFilterComponent) => {
    const { authContext } = useAuthState()
    const { costingType, setBargeData, loadBargesOnClickHandler, isBusinessNumberMandatory, formRef, contractType, initialValues, pcrContractType } = props;
    const [businessNumbers, setBusinessNumbers] = useState<BusinessAllocationDropdownDto[]>([]);
    const [purchaseContracts, setPurchaseContracts] = useState<PurchaseDropDownDto[]>([]);
    const [qualitiesData, setQualitiesData] = useState([]);
    const [purchaseTypesData, setPurchaseTypesData] = useState([]);
    const [buttonDisableHandler, setButtonDisableHandler] = useState(true);
    const businessNumberService = new BusinessNumberService();
    const salesContractService = new SalesContractService();

    useEffect(() => {
        getAllSaleContractsForDropDown();
        getAllAllocatedBns();
    }, [contractType]);

    useEffect(() => {
        formRef.setFieldValue('costingCurrency', initialValues?.costingCurrency);
        if (initialValues) {
            handleEditClick();
        };

        if (initialValues?.pcrContractId) {
            formRef.setFieldValue('pcrContractId', initialValues?.pcrContractId);
            businessNoOnChange();
            formRef.setFieldValue('contractId', initialValues?.contractId);
            saleContractOnChange(initialValues?.contractId);
            qualityOnChange(initialValues?.qualityId);
            salesTypeOnChange(initialValues?.purchaseType);
        };
        if (initialValues?.bnId) {
            formRef.setFieldValue('businessNumber', costingType === CostingTypesEnum.PERFORMA ? initialValues?.businessNumber : initialValues?.bnId);
            getAllSaleContractsForDropDown();
            businessNoOnChange(initialValues?.contractId);
            saleContractOnChange(initialValues?.contractId);
            qualityOnChange(initialValues?.qualityId);
            salesTypeOnChange(initialValues?.purchaseType);
        }
    }, [initialValues])

    if (initialValues?.costingDate) {
        initialValues.costingDate = moment(initialValues?.costingDate)
    }

    async function handleEditClick() {
        formRef.setFieldsValue({ contractId: initialValues?.contractId, costingDate: moment(initialValues?.costingDate) });
        await saleContractOnChange(initialValues?.contractId)
        formRef.setFieldsValue({ qualityId: initialValues?.qualityId })
        await qualityOnChange(initialValues?.qualityId)
        formRef.setFieldsValue({ purchaseType: initialValues?.purchaseType })
        salesTypeOnChange(initialValues?.purchaseType)
        bargeOnClickHandler();
    }

    const getAllAllocatedBns = () => {
        businessNumberService.getBusinessAllocationDropDown(new ContractIdRequest('withOutContractId', authContext.defaultPlant)).then(res => {
            if (res.status) {
                setBusinessNumbers(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }



    const businessNoOnChange = async (contractId?: string) => {
        formRef.setFieldValue('contractId', contractId ? contractId : undefined);
        await saleContractOnChange(contractId);
        getAllSaleContractsForDropDown();
        setBargeData([]);
    };

    const saleContractOnChange = async (pcId: string) => {
        if (pcId) {
            getSalesQualityAndLaycanDropdown(pcId);
            const Id = businessNumbers?.filter((rec) => rec.businessNoId === formRef.getFieldValue('businessNumber'))[0]?.allocatedId;
            formRef.setFieldValue('allocatedId', Id)
        } else {
            formRef.setFieldValue('qualityId', '');
            formRef.setFieldValue('purchaseType', '');
            setQualitiesData([]);
            setPurchaseTypesData([]);
            formRef.setFieldValue('allocatedId', undefined)


        }
        setBargeData([]);
    }

    const qualityOnChange = async (pcQualitiesId: string) => {
        if (pcQualitiesId) {
            getScSalesTypesDropDown(pcQualitiesId);
        } else {
            formRef.setFieldValue('purchaseType', '');
            setPurchaseTypesData([]);
        }
    }

    const salesTypeOnChange = (pcTypeId: string) => {
        if (pcTypeId) {
            const pcType = purchaseTypesData?.filter(rec => rec.pcTypeId == pcTypeId)[0];
            formRef.setFieldValue('incoterm', pcType?.purchaseType);
            setButtonDisableHandler(false);
        } else {
            formRef.setFieldValue('incoterm', '');
            setButtonDisableHandler(true);
        }
    }

    const bargeOnClickHandler = () => {
        formRef.validateFields().then(formData => {
            loadBargesOnClickHandler(formData);
        });
    };

    const getAllSaleContractsForDropDown = () => {
        salesContractService.getAllSaleContractsForDropDown({
            plantCode: authContext.defaultPlant,
            businessNoId: formRef.getFieldValue('businessNumber'),
            pcrContractType: pcrContractType,
            pcrContractId: initialValues?.pcrContractId ? initialValues?.pcrContractId : formRef.getFieldValue('pcrContractId')
        }).then(res => {
            if (res.status) {
                setPurchaseContracts(res.data);
                if (!initialValues) {
                    setQualitiesData([]);
                    setPurchaseTypesData([]);
                }


            } else {
                setPurchaseContracts([]);
                setQualitiesData([]);
                setPurchaseTypesData([]);
            }
        }).catch(err => {
            setPurchaseContracts([]);
            setQualitiesData([]);
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        })
    };


    const getSalesQualityAndLaycanDropdown = (pcId: string) => {
        salesContractService.getQualityAndLaycanDropdown(new ContractIdRequest(pcId, authContext.defaultPlant, undefined, pcrContractType, formRef.getFieldValue('businessNumber'))).then(res => {
            if (res.status) {
                setQualitiesData(res.data);
            } else {
                setQualitiesData([])
                setPurchaseTypesData([]);

            }
        }).catch(err => {
            setQualitiesData([])
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        })
    }

    const getScSalesTypesDropDown = (pcQualitiesId: string) => {
        salesContractService.getScSalesTypesDropDown(new PcPurchaseTypeReq(pcQualitiesId, pcrContractType)).then(res => {
            if (res.status) {
                setPurchaseTypesData(res.data);
            } else {
                setPurchaseTypesData([]);

            }
        }).catch(err => {
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        })

    }

    return (
        <div>
            <Form form={formRef} layout='vertical' autoComplete='off' initialValues={initialValues}>

                <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }}>
                        <Form.Item
                            label="Business Number"
                            name="businessNumber"
                            rules={[{ required: isBusinessNumberMandatory, message: 'Business Number is required' }]}
                        >
                            <Select
                                onChange={() => businessNoOnChange()}
                                allowClear
                                showSearch
                                placeholder='Select Business Number'
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {businessNumbers.map(item => {
                                    return <Option style={{ width: 250 }} value={item.businessNoId} key={item.businessNoId}>{item.businessNo}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            hidden
                            label="Allocation Id"
                            name="allocatedId"
                        >
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label={`Sales Contract`}
                            rules={[{ required: true, message: `Sales Contract is required` }]}
                            name="contractId"
                        >
                            <Select
                                onChange={saleContractOnChange}
                                allowClear
                                showSearch
                                placeholder={`Select Sales Contract No`}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {purchaseContracts.map(item => {
                                    return <Option style={{ width: 250 }} value={item.pcId} key={item.pcId}>{item.contractAndSupplier}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label="Laycan (Quality)"
                            name="qualityId"
                            rules={[{ required: true, message: 'Laycan (Quality) is required' }]}
                        >
                            <Select
                                allowClear
                                onChange={qualityOnChange}
                                showSearch
                                placeholder='Select Laycan'
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {qualitiesData?.map(item => {
                                    return <Option value={item.qualityId} key={item.qualityId} style={{ width: 250 }}>{moment(item.laycan[0]).format('DD MMM YY') + ' - ' + moment(item.laycan[1]).format('DD MMM YY')} - ({`${item.quality}`})</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label="Sales Type"
                            name="purchaseType"
                            rules={[{ required: true, message: 'Contract Type is required' }]}
                        >
                            <Select
                                allowClear
                                onChange={salesTypeOnChange}
                                showSearch
                                placeholder={`Select Sales Contract Type`}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {purchaseTypesData?.map(item => {
                                    return <Option value={item?.pcTypeId} key={item?.pcTypeId}>{`${item?.purchaseType} ${item?.jettyName ? `- (${item.jettyName})` : ''}`}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name='incoterm' style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item label={'Date'} name='costingDate' rules={[{ required: true, message: 'Costing Date is required' }]}>
                            <DatePicker style={{ width: '80%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 1 }} >
                        <Form.Item
                            label={' '}
                        >
                            <Button type="primary" disabled={buttonDisableHandler} onClick={bargeOnClickHandler}>Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </div>
    )
}

export default CmSaleFilterComponent;