
import { DeleteFilled, DownCircleOutlined, EditFilled, MessageFilled, UpCircleOutlined } from '@ant-design/icons';
import { BNoRequest, BnaGridResDto, BusinessNoAllocationId, BusinessNumberService, ContractModesEnum, PcQualitiesResponseDto, PurchaseContractService, PurchaseDropDownDto, SalesContractService, SalesQualitiesResponseDto } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Descriptions, Divider, Drawer, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { FormInstance } from 'rc-field-form';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../common';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Item from 'antd/es/list/Item';
interface IAddContractProps {
    formRef?: FormInstance<any>
    business?: string;
    drawer?: boolean;
    allocationGridState?: any;
    bargeBusiness?: string;
    allocationForm?: Boolean;
    setAllocationForm?: (value: Boolean) => void;
    setFeaturesRefName?: React.Dispatch<React.SetStateAction<string>>
    setCommentsTitle?: React.Dispatch<React.SetStateAction<string>>
    setCommentsVisible?: React.Dispatch<React.SetStateAction<boolean>>
    setDummyRefresh: React.Dispatch<React.SetStateAction<number>>
}


export const AddContract = (props: IAddContractProps) => {
    const { business, bargeBusiness, setDummyRefresh, setFeaturesRefName, setCommentsTitle, setCommentsVisible } = props;
    const { authContext } = useAuthState();
    const [salesData, setSalesData] = useState<SalesQualitiesResponseDto[]>([]);
    const [purchases, setPurchases] = useState<PurchaseDropDownDto[]>([]);
    const purchase = new PurchaseContractService();
    const [selectedRowKeysData, setPcSelectedRowKeysData] = useState<any>();
    const [sales, setSales] = useState<any>([]);
    const [purchaseData, setPurchaseData] = useState<PcQualitiesResponseDto[]>([]);
    const [modal, setModal] = useState(false);
    const [purchaseModal, setPurchaseModal] = useState(false);
    const [selectedPcId, setSelectedPcId] = useState(undefined);
    const [selectedScId, setSelectedScId] = useState(undefined);
    const { Option } = Select;
    const [rowsSelection, setScRowsSelection] = useState<any>();
    const salesService = new SalesContractService();
    const businessService = new BusinessNumberService();
    const [formRef] = Form.useForm();
    const { formatMessage: fm } = useIntl();
    const [specification, setSpecification] = useState<any>([]);
    const [pcStandard, setPcStandard] = useState<any>([]);
    const [selectedPcType, setSelectedPcType] = useState<any>();
    const [scTypeSelectionData, setScSelectionTypeData] = useState<any>();
    const [selectedRowKeyTwo, setSelectedRowKeyTwo] = useState<React.Key[]>([]);
    const [scSelectedRowKey, setScSelectedRowKeyTwo] = useState<React.Key[]>([]);
    const [pcTypeColumnsData, setPcColumnsData] = useState<any[]>();
    const [scTypeColumnsData, setScTypeColumnsData] = useState<any[]>();
    const [allocation, setAllocation] = useState<BnaGridResDto[]>([]);
    const [contractRecord, setContractRecord] = useState<any>();
    const [allocatedQuantityRecord, setAllocatedQuantityRecord] = useState<any>(undefined)
    const [indexValue, setDecrementIndexValue] = useState<number>();
    const [requiredState, setRequiredState] = useState<boolean>(true);
    const [contractsIndex, setContractsIndex] = useState<number>();
    const [pcRowSelectedKye, setPcRowSelectedKey] = useState<any>();
    const [pctRowSelectionKye, setPctRowSelectionKey] = useState<any>();
    const [scRowSelectedKye, setScRowSelectedKey] = useState<any>();
    const [sctRowSelectionKye, setSctRowSelectionKey] = useState<any>();
    const [drawer, setDrawer] = useState<boolean>(false)
    const [data, setData] = useState<any[]>([]);
    const [editIndex, setEditIndex] = useState(null);
    const [searchedText, setSearchedText] = useState("");

    useEffect(() => {
        getAllPurchaseContracts();
        specificationStandard();
        pcSpecificationStandard();
        getAllSaleContractsForDropDown();
        // businessNoAllocatedData();
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    useEffect(() => {
        businessNoAllocatedData();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [bargeBusiness]);


    useEffect(() => {
        if (purchaseData[0]?.PcLayCan) {
            layCanDate();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [purchaseData[0]?.PcLayCan]);


    useEffect(() => {
        if (allocatedQuantityRecord?.contractType === ContractModesEnum.PURCHASE) {
            setPcSelectedRowKeysData(allocatedQuantityRecord);
            setRequiredState(false);
            // allocationCreate();
        };
        if (allocatedQuantityRecord?.contractType === ContractModesEnum.SALES) {
            setScRowsSelection(allocatedQuantityRecord);
            setRequiredState(false);
            // allocationCreate();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [allocatedQuantityRecord]);

    useEffect(() => {
        if (!formRef.getFieldValue('purchaseContract')) {
            setPcRowSelectedKey(null);
            setPcSelectedRowKeysData(null);
            setPcColumnsData([]);
            setPctRowSelectionKey(null);

        }
    }, [formRef.getFieldValue('purchaseContract')]);

    useEffect(() => {
        if (!formRef.getFieldValue('salesContract')) {
            setScRowSelectedKey(null);
            setScRowsSelection(null);
            setScTypeColumnsData([]);
            setSctRowSelectionKey(null)

        }
    }, [formRef.getFieldValue('salesContract')]);

    const getAllPurchaseContracts = () => {
        purchase.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setPurchases(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        });
    };

    const pcSpecificationStandard = () => {
        purchase.pcSpecificationStandard(1).then(res => {
            if (res.status) {
                setPcStandard(res.data);
            };
        }).catch(err => {
            console.log('error: ' + err.message);
        });
    };

    const getAllSaleContractsForDropDown = () => {
        salesService.getAllSaleContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setSales(res.data);
            };
        }).catch(err => {
            console.log('error: ' + err.message);
        });
    };

    const specificationStandard = () => {
        salesService.specificationStandard(2).then(res => {
            if (res.status) {
                setSpecification(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        });
    };


    const layCanDate = () => {
        if (purchaseData[0]?.PcLayCan) {
            const layCan = purchaseData[0]?.PcLayCan;
            const split = layCan.split(',')
            moment(split[0]).format('DD\u2011MMM\u2011YYYY');
            moment(split[1]).format('DD\u2011MMM\u2011YYYY');
        };
    };

    const showPurchaseModal = (pcId: string, record?: any) => {
        purchase.getPurchaseQualitiesData({ contractId: pcId, plantCode: authContext.defaultPlant }).then(res => {
            setPcSelectedRowKeysData(undefined);
            if (res.status) {
                if (!drawer) {
                    setPurchaseModal(!pcId ? false : true);
                }
                setPurchaseData(res.data);
                formRef.setFieldValue('pcSpecification', res.data[0]?.specificationStandard);
                if (record) {
                    setContractRecord(record);
                    showPurchaseModal(pcId);
                    setRequiredState(false);
                    setScSelectionTypeData(undefined);
                };
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            console.log(err.message);
        });
        setSelectedPcId(pcId ? pcId : undefined);

    };


    const showModal = (scId: string, record?: any) => {
        salesService.getQualitiesData({ contractId: scId, plantCode: authContext.defaultPlant }).then(res => {
            setScRowsSelection(undefined);
            if (res.status) {
                if (!drawer) {
                    setModal(!scId ? false : true);
                }
                setSalesData(res.data);
                formRef.setFieldValue('scSpecification', res.data[0]?.specificationStandard);
                if (record) {
                    setContractRecord(record);
                    showModal(scId);
                    setRequiredState(false);
                    setSelectedPcType(undefined);
                }
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            console.log(err.message);
        })
        setSelectedScId(scId ? scId : undefined);
    };

    const closePurchaseModal = () => {
        setPurchaseModal(false);
        formRef.setFieldValue('purchaseContract', undefined);
        setPcSelectedRowKeysData(undefined);
        setContractRecord(undefined);
        setPctRowSelectionKey(undefined);
        setPcRowSelectedKey(undefined);
    };

    const closeModal = () => {
        setModal(false)
        formRef.setFieldValue('salesContract', undefined);
        setScRowsSelection(undefined);
        setContractRecord(undefined);
        setSctRowSelectionKey(undefined);
        setScRowSelectedKey(undefined);
    };

    const onReset = () => {
        formRef.resetFields();
        setPcSelectedRowKeysData(undefined);
        setScRowsSelection(undefined);

    }


    const onOkPurchase = () => {
        setPurchaseModal(false);
        if (selectedRowKeysData === undefined) {
            formRef.setFieldValue('purchaseContract', undefined);
        };
    };

    const onOk = () => {
        setModal(false);
        if (rowsSelection === undefined) {
            formRef.setFieldValue('salesContract', undefined);
        };
    };

    const allocationQuantity = allocation?.filter((rec: any) => {
        const validation = allocatedQuantityRecord?.contractType === ContractModesEnum.PURCHASE ? rec.pcContractIid : allocatedQuantityRecord?.contractType === ContractModesEnum.SALES ? rec.scContractId : undefined;
        const req = allocatedQuantityRecord?.contractType === ContractModesEnum.PURCHASE ? allocatedQuantityRecord.pcId : allocatedQuantityRecord?.contractType === ContractModesEnum.SALES ? allocatedQuantityRecord.scId : undefined;
        return validation === req;
    });
    const minimumValue = { sales: rowsSelection?.quantityInMt, purchase: selectedRowKeysData?.pcQuantityInMt };
    const objectValue = Object.values(minimumValue);
    const minimumQuantityAvailable = Math.min(...objectValue);

    const minimumQuantityValue = { sales: Number(allocationQuantity[0]?.scQuantityAdjusted), purchase: Number(allocationQuantity[0]?.pcQuantityAdjusted) }
    const objectQuantityValue = Object.values(minimumQuantityValue);
    const minimumAvailableQuantity = Math.min(...objectQuantityValue);


    const allocationCreate = () => {
        formRef.validateFields().then((values) => {
            data.push(
                ...(selectedRowKeysData && selectedPcType
                    ? [{ pcLaycan: { ...selectedRowKeysData, pcType: selectedPcType, pcSpecification: values.pcSpecification } }]
                    : []),
                ...(rowsSelection && scTypeSelectionData
                    ? [{ scLaycan: { ...rowsSelection, scType: scTypeSelectionData, scSpecification: values.scSpecification } }]
                    : [])
            );
            // data.push({ 
            //     (selectedRowKeysData && selectedPcType) && pcLaycan: { ...selectedRowKeysData, pcType: selectedPcType, pcSpecification: values.pcSpecification },
            //     (rowsSelection && scTypeSelectionData) ** scLaycan: { ...rowsSelection, scType: scTypeSelectionData, scSpecification: values.scSpecification } });
            // onReset(); 
            console.log('dddddd', data)
            let allocationData = []
            data.forEach((allocation) => {
                const bothData: any = {
                    businessNoId: business,
                    salesData: allocation?.scLaycan ? allocation?.scLaycan : undefined,
                    purchaseData: allocation?.pcLaycan ? allocation?.pcLaycan : undefined,
                    scSpecification: allocation?.scLaycan?.scSpecification,
                    pcSpecification: allocation?.pcLaycan?.pcSpecification,
                    qualityAllocation: 1,
                    createdUser: authContext.user.userName,
                    plantCode: authContext.defaultPlant,
                    businessAllocationId: allocatedQuantityRecord ? allocatedQuantityRecord.businessAllocationId : contractRecord?.businessAllocationId,
                    scTypeId: allocation?.scLaycan?.scType[0]?.scTypeId || allocatedQuantityRecord?.scTypeId,
                    pcTypeId: allocation?.pcLaycan?.pcType[0]?.pcTypeId || allocatedQuantityRecord?.pcTypeId,
                    contractType: allocatedQuantityRecord?.contractType
                }
                allocationData.push(bothData)
            })

            if (!allocationData.length) return;

            businessService.allocationCreate(allocationData).then(res => {
                if (res.status) {
                    onReset();
                    businessNoAllocatedData();
                    setContractRecord(undefined);
                    closeModal();
                    closePurchaseModal();
                    setDecrementIndexValue(undefined);
                    setContractsIndex(null);
                    setDummyRefresh(prev => prev + 1);
                    AlertMessages.getSuccessMessage(res.internalMessage);

                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err.message);
            })
        })
    }

    // const addData = () => {
    //     formRef.validateFields().then((values) => {
    //         data.push();
    //         onReset(); 
    //     })

    // }

    const addData = () => {
        formRef.validateFields().then((values) => {
            const newEntry = {
                pcLaycan: {
                    ...selectedRowKeysData,
                    pcType: selectedPcType,
                    pcSpecification: values.pcSpecification,
                },
                scLaycan: {
                    ...rowsSelection,
                    scType: scTypeSelectionData,
                    scSpecification: values.scSpecification,
                },
            };

            if (editIndex !== null) {
                const updatedData = [...data];
                updatedData[editIndex] = newEntry;
                setData(updatedData);
                setEditIndex(null);
            } else {
                setData([...data, newEntry]);
            }

            onReset();
        }).catch((err) => {
            console.error("Validation Error:", err);
        });
    };


    const handleEdit = (index) => {
        const editData = data[index];

        formRef.setFieldsValue({
            purchaseContract: editData.pcLaycan.contractNo,
            salesContract: editData.scLaycan.contractNo,
            pcSpecification: editData.pcLaycan.pcSpecification,
            scSpecification: editData.scLaycan.scSpecification,
        });

        setPcSelectedRowKeysData(editData.pcLaycan);
        setScRowsSelection(editData.scLaycan);
        setSelectedPcType(editData.pcLaycan.pcType);
        setScSelectionTypeData(editData.scLaycan.scType);

        setEditIndex(index);

    };



    const pcRowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            selectedRows[0].pcSpecification = selectedRows[0]?.specStandard
            setPcRowSelectedKey(selectedRowKeys);
            setPcSelectedRowKeysData(selectedRows[0]);
            setPcColumnsData(selectedRows[0].pcType);
            formRef.setFieldValue('pcSpecification', selectedRows[0]?.specStandard);
            // setRequiredState(!selectedRowKeysData.length? true : false);
        },
        getCheckboxProps: (record: SalesQualitiesResponseDto) => ({
            disabled: record.remaining === 0,
        }),
        selectedRowKeys: pcRowSelectedKye
    };


    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
        setSelectedPcType(selectedRows);
        setPctRowSelectionKey(newSelectedRowKeys);
    }
    const pcTypeRowSelection = {
        selectedRowKeyTwo,
        onChange: onSelectChange,
        selectedRowKeys: pctRowSelectionKye,
    };


    const scRowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            selectedRows[0].scSpecification = selectedRows[0]?.specificationStandard
            setScRowSelectedKey(selectedRowKeys);
            setScRowsSelection(selectedRows[0]);
            setScTypeColumnsData(selectedRows[0].scType);
            // setRequiredState(!rowsSelection.length? true : false);
            formRef.setFieldValue('scSpecification', selectedRows[0]?.specificationStandard);
        },
        getCheckboxProps: (record: SalesQualitiesResponseDto) => ({
            disabled: record.remaining === 0,
        }),
        selectedRowKeys: scRowSelectedKye,
    };


    const scTypeRowSelectionOnChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
        setScSelectionTypeData(selectedRows);
        setSctRowSelectionKey(newSelectedRowKeys);
    };
    const scTypeRowSelection = {
        scSelectedRowKey,
        onChange: scTypeRowSelectionOnChange,
        selectedRowKeys: sctRowSelectionKye
    };

    const businessNoAllocatedData = () => {
        const req = new BNoRequest(business, authContext.defaultPlant)
        businessService.businessNoAllocatedData(req).then(res => {
            if (res.status) {
                setAllocation(res.data);
            }
            //  else {
            //     AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            // }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        });
    };


    const getAllocationGridData = (serviceData: any[]) => {
        const dataToBeSet = [];
        serviceData.forEach(rec => {
            dataToBeSet.push({
                displayAllocationId: rec.displayAllocationId,
                businessAllocationId: rec.businessAllocationId,
                contractNo: rec.pcContractNo,
                tolerance: rec.pcToleranceAdjusted,
                incoTerms: rec.pcPurchaseType,
                quantity: rec.pcQuantity,
                quality: rec.pcQualitiesQuality,
                supplierAndBuyer: rec.supplierName,
                quantityAllocated: rec.quantityAllocated,
                defaultAllocated: rec.quantityAllocated,
                remaining: Number(rec.pcQuantity) - Number(rec.pcQuantityAllocated),
                pcId: rec.pcContractIid,
                pcQId: rec.pcQualitiesId,
                pcTypeId: rec.pcTypeId,
                pcQualitySpec: rec.pcQualitySpec,
                pcLaycan: rec.pcLaycan,
                rowSpan: 2,
                contractType: ContractModesEnum.PURCHASE,
                isMapped: rec.isMapped,
                minRemaining: Math.min(...[Number(rec.pcQuantity) - Number(rec.pcQuantityAllocated), Number(rec.scQuantity) - Number(rec.scQuantityAllocated)])
            });
            dataToBeSet.push({
                displayAllocationId: rec.displayAllocationId,
                businessAllocationId: rec.businessAllocationId,
                contractNo: rec.scContractNo,
                scQualitiesQuality: rec.scQualitiesQuality,
                tolerance: rec.scToleranceAdjusted,
                incoTerms: rec.scSalesType,
                quantity: rec.scQuantity,
                quality: rec.scQualitiesQuality,
                supplierAndBuyer: rec.scMineName,
                quantityAllocated: rec.quantityAllocated,
                defaultAllocated: rec.quantityAllocated,
                remaining: Number(rec.scQuantity) - Number(rec.scQuantityAllocated),
                scId: rec.scContractId,
                scQId: rec.scQualitiesId,
                scTypeId: rec.scTypeId,
                scQualitySpec: rec.scQualitySpec,
                scLaycan: rec.scLaycan,
                rowSpan: 0,
                contractType: ContractModesEnum.SALES,
                isMapped: rec.isMapped,
                minRemaining: Math.min(...[Number(rec.pcQuantity) - Number(rec.pcQuantityAllocated), Number(rec.scQuantity) - Number(rec.scQuantityAllocated)])
            });
        })
        return dataToBeSet;

    }

    const deleteAllocation = (id: string) => {
        const req = new BusinessNoAllocationId(id);
        businessService.deleteAllocation(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                businessNoAllocatedData();
                setDummyRefresh(prev => prev + 1);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message))
    }

    const commentsData = (refName, title) => {
        setCommentsVisible(true);
        setFeaturesRefName(refName);
        setCommentsTitle(title);
    }

    const getFilteredData = (data: any[]) => {
        if (!searchedText) return data;
        return data?.filter((item: any) =>
            Object.values(item)
                .join(' ')
                .toLowerCase()
                .includes(searchedText.toLowerCase())
        );
    };

    const allocatedColumns: ColumnsType<any> = [
        {
            title: "Allocation Id",
            dataIndex: 'displayAllocationId',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, row: any, index) => {
                return value;
            }
        },
        {
            title: "Contract No",
            dataIndex: 'contractNo',
            width: contractsIndex === undefined ? 150 : 210,
            render: (value, record, index) => {
                let link: string;
                if (record?.contractType === ContractModesEnum.PURCHASE) {
                    link = `/#/po-detail-view?pc_id=${record.pcId}`
                } else if (record?.contractType === ContractModesEnum.SALES) {
                    link = `/#/so-detail-view?sc_id=${record.scId}`
                }
                return <>
                    {<>{contractsIndex === index ? <Select
                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                        showSearch
                        allowClear
                        placeholder= {record?.contractType === ContractModesEnum.PURCHASE ? "Select Purchase Contract": "Select Sales Contract"}
                        onChange={(value) => {
                            return record?.contractType === ContractModesEnum.PURCHASE ? showPurchaseModal(value, record) : showModal(value, record)
                        }}

                    >
                        {(record?.contractType === ContractModesEnum.PURCHASE ? purchases : sales).map(item => {
                            return <Option value={item.pcId}>{item.contractAndSupplier}</Option>
                        })}
                    </Select> : <a className='link' href={link} >{value}</a>}
                        &nbsp;&nbsp;
                        <EditFilled
                            type='edit'
                            className='action-icons'
                            onClick={() => {
                                console.log('record ==>', record);
                                setContractsIndex(index);
                                setDecrementIndexValue(undefined);
                                setAllocatedQuantityRecord(record);
                            }}
                        />
                    </>}
                </>
            }

        },
        {
            title: "Laycan",
            dataIndex: 'pcLaycan',
            onCell: (record: any) => ({
                className: 'nowrap'
            }),
            render: (value, record) => {
                const laycan = record?.contractType === ContractModesEnum.PURCHASE ? record?.pcLaycan : record?.contractType === ContractModesEnum.SALES ? record?.scLaycan : null;
                return laycan?.length === 2 ? `${moment(laycan[0]).format('DD\u2011MMM\u2011YYYY')} to ${moment(laycan[1]).format('DD\u2011MMM\u2011YYYY')}` : '';
            },
        },
        {
            title: "Supplier & Buyer",
            dataIndex: 'supplierAndBuyer',
        },
        {
            title: "Quality",
            dataIndex: 'quality',
            render: (value, record) => {
                let qualitySpec = '';
                if (record?.contractType === ContractModesEnum.PURCHASE) {
                    qualitySpec = record.pcQualitySpec;
                } else if (record?.contractType === ContractModesEnum.SALES) {
                    qualitySpec = record.scQualitySpec;
                }

                return <>{value} <span className='quality-style'>{qualitySpec}</span></>
            }
        },
        {
            title: "Inco Term",
            dataIndex: 'incoTerms',
        },
        // {
        //     title: "QUANTITY",
        //     dataIndex: 'quantity',
        //     render: (value, record) => {
        //         return <>
        //             {value < 0 || isNaN(value) ? 0 : value}
        //         </>
        //     }
        // },
        // {
        //     title: "Allocated Quantity",
        //     dataIndex: 'quantityAllocated',
        //     width: indexValue === undefined ? 150 : 230,
        //     onCell: (record: any) => ({
        //         rowSpan: record.rowSpan
        //     }),
        //     render: (value, record, index) => {
        //         return <>
        //             {value ? <>
        //                 <Tooltip title='Increase Allocation'>
        //                     <UpCircleOutlined
        //                         style={{ fontSize: 18 }}
        //                         onClick={() => {
        //                             if (record.minRemaining <= 0) {
        //                                 AlertMessages.getErrorMessage(`You can't allocate more quantity`);
        //                             } else {
        //                                 setDecrementIndexValue(index);
        //                                 setRequiredState(false);
        //                             }
        //                         }}
        //                         className={record.minRemaining <= 0 ? 'disabled-Edit-Icon' : 'Editable-Icon'}
        //                     />
        //                 </Tooltip>
        //                 &nbsp;&nbsp;
        //                 {<span style={{ fontSize: 14 }}>
        //                     {indexValue === index ?
        //                         <><InputNumber
        //                             defaultValue={value}
        //                             onChange={(value) => {
        //                                 if (value <= Number(record.defaultAllocated) + Number(record.minRemaining) && value > 0) {
        //                                     record.quantityAllocated = value;
        //                                 } else {
        //                                     record.quantityAllocated = Number(record.defaultAllocated) + Number(record.minRemaining);
        //                                 }
        //                             }}
        //                             style={{ width: 70 }}
        //                             min={0}
        //                             max={Number(record.defaultAllocated) + Number(record.minRemaining)}
        //                         />
        //                             &nbsp;
        //                             <Button
        //                                 onClick={() => {
        //                                     setAllocatedQuantityRecord(record);
        //                                     setRequiredState(false);
        //                                 }} type='primary'
        //                             >Update
        //                             </Button>
        //                         </> : value}
        //                 </span>}&nbsp;&nbsp;
        //                 <Tooltip title='Decrease Allocation'>
        //                     <DownCircleOutlined
        //                         style={{ color: "#4E89FF", fontSize: 18 }}
        //                         onClick={() => {
        //                             setDecrementIndexValue(index);
        //                             setRequiredState(false);
        //                             setContractsIndex(undefined);
        //                         }}
        //                     />
        //                 </Tooltip>
        //             </> : <></>}
        //         </>;
        //     }
        // },
        // {
        //     title: "REMAINING",
        //     dataIndex: 'remaining',
        //     render: (value, record) => {
        //         return <>
        //             {value < 0 || isNaN(value) ? 0 : value}
        //         </>
        //     }
        // },
        {
            title: 'Action',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                return <>
                    <Popconfirm
                        onConfirm={() => {
                            deleteAllocation(record.businessAllocationId)
                        }}
                        title='Are you sure to Delete Record ?'
                    >
                        <DeleteFilled hidden={record.isMapped} className='delete-filled' />
                    </Popconfirm></>;
            }
        }
    ];



    const tableColumns: any = [
        {
            title: "Laycan",
            dataIndex: 'layCan',
            // align: 'center',
            key: '1',
            render: (value, record) => { return record?.layCan ? `${moment(record.layCan.split(',')[0]).format('DD\u2011MMM\u2011YYYY')} to ${moment(record.layCan.split(',')[1]).format('DD\u2011MMM\u2011YYYY')}` : ''; }
        },
        {
            title: "Quality",
            dataIndex: 'quality',
            // align: 'center',
            key: '2',
            render: (value, record) => {
                return <>{value} <span className='quality-style'>{record.scQualitySpec}</span></>
            }

        },
        // {
        //     title: 'Tolerance',
        //     dataIndex: 'tolerance',
        //     align: 'center',
        //     render: (value) => {
        //         return <>
        //             {value + " %"}
        //         </>
        //     }
        // },

        {
            title: 'Purchase Term',
            dataIndex: 'purchasingTerm',
            // align: 'center',
            key: '7',

        },
        {
            title: "Quantity",
            dataIndex: 'quantityInMt',
            align: 'right',
            key: '8',
            render: (value) => {
                return <>
                    {value}<span className='quality-style'> MT </span>
                </>
            }
        },
        {
            title: "Remaining",
            dataIndex: 'remaining',
            align: 'right',
            key: '9',
            render: (value) => {
                return <>
                    {Number(value).toFixed(3)}<span className='quality-style'> MT </span>
                </>
            }
        },


    ]

    const scTypeColumns: ColumnsType<any> = [
        // {
        //     title: 'MINE NAME',
        //     dataIndex: 'bpName',
        // },
        {
            title: 'Sales Type',
            dataIndex: 'salesType',
        },
        {
            title: 'Load Port',
            dataIndex: 'jettyName',
        },
        {
            title: 'Coal Price',
            dataIndex: 'averageCoalIndexPrice',
            // align: 'right'
        },
    ]

    const pcQualityColumns: ColumnsType<any> = [
        {
            title: "Laycan",
            dataIndex: 'PcLayCan',
            // align: 'center',
            render: (layCan, row) => {
                return layCan ? `${moment(layCan.split(',')[0]).format('DD\u2011MMM\u2011YYYY')} to ${moment(layCan.split(',')[1]).format('DD\u2011MMM\u2011YYYY')}` : ''
            }
        },
        {
            title: "Quality",
            dataIndex: 'pcQuality',
            // align: 'center',
            render: (value, record) => {
                return <>{value} <span className='quality-style'>{record.pcQualitySpec}</span></>
            }
        },
        // {
        //     title: 'Tolerance',
        //     dataIndex: 'pcTolerance',
        //     align: 'center',
        //     render: (value) => {
        //         return <>
        //             {value + " %"}
        //         </>
        //     }
        // },
        {
            title: 'Purchase Term',
            dataIndex: 'pcPurchasingTerm',
            // align: 'center'
        },
        {
            title: "Quantity",
            dataIndex: 'pcQuantityInMt',
            align: 'right',
            render: (value) => {
                return <>
                    {value}<span className='quality-style'> MT </span>
                </>
            }
        },
        {
            title: "Remaining",
            dataIndex: 'remaining',
            align: 'right',
            render: (value) => {
                return <>
                    {Number(value).toFixed(3)}<span className='quality-style'> MT </span>
                </>
            }
        }
    ]


    const pcTypeColumns: ColumnsType<any> = [
        // {
        //     title: 'MINE NAME',
        //     dataIndex: 'bpName',
        // },
        {
            title: 'Purchase Type',
            dataIndex: 'purchaseType',
        },
        {
            title: 'Jetty',
            dataIndex: 'jettyName',
        },
        {
            title: 'Coal Price',
            dataIndex: 'pcAverageCoalIndexPrice',
        },
    ]

    const isAddButtonEnabled = () => {
        const values = formRef.getFieldsValue();
        const { purchaseContract, salesContract } = values;

        const isFormComplete =
            purchaseContract &&
            salesContract &&
            pcRowSelectedKye &&
            scRowSelectedKye &&
            pctRowSelectionKye &&
            sctRowSelectionKye;

        const isFormPartiallyFilled = (purchaseContract || salesContract || pcRowSelectedKye || scRowSelectedKye || pctRowSelectionKye || sctRowSelectionKye) && !isFormComplete;

        // return data.length > 0 && !isFormPartiallyFilled || isFormComplete;
        return data?.length > 0
    };

    const isAddAnotherButtonEnabled = () => {
        const values = formRef.getFieldsValue();
        const { purchaseContract, salesContract } = values;

        return purchaseContract &&
            salesContract &&
            pcRowSelectedKye &&
            scRowSelectedKye &&
            pctRowSelectionKye &&
            sctRowSelectionKye;
    };


    return (
        <>
            {/* {allocationForm ? <Button type='primary' style={{ float: 'right' }} onClick={() => { setAllocationForm(!allocationForm); formRef.resetFields() }}> Allocate</Button> : <Button type='primary' danger style={{ float: 'right' }} onClick={() => setAllocationForm(!allocationForm)}>Cancel</Button>}
            <br /><br /> */}
            {/* {allocationForm ? <></> : <> */}

            <Row className='card-title-buttons mb-12' justify="space-between" >
                <Col>
                    <Input.Search placeholder="Search" allowClear className='input-search' onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} />
                </Col>
                <Col>
                    <Tooltip placement="topRight" title="Add Contract">
                        <Button
                            className='new-button'
                            size="small"
                            type="primary"
                            onClick={() => setDrawer(true)}
                        >
                            Add Contracts
                        </Button>
                    </Tooltip>
                </Col>
            </Row>

            <Card title={<span className="card-title-md ">View Contracts</span>}
                extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Contract Allocation > View Contracts', 'View Contracts')} /></Tooltip>}
                headStyle={{ border: 'none', marginTop: '4px' }}
                className='card-br-8'
            >
                <Table
                    className='contracts-list overflowX'
                    rowKey={record => { return record.pcContractIid + record.businessAllocationId + record.scContractId }}
                    columns={allocatedColumns}
                    pagination={false}
                    dataSource={getFilteredData(getAllocationGridData(allocation))}
                ></Table>
            </Card>


            <Drawer
                width={800}
                open={drawer}
                maskClosable={false}
                keyboard={false}
                onClose={() => setDrawer(false)}
                title={<span className='drawer-title'>Add Contracts</span>}
                extra={<Button className='xmark-icon' icon={<FontAwesomeIcon icon={faXmark} />} onClick={() => setDrawer(false)}></Button>}
                closable={false}
                footer={
                    <div className='drawer-footer-div'>
                        <Button type='link' className='link' onClick={onReset} >Cancel</Button>
                        <Button className='new-button' disabled={!isAddButtonEnabled()} onClick={() => { allocationCreate() }} >Add</Button>

                    </div>
                }
            >
                {data.length > 0 &&
                    data.map((laycan, index) => (
                        <Row gutter={[12, 12]} key={index} className='mb-12'>
                            <Col span={23}>
                                <Card className='card-br-8'>
                                    <Row>
                                        <Descriptions column={5}>
                                            <Descriptions.Item>{laycan?.pcLaycan?.contractNo}</Descriptions.Item>
                                            <Descriptions.Item>
                                                {`${moment(laycan?.pcLaycan?.PcLayCan?.split(',')[0]).format('DD\u2011MMM\u2011YYYY')} to ${moment(laycan?.pcLaycan?.PcLayCan.split(',')[1]).format('DD\u2011MMM\u2011YYYY')}`}
                                            </Descriptions.Item>
                                            <Descriptions.Item>{laycan.pcLaycan?.pcType[0]?.jettyName}</Descriptions.Item>
                                            <Descriptions.Item><>{laycan?.pcLaycan?.pcQuality}&nbsp;<span className='quality-style'>{laycan?.pcLaycan?.pcQualitySpec}</span></></Descriptions.Item>
                                            <Descriptions.Item>{laycan?.pcLaycan?.pcType[0]?.purchaseType}</Descriptions.Item>
                                        </ Descriptions>
                                    </Row>
                                    <Row>
                                        <Descriptions column={5}>
                                            <Descriptions.Item>{laycan?.scLaycan?.contractNo}</Descriptions.Item>
                                            <Descriptions.Item>
                                                {`${moment(laycan?.scLaycan?.layCan.split(',')[0]).format('DD\u2011MMM\u2011YYYY')} to ${moment(laycan?.scLaycan?.layCan?.split(',')[1]).format('DD\u2011MMM\u2011YYYY')}`}
                                            </Descriptions.Item>
                                            <Descriptions.Item>{laycan?.scLaycan?.scType[0]?.jettyName} </Descriptions.Item>
                                            <Descriptions.Item><>{laycan?.scLaycan?.quality}&nbsp;<span className='quality-style'>{laycan?.scLaycan?.scQualitySpec}</span></></Descriptions.Item>
                                            <Descriptions.Item>{laycan?.scLaycan?.scType[0]?.salesType}</Descriptions.Item>
                                        </ Descriptions>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={1} className='edit-delete'>
                                <div>
                                    <EditFilled type="edit"
                                        className='action-icons mb-12'
                                        onClick={() => handleEdit(index)}
                                    />
                                    {data.length > 1 && <DeleteFilled onClick={() => setData(prevData => prevData.filter((_, idx) => idx !== index))} className='delete-filled' />}
                                </div>
                            </Col>
                        </Row>
                    ))
                }

                <Card className='card-br-8 mb-12'>

                    <Form
                        layout="vertical"
                        title="Purchase Contract"
                        form={formRef}
                        initialValues={undefined}
                    >
                        <Form.Item
                            label={fm({ id: 'mdm.components.purchaseContract', defaultMessage: "Purchase Contract" })}
                            name="purchaseContract"
                            rules={
                                [
                                    { required: data.length > 0 ? false : true, message: fm({ id: 'mdm.components.purchaseContract', defaultMessage: " Please Select Purchase Contract" }) },
                                ]}
                            style={{ maxWidth: 250 }}
                        >
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                showSearch
                                allowClear
                                placeholder="Select  Purchase Contract"
                                onChange={(pcID) => showPurchaseModal(pcID)}
                            >
                                {purchases.map(item => {
                                    return <Option key={item.pcId} value={item.pcId}>{item.contractAndSupplier}</Option>
                                })}
                            </Select>
                        </Form.Item>

                        {formRef.getFieldValue(['purchaseContract']) && <>
                            <Table
                                className='contracts-list mb-12'
                                title={() => <span className='table-title'>Select Laycan</span>}
                                columns={pcQualityColumns}
                                dataSource={purchaseData}
                                pagination={false}
                                rowKey={record => record?.pcQualitiesId}
                                rowSelection={{ ...pcRowSelection, type: "radio" }}

                            >

                            </Table>
                            {selectedRowKeysData &&
                                <Table
                                    className='contracts-list'
                                    title={() => <span className='table-title'>Select Purchase Type</span>}
                                    columns={pcTypeColumns}
                                    dataSource={pcTypeColumnsData}
                                    rowSelection={{ ...pcTypeRowSelection, type: "radio" }}
                                    rowKey={record => record.pcTypeId}
                                    pagination={false}
                                >
                                </Table>}</>}

                        <Divider dashed ></Divider>

                        <Form.Item name={"salesContract"} label={'Sales Contract'} rules={[{ required: data.length > 0 ? false : true, message: 'Please Select Sales Contract' }]} style={{ maxWidth: 250 }}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                showSearch
                                allowClear
                                placeholder="Select Sales Contract"
                                onChange={(scId) => showModal(scId)}
                            >
                                {sales.map(item => { return <Option value={item.pcId} key={item.pcId}>{item.contractAndSupplier}</Option> })}
                            </Select>

                        </Form.Item>

                        {formRef.getFieldValue(['salesContract']) && <>
                            <Table
                                className='contracts-list mb-12'
                                title={() => <span className='table-title'>Select Laycan</span>}
                                columns={tableColumns}
                                dataSource={salesData}
                                pagination={false}
                                rowSelection={{ ...scRowSelection, type: "radio" }}
                                rowKey={record => record?.scQualitiesId}
                            >

                            </Table>
                            {rowsSelection &&
                                <Table
                                    className='contracts-list'
                                    title={() => <span className='table-title'>Select Sales Type</span>}
                                    columns={scTypeColumns}
                                    dataSource={scTypeColumnsData}
                                    pagination={false}
                                    rowSelection={{ ...scTypeRowSelection, type: 'radio' }}
                                    rowKey={record => record.scTypeId}
                                >
                                </Table>} </>}

                        <Row>
                            <Form.Item
                                name={"pcSpecification"}
                                style={{ display: 'none' }}
                                label={"Purchase Specification"}>
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} showSearch
                                    allowClear
                                    placeholder="Select Purchase Specification"
                                >
                                    <Option>
                                        Please Select
                                    </Option>
                                    {specification.map(rec => { return <Option key={rec.specificationStandard} value={rec.specificationStandard}>{rec.specificationStandard}</Option> })}
                                </Select>
                            </Form.Item>
                        </Row>

                        <Row gutter={24}>

                            <Form.Item name={"scSpecification"} label={"Sales Specification"} style={{ display: 'none' }}>
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} showSearch
                                    allowClear
                                    placeholder="Select Sales Specification"
                                >
                                    <Option>
                                        Please Select
                                    </Option>
                                    {pcStandard.map(rec => { return <Option key={rec.specificationStandard} value={rec.specificationStandard}>{rec.specificationStandard}</Option> })}
                                </Select>

                            </Form.Item>

                        </Row>

                    </Form>

                </Card>

                <Button className='new-button' disabled={!isAddAnotherButtonEnabled()} onClick={addData} >{editIndex !== null ? "Save Changes" : "Save and Add Another"}</Button>


            </Drawer>


            <Form
                layout="vertical"
                title="Purchase Contract"
                form={formRef}
                initialValues={undefined}
            >
                <Row >
                    {/* <Col span={8}>
                        <Form.Item
                            label={fm({ id: 'mdm.components.purchaseContract', defaultMessage: "Purchase Contract" })}
                            name="purchaseContract"
                            rules={
                                [
                                    { required: requiredState, message: fm({ id: 'mdm.components.purchaseContract', defaultMessage: " Please Select Purchase Contract" }) },
                                ]}
                        >
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                showSearch
                                allowClear
                                placeholder="Select  Purchase Contract"
                                // onSelect={() => handleDropdownSelect()}
                                onChange={(pcID) => showPurchaseModal(pcID)}
                            >
                                {purchases.map(item => {
                                    return <Option value={item.pcId}>{item.contractAndSupplier}</Option>
                                })}
                            </Select>
                        </Form.Item>

                        <Descriptions.Item label="Product">Purchase Contract:<b> {contractRecord?.businessAllocationId || allocatedQuantityRecord ? undefined : selectedRowKeysData?.contractNo}</b></Descriptions.Item><br /><br />
                        <Descriptions.Item label="Billing">	Quantity: <b>{contractRecord?.businessAllocationId || allocatedQuantityRecord ? undefined : selectedRowKeysData?.remaining}</b></Descriptions.Item><br /><br />

                    </Col> */}

                    {/* <Col span={8}>
                        <Form.Item name={"salesContract"} label={'Sales Contract'} rules={[{ required: requiredState, message: 'Please Select Sales Contract' }]}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                showSearch
                                allowClear
                                placeholder="Select Sales Contract"
                                onChange={(scId) => showModal(scId)}
                            >
                                <Option value={' '}> Select Sales Contract</Option>
                                {sales.map(item => { return <Option value={item.pcId}>{item.contractAndSupplier}</Option> })}
                            </Select>

                        </Form.Item>

                        <Descriptions.Item label="Product">Sales Contract: <b>{contractRecord?.businessAllocationId || allocatedQuantityRecord ? undefined : rowsSelection?.contractNo}</b></Descriptions.Item><br /><br />
                        <Descriptions.Item label="Billing">	Quantity: <b>{contractRecord?.businessAllocationId || allocatedQuantityRecord ? undefined : rowsSelection?.remaining}</b></Descriptions.Item><br /><br />

                    </Col> */}

                    {/* <Col span={8} >

                        <Form.Item name={"qualityAllocation"} rules={[{ required: requiredState }]} label={"Quantity Allocation"}>

                            <Input placeholder="Quantity Allocation" autoComplete='off' />
                        </Form.Item>
                        <Descriptions.Item>
                            Available Allocation Quantity :<b>{!minimumQuantityAvailable || contractRecord?.businessAllocationId ? undefined : minimumQuantityAvailable}</b>
                        </Descriptions.Item>
                    </Col> */}

                </Row>
                <Row>

                    <Col span={8}></Col>

                    <Col span={8}>
                        <Form.Item
                            name={"pcSpecification"}
                            style={{ display: 'none' }}
                            label={"Purchase Specification"}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} showSearch
                                allowClear
                                placeholder="Select Purchase Specification"
                            >
                                <Option>
                                    Please Select
                                </Option>
                                {specification.map(rec => { return <Option value={rec.specificationStandard}>{rec.specificationStandard}</Option> })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}></Col>

                </Row>

                <Row gutter={24}>

                    <Col span={8}>


                    </Col>

                    <Col span={8}></Col>
                    <Col span={8}>

                        <Form.Item name={"scSpecification"} label={"Sales Specification"} style={{ display: 'none' }}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} showSearch
                                allowClear
                                placeholder="Select Sales Specification"
                            >
                                <Option>
                                    Please Select
                                </Option>
                                {pcStandard.map(rec => { return <Option value={rec.specificationStandard}>{rec.specificationStandard}</Option> })}
                            </Select>

                        </Form.Item>
                    </Col>


                </Row>

                {/* <br /> */}

                {/* <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 18 }}>
                        <Form.Item>
                            <Button
                                    >Calculate</Button>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }}>
                        <Form.Item>
                            <Button onClick={onReset}
                            >Clear</Button>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }}>
                        <Form.Item>
                            <Button
                                type="primary" htmlType="submit" onClick={() => { allocationCreate() }}
                            >Submit</Button>
                        </Form.Item>
                    </Col>
                </Row> */}

            </Form>

            <Modal
                key={"scId" + selectedScId}
                cancelButtonProps={{ style: { display: 'none' } }}
                title="Quality Selection"
                open={modal}
                onCancel={closeModal}
                onOk={contractRecord?.businessAllocationId ? allocationCreate : onOk}
                okText='Submit'
                width={1000}
                okButtonProps={{ disabled: scTypeSelectionData === undefined ? true : false }}

            >
                <Card title='Sales Quality Selection'>
                    <Table
                        columns={tableColumns}
                        dataSource={salesData}
                        pagination={false}
                        rowSelection={{ ...scRowSelection, type: "radio" }}
                        rowKey={record => record?.scQualitiesId}
                    >

                    </Table>
                </Card>

                <br />
                {rowsSelection ? <Card title='Select Sales Type'>
                    <Table
                        columns={scTypeColumns}
                        dataSource={scTypeColumnsData}
                        pagination={false}
                        rowSelection={{ ...scTypeRowSelection, type: 'radio' }}
                        rowKey={record => record.scTypeId}
                    >
                    </Table>
                </Card> : <></>}


            </Modal>

            <Modal
                key={'pcId' + selectedPcId}
                cancelButtonProps={{ style: { display: 'none' } }}
                title="Quality Selection"
                open={purchaseModal}
                onCancel={closePurchaseModal}
                // footer={true}
                onOk={contractRecord?.businessAllocationId ? allocationCreate : onOkPurchase}
                okText='Submit'
                width={1000}
                okButtonProps={{ disabled: selectedPcType === undefined ? true : false, className: 'new-button' }}


            >
                <Card title={'Select Purchase Quality'}>

                    <Table
                        columns={pcQualityColumns}
                        dataSource={purchaseData}
                        pagination={false}
                        rowKey={record => record?.pcQualitiesId}
                        rowSelection={{ ...pcRowSelection, type: "radio" }}

                    >

                    </Table>
                </Card>
                <br></br>
                {selectedRowKeysData ? <Card title={'Select Purchase Type'}>
                    <Table
                        columns={pcTypeColumns}
                        dataSource={pcTypeColumnsData}
                        rowSelection={{ ...pcTypeRowSelection, type: "radio" }}
                        rowKey={record => record.pcTypeId}
                        pagination={false}
                    >
                    </Table>
                </Card> : <> </>}
            </Modal>
            {/* </>} */}

            {/* <Table
                rowKey={record => { return record.pcContractIid + record.businessAllocationId + record.scContractId }}
                columns={allocatedColumns}
                bordered
                pagination={false}
                dataSource={getAllocationGridData(allocation)}
            ></Table> */}

        </>
    )
}

export default AddContract







