import { Card, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import BpCategoryGrid from './bp-category-grid';
import { BusinessPartnerTypeEnum } from '@exportx/shared-models-and-services';
import { isPermissionExist } from '../../../../../common';
import { useLocation, useNavigate } from 'react-router-dom';


export const PartnerType = () => {
    const { state }: any = useLocation();
    const activeTab = state?.activeTab || 'customer';
  return (
    <Card className="card-radius">
      <Tabs defaultActiveKey={activeTab}>
        {isPermissionExist([43]) && (
          <TabPane tab="Customer" key="customer">
            <BpCategoryGrid
              bpCategory={BusinessPartnerTypeEnum.CUSTOMER}
              key={BusinessPartnerTypeEnum.CUSTOMER}
            />
          </TabPane>
        )}
        {isPermissionExist([39]) && (
          <TabPane tab="Vendor" key="vendor">
            <BpCategoryGrid
              bpCategory={BusinessPartnerTypeEnum.VENDOR}
              key={BusinessPartnerTypeEnum.VENDOR}
            />
          </TabPane>
        )}
        {isPermissionExist([47]) && (
          <TabPane tab="Facilitator" key="facilitator">
            <BpCategoryGrid
              bpCategory={BusinessPartnerTypeEnum.FACILITATOR}
              key={BusinessPartnerTypeEnum.FACILITATOR}
            />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};

export default PartnerType;
