import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { Fragment } from "react"
import { useAuthState } from "../../../common"
import moment from "moment"
import { ReferenceFeatures } from "@exportx/shared-models-and-services";

interface InviceProps {
  costing: any;
  costingName: string;
  plantCode: string;
}

const CostingBasicDetails = (props: InviceProps) => {
  const { costing, costingName, plantCode } = props
  
  const styles = StyleSheet.create({
    container: { 
      width: "100%", 
      display: "flex", 
      justifyContent: "space-between",
      marginTop: 12,
    }, 
    section: {
      marginTop: 4,
      display: "flex", 
      flexDirection: "column",
    },
    heading: {
      fontSize: 9,
      fontWeight: "bold",
      color: "grey"
    },
    costingNo: {
      fontSize: 10,
      fontWeight: "demibold",
      color: "#0083C9"
    },
    data: {
      fontSize: 9,
      fontWeight: "demibold",
    }
  })


  return (
  <>
  
  <Fragment>
    <View style={styles.container}>
       {(costingName === ReferenceFeatures.COMMERCIAL_PC_COSTING || costingName === ReferenceFeatures.COMMERCIAL_SC_COSTING || costingName === ReferenceFeatures.MARKETING_FEES || costingName === ReferenceFeatures.PROCUREMENT_FEES || costingName === ReferenceFeatures.PERFORMA_PC_COSTING || costingName === ReferenceFeatures.PERFORMA_SC_COSTING || costingName === ReferenceFeatures.SALES_FREIGHT_COSTING ) && ( 
        <View style={[styles.section, { marginTop: 8 }]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <View>
            <Text style={styles.heading}>Costing No :</Text>
            <Text style={styles.costingNo}>{costing?.costingNo ? costing?.costingNo : "--"}</Text>
          </View>
          <View style={{display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Text style={styles.heading}>Company:</Text>
            <Text style={[styles.costingNo, { alignItems: "flex-end" }]}>{plantCode}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Costing Date :</Text>
          <Text style={styles.data}>{costing?.costingDate ? moment(costing?.costingDate).format('DD-MMM-YYYY') : "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Business Partner :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.bpName || costing?.coalSupplier || costing?.bargeSupplier}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Business Number :</Text>
          <Text style={styles.data}>{costingName === ReferenceFeatures.COMMERCIAL_PC_COSTING || costingName === ReferenceFeatures.COMMERCIAL_SC_COSTING ? costing?.businessNumber || "--" :costing?.bnId || "--"}</Text>
        </View>
        <View style={{ width: "60%", flexDirection: "row" }}>
          <View style={[styles.section, { justifyContent: "space-between" }]}>
            <Text style={styles.heading}>Contract :</Text>
            <Text style={styles.data}>{costing?.contractNo ? costing?.contractNo : "--"}</Text>
          </View>
          <View style={[styles.section, { marginHorizontal: 20 }]}>
            <Text style={styles.heading}>Laycan :</Text>
            <Text style={styles.data}>{`${moment(costing?.laycanFromDate).format('DD-MMM-YYYY')} to ${moment(costing?.laycanToDate).format('DD-MMM-YYYY')}` || "--"}</Text>
          </View>
          <View style={[styles.section, { marginHorizontal: 20 }]}>
            <Text style={styles.heading}>Purchase Type :</Text>
            <Text style={styles.data}>{costing?.incoterm ? costing?.incoterm : "--"}</Text>
          </View>
        </View>
      </View>
    )}


      {costingName === ReferenceFeatures.BARGE_COSTINGS && (<View style={[styles.section, { marginTop: 8 }]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <View>
            <Text style={styles.heading}>Costing No :</Text>
            <Text style={styles.costingNo}>{costing?.costingNo ? costing?.costingNo : "--"}</Text>
          </View>
          <View style={{display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Text style={styles.heading}>Company:</Text>
            <Text style={[styles.costingNo, { alignItems: "flex-end" }]}>{plantCode}</Text>
          </View>
        </View>
        
        <View style={styles.section}>
          <Text style={styles.heading}>Business Number :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.actLedName || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Coal Supplier :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.coalSupplier || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Barge Supplier :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.bargeSupplier || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Costing Date :</Text>
          <Text style={styles.data}>{costing?.costingDate ? moment(costing?.costingDate).format('DD-MMM-YYYY') : "--"}</Text>
        </View>
      </View>)}


    {costingName === ReferenceFeatures.EXPENSE_ENTRY && (<View style={[styles.section, { marginTop: 8 }]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <View>
            <Text style={styles.heading}>Costing No :</Text>
            <Text style={styles.costingNo}>{costing?.expenseEntryNo ? costing?.expenseEntryNo : "--"}</Text>
          </View>
          <View style={{display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Text style={styles.heading}>Company:</Text>
            <Text style={[styles.costingNo, { alignItems: "flex-end" }]}>{plantCode}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Costing Date :</Text>
          <Text style={styles.data}>{costing?.date ? moment(costing?.date).format('DD-MMM-YYYY') : "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Vendor Ledger :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.actLedName || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Expense Ledger :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.expenseLedgerName || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Expense Type :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.expenseType || "--"}</Text>
        </View>
      </View>)}

      {costingName === ReferenceFeatures.MV_DEAD_FREIGHT && (<View style={[styles.section, { marginTop: 8 }]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <View>
            <Text style={styles.heading}>Costing No :</Text>
            <Text style={styles.costingNo}>{costing?.costingNo ? costing?.costingNo : "--"}</Text>
          </View>
          <View style={{display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Text style={styles.heading}>Company:</Text>
            <Text style={[styles.costingNo, { alignItems: "flex-end" }]}>{plantCode}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Business Number :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.businessNo || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Supplier :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.supplierName || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Dead Freight Entry :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.deadFreightEntryDate || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Account :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.account || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Costing Date :</Text>
          <Text style={styles.data}>{costing?.deadFreightDate ? moment(costing?.deadFreightDate).format('DD-MMM-YYYY') : "--"}</Text>
        </View>
      </View>) }

      {costingName === ReferenceFeatures.MV_DEMURRAGE && (<View style={[styles.section, { marginTop: 8 }]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <View>
            <Text style={styles.heading}>Costing No :</Text>
            <Text style={styles.costingNo}>{costing?.costingNo ? costing?.costingNo : "--"}</Text>
          </View>
          <View style={{display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Text style={styles.heading}>Company:</Text>
            <Text style={[styles.costingNo, { alignItems: "flex-end" }]}>{plantCode}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Business Number :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.businessNumber || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Supplier :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.bpName || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Account Type :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.account || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Cost Type :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.costingType || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Costing Date :</Text>
          <Text style={styles.data}>{costing?.demurrageDate ? moment(costing?.demurrageDate).format('DD-MMM-YYYY') : "--"}</Text>
        </View>
      </View>)}

      {costingName === ReferenceFeatures.CREDIT_NOTE || costingName === ReferenceFeatures.DEBIT_NOTE && (<View style={[styles.section, { marginTop: 8 }]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <View>
            <Text style={styles.heading}>Costing No :</Text>
            <Text style={styles.costingNo}>{costing?.debitCreditNo ? costing?.debitCreditNo : "--"}</Text>
          </View>
          <View style={{display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Text style={styles.heading}>Company:</Text>
            <Text style={[styles.costingNo, { alignItems: "flex-end" }]}>{plantCode}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Note Type :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.noteType || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Account :</Text>
          <Text style={[styles.data, { fontWeight: 'extrabold' }]}>{costing?.account || "--"}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Entry Date :</Text>
          <Text style={styles.data}>{costing?.debitCreditEntryDate ? moment(costing?.debitCreditEntryDate).format('DD-MMM-YYYY') : "--"}</Text>
        </View>
        
      </View>) }

    </View>
    
  </Fragment>
  </>
)

}

export default CostingBasicDetails;