import { CommercialGetAllDto, CompanyCodeReq, CompanyTdsService, ContractModesEnum, ContractTermEnum, CurrencyConverterService, CurrencyDto, CurrencyExchangeRequestDto, CurrencyService, TaxDropDownDto, TaxesService, TdsResModel } from '@exportx/shared-models-and-services';
import { getLocalFormat, getNumberFromLocalString } from '@exportx/ui-utils';
import { Button, Col, Drawer, Form, FormInstance, Input, InputNumber, Popover, Row, Select, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';
import { QualityPriceAdjustments } from '../common';
import { InfoCircleOutlined } from '@ant-design/icons';

interface PriceIProps {
    totalBargeQuantity: number;
    formRef: FormInstance<any>;
    bargeSelection: any;
    taxTotal: any;
    setTaxTotal: React.Dispatch<any>;
    initialValues?: CommercialGetAllDto;
    qualityFieldValue?: number;
    setPriceAdjustmentDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
    priceAdjustmentDrawer?: boolean;
    setDummyRefresh: React.Dispatch<React.SetStateAction<number>>;
    dummyRefresh: number;
    submitHandler?: () => void;
    contractType: ContractModesEnum;
}

const CmPriceAdjustment = (props: PriceIProps) => {
    const { contractType, submitHandler, totalBargeQuantity, formRef, bargeSelection, setTaxTotal, taxTotal, initialValues, qualityFieldValue, setPriceAdjustmentDrawer, priceAdjustmentDrawer, setDummyRefresh, dummyRefresh } = props;
    const { Option } = Select;
    const { Text } = Typography;
    const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
    const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([])
    const [exchangeRate, setExchangeRate] = useState<any>()
    const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);

    const { authContext } = useAuthState();
    const companyTdsService = new CompanyTdsService();
    const taxesService = new TaxesService();
    const currencyConverterService = new CurrencyConverterService();
    const currencyService = new CurrencyService();
    const currency = authContext.defaultPlantCurrency;
    const [exchangeDateVisible, setExchangeDateVisible] = useState<boolean>(true);



    useEffect(() => {
        getTdsData();
        taxActiveDropDown();
        getCurrency();

    }, []);

    useEffect(() => {
        formRef.setFieldValue("baseCurrency", bargeSelection?.baseCurrency)
        visibleDisable(bargeSelection?.baseCurrency, currency);
        if (bargeSelection?.purchasingTerms === ContractTermEnum.NON_SPEC || bargeSelection?.noPriceAdjustment === "true") {
            formRef.setFieldValue('adjustedPriceValue', bargeSelection?.coalBasePrice);
        }
    }, [bargeSelection]);



    useEffect(() => {
        formRef.setFieldValue('adjustedPriceValue', formRef.getFieldValue('qualityAdjustPrice'));
        pricePmtOnChange(formRef.getFieldValue('qualityAdjustPrice') ? Number(formRef.getFieldValue('qualityAdjustPrice')) : 1)
    }, [dummyRefresh]);

    useEffect(() => {
        if (initialValues) {
            formRef.setFieldValue('totalBargeQuantity', totalBargeQuantity);
            formRef.setFieldValue('baseCurrency', initialValues?.baseCurrency);
            formRef.setFieldValue('adjustedPriceValue', Number(initialValues.adjustedPriceValue));
            formRef.setFieldValue('exchangeDate', initialValues.exchangeDate);
            formRef.setFieldValue('adjustedPriceValue', Number(initialValues.adjustedPriceValue));
            let transactionData = initialValues.costingTransactions || initialValues.transactionBarges

            formRef.setFieldValue('currencyType', transactionData[0]?.currencyType);

            if (initialValues?.baseCurrency !== authContext.defaultPlantCurrency) {
                getCurrencyExchangeRate(initialValues?.baseCurrency, authContext.defaultPlantCurrency, initialValues.exchangeDate, transactionData[0]?.currencyType);
            } else {
                visibleDisable(initialValues?.baseCurrency, currency);
            };
            pricePmtOnChange(Number(initialValues.adjustedPriceValue));

        };
    }, [initialValues]);

    useEffect(() => {
        formRef.setFieldValue('totalBargeQuantity', totalBargeQuantity);
        subTotalOnChangeHandler(Number(taxTotal?.fcTotal),
            initialValues?.taxId1 ? initialValues.taxId1 : formRef.getFieldValue('taxId1'),
            initialValues?.taxId2 ? initialValues.taxId2 : formRef.getFieldValue('taxId2'),
            initialValues?.tdsId ? initialValues.tdsId : formRef.getFieldValue('tdsId'));
        pricePmtOnChange(formRef.getFieldValue('adjustedPriceValue') ? Number(formRef.getFieldValue('adjustedPriceValue')) : 1);
        pcTypeCharges();
    }, [totalBargeQuantity]);



    const visibleDisable = (bargeSelectionCurrency: string, systemCurrency: string) => {
        if (bargeSelectionCurrency === systemCurrency) {
            setExchangeDateVisible(true);
            formRef.setFieldValue('exchangeDate', undefined);
        } else {
            setExchangeDateVisible(false);
        };
    };


    const pcTypeCharges = () => {
        setTaxTotal((prev) => {
            const freightPricePmt = Number(bargeSelection?.freightPricePmt) * Number(formRef.getFieldValue('totalBargeQuantity')) * (prev.exchangeRate ? Number(prev.exchangeRate) : 1);
            const fcAmount = bargeSelection?.fcCharges ? Number(bargeSelection?.fcCharges) : 0
            let fcCharges = fcAmount * (prev.exchangeRate ? Number(prev.exchangeRate) : 1);
            if (bargeSelection?.bargingType === "PMT") {
                fcCharges = Number(formRef.getFieldValue('totalBargeQuantity')) * fcAmount * (prev.exchangeRate ? Number(prev.exchangeRate) : 1);
            }
            const fcTotal = (freightPricePmt + fcCharges + Number(prev.totalCoalPrice)) || 0;
            formRef.setFieldValue('costingInvoiceTotal', Number(fcTotal.toFixed(2)).toLocaleString());
            formRef.setFieldValue('remainingBalance', Number(fcTotal.toFixed(2)).toLocaleString());

            return {
                ...prev,
                freightPricePmt: freightPricePmt ? Number(Number(freightPricePmt).toFixed(2)) : 0,
                fcCharges: fcCharges ? Number(Number(fcCharges)?.toFixed(2)) : 0,
                fcTotal: fcTotal ? Number(Number(fcTotal)?.toFixed(2)) : 0
            };
        });
    };

    useEffect(() => {
        pricePmtOnChange(formRef.getFieldValue('adjustedPriceValue') ? Number(formRef.getFieldValue('adjustedPriceValue')) : 1);
        subTotalOnChangeHandler(Number(taxTotal?.fcTotal), formRef.getFieldValue('taxId1'), formRef.getFieldValue('taxId2'), formRef.getFieldValue('tdsId'))
        pcTypeCharges();
    }, [qualityFieldValue]);


    if (initialValues?.exchangeDate) {
        const ex: any = moment(initialValues.exchangeDate)
        initialValues.exchangeDate = ex;
    }

    const getTdsData = () => {
        const req = new CompanyCodeReq();
        req.company = authContext.defaultPlant;
        req.date = moment().format('YYYY-MM-DD');
        companyTdsService.getTdsByCompanyCode(req).then(res => {
            if (res.status) {
                setTdsModel(res.data);
            } else {
                setTdsModel([]);
            }
        }).catch(err => {
            console.log(err);
            setTdsModel([]);
        });
    };

    const taxActiveDropDown = () => {
        taxesService.getActiveTaxDropDown().then(res => {
            if (res.status) {
                setTaxDropDown(res.data)
            } else {
                setTaxDropDown([])
            }
        }).catch(err => {
            console.log(err.message);
            setTaxDropDown([]);
        })
    };

    const getCurrency = () => {
        currencyService.getAllCurrenciesForDropDown().then(res => {
            if (res.status) {
                setCurrencies(res.data);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message));
    };

    const getCurrencyExchangeRate = (fromCurrency, toCurrency, date, currencyType) => {
        const req = new CurrencyExchangeRequestDto(fromCurrency, toCurrency, moment(date).format('YYYY-MM-DD'), currencyType);

        if (moment(req.exchangeDate).format('YYYY-MM-DD') === 'Invalid date') {
            return;
        }
        currencyConverterService.getCurrencyExchangeRate(req).then(res => {
            if (res.status) {
                setExchangeRate(res.data);
                if (res.data.exchangeRate) {
                    const exchangeRateValue = Number(res.data.exchangeRate) * (initialValues?.adjustedPriceValue ? Number(initialValues?.adjustedPriceValue) : Number(formRef.getFieldValue('qualityAdjustPrice')));
                    setTaxTotal((prev) => {
                        return {
                            ...prev,
                            exchangeRateValue: exchangeRateValue,
                            exchangeRate: Number(res.data.exchangeRate),
                            displayExchangeRate: Number(res.data.displayExchangeRate),
                            currencyType: res.data.currencyType,
                            purchaseRate: Number(res.data.purchaseRate),
                            sellingRate: Number(res.data.sellingRate)
                        }
                    });
                    pricePmtOnChange(formRef.getFieldValue("adjustedPriceValue") ? Number(formRef.getFieldValue("adjustedPriceValue")) : 1)
                };
            } else {
                delete taxTotal?.displayExchangeRate;
                delete taxTotal.exchangeRateValue;
                pricePmtOnChange(formRef.getFieldValue("adjustedPriceValue") ? Number(formRef.getFieldValue("adjustedPriceValue")) : 1)
                AlertMessages.getInfoMessage(res.internalMessage);
                // const exchangeRateValue = 1 * (initialValues?.adjustedPriceValue ? Number(initialValues?.adjustedPriceValue) : Number(formRef.getFieldValue('qualityAdjustPrice')));
                setTaxTotal((prev) => {
                    return {
                        ...prev,
                        exchangeRateValue: 1,
                        purchaseRate: 0,
                        sellingRate: 0
                    }
                });
                pcTypeCharges();
            }
        }).catch(err => { console.log(err.message) });
    };

    const exChangeDateHandler = (value) => {
        const currencyType = formRef.getFieldValue('currencyType');
        const exchangeDate = formRef.getFieldValue('exchangeDate');


        if(currencyType && exchangeDate){
            getCurrencyExchangeRate(formRef.getFieldValue('baseCurrency'), authContext.defaultPlantCurrency, moment(exchangeDate), currencyType);
        }
        // getCurrencyExchangeRate(formRef.getFieldValue('baseCurrency'), authContext.defaultPlantCurrency, value);
    };


    const currencyOnChangeHandler = (value) => {
        visibleDisable(value, currency);
        getCurrencyExchangeRate(value, authContext.defaultPlantCurrency, formRef.getFieldValue('exchangeDate'), '');
    };


    const suffixSelector = (
        <Form.Item name="baseCurrency" noStyle label="Please Select">
            <Select onChange={currencyOnChangeHandler} filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 60 }}
            >
                {currencies?.map((rec) => {
                    return <Option value={rec.currencyCode}>{rec.currencyCode}</Option>
                })}
            </Select>
        </Form.Item>
    );

    const suffixSelector2 = (
        <Form.Item name="baseCurrency" noStyle label="Please Select">
            <Select disabled onChange={currencyOnChangeHandler} filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 60 }}
            >
                {currencies?.map((rec) => {
                    return <Option value={rec.currencyCode}>{rec.currencyCode}</Option>
                })}
            </Select>
        </Form.Item>
    );

    const onTaxChange1 = (v: string, tb: number) => {
        let p: number = taxDropDown?.filter((rec) => rec?.taxId === v)[0]?.taxPercentage;
        if (!taxDropDown.length) {
            if (initialValues?.taxPercentage1) {
                p = initialValues.taxPercentage1
            }
        }
        const taxTotal1 = (v === undefined || v === null) ? 0 : (Number(p) / 100) * Number(tb);
        setTaxTotal((prev) => {
            const withTax = taxTotal1 + prev?.taxTotal2 + prev.fcTotal - (prev?.tdsTotal);
            formRef.setFieldValue('costingInvoiceTotal', Number(withTax.toFixed(2)).toLocaleString());
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const toBeSupplier = (withTax - totalAdjustedValue) || 0;
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());
            return {
                ...prev,
                taxTotal1: taxTotal1,
                taxPercentage1: Number(p),
                invoiceTotal: taxTotal1 + prev?.taxTotal2 + prev.fcTotal - (prev?.tdsTotal)
            }
        });
    };


    const onTaxChange2 = (v: string, tb: number) => {
        let p: number = taxDropDown?.filter((rec) => rec?.taxId === v)[0]?.taxPercentage;
        if (!taxDropDown.length) {
            if (initialValues?.taxPercentage2) {
                p = initialValues.taxPercentage2
            }
        }
        const taxTotal2 = (v === undefined || v === null) ? 0 : (Number(p) / 100) * Number(tb);
        setTaxTotal((prev) => {
            const withTax = prev?.taxTotal1 + taxTotal2 + prev.fcTotal - (prev?.tdsTotal)
            formRef.setFieldValue('costingInvoiceTotal', Number(withTax.toFixed(2)).toLocaleString());;
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const toBeSupplier = (withTax - totalAdjustedValue) || 0;
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());
            return {
                ...prev,
                taxTotal2: taxTotal2,
                taxPercentage2: Number(p),
                invoiceTotal: prev?.taxTotal1 + taxTotal2 + prev.fcTotal - (prev?.tdsTotal)
            }
        })
    };






    const tdsOnchange = (v: string, tb: number) => {
        let p: number = tdsModel?.filter((rec) => rec?.companyTdsId === v)[0]?.tdsValue;
        if (!tdsModel.length) {
            if (initialValues?.tdsPercentage) {
                p = initialValues.tdsPercentage
            }
        }

        const tdsTotal = (v === undefined || v === null) ? 0 : (Number(p) / 100) * Number(tb);
        setTaxTotal((prev) => {
            const withTax = prev?.taxTotal1 + prev?.taxTotal2 + prev.fcTotal - (tdsTotal)
            formRef.setFieldValue('costingInvoiceTotal', Number(withTax.toFixed(2)).toLocaleString());
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const toBeSupplier = (withTax - totalAdjustedValue) || 0;
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());
            return {
                ...prev,
                tdsTotal: tdsTotal,
                tdsPercentage: Number(p),
                invoiceTotal: prev?.taxTotal1 + prev?.taxTotal2 + prev.fcTotal - (tdsTotal)
            };
        });

    };



    const pricePmtOnChange = (value: number) => {
        setTaxTotal((prev) => {
            const exchangeRateValue = Number(value) * (prev.exchangeRate ? prev.exchangeRate : 1);
            return {
                ...prev,
                exchangeRateValue: exchangeRateValue,
                totalCoalPrice: exchangeRateValue * Number(formRef.getFieldValue('totalBargeQuantity'))
            };
        });
    };

    //pricePmtOnChange is not execute totalCoalPrice changing time
    useEffect(() => {
        pcTypeCharges();
        subTotalOnChangeHandler(Number(taxTotal?.fcTotal), formRef.getFieldValue('taxId1'), formRef.getFieldValue('taxId2'), formRef.getFieldValue('tdsId'))
    }, [taxTotal?.totalCoalPrice, taxTotal?.fcTotal]);


    const subTotalOnChangeHandler = (e: number, t1: string, t2: string, tds: string) => {
        if (!t1 || !t2 || tds) {
            formRef.setFieldValue('remainingBalance', Number(taxTotal?.totalCoalPrice.toFixed(2)).toLocaleString());
            formRef.setFieldValue('costingInvoiceTotal', Number(taxTotal?.totalCoalPrice.toFixed(2)).toLocaleString());
        }
        console.log(e, t1, t2, tds)
        if (t1 !== undefined) {
            onTaxChange1(t1, Number(e));
        };
        if (t2 !== undefined) {
            onTaxChange2(t2, Number(e));
        };
        if (tds !== undefined) {
            tdsOnchange(tds, Number(e));
        };
    };



    return (
        <>
            <Form
                form={formRef}
                layout='vertical'
                initialValues={initialValues}
            >
                <Row >
                    <Form.Item name={'cmCostingId'} hidden>
                        <Input></Input>
                    </Form.Item>
                </Row>

                <br />
                <Row>
                    <Col span={24}>
                        <Row >
                            <Col span={6} offset={0} >
                                <Form.Item 
                                    label="Currency Type" 
                                    name="currencyType" style={{ width: '80%' }}
                                >
                                    <Select 
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} 
                                        allowClear 
                                        showSearch 
                                        defaultValue={!formRef.getFieldValue('currencyType') && formRef.setFieldValue('currencyType', "JISDOR")}
                                        placeholder='Select Currency'
                                        onChange={exChangeDateHandler}
                                        disabled={exchangeDateVisible}

                                    >
                                        {['JISDOR', 'Middle Rate', 'Tax Rate'].map((item, index) => {
                                            return <Option value={item} key={index}>{item}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} >
                                <Form.Item name={'exchangeDate'} label={"Exchange Date"} >
                                    <DatePicker style={{ width: 160 }} onChange={exChangeDateHandler} disabled={exchangeDateVisible} />
                                </Form.Item>
                            </Col>

                            <Col span={8} offset={2}>
                                <Form.Item label={"Adjusted Price PMT"} name={'adjustedPriceValue'}>
                                    <InputNumber
                                        onChange={(e) => pricePmtOnChange((Number(e)))}
                                        addonAfter={suffixSelector}
                                        disabled={true}>
                                    </InputNumber>
                                </Form.Item>
                            </Col>

                            <Col>


                            </Col>

                        </Row>


                        <table className="table table-bordered table-sm">
                            <tr>
                                <th colSpan={2}>Exchange Rate</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.displayExchangeRate, currency)}</Text> &nbsp;
                                    {formRef.getFieldValue('currencyType') === 'Middle Rate' && (<Popover
                                        content={
                                            <>
                                               <Typography style={{ fontSize: 10 }}>Purchase Rate: <b>{taxTotal?.purchaseRate}</b></Typography>
                                               <Typography style={{ fontSize: 10 }}>Selling Rate <b>{taxTotal?.sellingRate}</b></Typography>
                                            </>
                                        }
                                        title="Rates"
                                        placement="bottom"
                                    >
                                        <InfoCircleOutlined style={{ color: "#0295D4" }} />

                                    </Popover>)}
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Price after Exchange</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.exchangeRateValue, currency)}</Text>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Total Coal Price</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.totalCoalPrice, currency)}</Text>

                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>FC Charges</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.fcCharges, currency)}</Text>

                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Freight</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.freightPricePmt, currency)}</Text>

                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Total Amount</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.fcTotal, currency)}</Text>

                                </td>
                            </tr>
                            <tr>
                                <th>TAX 1</th>
                                <td>
                                    <Form.Item name={"taxId1"}>
                                        <Select
                                            style={{ width: '160px' }}
                                            onChange={(v) => onTaxChange1(v, taxTotal?.fcTotal)}
                                            placeholder='Select tax'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={undefined}>Not Applicable</Option>
                                            {taxDropDown?.map((rec) => {
                                                return <Option value={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                                            })}
                                        </Select>
                                    </Form.Item >
                                </td>
                                <td>{getLocalFormat(taxTotal?.taxTotal1, currency)}</td>
                            </tr>
                            <tr>
                                <th>TAX 2</th>
                                <td>
                                    <Form.Item name={"taxId2"}>
                                        <Select
                                            style={{ width: '160px' }}
                                            onChange={(v) => onTaxChange2(v, taxTotal?.fcTotal)}
                                            placeholder='Select tax'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={undefined}>Not Applicable</Option>
                                            {taxDropDown?.map((rec) => {
                                                return <Option value={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                                            })}
                                        </Select>
                                    </Form.Item >
                                </td>
                                <td>{getLocalFormat(taxTotal?.taxTotal2, currency)}</td>
                            </tr>
                            <tr>
                                <th><Form.Item label={'TDS'}></Form.Item></th>
                                <td>
                                    <Form.Item name={"tdsId"}>
                                        <Select
                                            style={{ width: '160px' }}
                                            onChange={(v) => tdsOnchange(v, taxTotal?.fcTotal)}
                                            placeholder='Select TDS'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={undefined}>Not Applicable</Option>
                                            {tdsModel?.map((rec) => {
                                                return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
                                            })}
                                        </Select>
                                    </Form.Item >
                                </td>
                                <td>{getLocalFormat(taxTotal?.tdsTotal, currency)}</td>
                            </tr>
                            <tr>
                                <th colSpan={2} >
                                    Amount
                                </th>
                                <td>
                                    <Form.Item name={'costingInvoiceTotal'}>
                                        <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
                                        </InputNumber>
                                    </Form.Item>

                                </td>

                            </tr>
                            <tr>
                                <th colSpan={2} >
                                    Adjusted Against Advances
                                </th>
                                <td>
                                    <Form.Item name={'totalAdjustedValue'}>
                                        <InputNumber addonAfter={suffixSelector2} style={{ width: 204 }} disabled>
                                        </InputNumber>
                                    </Form.Item>

                                </td>

                            </tr>
                            <tr>
                                <th colSpan={2} >
                                    To be paid to Supplier
                                </th>
                                <td>
                                    <Form.Item name={'remainingBalance'}>
                                        <InputNumber addonAfter={suffixSelector2} style={{ width: 204 }} disabled>
                                        </InputNumber>
                                    </Form.Item>

                                </td>

                            </tr>
                            <tr>
                                <th colSpan={2} >
                                </th>
                                <th  >

                                    <Button
                                        onClick={submitHandler}
                                        type='primary'>Submit Invoice</Button>

                                </th>
                                {/* <td>
                                    <Text>{getLocalFormat(taxTotal?.invoiceTotal, currency)}</Text>
                                </td> */}
                            </tr>



                        </table>

                    </Col>

                </Row>
            </Form >
            <Drawer
                open={priceAdjustmentDrawer}
                key={dummyRefresh}
                footer={null}
                width={1000}
                closable={false}

            >
                <QualityPriceAdjustments
                    selectedRowData={bargeSelection}
                    setDummyRefresh={setDummyRefresh}
                    commercialProp={"Commercial"}
                    setPriceAdjustmentDrawer={setPriceAdjustmentDrawer}
                    formRef={formRef}
                    contractType={contractType}
                />
            </Drawer>
        </>
    )
}

export default CmPriceAdjustment