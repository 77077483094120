import { Space, Flex } from 'antd';

import { MenuOutlined } from '@ant-design/icons';
import './header-nav-component.css';
interface IProps {
  toggle: () => void;
  title: string;
}
export const HeaderBreadcrumb = (props: IProps) => {
  return (
    <div className="card-container" style={{marginBottom: 8}}>
      <Flex align="middle">
        <Space size={20}>
          <span style={{ fontSize: 20 }}>
            <MenuOutlined onClick={props.toggle} />
          </span>
          <span className="card-title">{props.title}</span>
        </Space>
      </Flex>
    </div>
  );
};

export default HeaderBreadcrumb;