import { UomsDto, UomsService } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Input, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  FilterableGrid,
  isPermissionExist,
} from '../../../../../common';
import './uoms-grid.css';

export interface UomsGridProps {}

export function UomsGrid() {
  const navigate = useNavigate();

  const [uomsData, setUomsData] = useState<UomsDto[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const { formatMessage: fm } = useIntl();

  const service = new UomsService();
  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: `Unit Of Measurement`,
      dataIndex: 'uomName',
      sorter: (a, b) => a.uomName.localeCompare(b.uomName),
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
    },
  ];

  useEffect(() => {
    if (!isPermissionExist([59])) {
      return navigate('/');
    }
  }, []);

  useEffect(
    () => {
      getAllUoms();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getAllUoms = () => {
    service
      .getAllUoms()
      .then((res) => {
        console.log(res);
        if (res.status) {
          setUomsData(res.data);
        } else {
          if (res.errorCode) {
            setUomsData([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
        setUomsData([]);
      });
  };

  const deleteUoms = (uomsViewData: UomsDto) => {
    uomsViewData.isActive = uomsViewData.isActive ? false : true;
    service
      .ActivatedeActivateUoms(uomsViewData)
      .then((res) => {
        console.log(res);
        if (res.status) {
          getAllUoms();
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: `Uoms ${
                uomsViewData.isActive ? ' activated ' : ' deactivated '
              } SuccessFully`,
            }),
          );
        } else {
          if (res.errorCode) {
            AlertMessages.getErrorMessage(res.internalMessage);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const view = (uomData: UomsDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (uomData) {
      isNewRecord = false;
    }
    let pathToreDirect = '/UOMs-form';
    navigate(pathToreDirect, { state: { uomData, isNewRecord, isUpdate } });
  };

  return (
    <>
      {/* <Card
        title={'Unit of Measurements'}
        className="default-card-class"
        extra={
          <>
            {isPermissionExist([58]) && (
              <Button onClick={() => view(undefined, false)}>
                {fm({ id: 'common.create', defaultMessage: 'Create' })}
              </Button>
            )}
          </>
        }
      > */}
      <Row justify="space-between" className="mb-12 mt-8">
        <Col>
          <Input.Search
            placeholder="Search Unit Of Measurement"
            allowClear
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
            onSearch={(value) => {
              setSearchedText(value);
            }}
            className="input-search"
          />
        </Col>
        <Col>
          <>
            {isPermissionExist([58]) && (
              <Button
                className="new-button"
                onClick={() => view(undefined, false)}
              >
                {fm({ id: 'common.create', defaultMessage: 'Create' })}
              </Button>
            )}
          </>
        </Col>
      </Row>
      <FilterableGrid
        rowKey="uomId"
        mainColumns={columnsSkelton}
        data={uomsData}
        filterColumnsData={[]}
        activateOrDeactivate={deleteUoms}
        view={view}
      />
      {/* </Card> */}
    </>
  );
}

export default UomsGrid;
