import {
  CurrencyDeactivateDto,
  CurrencyDto,
  CurrencyService,
} from '@exportx/shared-models-and-services';
import type { ColumnsType } from 'antd/lib/table';
import { useIntl } from 'react-intl';
//import { AlertMessages } from '../../../../../common/notifications';
import { Button, Card, Col, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  FilterableGrid,
  isPermissionExist,
  useAuthState,
} from '../../../../../common';

export const CurrencyGrid = () => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { authContext } = useAuthState();
  const [gridData, setgridData] = useState<CurrencyDto[]>([]);
  const [searchedText, setSearchedText] = useState('');

  const service = new CurrencyService();

  useEffect(() => {
    if (!isPermissionExist([7])) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGridData = () => {
    service
      .getAllCurrencies()
      .then((res) => {
        if (res.status) {
          setgridData(res.data);
        } else {
          // AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const activateOrDeactivate = (currencyData: CurrencyDto) => {
    const currencyDeactivateDto: CurrencyDeactivateDto =
      new CurrencyDeactivateDto(
        currencyData.currencyId,
        authContext.user.userName,
        currencyData.versionFlag,
      );
    service
      .activateOrDeactivate(currencyDeactivateDto)
      .then((res) => {
        if (res.status) {
          getGridData();
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: `Currency ${
                currencyData.isActive ? ' activated ' : ' deactivated '
              } SuccessFully`,
            }),
          );
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const view = (currencyData: CurrencyDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (currencyData) {
      isNewRecord = false;
    }
    let pathToreDirect = '/createCurrency';
    navigate(pathToreDirect, {
      state: { currencyData, isNewRecord, isUpdate },
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: fm({
        id: 'mdm.components.currencyGrid.currencyName',
        defaultMessage: 'Currency Name',
      }),
      dataIndex: 'currencyName',
      key: 'currencyName',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
    },
    {
      title: fm({
        id: 'mdm.components.currencyGrid.currencyCode',
        defaultMessage: 'Currency Code',
      }),
      dataIndex: 'currencyCode',
      key: 'currencyCode',
      filteredValue: [String(searchedText).toLowerCase()],
    },
    {
      title: fm({
        id: 'mdm.components.currencyGrid.currencySymbol',
        defaultMessage: 'Currency Symbol',
      }),
      dataIndex: 'currencySymbol',
      key: 'currencySymbol',
    },
  ];
  return (
    <>
      {/* <Card
      title={fm({ id: `Currency.header`, defaultMessage: `Currency Details` })}
      style={{ overflow: 'auto' }}
      className="default-card-class"
      extra={
        <>
          {isPermissionExist([6]) && (
            <Button onClick={() => view(undefined, false)}>
              {fm({ id: 'common.create', defaultMessage: 'Create' })}
            </Button>
          )}
        </>
      }
      bodyStyle={{ overflow: 'auto' }}
    > */}
      <Row justify="space-between" className="mb-12 mt-8">
        <Col>
          <Input.Search
            placeholder="Search Currency"
            allowClear
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
            onSearch={(value) => {
              setSearchedText(value);
            }}
            className="input-search"
          />
        </Col>
        <Col>
          <>
            {isPermissionExist([6]) && (
              <Button
                className="new-button"
                onClick={() => view(undefined, false)}
              >
                {fm({ id: 'common.create', defaultMessage: 'Create' })}
              </Button>
            )}
          </>
        </Col>
      </Row>
      <FilterableGrid
        rowKey="currencyId"
        mainColumns={columns}
        data={gridData}
        filterColumnsData={[
        ]}
        activateOrDeactivate={activateOrDeactivate}
        view={view}
      />
      {/* </Card> */}
    </>
  );
};

export default CurrencyGrid;
