import { UploadOutlined } from '@ant-design/icons';
import { CoalIndexedPriceService, ColaIndexTypeEnum, ProrateGradeIndexes, SpecificationsEnum } from '@exportx/shared-models-and-services';
import { ClassValidator, getBase64 } from '@exportx/ui-utils';
import { Button, Card, Col, Form, Input, InputNumber, message, Row, Upload, UploadFile, UploadProps, } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../../common';
import DatePicker from '../../../../../common/data-picker/date-picker';

interface ICoalIndexFormProps {
    coalIndexType: ColaIndexTypeEnum,
    saveCallBack: () => void,
    initialValues: any,
    weeklyData:any,
    setInitialValues: any
}
const classValidator = new ClassValidator();
export const CoalIndexPricesForm = (props: ICoalIndexFormProps) => {
    const { authContext } = useAuthState();
    const [fileList, setFileList] = useState([]);
    const { coalIndexType, saveCallBack, initialValues, weeklyData, setInitialValues } = props;
    const { formatMessage: fm } = useIntl();
    const [formRef] = Form.useForm();
    const [selectedWeekEndingDate, setSelectedWeekEndingDate] = useState<string | null>(null)
    
    

    useEffect(()=>{
        if(initialValues){
            setSelectedWeekEndingDate(initialValues.weekEndingDate)
            const weekDate = moment(initialValues.weekEndingDate);
            let newInitialValues = {
                id: initialValues.id,
                date: weekDate.isValid() ? weekDate : "",
                gar3400: initialValues.gar3400,
                gar4200: initialValues.gar4200,
                gar5000: initialValues.gar5000,
                gar5800: initialValues.gar5800,
                gar6500: initialValues.gar6500,
                filesData: initialValues.filesData,
                createdAt: initialValues.createdAt,
                createdUser: initialValues.createdUser,
                updatedAt: initialValues.updatedAt,
                updatedUser: initialValues.updatedUser,
                versionFlag: initialValues.versionFlag,
            }
            setFileList(newInitialValues.filesData);
            formRef.setFieldsValue(newInitialValues);

        }
    },[initialValues,formRef])

    const cancelHandler = () => {
        formRef.resetFields()
        setInitialValues(null)
        setSelectedWeekEndingDate(null)
    }

    const service = new CoalIndexedPriceService();

    const getDisabledDates = (date: moment.Moment) => {
        if (coalIndexType === ColaIndexTypeEnum.WEEKLY) {
            const isUpdatingSelectedDate = selectedWeekEndingDate ? moment(selectedWeekEndingDate).isSame(date, 'week') : '';

            if (isUpdatingSelectedDate) {
                return false;
            }

            const isInDisabledWeek = weeklyData.some(item => {
                const savedDate = moment(item.weekEndingDate);
                const weekStart = savedDate.clone().startOf('week');
                const weekEnd = savedDate.clone().endOf('week');
    
                return date.isBetween(weekStart, weekEnd, 'days', '[]');
            });
    
            return isInDisabledWeek;
        }
        return false;
    };

    const submitHandler = () => {
        formRef.validateFields().then(async (formValues) => {
            if (formValues.filesData.file) {
                const base64 = await getBase64(formValues.filesData.fileList[0].originFileObj);
                formValues.filesData = [{
                        ...formValues.filesData.file,
                        base64Url: base64,
                        fileDescription: 'ICI file',
                }]
            }
            formValues.date =
                coalIndexType === ColaIndexTypeEnum.WEEKLY
                    ? moment(formValues.date).format('YYYY-MM-DD')
                    : moment(formValues.date).endOf('month').format('YYYY-MM-DD');
            
            let requestObject;
            if(initialValues){
                requestObject = {
                    coalIndexType,
                    ...formValues,
                    id: initialValues.id || '',
                    createdUser: authContext.user.userName,
                };
            } else {
                requestObject = {
                    coalIndexType,
                    ...formValues,
                    createdUser: authContext.user.userName,
                };
            }
    
            saveCoalIndexPrices(requestObject);
        });
    };
    

    const saveCoalIndexPrices = (createData: any) => {
        service.createCoalIndexPrice(createData).then(res => {
            if (res.status) {
                saveCallBack();
                formRef.resetFields()
                setFileList([])
                setInitialValues(null)
                setSelectedWeekEndingDate(null)
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const saveMonthly = (createData: any) => {
        service.monthlyAvgCoalIndexPrice(createData).then(res => {
            if (res.status) {
                saveCallBack();
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const uploadFieldProps = {
        multiple: false,
        onRemove: file => {
            setFileList([]);
            formRef.setFieldsValue({ filesData: [] })
        },
        beforeUpload: (file: any) => {
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = data => {
                setFileList([file]);
                return false;
            }
        },

        fileList: fileList,
    }

    return (
        <Form 
        layout="vertical" 
        form={formRef}
        // initialValues={initialValues}
         >
            <Card>
                <Row justify='space-evenly'>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Form.Item
                            name="date"
                            label={coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'Week Ending' : 'Month'}
                            rules={
                                [
                                    { required: true, message: '' },

                                ]
                            }
                        >
                            <DatePicker disabledDate={getDisabledDates} style={{ width: '100%' }} picker={coalIndexType === ColaIndexTypeEnum.MONTHLY ? "month" : undefined} />
                        </Form.Item>
                    </Col>
                    {ProrateGradeIndexes.garIndexes.map((garIndex, index) => {
                        return <Col
                            xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}
                        >
                            <Form.Item
                                label={`${garIndex}  ${SpecificationsEnum.GAR} (${ProrateGradeIndexes.narIndexes[index]}  ${SpecificationsEnum.NAR})`}
                                name={`gar${garIndex}`}
                                rules={
                                    [
                                        {
                                            required: true, message: ''
                                        },
                                        //...classValidator.onlyNumeric()
                                    ]}
                            >
                                <Input type='number' placeholder={`PMT in USD`} autoComplete='off' />
                            </Form.Item>
                        </Col>
                    })
                    }
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Form.Item
                            name="filesData"
                            label='ICI file'
                            rules={
                                [
                                    { required: true, message: '' },

                                ]
                            }
                        >
                            <Upload {...uploadFieldProps}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

                <Row align='middle' justify='center'>
                    <Col>
                        <Button type='primary' onClick={submitHandler}>Submit</Button>
                    </Col>
                    &nbsp;
                    &nbsp;
                    {initialValues && (<Col>
                        <Button onClick={cancelHandler}>Cancel</Button>
                    </Col>)}
                </Row>
            </Card>
        </Form>
    )
}

export default CoalIndexPricesForm;