import {
  DeleteOutlined,
  EditOutlined,
  FilePdfTwoTone,
  PlusSquareTwoTone,
} from '@ant-design/icons';
import {
  BNoRequest,
  BPDropDownDto,
  BPFCostingParentDto,
  BargeCostingIdReqDto,
  BargeCostingVouchersParentDto,
  BargeCostingsService,
  BargeService,
  BpCategoryGetDto,
  BusinessNoDropDownDto,
  BusinessNoPlantCodeReq,
  BusinessNumberService,
  BusinessPartnerService,
  BusinessPartnerTypeEnum,
  ContractStatusEnum,
  ContractTypeDto,
  CostingWorkLogsEnum,
  PlantCodeReqDto,
  PurchaseContractService,
  PurchaseDropDownDto,
  ReferenceFeatures,
  bargeCostingDeleteIdReqDto,
} from '@exportx/shared-models-and-services';
import { getLocalFormat, internationalFormattedValue } from '@exportx/ui-utils';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
// import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../../common';
import AddInvoiceDrawer from '../../add-invoice-drawer';
import Invoice from '../../invoice/invoice';
import { SequenceUtils } from '@exportx/ui-utils';
import TabPane from 'antd/es/tabs/TabPane';
import { Option } from 'antd/es/mentions';
import { useNavigate } from 'react-router-dom';
import { BargeCostingApproval } from '../barge-costing-Approval';
import debounce from 'lodash/debounce';
interface IBargeCostingsList {
  setEditDataToForm?: (data: any) => void;
  paramsId?: any;
  accessId?: any;
}

export const BargeCostingsList = (props: IBargeCostingsList) => {
  const { formatMessage: fm } = useIntl();
  const { authContext } = useAuthState();
  const [bgVouchers, setBgVouchers] = useState<BargeCostingVouchersParentDto>();
  const [bargeCostingData, setBargeCostingData] = useState<
    BPFCostingParentDto[]
  >([]);
  const service = new BargeCostingsService();
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingId, setCostingId] = useState<any>();
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const [searchedText, setSearchedText] = useState('');
  const [businessDropDown, setBusinessDropDown] = useState<
    BusinessNoDropDownDto[]
  >([]);
  const [bargeDropDown, setBargeDropDown] = useState<any[]>([]);
  const [supplier, setSupplier] = useState<BPDropDownDto[]>();
  const [selectedBusinessNo, setSelectedBusinessNo] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedBarge, setSelectedBarge] = useState(null);

  const businessService = new BusinessNumberService();
  const bargeService = new BargeService();
  const businessPartnerService = new BusinessPartnerService();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
    total: 0,
  });
  const [dateRangeType, setDateRangeType] = useState<string | null>(null);
  const [dateRangeValues, setDateRangeValues] = useState<{
    from: any | null;
    to: any | null;
  }>({
    from: null,
    to: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getBargeCostingGridData();
  }, []);

  useEffect(() => {
    if (!isPermissionExist([396, 400])) navigate('/');
  }, []);

  // useEffect(() => {
  //   service
  //     .getBargeCostingRejectedParentData(
  //       new PlantCodeReqDto(authContext.defaultPlant),
  //     )
  //     .then((res) => {
  //       if (res.status) {
  //         setBargeCostingData(res.data);
  //       } else {
  //         setBargeCostingData([]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setBargeCostingData([]);
  //     });
  // }, []);

  const getBargeCostingGridData = useCallback(
    (
      page = 1,
      pageSize = 25,
      search?: string,
      businessId?: string,
      supplierId?: string,
      bargeId?: string,
      fromDate?: moment.Moment,
      toDate?: moment.Moment,
    ) => {
      const req = new ContractTypeDto();
      req.contractType = undefined;
      req.plantCode = authContext.defaultPlant;
      req.limit = pageSize;
      req.offset = (page - 1) * pageSize;
      req.searchString = search;
      req.businessNoId = businessId;
      req.businessPartnerId = supplierId;
      req.bargeId = bargeId;
      req.fromDate = fromDate?.format('YYYY-MM-DD');
      req.toDate = toDate?.clone().add(1, 'day').format('YYYY-MM-DD');

      service
        .getBargeCostingRejectedParentData(req)
        .then((res) => {
          if (res.status) {
            setBargeCostingData(res.data);
            setPagination((prev) => ({
              ...prev,
              current: page,
              pageSize: pageSize,
              total: res.totalCount || 0,
            }));
          } else {
            setBargeCostingData([]);
            console.log(res.internalMessage);
          }
        })
        .catch((err) => {
          setBargeCostingData([]);
          console.log(err.message);
        });
    },
    [],
  );

  useEffect(() => {
    editHandler(props.paramsId);
  }, [props.paramsId]);

  useEffect(() => {
    getAllBusinessPartnersVendorsDropDown();
    getBusinessNoDropDown();
    getBargeDropDown();
  }, []);

  const deleteBargeCosting = (costingId) => {
    const req = new bargeCostingDeleteIdReqDto(
      costingId,
      authContext.defaultPlant,
    );
    service
      .deleteBargeCosting(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const editHandler = (costingId: string) => {
    service
      .getBargeCostingDataByCostingId(
        new BargeCostingIdReqDto(costingId, authContext.defaultPlant),
      )
      .then((res) => {
        if (res.status) {
          props.setEditDataToForm(res.data);
        } else {
          //setBargeCostingData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        // setBargeCostingData([]);
      });
  };

  const vouchersData = (id: string) => {
    const req = new BargeCostingIdReqDto(id, authContext.defaultPlant);
    service
      .getBargeCostingInvoice(req)
      .then((res) => {
        if (res.status) {
          setBgVouchers(res.data);
          setDummyRefresh((prev) => prev + 1);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setBgVouchers(undefined);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getAllBusinessPartnersVendorsDropDown = () => {
    const req = new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR);
    businessPartnerService
      .getAllBusinessPartnersForDropDown(req)
      .then((res) => {
        if (res.status) {
          setSupplier(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant);
    businessService
      .getBusinessNoDropDown(req)
      .then((res) => {
        if (res.status) {
          setBusinessDropDown(res.data);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getBargeDropDown = () => {
    const req = new BNoRequest('');
    req.plantCode = authContext.defaultPlant;
    bargeService
      .getBargeDropForBusinessNo(req)
      .then((res) => {
        if (res.status) {
          setBargeDropDown(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleBusinessNoChange = (value) => {
    setSelectedBusinessNo(value);
    getBargeCostingGridData(
      1,
      pagination.pageSize,
      searchedText,
      value,
      selectedSupplier,
      selectedBarge,
      dateRangeValues.from,
      dateRangeValues.to,
    );
  };

  const handleSupplierChange = (value) => {
    setSelectedSupplier(value);
    getBargeCostingGridData(
      1,
      pagination.pageSize,
      searchedText,
      selectedBusinessNo,
      value,
      selectedBarge,
      dateRangeValues.from,
      dateRangeValues.to,
    );
  };

  const handleBargeChange = (value) => {
    setSelectedBarge(value);
    getBargeCostingGridData(
      1,
      pagination.pageSize,
      searchedText,
      selectedBusinessNo,
      selectedSupplier,
      value,
      dateRangeValues.from,
      dateRangeValues.to,
    );
  };

  const tableColumns: TableColumnsType<any> = [
    // {
    //     title: 'Action',
    //     dataIndex: 'action',
    //     width: '20%',
    //     align: 'center',
    //     render: (text, rowData) => {
    //         return (
    //             <span>
    //                 {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) && isPermissionExist([props?.accessId?.bargeEdit]) && (

    //                     <Tooltip placement="topRight" title="Edit">
    //                         <EditOutlined type="edit"
    //                             onClick={() => editHandler(rowData.costingId)}
    //                             style={{ color: '#1890ff', fontSize: '20px' }}
    //                         /></Tooltip>)}

    //                 <Divider type="vertical" />
    //                 {isPermissionExist([props?.accessId?.bargeDelete]) && (
    //                 <Popconfirm
    //                     onConfirm={e => {
    //                         //activateOrDeactivate(RowData);
    //                         deleteBargeCosting(rowData.costingId)
    //                     }}
    //                     title={fm({ id: "common.deleteConfirmMsg", defaultMessage: 'Are you sure to Delete Record ?' })}>
    //                     <Tooltip placement="topRight" title="Delete">
    //                         <DeleteOutlined type='delete' onClick={() => { }} style={{ color: 'red', fontSize: '20px' }} />
    //                     </Tooltip>
    //                 </Popconfirm>
    //                 )}
    //                 <Divider type="vertical" />
    //                 {isPermissionExist([props?.accessId?.view]) && (
    //                 <Tooltip placement="topRight" title="Add Invoice">
    //                     <PlusSquareTwoTone onClick={() => {
    //                         setDrawerOpen(true);
    //                         setCostingId((prev) => { return { ...prev, costingId: rowData.costingId, totalAmount: rowData.totalSumAmount } });
    //                         setDummyRefresh(prev => prev + 1);
    //                     }}
    //                         style={{ color: '#1890ff', fontSize: '20px' }} />
    //                 </Tooltip>)}
    //                 <Divider type='vertical'></Divider>
    //                 <PDFDownloadLink
    //                     document={<Invoice key={dummyRefresh} invoice={bgVouchers} demurrageName={''} costingName={ReferenceFeatures.BARGE_CPC_COSTING} />}
    //                     fileName={`${rowData.costingNo}.pdf`}
    //                 >
    //                     <Tooltip placement="topRight" title="Invoice">
    //                         <FilePdfTwoTone onClick={() => vouchersData(rowData.costingId)} style={{ color: '#1890ff', fontSize: '20px' }}
    //                         />
    //                     </Tooltip>

    //                 </PDFDownloadLink>
    //             </span>
    //         )
    //     }
    // },
    {
      title: 'Costing No',
      dataIndex: 'costingNo',
      // sorter: (a, b) => a.costingNo.localeCompare(b.costingNo),
      // sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
      render: (value, record: any, index) => {
        let link = `/#/barge-costing-detail-view?costing_id=${record.costingId}`;
        return (
          <>
            {' '}
            {
              <a href={link} className="link">
                {value}
              </a>
            }
          </>
        );
      },
    },

    {
      title: ' Barge Supplier',
      dataIndex: 'bargeSupplier',
      // sorter: (a, b) => a.bargeSupplier.localeCompare(b.bargeSupplier),
      // sortDirections: ['descend', 'ascend'],
    },
    // {
    //     title: 'Date',
    //     dataIndex: 'costingDate',
    //     sorter: (a, b) => moment(a.costingDate).unix() - moment(b.costingDate).unix(),
    //     sortDirections: ['descend', 'ascend'],
    //     render: (value, record) => {
    //         return <>{moment(value).format('YYYY-MM-DD')}</>
    //     }
    // },
    {
      title: 'Business No',
      dataIndex: 'businessNumber',
      render: (value, record) => {
        if (record.bnId) {
          const link = `/#/bn-detail-view?bn_Id=${record.bnId}`;
          return (
            <a href={link} target="_blank" className="link">
              {value}
            </a>
          );
        }
        return <span>{value}</span>;
      },
    },
    // {
    //   title: 'Barges',
    //   dataIndex: 'bargeNo',
    //   render: (value, record) => {
    //     if (record.bargeId) {
    //       const link = `/#/barge-detail-view?barge_id=${record.bargeId}`;
    //       return (
    //         <a href={link} className="link" target="_blank">
    //           {`${SequenceUtils.formatNumberToSpecificLength(
    //             record.bargeNo,
    //           )} (${record.bargeName})`}
    //         </a>
    //       );
    //     }
    //     return <span>{`${value} (${record.bargeName})`}</span>;
    //   },
    // },
    {
      title: 'Barges',
      dataIndex: 'barges',
      render: (barges) => {
        return (
          <>
            {barges.map((barge, index) => {
              const link = `/#/barge-detail-view?barge_id=${barge.bargeId}`;
              return (
                <div key={barge.bargeId}>
                  <a href={link} className="link" target="_blank">
                    {SequenceUtils.formatNumberToSpecificLength(barge.bargeNo) +
                      ' ' +
                      `(${barge.bargeName})`}
                  </a>
                  {index !== barges.length - 1 && <Divider />}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      title: 'Payable',
      dataIndex: 'totalAmount',
      align: 'right',
      // sorter: (a, b) => a.totalAmount.localeCompare(b.totalAmount),
      // sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${record.billingCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      render: (value, record) => <StatusTag status={record.approvalStatus} />,
    },
  ];

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string, page: number, pageSize: number) => {
        getBargeCostingGridData(
          page,
          pageSize,
          value,
          selectedBusinessNo,
          selectedSupplier,
          selectedBarge,
          dateRangeValues.from,
          dateRangeValues.to,
        );
      }, 500),
    [
      getBargeCostingGridData,
      selectedBusinessNo,
      selectedSupplier,
      selectedBarge,
      dateRangeValues,
    ],
  );

  const dateRangeDropDown = [
    { value: 'this_month', label: 'This Month' },
    { value: 'previous_month', label: 'Previous Month' },
    { value: 'this_quarter', label: 'This Quarter' },
    { value: 'previous_quarter', label: 'Previous Quarter' },
    { value: 'this_year', label: 'This Year' },
    { value: 'previous_year', label: 'Previous Year' },
    { value: 'custom', label: 'Custom Date Range' },
  ];

  const handleDateRangeTypeChange = (value: string | null) => {
    setDateRangeType(value);

    if (value === 'custom') {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    if (!value) {
      setDateRangeValues({ from: null, to: null });

      getBargeCostingGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        selectedSupplier,
        selectedBarge,
        null,
        null,
      );
      return;
    }

    const now = moment();
    let fromDate: moment.Moment;
    let toDate: moment.Moment;

    switch (value) {
      case 'this_month':
        fromDate = now.clone().startOf('month');
        toDate = now.clone().endOf('month');
        break;
      case 'previous_month':
        fromDate = now.clone().subtract(1, 'month').startOf('month');
        toDate = now.clone().subtract(1, 'month').endOf('month');
        break;
      case 'this_quarter':
        fromDate = now.clone().startOf('quarter');
        toDate = now.clone().endOf('quarter');
        break;
      case 'previous_quarter':
        fromDate = now.clone().subtract(1, 'quarter').startOf('quarter');
        toDate = now.clone().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'this_year':
        fromDate = now.clone().startOf('year');
        toDate = now.clone().endOf('year');
        break;
      case 'previous_year':
        fromDate = now.clone().subtract(1, 'year').startOf('year');
        toDate = now.clone().subtract(1, 'year').endOf('year');
        break;
      default:
        return;
    }

    setDateRangeValues({ from: fromDate, to: toDate });

    getBargeCostingGridData(
      1,
      pagination.pageSize,
      searchedText,
      selectedBusinessNo,
      selectedSupplier,
      selectedBarge,
      fromDate,
      toDate,
    );
  };

  const handleDateRangeValuesChange = (
    dates: [any | null, any | null] | null,
  ) => {
    if (!dates || !dates[0] || !dates[1]) {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    const [from, to] = dates;
    setDateRangeValues({ from, to });

    getBargeCostingGridData(
      1,
      pagination.pageSize,
      searchedText,
      selectedBusinessNo,
      selectedSupplier,
      selectedBarge,
      from,
      to,
    );
  };

  return (
    <>
      <Card className="card-radius">
        <Tabs
          items={[
            ...(isPermissionExist([396])
              ? [
                  {
                    key: 'costing-creation',
                    label: 'Costing Creation',
                    children: (
                      <>
                        <Row justify="space-between" className="mb-12 mt-8" gutter={[0,8]}>
                          <Col xs={24} md={20}>
                            <Flex gap={10} wrap="wrap">
                              <Input.Search
                                className="input-search"
                                style={{ width: 225 }}
                                placeholder="Search"
                                allowClear
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setSearchedText(value);
                                  debouncedSearch(
                                    value,
                                    1,
                                    pagination.pageSize,
                                  );
                                }}
                                onSearch={(value) => {
                                  setSearchedText(value);
                                  // Immediate search on enter key or search button click
                                  getBargeCostingGridData(
                                    pagination.current,
                                    pagination.pageSize,
                                    value,
                                    selectedBusinessNo,
                                    selectedSupplier,
                                    selectedBarge,
                                    dateRangeValues.from,
                                    dateRangeValues.to,
                                  );
                                }}
                              />
                              <Select
                                style={{ width: 175 }}
                                placeholder="Barge Supplier"
                                value={selectedSupplier}
                                onChange={handleSupplierChange}
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toString()
                                    .toLocaleLowerCase()
                                    .includes(input.toLocaleLowerCase())
                                }
                                showSearch
                                allowClear
                              >
                                {supplier?.map((item, index) => (
                                  <Select.Option
                                    value={item.bpId}
                                    key={item.bpId + '-' + index}
                                  >
                                    {item.bpName}
                                  </Select.Option>
                                ))}
                              </Select>
                              <Select
                                style={{ width: 175 }}
                                placeholder="Business"
                                value={selectedBusinessNo}
                                onChange={handleBusinessNoChange}
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toString()
                                    .toLocaleLowerCase()
                                    .includes(input.toLocaleLowerCase())
                                }
                                allowClear
                                showSearch
                              >
                                {businessDropDown.map((bn, index) => {
                                  return (
                                    <Select.Option
                                      value={bn.bnId}
                                      key={bn.bnId + '-' + index}
                                    >
                                      {bn.businessName}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                              <Select
                                style={{ width: 175 }}
                                placeholder="Barge"
                                value={selectedBarge}
                                onChange={handleBargeChange}
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toString()
                                    .toLocaleLowerCase()
                                    .includes(input.toLocaleLowerCase())
                                }
                                allowClear
                                showSearch
                              >
                                {bargeDropDown?.map((rec, index) => {
                                  return (
                                    <Select.Option
                                      value={rec.id}
                                      key={rec.id + '-' + index}
                                    >
                                      {SequenceUtils.formatNumberToSpecificLength(
                                        rec.bargeId.toString(),
                                      ) +
                                        ' ' +
                                        `(${rec.bargeNomination})`}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                              <Select
                                placeholder="Date Range"
                                style={{ width: 175 }}
                                allowClear
                                value={dateRangeType}
                                onChange={handleDateRangeTypeChange}
                              >
                                {dateRangeDropDown.map((item, index) => (
                                  <Select.Option
                                    value={item.value}
                                    key={item.value + '-' + index}
                                  >
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                              {dateRangeType === 'custom' && (
                                <DatePicker.RangePicker
                                  style={{ maxWidth: 175 }}
                                  value={[
                                    dateRangeValues.from,
                                    dateRangeValues.to,
                                  ]}
                                  onChange={handleDateRangeValuesChange}
                                />
                              )}
                            </Flex>
                          </Col>
                          <Col>
                            {isPermissionExist([395]) && (
                              <Button
                                className="new-button"
                                onClick={() =>
                                  navigate('/barge-costings/barge-creation')
                                }
                              >
                                New Barge
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Table
                          className="contracts-list"
                          rowKey={(record) => record.costingId}
                          columns={tableColumns}
                          dataSource={bargeCostingData}
                          scroll={{ x: 500 }}
                          pagination={{
                            ...pagination,
                            showTotal: (total) => (
                              <span className="page-total-items">
                                Total {total} items
                              </span>
                            ),
                            showSizeChanger: true,
                            pageSizeOptions: ['25', '50', '100'],
                            onChange: (page, pageSize) => {
                              getBargeCostingGridData(
                                page,
                                pageSize,
                                searchedText,
                                selectedBusinessNo,
                                selectedSupplier,
                                selectedBarge,
                                dateRangeValues.from,
                                dateRangeValues.to,
                              );
                            },
                            style: {
                              paddingTop: '12px',
                            },
                          }}
                          style={{ width: '100%' }}
                          rowClassName={(record, index) =>
                            record.approvalStatus ===
                            ContractStatusEnum.CANCELLED
                              ? 'bg-warning'
                              : ''
                          }
                        />
                      </>
                    ),
                  },
                ]
              : []),
            ...(isPermissionExist([400])
              ? [
                  {
                    key: 'business-approval',
                    label: 'Business Approval',
                    children: (
                      <BargeCostingApproval
                        type={ContractStatusEnum.BUSINESS_APPROVAL}
                        costingType={CostingWorkLogsEnum.BARGE_COSTING}
                        key="barge-approval"
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </Card>
      <AddInvoiceDrawer
        referenceFeatures={ReferenceFeatures.BARGE_CPC_COSTING}
        key={dummyRefresh}
        setDummyRefresh={setDummyRefresh}
        openDrawer={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        costingId={costingId}
        accessId={props?.accessId}
      />
    </>
  );
};

export default BargeCostingsList;
