import { ContractStatusEnum, ExpenseEntryApprovalDto, ExpenseEntryService, GetExpApprovalDataDto } from "@exportx/shared-models-and-services"
import { Button, Card, Col, Flex, Form, Input, List, Popconfirm, Row, Space, Table, TableColumnsType, Tooltip, Typography } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { AlertMessages, useAuthState } from "../../../../common"
import { PaymentNoticePageEnum } from "../../payment-notice"
import { DownCircleOutlined, RightCircleOutlined } from "@ant-design/icons"
import { ExpenseEntryCostingDetails, InvoiceDetails } from "../../costing-detail-view/"
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { internationalFormattedValue } from "@exportx/ui-utils"

interface IProps {
  type?: PaymentNoticeApprovalScreen;
  paymentType?: PaymentNoticePageEnum;
  level?: PaymentNoticeApprovalScreen;
}

export enum PaymentNoticeApprovalScreen {
  BUSINESS_APPROVAL = 'Business Approval',
  AUDIT_APPROVAL = 'Audit Approval',
}

const PaymentNoticeApproval = (props: IProps) => {
  const { type, paymentType } = props;
  const [approval, setApproval] = useState([]);
  const { authContext } = useAuthState();
  const [searchedText, setSearchedText] = useState('');
  const expenseEntryService = new ExpenseEntryService();
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [dummyRefresh, setDummyRefresh] = useState<boolean>(false);

  useEffect(() => {
    getApprovalData();
  }, [dummyRefresh]);
  const [formRef] = Form.useForm();

  const tableColumns: TableColumnsType<any> = [
    {
      title: 'Costing No',
      dataIndex: 'expenseEntryNo',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const aaa = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (aaa.size && aaa.has(true)) return true;
        else return false;
      },
      render: (value, record) => {
        return (
          <>
            <a
              href={`/#/${
                'expense-entry' + '?costingId=' + record.expenseEntryId
              }`}
              className="link"
            >
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'expenseEntryDate',
      sorter: (a, b) =>
        moment(a.expenseEntryDate).unix() - moment(b.expenseEntryDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (value) => moment(value).format('DD-MM-YYYY'),
    },
    {
      title: 'Aging',
      dataIndex: 'expenseEntryDate',
      sorter: (a, b) =>
        Math.floor(
          (new Date(moment(a.etd).format('YYYY-MM-DD')).getTime() -
            new Date().getTime()) /
            (1000 * 60 * 60 * 24),
        ) -
        Math.floor(
          (new Date(b.etd).getTime() - new Date().getTime()) /
            (1000 * 60 * 60 * 24),
        ),
      // sorter: (a, b) => a.aging.localeCompare(b.aging),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render: (text, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {Math.floor(
              (new Date().getTime() -
                new Date(moment(text).format('YYYY-MM-DD')).getTime()) /
                (1000 * 60 * 60 * 24),
            )}
          </div>
        );
      },
    },
    {
      title: 'Expense type',
      dataIndex: 'expenseType',
    },

    {
      title: 'Amount To Be Paid',
      dataIndex: 'total',
      align: 'right',
      render: (value, record) => {
        return <>
          <Typography.Text>{internationalFormattedValue(value, 3)}</Typography.Text>
          <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
        </>

      }
    },
  ];

  const getApprovalData = () => {
    const status =
      type === PaymentNoticeApprovalScreen.BUSINESS_APPROVAL
        ? ContractStatusEnum.PENDING_APPROVAL
        : ContractStatusEnum.PENDING_AUDIT_APPROVAL;
    const req = new GetExpApprovalDataDto(
      paymentType,
      authContext.defaultPlant,
      status,
    );
    expenseEntryService
      .getExpensesDataForApproval(req)
      .then((res) => {
        if (res.status) {
          setApproval(res.data);
        } else {
          setApproval([]);
        }
      })
      .catch((err) => {
        setApproval([]);
      });
  };
  const approveStatus =
    type === PaymentNoticeApprovalScreen.BUSINESS_APPROVAL
      ? ContractStatusEnum.ACTIVE
      : ContractStatusEnum.ACTIVE;
  const approveExpenses = (expenseId: string, status: ContractStatusEnum) => {
    formRef.validateFields().then((values) => {
      const req = new ExpenseEntryApprovalDto();
      req.expenseEntryId = expenseId;
      req.plantCode = authContext.defaultPlant;
      req.status = status;
      req.approvalLevel = type;
      req.userName = authContext.user.userName;
      req.remarks = values['remarks'];
      expenseEntryService
        .approveExpense(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setApproval([]);
            getApprovalData();
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log('err : ', err);
        });
    });
  };

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.expenseEntryId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };
  const renderItems = (record: any, index, indent, expanded) => {
    console.log('record', record);
    return (
      <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }}>
        <ExpenseEntryCostingDetails
          expenseEntryId={record.expenseEntryId}
          remainingAmount={0}
        >
          <Card
            bordered={false}
            className="card-shadow mb-2"
            styles={{ body: { padding: '0px 10px 15px' } }}
          >
            <Form
              form={formRef}
              // initialValues={initialValues}
              layout="vertical"
              autoComplete="off"
            >
              <Row className="p-2">
                <Form.Item
                  style={{ width: '100%' }}
                  name="remarks"
                  label="Remarks"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Work Log', // fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Notes" />
                </Form.Item>
              </Row>
              <Row>
                <Col span={16}>
                  <Row>
                    <Col span={3}>Worklog :</Col>
                    <Col span={20}>
                      {record?.workLog?.length > 0 && (
                        <List
                          bordered
                          dataSource={record?.workLog ? record?.workLog : []}
                          renderItem={(item, index) => (
                            <List.Item key={index} style={{ width: '100%' }}>
                              <div>
                                <span>{index + 1}</span>
                                <span>.</span>&nbsp;{item.workLog}
                              </div>
                            </List.Item>
                          )}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {record.costing_status ===
                ContractStatusEnum.PENDING_APPROVAL && (
                <Flex align="middle" justify="flex-end">
                  <Space size={10}>
                    <Button
                      size="small"
                      className="approve"
                      onClick={() =>
                        approveExpenses(record?.expenseEntryId, approveStatus)
                      }
                    >
                      {' '}
                      <FontAwesomeIcon icon={faCheckCircle} /> Approve
                    </Button>

                    <Popconfirm
                      onConfirm={(e) => {
                        approveExpenses(
                          record?.expenseEntryId,
                          ContractStatusEnum.CANCELLED,
                        );
                      }}
                      title={'Are you sure to reject'}
                    >
                      <Tooltip placement="top" title={'Reject'}>
                        <Button size="small" className="reject">
                          {' '}
                          <FontAwesomeIcon icon={faCircleXmark} />
                          Reject
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                </Flex>
              )}
            </Form>
          </Card>
          <InvoiceDetails
            costingId={record.expenseEntryId}
            status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
            level={ContractStatusEnum.BUSINESS_APPROVAL}
            invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
            invApproveDisabled={
              record.costing_status === ContractStatusEnum.PENDING_APPROVAL
            }
            setDummyRefresh={setDummyRefresh}
          />
        </ExpenseEntryCostingDetails>
      </div>
    );
  };

  return (
    <>
      <Row className="card-title-buttons mb-3">
        <Input.Search
          placeholder="Search Orders"
          allowClear
          value={searchedText}
          onChange={(e) => setSearchedText(e.target.value)}
          className="input-search"
        />
      </Row>

      <Table
        // size="small"
        rowKey={(rowData) => rowData.expenseEntryId}
        columns={tableColumns}
        dataSource={approval}
        bordered={false}
        className="contracts-list"
        expandable={{
          expandedRowRender: renderItems,
          expandedRowKeys: expandedIndex,
          onExpand: setIndex,
          fixed: 'right',
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <DownCircleOutlined onClick={(e) => onExpand(record, e)}>
              Collapse
            </DownCircleOutlined>
          ) : (
            <RightCircleOutlined onClick={(e) => onExpand(record, e)}>
              Expand
            </RightCircleOutlined>
          )
        }
      ></Table>
    </>
  );
};

export default PaymentNoticeApproval;
