import { Card, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import BusinessPartnerGrid from './business-patner-grid';
import { BusinessPartnerTypeEnum } from '@exportx/shared-models-and-services';
import { isPermissionExist } from '../../../../../common';
import { useLocation } from 'react-router-dom';

const BusinessPartner = () => {
  const { state }: any = useLocation();
  const activeTab = state?.activeTab || 'customer';

  return (
    <Card className="card-radius">
      <Tabs defaultActiveKey={activeTab}>
        {isPermissionExist([75]) && (
          <TabPane tab="Customer" key="customer">
            <BusinessPartnerGrid
              bpCategory={BusinessPartnerTypeEnum.CUSTOMER}
              key={BusinessPartnerTypeEnum.CUSTOMER}
            />
          </TabPane>
        )}
        {isPermissionExist([82]) && (
          <TabPane tab="Vendor" key="vendor">
            <BusinessPartnerGrid
              bpCategory={BusinessPartnerTypeEnum.VENDOR}
              key={BusinessPartnerTypeEnum.VENDOR}
            />
          </TabPane>
        )}
        {isPermissionExist([89]) && (
          <TabPane tab="Facilitator" key="facilitator">
            <BusinessPartnerGrid
              bpCategory={BusinessPartnerTypeEnum.FACILITATOR}
              key={BusinessPartnerTypeEnum.FACILITATOR}
            />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};

export default BusinessPartner;
