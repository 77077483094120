import {
  BpCategoryDeactivateDto,
  BpCategoryDto,
  BpCategoryGetDto,
  BusinessPartnerCategoryService,
  BusinessPartnerTypeEnum,
} from '@exportx/shared-models-and-services';
import { Card, Button, Row, Col, Input } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  FilterableGrid,
  isPermissionExist,
  useAuthState,
} from '../../../../../common';
interface BpCategory {
  bpCategory: BusinessPartnerTypeEnum;
}

export const BpCategoryGrid = (props: BpCategory) => {
  const { formatMessage: fm } = useIntl();
  //to access global authorized user data(Login user Data which is passed as created user or updated user)
  const { authContext } = useAuthState(); //Common for all Page Components
  const navigate = useNavigate();
  const baseIntlPath =
    props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER
      ? `mdm.components.customerTypeGrid`
      : props.bpCategory === BusinessPartnerTypeEnum.VENDOR
      ? `mdm.components.vendorTypeGrid`
      : `mdm.components.facilitatorTypeGrid`;
  const columOneTile = fm({
    id: `${baseIntlPath}.bpInternalCategory`,
    defaultMessage: `${
      props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER
        ? 'Customer Type'
        : props.bpCategory === BusinessPartnerTypeEnum.VENDOR
        ? ' Vendor Type'
        : 'Facilitator Type'
    }`,
  });
  const bpInternalCategoryCode = fm({
    id: `${baseIntlPath}.bpInputCategoryCode`,
    defaultMessage: `${
      props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER
        ? 'Customer Type Code'
        : props.bpCategory === BusinessPartnerTypeEnum.VENDOR
        ? 'Vendor Type Code'
        : 'Facilitator Type Code'
    }`,
  });
  //to store Grid data
  const [gridData, setGridData] = useState<BpCategoryDto[]>([]); //This is common for all Page Components But Variable Name May Vary
  const [permissionExist, setPermissionExist] = useState<boolean>(false);
  const [searchedText, setSearchedText] = useState('');

  useEffect(() => {
    let hasPermission = false;
    if (props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER) {
      console.log('if customer');
      hasPermission = isPermissionExist([43]);
      setPermissionExist(isPermissionExist([42]));
    } else if (props.bpCategory === BusinessPartnerTypeEnum.VENDOR) {
      console.log('if vendor');
      hasPermission = isPermissionExist([39]);
      setPermissionExist(isPermissionExist([38]));
    } else {
      console.log('if facilitator');
      hasPermission = isPermissionExist([47]);
      setPermissionExist(isPermissionExist([46]));
    }

    if (!hasPermission) {
      return navigate('/');
    }
  }, [props.bpCategory]);

  useEffect(() => {
    getGridData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: columOneTile,
      dataIndex: 'bpCategory',
      key: 'bpCategory',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
    },
    {
      title: bpInternalCategoryCode,
      dataIndex: 'bpCategoryCode',
      key: 'bpCategoryCode',
    },
  ];

  //To Access Backend Services Via Shared Services
  const service = new BusinessPartnerCategoryService();

  const getGridData = () => {
    service
      .getAllBpCategories(new BpCategoryGetDto(props.bpCategory))
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
        } else {
          // AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const activateOrDeactivate = (bpCategoryDto: BpCategoryDto) => {
    const bpDeactivateDto: BpCategoryDeactivateDto =
      new BpCategoryDeactivateDto(
        bpCategoryDto.bpCategoryId,
        authContext.user.userName,
        bpCategoryDto.versionFlag,
      );
    service
      .activateOrDeactivate(bpDeactivateDto)
      .then((res) => {
        if (res.status) {
          getGridData();
          let activeOrDecative =
            res.errorCode === 4 ? ' deactivated' : ' activated';
          AlertMessages.getSuccessMessage(
            `${props.bpCategory} ${activeOrDecative}  SuccessFully`,
          );
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const view = (bpCategoryDto: BpCategoryDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (bpCategoryDto) {
      isNewRecord = false;
    }
    let pathToreDirect = '/createCustomerType';
    if (BusinessPartnerTypeEnum.CUSTOMER === props.bpCategory) {
      pathToreDirect = '/createCustomerType';
    } else if (BusinessPartnerTypeEnum.VENDOR === props.bpCategory) {
      pathToreDirect = '/createVendorType';
    } else {
      pathToreDirect = '/createFacilitatorType';
    }
    navigate(pathToreDirect, {
      state: { bpCategoryDto, isNewRecord, isUpdate },
    });
  };

  return (
    <>
      {/* <Card
            title={fm({ id: `${baseIntlPath}.header`, defaultMessage: `${props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'Customer' : props.bpCategory === BusinessPartnerTypeEnum.VENDOR ? 'Vendor' : 'Facilitator'} Types` })}
            style={{ overflow: 'auto' }}
            className='default-card-class'
            extra={<>{ permissionExist && (
                <Button
                    onClick={() => view(undefined, false)}
                >
                    {fm({ id: 'common.create', defaultMessage: 'Create' })}
                </Button>)}</>
            }
            bodyStyle={{ overflow: 'auto' }}
        > */}
      <Row justify="space-between" className="mb-12 mt-8">
        <Col>
          <Input.Search
            placeholder={`${
              props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER
                ? 'Search Customer'
                : props.bpCategory === BusinessPartnerTypeEnum.VENDOR
                ? 'Search Vendor'
                : 'Search Facilitator'
            }`}
            allowClear
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
            onSearch={(value) => {
              setSearchedText(value);
            }}
            className="input-search"
          />
        </Col>
        <Col>
          <>
            {permissionExist && (
              <Button
                className="new-button"
                onClick={() => view(undefined, false)}
              >
                {fm({ id: 'common.create', defaultMessage: 'Create' })}
              </Button>
            )}
          </>
        </Col>
      </Row>
      <FilterableGrid
        rowKey="bpCategoryId"
        mainColumns={columns}
        data={gridData}
        bpCategory={props.bpCategory}
        filterColumnsData={[]}
        activateOrDeactivate={activateOrDeactivate}
        view={view}
      />
      {/* </Card> */}
    </>
  );
};

export default BpCategoryGrid;
