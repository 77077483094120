import { ColaIndexTypeEnum } from '@exportx/shared-models-and-services';
import { Card, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import CoalIndexPricesForm from '../coal-index-prices-form';
import CoalIndexPricesGrid from '../coal-index-prices-grid';
import { isPermissionExist } from '../../../../../common';
import { useNavigate } from 'react-router-dom';
import TabPane from 'antd/es/tabs/TabPane';
import IndexedPriceCalculationForm from '../coal-index-calculation';
interface ICoalIndexPageProps {
  coalIndexType: ColaIndexTypeEnum;
}
export const CoalIndexPricesPage = (props: ICoalIndexPageProps) => {
  const [refreshKey, setRefreshKey] = useState(1);
  const [hasPermission, setPermission] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();
  const [weeklyData, setWeeklyData] = useState<any>();
  const navigate = useNavigate();
  const { coalIndexType } = props;
  const saveCallBack = () => {
    setRefreshKey((key) => key + 1);
  };

  useEffect(() => {
    let permission = false;

    if (coalIndexType === ColaIndexTypeEnum.MONTHLY) {
      permission = isPermissionExist([134]);
    } else {
      permission = isPermissionExist([126]);
    }

    setPermission(permission);

    if (!isPermissionExist([126,134])) {
      return navigate('/');
    }
  }, [coalIndexType, navigate]);

  return (
    <Card className="card-radius">
      <Tabs>
        {isPermissionExist([126]) && (
          <TabPane tab="Weekly" key="1">
            {coalIndexType === ColaIndexTypeEnum.WEEKLY &&
            isPermissionExist([125]) ? (
              <CoalIndexPricesForm
                coalIndexType={coalIndexType}
                saveCallBack={saveCallBack}
                initialValues={initialValues}
                weeklyData={weeklyData}
                setInitialValues={setInitialValues}
              />
            ) : (
              <></>
            )}
            {hasPermission && (
              <CoalIndexPricesGrid
                coalIndexType={coalIndexType}
                key={refreshKey}
                setInitialValues={setInitialValues}
                setWeeklyData={setWeeklyData}
              />
            )}
          </TabPane>
        )}
        {isPermissionExist([134]) && (
          <TabPane tab="Price Calculation" key="2">
            <IndexedPriceCalculationForm
              initialValues={undefined}
              coalIndexType={ColaIndexTypeEnum.WEEKLY}
              cancelHandler={() => {}}
              indexFormSubmitButtonHandler={(values) => {}}
            />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};

export default CoalIndexPricesPage;
