import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Card, Form, Input, Select, Tooltip } from 'antd';
import { CurrencyService, BusinessNumberService, PriceTypeEnum, BudgetAndCostingService, BaCostingIdReqDto, PlantCodeReqDto } from '@exportx/shared-models-and-services';
import { useLocation, useNavigate } from 'react-router-dom';
import './budget-and-costing-form.css';
import { SalesGridForm } from './sales-grid-form';
import { PurchaseGridForm } from './purchase-grid-form';
import { MiscExpensesDataGridFrom } from './misc-expenses-data-grid-form';
import { ServiceFeesGridForm } from './service-fee-form';
import { ProcurementFeesGridForm } from './procurement-fee-form';
import { BargeCostDataForm } from './barge-cost-data-form';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../../common';
import moment from 'moment';
import DatePicker from '../../../../common/data-picker/date-picker';



const { RangePicker } = DatePicker;
const { Option } = Select;
export const BudgetAndCostingForm = () => {
  const [form] = Form.useForm();
  const [currency, setCurrency] = useState<any[]>([]);
  const [businessNum, setBusinessNum] = useState<any[]>([]);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [initialValues, setInitialValues] = useState<any>()
  const { authContext } = useAuthState()
  const navigate = useNavigate();
  const { state }: any = useLocation()
  const { id } = state ? state : { id: null }

  const currencyService = new CurrencyService();
  const businessNumberService = new BusinessNumberService();
  const bacService = new BudgetAndCostingService()

  useEffect(() => {
    let permission = false;
    if(state) {
       permission = isPermissionExist([768])
    } else {
      permission = isPermissionExist([766])
    }
    if(!permission) navigate('/')
  }, [state])

  useEffect(() => {
    getCurrencyData();
    getBusinessNoDropData();
    getBacUpdatedData()
  }, [])


  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({ coalSalesData: initialValues.coalSalesData });
      form.setFieldsValue({ miscExpensesData: initialValues.miscExpensesData });
      form.setFieldsValue({ csfManualData: initialValues.csfManualData });
      form.setFieldsValue({ cpfManualData: initialValues.cpfManualData });
      form.setFieldsValue({ bargeCostData: initialValues.bargeCostData });
      form.setFieldsValue({ coalPurchaseData: initialValues.coalPurchaseData });
      form.setFieldValue('businessNoId', initialValues?.businessNoId);
      form.setFieldValue('vesselName', initialValues?.vesselName);
      form.setFieldValue('budgetCurrency', initialValues?.budgetCurrency);
      form.setFieldValue('exchangeRate', initialValues?.exchangeRate);
      const laycanDate = initialValues?.laycan.split(',')
      form.setFieldsValue({ laycan: [moment(laycanDate[0]), moment(laycanDate[1])] })
    }
  }, [initialValues])



  const budgetAndCoasting = () => {
    form.validateFields().then(values => {
      bacService.createBAC({ ...values, plantCode: authContext.defaultPlant, userName: authContext.user.userName, budgetAndCostingId: initialValues?.budgetAndCostingId }).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setTimeout(() => {
            navigate('/budget-costing')
          }, 1000)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch(err => {
        console.log(err.message)
      })
    })
  }

  const getCurrencyData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrency(res.data);
      }
    }).catch(err => {
      console.log('error:' + err.message);
    });
  }

  const getBusinessNoDropData = () => {
    businessNumberService.getAllBusinessNumber(new PlantCodeReqDto(authContext.defaultPlant)).then(res => {
      if (res.status) {
        setBusinessNum(res.data)
      }
    }).catch(err => {
      console.log('error:' + err.message);
    })
  }

  const getBacUpdatedData = () => {
    const req = new BaCostingIdReqDto(id, authContext.defaultPlant)
    bacService.getBacUpdatedData(req).then(res => {
      if (res.status) {
        setInitialValues(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }


  return (
    <>
      <Card title={<span style={{ color: 'black' }}> New Budget And Costing</span>}
        style={{ textAlign: 'left' }} extra={<>{isPermissionExist([767]) && (<span style={{ color: 'white' }} ><Button type="primary" onClick={() => navigate("/budget-costing")}>View</Button> </span>)}</>}>

        <Card title='Add Details To Create Budget & Costing' style={{ paddingBlockEnd: '10px' }}>

          <Form initialValues={initialValues} form={form}>
            <div className="row mb-2">
              <div className="col-lg-3">
                <Form.Item label="Laycan" rules={[{ required: true }]}
                  name="laycan"><RangePicker />
                </Form.Item>
              </div>
              <div className="col-lg-3">
                <Form.Item label="Business No" rules={[{ required: true, }]}
                  name={"businessNoId"} style={{ width: '80%' }}>
                  <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear placeholder='Select Business No'>
                    {businessNum.map(b => {
                      return <Option value={b.bnId}>{b.motherVesselName + `(${b.businessNo})`}</Option>
                    })}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-lg-3">
                <Form.Item label="Vessel Name" rules={[{ required: true, }]}
                  name="vesselName"><Input placeholder="Enter Vessel Name" type="text" />
                </Form.Item>
              </div>
              <div className="col-lg-3">
                <Form.Item label="Currency" rules={[{ required: true, }]} name="budgetCurrency" style={{ width: "80%" }}>
                  <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear placeholder='Select Currency' onChange={(value) => setSelectedCurrency(value)}>
                    {currency.map(c => {
                      return <Option value={c.currencyCode}>{c.currencyName}</Option>
                    })}
                  </Select>
                </Form.Item>

              </div>

              <div className="col-lg-3">
                <Form.Item label="Exchange Rate" rules={[{ required: true }]}
                  name="exchangeRate"><Input placeholder="Exchange Rate*" type='number' onChange={(e) => {
                    setExchangeRate(Number(e.target.value))
                  }} />
                </Form.Item>
              </div>
            </div>

          </Form>


          <Form
            initialValues={{
              coalSalesData: [''],
              coalPurchaseData: [''],
              miscExpensesData: [{ priceType: PriceTypeEnum.PER_MT }],
              csfManualData: [{ priceType: PriceTypeEnum.PER_MT }],
              cpfManualData: [{ priceType: PriceTypeEnum.PER_MT }],
              bargeCostData: [''],

            }}
            form={form}
          >
            <div className="section_block w-100" >



              <div className="table-responsive">
                <table className='table table-sm table-bordered'>
                  <thead className='table-header'>
                    <tr className="bg-primary text-white">
                      <th className='text-center' style={{ minWidth: 300 }} >Supplier / Buyer <span style={{ color: "red" }}>*</span></th>
                      <th className='text-center' style={{ minWidth: 300 }} >Contract</th>
                      <th className='text-center' style={{ minWidth: 150 }} >Quality <span style={{ color: "red" }}>*</span></th>
                      <th className='text-center' style={{ minWidth: 200 }} >Sales / Purchase Type</th>
                      <th className='text-center' style={{ minWidth: 150 }} >Price Type</th>
                      <th style={{ minWidth: 150 }} className='text-right' >Quantity <span style={{ color: "red" }}>*</span></th>
                      <th style={{ minWidth: 150 }} className='text-right' >Price <span style={{ color: "red" }}>*</span></th>
                      <th className='text-center' style={{ minWidth: 150 }} >Currency <span style={{ color: "red" }}>*</span></th>
                      <th style={{ minWidth: 150 }} className='text-right' >Total </th>
                      <th style={{ minWidth: 100 }} className='text-center'>Action</th>
                    </tr>

                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan={10}>Coal Sales</th>
                    </tr>
                    <Form.List name="coalSalesData">
                      {(coalSalesData, { add, remove }) => (
                        <>
                          <SalesGridForm coalSalesData={coalSalesData} form={form} currency={currency} add={add} remove={remove} exchangeRate={exchangeRate} selectedCurrency={selectedCurrency} />
                        </>
                      )}
                    </Form.List>
                    <tr>
                      <th colSpan={10}>Coal Purchase</th>
                    </tr>
                    <Form.List name="coalPurchaseData">
                      {(coalPurchaseData, { add, remove }) => (
                        <>
                          <PurchaseGridForm coalPurchaseData={coalPurchaseData} form={form} currency={currency} add={add} remove={remove} exchangeRate={exchangeRate} selectedCurrency={selectedCurrency} />
                        </>
                      )}
                    </Form.List>

                    <tr>
                      <th colSpan={10}>Service Fee</th>
                    </tr>
                    <Form.List name="csfManualData">
                      {(csfManualData, { add, remove }) => (
                        <>
                          <ServiceFeesGridForm csfManualData={csfManualData} form={form} currency={currency} add={add} remove={remove} exchangeRate={exchangeRate} selectedCurrency={selectedCurrency} />
                        </>
                      )}
                    </Form.List>

                    <tr>
                      <th colSpan={10}>Procurement Fee</th>
                    </tr>
                    <Form.List name="cpfManualData">
                      {(cpfManualData, { add, remove }) => (
                        <>
                          <ProcurementFeesGridForm cpfManualData={cpfManualData} form={form} currency={currency} add={add} remove={remove} exchangeRate={exchangeRate} selectedCurrency={selectedCurrency} />
                        </>
                      )}
                    </Form.List>

                    <tr>
                      <th colSpan={10}>Barge Cost</th>
                    </tr>
                    <Form.List name="bargeCostData">
                      {(bargeCostData, { add, remove }) => (
                        <>
                          <BargeCostDataForm bargeCostData={bargeCostData} form={form} currency={currency} add={add} remove={remove} exchangeRate={exchangeRate} selectedCurrency={selectedCurrency} />
                        </>
                      )}
                    </Form.List>



                    <tr>
                      <th colSpan={10}>Misc Expenses</th>
                    </tr>
                    <Form.List name="miscExpensesData">
                      {(miscExpensesData, { add, remove }) => (
                        <>
                          <MiscExpensesDataGridFrom miscExpensesData={miscExpensesData} form={form} currency={currency} add={add} remove={remove} exchangeRate={exchangeRate} selectedCurrency={selectedCurrency} />
                        </>
                      )}
                    </Form.List>
                  </tbody>
                </table>
              </div>
              <div className='row'>
                <table className='table table-sm table-bordered'>
                  <thead className='table-header'>
                    <tr>
                      <th className='text-right'>Total Cost of Purchase</th>
                      <th className='text-right'>Total Cost of Purchase / MT</th>
                      <th className='text-right'>Total Cost of Sales</th>
                      <th className='text-right'>Total Cost of Sales / MT</th>
                      <th className='text-right'>Realization / MT</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className='text-right' style={{ fontWeight: 'bold' }}>0</td>
                      <td className='text-right' style={{ fontWeight: 'bold' }}>0</td>
                      <td className='text-right' style={{ fontWeight: 'bold' }}>0</td>
                      <td className='text-right' style={{ fontWeight: 'bold' }}>0</td>
                      <td className='text-right' style={{ fontWeight: 'bold' }}>0</td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </Form>
        </Card>
        <div style={{ paddingTop: '10px' }}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { budgetAndCoasting() }}> Submit</Button>
        </div>
      </Card>
    </>
  );
}

export default BudgetAndCostingForm;