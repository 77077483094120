import { Card, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { JournalG } from './journal-voucher';
import { PaymentG } from './payment-voucher';
import { AccountsVoucherTypesEnum } from '@exportx/shared-models-and-services';
import { isPermissionExist } from '../../common';

export const Vouchers = () => {
  return (
    <Card className="card-radius">
      <Tabs>
        {isPermissionExist([797]) && (
          <TabPane tab="Payment" key="1">
            <PaymentG
              paymentTypeCategory={AccountsVoucherTypesEnum.PAYMENT_VOUCHER}
              key={'payment-voucher'}
            />
          </TabPane>
        )}
        {isPermissionExist([803]) && (
          <TabPane tab="Receipt" key="2">
            <PaymentG
              paymentTypeCategory={AccountsVoucherTypesEnum.RECEIPT_VOUCHER}
              key={'receipt-voucher'}
            />
          </TabPane>
        )}
        {isPermissionExist([809]) && (
          <TabPane tab="Journal" key="3">
            <JournalG key={'journalVoucher'} />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};

export default Vouchers;
