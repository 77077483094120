import {
  ContractModesEnum,
  SamplingTypes,
} from '@exportx/shared-models-and-services';
import { Button, Card } from 'antd';
import ActualReport from '../../purchasequality/reports/actual-report';
import { useNavigate } from 'react-router-dom';
import { isPermissionExist } from '../../../../common';

interface ISupplierProps {
  contractType: ContractModesEnum;
  sampleType: SamplingTypes;
}

const SupplierReports = (props: ISupplierProps) => {
  const navigate = useNavigate();
  return (
    <Card className='card-radius'
      title={`${
        props.contractType === ContractModesEnum.SALES
          ? 'S&A - Sales'
          : 'P&A - Purchase'
      }`}
      extra={
        isPermissionExist([882]) && (
          <Button
            className="new-button"
            onClick={() => navigate('/sales-sampling/actual')}
          >
            Create
          </Button>
        )
      }
      headStyle={{marginTop: '8px'}}
    >
      <ActualReport
        contractType={ContractModesEnum.SALES}
        key={ContractModesEnum.SALES + 'S&A - Sales'}
        sampleType={props.sampleType}
      />
    </Card>
  );
};

export default SupplierReports;
