import { DownCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import {
  BGCostingParentReqDto,
  ContractStatusEnum,
  CostingWorkLogsEnum,
  PaymentNoticeGetDataDto,
  PaymentNoticeService,
  PaymentTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
import { Card, Table, Typography } from 'antd';
import { isPermissionExist, useAuthState } from '../../../common';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useCallback, useEffect, useState, useMemo } from 'react';
import PaymentNoticeChild from './payment-notice-child';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { AccountPayableCostings } from '../costing-detail-view';
import { CostingFilters } from '../costing-detail-view/costing-filters';

interface IPaymentNoticePage {
  type: PaymentNoticePageEnum;
  paymentType?: PaymentNoticePageEnum;
}

export enum PaymentNoticePageEnum {
  APPROVAL = 'approval',
  CREATE = 'create',
  RELEASE = 'Release',
  RECEIVABLES = 'Receivables',
  CLOSED = 'Closed',
}

export const PaymentNoticePage = (props: IPaymentNoticePage) => {
  const { paymentType } = props;
  const [expandedIndex, setExpandedIndex] = useState([]);
  const paymentNoticeService = new PaymentNoticeService();
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const [paymentNoticeData, setPaymentNoticeData] = useState<
    PaymentNoticeGetDataDto[]
  >([]);
  // const [paymentNoticeData, setPaymentNoticeData] = useState<any>();
  const [searchedText, setSearchedText] = useState('');
  const [type, setActiveTab] = useState<PaymentNoticePageEnum>(
    PaymentNoticePageEnum.CREATE,
  );
  const [serachInput, setSerachInput] = useState('');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [businessNo, setBusinessNo] = useState(null);
  const [costingType, setCostingType] = useState<CostingWorkLogsEnum>(null);
  const [bargeId, setBarge] = useState<string>(null);

  useEffect(() => {
    if (
      type === PaymentNoticePageEnum.CREATE ||
      type === PaymentNoticePageEnum.CLOSED
    ) {
      if (
        !isPermissionExist([
          287, 329, 742, 189, 229, 249, 433, 454, 475, 496, 517, 538, 559, 580,
          601, 622, 642, 662, 682, 702, 308, 371, 412, 722,
        ])
      ) {
        return navigate('/');
      }
    } else if (type === PaymentNoticePageEnum.APPROVAL) {
      if (
        !isPermissionExist([
          288, 330, 743, 190, 230, 250, 434, 455, 476, 497, 518, 539, 560, 581,
          602, 623, 643, 663, 683, 703, 309, 372, 413, 723,
        ])
      ) {
        return navigate('/');
      }
    } else {
      if (
        !isPermissionExist([
          290, 332, 745, 192, 232, 252, 436, 457, 478, 499, 520, 541, 562, 583,
          604, 625, 645, 665, 685, 705, 311, 374, 415, 725,
        ])
      ) {
        return navigate('/');
      }
    }
  }, [type]);

  let status = ContractStatusEnum.ACTIVE;
  if (type === PaymentNoticePageEnum.CREATE) {
    status = ContractStatusEnum.DRAFT;
  } else if (type === PaymentNoticePageEnum.APPROVAL) {
    status = ContractStatusEnum.PENDING_APPROVAL;
  } else if (type === PaymentNoticePageEnum.CLOSED) {
    status = ContractStatusEnum.CLOSED;
  }

  const getParentData = () => {
    const req = new BGCostingParentReqDto();
    req.plantCode = authContext.defaultPlant;
    req.offset = (currentPage - 1) * pageSize;
    req.limit = pageSize;
    req.searchTerm = searchedText;
    req.businessNo = businessNo;
    req.bargeId = bargeId;
    req.costingType = costingType;
    req.status = status;
    if (paymentType) {
      req.paymentType = paymentType;
    }
    paymentNoticeService
      .getPaymentNotice(req)
      .then((res) => {
        if (res.status) {
          const filteredData = res.data?.paymentNotices?.filter((item) => {
            return hasPermission(item);
          });
          setPaymentNoticeData(filteredData ? filteredData : []);
          // setPaymentNoticeData(res.data ? res.data : []);
          setTotal(res.data?.total);
          setExpandedIndex([]);
        } else {
          setPaymentNoticeData([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setPaymentNoticeData([]);
        setTotal(0);
      });
  };

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((searchValue: string) => {
        setSearchedText(searchValue);
        setCurrentPage(1);
      }, 500),
    [],
  );

  useEffect(() => {
    getParentData();
  }, [
    searchedText,
    type,
    paymentType,
    currentPage,
    pageSize,
    businessNo,
    bargeId,
    costingType,
  ]);

  const hasPermission = (data) => {
    let permission = false;
    if (
      type === PaymentNoticePageEnum.CREATE ||
      type === PaymentNoticePageEnum.CLOSED
    ) {
      if (data.expenseType === 'Performa PC Costing') {
        permission = isPermissionExist([287, 329]);
      } else if (data.expenseType === 'Demurrage') {
        permission = isPermissionExist([742]);
      } else if (data.expenseType === 'Advance Invoice') {
        permission = isPermissionExist([189, 229, 249]);
      } else if (data.expenseType === 'Expense Entry') {
        permission = isPermissionExist([
          433, 454, 475, 496, 517, 538, 559, 580, 601, 622, 642, 662, 682, 702,
        ]);
      } else if (data.expenseType === 'Commercial PC Costing') {
        permission = isPermissionExist([308]);
      } else if (data.expenseType === 'Procurement Fees') {
        permission = isPermissionExist([371]);
      } else if (data.expenseType === 'BARGE COSTINGS') {
        permission = isPermissionExist([412]);
      } else if (data.expenseType === 'DEAD FREIGHT') {
        permission = isPermissionExist([722]);
      } else {
        permission = isPermissionExist([1]);
      }
    } else if (type === PaymentNoticePageEnum.APPROVAL) {
      if (data.expenseType === 'Performa PC Costing') {
        permission = isPermissionExist([288, 330]);
      } else if (data.expenseType === 'Demurrage') {
        permission = isPermissionExist([743]);
      } else if (data.expenseType === 'Advance Invoice') {
        permission = isPermissionExist([190, 230, 250]);
      } else if (data.expenseType === 'Expense Entry') {
        permission = isPermissionExist([
          434, 455, 476, 497, 518, 539, 560, 581, 602, 623, 643, 663, 683, 703,
        ]);
      } else if (data.expenseType === 'Commercial PC Costing') {
        permission = isPermissionExist([309]);
      } else if (data.expenseType === 'Procurement Fees') {
        permission = isPermissionExist([372]);
      } else if (data.expenseType === 'BARGE COSTINGS') {
        permission = isPermissionExist([413]);
      } else if (data.expenseType === 'DEAD FREIGHT') {
        permission = isPermissionExist([723]);
      } else {
        permission = isPermissionExist([1]);
      }
    } else {
      if (data.expenseType === 'Performa PC Costing') {
        permission = isPermissionExist([290, 332]);
      } else if (data.expenseType === 'Demurrage') {
        permission = isPermissionExist([745]);
      } else if (data.expenseType === 'Advance Invoice') {
        permission = isPermissionExist([192, 232, 252]);
      } else if (data.expenseType === 'Expense Entry') {
        permission = isPermissionExist([
          436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705,
        ]);
      } else if (data.expenseType === 'Commercial PC Costing') {
        permission = isPermissionExist([311]);
      } else if (data.expenseType === 'Procurement Fees') {
        permission = isPermissionExist([374]);
      } else if (data.expenseType === 'BARGE COSTINGS') {
        permission = isPermissionExist([415]);
      } else if (data.expenseType === 'DEAD FREIGHT') {
        permission = isPermissionExist([725]);
      } else {
        permission = isPermissionExist([1]);
      }
    }
    if (permission) {
      return data;
    }
  };

  const routes = (key) => {
    let object = {
      [CostingWorkLogsEnum.PERFORMA_PC_COSTING]: `performaCoalCosting`,
      [CostingWorkLogsEnum.PERFORMA_SC_COSTING]: `performaCostingSale`,
      [CostingWorkLogsEnum.COMMERCIAL_PC_COSTING]: `commercial-coal-costing`,
      [CostingWorkLogsEnum.COMMERCIAL_SC_COSTING]: `commercial-costing-sales`,
      [CostingWorkLogsEnum.PROCUREMENT_FEES]: `procurement-coal-costing`,
      [CostingWorkLogsEnum.MARKETING_FEES]: `marketing-coal-costing`,
      [CostingWorkLogsEnum.DEMURRAGE]: `demurrage`,
      [CostingWorkLogsEnum.DEAD_FREIGHT]: `dead-freight`,
      [CostingWorkLogsEnum.ADVANCE_INVOICE]: `advanceDetailView`,
      [CostingWorkLogsEnum.EXPENSE_ENTRY]: 'expense-entry',
      [CostingWorkLogsEnum.ADVANCE_RECEIVABLES]:
        'advance-receivables-detailed-view',
      [CostingWorkLogsEnum.BARGE_COSTING]: `barge-costings/barge`,
    };

    let id = '?costingId=';

    if (key === ReferenceFeatures.ADVANCE_INVOICE) {
      id = '?id=';
    } else if (key === ReferenceFeatures.ADVANCE_RECEIVABLES) {
      id = '?id=';
    }

    return object[key] + id;
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 25);
  };

  // Handle search input change
  const handleSearch = (value: string) => {
    setSerachInput(value);
    debouncedSearchHandler(value);
  };

  const handleBusinessNoChange = (value: string) => {
    setBusinessNo(value);
    setCurrentPage(1);
  };

  const handleCostingTypeChange = (value: CostingWorkLogsEnum) => {
    setCostingType(value);
    setCurrentPage(1);
  };

  const bargeChangeHandler = (value: string) => {
    setBarge(value);
    setCurrentPage(1);
  };

  const tableColumns: ColumnsType<any> = [
    {
      title: 'Expense Reference',
      dataIndex: 'costingNo',
      render: (value, record) => {
        return (
          <>
            <a
              href={`/#/${routes(record.expenseType) + record.costingId}`}
              className="link"
            >
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Expense Type',
      dataIndex: 'expenseType',
      // filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },

    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      render: (value, row: any, index) => {
        const bnValues = [
          ...new Map(
            row.paymentNoticeBusinessNos?.map((rec) => [rec.businessNo, rec]),
          ).values(),
        ];
        return (
          <>
            {new Set(
              row.paymentNoticeBusinessNos?.filter(
                (rec) => rec.businessNo !== null || rec.businessNo != undefined,
              ),
            ).size
              ? bnValues.map(
                (
                  rec: {
                    businessNo: string;
                    businessNoName: string;
                    bnId: string;
                  },
                  index,
                ) => {
                  const comma = index !== bnValues.length - 1 ? ' , ' : '';
                  const link = `/#/bn-detail-view?bn_Id=${rec.bnId}`;
                  return (
                    <>
                      {
                        <a key={rec.bnId} href={link} className="link">
                          {rec.businessNo +
                            ` (${rec.businessNoName})` +
                            comma}
                        </a>
                      }
                    </>
                  );
                },
              )
              : 'NA'}
          </>
        );
      },
    },
    {
      title: 'Barge',
      dataIndex: 'bargeNo',
      render: (value, row: any, index) => {
        const bg = [];
        row.paymentNoticeBarges?.forEach((rec) => {
          if (rec.bargeNo)
            bg.push({
              bargeNo: rec.bargeNo,
              bargeId: rec.bargeId,
              bargeNomination: rec.bargeNomination,
            });
        });
        return (
          <>
            {new Set(
              row.paymentNoticeBarges?.filter(
                (rec) => rec.bargeNo !== null || rec.bargeNo !== undefined,
              ),
            ).size
              ? bg.map((rec, index) => {
                const comma = index !== bg.length - 1 ? ' , ' : '';
                const link = `/#/barge-detail-view?barge_id=${rec?.bargeId}`;
                return (
                  <a href={`${link}`} className="link" key={rec?.bargeId}>
                    {SequenceUtils.formatNumberToSpecificLength(
                      rec?.bargeNo,
                    ) +
                      ` (${rec.bargeNomination})` +
                      comma}
                  </a>
                );
              })
              : 'NA'}
          </>
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
          </>
        );
      },
    },
  ];

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.costingId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };
  const renderItems = (
    record: PaymentNoticeGetDataDto,
    index,
    indent,
    expanded,
  ) => {
    const remainingAmount =
      Number(record?.totalAmount) - Number(record?.transactionalTotalAmount);

    return (
      <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }}>
        <AccountPayableCostings
          costingId={record.costingId}
          costingType={record.expenseType}
          remainingAmount={remainingAmount}
        >
          <PaymentNoticeChild
            paymentType={paymentType}
            record={record}
            type={type}
            getParentData={getParentData}
            status={status}
          />
        </AccountPayableCostings>
      </div>
    );
  };

  const tabListNoTitle = [
    {
      key: PaymentNoticePageEnum.CREATE,
      label: 'Creation',
    },
    {
      key: PaymentNoticePageEnum.APPROVAL,
      label: 'Approval',
    },
    {
      key: PaymentNoticePageEnum.RELEASE,
      label: 'Release',
    },
    {
      key: PaymentNoticePageEnum.CLOSED,
      label: 'Closed',
    },
  ];

  const onTab2Change = (key: PaymentNoticePageEnum) => {
    setActiveTab(key);
    setCurrentPage(1);
  };

  return (
    <Card className="card-radius">
      <Card
        className="no-shadow"
        tabList={tabListNoTitle}
        bordered={false}
        activeTabKey={type}
        size="small"
        onTabChange={onTab2Change}
        tabProps={{
          size: 'middle',
          tabBarStyle: { width: '100%' },
        }}
      >
        <CostingFilters
          handleSearch={handleSearch}
          serachInput={serachInput}
          handleBusinessNoChange={handleBusinessNoChange}
          businessNo={businessNo}
          handleCostingTypeChange={handleCostingTypeChange}
          costingType={costingType}
          bargeChangeHandler={bargeChangeHandler}
          bargeId={bargeId}
          paymentType={PaymentTypeEnum.PAYABLE}
        />
        <Table
          size="small"
          className="contracts-list"
          rowKey={(record) => record.costingId}
          columns={tableColumns}
          dataSource={paymentNoticeData}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right',
          }}
          rootClassName="table-row-white"
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <DownCircleOutlined
                twoToneColor="#0083C9"
                onClick={(e) => onExpand(record, e)}
              >
                Collapse
              </DownCircleOutlined>
            ) : (
              <RightCircleOutlined
                twoToneColor="#0083C9"
                onClick={(e) => onExpand(record, e)}
              >
                Expand
              </RightCircleOutlined>
            )
          }
          scroll={{ x: 500 }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            size: 'small',
          }}
          onChange={handleTableChange}
        />
      </Card>
    </Card>
  );
};

export default PaymentNoticePage;
