import {
  CommentsService,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { useAuthState } from '../iam-client-react';
import { useEffect, useState } from 'react';
import { Button, Col, Divider, Empty, Form, Input, Row, Space } from 'antd';
import {
  FileExcelFilled,
  FilePdfFilled,
  FileUnknownFilled,
  FileWordFilled,
  MessageFilled,
} from '@ant-design/icons';
import { AlertMessages } from '../notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FileUploadForm } from '../file-upload-form';
import { getFilesData } from '@exportx/ui-utils';
import { useIntl } from 'react-intl';
const { TextArea } = Input;

interface commentsPageProps {
  featuresRefNo: string;
  featuresRefName: string;
  commentsTitle?: string;
  setCommentsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;
}

export const CommentsPage = (props: commentsPageProps) => {
  const { authContext } = useAuthState();
  const {
    featuresRefNo,
    featuresRefName,
    commentsTitle,
    setCommentsVisible,
    setDummyRefresh,
  } = props;
  const [commentsData, setCommentsData] = useState<any>();
  const [newComment, setNewComment] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filesFormRef] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { formatMessage: fm } = useIntl();

  const commentsService = new CommentsService();

  useEffect(() => {
    getComments();
  }, [featuresRefNo, featuresRefName, refresh]);

  const createComments = () => {
    const commentData = [
      {
        featuresRefNo: featuresRefNo,
        featuresRefName: featuresRefName,
        comments: newComment,
        plantCode: authContext.defaultPlant,
        createdUser: authContext.user.userName,
        updatedUser: authContext.user.userName,
        commentType: ReferenceFeatures.COMMENTS_TEXT
      },
    ];
    commentsService
      .createComments(commentData)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setNewComment('');
          getComments();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getComments = () => {
    commentsService
      .getCommentDataById({
        featuresRefNo: featuresRefNo,
        featuresRefName: featuresRefName,
      })
      .then((res) => {
        if (res.status) {
          if (featuresRefName === '') {
            const allComments = res.data.reduce((acc, item) => {
              const commentsWithRef = item.comments.map((comment) => ({
                ...comment,
                featureRef: item.featuresRefName,
              }));
              return [...acc, ...commentsWithRef];
            }, []);

            const sortedComments = allComments.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime(),
            );

            setCommentsData([
              {
                featuresRefName: '',
                comments: sortedComments,
              },
            ]);
          } else {
            setCommentsData(res.data);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const filteredData =
    featuresRefName === ''
      ? (commentsData?.[0]?.comments || []).filter((comment) =>
          searchText
            ? comment.comment
                ?.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              comment.createdUser
                ?.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              (comment.featureRef?.split('-')[1] || comment.featureRef)
                ?.toLowerCase()
                .includes(searchText.toLowerCase())
            : true,
        )
      : commentsData?.find((item) => item.featuresRefName === featuresRefName)
          ?.comments || [];

  const getRelativeTime = (dateString: string | Date) => {
    const givenDate =
      typeof dateString === 'string' ? new Date(dateString) : dateString;

    if (!(givenDate instanceof Date) || isNaN(givenDate.getTime())) {
      throw new Error('Invalid date');
    }

    const now = new Date();
    const timeDifference = now.getTime() - givenDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} days ago`;
    if (hours > 0) return `${hours} hours ago`;
    if (minutes > 0) return `${minutes} minutes ago`;
    if (seconds > 0) return `${seconds} seconds ago`;

    return 'just now';
  };

  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then((fileValues) => {
      getFilesData(fileValues.filesData).then((filesData) => {
        commentsService
          .addFilesToComments({
            filesData: filesData,
            createdUser: authContext.user.userName,
            featuresRefId: featuresRefNo,
            plantCode: authContext.defaultPlant,
            featuresRefName: featuresRefName,
          })
          .then((res) => {
            if (res.status) {
              setOpenModal(false);
              setRefresh((prev) => !prev);
              AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          })
          .catch((err) => {
            AlertMessages.getErrorMessage(err.message);
          });
      });
    });
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    return `${(size / (1024 * 1024)).toFixed(1)} MB`;
  };

  return (
    <>
      <Row className="comments-header" align="middle" justify="space-between">
        <Space size="middle">
          <MessageFilled />
          <span className="comments-title">{commentsTitle}</span>
        </Space>
        <Button
          className="xmark-icon"
          icon={<FontAwesomeIcon icon={faXmark} />}
          onClick={() => setCommentsVisible(false)}
        ></Button>
      </Row>
      <div className="comments-container">
        <div className="comments-list">
          {featuresRefName === '' && (
            <Input.Search
              placeholder="Search"
              allowClear
              className="comments-search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
          {commentsData ? (
            filteredData.length > 0 ? (
              filteredData.map((rec, index) => (
                <div key={index}>
                  <Row className="comment-row">
                    <Space size="middle">
                      <span className="comment-avatar">
                        {rec.createdUser?.charAt(0).toUpperCase()}
                      </span>
                      <span className="comment-username">
                        {rec.createdUser}
                      </span>
                      <span className="comment-time">
                        {getRelativeTime(rec.createdAt)}
                      </span>
                    </Space>
                  </Row>

                  {featuresRefName === '' && rec.featureRef && (
                    <Row className="comment-feature">
                      {rec.featureRef.split('-')[1] || rec.featureRef}
                    </Row>
                  )}

                  {rec.comment === null && rec.fileUploadId ? (
                    <Row style={{ marginLeft: '34px' }}>
                      <div className="file-container">
                        {rec.type.startsWith('image/') ? (
                          <img
                            src={rec.filePath}
                            alt={rec.originalName}
                            className="file-display"
                          />
                        ) : (
                          <span className="file-icon">
                            {rec.type.startsWith('application/pdf') ? (
                              <FilePdfFilled />
                            ) : rec.type.startsWith('application/msword') ||
                              rec.type.startsWith(
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                              ) ? (
                              <FileWordFilled />
                            ) : rec.type.startsWith(
                                'application/vnd.ms-excel',
                              ) ||
                              rec.type.startsWith(
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                              ) ? (
                              <FileExcelFilled />
                            ) : (
                              <FileUnknownFilled />
                            )}
                          </span>
                        )}
                        <div className="file-details">
                          <a
                            href={rec.filePath}
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                          >
                            {rec.fileDescription}
                          </a>
                          <span className="file-size">
                            {rec.originalName.split('.').pop().toUpperCase()} -{' '}
                            {formatFileSize(rec.size)}
                          </span>
                        </div>
                      </div>
                    </Row>
                  ) : (
                    <Row className="comment-text">{rec.comment}</Row>
                  )}
                  <Divider />
                </div>
              ))
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="empty-tag"
                description="No comments have been added."
              />
            )
          ) : (
            ''
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginBottom: '10px',
          }}
        >
          <Button className="new-button" onClick={() => setOpenModal(true)}>
            Add Files
          </Button>
        </div>

        <div className="comment-input-container">
          <TextArea
            className="comment-input card-br-8"
            allowClear
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Type your comment"
            rows={3}
          />
          <Button
            size="middle"
            className="comment-button new-button"
            onClick={createComments}
            disabled={!newComment?.trim()}
          >
            Comment
          </Button>
        </div>
      </div>

      {openModal && (
        <FileUploadForm
          maxCount={3}
          layoutType="vertical"
          formRef={filesFormRef}
          initialValues={commentsData?.filesData ?? []}
          formType="View"
          fileModal={openModal}
          setFileModal={setOpenModal}
        >
          <Row justify="end">
            <Col>
              {
                <Space>
                  <Button
                    onClick={fileUploadFormHandler}
                    type="primary"
                    className="new-button"
                  >
                    Save
                  </Button>
                </Space>
              }
            </Col>
          </Row>
        </FileUploadForm>
      )}
    </>
  );
};

export default CommentsPage;
