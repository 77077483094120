import { CPSPreviewDto, ContractStatusEnum, PlantCodeReqDto, ProcurementContractService } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Form, Input, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";



const ProcurementPendingApproval = () => {
  const [procurementPending, setProcurementPending] = useState<any>()
  const [searchData, setSearchData] = useState("")

  const service = new ProcurementContractService()
  let pathToreDirect = '/procurement-contract-detailedView';
  const { authContext } = useAuthState()

  useEffect(() => {
    procurementPendingReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const procurementPendingReport = () => {
    const req = new PlantCodeReqDto(authContext.defaultPlant)
    service.procurementPendingData(req).then(res => {
      if (res.status) {
        setProcurementPending(res.data)
      } else {
        setProcurementPending([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })

  }



  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.bpName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.action)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },


      render: (value, rowData) => {
        const link = `/#${pathToreDirect}?cpcId=${rowData.contractId}`
        return <> {
          <a href={link} className="link" >{value}</a>
        }
        </>
      }
    },

    {
      title: 'Contract Type',
      dataIndex: 'contractType',
    },

    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('DD\u2011MMM\u2011YYYY') : "" },
      sorter: (a, b) => moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },


    {
      title: 'Action',
      dataIndex: 'action',
      onCell: (record: any) => ({
        className: 'nowrap'
      }),
      render: (text, rowData) => {
        return (
          <span>
            <Button size="small" className="approve" onClick={() => {
              approveOrRejectServiceContract(rowData.contractId, ContractStatusEnum.ACTIVE, rowData.contractTypes);
            }}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>&nbsp;&nbsp;
            <Button size="small" className="reject" onClick={() => {
              approveOrRejectServiceContract(rowData.contractId, ContractStatusEnum.DRAFT, rowData.contractTypes);
            }}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
          </span>
        );
      }

    },
  ]

  const approveOrRejectServiceContract = (contractId: string, status: ContractStatusEnum, contractTypes: any) => {
    const req = new CPSPreviewDto(contractId, authContext.defaultPlant, contractTypes, status, authContext.user.userName);
    service.approveOrRejectServiceContract(req).then(res => {
      if (res.status) {
        procurementPendingReport();
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.error);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (
    <>
      <Card headStyle={{border: 0, paddingTop: '16px'}} className="card-br-8"
        title={<Input.Search placeholder="Search" className="input-search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} />}
      >

        <Table className='contracts-list overflowX' columns={tableColumns} dataSource={procurementPending}
            pagination={{
              showTotal: (total) => (
                <span 
                className='page-total-items'
                >Total {total} items</span>
              ),
              showSizeChanger: true, 
                pageSizeOptions: ['25', '50', '100'],
                defaultPageSize: 25,
              style: {
                paddingTop: '20px',
              },
            }}
        ></Table>

        </Card>

    {/* <Card>

      <Form layout="vertical">
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
          </Col>
        </Row>

      </Form>
      <Table className='contracts' columns={tableColumns} dataSource={procurementPending} ></Table>

    </Card> */}
    </>
  )

}
export default ProcurementPendingApproval;