
import { ActionEnum, BargeIdRequestDto, BargeService, ContractModesEnum, PurchaseSupplierReportDto, SamplingAndAnalysisService, SamplingTypes, ScqTypicalRequestDto, PortTypeEnum } from "@exportx/shared-models-and-services";
import { getFilesData } from "@exportx/ui-utils";
import { Affix, Button, Card, Form, Input, Row, Table, FormInstance, Radio } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertMessages, FileUploadForm, isPermissionExist, useAuthState } from "../../../../common";

interface IPropsBargeSamplingReport {
  bargeId?: any;
  setIsEntryFormActivated?: React.Dispatch<React.SetStateAction<boolean>>;
  sampleType?: SamplingTypes
  contractQuality?: string;
  bargeScq?: any;
  contractType?: ContractModesEnum;
  qualityData?: PurchaseSupplierReportDto;
  actionType?: ActionEnum;
  formRef?: FormInstance<any>
  filePermission?: number;
}

export const BargeSamplingReport = (props: IPropsBargeSamplingReport) => {
  const [gridData, setGridData] = useState<any>({});
  const [filesFormRef] = Form.useForm();
  const [actualFormRef] = Form.useForm();
  const bargeService = new BargeService();
  const qualityService = new SamplingAndAnalysisService();

  const [bargeGridData, setBargeGridData] = useState<PurchaseSupplierReportDto[]>([])
  // const [formRef] = Form.useForm()
  const { formRef, bargeId, setIsEntryFormActivated, sampleType, contractQuality, bargeScq, contractType, qualityData, actionType, filePermission } = props;
  const { authContext } = useAuthState();
  const navigate = useNavigate();

  console.log('props =>', props)


  useEffect(() => {
    if (contractType !== ContractModesEnum.SALES) {
      getPurchaseSamplingSupplier();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bargeId[0]]);

  useEffect(() => {
    if (contractType === ContractModesEnum.SALES) {
      getScqTypicalSpecs();
    }

  }, [bargeScq])
  
  const hasPermission = (prop: number) => {
    if(prop){
      return isPermissionExist([prop])
    }
    return true
  }

  const getPurchaseSamplingSupplier = () => {
    const req = new BargeIdRequestDto(bargeId[0], authContext.defaultPlant);
    qualityService.getPurchaseSamplingSupplier(req).then(res => {
      if (res.status) {
        setBargeGridData(res.data);
        setDefaultValue(res.data[0]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getScqTypicalSpecs = () => {
    const req = new ScqTypicalRequestDto(bargeScq?.scQualitiesId, authContext.defaultPlant, actualFormRef.getFieldValue('portType'), sampleType);
    qualityService.getSalesSamplingSupplier(req).then(res => {
      if (res.status) {
        setBargeGridData(res.data);
        setDefaultValue(res.data[0]);

        actualFormRef.setFieldValue('portType', res.data[0]?.portType);
        setIsEntryFormActivated(true);
      } else {
        console.log(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  };




  const obj = {};
  const setDefaultValue = (response) => {
    obj['qualityCalorificValueActual'] = Number(response.cvActual);
    obj['qualityCalorificValue2Actual'] = Number(response.cvActual2);
    obj['qualityCalorificValue3Actual'] = Number(response.cvActual3);
    obj['qualityTotalMoistureActual'] = Number(response.tmActual);
    obj['qualityInherentMoistureActual'] = Number(response.inmActual);
    obj['qualityAshActual'] = Number(response.ashActual);
    obj['qualityVolatileMatterActual'] = Number(response.vmActual);
    obj['qualityFixedCarbonActual'] = Number(response.fcActual);
    obj['qualityFuelRatioActual'] = Number(response.frActual);
    obj['qualityTotalSulphurActual'] = Number(response.tsActual);
    obj['qualityHgiActual'] = Number(response.hgActual);
    obj['portType'] = Number(response?.portType);
    actualFormRef.setFieldsValue(obj);
    formRef.setFieldsValue(obj);
    onChangeCalling(obj)
  };

  const onChangeCalling = (obj) => {
    Object.keys(obj).forEach((rec) => {
      onActualChange(obj[rec], rec)
    });
  };

  let pathToRedirect;
  if (sampleType === SamplingTypes.SUPPLIER) {
    pathToRedirect = 'supplier'
  } else if (sampleType === SamplingTypes.ACTUAL) {
    pathToRedirect = 'actual'
  } else if (sampleType === SamplingTypes.INTERNAL) {
    pathToRedirect = 'company'
  } else if (sampleType === SamplingTypes.PRE_SHIPMENT) {
    pathToRedirect = 'pre-shipment'
  }

  const bargeSamplingCreate = () => {
    formRef.validateFields().then(values => {
      filesFormRef.validateFields().then(fileValues => {
        getFilesData(fileValues.filesData).then((filesData: any) => {
          actualFormRef.validateFields().then(actValues => {
            console.log('actValues ==>', actValues)
            const req: any = {
              samplingActual: {
                ...values, createdUser: authContext.user.userName, sampleType: sampleType, plantCode: authContext.defaultPlant, actualId: qualityData ? qualityData[0]?.actualId : null,
                cvActual: gridData.qualityCalorificValueActual,
                cvActual2: gridData.qualityCalorificValue2Actual,
                cvActual3: gridData.qualityCalorificValue3Actual,
                tmActual: gridData.qualityTotalMoistureActual,
                inmActual: gridData.qualityInherentMoistureActual,
                ashActual: gridData.qualityAshActual,
                vmActual: gridData.qualityVolatileMatterActual,
                fcActual: gridData.qualityFixedCarbonActual,
                frActual: gridData.qualityFuelRatioActual,
                tsActual: gridData.qualityTotalSulphurActual,
                hgActual: gridData.qualityHgiActual,

              }, bargeId: bargeId, filesData, actualGroupId: qualityData ? qualityData[0].actualGroupId : undefined, actionType: actionType, contractType: contractType,
              ...actValues,
            }
            qualityService.purchaseSamplingSupplierCreation(req).then(res => {
              if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                navigate(`/${contractType === ContractModesEnum.SALES ? "sales" : "purchase"}-sampling-reports/` + pathToRedirect);
              } else {
                AlertMessages.getErrorMessage(res.internalMessage)
              };
            });
          });
        });
      });
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    });
  };


  const tableColumns: any = [
    {
      title: 'Components',
      dataIndex: 'typicalAnalysis',
      width: 200,

      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
    },
    {
      title: 'Basis',
      dataIndex: 'basis',
      width: 130,

    },
    {
      title: 'Typical',
      dataIndex: 'typical',
      width: 130,

    },
    {
      title: 'Rejection',
      dataIndex: 'rejection',
      width: 130,

    },
    {
      title: 'Bonus Cap',
      dataIndex: 'bonusCap',
      width: 130,

    },
    {
      title: 'Penalty',
      dataIndex: 'penalty',
      width: 130,

    },
    {
      title: 'Actual',
      width: 130,

      dataIndex: 'actual',
      render: (name, row) => {
        return <>
          {contractQuality === "contract" ? row.actual :
            <Form.Item
              name={row.name} >
              <Input type="number" autoComplete='off' onChange={(e) => onActualChange(e.target.value, row.name)} />
            </Form.Item>
          }
        </>
      }
    },
  ]

  const onActualChange = (e, title) => {
    const newData: any = gridData
    newData[title] = Number(e)
    setGridData((prevData) => ({ ...prevData, ...newData }));
  };


  const tableData: any = [
    {
      typicalAnalysis: 'Gross Calorific Value (Kcal/kg)',
      name: 'qualityCalorificValueActual',
      units: 'Kcal/Kg',
      rowSpan: 3,
      basis: bargeGridData[0]?.cvBasis,
      typical: bargeGridData[0]?.cvTypical,
      rejection: bargeGridData[0]?.cvRejection,
      bonusCap: bargeGridData[0]?.cvBonusCap,
      penalty: bargeGridData[0]?.cvPenality,
      actual: bargeGridData[0]?.cvActual,
    },
    {
      typicalAnalysis: 'Gross Calorific Value (Kcal/kg)',
      name: 'qualityCalorificValue2Actual',
      units: 'Kcal/Kg',
      rowSpan: 0,
      basis: bargeGridData[0]?.cvBasis2,
      typical: bargeGridData[0]?.cvTypical2,
      rejection: bargeGridData[0]?.cvRejection2,
      bonusCap: bargeGridData[0]?.cv2BonusCap,
      penalty: bargeGridData[0]?.cv2PenalityCap,
      actual: bargeGridData[0]?.cvActual2,

    },
    {
      typicalAnalysis: 'Gross Calorific Value (Kcal/kg)',
      name: 'qualityCalorificValue3Actual',
      units: 'Kcal/Kg',
      rowSpan: 0,
      basis: bargeGridData[0]?.cvBasis3,
      typical: bargeGridData[0]?.cvTypical3,
      rejection: bargeGridData[0]?.cvRejection3,
      bonusCap: bargeGridData[0]?.cv2BonusCap,
      penalty: bargeGridData[0]?.cv3PenalityCap,
      actual: bargeGridData[0]?.cvActual3,
    },
    {
      typicalAnalysis: 'Total Moisture (%)',
      name: 'qualityTotalMoistureActual',
      units: '%',
      basis: bargeGridData[0]?.tmBasis,
      typical: bargeGridData[0]?.tmTypical,
      rejection: bargeGridData[0]?.tmRejection,
      actual: bargeGridData[0]?.tmActual,
    },
    {
      typicalAnalysis: 'inherent Moisture (%)',
      name: 'qualityInherentMoistureActual',
      units: '%',
      basis: bargeGridData[0]?.inmBasis,
      typical: bargeGridData[0]?.inmTypical,
      rejection: bargeGridData[0]?.inmRejection,
      actual: bargeGridData[0]?.inmActual,
    },
    {
      typicalAnalysis: 'Ash  (%)',
      name: 'qualityAshActual',
      units: '%',
      basis: bargeGridData[0]?.ashBasis,
      typical: bargeGridData[0]?.ashTypical,
      rejection: bargeGridData[0]?.ashRejection,
      penalty: bargeGridData[0]?.ashPenality,
      actual: bargeGridData[0]?.ashActual,
    },
    {
      typicalAnalysis: 'Volatile Matter (VM) (%)',
      name: 'qualityVolatileMatterActual',
      units: '%',
      basis: bargeGridData[0]?.vmBasis,
      typical: bargeGridData[0]?.vmTypical,
      rejection: bargeGridData[0]?.vmRejection,
      actual: bargeGridData[0]?.vmActual,
    },
    {
      typicalAnalysis: 'Fixed Carbon (%)',
      name: 'qualityFixedCarbonActual',
      units: '%',
      basis: bargeGridData[0]?.fcBasis,
      typical: bargeGridData[0]?.fcTypical,
      rejection: bargeGridData[0]?.fcRejection,
      actual: bargeGridData[0]?.fcActual,
    },
    {
      typicalAnalysis: 'Fuel Ratio (FC/VM)',
      name: 'qualityFuelRatioActual',
      units: '%',
      basis: bargeGridData[0]?.frBasis,
      typical: bargeGridData[0]?.frTypical,
      rejection: bargeGridData[0]?.frRejection,
      actual: bargeGridData[0]?.frActual,
    },
    {
      typicalAnalysis: 'Total Sulphur (%)',
      name: 'qualityTotalSulphurActual',
      units: '%',
      basis: bargeGridData[0]?.tsBasis,
      typical: bargeGridData[0]?.tsTypical,
      rejection: bargeGridData[0]?.tsRejection,
      penalty: bargeGridData[0]?.tsPenality,
      actual: bargeGridData[0]?.tsActual,
    },
    {
      typicalAnalysis: 'HGI',
      name: 'qualityHgiActual',
      units: '',
      basis: bargeGridData[0]?.hgBasis,
      typical: bargeGridData[0]?.hgTypical,
      rejection: bargeGridData[0]?.hgRejection,
      actual: bargeGridData[0]?.hgActual,
    },
  ]

  const rowClassName = (row: any, index) => {
    return index % 2 === 0 ? 'row-even' : 'row-odd';
  };

  return (
    <>
      {/* <Card bordered={false}> */}
        <Form form={actualFormRef} autoComplete='off'>

          {contractType === ContractModesEnum.SALES &&

            <Form.Item name={"portType"} label={`Port Type :`}
              rules={[{ required: true, message: "Port Type is Required" }]}>
              <Radio.Group>
                <Radio value={PortTypeEnum.LOADING_PORT}>Loading Port</Radio>
                <Radio value={PortTypeEnum.DISCHARGE_PORT}>Discharge Port</Radio>
              </Radio.Group>
            </Form.Item>
          }
          <Table
            columns={tableColumns}
            dataSource={tableData}
            rowClassName={rowClassName}
            // bordered 
            pagination={false}
            className="contracts-PO-List"
          />
          <br />
        </Form>



        {hasPermission(filePermission) && (<FileUploadForm key={Date.now()} maxCount={3} layoutType='vertical' formRef={filesFormRef} initialValues={bargeGridData?.[0]?.filesData?.length ? bargeGridData?.[0]?.filesData : []} ></FileUploadForm>)}
        <Affix >
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>

              <Button hidden={contractQuality === "contract" ? true : false}
                onClick={() => setIsEntryFormActivated(false)}>Previous</Button>
              {}<Button hidden={contractQuality === "contract" ? true : false}
                onClick={() => {
                  bargeSamplingCreate();
                }}
                type='primary'
              >Submit
              </Button>
            </Row>
          </Card>
        </Affix>
      {/* </Card> */}

    </>
  )
}

export default BargeSamplingReport