import React, { useEffect, useState } from 'react'


import { Form, Input, Row, Col, Button, Space, Card, InputNumber, Tooltip, Upload, FormInstance, UploadFile, message, UploadProps } from "antd"
import { useIntl } from 'react-intl';
import DatePicker from '../../../../common/data-picker/date-picker';
import { DeleteFilled, UploadOutlined } from '@ant-design/icons';
import ViewFileHandling from '../../../../common/file-upload-form/view-files-handling';

interface IBargeShippingInstructionsProps {
  initialValues: any;
  formRef: FormInstance<any>;

}

export const BargeShippingInstructions = (props: IBargeShippingInstructionsProps) => {
  const { initialValues, formRef } = props;
  const { formatMessage: fm } = useIntl();
  const [blFileList, setBLFileList] = useState<any[]>([]);


  const validateFileType = ({ type, name }: UploadFile, allowedTypes: string[] = ['image/jpeg']) => {
    return true;//allowedTypes.includes(type!);
  };

  const getUploadProps = (indexOfThatFile: number, stateVariableSetter: React.Dispatch<React.SetStateAction<any[]>>, stateVariable: any[]) => {
    const uploadProps = {
      multiple: false,
      beforeUpload: (file: UploadFile) => {
        const isAllowedType = validateFileType(file);
        if (!isAllowedType) {
          message.error(`${file.name} is not JPEG file`);
          return false;
        }
        stateVariableSetter((prev) => {
          if (prev.length < 5) {
            const clonedData = [...prev];
            clonedData[indexOfThatFile] = file;
            return clonedData;
          }
          return prev;
        });
        return false;
      },
      onRemove: (file: UploadFile) => {
        stateVariableSetter((prev) => {
          const clonedData = [...prev];
          clonedData[indexOfThatFile] = [];
          return clonedData;
        });
      },
      fileList: stateVariable[indexOfThatFile] ? [stateVariable[indexOfThatFile]] : []
    } as UploadProps;
    return uploadProps;
  };

  return (
    <>
      <br />
      <Form
        layout='vertical'
        autoComplete="off"
        initialValues={initialValues ? initialValues : { shippingInstructions: [''] }}
        form={formRef}
      >
        <Form.List name="shippingInstructions">
          {(fields, { add, remove }) => (
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              {fields.map(({ key, name, ...restField }, index) => (
                <Row>
                  <Col
                    xs={{ span: 23 }}
                    sm={{ span: 23 }}
                    md={{ span: 23 }}
                    lg={{ span: 23 }}
                    xl={{ span: 23 }}>
                    <Card size='small'>
                      <Row key={key} gutter={16}>
                        <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
                          <Form.Item
                            label='Date'
                            name={[name, "siId"]}
                            style={{ display: 'none' }}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            label='Date'
                            name={[name, "siDate"]}
                            rules={[{ required: true, message: 'Missing Item' }]}
                          >
                            <DatePicker />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
                          <Form.Item
                            name={[name, "siQuantity"]}
                            label={'Quantity'}
                            rules={[
                              {
                                required: true, message: fm({ id: `mdm.components.addQualityForm.qualityReq` })
                              },

                              {
                                pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                                message: `Only Number & Decimals`
                              }

                            ]}
                          >
                            <InputNumber placeholder={fm({ id: `mdm.components.addQualityForm.qualityReq`, defaultMessage: " Please  Enter Quality " })} width={'100%'} />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
                          {/* <Form.Item
                            name={[field.name, 'file']}
                            // fieldKey={[fieldKey, 'file']}
                            label='Document'
                            valuePropName="fileList"
                            getValueFromEvent={(e) => e && e.fileList}
                            rules={[{ required: true, message: 'Please upload a file' }]}
                          >
                            <Upload
                              beforeUpload={() => false} // Prevent automatic upload
                              listType="text"
                              onChange={(info)=>handleChange(info, field.name)}
                              
                            >
                              <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                          </Form.Item> */}

                          <Form.Item
                            label={'UploadDocument'}

                            name={[name, 'filesData']}
                          >
                            <Upload
                              style={{ width: 130 }}
                              multiple={false}
                              listType="text"
                              showUploadList
                              {...getUploadProps(index, setBLFileList, blFileList)}

                            > <Button icon={<UploadOutlined />}>Click to Upload</Button></Upload>
                          </Form.Item>
                          {initialValues?.shippingInstructions?.length === undefined ? <></> : <ViewFileHandling key={index + 1} filesData={initialValues?.shippingInstructions[index]?.file} required={false} />}

                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  {fields?.length > 1 &&
                    <Col xs={{ span: 2 }}
                      sm={{ span: 1 }}
                      md={{ span: 1 }}
                      lg={{ span: 1 }}
                      xl={{ span: 1 }}>
                      <span style={{
                        position: "absolute",
                        marginTop: "33px",
                        marginLeft: "4px",
                      }}>
                        <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                          <DeleteFilled style={{ color: '#f70404', fontSize: '21px' }} onClick={() => {
                            remove(name);

                          }} />
                        </Tooltip>
                      </span>
                    </Col>
                  }

                </Row>


              ))}

              <Form.Item >
                <Button type="dashed" onClick={() => add({ shippingInstructions: [''] })}>
                  Add More
                </Button>
              </Form.Item>

            </Space>
          )}

        </Form.List>
      </Form>
    </>
  )
}
