import { CostingWorkLogsEnum, ContractModesEnum, PerformaCoalCostingService, CommercialCoalCostingService, CalculationFilterDataReq, ContractStatusEnum, ReferenceFeatures, BargeCostingsEnum } from "@exportx/shared-models-and-services";
import { PerformaApprovalDetails, BargeCostingDetails, CmApprovalDetails, ExpenseEntryCostingDetails, DeadFreightApprovalDetails, DemurrageDispatchDetails } from ".";
import React from "react";

interface IBargeCostingChild {
  costingId: string;
  costingType: CostingWorkLogsEnum | ReferenceFeatures | BargeCostingsEnum;
  remainingAmount?: number,
  children?: React.ReactNode;
}


const Costings = (props: IBargeCostingChild) => {
  const { costingType, costingId, remainingAmount, children } = props;

  let componentToRender;
  switch (costingType) {
    case CostingWorkLogsEnum.PERFORMA_PC_COSTING:
      componentToRender = <PerformaApprovalDetails costingId={costingId} contractType={ContractModesEnum.PURCHASE} remainingAmount={remainingAmount}>
        {props?.children}
      </PerformaApprovalDetails>;
      break;
    case CostingWorkLogsEnum.PERFORMA_SC_COSTING:
      componentToRender = <PerformaApprovalDetails costingId={costingId} contractType={ContractModesEnum.SALES} remainingAmount={remainingAmount} >
        {props?.children}
      </PerformaApprovalDetails>;
      break;
    case CostingWorkLogsEnum.SALES_FREIGHT_COSTING:
      componentToRender = <PerformaApprovalDetails costingId={costingId} contractType={ContractModesEnum.FREIGHT_COSTING} remainingAmount={remainingAmount}>
        {props?.children}
      </PerformaApprovalDetails>
      break;
    case CostingWorkLogsEnum.BARGE_COSTING:
      componentToRender = <BargeCostingDetails costingId={costingId} remainingAmount={remainingAmount} >
        {props?.children}
      </BargeCostingDetails>;
      break;
    case CostingWorkLogsEnum.PROCUREMENT_FEES:
      componentToRender = <CmApprovalDetails costingId={costingId} contractType={ContractModesEnum.PROCUREMENT_FEES} remainingAmount={remainingAmount} >
        {props?.children}
      </CmApprovalDetails>;
      break;
    case CostingWorkLogsEnum.MARKETING_FEES:
      componentToRender = <CmApprovalDetails costingId={costingId} contractType={ContractModesEnum.MARKETING_FEES} remainingAmount={remainingAmount}>
        {props?.children}
      </CmApprovalDetails>;
      break;
    case CostingWorkLogsEnum.COMMERCIAL_PC_COSTING:
      componentToRender = <CmApprovalDetails costingId={costingId} contractType={ContractModesEnum.PURCHASE} remainingAmount={remainingAmount} >
        {props?.children}
      </CmApprovalDetails>;
      break;
    case CostingWorkLogsEnum.COMMERCIAL_SC_COSTING:
      componentToRender = <CmApprovalDetails costingId={costingId} contractType={ContractModesEnum.SALES} remainingAmount={remainingAmount}>
        {props?.children}
      </CmApprovalDetails>;
      break;
    case CostingWorkLogsEnum.EXPENSE_ENTRY:
      componentToRender = <ExpenseEntryCostingDetails expenseEntryId={costingId} remainingAmount={remainingAmount}>
        {props?.children}
      </ExpenseEntryCostingDetails>;
      break;
    case CostingWorkLogsEnum.DEAD_FREIGHT:
      componentToRender = <DeadFreightApprovalDetails costingId={costingId} remainingAmount={remainingAmount}>
        {children}
      </DeadFreightApprovalDetails>;
      break;
    case CostingWorkLogsEnum.DEMURRAGE:
      componentToRender = <DemurrageDispatchDetails costingId={costingId} remainingAmount={remainingAmount} >
        {children}
      </DemurrageDispatchDetails>;
      break;
    case CostingWorkLogsEnum.DISPATCH:
      componentToRender = <DemurrageDispatchDetails costingId={costingId} remainingAmount={remainingAmount} >
        {children}
      </DemurrageDispatchDetails>;
      break;

    default: componentToRender = children
  }


  return (
    <>
      {componentToRender}
    </>

  )

}

export const AccountPayableCostings = React.memo(Costings);
