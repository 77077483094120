import { PurchaseContractService } from "@exportx/shared-models-and-services";
import { Card, Col, Form, Input, Row, Select, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertMessages, useAuthState } from "../../../../../common";
import Link from 'antd/lib/typography/Link';

const PurchaseActiveApproval = () => {
  const [purchaseActive, setPurchaseActive] = useState<any>();
  const [searchData, setSearchData] = useState("")

  const service = new PurchaseContractService()
  const navigate = useNavigate();
  let pathToreDirect = '/po-detail-view'
  const { authContext } = useAuthState()

  const redirectToFullView = (contractId: string) => {
    navigate(pathToreDirect, { state: { contractId } });
  }

  useEffect(() => {
    PurchaseActive();
  }, []);


  const PurchaseActive = () => {
    service.purchaseApprovalData({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setPurchaseActive(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }
  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.bpName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.approvedBy)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
      render: (value, rowData) =>{
        const link = `/#/po-detail-view?pc_id=${rowData.contractId}`
        return <> {
        <a href={link} className="link" >{value}</a>
      }
      </>}

    },

    {
      title: 'Contract Type',
      dataIndex: 'contractType',
    },

    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('DD\u2011MMM\u2011YYYY') : "" },
      sorter: (a, b) => moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },

    {
      title: 'Laycan',
      dataIndex: 'layCan',
      render: (value, record) => { return record?.layCan ? Array.isArray(record?.layCan) ? `${moment(record.layCan[0]).format('DD\u2011MMM\u2011YYYY')} to ${moment(record.layCan[1]).format('DD\u2011MMM\u2011YYYY')}` : `${moment(record.layCan.split(',')[0]).format('DD\u2011MMM\u2011YYYY')} to ${moment(record.layCan.split(',')[1]).format('DD\u2011MMM\u2011YYYY')}` : ''; }
    },

    {
      title: 'Quality & Quantity MT',
      dataIndex: 'formattedQualities'
    },

    {
      title: 'Approved By',
      dataIndex: 'approvedBy',
    },




  ]


  return (
    <>
       <Card headStyle={{border: 0, paddingTop: '16px'}} className="card-br-8"
        title={<Input.Search placeholder="Search" className="input-search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} />}
      >

        <Table className='contracts-list overflowX' columns={tableColumns} dataSource={purchaseActive}
            pagination={{
              showTotal: (total) => (
                <span 
                className='page-total-items'
                >Total {total} items</span>
              ),
              showSizeChanger: true, 
                pageSizeOptions: ['25', '50', '100'],
                defaultPageSize: 25,
              style: {
                paddingTop: '20px',
              },
            }}
        ></Table>

        </Card>

    {/* <Card>

      <Form layout="vertical">
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
          </Col>
        </Row>

      </Form>
      <Table className='contracts' columns={tableColumns} dataSource={purchaseActive}></Table>

    </Card> */}
    </>
  )

}
export default PurchaseActiveApproval;