import { BusinessNumberService } from "@exportx/shared-models-and-services";
import { Card, Table } from "antd";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../common";
import moment from "moment";



export const BusinessNoBoard = () => {
  const [bnData, setBnData] = useState([]);
 const { authContext } = useAuthState()

  const bnService = new BusinessNumberService()

  useEffect(()=>{
    BusinessNoData()
  },[])

  const BusinessNoData = () => {
    bnService.getAllFeatureBnNoData({plantCode: authContext.defaultPlant}).then(res => {
      if(res.status){
        setBnData(res.data)
      }
    }).catch(error => {
      AlertMessages.getErrorMessage(error.messege)
    })
  }

  const FormatedBusinessNoData = (businessNoData) => {
    const bnNoData = []
    businessNoData.forEach(rec => {
        rec.loadingAnchorage.forEach((anchorageRecord, index) => {
          bnNoData.push({
            bnId: rec.bnId,
            businessNo: rec.businessNo,
            mvName: rec.mvName,
            stowagePlan: rec.stowagePlan,
            rowSpan: index === 0 ? rec.loadingAnchorage.length : 0,
            anchorageName: anchorageRecord.anchorageName,
            estimatedETA: anchorageRecord.estimateDate
          })
        })
      })
    return bnNoData;
  }

  const Columns = [
    {
      title: 'Business No',
      dataIndex: 'businessNo',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      render: (text,record) => {
        let link = `/#/bn-detail-view?bn_Id=${record.bnId}`
        return <a className="link" href={link}>{text} ({record.mvName})</a>
      }
    },
    {
      title: 'Stowage Plan',
      dataIndex: 'stowagePlan',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      })
    },
    {
      title: 'Anchorage',
      dataIndex: 'anchorageName',
    },
    {
      title: 'Estimated ETA',
      dataIndex: '',
      render: (text, record) => {
        const ETA = record?.estimatedETA ? record.estimatedETA.split(',') : [];
    
        return ETA.map((item, index) => {
          const formattedDate = moment(item).isValid()
            ? moment(item).format('DD-MMM-YYYY')
            : '';
    
          return (
            <div key={index} style={{ marginRight: 5 }}>
              {formattedDate}
            </div>
          );
        });
      }
    }
    
  ]

  return (
    <Card style={{ width: '100%'}} title={'Ongoing Business Numbers'}>
        <Table 
          className="contracts-list"
          columns={Columns}
          dataSource={FormatedBusinessNoData(bnData)}
          style={{width: '100%'}}
        >

        </Table>
    </Card>
  )
}

export default BusinessNoBoard;