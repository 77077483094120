import {
  BargeAgreementService,
  ContractStatusEnum,
  PlantCodeReqDto,
  PreviewRequests,
  VesselAgreementPreviewDto,
} from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../../common';
import moment from 'moment';
import { EditFilled, FileExcelFilled } from '@ant-design/icons';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { Excel } from 'antd-table-saveas-excel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { Option } from 'antd/es/mentions';
import TabPane from 'antd/es/tabs/TabPane';
import { VesselAgreementGrid } from '../vessel-agreements';

export const BargeAgreementGrid = () => {
  const { formatMessage: fm } = useIntl();
  const [gridData, setGridData] = useState<any[]>([]);
  const navigate = useNavigate();
  const service = new BargeAgreementService();
  let pathToreDirect = '/bargeAgreement-detailed-view';
  const [searchData, setSearchData] = useState('');
  const { authContext } = useAuthState();
  const { state }: any = useLocation();
  const { baId } = state ? state : { baId: null };
  const [status, setStatus] = useState<any>('');

  useEffect(() => {
    if (!isPermissionExist([138])) navigate('/');
    getAllBargeAgreementData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllBargeAgreementData = () => {
    const req = new PlantCodeReqDto(authContext.defaultPlant);

    service
      .getAllBargeAgreement(req)
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const sendForApprovalHandler = (id) => {
    service
      .sendForApproval({ baId: id, plantCode: authContext.defaultPlant })
      .then((res) => {
        if (res.status) {
          getAllBargeAgreementData();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const approveOrRejectHandler = (
    agreementId: string,
    status: ContractStatusEnum,
  ) => {
    const req = new VesselAgreementPreviewDto(
      agreementId,
      authContext.defaultPlant,
      authContext.user.userName,
      status,
    );
    service
      .approveOrRejectAgreement(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getAllBargeAgreementData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  // Handle status change
  const handleStatusChange = (value) => {
    setStatus(value);
  };

  // Filter data based on selected status
  const filteredData = status
    ? gridData.filter((item) => item.status === status)
    : gridData;

  const tableColumns: any = [
    // {
    //     title: 'S No',
    //     key: 'sno',
    //     width: '70px',
    //     align: 'center',
    //     responsive: ['sm'],
    //     render: (text, object, index) => (page - 1) * 10 + (index + 1)
    //   },
    {
      title: 'Fixture Note',
      dataIndex: 'fixtureNoteNo',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.fixtureNoteNo.localeCompare(b.fixtureNoteNo),
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.fixtureNoteNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.agreementDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.vendorName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.status).toLowerCase().includes(value.toLocaleString())
        );
      },
      render: (value, record) => {
        const link = `/#/bargeAgreement-detailed-view?ba_Id=${record.baId}`;
        return (
          <>
            <a href={link} className="link">
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Agreement Date',
      dataIndex: 'agreementDate',
      // width: 125,
      align: 'left',
      render: (text, record) => {
        return (
          <span className="table-other-columns">
            {record.agreementDate !== undefined
              ? moment(record.agreementDate).format('DD-MMM-YYYY')
              : ''}
          </span>
        );
      },
      sorter: (a, b) =>
        moment(a.agreementDate).unix() - moment(b.agreementDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Barge Vendor',
      dataIndex: 'vendorName',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span className="table-name-column">{record?.vendorName}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // width: 130,
      align: 'left',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <>
          <StatusTag status={record.status} />
        </>
      ),
    },
    {
      title: 'Action',
      className: 'operations',
      // width: 130,
      // align: 'Center',
      render: (text, rowData) => (
        <span>
          {isPermissionExist([139]) &&
            rowData.status !== ContractStatusEnum.ACTIVE && (
              <EditFilled
                className="action-icons mr-8"
                type="edit"
                onClick={() => {
                  editClickHandler(rowData.baId);
                }}
              />
            )}
          {/* &nbsp;&nbsp;
                    {rowData.status === ContractStatusEnum.DRAFT  && <Button size="small" className='new-button' onClick={() => sendForApprovalHandler(rowData.baId)} >Send For Approval</Button>}
                    {(rowData.status === ContractStatusEnum.PENDING_APPROVAL && isPermissionExist([142])) && <Button size="small" className='approve' onClick={() => approveOrRejectHandler(rowData.baId, ContractStatusEnum.ACTIVE)}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>} &nbsp;&nbsp;
                    {(rowData.status === ContractStatusEnum.PENDING_APPROVAL && isPermissionExist([143])) && <Button size="small" className='reject' onClick={() => approveOrRejectHandler(rowData.baId, ContractStatusEnum.DRAFT)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>} */}
        </span>
      ),
    },
  ];
  const redirectToFullView = (baId: string) => {
    navigate(pathToreDirect, { state: { baId } });
  };
  const editClickHandler = (baId: string) => {
    const req = { ...new PreviewRequests(baId, authContext.defaultPlant) };
    service
      .getBargeAgreementPreviewData(req)
      .then((res) => {
        if (res.status) {
          navigate('/bargeAgreement-update', {
            state: { record: res.data, isUpdate: true },
          });
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const handleExport = (e: any) => {
    e.preventDefault();

    // Formatting columns - to set default render

    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split('-')
      .join('/');
    // Formatting columns - to set default render
    let cloneArr = tableColumns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return {
          title: item.dataIndex.toLocaleUpperCase(),
          dataIndex: item.dataIndex,
        };
    });

    const excel = new Excel();
    excel.addSheet('Sheet1');
    excel.addRow();
    excel
      .addColumns(exportingColumns)
      .addDataSource(filteredData)
      .saveAs(`BargeAgreement-${currentDate}.xlsx`);
  };

  return (
    <>
      <Card className="card-radius">
        <Tabs>
          {isPermissionExist([138]) && (
            <TabPane tab="Barge Agreement" key="1">
              <Flex justify="space-between" align="middle">
                <Row className="card-title-buttons">
                  <Input.Search
                    placeholder="Search"
                    allowClear
                    onChange={(e) => {
                      setSearchData(e.target.value);
                    }}
                    onSearch={(value) => {
                      setSearchData(value);
                    }}
                    className="input-search"
                  />
                  <Select
                    placeholder="Filter by Status"
                    className="filter-by-status"
                    allowClear
                    onChange={handleStatusChange}
                  >
                    <Option value="Draft">Draft</Option>
                    <Option value="Send For Approval">Send for Approval</Option>
                    <Option value="Active">Active</Option>
                  </Select>
                </Row>
                <>
                  {isPermissionExist([137]) && (
                    <Tooltip placement="topRight" title="Create">
                      <Row className="mt-16">
                        <Button
                          className="new-button"
                          size="small"
                          type="primary"
                          onClick={() => navigate('/bargeAgreement-creation')}
                        >
                          Create
                        </Button>
                      </Row>
                    </Tooltip>
                  )}
                </>
              </Flex>
              <Table
                className="contracts-list mt-16"
                rowKey={(record) => record.baId}
                columns={tableColumns}
                dataSource={filteredData}
                pagination={{
                  showTotal: (total) => (
                    <span className="page-total-items">
                      Total {total} items
                    </span>
                  ),
                  showSizeChanger: true,
                  pageSizeOptions: ['25', '50', '100'],
                  defaultPageSize: 25,
                  style: {
                    paddingTop: '20px',
                  },
                }}
                scroll={{ x: 500 }}
              ></Table>
            </TabPane>
          )}
          {isPermissionExist([138]) && (
            <TabPane tab="Vessel Agreement" key="2">
              <VesselAgreementGrid />
            </TabPane>
          )}
        </Tabs>
      </Card>

      {/* <Card
            title={fm({ id: `mdm.components.header`, defaultMessage: `Barge Agreement` })}
            extra={<>{isPermissionExist([137]) && (<Button
                type="primary"
                onClick={() => navigate('/bargeAgreement-creation')}
                className='panel_button'
            >Create</Button>
            )}</>}
        >   
            {isPermissionExist([144]) && (<Tooltip placement="topRight" title="Export As Excel" color="green">
                <Button
                    // disabled={gridData.length === 0}
                    size='small'
                    type="default"
                    style={{ float: "right" }}
                    //className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
                    onClick={handleExport}
                    icon={<FileExcelFilled />}
                >Export</Button>
            </Tooltip>)}
            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />

            <Table
                className='contracts'
                rowKey={record => record.baId}
                columns={tableColumns}
                dataSource={gridData}
                pagination={false}

                scroll={{ x: 500 }}
                size="large"
                bordered
            ></Table>
        </Card> */}
    </>
  );
};
