import {
  CommercialCoalCostingService,
  ContractModesEnum,
  ContractStatusEnum,
  CostingTypesEnum,
  CurrencyConverterService,
  PerformaCoalCostingService,
  PerFormaReqIdDto,
  PriceTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Flex,
  Layout,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import queryString from 'query-string';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../../common';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditFilled, InfoCircleOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import { QualityPriceAdjustmentsDeatils } from '../../costing-detail-view';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
import { CostingInvoice } from '../../costing-invoice';
import AddInvoice from '../../add-invoice';
import { AuthContext } from '../../../../common/iam-client-react/iam-client';

export interface PerformaProps {
  costingType: CostingTypesEnum;
  contractType: ContractModesEnum;
  referenceFeatures: ReferenceFeatures;
}

export const PerformaDetailView = (props: PerformaProps) => {
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingIds, setCostingId] = useState<any>();
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const [data, setData] = useState<any>();
  const [advTotal, setAdvTotal] = useState<number>(0);
  const [bargeDetails, setBargeDetails] = useState<any>([]);
  const [advanceAdjustment, setAdvanceAdjustment] = useState([]);
  const [downloadPermission, setDownloadPermission] = useState<any>(1);
  const [editPermission, setEditPermission] = useState<any>();
  const [viewInvoice, setViewInvoice] = useState<any>();
  const [accessId, setAccessId] = useState<any>({});
  const { Text } = Typography;

  const { contractType, costingType, referenceFeatures } = props;
  const paramBargeId: any = queryString.parse(location.search)?.costing_id;
  let { costingId } =
    paramBargeId != null ? { costingId: paramBargeId } : { costingId: null };

  console.log('authContext.defaultPlant',authContext.defaultPlant)

  const performaCostingsService = new PerformaCoalCostingService();
  const cmService = new CommercialCoalCostingService();

  useEffect(() => {
    let permission;
    let download;
    let edit;
    let viewInvoices;
    if (costingType === CostingTypesEnum.PERFORMA) {
      permission = contractType === ContractModesEnum.PURCHASE ? 271 : 313;
      edit = contractType === ContractModesEnum.PURCHASE ? 272 : 314;
      download = contractType === ContractModesEnum.PURCHASE ? 895 : 897;
      viewInvoices = contractType === ContractModesEnum.PURCHASE ? 280 : 322;
      if (contractType === ContractModesEnum.PURCHASE) {
        setAccessId({
          create: 279,
          view: 280,
          update: 281,
          delete: 282,
          approve: 283,
          reject: 284,
          release: 285,
          files: 286,
        });
      } else {
        setAccessId({
          create: 321,
          view: 322,
          update: 323,
          delete: 324,
          approve: 325,
          reject: 326,
          release: 327,
          files: 328,
        });
      }
    } else if (costingType === CostingTypesEnum.COMMERCIAL) {
      permission = contractType === ContractModesEnum.PURCHASE ? 292 : 334;
      edit = contractType === ContractModesEnum.PURCHASE ? 293 : 335;
      download = contractType === ContractModesEnum.PURCHASE ? 896 : 898;
      viewInvoices = contractType === ContractModesEnum.PURCHASE ? 301 : 343;
      if (contractType === ContractModesEnum.PURCHASE) {
        setAccessId({
          create: 300,
          view: 301,
          update: 302,
          delete: 303,
          approve: 304,
          reject: 305,
          release: 306,
          files: 307,
        });
      } else {
        setAccessId({
          create: 342,
          view: 343,
          update: 344,
          delete: 345,
          approve: 346,
          reject: 347,
          release: 348,
          files: 349,
        });
      }
    } else if (costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) {
      permission = edit = download = viewInvoices = 1;
      setAccessId(
        Object.fromEntries(
          [
            'create',
            'update',
            'delete',
            'approve',
            'reject',
            'release',
            'files',
          ].map((key) => [key, 1]),
        ),
      );
    } else if (costingType === CostingTypesEnum.MARKETING_FEES) {
      permission = 376;
      edit = 377;
      download = 900;
      viewInvoices = 384;
      setAccessId({
        create: 383,
        update: 385,
        delete: 386,
        approve: 387,
        reject: 388,
        release: 389,
        files: 390,
      });
    } else {
      permission = 355;
      edit = 356;
      download = 899;
      viewInvoices = 364;
      setAccessId({
        create: 363,
        update: 365,
        delete: 366,
        approve: 367,
        reject: 368,
        release: 369,
        files: 370,
      });
    }
    setDownloadPermission(download);
    setEditPermission(edit);
    setViewInvoice(viewInvoices);
    if (!isPermissionExist([permission])) navigate('/');
  }, [costingType, contractType]);

  let path;
  let title;
  let pathToList;

  if (costingType === CostingTypesEnum.PERFORMA) {
    path =
      contractType === ContractModesEnum.PURCHASE
        ? 'create-performa-coal-costing'
        : 'create-performa-costing-sale';
    title =
      contractType === ContractModesEnum.PURCHASE
        ? 'Performa Details (Coal Purchase)'
        : 'Performa Details (Coal Sales)';
    pathToList =
      contractType === ContractModesEnum.PURCHASE
        ? 'performaCoalCosting'
        : 'performaCostingSale';
  } else if (costingType === CostingTypesEnum.COMMERCIAL) {
    path =
      contractType === ContractModesEnum.PURCHASE
        ? 'create-commercial-coal-costing'
        : 'create-commercial-costing-sale';
    title =
      contractType === ContractModesEnum.PURCHASE
        ? 'Commercial Details (Coal Purchase)'
        : 'Commercial Details (Coal Sales)';
    pathToList =
      contractType === ContractModesEnum.PURCHASE
        ? 'commercial-coal-costing'
        : 'commercial-costing-sales';
  } else if (costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) {
    path = 'create-sale-freight-costing';
    title = 'Freight Costing Details (Sales)';
    pathToList = 'salesFreightCosting';
  } else if (costingType === CostingTypesEnum.MARKETING_FEES) {
    path = 'create-marketing-coal-costing';
    title = 'Marketing Fees Details';
    pathToList = 'marketing-coal-costing';
  } else {
    path = 'create-procurement-coal-costing';
    title = 'Procurement Fees Details';
    pathToList = 'procurement-coal-costing';
  }

  useEffect(() => {
    if (
      costingType === CostingTypesEnum.PERFORMA ||
      costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
    ) {
      getAllPerFormaData();
    }
    if (
      costingType === CostingTypesEnum.COMMERCIAL ||
      costingType === CostingTypesEnum.PROCUREMENT_FEES ||
      costingType === CostingTypesEnum.MARKETING_FEES
    ) {
      getAllCommercialData();
    }
  }, [costingId]);

  const getAllPerFormaData = async () => {
    const req = new PerFormaReqIdDto(
      costingId,
      contractType,
      authContext.defaultPlant,
    );
    await performaCostingsService
      .getAllPerFormaData(req)
      .then((res) => {
        if (res.status) {
          const displayExchangeRate =
            res.data?.costingTransactions[0]?.exchangeRate;
          const exchangeDate = res.data?.costingTransactions[0]?.exchangeDate;
          const totalPayable =
            +res?.data?.costingsTotal +
            (+res.data?.taxTotal1 + +res?.data?.taxTotal2) -
            +res?.data?.tdsTotal;
          setData({
            ...res.data,
            totalPayable: totalPayable,
            exchangeRate: displayExchangeRate,
            exchangeDate: exchangeDate,
            includeFreight: res.data?.includeFreight ? true : false,
          });
          setAdvanceAdjustment(res.data?.advanceTarnsactionsData);
          const advTotal = Number(
            res.data.advanceTarnsactionsData
              .reduce((a, c) => a + Number(c.totalAdjustedAmount), 0)
              .toFixed(2),
          );
          const bargeDetails = res.data?.costingTransactions?.map((item) => ({
            ...item,
            incoterm: res.data?.incoterm,
            advAdjustment: advTotal,
            totalPayable: totalPayable,
            layCan: `${moment(res?.data?.laycanFromDate)?.format(
              'DD-MMM-YY',
            )} - ${moment(res?.data?.laycanToDate)?.format('DD-MMM-YY')} (${res?.data?.quality || '-'
              })`,
            priceAfterExchg: Number(+item?.coalPricePerMt * item?.exchangeRate),
            remaining: +res.data?.totalAmount,
          }));
          setBargeDetails(bargeDetails);
          setAdvTotal(advTotal);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getAllCommercialData = () => {
    const req = new PerFormaReqIdDto(
      costingId,
      contractType,
      authContext.defaultPlant,
    );
    cmService
      .getAllCommercialCostingsData(req)
      .then(async (res) => {
        if (res.status) {
          const totalCoalPrice =
            res?.data?.price_type === PriceTypeEnum.LUMP_SUM
              ? Number(res?.data?.adjustedPriceValue)
              : +res?.data?.totalBargeQuantity * +res?.data?.exchangeRateValue;
          const totalPayable =
            +totalCoalPrice +
            (+res.data?.taxTotal1 + +res?.data?.taxTotal2) -
            +res?.data?.tdsTotal;
          const currencyConverterService = new CurrencyConverterService();
          let exchangeRate = 1;
          const currencyData =
            await currencyConverterService.getCurrencyExchangeRate({
              baseCurrency: res?.data?.baseCurrency,
              exchangeDate: res?.data?.exchangeDate,
              quoteCurrency: res?.data?.quoteCurrency,
            });
          if (currencyData?.data)
            exchangeRate = currencyData?.data?.exchangeRate;
          setData({
            ...res.data,
            totalPayable: totalPayable,
            totalCoalPrice: totalCoalPrice,
            exchangeRate: exchangeRate,
            includeFreight: res.data?.includeFreight ? true : false,
          });
          const advTotal = Number(
            res.data.advanceTarnsactionsData
              .reduce((a, c) => a + Number(c.totalAdjustedAmount), 0)
              .toFixed(2),
          );
          const bargeDetails = res.data?.transactionBarges?.map((item) => ({
            ...item,
            incoterm: res.data?.incoterm,
            advAdjustment: advTotal,
            totalPayable: item.total,
            layCan: `${moment(res?.data?.laycanFromDate)?.format(
              'DD-MMM-YY',
            )} - ${moment(res?.data?.laycanToDate)?.format('DD-MMM-YY')} (${res?.data?.quality || '-'
              })`,
            remaining: +res.data?.invoiceTotal,
          }));
          setAdvanceAdjustment(res.data?.advanceTarnsactionsData);
          setBargeDetails(bargeDetails);
          setAdvTotal(advTotal);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const showFreightPrice = () =>
    contractType !== ContractModesEnum.PROCUREMENT_FEES &&
    contractType !== ContractModesEnum.MARKETING_FEES;

  const sharedOnCell = (_, index?: number) => {
    if (index == 0) return { rowSpan: bargeDetails.length };
    else return { rowSpan: 0 };
  };

  const adjustedQty = bargeDetails?.reduce(
    (total, item) => total + Number(item.inWardQty),
    0,
  );

  const footer = () => (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          textAlign: 'right',
        }}
      >
        <div style={{ marginRight: '35px' }}>
          <div>
            <b>Adjust Quantity:</b>
          </div>
        </div>
        <div style={{ marginRight: '10px' }}>
          {costingType !== CostingTypesEnum.SALE_FREIGHT_COSTING ? (
            <div style={{ color: '#0283C8' }}>
              <b>{Number(adjustedQty).toFixed(3)}</b>
            </div>
          ) : (
            <div style={{ color: '#0283C8' }}>
              <b>{Number(adjustedQty).toFixed(3)}</b>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const qualityFooter = () => (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          textAlign: 'right',
        }}
      >
        <div style={{ marginRight: '35px' }}>
          <div>
            <b>Total Penalty/Bonus:</b>
          </div>
          <div>
            <b>Adjusted Price:</b>
          </div>
        </div>
        <div style={{ marginRight: '10px' }}>
          <div style={{ color: '#0283C8' }}>
            <b>0.000</b>
          </div>
          <div style={{ color: '#0283C8' }}>
            <b>0.000</b>
          </div>
        </div>
      </div>
    </div>
  );

  const editHandler = () => {
    navigate(`/${path}?costingId=${costingId}`);
  };

  let billingCurrency =
    costingType === CostingTypesEnum.PERFORMA
      ? data?.billingCurrency
      : costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
        ? data?.billingCurrency || data?.quoteCurrency
        : data?.quoteCurrency;

  const columns = [
    {
      title: `${contractType === ContractModesEnum.PURCHASE ? 'Purchase' : 'Sales'
        } Contract`,
      dataIndex: 'contractNo',
      width: 160,
      render: (value, record) => {
        let link =
          contractType === ContractModesEnum.PURCHASE
            ? `/#/po-detail-view?pc_id=${data?.contractId}`
            : `/#/so-detail-view?sc_id=${data?.contractId}`;
        return (
          <a href={link} className="link">
            {data?.contractNo}
          </a>
        );
      },
    },
    {
      title: 'Barge ID',
      dataIndex: 'bargeNo',
      key: 'bargeNo',
      width: 150,
      render: (value, record) => {
        if (record.bargeId || record.bgUId) {
          const link = `/#/barge-detail-view?barge_id=${record.bargeId || record.bgUId
            }`;
          return (
            <div>
              <a href={link} className="link" target="_blank">
                {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
              </a>
              {/* <Typography className="small-text"> {record.bgName}</Typography> */}
            </div>
          );
        }
      },
    },
    {
      title: 'Barge Nomination',
      dataIndex: 'bgName',
      width: 400,
      render: (value, record) => {
        return (
          <div>
            <Text className="small-text"> {value}</Text>
          </div>
        );
      },
    },
    {
      title: 'Jetty',
      dataIndex: 'loadingJetty',
      width: 100,
      render: (value, record) => (
        <Typography className="small-text"> {value}</Typography>
      ),
    },
    {
      title: 'Loading Date',
      dataIndex: 'loadingDate',
      width: 200,
      onCell: sharedOnCell,
      render: (value, record) => {
        let validDate = moment(value).isValid()
          ? moment(value).format('DD-MMM-YYYY')
          : '';
        return <span>{validDate}</span>;
      },
    },
    {
      title: 'MV Name',
      dataIndex: 'bgName',
      width: 400,
    },
    {
      title: 'Quality',
      dataIndex: '',
      width: 150,
      render: (value, record) => {
        return (
          <>
            <span>{data?.quality}</span>{' '}
            <span style={{ fontSize: 10, color: 'grey' }}>
              {' '}
              {data?.qualitySpecification}
            </span>
          </>
        );
      },
    },
    {
      title: `${contractType === ContractModesEnum.PURCHASE ||
          contractType === ContractModesEnum.PROCUREMENT_FEES
          ? 'Purchase'
          : 'Sales'
        } Type`,
      dataIndex: '',
      width: 200,
      render: (value, record) => {
        return <span>{data?.incoterm}</span>;
      },
    },
    {
      title: 'Quantity (MT)',
      dataIndex: 'inWardQty',
      key: 'inWardQty',
      width: 150,
      onCell: sharedOnCell,
      render: (value, record) => (
        <Typography className="small-text">
          {Number(value).toFixed(3)}
        </Typography>
      ),
    },
  ];

  const columns2: any = [
    {
      title: 'Advance Type',
      dataIndex: 'advanceType',
    },
    {
      title: 'Advance Balance',
      dataIndex: 'totalAdjustedAmount',
      align: 'right',
      render: (value, record) => {
        return <span>{Number(value).toFixed(3)}</span>;
      },
    },
    {
      title: 'Costing No',
      dataIndex: 'adjustedCostingNo',
    },
    {
      title: 'Adjusted Amount',
      dataIndex: 'adjustAmount',
      align: 'right',
      render: (value, record) => {
        return <span>{Number(value).toFixed(3)}</span>;
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: ""
    // },
  ];

  const columns4: any = [
    {
      title: 'Barge ID',
      dataIndex: 'bargeNo',
      key: 'bargeNo',
      render: (value, record) => {
        if (record.bgUId) {
          const link = `/#/barge-detail-view?barge_id=${record.bgUId}`;
          return (
            <div>
              <a href={link} className="link" target="_blank">
                {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
              </a>
              <Typography className="small-text"> {record.bgName}</Typography>
            </div>
          );
        }
        return (
          <div>
            <Text className="small-text"> {value}</Text>
            <Typography className="small-text"> {record.bgName}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Business No',
      dataIndex: 'businessNo',
      key: 'businessNo',
      render: (value, record) => (
        <Typography className="small-text">{value ?? 'N/A'}</Typography>
      ),
    },
    {
      title: 'Jetty',
      dataIndex: 'loadingJetty',
      render: (value, record) => (
        <Typography className="small-text"> {value}</Typography>
      ),
    },
    {
      title: 'Laycan (Quality)',
      dataIndex: 'layCan',
      key: 'layCan',
      onCell: sharedOnCell,
      render: (value, record) => (
        <>
          <Typography className="small-text">
            {record.layCan ?? 'N/A'}
          </Typography>
          <Typography className="small-text">
            {record.incoterm ?? 'N/A'}
          </Typography>
        </>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'inWardQty',
      key: 'inWardQty',
      onCell: sharedOnCell,
      render: (value, record) => (
        <Typography className="small-text">
          {Number(value).toFixed(3) ?? 'N/A'}
        </Typography>
      ),
    },
    {
      title: 'Contract Price PMT',
      dataIndex: 'adjustedPriceValue',
      key: 'adjustedPriceValue',
      onCell: sharedOnCell,
      render: (text, record) => (
        <>
          <Text className="small-text">
            {internationalFormattedValue(record?.adjustedPriceValue, 2)}
          </Text>
          <span className="currency-style">{record?.baseCurrency}</span>
        </>
      ),
    },
    ...(showFreightPrice()
      ? [
        {
          title: 'Freight Price',
          dataIndex: 'freightPricePmt',
          key: 'freightPricePmt',
          onCell: sharedOnCell,
          render: (text, record) => (
            <>
              <Text className="small-text">
                {internationalFormattedValue(record?.freightPricePmt, 2)}
              </Text>
              <span className="currency-style">{record?.baseCurrency}</span>
            </>
          ),
        },
      ]
      : []),
    {
      title: 'Total Payable',
      dataIndex: 'totalPayable',
      key: 'totalPayable',
      onCell: sharedOnCell,
      render: (text, record) => (
        <>
          <Text className="small-text">
            {internationalFormattedValue(record?.totalPayable, 2)}
          </Text>
          <span className="currency-style">
            {record?.quoteCurrency || record?.baseCurrency}
          </span>
        </>
      ),
    },
    {
      title: 'Advance',
      dataIndex: 'advAdjustment',
      key: 'advAdjustment',
      onCell: sharedOnCell,
      render: (text, record) => (
        <>
          <Text className="small-text">
            {internationalFormattedValue(record?.advAdjustment, 2)}
          </Text>
          <span className="currency-style">
            {record?.quoteCurrency || record?.baseCurrency}
          </span>
        </>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'remaining',
      key: 'remaining',
      onCell: sharedOnCell,
      render: (text, record) => (
        <>
          <Text className="small-text">
            {internationalFormattedValue(record?.remaining, 2)}
          </Text>
          <span className="currency-style">{record?.quoteCurrency}</span>
        </>
      ),
    },
  ];

  let transactionData = data?.transactionBarges || data?.costingTransactions;

  return (
    <>
      <Layout style={{ backgroundColor: '#F5F5F5', border: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: 'none',
            marginBottom: '10px',
          }}
        >
          <Space size={5}>
            <a
              href={`/#/${pathToList}`}
              style={{ fontSize: '18px', color: '#6B7280' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
            <span
              style={{ fontSize: '16px', fontWeight: 600, color: '#323F49' }}
            >
              {data?.costingNo}
            </span>
            &nbsp;
            <StatusTag status={data?.approvalStatus} />
          </Space>
          <Space size={10}>
            {isPermissionExist([downloadPermission]) && (
              <PDFDownloadLink
                document={
                  <CostingInvoice
                    costingDetails={data}
                    costingName={referenceFeatures}
                    plantCode={authContext.defaultPlant}
                  />
                }
                fileName={`${data?.costingNo}.pdf`}
              >
                <Tooltip placement="topRight" title="Costing Invoice">
                  <FontAwesomeIcon
                    style={{
                      border: 'none',
                      fontSize: '15px',
                      padding: '7px',
                      borderRadius: '50%',
                      color: '#0295D4',
                      backgroundColor: '#DEECF3',
                    }}
                    icon={faDownload}
                    onClick={() => {
                      setDummyRefresh((prev) => prev + 1);
                    }}
                  />
                </Tooltip>
              </PDFDownloadLink>
            )}

            {[
              ContractStatusEnum.CANCELLED,
              ContractStatusEnum.DRAFT,
              ContractStatusEnum.PENDING_APPROVAL,
            ].includes(data?.approvalStatus) &&
              isPermissionExist([editPermission]) && (
                <EditFilled
                  style={{
                    border: 'none',
                    fontSize: '15px',
                    padding: '7px',
                    borderRadius: '50%',
                    color: '#0295D4',
                    backgroundColor: '#DEECF3',
                  }}
                  onClick={editHandler}
                />
              )}
            {isPermissionExist([viewInvoice]) && (
              <Button
                type="primary"
                htmlType="submit"
                className="new-button"
                onClick={() => {
                  setDrawerOpen(true);
                  setCostingId((prev) => {
                    return {
                      ...prev,
                      costingId: costingId,
                      referenceFeatures: referenceFeatures,
                      totalAmount: data.invoiceTotal || data.amount,
                      ...data,
                    };
                  });
                }}
              >
                Invoices
              </Button>
            )}
          </Space>
        </div>
        <Row gutter={8}>
          <Col span={16} xs={24} md={24} lg={16}>
            {/* add new xs={24} md={24} lg={16} */}
            <Col span={10} style={{ display: 'flex' }} xs={24} md={24} lg={24}>
              <Card
                title={`${title}`}
                headStyle={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#323F49',
                  border: 'none',
                  paddingTop: '10px',
                }}
                bordered={false}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                }}
              >
                <Row>
                  <Col span={16} xs={24} md={24} lg={24}>
                    <Descriptions
                      className="descriptions-label"
                      title=""
                      column={{
                        xxl: 6,
                        xl: 6,
                        lg: 6,
                        md: 3,
                        sm: 1,
                        xs: 1,
                      }}
                      layout="vertical"
                      colon={false}
                    >
                      {costingType === CostingTypesEnum.PERFORMA && (
                        <Descriptions.Item label={'Business Number'}>
                          <span className="descriptions">
                            <a
                              style={{ color: '#0083C9' }}
                              href={`/#/bn-detail-view?bn_Id=${data?.businessNumber}`}
                            >
                              {data?.bnId ? data?.bnId : '--'}
                            </a>
                          </span>
                        </Descriptions.Item>
                      )}
                      {costingType ===
                        CostingTypesEnum.SALE_FREIGHT_COSTING && (
                          <Descriptions.Item label={'Business Number'}>
                            <span className="descriptions">
                              <a
                                style={{ color: '#0083C9' }}
                                href={`/#/bn-detail-view?bn_Id=${data?.businessNumber}`}
                              >
                                {data?.bnId ? data?.bnId : '--'}
                              </a>
                            </span>
                          </Descriptions.Item>
                        )}
                      {costingType === CostingTypesEnum.COMMERCIAL && (
                        <Descriptions.Item label={'Business Number'}>
                          <span className="descriptions">
                            <a
                              style={{ color: '#0083C9' }}
                              href={`/#/bn-detail-view?bn_Id=${data?.bnId}`}
                            >
                              {data?.businessNumber
                                ? data?.businessNumber
                                : '--'}
                            </a>
                          </span>
                        </Descriptions.Item>
                      )}
                      {costingType === CostingTypesEnum.PROCUREMENT_FEES && (
                        <Descriptions.Item label={'Business Number'}>
                          <span className="descriptions">
                            <a
                              style={{ color: '#0083C9' }}
                              href={`/#/bn-detail-view?bn_Id=${data?.bnId}`}
                            >
                              {data?.businessNumber
                                ? data?.businessNumber
                                : '--'}
                            </a>
                          </span>
                        </Descriptions.Item>
                      )}
                      <Descriptions.Item label="Coal Supplier">
                        <span className="descriptions">{data?.bpName}</span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Laycan" span={2}>
                        <span className="descriptions">
                          {`${moment(data?.laycanFromDate).format(
                            'DD-MMM-YYYY',
                          )} to ${moment(data?.laycanToDate).format(
                            'DD-MMM-YYYY',
                          )} - (${data?.quality})`}
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Purchase Type">
                        <span className="descriptions">{data?.incoterm}</span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Transaction Date">
                        <span className="descriptions">
                          {`${moment(data?.costingDate).format('DD-MMM-YYYY')}`}
                        </span>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Card>
            </Col>
            {costingType !== CostingTypesEnum.PROCUREMENT_FEES &&
              costingType !== CostingTypesEnum.MARKETING_FEES && (
                <Col style={{ margin: '12px 0px ' }}>
                  <Card
                    className="card-shadow"
                    styles={{ body: { padding: '0px 10px 15px' } }}
                    style={{ marginBottom: 8 }}
                    title={<h6>Barges</h6>}
                  >
                    <Table
                      className="contracts-list"
                      dataSource={bargeDetails}
                      columns={columns}
                      pagination={false}
                      scroll={{ x: 1200 }}
                      footer={footer}
                    />
                  </Card>
                </Col>
              )}
            {(costingType === CostingTypesEnum.PROCUREMENT_FEES ||
              costingType === CostingTypesEnum.MARKETING_FEES) && (
                <Col style={{ margin: '12px 0px ' }}>
                  <Card
                    className="card-shadow"
                    styles={{ body: { padding: '0px 10px 15px' } }}
                    style={{ marginBottom: 8 }}
                    title={<h6>Barges</h6>}
                  >
                    <Table
                      className="contracts-list"
                      dataSource={bargeDetails}
                      columns={columns4}
                      pagination={false}
                      scroll={{ x: 1200 }}
                      footer={footer}
                    />
                  </Card>
                </Col>
              )}

            {/* {qualityData?.length > 0 && ( */}
            <Col style={{ margin: '12px 0px ' }}>
              <QualityPriceAdjustmentsDeatils
                contractType={contractType}
                selectedRowData={bargeDetails[0]}
                formInitialValues={data}
              />
            </Col>
            {/* // )} */}

            <Col style={{ margin: '12px 0px ' }}>
              <Card
                className="card-shadow"
                styles={{ body: { padding: '0px 10px 15px' } }}
                style={{ marginBottom: 8 }}
                title={<h6>Advance Adjustment</h6>}
              >
                <Table
                  className="contracts-list"
                  dataSource={advanceAdjustment}
                  columns={columns2}
                  pagination={false}
                />
              </Card>
            </Col>
          </Col>

          <Col span={8} xs={24} md={24} lg={8}>
            {(costingType === CostingTypesEnum.PROCUREMENT_FEES ||
              costingType === CostingTypesEnum.MARKETING_FEES) && (
                <Card
                  className="card-shadow"
                  title={<span className="card-title-md">Price Details</span>}
                  styles={{ body: { padding: '6px 14px' } }}
                >
                  {bargeDetails?.length > 0 && (
                    <Flex justify="space-between" align="center">
                      <Text className="price-card-text">Currency Type</Text>
                      <Text className="price-card-text">
                        {' '}
                        {bargeDetails[0]?.currencyType ?? 'N/A'}
                      </Text>
                    </Flex>
                  )}

                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Exchange Date</Text>
                    <Text className="price-card-text">
                      {' '}
                      {data?.exchangeDate
                        ? moment(data?.exchangeDate)?.format('DD-MM-YYYY')
                        : 'N/A'}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Exchange Rate</Text>
                    <Text className="price-card-text">
                      {data?.exchangeRate} {data?.quoteCurrency}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">
                      Price After Exchange Rate
                    </Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.exchangeRateValue, 3)}{' '}
                      {data?.quoteCurrency}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Total Coal Price</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.adjustedPriceValue, 3)}{' '}
                      {data?.quoteCurrency}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">FC Charges</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.fcCharges, 3)}{' '}
                      {data?.quoteCurrency}
                    </Text>
                  </Flex>

                  <Divider dashed={true} />

                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Tax 1</Text>
                    <Text className="price-card-text">{`${data?.taxName1 || 'N/A'
                      } (${data?.taxPercentage1 || '0'})`}</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.taxTotal1, 3)}{' '}
                      {data?.quoteCurrency}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Tax 2</Text>
                    <Text className="price-card-text">{`${data?.taxName2 || 'N/A'
                      } (${data?.taxPercentage2 || '0'})`}</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.taxTotal2, 3)}{' '}
                      {data?.quoteCurrency}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">TDS</Text>
                    <Text className="price-card-text">{`${data?.tdsName || 'N/A'
                      } (${data?.tdsPercentage || '0'})`}</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.tdsTotal, 3)}{' '}
                      {data?.quoteCurrency}
                    </Text>
                  </Flex>

                  <Divider dashed={true} />

                  <Flex justify="space-between" align="center">
                    <Text className="price-card-heading">Total Amount</Text>
                    <Text className="price-card-heading">
                      {internationalFormattedValue(data?.invoiceTotal, 3)}{' '}
                      {data?.quoteCurrency}
                    </Text>
                  </Flex>

                  {data?.advanceTarnsactionsData?.map((adv) => (
                    <Flex justify="space-between" align="center" key={adv.id}>
                      <Text className="price-card-text">{`Deduction (${adv.adjustedCostingNo}) `}</Text>
                      <Text className="price-card-text">
                        {internationalFormattedValue(adv?.totalAdjustedAmount, 3)}{' '}
                        {data?.quoteCurrency}
                      </Text>
                    </Flex>
                  ))}

                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">
                      Total Advance Adjustment
                    </Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(advTotal, 3)}{' '}
                      {data?.quoteCurrency}
                    </Text>
                  </Flex>

                  <Flex justify="space-between" align="center">
                    <Text className="price-card-green">
                      To be paid to Supplier
                    </Text>
                    <Text className="price-card-green">
                      {internationalFormattedValue(data?.invoiceTotal, 3)}{' '}
                      {data?.quoteCurrency}
                    </Text>
                  </Flex>

                  {/* 
              {Number(remainingAmount) > 0 &&

                  <Flex justify="space-between" align='center'>
                      <Text className="price-card-red">Remaining Amount</Text>
                      <Text className="price-card-red">
                          <CountUp
                              start={0}
                              end={Number(remainingAmount)}
                              duration={2.75}
                              decimals={3}
                              suffix={` ${data?.quoteCurrency}`} />
                      </Text>
                  </Flex>
              } */}
                </Card>
              )}

            {data?.includeFreight && (
              <Card
                className="card-shadow"
                title={
                  <span className="card-title-md">Freight Price Details</span>
                }
                styles={{ body: { padding: '6px 14px' } }}
                style={{ marginBottom: 8 }}
              >
                <Flex justify="space-between" align="center">
                  <Text className="price-card-text">Exchange Date</Text>
                  <Text className="price-card-text">
                    {' '}
                    {data?.exchangeDate
                      ? moment(data?.exchangeDate)?.format('DD-MM-YYYY')
                      : 'N/A'}
                  </Text>
                </Flex>
                <Flex justify="space-between" align="center">
                  <Text className="price-card-text">Exchange Rate</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.exchangeRate, 3)}{' '}
                    {data?.billingCurrency}
                  </Text>
                </Flex>
                <Flex justify="space-between" align="center">
                  <Text className="price-card-text">Freight Pmt</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.freightPricePmt, 3)}{' '}
                    {data?.baseCurrency}
                  </Text>
                </Flex>
                <Flex justify="space-between" align="center">
                  <Text className="price-card-heading">Total Freight</Text>
                  <Text className="price-card-heading">
                    {internationalFormattedValue(data?.freightTotalAmt, 3)}{' '}
                    {data?.billingCurrency}
                  </Text>
                </Flex>
                <Divider dashed={true} />

                <Flex justify="space-between" align="center">
                  <Text className="price-card-text">Tax 1</Text>
                  <Text className="price-card-text">{`${data?.freightTaxName1 || ''
                    } (${data?.freightTaxPerc1 || ''})`}</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.freightTaxTotal1, 3)}{' '}
                    {data?.billingCurrency}
                  </Text>
                </Flex>
                <Flex justify="space-between" align="center">
                  <Text className="price-card-text">Tax 2</Text>
                  <Text className="price-card-text">{`${data?.freightTaxName2 || ''
                    } (${data?.freightTaxPerc2 || ''})`}</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.freightTaxTotal2, 3)}{' '}
                    {data?.billingCurrency}
                  </Text>
                </Flex>
                <Flex justify="space-between" align="center">
                  <Text className="price-card-text">TDS</Text>
                  <Text className="price-card-text">{`${data?.freightTdsName || ''
                    } (${data?.freightTdsPerc || ''})`}</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.freightTdsTotal, 3)}{' '}
                    {data?.billingCurrency}
                  </Text>
                </Flex>

                <Divider dashed={true} />

                <Flex justify="space-between" align="center">
                  <Text className="price-card-green">Total Freight</Text>
                  <Text className="price-card-green">
                    {internationalFormattedValue(data?.freightCostingTotal, 3)}{' '}
                    {data?.billingCurrency}
                  </Text>
                </Flex>
              </Card>
            )}

            {costingType !== CostingTypesEnum.PROCUREMENT_FEES &&
              costingType !== CostingTypesEnum.MARKETING_FEES && (
                <Card
                  className="card-shadow"
                  title={
                    <span className="card-title-md">Price Adjustment</span>
                  }
                  styles={{ body: { padding: '6px 14px' } }}
                >
                  {bargeDetails.length && (
                    <Flex justify="space-between" align="center">
                      <Text className="price-card-text">Currency Type</Text>
                      <Text className="price-card-text">
                        {' '}
                        {bargeDetails[0]?.currencyType ?? 'N/A'}
                      </Text>
                    </Flex>
                  )}
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Exchange Date</Text>
                    <Text className="price-card-text">
                      {' '}
                      {data?.exchangeDate
                        ? moment(data?.exchangeDate)?.format('DD-MM-YYYY')
                        : 'N/A'}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Exchange Rate</Text>
                    <Text className="price-card-text">
                      {data?.exchangeRate} {billingCurrency}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Total</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.costingsTotal, 3)}{' '}
                      {billingCurrency}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-heading">Total Amount</Text>
                    <Text className="price-card-heading">
                      {internationalFormattedValue(data?.totalPayable, 3)}{' '}
                      {billingCurrency}
                    </Text>
                  </Flex>
                  <Divider dashed={true} />

                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Tax 1</Text>
                    <Text className="price-card-text">{`${data?.taxName1 || ''
                      } (${data?.taxPercentage1 || ''})`}</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.taxTotal1, 3)}{' '}
                      {billingCurrency}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">Tax 2</Text>
                    <Text className="price-card-text">{`${data?.taxName2 || ''
                      } (${data?.taxPercentage2 || ''})`}</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.taxTotal2, 3)}{' '}
                      {billingCurrency}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">TDS</Text>
                    <Text className="price-card-text">{`${data?.tdsName || ''
                      } (${data?.tdsPercentage || ''})`}</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(data?.tdsTotal, 3)}{' '}
                      {billingCurrency}
                    </Text>
                  </Flex>

                  <Divider dashed={true} />

                  {data?.advanceTarnsactionsData?.map((adv) => (
                    <Flex justify="space-between" align="center" key={adv.id}>
                      <Text className="price-card-text">{`Deduction (${adv.adjustedCostingNo}) `}</Text>
                      <Text className="price-card-text">
                        {internationalFormattedValue(
                          adv?.totalAdjustedAmount,
                          3,
                        )}{' '}
                        {billingCurrency}
                      </Text>
                    </Flex>
                  ))}

                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">
                      Total Advance Adjustment
                    </Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(advTotal, 3)}{' '}
                      {billingCurrency}
                    </Text>
                  </Flex>

                  {data?.advanceTarnsactionsData?.map((adv) => (
                    <Flex justify="space-between" align="center" key={adv.id}>
                      <Text className="price-card-text">{`Deduction (${adv.adjustedCostingNo}) `}</Text>
                      <Text className="price-card-text">
                        {Number(adv?.totalAdjustedAmount).toFixed(3)}{' '}
                        {billingCurrency}
                      </Text>
                    </Flex>
                  ))}

                  <Flex justify="space-between" align="center">
                    <Text className="price-card-text">
                      Total Advance Adjustment
                    </Text>
                    <Text className="price-card-text">
                      {Number(advTotal).toFixed(3)} {billingCurrency}
                    </Text>
                  </Flex>

                  <Flex justify="space-between" align="center">
                    <Text className="price-card-green">
                      To be paid to Supplier
                    </Text>
                    <Text className="price-card-green">
                      {costingType !== CostingTypesEnum.COMMERCIAL
                        ? internationalFormattedValue(data?.totalAmount, 3)
                        : internationalFormattedValue(data?.invoiceTotal, 3)}
                    </Text>
                  </Flex>

                  {/* {Number(remainingAmount) > 0 && */}

                  {/* <Flex justify="space-between" align='center'>
                  <Text className="price-card-red">Remaining Amount</Text>
                  <Text className="price-card-red">
                    <CountUp
                      start={0}
                      end={Number(0)}
                      duration={2.75}
                      decimals={3}
                      suffix={` ${billingCurrency}`} />
                  </Text>
                </Flex> */}
                  {/* } */}
                </Card>
              )}
          </Col>
        </Row>
        <Drawer
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
            setDummyRefresh((prev) => prev + 1);
          }}
          width={'1000'}
        >
          <AddInvoice costingData={costingIds} accessId={accessId} />
        </Drawer>
      </Layout>
    </>
  );
};

export default PerformaDetailView;
