import { ContractStatusEnum, ContractTypesEnum, PreviewRequests, PriceTypeEnum, ProcurementContractTypeEnum, VesselAgreementAll, VesselAgreementDto, VesselAgreementPreviewDto, VesselAgreementService } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Affix, Button, Card, Col, Descriptions, Divider, Form, Layout, Row, Space, Table, TableColumnProps, Tabs, Tooltip } from 'antd';
import Link from 'antd/es/typography/Link';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../../common';
import PaymentTermsView from '../../../contracts/view-components/payment-terms-view';
import { EditFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faCircleXmark, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import TabPane from 'antd/es/tabs/TabPane';

interface IProps {
  contractTypeCategory?: ProcurementContractTypeEnum;
}


export const VesselAgreementDetailedView = (props: IProps) => {
  const [gridData, setGridData] = useState<any>();
  const [snapData, setSnapData] = useState<any[]>([]);
  const [filesFormRef] = Form.useForm();
  const { authContext } = useAuthState();
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState<number>(0);

  const location = useLocation();
  const paramId = queryString.parse(location.search)?.va_Id;
  console.log('paramId', paramId)
  let { agreementId }: any = paramId != null ? { agreementId: paramId } : { agreementId: null };

  const navigate = useNavigate()

  let pcUpdatePath = '/vesselAgreement-update';
  let detailViewPath = '/vesselAgreement-detailed-view';
  let vesselAddendum = '/vesselAgreement-addendum';

  const service = new VesselAgreementService();

  useEffect(() => {
    getAgreementPreviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementId]);

  const getAgreementPreviewData = () => {
    const req = new PreviewRequests(agreementId, authContext.defaultPlant)
    service.getAgreementPreviewData(req).then(res => {
      if (res.status) {
        setGridData(res.data);
        setSnapData(res.snapData);
        setOpenModal(false) 
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        setOpenModal(false)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setOpenModal(false)
    })
  };

  const sendForApproval = () => {
    service.sendForApproval({ baId: agreementId, plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAgreementPreviewData();
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const addendumClickHandler = () => {
    const link = `${vesselAddendum}?va_Id=${agreementId}`
    navigate(link, { state: { record: {...gridData, action:'addendum'} } })
  }

  console.log('gridData', gridData)

  const approveOrRejectHandler = (agreementId: string, status: ContractStatusEnum) => {
    const req = new VesselAgreementPreviewDto(agreementId, authContext.defaultPlant, authContext.user.userName, status, gridData.contractType)
    console.log('req', req);
    // return
    service.approveOrRejectAgreement(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAgreementPreviewData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button className='send-approval-button' onClick={() => sendForApproval()}><FontAwesomeIcon icon={faPaperPlane} />Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <span>
        <Button size="small" className='approve' onClick={() => {
          approveOrRejectHandler(agreementId, ContractStatusEnum.ACTIVE);
        }}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button> &nbsp;&nbsp;
        <Button size="small" className='reject' onClick={() => {
          approveOrRejectHandler(agreementId, ContractStatusEnum.DRAFT); 
        }}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
      </span>
    }
    if (status === ContractStatusEnum.ACTIVE && gridData?.contractType === ContractTypesEnum.ORIGINAL) {
      return <Button className='new-button' onClick={() => addendumClickHandler()}>Create Addendum</Button>
    }
  }


  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => { 
      getFilesData(fileValues.filesData).then(filesData => {
        service.addNewFiles({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          vaId: agreementId
        }).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            getAgreementPreviewData();
            filesFormRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      });
    });
  }

  const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
    for (const snapDate of snapData) {
      let valueAtGivenKey: any = snapDate;
      for (const key of keys) {
        valueAtGivenKey = valueAtGivenKey?.[key];
      }
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (gridData.contractType === ContractTypesEnum.ADDENDUM) {
          if (isRawData) {
            return value;
          } else {
            return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapDate.fixtureNoteNo.replace('SNP', 'ADD')}><b>
              {value}
            </b></Tooltip></>
          }
        }
      }
    };
    return value
  }

  const valiDateChildWithSnapShotChild = (index: number, key: string, value: string): any => {
    let valueAtGivenKey: any = snapData?.[0]?.specPriceData?.[index]?.[key];
    if (valueAtGivenKey) {
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (gridData.contractType === ContractTypesEnum.ADDENDUM) {
          console.log('came', key, value, valueAtGivenKey)
          return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapData?.[0]?.fixtureNoteNo?.replace('SNP', 'ADD')}><b>
            {value}
          </b></Tooltip></>
        }
      };
    }
    return value;//'hhhhhhhhhhhh'
  }


  const paymentTerms = { lc: gridData?.lc, others: gridData?.others, telegraphicTransfer: gridData?.telegraphicTransfer, lcValue: gridData?.lcValue, lcType: gridData?.lcType, lcCurrency: gridData?.lcCurrency, paymentMode: gridData?.paymentMode }
  const columns: TableColumnProps<any>[] = [
    {
      title: 'Laycan',
      dataIndex: 'layCan',
      sorter: (a, b) => (a.layCan?.[0] ? moment(a.layCan[0]).valueOf() : 0) - (b.layCan?.[0] ? moment(b.layCan[0]).valueOf() : 0),
      render: (value, record) => {
        return <>
          {value[0] === null || value[1] === null ? "-" : moment(value[0]).format('DD\u2011MMM\u2011YYYY') + " to " + moment(value[1]).format('DD\u2011MMM\u2011YYYY')}
        </>
      }
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vesselName',
      // align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'vesselName', value)}</span>
      ),
    },
    {
      title: 'Quantity MT',
      dataIndex: 'quantity',
      align: 'right',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'quantity', value)}</span>
      ),
    },
    {
      title: 'Tolerance',
      dataIndex: 'tolerance',
      // align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'tolerance', value)}%</span>
      ),
    },
    {
      title: 'Price Type ',
      dataIndex: 'priceType',
      // align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'priceType', value)}</span>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'freightPrice',
      align: 'right',
      render: (value, record, index) => {
        if (isPermissionExist([150, 159, 168])) {
          return <>
            {record.priceType === PriceTypeEnum.LUMP_SUM ? valiDateChildWithSnapShotChild(index, 'freightPrice', value) : record.priceType === PriceTypeEnum.PER_MT ? <>{valiDateChildWithSnapShotChild(index, 'freightPrice', record.freightPrice)} </> : undefined}
          </>
        } else return <></>

      }
    },
    {
      title: "VAT",
      dataIndex: 'vatInclOrExcl',
      // align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'vatInclOrExcl', value)}</span>
      ),

    },
    {
      title: "Load Port",
      dataIndex: 'loadPortName',
      // align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'loadPortName', value)}</span>
      ),

    },
    {
      title: "Load Rate",
      dataIndex: 'loadRate',
      align: 'right',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'loadRate', value)}</span>
      ),

    },
    {
      title: "Discharge Port",
      dataIndex: 'dischargePortName',
      // align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'dischargePortName', value)}</span>
      ),

    },
    {
      title: "Discharge Rate",
      dataIndex: 'dischargeRate',
      align: 'right',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'dischargeRate', value)}</span>
      ),

    },
    {
      title: "Demurrage Rate",
      dataIndex: 'demurrageRate',
      align: 'right',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'demurrageRate', value)}</span>
      ),

    },
    {
      title: "Dispatch Rate",
      dataIndex: 'dispatchRate',
      align: 'right',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'dispatchRate', value)}</span>
      ),

    },

  ];

  const remarks: TableColumnProps<any>[] = [
    {
      title: "Remarks",
      dataIndex: "remarks"
    }
  ]

  const link = `/#/detailCustomer?bp_id=${gridData?.vesselVendorId}`

  return (
    <>
      <div className='layout'>
          <Row justify='space-between' className='mb-12' >
                <Space size={5}>
                    <a href='/#/vesselAgreement-grid' className='arrow-left'>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </a>
                    <span className='card-title-md'>Contract -  {(gridData?.fixtureNoteNo)}</span>&nbsp;
                    <StatusTag status={gridData?.status} />
                </Space>
                <Space size={10}>
                  {(gridData?.['status'] === ContractStatusEnum.DRAFT || gridData?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && (
                      <Button className='action-icons'
                        icon={<EditFilled />}
                        onClick={() => {
                          const link = `${pcUpdatePath}?va_Id=${agreementId}`
                          navigate(link, { state: { record: gridData } })
                        }}
                      
                            />
                    )}
                                  
                    {getExtraComponent(gridData?.status)}
                </Space>
          </Row>
          <Row gutter={[16, 12]} >
                <Col span={gridData?.addendumData?.length > 0 ? 14 : 24} className='flex width-full'
                    xs={24}
                    md={24}
                    lg={gridData?.addendumData?.length > 0 ? 14 : 24}
                >
                    <Card title={<span className='card-title-md'>Contract Details</span>} headStyle={{border: 'none', paddingTop: '10px'}} bordered={false} className='card-br-8 full-width'>
                        <Row>
                            <Col>
                                <Descriptions
                                className='descriptions'
                                    layout='vertical'
                                    colon={false}
                                >
                                    <Descriptions.Item label={"Vendor"}>
                                      <a href={link} className='link'>
                                          {valiDateWithSnapShotData(['vendor'], gridData?.vendorName, false)}
                                      </a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Contract Mode"}>
                                      {valiDateWithSnapShotData(['contractMode'], gridData?.contractMode, false)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Contract Date"}>
                                      {valiDateWithSnapShotData(['agreementDate'], gridData?.agreementDate ? moment(gridData?.agreementDate).format('DD\u2011MMM\u2011YYYY') : '', false)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Currency"}>
                                      <span className='currency'>
                                        {valiDateWithSnapShotData(['currency'], gridData?.currency, false)}
                                      </span>  
                                    </Descriptions.Item>
                                    {gridData?.contractType === ContractTypesEnum.ADDENDUM &&
                                    <Descriptions.Item label={ "Base Contract"} >
                                        <a className='link' href={`/#${detailViewPath}?va_Id=${gridData?.baseContractId}`}>
                                            {valiDateWithSnapShotData(['baseContractNo'], gridData?.baseContractNo, false)}
                                        </a>
                                    </Descriptions.Item> 
                                    }
                                </Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                { (gridData?.addendumData && gridData?.addendumData?.length > 0) &&
                    <Col className='flex'
                    xs={24}
                    md={24}
                    lg={10}
                    >
                        <Card title={<span className='card-title-md'>Addendums</span>} headStyle={{ border: 'none', paddingTop: '10px'}} bordered={false} className='card-br-8 full-width addendums-height' >
                            <Row className='mb-12'>
                                <Card 
                                    bordered={false} 
                                    headStyle={{ border: 'none' }} 
                                    size="small" 
                                    className='addendum-card'
                                >
                                    <Space size={16}>
                                    <Tooltip placement="top" title={gridData.addendumData[0].status}>
                                        <a 
                                        href={`/#${detailViewPath}?va_Id=${gridData.addendumData[0].agreementId}`} 
                                        className="link"
                                        >
                                        {gridData.addendumData[0].contractNo}
                                        </a>
                                    </Tooltip>
                                    <StatusTag status={gridData.addendumData[0].status} />
                                    </Space>
                                </Card>
                            </Row>

                            <Row gutter={[12, 12]}>
                                {gridData.addendumData.slice(1).reverse().map((rec, index) => (
                                  <Col>
                                <Card 
                                    bordered={false} 
                                    headStyle={{ border: 'none' }} 
                                    size="small" 
                                    className='addendum-card'
                                >
                                    <Space size={16}>
                                    <Tooltip placement="top" title={rec.status}>
                                        <a 
                                        href={`/#${detailViewPath}?va_Id=${rec.agreementId}`} 
                                        className="link"
                                        >
                                        {rec.contractNo}
                                        </a>
                                    </Tooltip>
                                    <StatusTag status={rec.status} />
                                    </Space>
                                </Card>
                                </Col>
                            ))}
                            </Row>
                        </Card>
                    </Col>
                }
          </Row>     
          <br />  
                    <Card title={<span className='card-title-md'>Laycan Details</span>} headStyle={{ border: 'none', paddingTop: '10px' }} bordered={false} className='card-br-8 full-width' >
                        <Table
                          className='contracts-list'
                          style={{overflowX: 'auto'}}
                          dataSource={gridData?.specPriceData ? gridData?.specPriceData : []}
                          columns={columns}
                          pagination={false} 
                        /> 
                    </Card>
                <br />
          <Row gutter={[16, 12]}>
                    <Col span={16} lg={16} md={24} xs={24}>
                <Card title="Additional terms" bordered={false} headStyle={{border: 'none', paddingTop: '10px'}} className='card-br-8 full-width'>
                    <Tabs tabPosition='left' tabBarStyle={{backgroundColor: '#F7F9FC'}}>
                        <TabPane tab="Payment Terms" key="1">
                        <PaymentTermsView paymentTerms={gridData?.paymentTerms} snapShotData={snapData} contractType={gridData?.contractType} />
                        </TabPane>
                        <TabPane tab="Remarks" key="2">
                        <Row>
                            <Col xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 24 }}
                            xl={{ span: 24 }}>
                            <Card title='Remarks' className='terms-card'  bordered={false} headStyle={{ border: 0 }} bodyStyle={{paddingTop: '2px'}}>
                            <Divider dashed className='divider'></Divider>
                            <pre>
                                {gridData?.remarks}
                                </pre>
                            </Card>
                            </Col>
                        </Row>
                        </TabPane>
                        <TabPane tab="Audit Information" key="3">
                        <Card title='User Details' className='terms-card' bordered={false} headStyle={{ border: 0 }} bodyStyle={{paddingTop: '2px'}}>
                        <Divider dashed className='divider'></Divider>
                        <Row>
                        <Col>
                        <Descriptions
                                column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                                >
                                <Descriptions.Item label="Created By" >
                                <span className='descriptions'>{gridData?.createdUser}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Approved By" >
                                <span className='descriptions'>{gridData?.approvedBy}</span>
                                </Descriptions.Item>
                                </Descriptions>
                                </Col>
                                <Col>
                                <Descriptions
                                column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                                >
                                <Descriptions.Item label="Created Date">
                                <span className='descriptions'>{gridData?.createdAt ? moment(gridData?.createdAt).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Approved Date">
                                <span className='descriptions'>{gridData?.approvedOn ? moment(gridData?.approvedOn).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}</span>
                                </Descriptions.Item>
                            </Descriptions>
                                </Col>
                                </Row>
                            </Card>
                        </TabPane>
                    </Tabs>
                </Card>
                </Col>
                <Col span={8} lg={8} md={24} xs={24} className='flex'>
                {gridData?.filesData && (
                        <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} formType='View' fileModal={openModal} setFileModal={setOpenModal} setRefresh={setRefresh}>
                            <Row justify="end">
                                <Col>
                                    {
                                        <Space>
                                            <Button className='new-button'
                                                onClick={fileUploadFormHandler}
                                                type="primary">
                                                Save
                                            </Button>
                                        </Space>
                                    }</Col>
                            </Row>
                        </FileUploadForm>)
                    }
                    </Col>
          </Row>       
      </div>

      {/* <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>
        <Card
          style={{ textAlign: 'center' }}
          className='default-card-class'
          title={<span style={{ color: 'white' }}> Contract -  {(gridData?.fixtureNoteNo)}
            <span style={{ paddingLeft: 20 }}> <StatusTag status={gridData?.status} />
            </span>
          </span>
          }
          extra={<>{getExtraComponent(gridData?.status)}</>}
        >
          <Row>
            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"Vendor"}><span><Link href={link}>
                  {valiDateWithSnapShotData(['vendor'], gridData?.vendorName, false)}
                </Link></span></Descriptions.Item>
              </Descriptions></Col>

            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"Contract Mode"}>
                  {valiDateWithSnapShotData(['contractMode'], gridData?.contractMode, false)}</Descriptions.Item>
              </Descriptions></Col>
            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"ContractDate"}>
                  {valiDateWithSnapShotData(['agreementDate'], gridData?.agreementDate ? moment(gridData?.agreementDate).format('DD/MM/YYYY h:mm A') : '', false)}</Descriptions.Item>
              </Descriptions></Col>
            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"Currency"}>
                  {valiDateWithSnapShotData(['currency'], gridData?.currency, false)}
                </Descriptions.Item>

              </Descriptions></Col>
            <Col span={8}>
              <Descriptions>

                <Descriptions.Item label={"Approved By"}>
                  {gridData?.approvedBy}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"Approved Date"}>
                  {gridData?.approvedOn ? moment(gridData?.approvedOn).format('DD-MM-YYYY h:mm A') : ''}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>

            <Descriptions>
              <Descriptions.Item label={gridData?.contractType === 'Addendum' ? 'Base Contract' : 'Addendum Data' }>
                {gridData?.addendumData?.map((rec) => {
                  const link = `/#${detailViewPath}?va_Id=${rec.agreementId}`
                  return <><a href={link} className="link-primary" >
                    {`${rec.contractNo} ,`}
                  </a></>
                })}
                {gridData?.contractType === 'Original' ? (
                  gridData?.addendumData?.map((rec) => {
                  const link = `/#${detailViewPath}?va_Id=${rec.agreementId}`;
                    return (
                      <a href={link} className="link-primary" key={rec.agreementId}>
                        {`${rec.contractNo} ,`}
                      </a>
                    );
                  })
                ) : (
                  (() => {
                    const link = `/#${detailViewPath}?va_Id=${gridData?.baseContractId}`;
                      return (
                        <a href={link} className="link-primary">
                          {gridData?.baseContractNo}
                        </a>
                      );
                    }
                  )()
                )}
              </Descriptions.Item>
            </Descriptions>
          </Row>

          <br />

          <Card>
            <Table
              dataSource={gridData?.specPriceData ? gridData?.specPriceData : []}
              columns={columns}
              pagination={false} />
          </Card>

          <Card >
          <PaymentTermsView paymentTerms={gridData?.paymentTerms} snapShotData={snapData} contractType={gridData?.contractType} />
          </Card>
          <Card>
            <Table dataSource={Array(gridData)} columns={remarks}
              pagination={false} /></Card>

          {gridData?.filesData &&
            <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} >
              <Row justify="end">
                <Col>
                  {
                    <Space>
                      <Button
                        onClick={fileUploadFormHandler}
                        type="primary">
                        Save and Next
                      </Button>
                    </Space>
                  }</Col>
              </Row>
            </FileUploadForm>
          }
        </Card>
        <Affix offsetBottom={0}>
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>
              <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
              <Col>{(gridData?.['status'] === ContractStatusEnum.DRAFT || gridData?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && <Button onClick={() => {
                const link = `${pcUpdatePath}?va_Id=${agreementId}`
                navigate(link, { state: { record: gridData } })
              }}>Edit</Button>}</Col>
            </Row>
          </Card>
        </Affix>
      </Layout> */}
    </>
  );

}
export default VesselAgreementDetailedView;