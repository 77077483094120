import { GroupEntryDto, GroupEntryIdRequest, GroupEntryService } from '@exportx/shared-models-and-services';
import { Button, Card, Drawer, Input, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { EditOutlined, DeleteOutlined, FileExcelFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { Excel } from 'antd-table-saveas-excel';
import { AlertMessages, isPermissionExist } from '../../../common';

export const GroupEntryGrid = () => {
  const navigate = useNavigate();
  const [gridData, setGridData] = useState([]);
  const { formatMessage: fm } = useIntl();
  const pathToreDirect = '/create-account-group';
  const [searchData, setSearchData] = useState("")

  const service = new GroupEntryService();

  useEffect(() => {
    if(!isPermissionExist([785])) navigate('/')
    getAll();
  }, []);
  const getAll = () => {
    service.getAllGroupEntry().then(res => {
      if (res.status) {
        setGridData(res.data)
      } else {
        if (res.errorCode !== 99998) {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const deleteGroupEntry = (groupEntryData: GroupEntryIdRequest) => {
    const req = new GroupEntryIdRequest(groupEntryData.actGrpId)
    service.deleteGroupEntry(req).then(res => {
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage('Delete Group Entry Successfully');
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const handleExport = (e: any) => {
    e.preventDefault();
    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("/");
    let cloneArr = Columns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
    });

    const excel = new Excel();
    excel
      .addSheet("Sheet1")
    excel.addRow();
    excel.addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`AccountCreation-${currentDate}.xlsx`);
  };

  const Columns: any = [
    {
      title: 'Sub Group',
      dataIndex: 'actGrpName',
      width: 130,
      sorter: (a, b) => a.actGrpName.localeCompare(b.actGrpName),
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.actGrpName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.slNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.actGrpMainGroupName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.action)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
    },
    {
      title: 'Main Group',
      dataIndex: 'actGrpMainGroupName',
      width: 130,
      sorter: (a, b) => a.actGrpMainGroupName.localeCompare(b.actGrpMainGroupName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 130,
      render: (text, rowData) => (
        <span>
          {isPermissionExist([786]) && (<Tooltip placement="topRight" title="Edit">
            <EditOutlined className={'editSamplTypeIcon'} type="edit" style={{ color: '#1890ff', fontSize: '20px' }}
              onClick={() => {
                navigate(pathToreDirect, { state: { groupEntryData: rowData, isUpdate: true } });
              }}

            /></Tooltip>)}
          {isPermissionExist([787]) && (<Tooltip placement="topRight" title="Delete">
            <DeleteOutlined type='delete' onClick={() => deleteGroupEntry(rowData)} style={{ color: 'red', fontSize: '20px' }} />
          </Tooltip>)}
        </span>
      )

    }
  ]
  return (
    <div>
      <Card title={<span>Group Entry </span>}
        extra={<>{isPermissionExist([784]) && (<Button type="primary" onClick={() => { navigate(pathToreDirect) }}>Create</Button>)}</>}>
        {isPermissionExist([788]) && (<Tooltip placement="topRight" title="Export As Excel">
          <Button
            style={{ float: "right" }}
            size='small'
            type="default"
            onClick={handleExport}
            icon={<FileExcelFilled />}
          >Export</Button>
        </Tooltip>)}
        <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />
        <Table
          columns={Columns}
          dataSource={gridData}

        />

      </Card>
    </div>
  )
}

export default GroupEntryGrid;