import { BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, PortTypeEnum, ReportsService, StatusEnum } from "@exportx/shared-models-and-services";
import { Button, Card, Descriptions, Divider, Flex, Form, Input, Modal, Popover, Select, Space, Table, TableColumnsType, Tooltip, Typography, } from "antd";
import { useEffect, useState } from "react";
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from "../../../common";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CloseOutlined, CheckCircleOutlined, InfoCircleOutlined, EditFilled, EditOutlined, SaveTwoTone } from "@ant-design/icons";

const LTCReports = () => {
  const navigate = useNavigate() 
  const [formRef] = Form.useForm();
  const { Option } = Select;
  const { authContext } = useAuthState();
  const [LTCReports, setLTCReports] = useState([])
  const [edit,setEdit] = useState(false)
  const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
  const [selectedBusinessNo, setSelectedBusinessNo] = useState(null);
  const [displayCount, setDisplayCount] = useState(10); 
  const [displayedData, setDisplayedData] = useState([]); 
  const [open, setOpen] = useState<number | null>(null)

  let limitedData;

  const bnService = new BusinessNumberService()
  const businessService = new BusinessNumberService();
  const reportsService = new ReportsService();

  useEffect(()=> {
    if(!isPermissionExist([892])){
      return navigate('/')
    }
  }, [])


  useEffect(()=>{
    getAllLTCReports()
    getBusinessNoDropDown()
  },[])

  


    const getBusinessNoDropDown = () => {
        const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
        businessService.getBusinessNoDropDown(req).then(res => {
          if (res.status) {
            let dropdown = res.data.map(item => {
              let bnNo = item.businessNo.split(" ").pop()
              return {
                ...item,
                bnNo: bnNo
              }
            })
            setBusinessDropDown(dropdown)
          } else {
            console.log(res.internalMessage)
          }
        }).catch(err => {
          console.log(err.message)
        })
    }

  const getAllLTCReports = () => {
    bnService.getAllLTCReports({ plantCode: authContext.defaultPlant }).then(res => {
      if(res.status){
        setLTCReports(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const currentDate = new Date()
  const formattedDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');

  const handleSubmit = () => {

    const saveData = displayedData
    .filter(item => item.isUpdated === true || item.isNewRecord === true)
    .map(item => ({
      ...item,
      updatedBy: authContext.user.userName,
      updatedAt: formattedDate,
      createdBy: authContext.user.userName,
      createdAt: formattedDate,
      plantCode: authContext.defaultPlant,
      approvalLevel: item.managementApprovalStatus
    }));

    reportsService.createLTCReport(saveData).then(res => {
      if(res.status){
        getAllLTCReports()
        setEdit(null)
        AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }


  const handleClick = (index) => {
    setEdit(index)
  };

  
  const ltcReportsData = (qualities: any[]) => {
    const ltcData = [];

    qualities?.forEach((rec) => {
    rec.contractData?.forEach((contractRecord, index) => {
      ltcData.push({
            id: contractRecord.id ? contractRecord.id : '',
            client: contractRecord.client,
            bpName: contractRecord.bpName,
            contractId: contractRecord.contractId,
            contractNo: contractRecord.contractNo,
            fileStatus: contractRecord.fileStatus,
            comment: contractRecord.comment,
            contractTypeId: contractRecord.contractTypeId,
            incoterm: contractRecord.incoterm,
            qualitiesId: contractRecord.qualitiesId,
            loadingRate: contractRecord.loadingRate,
            dischargeRate: contractRecord.dischargeRate,
            laycan: contractRecord.laycan,
            bgDemurrageId: contractRecord.bgDemurrageId,
            costingType: contractRecord.costingType,
            businessApprovalStatus: contractRecord.businessApprovalStatus,
            approvedBy: contractRecord.approvedBy,
            approvedDate: contractRecord.approvedDate,
            managementApprovalStatus: contractRecord.managementApprovalStatus,
            managementApprovedAt: contractRecord.managementApprovedAt,
            managementApprovedBy: contractRecord.managementApprovedBy,
            remarks: contractRecord.remarks,
            allocationId: contractRecord.allocationId,
            ltcApplicable: contractRecord.ltcApplicable,
            portType: contractRecord.portType,
            totalAmount: contractRecord.totalAmount,
            completedLoadTime: contractRecord.completedLoadTime,
            allowedTimeToLoad: contractRecord.allowedTimeToLoad,
            noOfDifference: contractRecord.noOfDifference,
            demurrage: contractRecord.demurrage,
            dispatch: contractRecord.dispatch,
            rowSpan: index === 0 ? rec.contractData.length : 0,
            bnId: rec.bnId,
            businessNo: rec.businessNo,
            mvName: rec.mvName,
            mvQty: rec.mvQty,
            blDates: rec.blDates,
            });
        });
      });
    return ltcData;
  };


  const handleLoadMore = () => {
    setDisplayCount(prevCount => prevCount + 10);
  };

  const handleBusinessNoChange = (value) => {
    setSelectedBusinessNo(value);
  };
  

  const filteredData = selectedBusinessNo
    ? ltcReportsData(LTCReports).filter(
        (item) => item.bnId === selectedBusinessNo
      )
    : ltcReportsData(LTCReports);

    limitedData = LTCReports.slice(0, displayCount);
    let data = ltcReportsData(limitedData);
    
    useEffect(() => {
      if(!selectedBusinessNo){
        setDisplayedData([...data]);
      } else {
        setDisplayedData([...filteredData]);
      }
    },[LTCReports,displayCount,selectedBusinessNo])


    const handleChange = (value: any, key: string, index: number) => {
      const newValue = value && value.target ? value.target.value : value;
    
      const newData = [...displayedData];
      newData[index][key] = newValue;
      if(newData[index].id){
        newData[index].isUpdated = true;
      } else {
        newData[index].isNewRecord = true;
      }
      setDisplayedData(newData);
    };


  const columns: any = [
      {
        title: 'Business Number',
        dataIndex: 'businessNo',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text, record) => {
          const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
          return <><a className="link" href={link}>{text} ({record.mvName})</a></>
        }
      },
      {
        title: 'Client',
        dataIndex: 'client',
      },
      {
        title: 'Business Partner',
        dataIndex: 'bpName',
      },
      {
        title: 'Contract No',
        dataIndex: 'contractNo',
      },
      {
        title: 'Incoterm',
        dataIndex: 'incoterm',
      },
      {
        title: 'Load Rate',
        children: [
          {
            title: 'Load Port',
            dataIndex: 'loadingRate',
            render: (value, record) => {
              return <span>{Math.round(value)}</span>
            }
          },
          {
            title: 'Discharge Port',
            dataIndex: 'dischargeRate',
            render: (value, record) => {
              return <span>{Math.round(value)}</span>
            }
          },
        ]
      },
      {
        title: 'Laycan',
        dataIndex: 'laycan',
        width: 180,
        render: (text, record) => {
          if (record.laycan) {
            const [fromDate, toDate] = record.laycan.split(' - ');
            if (fromDate && toDate) {
              return (
                <div>
                  {moment(fromDate).isValid() && moment(toDate).isValid()
                    ? `${moment(fromDate).format('DD-MMM-YYYY')} to ${moment(toDate).format('DD-MMM-YYYY')}`
                    : null}
                </div>
              );
            }
          } else {
            return null
          }
        }
      },
      {
        title: 'Load Port',
        children: [
          {
            title: 'Demurrage Rate/Dispatch Rate',
            dataIndex: 'demurrage',
            render: (text,record) => {
               return text && record.portType !== PortTypeEnum.DISCHARGE_PORT ? <>{text} / {record.dispatch}</> : null
            }
          }
        ]
      },
      {
        title: 'Discharge Port',
        children: [
          {
            title: 'Demurrage Rate/Dispatch Rate',
            dataIndex: 'dispatch',
            render: (text,record) => {
              return record.portType === PortTypeEnum.DISCHARGE_PORT ? <>{text} / {record.demurrage}</> : null 
           }
          }
        ]
      },
      {
        title: 'Stowage',
        dataIndex: 'stowage',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text,record) => {
          let totalHetchQty = record.mvQty.reduce((a, c) => a + Number(c.hatchQty), 0)
          return totalHetchQty ? totalHetchQty : null
        }
      },
      {
        title: 'Loaded',
        dataIndex: 'stowage',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text,record) => {
          let finalQty = record.mvQty.reduce((a, c) => a + Number(c.finalQty), 0)
          return finalQty ? finalQty : null
        }
      },
      {
        title: 'Dead Freight',
        dataIndex: 'mvQty',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text,record) => {
          let totalHetchQty = record.mvQty.reduce((a, c) => a + Number(c.hatchQty), 0)
          let finalQty = record.mvQty.reduce((a, c) => a + Number(c.finalQty), 0)
          return (totalHetchQty - finalQty) ? (totalHetchQty - finalQty) : null
        }
      },
      {
        title: 'BL Date',
        dataIndex: 'blDates',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text, record) => {
           if(record.blDates && record.blDates !== null){
            let blDates = record.blDates?.split(',');
          return (
            <>
              {blDates.map((blDate, index) => (
                <div key={index}>
                  {moment(blDate).format('DD-MMM-YYYY')}
                </div>
              ))}
            </>
          );
           } else {
            return null
           }
        }
      },     
      {
        title: 'LTC Applicable',
        dataIndex: 'ltcApplicable',
        render: (text, record, index) => {
          return edit === index && record.bgDemurrageId ? (
            <Select
              style={{ width: '100%' }}
              placeholder="LTC Applicable"
              value={record.ltcApplicable ?? null}
              onChange={(e)=> handleChange(e,'ltcApplicable',index)}
              allowClear
            >
              <Option value={1}>YES</Option>
              <Option value={0}>NO</Option>
            </Select>
          ) : (
            text !== null ? text === 1 ? 'YES' : 'NO' : null
          )
        }
      },
      {
        title: 'LTC Status',
        dataIndex: 'costingType',
      },
      {
        title: 'Port Type',
        dataIndex: 'status',
        render: (text,record) => {
          return record.portType || null
        }
      },
      {
        title: 'DEM Days',
        dataIndex: '',
        render: (text, record) => {
          let demDays = record.completedLoadTime > record.allowedTimeToLoad ? record.noOfDifference : 0;
          return demDays
        }
      },
      {
        title: 'DES Days',
        dataIndex: '',
        render: (text, record) => {
          let desDays = record.completedLoadTime < record.allowedTimeToLoad ? record.noOfDifference : 0;
          return desDays
        }
      },
      {
        title: 'LTC Amount LP',
        dataIndex: 'totalAmount',
        render: (text,record) => {
          return record.portType !== PortTypeEnum.DISCHARGE_PORT  ? Number(record.totalAmount).toFixed(3) : null
        }
      },
      {
        title: 'LTC Amount DP',
        dataIndex: 'totalAmount',
        render: (text,record) => {
          return record.portType === PortTypeEnum.DISCHARGE_PORT ? Number(record.totalAmount).toFixed(3) : null
        }
      },
      {
        title: 'Business Approval Status',
        dataIndex: 'businessApprovalStatus',
        render: (text,record) => {
          return (record.businessApprovalStatus === "Send For Approval" || record.businessApprovalStatus === "Active" || record.businessApprovalStatus === "Draft") ? <>
          <StatusTag status={text} /> 
          {record.businessApprovalStatus === "Active" && (
            <Popover
              content={<>
                  <>
                    <Typography style={{ fontSize: 10 }}>Approved By <b>{record?.approvedBy}</b></Typography>
                    <Typography style={{ fontSize: 10 }}>Approved On <b>{record?.approvedDate ? moment(record?.approvedDate).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
                  </>
              </>}
              title="Business Approval Status"
              placement="bottom"
            >
              <InfoCircleOutlined style={{ color: "#0295D4" }} />

            </Popover>
          )}
          </> : null
        }
      },
      {
        title: 'Management Approval Status',
        dataIndex: 'managementApprovalStatus',
        hidden: !isPermissionExist([893]),
        render: (text,record, index) => {
            if(record.businessApprovalStatus === "Active" && record.managementApprovalStatus !== "Active" ){
              return <>

                      <Popover
                        content={<>
                            <Form layout='vertical' form={formRef} autoComplete='off' style={{ width: '300px' }}>
                                <Form.Item name={"invoiceNo"} style={{ display: 'none' }}>
                                    <Input hidden />
                                </Form.Item>


                                <Form.Item
                                    label='remarks'
                                    name='remarks'
                                    style={{ width: '100%' }}
                                    rules={[
                                        {
                                            required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                                        },
                                        // ...classValidator.alphaNumericSpecialCharsWithSpaces(),
                                    ]}
                                >
                                    <Input.TextArea style={{ width: '100%' }} placeholder='Notes' onChange={(e) => handleChange(e,'remarks',index)} />
                                </Form.Item>

                                <Divider />

                                <Flex align='center' justify='flex-end'>
                                    <Space size={10}>

                                        <Button type="text" className='link' size='small' onClick={() => setOpen(undefined)}>Cancel</Button>
                                        <Button
                                          className="approve"
                                          disabled={!formRef.getFieldValue('remarks')}
                                          onClick={async () => {
                                            try {
                                              const newData = [...displayedData];
                                              if (newData[index]) {
                                                newData[index].managementApprovalStatus = "Active";
                                                newData[index].approvedAt = formattedDate;
                                                newData[index].approvedBy = authContext.user.userName;
                                                // newData[index].remarks = formRef.getFieldValue('remarks');
                                                newData[index].isNewRecord = true;
                                              }

                                              setDisplayedData(newData);

                                              handleSubmit();
                                            } catch (error) {
                                              console.error("Error during approval:", error);
                                              AlertMessages.getErrorMessage("Failed to approve the record.");
                                            }
                                          }}
                                        >
                                          <CheckCircleOutlined style={{ color: "#0B903C" }} /> Approve
                                        </Button>
                                    </Space>
                                </Flex>
                            </Form>
                        </>}
                        title="Work Log"
                        trigger="click"
                        open={index === open}
                        placement="bottom"
                    >
                            <Button icon={<CheckCircleOutlined />} className="approve" onClick={() => setOpen(index)}>Approve</Button>
                    </Popover>
              </>
            } else {
            if(record.managementApprovalStatus === "Active"){
              return <>
                    <StatusTag status={record.managementApprovalStatus} />
                    <Popover
                    content={<>
                      {/* {record.workLog?.map((log) => ( */}
                        <>
                          <Typography style={{ fontSize: 10 }}>Remarks <b>{record?.remarks}</b></Typography>
                          <Typography style={{ fontSize: 10 }}>Approved By <b>{record?.managementApprovedBy}</b></Typography>
                          <Typography style={{ fontSize: 10 }}>Approved On <b>{record?.managementApprovedAt ? moment(record?.managementApprovedAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
                        </>
                      {/* ))} */}
                    </>}
                    title="Management Approval Status"
                    placement="bottom"
                  >
                    <InfoCircleOutlined style={{ color: "#0295D4" }} />

                  </Popover>
                  </>
            } else {
              return ''
            }
          }
        }
      },
      {
        title: 'File Status',
        dataIndex: 'fileStatus',
        width: 140,
        render: (text,record, index) => {
          return edit === index ? (
            <Select
              style={{ width: '100%' }}
              placeholder="File status" 
              value={record.fileStatus || null}
              onChange={(e)=> handleChange(e,'fileStatus',index)}
              allowClear
            >
              {['Initated','Pending Confirmation','Confirmed', 'Payment pending','Completed'].map(item => {
                 return <Option value={item}>{item}</Option>
              })}
            </Select>
          ) : (
            text
          )
        }
      },
      {
        title: 'Comments',
        dataIndex: 'comment',
        width: 140,
        render: (text,record, index) => {
          return edit === index ? (
            <Input 
                style={{ width: '100%' }}
                value={record.comment || null}
                onChange={(e) => handleChange(e,'comment',index)}
                placeholder="comment"
                autoComplete="off"
                allowClear
            />
          ) : (
            text
          )
        }
      },
      {
        title: 'Action',
        fixed: 'right',
        align: 'center',
        hidden: !isPermissionExist([894]),
        render: (text, record, index) => {
          return edit === index ? (
          <>
            <Tooltip placement="top" title="Save">
                <Button style={{ padding: '0px 3px' }}><SaveTwoTone
                    onClick={handleSubmit}
                    style={{ color: '#1890ff', fontSize: '18px' }}
                /></Button>
            </Tooltip> &nbsp;
            <Tooltip placement="top" title="Cancel">
            <Button style={{ padding: '0px 3px' }}>
            <CloseOutlined
                type="cancel"
                onClick={() => {
                  const newData = [...displayedData];
                  if (newData[index]) {
                    newData[index].fileStatus = null;
                    newData[index].comment = null;
                  }
                  setDisplayedData(newData);
                  setEdit(null)}}
                style={{ fontSize: '18px' }}
            /></Button>
            </Tooltip>
          </>) : (
            <Tooltip placement="top" title="Edit">
            <EditFilled
                type="edit"
                onClick={() => {handleClick(index)}}
                className="action-icons"
            />
            </Tooltip>
          )
        }
      }
  ]
  
  return (<>
          <Card 
            className='card-radius'>
              <Descriptions layout="vertical">
                <Descriptions.Item label="Business Number">
                  <Select
                      style={{ width: '28%', marginBottom: '12px' }} 
                      placeholder="Business Number" 
                      value={selectedBusinessNo}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toString()
                          .toLocaleLowerCase()
                          .includes(input.toLocaleLowerCase())
                      }
                      onChange={handleBusinessNoChange}
                      showSearch
                      allowClear
                  >
                      {businessDropDown.map(item => (
                          <Option value={item.bnId} key={item.bnId}>
                              {item.bnNo} {item.motherVesselName && `(${item.motherVesselName})`}
                          </Option>
                      ))}
                  </Select>
                </Descriptions.Item>
              </Descriptions>

            <Table
              className="contracts-list"
              columns={columns}
              dataSource={displayedData}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            { limitedData.length < LTCReports.length && !selectedBusinessNo && (
              <Button onClick={handleLoadMore} className="new-button" style={{ marginTop: '20px' }}>
                Load More
              </Button>
            )}
          </Card>
    </>
  )
}

export default LTCReports;