import { AllocatedTypeEnum, GetAllBargeMappingDataDto, ResponsePersonEnum } from "@exportx/shared-models-and-services"
import { Card, Row, Col, Switch, Input, Descriptions, Select, Form, InputNumber, Radio } from "antd"
import { useEffect, useState } from "react";
import { AlertMessages } from "../../../../../common";
import { SequenceUtils } from "@exportx/ui-utils";

const { Option } = Select;
interface IPropsReDraft {
    record: GetAllBargeMappingDataDto;
    index: number;
    formValuesData: Map<string, any>;
    setFormValuesData: React.Dispatch<React.SetStateAction<Map<string, any>>>
}
export const RedraftForm = (props: IPropsReDraft) => {
    const { record, formValuesData, setFormValuesData } = props;
    const [allocationType, setAllocationType] = useState<any>(true);
    const [defaultQuantity, setDefaultQuantity] = useState<number>(0);
    const [redraftForm] = Form.useForm();
    const [disabledPartial, setDisabledPartial] = useState<boolean>(false);
    let key = record.id + '$@$' + record.allocationUId;

    const [redraftFormValue, setRedraftFormValues] = useState<Map<string, any>>(formValuesData)


    const defaultValue = redraftFormValue?.get(key);

    useEffect(() => {
        const barges = Array.from(formValuesData.values());

        const newForm = new Map(redraftFormValue);

        for (const [key, value] of formValuesData) {
            const sameBarges = barges.filter(barge => barge.bargeUid === value?.bargeUid);
            const remainingAmt = sameBarges.reduce((acc, curr) => acc + Number(curr.quantity ?? 0), 0)
            redraftForm.setFieldValue('availableQuantity', (Number(record?.availableQuantity ?? 0) - remainingAmt)?.toFixed(2));
            if (defaultValue) {
                defaultValue['totalQuantity'] = Number(record?.bargeQuantityInMt);
                defaultValue['bargeQuantityInMt'] = Number(record?.bargeQuantityInMt);

            }
            newForm.set(key, value);
            setRedraftFormValues(newForm)

        }
    }, [formValuesData])


    useEffect(() => {
        let allocation: any = true;
        if (formValuesData?.get(key)?.allocationType) {
            if (formValuesData?.get(key)?.allocationType === AllocatedTypeEnum.FULL) {
                allocation = true;
            } else {
                allocation = false;
            }
            setDefaultQuantity(Number(defaultValue.quantity ?? 0));
        } else {
            if (typeof (formValuesData?.get(key)?.allocationType) === "boolean") {
                allocation = false;
                setDefaultQuantity(Number(defaultValue.quantity ?? 0))
            } else {
                allocation = true;
            }
        }
        setAllocationType(allocation);

        redraftForm.setFieldValue('allocationType', allocation)
        redraftForm.setFieldValue('availableQuantity', Number(record?.availableQuantity ?? record?.bargeQuantityInMt))
    }, [])


    const onValuesChangeHandler = (changedValues: any, allValues: any) => {
        const changedValueKeys = Object.keys(changedValues);

        if (changedValueKeys.includes('quantity')) {
            const data = Array.from(formValuesData.values());
            const existingBargeAllocation = data?.filter(e => e.bargeUid === allValues['bargeUid'] && e.allocationId !== allValues['allocationId']);

            const remainingAmt = existingBargeAllocation.reduce((acc, curr) => acc + Number(curr.quantity ?? 0), 0)
            const totalAvailableQty = (Number(record?.availableQuantity) - remainingAmt)?.toFixed(2);
            const requestedQty = Number(changedValues['quantity']);


            if (Number(requestedQty) > Number(totalAvailableQty)) {
                redraftForm.setFields([{
                    name: 'quantity',
                    value: defaultQuantity,
                    errors: [`Cannot exceed available quantity of ${totalAvailableQty} MT`]
                }]);
                // redraftForm.setFieldValue('availableQuantity', record?.availableQuantity);
            }
            else {
                redraftForm.setFields([{
                    name: 'quantity',
                    errors: []
                }]);
            }
        }

        if (changedValueKeys.includes('redraftQuantity')) {
            const redraftQuantity = Number(changedValues['redraftQuantity']);
            const finalQty = (allValues['allocationType'] === true || allValues['allocationType'] === AllocatedTypeEnum.FULL) ? Number(record?.bargeQuantityInMt) : Number(allValues['quantity']);

            const rdQty = (redraftQuantity - finalQty);
            redraftForm.setFieldValue('quantityDifference', rdQty.toFixed(3));
        }

        if (changedValueKeys.includes('allocationType')) {
            const data = Array.from(formValuesData.values());
            const existingBargeAllocation = data?.find(e => e.bargeUid === allValues['bargeUid'] && e.allocationId !== allValues['allocationId']);
            if (existingBargeAllocation) {
                if (existingBargeAllocation.allocationType === AllocatedTypeEnum.FULL || existingBargeAllocation.allocationType === true) {
                    AlertMessages.getErrorMessage(`You cannot assign same barge ${SequenceUtils.formatNumberToSpecificLength(existingBargeAllocation.bargeId)} with both Full`)
                    return;
                }
                if ((existingBargeAllocation.allocationType === AllocatedTypeEnum.PARTIAL || existingBargeAllocation.allocationType === false) && (allValues['allocationType'] === AllocatedTypeEnum.FULL || allValues['allocationType'] === true)) {
                    AlertMessages.getErrorMessage(`You cannot assign same barge ${SequenceUtils.formatNumberToSpecificLength(existingBargeAllocation.bargeId)} with both Full and Partial`)
                    return;
                }
            }

            setAllocationType(changedValues['allocationType']);
            if ((changedValues['allocationType'] === true || changedValues['allocationType'] === AllocatedTypeEnum.FULL)) {
                redraftForm.setFieldValue('quantity', Number(record?.bargeQuantityInMt));
                redraftForm.setFieldValue('availableQuantity', 0);
            }
        }

        const newFormValuesData = new Map(formValuesData); // Create a new Map instance

        newFormValuesData.set(key, { ...defaultValue, ...allValues })
        setFormValuesData(newFormValuesData);
    }



    return (
        <>
            <Form form={redraftForm} initialValues={{ ...defaultValue }} onValuesChange={onValuesChangeHandler} autoComplete='off' layout='vertical'>
                <Row className="mb-12">
                    <Form.Item name={"bargeUid"} label="Allocation Type" style={{ display: 'none' }}>
                        <Input type={'number'} placeholder='Quantity' autoComplete="off" min={0} addonAfter="MT" />
                    </Form.Item>
                    <Col xs={{ span: 24 }} lg={{ span: 6, offset: 1 }} >
                        <Form.Item name={"allocationType"} label="Allocation Type" initialValue={allocationType} >
                            <Radio.Group
                                optionType="button"
                            >
                                <Radio value={true} disabled={record?.disabledFull} >Full</Radio>
                                <Radio value={false} disabled={disabledPartial}>Partial</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {!allocationType && <Col xs={{ span: 24 }} lg={{ span: 6, offset: 1 }} >
                        <Form.Item
                            name={'quantity'}
                            label='Quantity'
                        >
                            <InputNumber
                                placeholder='Quantity'
                                autoComplete="off"
                                min={0}
                                addonAfter="MT"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>}
                    {!allocationType && <Col xs={{ span: 24 }} lg={{ span: 6, offset: 1 }} >
                        <Form.Item name={'availableQuantity'} label='Available Quantity' >
                            <Input disabled={true} autoComplete="off" placeholder='Quantity Difference' type={'number'} />
                        </Form.Item>
                    </Col>}
                </Row>
                <Col lg={{ offset: 1 }} sm={{ offset: 0 }} className="mb-12 comment-username" >Redraft Details</Col>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }} >
                        <Form.Item name={'redraftQuantity'} label='Redraft Quantity' >
                            <Input autoComplete="off" type={'number'} placeholder='Redraft Quantity' addonAfter="MT" />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }} >
                        <Form.Item name={'redraftParty'} label={'Redraft Party'}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                allowClear showSearch placeholder='Select Redraft Party'>
                                <Option value={''}>Please Select</Option>
                                {Object.keys(ResponsePersonEnum).map((rec) => {
                                    return <Option value={ResponsePersonEnum[rec]}>{ResponsePersonEnum[rec]}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }} >
                        <Form.Item name={'quantityDifference'} label='Qty Difference' >
                            {/* <span>{Number(redraftForm.getFieldValue('quantityDifference') ?? 0)}</span> */}
                            <Input disabled={true} autoComplete="off" placeholder='Quantity Difference' type={'number'} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }} >
                        <Form.Item name={'quantityDifferenceAccount'} label={'Qty Difference Account'}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                allowClear showSearch placeholder='Select Quantity Party' style={{}}>
                                <Option value={''}>Please Select</Option>
                                {Object.values(ResponsePersonEnum).filter(rec => { return rec !== ResponsePersonEnum.BUYER }).map(rec => {
                                    return <Option value={rec}>{rec}</Option>
                                })}
                            </Select>

                        </Form.Item>

                    </Col>
                </Row>
                <Row >
                    <Col>
                        <Form.Item name={'availableQuantity'} style={{ display: 'none' }}>
                            <InputNumber type={'hidden'} />
                        </Form.Item>
                        <Form.Item name={'bargeQuantityInMt'} hidden>
                            <InputNumber type={'hidden'} />
                        </Form.Item>
                        <Form.Item name={'allocationId'} hidden>
                            <InputNumber type={'hidden'} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default RedraftForm;