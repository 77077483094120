import {
  CheckCircleOutlined,
  EditFilled,
  FileExcelFilled,
  PlusSquareOutlined,
} from '@ant-design/icons';
import {
  AdvanceAll,
  AdvanceEnum,
  AdvancePreview,
  ContractStatusEnum,
  ContractTypeDto,
  VendorAdvService,
} from '@exportx/shared-models-and-services';
import { Button, Card, Input, Row, Select, Table, Tooltip } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Option } from 'antd/es/mentions';

interface IAdvanceGridProps {
  advanceCategory: AdvanceEnum;
}

const AdvanceGrid = (props: IAdvanceGridProps) => {
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const { authContext } = useAuthState();

  const { state }: any = useLocation();
  const { returnContractType, record } = state
    ? state
    : { returnContractType: null, record: null };
  const { id } = record ? record : { id: null };
  const [gridData, setGridData] = useState<any>();
  const service = new VendorAdvService();
  let pathToreDirect = '/advanceDetailView';

  const [searchData, setSearchData] = useState('');
  const [createPermission, setCreatePermission] = useState<any>(false);
  const [excelPermission, setExcelPermission] = useState<any>(false);
  const [editPermission, setEditPermission] = useState<any>(false);
  const [approvePermission, setApprovePermission] = useState<any>(false);
  const [status, setStatus] = useState<any>('');

  useEffect(() => {
    let permission;
    if (props.advanceCategory === AdvanceEnum.VendorAdvance) {
      setCreatePermission(isPermissionExist([173]));
      setExcelPermission(isPermissionExist([179]));
      setEditPermission(isPermissionExist([175]));
      setApprovePermission(isPermissionExist([177]));
      permission = [174];
    } else if (props.advanceCategory === AdvanceEnum.DownPayment) {
      setCreatePermission(isPermissionExist([193]));
      setExcelPermission(isPermissionExist([199]));
      setEditPermission(isPermissionExist([195]));
      setApprovePermission(isPermissionExist([197]));
      permission = [194];
    } else {
      setCreatePermission(isPermissionExist([213]));
      setExcelPermission(isPermissionExist([219]));
      setEditPermission(isPermissionExist([215]));
      setApprovePermission(isPermissionExist([217]));
      permission = [214];
    }
    if (!isPermissionExist(permission)) navigate('/');

    if (props.advanceCategory) {
      getAdvanceContractType();
    }
  }, [props.advanceCategory]);

  useEffect(() => {
    if (returnContractType) {
      getAdvanceContractType();
    }
  }, [returnContractType]);

  const getAdvanceContractType = () => {
    const req = new ContractTypeDto();
    req.contractType = record ? record : props.advanceCategory;
    req.plantCode = authContext.defaultPlant;
    service
      .getAdvanceContractType(req)
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const sendForApproval = (id: string) => {
    const req = new AdvancePreview(
      id,
      authContext.defaultPlant,
      authContext.user.userName,
    );
    service
      .advanceSendForApproval(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
          getAdvanceContractType();
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };
  const advanceApproveOrReject = (id: string) => {
    const req = new AdvancePreview(
      id,
      authContext.defaultPlant,
      authContext.user.userName,
      ContractStatusEnum.ACTIVE,
    );
    service
      .advanceApproveOrReject(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
          getAdvanceContractType();
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const view = (vendorAdvData: AdvanceAll, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (vendorAdvData) {
      isNewRecord = false;
    }
    let pathToreDirect = '/vendorAdvance';
    let contract = props.advanceCategory;
    navigate(pathToreDirect, { state: { contract } });
  };

  const tableColumns: any = [
    

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      // width: 130,
      // align: 'left',
      sorter: (a, b) =>
        a.contractNo != null
          ? a.contractNo.localeCompare(b.contractNo)
          : 'Have Null Values',
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.vendorName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.amount)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.negotiationCurrency)
            .toLowerCase()
            .includes(value.toLocaleString())
        );
      },
      render: (value, record) => {
        const link = `/#${pathToreDirect}?id=${record.id}`;
        return (
          <>
            {' '}
            {
              <a href={link} className="link">
                {record.contractNo}
              </a>
            }
          </>
        );
      },
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      // width: 130,
      // align: 'left',
      sorter: (a, b) =>
        a.vendorName != null
          ? a.vendorName.localeCompare(b.vendorName)
          : 'Have Null Values',
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      // width: 125,
      // align: 'left',
      render: (text, record) => {
        return record.contractDate !== undefined
          ? moment(record.contractDate).format('DD\u2011MMM\u2011YYYY')
          : '';
      },
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      // width: 130,
      align: 'right',
      render: (value, record) => {
        return <>{Number(value).toFixed(2)}</>;
      },
    },
    {
      title: 'Currency',
      dataIndex: 'billingCurrency',
      // width: 130,
      // align: 'left',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // width: 130,
      // align: 'center',
      // sorter: (a, b) => a.status.localeCompare(b.status),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <>
          <StatusTag status={record.status} />
        </>
      ),
    },
    {
      title: 'Action',
      className: 'operations',
      // width: 130,
      // align: 'left',
      render: (value, record) => (
        <span>
          {record.status !== ContractStatusEnum.CLOSED &&
          record.status !== ContractStatusEnum.ACTIVE &&
          editPermission ? (
            <>
              <EditFilled
                type="edit"
                className="action-icons"
                onClick={() => {
                  editClickHandler(record.id);
                }}
                // style={{ color: '#1890ff', fontSize: '14px' }}
              />
            </>
          ) : null}
          {/* {(record.status === ContractStatusEnum.DRAFT) ? <><Tooltip placement="top" title={fm({ id: "common.sendForApproval", defaultMessage: 'Send For Approval' })}>
          <FontAwesomeIcon icon={faPersonCircleCheck} style={{ color: '#1890ff', fontSize: '14px' }}
            onClick={() => {
              sendForApproval(record.id);
            }} className={`hover ${record.status !== ContractStatusEnum.DRAFT ? 'disabled-button' : ''}`} />
        </Tooltip><span> | </span></> : null}

        {(record.status === ContractStatusEnum.PENDING_APPROVAL && approvePermission) ?
          <><Tooltip placement="top" title={fm({ id: "common.approve", defaultMessage: 'Approve' })}>
            <CheckCircleOutlined type='approve'
              onClick={() => {
                advanceApproveOrReject(record.id)
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip></> : null
        }
        {(record.status === ContractStatusEnum.ACTIVE) ?
          <><Tooltip placement="top" title={fm({ id: "common.approve", defaultMessage: 'Addendum' })}>
            <PlusSquareOutlined type='approve'
              onClick={() => {
                // advanceApproveOrReject(record.id)
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip></> : null
        } */}
        </span>
      ),
    },
  ];
  const editClickHandler = (id: string) => {
    const req = new AdvancePreview(id, authContext.defaultPlant);
    service
      .getAdvancePreviewData(req)
      .then((res) => {
        if (res.status) {
          navigate('/vendorAdvance-UpDate', {
            state: { vendorAdvData: res.data },
          });
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const handleExport = (e: any) => {
    e.preventDefault();

    // Formatting columns - to set default render

    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split('-')
      .join('/');
    // Formatting columns - to set default render
    let cloneArr = tableColumns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return {
          title: item.dataIndex.toLocaleUpperCase(),
          dataIndex: item.dataIndex,
        };
    });

    const excel = new Excel();
    excel.addSheet('Sheet1');
    excel.addRow();
    excel
      .addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`Advance-${currentDate}.xlsx`);
  };

  // Handle status change
  const handleStatusChange = (value) => {
    setStatus(value);
  };

  // Filter data based on selected status
  const filteredData = status
    ? gridData.filter((item) => item.status === status)
    : gridData;

  return (
    <>
      {/* 
      <Card className='card-container'
      title={<span className='card-title'> {props.advanceCategory} </span>}
      headStyle={{border: 'none'}}> */}

      <Card
        className="card-radius"
        headStyle={{ border: 0 }}
        title={
          <Row className="card-title-buttons">
            <Input.Search
              placeholder="Search"
              allowClear
              className="input-search"
              onChange={(e) => {
                setSearchData(e.target.value);
              }}
              onSearch={(value) => {
                setSearchData(value);
              }}
            />
            <Select
              placeholder="Filter by Status"
              className="filter-by-status"
              allowClear
              onChange={handleStatusChange}
            >
              <Option value="Draft">Draft</Option>
              <Option value="Send For Approval">Send for Approval</Option>
              <Option value="Active">Active</Option>
            </Select>
          </Row>
        }
        extra={
          <>
            {createPermission && (
              <Button
                type="primary"
                className="new-button mt-16"
                onClick={() => {
                  view(undefined, false);
                }}
              >
                {fm({ id: 'common.create', defaultMessage: 'Create' })}
              </Button>
            )}
          </>
        }
      >
        <Table
          className="contracts-list full-width"
          rowKey={(record) => record.Id}
          columns={tableColumns}
          dataSource={filteredData}
          scroll={{ x: 500 }}
          pagination={{
            showTotal: (total) => (
              <span className="page-total-items">Total {total} items</span>
            ),
            showSizeChanger: true,
            pageSizeOptions: ['25', '50', '100'],
            defaultPageSize: 25,
            style: {
              paddingTop: '20px',
            },
          }}
        />
      </Card>
      {/* </Card> */}

      {/* <Card
        title={props.advanceCategory}
        style={{ overflow: 'auto' }}
        extra={<>{createPermission && (
          <Button
            type='primary'
            onClick={() => { view(undefined, false); }}
          >
            {fm({ id: 'common.create', defaultMessage: 'Create' })}
          </Button>)}</>
        }
        bodyStyle={{ overflow: 'auto' }}>
        <Card>
          {excelPermission && (
          <Tooltip placement="topRight" title="Export As Excel">
            <Button
              //disabled={gridData.length === 0}
              style={{ float: "right" }}
              size='small'
              type="default"
              //className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
              onClick={handleExport}
              icon={<FileExcelFilled />}
            >Export</Button>
          </Tooltip>)}
          <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />

          <Table className='contracts' rowKey={record => record.Id} columns={tableColumns} dataSource={gridData} scroll={{ x: 500 }}
            size="large"
            bordered />
        </Card>
      </Card> */}
    </>
  );
};

export default AdvanceGrid;
